import {useEffect, useState} from 'react'
import Drawer from 'react-modern-drawer'
import {useAuth} from '../../../modules/auth'
import {
  addDoc,
  arrayRemove,
  collection,
  doc,
  getDoc,
  onSnapshot,
  runTransaction,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore'
import db from '../../../../services/firebase'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import ReactSlider from 'react-slider'
import AddRiskControls from './modal/AddRiskControls'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'

type Props = {
  id: string
  isOpen: boolean
  onClose: () => void
}

type Control = {
  id: string
  control_title: string
  control_framework_mappings: string
}

const EditRiskModal: React.FC<Props> = ({id, isOpen, onClose}) => {
  const [riskSource, setRiskSource] = useState('')
  const [owner, setRiskOwner] = useState('')
  const [message, setMessage] = useState({error: false, msg: ''})
  const [showAlert, setShowAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [owners, setOwners] = useState<any[]>([])
  const {currentUser} = useAuth()
  const [inherent_likelihood, setInherentLikelihood] = useState('')
  const [inherent_impact, setInherentImpact] = useState('')
  const [residual_likelihood, setResidualLikelihood] = useState('')
  const [residual_impact, setResidualImpact] = useState('')
  const [description, setDescription] = useState('')
  const [category, setCategory] = useState('')
  const [treatment, setTreatment] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [risk_status, setRiskStatus] = useState('')
  const [addedControls, setAddedControls] = useState<Control[]>([])
  const navigate = useNavigate()
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      // Logout()
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setOwners(risksData)
    })

    return unsubscribe
  }, [])

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const validateForm = () => {
    const errors: Record<string, string> = {}

    if (!riskSource.trim()) {
      errors.riskSource = 'Risk name is required'
    }

    if (!description.trim()) {
      errors.description = 'Risk description is required'
    }

    if (!category) {
      errors.category = 'Risk category is required'
    }
    if (!owner) {
      errors.owner = 'Risk Owner is required'
    }

    if (!treatment) {
      errors.treatment = 'Treatment plan is required'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault()

    const newRisk = {
      riskSource,
      description,
      category,
      owner,
      inherent_impact,
      inherent_likelihood,
      residual_impact,
      residual_likelihood,
      treatment,
    }

    const modalContentElement = document.getElementById('kt_modal_9')
    if (!validateForm()) {
      setIsLoading(false)
      return
    }

    const currentDate = new Date() // No need to import the Firestore module
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1 // Note: The month is zero-based
    const day = currentDate.getDate()

    const todaysDate = month + '/' + day + '/' + year

    try {
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)

      const assetRef = doc(tenantRef, 'risks', id)
      await updateDoc(assetRef, {
        ...newRisk,
        risk_status: 'Needs approval',
        updated_at: serverTimestamp(),
      })

      const activitiesRef = collection(tenantRef, 'activities')
      await addDoc(activitiesRef, {
        message: `${currentUser?.userName} updated risk '${riskSource}'`,
        timestamp: serverTimestamp(),
        tenantId: currentUser?.tenantId,
        uid: currentUser?.uid,
        action: 'Risk scenario updated',
        target: 'Risk Management',
        username: currentUser?.userName,
        date: todaysDate,
        email: currentUser?.email,
      })

      toast.success('Owner has been requested for approval', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })
  
      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
    
      setIsLoading(false)
    } catch (err: any) {

      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
   
      setIsLoading(false)
    }

    setTimeout(() => {
      // window.location.reload()
      setShowAlert(false)
      onClose()
    }, 3000)
  }

  const handleRiskOwner = (event) => {
    // Get the selected value from the event
    const selectedValue = event.target.value

    // Update the state variable with the selected value
    setRiskOwner(selectedValue)
  }

  const handleboard = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setShowModal(true)
  }

  const getRisk = async () => {
    try {
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'risks')
      const riskDocRef = doc(PolicyRef, id)

      const unsubscribe = onSnapshot(riskDocRef, (doc) => {
        if (doc.exists()) {
          const riskData = doc.data()
          // Update the state with the fetched risk data
          setRiskSource(riskData.riskSource)
          setDescription(riskData.description)
          setCategory(riskData.category)
          setRiskOwner(riskData.owner)
          setInherentLikelihood(riskData.inherent_likelihood)
          setInherentImpact(riskData.inherent_impact)
          setResidualLikelihood(riskData.residual_likelihood)
          setResidualImpact(riskData.residual_impact)
          setTreatment(riskData.treatment)
          setRiskStatus(riskData.risk_status)
          setAddedControls(riskData.added_controls)
        } else {
          console.log('Risk document does not exist')
        }
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting risk:', error)
    }
  }

  useEffect(() => {
    if (id !== '' && id !== undefined) {
      getRisk()
    }
  }, [id])

  const handleCategory = (event) => {
    // Get the selected value from the event
    const selectedValue = event.target.value

    // Update the state variable with the selected value
    setCategory(selectedValue)
  }

  const handleChange = (event) => {
    setTreatment(event.target.value)
  }

  const updatePolicy = async (updateData) => {
    if (!currentUser?.tenantId || !id) return

    const tenantRef = doc(db, 'tenants', currentUser.tenantId)
    const policyRef = doc(collection(tenantRef, 'risks'), id)

    try {
      await runTransaction(db, async (transaction) => {
        const policyDoc = await transaction.get(policyRef)
        if (!policyDoc.exists()) {
          throw 'Document does not exist!'
        }

        transaction.update(policyRef, {
          ...updateData,
          updated_at: serverTimestamp(),
        })
      })
    } catch (error) {
      console.error(error)
    }
  }

  // const handleApprove = async () => {
  //   try {
  //     setLoading(true)
  //     await updatePolicy({riskStatus: 'Approved'})

  //     const currentDate = new Date() // No need to import the Firestore module
  //     const year = currentDate.getFullYear()
  //     const month = currentDate.getMonth() + 1 // Note: The month is zero-based
  //     const day = currentDate.getDate()

  //     const todaysDate = month + '/' + day + '/' + year
  //     // setStatus('Approved')
  //     const tenantId = currentUser?.tenantId || ''
  //     const tenantRef = doc(db, 'tenants', tenantId)
  //     const activitiesRef = collection(tenantRef, 'activities')
  //     await addDoc(activitiesRef, {
  //       message: `${currentUser?.userName} has been approved risk scenario '${riskSource}'`,
  //       timestamp: serverTimestamp(),
  //       tenantId: currentUser?.tenantId,
  //       uid: currentUser?.uid,
  //       action: 'Risk scenario updated',
  //       target: 'Risk Management',
  //       username: currentUser?.userName,
  //       date: todaysDate,
  //       email: currentUser?.email,
  //     })

  //     toast.success('Risk scenario approved successfully!', {
  //       position: toast.POSITION.TOP_CENTER,
  //       className: 'bg-white',
  //       style: {
  //         borderRadius: '8px',
  //         boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  //         border: '1px solid #e0e0e0',
  //         color: 'black',
  //       },
  //       progressStyle: {
  //         background: '#1CBB8D',
  //       },
  //       icon: ({theme, type}) => (
  //         <svg
  //           width='26'
  //           height='26'
  //           viewBox='0 0 24 24'
  //           fill='none'
  //           xmlns='http://www.w3.org/2000/svg'
  //           style={{color: '#1CBB8D'}}
  //         >
  //           <path
  //             d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
  //             fill='currentColor'
  //           />
  //         </svg>
  //       ),
  //       autoClose: 3000,
  //     })
  //   } catch (error) {
  //     console.error('Error approving policy:', error)
  //     toast.error('Error approving policy!', {
  //       position: toast.POSITION.TOP_CENTER,
  //       className: 'bg-white',
  //       style: {
  //         borderRadius: '8px',
  //         boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  //         border: '1px solid #e0e0e0',
  //         color: 'black',
  //       },
  //       // progressStyle: {
  //       //   background: '#1CBB8D',
  //       // },
  //       icon: ({theme, type}) => (
  //         <svg
  //           width='26'
  //           height='26'
  //           viewBox='0 0 24 24'
  //           fill='none'
  //           xmlns='http://www.w3.org/2000/svg'
  //           style={{color: '#ee3043'}}
  //         >
  //           <path
  //             d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
  //             fill='currentColor'
  //           />
  //         </svg>
  //       ),
  //       autoClose: 3000,
  //     })
  //   }
  //   setLoading(false)
  // }

  const handleApprove = async () => {
    try {
      setLoading(true)
      const newStatus = 'Approved'
      await updatePolicy({risk_status: newStatus})

      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = currentDate.getMonth() + 1
      const day = currentDate.getDate()
      const todaysDate = `${month}/${day}/${year}`

      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const riskRef = doc(tenantRef, 'risks', id)

      // Update the risk document
      await updateDoc(riskRef, {risk_status: newStatus})

      // Update all associated control documents
      if (addedControls && addedControls.length > 0) {
        const updatePromises = addedControls.map(async (control) => {
          const controlRef = doc(tenantRef, 'controls', control.id)
          const controlDoc = await getDoc(controlRef)

          if (controlDoc.exists()) {
            const controlData = controlDoc.data()
            const updatedRiskScenario = controlData.risk_scenario.map((scenario: any) => {
              if (scenario.riskId === id) {
                return {...scenario, status: newStatus}
              }
              return scenario
            })

            await updateDoc(controlRef, {risk_scenario: updatedRiskScenario})
          }
        })

        await Promise.all(updatePromises)
      }

      // Log the activity
      const activitiesRef = collection(tenantRef, 'activities')
      await addDoc(activitiesRef, {
        message: `${currentUser?.userName} has approved risk scenario '${riskSource}'`,
        timestamp: serverTimestamp(),
        tenantId: currentUser?.tenantId,
        uid: currentUser?.uid,
        action: 'Risk scenario updated',
        target: 'Risk Management',
        username: currentUser?.userName,
        date: todaysDate,
        email: currentUser?.email,
      })

      toast.success('Risk scenario approved successfully!', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })

      // Update local state
      setRiskStatus(newStatus)
    } catch (error) {
      console.error('Error approving policy:', error)
      toast.error('Error approving policy!', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#ee3043'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })
    }
    setLoading(false)
  }

  const handleDeleteControl = async (control) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${control.control_title}`,
        text: `Are you sure you want to remove ${control.control_title} control from risk scenario ? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const riskRef = doc(tenantRef, 'risks', id)
        const controlRef = doc(tenantRef, 'controls', control.id)

        // Find the control to be deleted
        const controlToDelete = addedControls.find((controls) => controls.id === control.id)

        if (!controlToDelete) {
          console.error('Control not found')
          return
        }

        // 1. Update the risk document
        await updateDoc(riskRef, {
          added_controls: arrayRemove(controlToDelete),
        })

        // 2. Update the control document
        const controlDoc = await getDoc(controlRef)
        if (controlDoc.exists()) {
          const controlData = controlDoc.data()
          const updatedRiskScenarios = controlData.risk_scenario.filter(
            (scenario) => scenario.riskId !== id
          )

          await updateDoc(controlRef, {
            risk_scenario: updatedRiskScenarios,
          })
        }

        // 3. Update local state
        setAddedControls((prevControls) =>
          prevControls.filter((controls) => controls.id !== control.id)
        )

        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: ` "${control.control_title}" has been deleted.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the control from risk scenario.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      // setDropdownVisible(false)
    }
  }

  const handleRiskClick = (id) => {
    navigate(`/controls?id=${id}`)
  }

  const onCancel = () => {
    setFormErrors({})
    onClose()
  }
  const getBadgeClass = (risk) => {
    if (risk < 5) {
      return {backgroundColor: '#1CBB8D'} // Low risk (primary blue)
    } else if (risk >= 5 && risk <= 14) {
      return {backgroundColor: '#ffc107'} // Medium risk (warning yellow)
    } else if (risk >= 15 && risk < 25) {
      return {backgroundColor: '#dc3545'} // High risk (danger red)
    } else if (risk >= 25) {
      return {backgroundColor: '#A7222F'} // Critical risk (custom dark red)
    } else {
      return {backgroundColor: ''} // Default or undefined risk (secondary gray)
    }
  }

  const getRiskLevelText = (risk) => {
    if (risk < 5) {
      return 'Low'
    } else if (risk >= 5 && risk <= 14) {
      return 'Medium'
    } else if (risk >= 15 && risk < 25) {
      return 'High'
    } else if (risk >= 25) {
      return 'Critical'
    } else {
      return ''
    }
  }

  return (
    <>
      <Drawer open={isOpen} onClose={onCancel} direction='right' className='' size='45%'>
        <div
          className='modal-dialog modal-dialog-centered pt-3 px-6 border-bottom border-gray-300 '
          style={{position: 'sticky', top: 0, backgroundColor: 'white', paddingLeft: '12px'}}
        >
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 className='modal-title mt-2 mb-3 '>{riskSource}</h4>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary mx-4'
                onClick={onCancel}
                aria-label='Close'
                style={{
                  transition: 'all 0.2s ease',
                }}
                onMouseEnter={(e) => {
                  if (e.currentTarget) {
                    e.currentTarget.style.backgroundColor = '#fff'
                    const svgElement = e.currentTarget.querySelector('svg')
                    if (svgElement) {
                      svgElement.style.stroke = '#1CBB8D'
                    }
                  }
                }}
                onMouseLeave={(e) => {
                  if (e.currentTarget) {
                    e.currentTarget.style.backgroundColor = '#fff'
                    const svgElement = e.currentTarget.querySelector('svg')
                    if (svgElement) {
                      svgElement.style.stroke = '#5A5A5A'
                    }
                  }
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='#5A5A5A'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  className='icon icon-tabler icons-tabler-outline icon-tabler-x'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M18 6l-12 12' />
                  <path d='M6 6l12 12' />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div
          className=' scroll-y overflow-auto p-6 mb-4'
          tabIndex={-1}
          style={{height: 'calc(100vh - 40px)'}}
        >
          <form id='kt_risk_form' className='form' onSubmit={handleSubmit}>
            <div className='modal-content'>
              <div className='modal-body'>
                <AddRiskControls id={id} isOpen={showModal} onCancel={handleCloseModal} />

                <div className=''>
                  <div className='card mb-4 p-4' style={{background: '#F6F6F6'}}>
                    {/* <span
                      className='badge rounded-pill  p-1 mt-2'
                      style={{border: '1px solid #C5C5C5', width: '110px'}}
                    >
                      <span className='mx-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='18'
                          height='18'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='#EE3043'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          className='icon icon-tabler icons-tabler-outline icon-tabler-xbox-x'
                        >
                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                          <path d='M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z' />
                          <path d='M9 8l6 8' />
                          <path d='M15 8l-6 8' />
                        </svg>
                      </span>
                      <span className='fw-semibold fs-7 '>Incomplete</span>
                    </span>
                    <div className='fs-6 fw-semibold my-4'>
                      This risk is incomplete. Please fill all the mandatory fields below so that
                      the risk owner can approve.
                    </div> */}
                    {risk_status === 'Incomplete' ? (
                      <>
                        <span
                          className='badge rounded-pill p-1 mt-2'
                          style={{border: '1px solid #C5C5C5', width: '110px'}}
                        >
                          <span className='mx-2'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='18'
                              height='18'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='#EE3043'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className='icon icon-tabler icons-tabler-outline icon-tabler-xbox-x '
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z' />
                              <path d='M9 8l6 8' />
                              <path d='M15 8l-6 8' />
                            </svg>
                          </span>
                          <span className='fs-7'>Incomplete</span>
                        </span>
                        <div className='fs-6 fw-semibold my-4'>
                          This risk is incomplete. Please fill all the mandatory fields below so
                          that the risk owner can approve.
                        </div>
                      </>
                    ) : risk_status === 'Needs approval' ? (
                      <>
                        <span
                          className='badge rounded-pill p-1 mt-2'
                          style={{border: '1px solid #C5C5C5', width: '140px'}}
                        >
                          <span className='mx-2'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='18'
                              height='18'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='#FFA800'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className='icon icon-tabler icons-tabler-outline icon-tabler-file-check'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M14 3v4a1 1 0 0 0 1 1h4' />
                              <path d='M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z' />
                              <path d='M9 15l2 2l4 -4' />
                            </svg>
                          </span>
                          <span className='fs-7'>Needs Approval</span>
                        </span>
                        <div className='fs-6 fw-semibold my-4'>
                          Owner approval is pending for this risk scenario. <strong>Note:</strong>{' '}
                          Please assign owner if not assigned yet.
                        </div>
                        {currentUser?.userName === owner &&
                          (loading ? (
                            <button
                              type='button'
                              // onClick={handleApprove}
                              className='btn btn-sm fs-6'
                              style={{
                                height: '40px',
                                width: '150px',
                                background: '#1CBB8D',
                                color: '#fff',
                              }}
                              disabled
                            >
                              Updating...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </button>
                          ) : (
                            <button
                              type='button'
                              onClick={handleApprove}
                              className='btn btn-sm fs-6'
                              style={{
                                height: '40px',
                                width: '100px',
                                background: '#1CBB8D',
                                color: '#fff',
                              }}
                              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#148363')
                              }
                              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#1CBB8D')
                              }
                            >
                              Approve
                            </button>
                          ))}
                      </>
                    ) : (
                      risk_status === 'Approved' && (
                        <>
                          <span
                            className='badge rounded-pill p-1 mt-2'
                            style={{border: '1px solid #C5C5C5', width: '110px'}}
                          >
                            <span className='mx-2'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#1CBB8D'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-circle-check'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
                                <path d='M9 12l2 2l4 -4' />
                              </svg>
                            </span>
                            <span className='fs-7'>Approved</span>
                          </span>
                          <div className='fs-6 fw-semibold my-4'>
                            This risk scenario has been approved by the owner.
                          </div>
                        </>
                      )
                    )}
                  </div>
                  <div className='form-group mb-4'>
                    <label className='fw-semibold fs-6  required'>Risk name</label>
                    <input
                      type='text'
                      className={`form-control ${formErrors.riskSource ? 'is-invalid' : ''}`}
                      value={riskSource}
                      placeholder='Enter Risk Source'
                      onChange={(e) => {
                        setRiskSource(e.target.value)
                        setFormErrors((prev) => ({...prev, riskSource: ''}))
                      }}
                    />
                    {formErrors.riskSource && (
                      <span className='text-danger'>{formErrors.riskSource}</span>
                    )}
                  </div>
                  <div className='form-group mb-4'>
                    <label className=' fw-semibold fs-6  required'>Risk Description</label>
                    <textarea
                      className={`form-control ${formErrors.description ? 'is-invalid' : ''}`}
                      placeholder='Enter risk description'
                      value={description}
                      id='discription'
                      rows={3}
                      onChange={(e) => {
                        setDescription(e.target.value)
                        setFormErrors((prev) => ({...prev, description: ''}))
                      }}
                    ></textarea>
                    {formErrors.description && (
                      <span className='text-danger'>{formErrors.description}</span>
                    )}
                  </div>

                  <div className='form-group mb-4'>
                    <label className='fw-semibold my-2 fs-6'>Category</label>

                    <select
                      name='category'
                      className={`form-select ${formErrors.category ? 'is-invalid' : ''}`}
                      data-control='select2'
                      data-placeholder='Select Category'
                      onChange={(e) => {
                        setCategory(e.target.value)
                        setFormErrors((prev) => ({...prev, category: ''}))
                      }}
                      value={category}
                    >
                      <option selected>Select category</option>

                      <option value='Access control'>Access control</option>
                      <option value='Artificial intelligence'>Artificial intelligence</option>
                      <option value='Asset management'>Asset management</option>
                      <option value='Business continuity and disaster recovery'>
                        Business continuity and disaster recovery
                      </option>
                      <option value='Communications security'>Communications security</option>
                      <option value='Compliance'>Compliance</option>
                      <option value='Cryptography'>Cryptography</option>
                      <option value='Environmental, social, and governance'>
                        Environmental, social, and governance
                      </option>
                      <option value='Fraud'>Fraud</option>
                      <option value='People operations'>People operations</option>
                      <option value='Incident response manegement'>
                        Incident response management
                      </option>
                      <option value='Information security policies'>
                        Information security policies
                      </option>
                      <option value='Operations security'>Operations security</option>
                      <option value='Information security operations'>
                        Information security operations
                      </option>
                      <option value='Physical and environmental security'>
                        Physical and environmental security
                      </option>
                      <option value='Privacy'>Privacy</option>
                      <option value='Vendor relationships'>Vendor relationships</option>
                      <option value='Software development and acquisition'>
                        Software development and acquisition
                      </option>
                      <option value='Trustworthiness'>Trustworthiness</option>
                    </select>
                    {formErrors.category && (
                      <span className='text-danger'>{formErrors.category}</span>
                    )}
                  </div>

                  <div className='form-group mb-4'>
                    <label className='fw-semibold my-2 fs-6'>Risk Owner</label>

                    <select
                      name='riskOwner'
                      className={`form-select ${formErrors.owner ? 'is-invalid' : ''}`}
                      data-control='select2'
                      data-placeholder='Select Owner'
                      onChange={(e) => {
                        setRiskOwner(e.target.value)
                        setFormErrors((prev) => ({...prev, owner: ''}))
                      }}
                      value={owner || ''}
                    >
                      <option selected value=''>
                        Select owner
                      </option>
                      {owners
                        .filter((user) => user.role === 'Admin')
                        .map((owner) => (
                          <option key={owner.id} value={owner.full_name}>
                            {owner.full_name}
                          </option>
                        ))}
                    </select>
                    {formErrors.owner && <span className='text-danger'>{formErrors.owner}</span>}
                  </div>
                  <div className='separator my-8'></div>

                  <div className='form-group mb-4'>
                    <h4 className='fw-bold required'>Risk Score</h4>
                    <span className='fs-6'>
                      Evaluate the risk based on event likelihood and its potential impact on your
                      organization with risk scores.
                    </span>
                  </div>
                  <div className='card p-4 mb-8' style={{background: '#F6F6F6'}}>
                    <div className='form-group mb-4'>
                      <div className='d-flex justify-content-between'>
                        <label className='fw-semibold fs-6'>
                          {' '}
                          <span className=' required'>Inherent Risk</span>
                          <OverlayTrigger
                            placement='right'
                            overlay={
                              <Tooltip id='tooltip-top'>
                                <span>
                                  Inherent risks are vulnerabilities or potential threats that exist
                                  within an organization before any controls or mitigation measures
                                  are implemented.
                                </span>
                              </Tooltip>
                            }
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='#5A5A5A'
                              stroke-width='2'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                              <path d='M12 9h.01' />
                              <path d='M11 12h1v4h1' />
                            </svg>
                          </OverlayTrigger>
                        </label>{' '}
                        <span
                          className='badge p-2 rounded-pill'
                          style={{border: '1px solid #C5C5C5'}}
                        >
                          <span
                            className={`badge border rounded-circle mx-1 `}
                            style={{
                              width: '12px',
                              height: '12px',
                              padding: '2px',

                              ...getBadgeClass(
                                Number(inherent_likelihood) * Number(inherent_impact)
                              ),
                            }}
                          >
                            {' '}
                          </span>
                          <span className='fw-semibold  fs-7 mx-1'>
                            {getRiskLevelText(
                              Number(inherent_likelihood) * Number(inherent_impact)
                            )}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className=' mb-4'>
                      <h6 className='fw-semibold mb-3'>
                        Likelihood{' '}
                        <OverlayTrigger
                          placement='right'
                          overlay={
                            <Tooltip id='tooltip-top'>
                              <span>
                                Likelihood estimates how probable it is that a risk event will
                                occur. Rate the chance of this risk happening based on historical
                                data, current conditions, and expert judgment.
                              </span>
                            </Tooltip>
                          }
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#5A5A5A'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                            <path d='M12 9h.01' />
                            <path d='M11 12h1v4h1' />
                          </svg>
                        </OverlayTrigger>
                      </h6>
                      <div style={{padding: '10px 0 10px'}}>
                        <ReactSlider
                          className='mx-0'
                          marks
                          min={1}
                          max={5}
                          step={1}
                          value={inherent_likelihood}
                          onChange={(value) => setInherentLikelihood(value)}
                          renderTrack={(props, state) => {
                            const {index, value} = state
                            setInherentLikelihood(value)
                            const percentage = ((value - 1) / (5 - 1)) * 100
                            console.log(index, value)
                            return (
                              <div
                                {...props}
                                style={{
                                  ...props.style,
                                  height: '6px',
                                  top: '0',
                                  background: index === 0 ? '#20c997' : '#e9ecef',
                                  borderRadius: '3px',
                                }}
                              />
                            )
                          }}
                          renderThumb={(props) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: '20px',
                                width: '20px',
                                backgroundColor: 'white',
                                border: '2px solid #20c997',
                                borderRadius: '50%',
                                cursor: 'grab',
                                top: '-8px',
                                zIndex: 0,
                              }}
                            />
                          )}
                          renderMark={(props) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                width: '4px',
                                height: '12px',
                                backgroundColor: '#F6F6F6',
                                top: '16px',
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className='d-flex justify-content-between mx-3'>
                        {[1, 2, 3, 4, 5].map((num) => (
                          <span key={num} className='text-bold' style={{fontSize: '12px'}}>
                            {num}
                          </span>
                        ))}
                      </div>
                    </div>

                    <div className=' mb-4'>
                      <h6 className='fw-semibold mb-3'>
                        Impact{' '}
                        <OverlayTrigger
                          placement='right'
                          overlay={
                            <Tooltip id='tooltip-top'>
                              <span>
                                Impact measures the severity of consequences if a risk event occurs.
                                Evaluate how this risk could affect your organization's objectives,
                                operations, finances, or reputation.
                              </span>
                            </Tooltip>
                          }
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#5A5A5A'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                            <path d='M12 9h.01' />
                            <path d='M11 12h1v4h1' />
                          </svg>
                        </OverlayTrigger>
                      </h6>
                      <div style={{padding: '10px 0 10px'}}>
                        <ReactSlider
                          className='mx-0'
                          marks
                          min={1}
                          max={5}
                          step={1}
                          value={inherent_impact}
                          onChange={(value) => setInherentImpact(value)}
                          renderTrack={(props, state) => {
                            const {index, value} = state
                            setInherentImpact(value)
                            const percentage = ((value - 1) / (5 - 1)) * 100
                            console.log(index, value)
                            return (
                              <div
                                {...props}
                                style={{
                                  ...props.style,
                                  height: '6px',
                                  top: '0',
                                  background: index === 0 ? '#20c997' : '#e9ecef',
                                  borderRadius: '3px',
                                }}
                              />
                            )
                          }}
                          renderThumb={(props) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: '20px',
                                width: '20px',
                                backgroundColor: 'white',
                                border: '2px solid #20c997',
                                borderRadius: '50%',
                                cursor: 'grab',
                                top: '-8px',
                                zIndex: 0,
                              }}
                            />
                          )}
                          renderMark={(props) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                width: '4px',
                                height: '12px',
                                backgroundColor: '#F6F6F6',
                                top: '14px',
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className='d-flex justify-content-between mx-3'>
                        {[1, 2, 3, 4, 5].map((num) => (
                          <span key={num} className='text-bold' style={{fontSize: '12px'}}>
                            {num}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className='card p-4 ' style={{background: '#F6F6F6'}}>
                    <div className='form-group mb-4'>
                      <div className='d-flex justify-content-between'>
                        <label className='fw-semibold fs-6 required'>
                          {' '}
                          <span className=' required'>Residual Risk</span>
                          <OverlayTrigger
                            placement='right'
                            overlay={
                              <Tooltip id='tooltip-top'>
                                <span>
                                  Residual Risk represents the remaining risk after control measures
                                  have been implemented.
                                </span>
                              </Tooltip>
                            }
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='#5A5A5A'
                              stroke-width='2'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                              <path d='M12 9h.01' />
                              <path d='M11 12h1v4h1' />
                            </svg>
                          </OverlayTrigger>
                        </label>{' '}
                        <span
                          className='badge p-2 rounded-pill'
                          style={{border: '1px solid #C5C5C5'}}
                        >
                          <span
                            className={`badge border rounded-circle mx-1 `}
                            style={{
                              width: '12px',
                              height: '12px',
                              padding: '2px',

                              ...getBadgeClass(
                                Number(residual_likelihood) * Number(residual_impact)
                              ),
                            }}
                          >
                            {' '}
                          </span>
                          <span className='fw-semibold  fs-7 mx-1'>
                            {getRiskLevelText(
                              Number(residual_likelihood) * Number(residual_impact)
                            )}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className=' mb-4'>
                      <h6 className='fw-semibold mb-3'>
                        Likelihood{' '}
                        <OverlayTrigger
                          placement='right'
                          overlay={
                            <Tooltip id='tooltip-top'>
                              <span>
                                Estimate how probable the risk event is now, considering your
                                current control measures. Has the chance of occurrence decreased?
                              </span>
                            </Tooltip>
                          }
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#5A5A5A'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                            <path d='M12 9h.01' />
                            <path d='M11 12h1v4h1' />
                          </svg>
                        </OverlayTrigger>
                      </h6>
                      <div style={{padding: '10px 0 10px'}}>
                        <ReactSlider
                          className='mx-0'
                          marks
                          min={1}
                          max={5}
                          step={1}
                          value={residual_likelihood}
                          onChange={(value) => setResidualLikelihood(value)}
                          renderTrack={(props, state) => {
                            const {index, value} = state
                            setResidualLikelihood(value)
                            const percentage = ((value - 1) / (5 - 1)) * 100
                            console.log(index, value)
                            return (
                              <div
                                {...props}
                                style={{
                                  ...props.style,
                                  height: '6px',
                                  top: '0',
                                  background: index === 0 ? '#20c997' : '#e9ecef',
                                  borderRadius: '3px',
                                }}
                              />
                            )
                          }}
                          renderThumb={(props) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: '20px',
                                width: '20px',
                                backgroundColor: 'white',
                                border: '2px solid #20c997',
                                borderRadius: '50%',
                                cursor: 'grab',
                                top: '-8px',
                                zIndex: 0,
                              }}
                            />
                          )}
                          renderMark={(props) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                width: '4px',
                                height: '12px',
                                backgroundColor: '#F6F6F6',
                                top: '14px',
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className='d-flex justify-content-between mx-3'>
                        {[1, 2, 3, 4, 5].map((num) => (
                          <span key={num} className='text-bold' style={{fontSize: '12px'}}>
                            {num}
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className=' mb-4'>
                      <h6 className='fw-semibold mb-3'>
                        Impact{' '}
                        <OverlayTrigger
                          placement='right'
                          overlay={
                            <Tooltip id='tooltip-top'>
                              <span>
                                Evaluate the potential consequences if the risk occurs, taking into
                                account your mitigation strategies. Has the severity of the impact
                                been reduced?
                              </span>
                            </Tooltip>
                          }
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#5A5A5A'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                            <path d='M12 9h.01' />
                            <path d='M11 12h1v4h1' />
                          </svg>
                        </OverlayTrigger>
                      </h6>
                      <div style={{padding: '10px 0 10px'}}>
                        <ReactSlider
                          className='mx-0'
                          marks
                          min={1}
                          max={5}
                          step={1}
                          value={residual_impact}
                          onChange={(value) => setResidualImpact(value)}
                          renderTrack={(props, state) => {
                            const {index, value} = state
                            setResidualImpact(value)
                            const percentage = ((value - 1) / (5 - 1)) * 100
                            console.log(index, value)
                            return (
                              <div
                                {...props}
                                style={{
                                  ...props.style,
                                  height: '6px',
                                  top: '0',
                                  background: index === 0 ? '#20c997' : '#e9ecef',
                                  borderRadius: '3px',
                                }}
                              />
                            )
                          }}
                          renderThumb={(props) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: '20px',
                                width: '20px',
                                backgroundColor: 'white',
                                border: '2px solid #20c997',
                                borderRadius: '50%',
                                cursor: 'grab',
                                top: '-8px',
                                zIndex: 0,
                              }}
                            />
                          )}
                          renderMark={(props) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                width: '4px',
                                height: '12px',
                                backgroundColor: '#F6F6F6',
                                top: '14px',
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className='d-flex justify-content-between mx-3'>
                        {[1, 2, 3, 4, 5].map((num) => (
                          <span key={num} className='text-bold' style={{fontSize: '12px'}}>
                            {num}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='separator my-8'></div>
            <div className='form-group mb-4'>
              <h4 className='fw-bold  required'>Treatment</h4>
              <span className='fs-6'>
                Select a treatment plan for this risk based on your risk source.
              </span>
              {/* <div className='d-flex justify-content-start mt-4'>
                <div className='form-check form-check-custom form-check-solid mx-2 p-1'>
                  <input
                    className='form-check-input'
                    type='radio'
                    value='Mitigate'
                    id='treatmentMitigate'
                    name='treatment'
                    checked={treatment === 'Mitigate'}
                    onChange={handleChange}
                  />
                </div>
                <span>
                  <span className='text-dark fw-semibold  d-block fs-6'>Mitigate</span>
                  <span className='d-block fs-6' style={{color: '#5A5A5A'}}>
                    Implement controls to reduce the risk's impact or likelihood
                  </span>
                </span>
              </div>

              <div className='d-flex justify-content-start mt-4'>
                <div className='form-check form-check-custom form-check-solid mx-2 p-1'>
                  <input
                    className='form-check-input'
                    type='radio'
                    value='Avoid'
                    id='treatmentAvoid'
                    name='treatment'
                    checked={treatment === 'Avoid'}
                    onChange={handleChange}
                  />
                </div>
                <span>
                  <span className='text-dark fw-semibold  d-block fs-6'>Avoid</span>
                  <span className='d-block fs-6' style={{color: '#5A5A5A'}}>
                    Stop activities that create risk for your organization
                  </span>
                </span>
              </div>

              <div className='d-flex justify-content-start mt-4'>
                <div className='form-check form-check-custom form-check-solid mx-2 p-1'>
                  <input
                    className='form-check-input'
                    type='radio'
                    value='Transfer'
                    id='treatmentTransfer'
                    name='treatment'
                    checked={treatment === 'Transfer'}
                    onChange={handleChange}
                  />
                </div>
                <span>
                  <span className='text-dark fw-semibold  d-block fs-6'>Transfer</span>
                  <span className='d-block fs-6' style={{color: '#5A5A5A'}}>
                    Shift responsibility for the risk to another party, such as an insurer
                  </span>
                </span>
              </div>

              <div className='d-flex justify-content-start mt-4'>
                <div className='form-check form-check-custom form-check-solid mx-2 p-1'>
                  <input
                    className='form-check-input'
                    type='radio'
                    value='Accept'
                    id='treatmentAccept'
                    name='treatment'
                    checked={treatment === 'Accept'}
                    onChange={handleChange}
                  />
                </div>
                <span>
                  <span className='text-dark fw-semibold  d-block fs-6'>Accept</span>
                  <span className='d-block fs-6' style={{color: '#5A5A5A'}}>
                    Keep the risk as is without taking additional action
                  </span>
                </span>
              </div> */}

              {['Mitigate', 'Avoid', 'Transfer', 'Accept'].map((value) => (
                <div key={value} className='d-flex justify-content-start mt-4 mb-2'>
                  <div className='form-check form-check-custom form-check-solid mx-2 p-1'>
                    <input
                      className={`form-check-input `}
                      type='radio'
                      value={value}
                      id={`treatment${value}`}
                      name='treatment'
                      checked={treatment === value}
                      onChange={handleChange}
                      style={formErrors.treatment ? {border: '1px solid red'} : {}}
                    />
                  </div>
                  <span>
                    <span className='text-dark fw-semibold d-block fs-6'>{value}</span>
                    <span className='d-block fs-6' style={{color: '#5A5A5A'}}>
                      {value === 'Mitigate' &&
                        "Implement controls to reduce the risk's impact or likelihood"}
                      {value === 'Avoid' &&
                        'Stop activities that create risk for your organization'}
                      {value === 'Transfer' &&
                        'Shift responsibility for the risk to another party, such as an insurer'}
                      {value === 'Accept' && 'Keep the risk as is without taking additional action'}
                    </span>
                  </span>
                </div>
              ))}
              {formErrors.treatment && <span className='text-danger'>{formErrors.treatment}</span>}
            </div>

            <div className='separator my-8'></div>
            <div className='my-4 d-flex justify-content-between'>
              <div className='mb-4'>
                <div>
                  <h4 className='fw-bold'>
                    Map Controls{' '}
                    <span
                      className='fs-6'
                      style={{color: '#78829D', fontWeight: '500', paddingLeft: '5px'}}
                    >
                      (Optional)
                    </span>
                  </h4>
                  <div className='fs-6 mb-2'>
                    Select controls that may reduce the residual risk score.
                  </div>
                </div>
              </div>

              <button
                className='btn btn-sm fs-6'
                onClick={handleboard}
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#148363')
                }
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                }
                style={{
                  backgroundColor: '#1CBB8D',
                  color: '#fff',
                  width: '130px',
                  height: '40px',
                  borderRadius: '4px',
                  marginRight: '0px',
                  transition: 'background-color 0.3s',
                }}
              >
                Add Controls
              </button>
            </div>

            <div className='card mb-4' style={{background: '#F6F6F6'}}>
              <div
                className='card-header fs-6 fw-bold p-8 '
                style={{borderBottom: '1px solid #C5C5C5'}}
              >
                {' '}
                CONTROLS
              </div>

              <div className='card-body p-0 px-8'>
                {/* {addedControls && addedControls.length > 0 ? (
                  addedControls.map((control) => ( */}
                <table className='table'>
                  {/* <thead>
                        <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        </tr>
                      </thead> */}
                  <tbody>
                    {addedControls && addedControls.length > 0 ? (
                      addedControls.map((control) => (
                        <tr key={control.id} style={{borderBottom: '1px dotted #C5C5C5'}}>
                          <td
                            className='fs-6 fw-semibold py-8 text-hover-primary'
                            style={{cursor: 'pointer'}}
                            key={control.id}
                            onClick={() => handleRiskClick(control.id)}
                          >
                            {control.control_title}
                          </td>
                          <td className='fs-6 fw-semibold py-8'>
                            {control.control_framework_mappings}
                          </td>
                          {/* <td className='fs-6 fw-semibold py-8'>
                              <button
                                onClick={() => handleDeleteControl(control.id)}
                                className='btn btn-sm btn-light-danger p-0'
                                style={{marginLeft: '10px'}}
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='currentColor'
                                  strokeWidth='2'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-square-x'
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z' />
                                  <path d='M9 9l6 6m0 -6l-6 6' />
                                </svg>
                              </button>
                            </td> */}
                          <td className='fs-6 fw-semibold py-6'>
                            <div
                              className='btn btn-icon btn-sm btn-active-light-primary'
                              onClick={() => handleDeleteControl(control)}
                              style={{
                                transition: 'all 0.2s ease',
                                // border:'2px solid #C5C5C5'
                              }}
                              onMouseEnter={(e) => {
                                if (e.currentTarget) {
                                  e.currentTarget.style.backgroundColor = '#F6F6F6'
                                  const svgElement = e.currentTarget.querySelector('svg')
                                  if (svgElement) {
                                    svgElement.style.stroke = '#1CBB8D'
                                  }
                                }
                              }}
                              onMouseLeave={(e) => {
                                if (e.currentTarget) {
                                  e.currentTarget.style.backgroundColor = '#F6F6F6'
                                  const svgElement = e.currentTarget.querySelector('svg')
                                  if (svgElement) {
                                    svgElement.style.stroke = '#5A5A5A'
                                  }
                                }
                              }}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#5E6274'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-x '
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M18 6l-12 12' />
                                <path d='M6 6l12 12' />
                              </svg>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <>
                        <div
                          className='card-body'
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#5A5A5A'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-adjustments-horizontal'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M14 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
                            <path d='M4 6l8 0' />
                            <path d='M16 6l4 0' />
                            <path d='M8 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
                            <path d='M4 12l2 0' />
                            <path d='M10 12l10 0' />
                            <path d='M17 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
                            <path d='M4 18l11 0' />
                            <path d='M19 18l1 0' />
                          </svg>
                          <br />
                          <span className='fs-6 fw-semibold'>No Controls added</span>
                        </div>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div
              className='offcanvas-footer d-flex justify-content-end  border-top py-4 mb-4'
              style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
            >
              <button
                type='button'
                className='btn btn-light mx-2 mb-2'
                style={{height: '40px'}}
                onClick={onCancel}
              >
                Cancel
              </button>
              {isLoading ? (
                <button
                  type='submit'
                  className='btn btn-sm'
                  style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                  disabled
                >
                  <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                  Save
                </button>
              ) : (
                <button
                  type='submit'
                  className='btn btn-sm'
                  style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                >
                  Save
                </button>
              )}
            </div>
            {/* </div> */}
          </form>
        </div>
      </Drawer>
    </>
  )
}

export {EditRiskModal}
