import {collection, doc, onSnapshot, updateDoc} from 'firebase/firestore'
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import db from '../../../../services/firebase'
import {useAuth} from '../../../modules/auth'
import {toast} from 'react-toastify'

interface ViewProps {
  isOpen: boolean
  onClose: () => void
  ids: string[]
}

const AssignOwner: React.FC<ViewProps> = ({isOpen, onClose, ids}) => {
  const [owner, setOwner] = useState('')
  const [owners, setOwners] = useState<any[]>([])
  const {currentUser} = useAuth()

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setOwners(risksData)
    })

    return unsubscribe
  }, [])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)

      const updatePromises = ids.map((policyId) => {
        const policyDocRef = doc(tenantRef, 'policies', policyId)
        return updateDoc(policyDocRef, {owner})
      })

      await Promise.all(updatePromises)

      toast.success(`Assigned owner to ${ids.length} policy successfully`, {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })
      onClose()
    } catch (error) {
      console.error('Error assigning owner:', error)
    }
  }

  return (
    <Modal show={isOpen} onHide={onClose} backdropClassName='bg-opacity-1' centered>
      <Modal.Header closeButton>
        <Modal.Title>Assign Owner to {ids.length} Policies</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-0 mt-4'>
        <form className='px-6' onSubmit={handleSubmit}>
          <div className='fs-6 mb-4 fw-semibold'>
            Policy owner will be responsible for reviewing & approving documents.{' '}
            <strong>Warning:</strong> This action will assign new owner for all selected policies &
            override any existing owners.
          </div>

          <div className='fv-row  '>
            <label className=' fw-semibold fs-6 mb-1'>Assign to</label>
            <select
              data-placeholder='Select Owner'
              onChange={(e) => setOwner(e.target.value)}
              value={owner}
              className='form-select  mb-3 mb-lg-0 w-100'
            >
              <option value='' selected>
                Select Owner
              </option>
              {owners
                .filter((user) => user.role === 'Admin')
                .map((owner) => (
                  <option key={owner.id} value={owner.full_name}>
                    {owner.full_name}
                  </option>
                ))}
            </select>
          </div>

          <div className='modal-footer  p-0 my-4 pt-4'>
            <button
              className='btn btn-sm btn-light mx-4'
              onClick={onClose}
              // disabled={activities.length === 0}
              style={{
                width: '80px',
                height: '40px',
                borderRadius: '4px',
                transition: 'background-color 0.3s',
              }}
            >
              Cancel
            </button>

            <button
              className='btn btn-sm'
              type='submit'
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#148363')
              }
              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#1CBB8D')
              }
              // disabled={activities.length === 0}
              style={{
                backgroundColor: '#1CBB8D',
                color: '#fff',
                height: '40px',
                borderRadius: '4px',

                transition: 'background-color 0.3s',
              }}
            >
              Assign
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default AssignOwner
