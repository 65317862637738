import React, {useEffect, useState} from 'react'
import {OnDashPageTitle} from '../cores/OnDashPageData'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageChangeEvent, Viewer, Worker} from '@react-pdf-viewer/core'
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import {getDownloadURL, listAll, ref} from 'firebase/storage'
import {storage} from '../../../services/firebase'

import trainingServices from '../services/training.services'
import {useAuth} from '../../modules/auth'
import {Link, useNavigate} from 'react-router-dom'
interface CustomFile {
  id?: string
  name: string
  // base64String:string
  downloadURL: string
  isChecked?: boolean
  tenantId?: string
  uid?: string
  PCI?: CustomFile[]
}

const PCI = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin()

  const [selectedFileIndex, setSelectedFileIndex] = useState(0)
  const [fileData, setFileData] = useState<CustomFile[]>([])
  const [selectedPDFUrl, setSelectedPDFUrl] = useState<string | null>(null)
  const [allChecked, setAllChecked] = useState(false)
  const {currentUser} = useAuth()
  const [allData, setAllData] = useState<CustomFile[]>([])
  const [filteredData, setFilteredData] = useState<CustomFile[]>([])
  const [completed, setCompleted] = useState(false)
  const navigate = useNavigate()
  const [lastPage, setLastPage] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await trainingServices.getAllOnboarding()
        const data: CustomFile[] = []
        querySnapshot.forEach((doc) => {
          data.push({id: doc.id, ...doc.data()} as CustomFile)
        })

        setAllData(data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    async function fetchData() {
      const existingCollection = await trainingServices.getAllOnboarding()

      if (existingCollection) {
        for (const doc of existingCollection.docs) {
          const data = doc.data()

          if (data.tenantId === currentUser?.tenantId && data.uid === currentUser?.uid) {
            const docId = doc.id
            const Detail = await trainingServices.getTraining(docId)
            console.log('Detail:', Detail.data())
            const Training = (Detail.data() as any)?.PCI || []

            Training.length && Training.forEach((file) => setCompleted(file.isChecked))
          }
        }
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (fileData.length > 0) {
      setSelectedPDFUrl(fileData[selectedFileIndex].downloadURL)
    }
  }, [fileData, selectedFileIndex])
  useEffect(() => {
    const fetchDataFromFolder = async () => {
      const folderPath = '/PCI_training_pdf/' // Specify the path to your folder in Firebase Storage

      try {
        const folderRef = ref(storage, folderPath)
        const fileList = await listAll(folderRef)
        console.log('File List:', fileList.items[0])

        const filesPromise = fileList.items.map(async (file) => {
          const downloadURL = await getDownloadURL(file) // Get the download URL for each file
          return {name: file.name, downloadURL} // Create an object with file name and download URL
        })

        // Wait for all promises to resolve
        const filesData = await Promise.all(filesPromise)

        setFileData(filesData)
      } catch (error) {
        console.error('Error fetching data from folder:', error)
      }
    }

    fetchDataFromFolder()
  }, [])
  console.log('fileData:', fileData)
  const handleNameClick = (pdfUrl: string, index) => {
    setSelectedPDFUrl(pdfUrl)
    setSelectedFileIndex(index)
  }

  const handleNextChapterClick = () => {
    if (selectedFileIndex < fileData.length - 1) {
      const nextFileIndex = selectedFileIndex + 1
      console.log('Next file index:', nextFileIndex)
      setSelectedFileIndex(nextFileIndex)
      const nextFile = fileData[nextFileIndex]
      if (nextFile && nextFile.downloadURL) {
        setSelectedPDFUrl(nextFile.downloadURL)
      } else {
        console.error('Invalid file data:', nextFile)
      }
    }
  }

  const handlePreviousChapterClick = () => {
    if (selectedFileIndex > 0) {
      const prevFileIndex = selectedFileIndex - 1
      setSelectedFileIndex(prevFileIndex)
      const prevFile = fileData[prevFileIndex]
      if (prevFile && prevFile.downloadURL) {
        setSelectedPDFUrl(prevFile.downloadURL)
      } else {
        console.error('Invalid file data:', prevFile)
      }
    }
  }

  // const handleCheckboxChange = async (index: number) => {
  //   const updatedFileData = fileData.map((file, idx) => {
  //     if (idx === index) {
  //       return {...file, isChecked: !file.isChecked}
  //     }
  //     return file
  //   })
  //   setFileData(updatedFileData)

  //   const allChecked = updatedFileData.every((file) => file.isChecked)
  //   setAllChecked(allChecked)

  //   // try {
  //   //   if (allChecked) {
  //   //     await trainingServices.addTraining(
  //   //       updatedFileData,
  //   //       currentUser?.tenantId || '',
  //   //       currentUser?.uid || ''
  //   //     )
  //   //     console.log('Successfully added training data to the database.')
  //   //   } else {
  //   //     console.log('Not all items are checked. Data will not be added to the database.')
  //   //   }
  //   // } catch (error) {
  //   //   console.error('Error updating or adding training data in the database:', error)
  //   // }
  //   try {
  //     if (allChecked) {
  //       // Check if there is an existing collection with the same tenant ID and user ID
  //       const existingCollection = await trainingServices.getAllOnboarding()

  //       if (existingCollection) {
  //         existingCollection.forEach(async(doc) => {
  //           const docId = doc.id
  //           const data = doc.data()

  //           if (data.tenantId === currentUser?.tenantId && data.uid === currentUser?.uid) {
  //             console.log('Document ID:', docId)
  //             console.log(data)
  //             console.log('tenantId', data.tenantId)
  //             await trainingServices.updatePCITraining(docId, updatedFileData)
  //           } else {
  //             console.log('No data')
  //           }
  //         })
  //         console.log('Successfully updated training data in the database.')
  //       } else {
  //         await trainingServices.addPCITraining(
  //           updatedFileData,
  //           currentUser?.tenantId || '',
  //           currentUser?.uid || ''
  //         )
  //         console.log('Successfully added training data to the database.')
  //       }
  //     } else {
  //       console.log('Not all items are checked. Data will not be added to the database.')
  //     }
  //   } catch (error) {
  //     console.error('Error updating or adding training data in the database:', error)
  //   }
  // }

  const handleCheckboxChange = async (index: number) => {
    const updatedFileData = fileData.map((file, idx) => {
      if (idx === index) {
        return {...file, isChecked: !file.isChecked}
      }
      return file
    })
    setFileData(updatedFileData)

    const allChecked = updatedFileData.every((file) => file.isChecked)
    setAllChecked(allChecked)

    try {
      if (allChecked) {
        // Check if there is an existing collection with the same tenant ID and user ID
        const existingCollection = await trainingServices.getAllOnboarding()

        if (existingCollection) {
          let isDataUpdated = false

          for (const doc of existingCollection.docs) {
            const data = doc.data()

            if (data.tenantId === currentUser?.tenantId && data.uid === currentUser?.uid) {
              const docId = doc.id
              await trainingServices.updatePCITraining(docId, updatedFileData)
              isDataUpdated = true
              break // Exit the loop after updating
            }
            console.log('Successfully updated training data to the database.')
            setTimeout(() => {
              navigate('/training')
            }, 5000)
          }

          if (!isDataUpdated) {
            console.log('No existing data. Adding new data')
            await trainingServices.addPCITraining(
              updatedFileData,
              currentUser?.tenantId || '',
              currentUser?.uid || ''
            )
            console.log('Successfully added training data to the database.')
            setTimeout(() => {
              navigate('/training')
            }, 5000)
          }
        }
      } else {
        console.log('Not all items are checked. Data will not be added to the database.')
      }
    } catch (error) {
      console.error('Error updating or adding training data in the database:', error)
    }
  }
  useEffect(() => {
    // Perform filtering and state update within the useEffect hook
    const filteredData = allData.filter(
      (file) => file.tenantId === currentUser?.tenantId && file.uid === currentUser?.uid
    )
    let isCheckeda = filteredData.length > 0 && filteredData[0]?.PCI?.some((file) => file.isChecked)
    setFilteredData(filteredData)
  }, [allData, currentUser])

  const handlePageChange = (e: PageChangeEvent) => {
    // Get the current page number
    const currentPage = e.currentPage + 1
    // Get the total number of pages
    const totalPages = e.doc.numPages
    console.log(currentPage, totalPages)

    // Check if the current page number equals the total number of pages
    if (currentPage === totalPages) {
      console.log('Last page reached')
      console.log(currentPage, totalPages)
      setLastPage(true)
      // Perform actions or display UI indicators for last page reached
    } else {
      console.log('Not last page')
      setLastPage(false)
    }
  }

  return (
    <>
      <div className='d-flex align-items-center mb-2 mt-0'>
        <Link to='/training'>
          <img src={toAbsoluteUrl(`/media/logos/leftButton.svg`)} alt='back' className='p-2' />
        </Link>
        <Link to='/training' style={{textDecoration: 'none', color: 'inherit'}}>
          <label style={{cursor: 'pointer', paddingLeft: '0px'}}>
            <strong>Back To Training</strong>
          </label>
        </Link>
      </div>
      <OnDashPageTitle
        icon='PCIT.svg'
        title='PCI Training'
        desc='Safeguard sensitive payment data. '
      />

      <div className='card'>
        <div className='row '>
          <div className='col-md-12'>
            <div className='row m-0 '>
              {/* Left Column (Recent Posts) */}
              <div className='col-md-3 border-end h-100 ' style={{minHeight: '500px'}}>
                <h2 className='text-dark mb-1 mt-3 ml-4 p-4'>Contents</h2>

                <hr />
                {fileData.map((file, index) => (
                  <div
                    className={`d-flex align-items-start mb-2 p-3 ${
                      selectedPDFUrl === file.downloadURL ? 'bg-light cursor-pointer' : ''
                    }`}
                    key={index}
                    onClick={() => handleNameClick(file.downloadURL, index)}
                  >
                    {filteredData && filteredData.length > 0 ? (
                      <input
                        type='checkbox'
                        className='mt-2 form-check-input p-3'
                        checked={allChecked || filteredData[0]?.PCI?.some((file) => file.isChecked)}
                        onClick={() => handleCheckboxChange(index)}
                        disabled
                      />
                    ) : (
                      <input
                        type='checkbox'
                        className='mt-2 form-check-input p-3'
                        // checked={isChecked }
                        onClick={() => handleCheckboxChange(index)}
                        disabled
                      />
                    )}

                    <div className='ms-4 cursor-pointer'>
                      <div className='text-dark fw-bold text-hover-primary fs-5 mt-1'>
                        {file.name}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* {fileData.map((file, index) => ( */}
              <div className='col-md-9 px-0'>
                <div className='mb-0'>
                  <div
                    className='mb-0'
                    // style={{marginRight: '-12px', marginLeft: '-13px', marginTop: '-13px'}}
                  >
                    <div className='overlay '>
                      {selectedPDFUrl && (
                        <div className='container-fluid'>
                          <div className='row'>
                            <div className='col p-0' style={{height: 600}}>
                              <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
                                <Viewer
                                  fileUrl={selectedPDFUrl}
                                  plugins={[defaultLayoutPluginInstance]}
                                  onPageChange={handlePageChange}
                                />
                              </Worker>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='border p-4 ml-3 '>
                  {lastPage ? (
                    <button
                      type='button'
                      className='btn btn-primary me-3 btn-sm'
                      onClick={() => handleCheckboxChange(selectedFileIndex)}
                    >
                      <i className='ki-duotone ki-check fs-2'></i>
                      Mark as Complete
                    </button>
                  ) : (
                    <button
                      type='button'
                      className='btn btn-primary me-3 btn-sm'
                      onClick={() => handleCheckboxChange(selectedFileIndex)}
                      disabled
                    >
                      <i className='ki-duotone ki-check fs-2'></i>
                      Mark as Complete
                    </button>
                  )}
                  <span className='p-1 fs-6 text-muted'>
                    {' '}
                    Please note that you are required to read all the training metrial in order to
                    complete the training.{' '}
                  </span>
                </div>
                {/* {fileData && (
                  <div className='d-flex justify-content-between p-4'>
                    {selectedFileIndex > 0 && (
                      <img
                        src={toAbsoluteUrl('/media/buttons/PreviousChapter.svg')}
                        alt=''
                        className='p-1 py-3'
                        onClick={handlePreviousChapterClick}
                      />
                    )}
                    {selectedFileIndex < fileData.length - 1 && (
                      <img
                        src={toAbsoluteUrl('/media/buttons/NextChapter.svg')}
                        alt=''
                        className='p-1'
                        onClick={handleNextChapterClick}
                      />
                    )}
                  </div>
                )}
                <div className='col-md-12 d-flex justify-content-end  p-4'>
                  {(allChecked || completed) && (
                    <a href='/training/PCISecure'>
                      <button
                        className='btn btn-primary btn-sm '
                        // onClick={() => (window.location.href = '/training/PCI')}
                      >
                        Go to Another Training
                      </button>
                    </a>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PCI
