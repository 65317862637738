/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'

// import { IdleTimerComponent } from 'react-idle-timer';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()

  const role = currentUser?.role; // Safely extract role from currentUser

  console.log(role);

  // const handleOnIdle = () => {
  //   // Redirect to the authentication page when user becomes idle
  //   // You may use Navigate component to redirect programmatically
  //   // For example: <Navigate to='/auth' />
  //   window.location.href = '/auth';
  // };\

  return (
    
    <BrowserRouter basename={PUBLIC_URL}>
      {/* <IdleTimerComponent
        timeout={1000 * 60 * 1} // 1 minute
        onIdle={handleOnIdle}
      /> */}
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
              <>
                <Route path='/*' element={<PrivateRoutes />} />{
                        role === "Member"?(
                          <Route index element={<Navigate to='/training' />} />
                        ):(
                          <>
                          <Route index element={<Navigate to='/dashboard' />} />
                          </>
                        )
                }
              </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
