/* eslint-disable react/jsx-pascal-case */
import React, {FC, useState, useEffect} from 'react'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'
// import {VendorRisk} from './VendorRisk'
import {DataRoomHeader} from './components/header/DataRoomHeader'
import {KTCard} from '../../../_metronic/helpers'
import {DataRoom} from './DataRoom'
import {set} from 'date-fns'
import {Data_room} from './Data_room'
import {useAuth} from '../../modules/auth'
import {collection, onSnapshot} from 'firebase/firestore'
import db from '../../../services/firebase'
// import {PersonnelManagement} from './PersonnelManagement'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
type FileItemType = {
  id?: string
  name: string
  isFolder: boolean
  fileSize: number
  contentType: string | undefined
  timeCreated: string
  isChecked?: boolean
  tenantId?: string
  uid?: string
  additionalData?: any
  downloadUrl?: string
  folderName: string
  subFolder: string
  fName: string
  fname?: string
}

const DataRoomWrapper: FC = () => {
  const [item, setItem] = useState<FileItemType[]>([])
  const [value, setData] = useState(false)
  const [subFolderClicked, setSubFolderClicked] = useState(false)
  const [folderPath, setFolderPath] = useState('policy_files')
  const [created, setFolderFileCreated] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [totalSize, setTotalSize] = useState(0)
  const {currentUser} = useAuth()

  useEffect(() => {
    // Calculate the total size
    const newTotalSize = item
      .filter(
        (item) =>
          item.tenantId === currentUser?.tenantId &&
          item.uid === currentUser?.uid &&
          item.isFolder !== true
      )
      .reduce((acc, item) => acc + item.fileSize, 0)
    const totalSizeInMB = (newTotalSize / 1e6).toFixed(2)
    // Update the state variable
    setTotalSize(Number(totalSizeInMB))
  }, [item])

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    try {
      const dataroomCollectionRef = collection(db, 'policy_files')
      const unsubscribe = onSnapshot(dataroomCollectionRef, (snapshot) => {
        const policy = snapshot.docs.map((item: any) => ({
          ...item.data(),
          id: item.id,
        })) as FileItemType[]
        setItem(policy)
      })

      // Return the unsubscribe function to stop listening for updates
      return unsubscribe
    } catch (error) {
      console.error('Error getting policies:', error)
    }
  }
  // console.log(totalSize)
  const onNewFolderClicked = () => {
    setData(true)
  }

  const updateData = () => {
    setData(!value)

    setFolderFileCreated(!created)
  }

  const onSubFolderClicked = (item) => {
    setFolderPath(`policy_files/${item.folderName}`)
    setSubFolderClicked(true)
  }

  const onFileUploaded = () => {
    setFolderFileCreated(!created)
  }
  const handleSearchTermChange = (term) => {
    setSearchTerm(term)
  }

  return (
    <>
      <PageTitle
        icon='DataRoomIcon.svg'
        title='Data Room'
        desc={`${totalSize} MB Used of 15 GB`}
        breadcrumbs={usersBreadcrumbs}
      >
        Data Room
      </PageTitle>
      <KTCard>
        <DataRoomHeader
          onHeaderAction={onNewFolderClicked}
          subFoldderClicked={subFolderClicked}
          folderPath={folderPath}
          onFlieFolderCreated={onFileUploaded}
          onSearchTermChange={handleSearchTermChange}
        />
        {/* <DataRoom className='mb-5 mb-xl-8' isNewFolder={value} onFolderCreatedOrCancel={updateData} onSubFolderClicked={onSubFolderClicked} folderPath={folderPath} fileFolderCreated = {created}  searchTerm={searchTerm}/> */}
        {/* <VendorRisk className='card-xl-stretch mb-xl-8' /> */}
        <Data_room
          className='mb-5 mb-xl-8'
          isNewFolder={value}
          onFolderCreatedOrCancel={updateData}
          onSubFolderClicked={onSubFolderClicked}
          folderPath={folderPath}
          fileFolderCreated={created}
          searchTerm={searchTerm}
        />
      </KTCard>
    </>
  )
}

export default DataRoomWrapper
