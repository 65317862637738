import {Route, Routes, Outlet, Navigate, Link, useLocation, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {PolicyListWrapper} from './PolicyList'
import {useEffect, useState} from 'react'
import db from '../../../services/firebase'
import {useAuth} from '../../modules/auth'
import {KTCard} from '../../../_metronic/helpers'
import {PolicyPageTitle} from '../../../_metronic/layout/core/PolicyPageData'
import {PolicyTemplates} from './tab-menu-list/PolicyTemplates'
import {collection, doc, getDocs, onSnapshot} from 'firebase/firestore'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
//adding number of policy template in db
const PolicyPage = () => {
  const [totalPolicy, setTotalPolicy] = useState(0)
  const [activeTab, setActiveTab] = useState('policy-library')
  const {currentUser} = useAuth()
  const location = useLocation()
  const {id} = useParams()

  useEffect(() => {
    // Fetch control data when the component mounts
    if (location.pathname.includes('PolicyTemplates')) {
      console.log('Setting active tab to PolicyTemplates')
      setActiveTab('PolicyTemplates')
    } else {
      console.log('Setting active tab to policy-library')
      setActiveTab('policy-library')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)
    const policyRef = collection(tenantRef, 'Policies')

    // Use getDocs() to get a snapshot of the documents in the collection
    getDocs(policyRef)
      .then((snapshot) => {
        // Get the size of the snapshot (number of documents)
        const count = snapshot.size
        // Update the state with the total number of policies
        setTotalPolicy(count)
      })
      .catch((error) => {
        // Handle any errors
        console.error('Error fetching documents:', error)
      })
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='lists'
          element={
            <>
              <>
                <PolicyPageTitle
                  icon='Policies.svg'
                  title='Policy Library'
                  desc={
                    'Setup polices for your organization. You can upload your existing polices or use from templates and assign owner for approval. '
                  }
                  breadcrumbs={usersBreadcrumbs}
                >
                  Policy Management
                </PolicyPageTitle>

                <KTCard>
                  <PolicyListWrapper />
                </KTCard>
              </>
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/policy-library/lists' />} />
    </Routes>
  )
}

export default PolicyPage
