/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import db from '../../../services/firebase'
import controlServices from '../controls/services/control.services'
import {format} from 'date-fns'
import {Link, useParams, useLocation} from 'react-router-dom'
import {right} from 'glamor'

type Props = {}

const CompleteAssessment: React.FC<Props> = () => {
  const [risk, setRisk] = useState<any | null>(null)
  const location = useLocation()
  const [activeTab, setActiveTab] = useState('details')
  const {id} = useParams()
  console.log('Risk', risk)
  const [dataCount, setDataCount] = useState(0)
  useEffect(() => {
    // Fetch risk data when the component mounts
    if (id) {
      getRisk(id)
    }
  }, [id])

  const getRisk = async (controlId: string) => {
    try {
      const riskRef = db.collection('RiskManagement').doc(controlId) // Replace 'your-collection-name' with your actual Firestore collection name
      const doc = await riskRef.get()

      if (doc.exists) {
        setRisk(doc.data())
      } else {
        // Handle the case where the document doesn't exist
        console.log(`Risk with ID ${controlId} not found.`)
      }
    } catch (error) {
      console.error('Error fetching risk data:', error)
    }
  }

  return (
    <div
      className='card card-flush pb-0 bgi-position-y-center bgi-no-repeat mb-10'
      style={{
        backgroundSize: 'auto calc(100% + 10rem)',
        backgroundPositionX: '100%',
      }}
    >
      {/*begin::Card header*/}

      {risk && (
        <div>
          <div className='card-header pt-5'>
            <div className=''>
              <div className='d-flex justify-content-start flex-column'>
                <span className='card-label fw-bold fs-3'>{risk.riskCode}</span>
                <span className='text-muted fw-semibold fs-5 mb-3'>{risk.riskSource}</span>
              </div>
            </div>

            <div
              className='d-flex justify-content-end mb-5'
              data-kt-filemanager-table-toolbar='base'
            >
              {/*begin::Export*/}
              <button
                type='button'
                className='btn btn-flex btn-light-primary me-3'
                id='kt_file_manager_new_folder'
              >
                <i className='ki-duotone ki-add-folder fs-2'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                Archive
              </button>
              {/*end::Export*/}

              {/*begin::Add customer*/}
              <button
                type='button'
                className='btn btn-flex btn-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_upload'
              >
                Complete Assessment
              </button>
              {/*end::Add customer*/}
            </div>
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className='card-body d-flex flex-column align-items-center justify-content-center'>
            <div
              className='card card-flush pb-0 bgi-position-y-center bgi-no-repeat mb-10'
              style={{
                backgroundSize: 'auto calc(100% + 50rem)',
                backgroundPositionX: '100%',
                width: '80%',
              }}
            >
              {/*begin::Card header*/}
              <div className='card-header pt-10'>
                <div className='d-flex align-items-center'>
                  {/*begin::Title*/}
                  <div className='d-flex flex-column'>
                    <h2 className='mb-1'>Risk Assessment</h2>
                    <span className='mb-5'>
                      For each risk in your register, review the details, and describe the risk and
                      treatment below
                    </span>
                  </div>
                  {/*end::Title*/}
                </div>
              </div>
              {/*end::Card header*/}
            </div>
            {/*begin::Card body*/}
            <div
              className='card-flush pb-0 bgi-position-y-center bgi-no-repeat mb-10'
              style={{width: '80%'}}
            >
              {/*begin::Row*/}
              <div className='row g-5 gx-xl-10 mb-5 mb-xl-10'>
                {/*begin::Col*/}
                <div className='col-md-8 col-lg-8 col-xl-8 col-xxl-3 mb-md-5 mb-xl-10'>
                  {/*begin::Card widget 17*/}
                  <div className='card card-flush h-md-50 mb-5 mb-xl-10' style={{maxHeight: '30%'}}>
                    {/*begin::Header*/}
                    <div className='card-header pt-5'>
                      {/*begin::Title*/}
                      <div className='card-title d-flex flex-column m-0'>
                        {/*begin::Info*/}
                        <div className='d-flex align-items-center'>
                          {/*begin::Currency*/}

                          {/*end::Currency*/}
                          {/*begin::Amount*/}
                          <span className='fs-2 fw-bold text-dark'>Details</span>
                          {/*end::Amount*/}
                          {/*begin::Badge*/}

                          {/*end::Badge*/}
                        </div>
                        {/*end::Info*/}
                      </div>
                      {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Card body*/}
                    <div
                      className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'
                      style={{marginTop: '-20px'}}
                    >
                      {/*begin::Labels*/}
                      <div className='d-flex flex-column content-justify-center flex-row-fluid'>
                        {/*begin::Label*/}
                        <div className='d-flex fw-semibold align-items-center'>
                          {/*begin::Label*/}
                          <div className='text-gray-900 flex-grow-1 me-4'>Assessment</div>
                          {/*end::Label*/}
                        </div>
                        {/*end::Label*/}
                        {/*begin::Label*/}
                        <div className='d-flex fw-semibold align-items-center my-3'>
                          {/*begin::Label*/}
                          <div className='text-gray-900 flex-grow-1 me-4'>Treatment</div>
                          {/*end::Label*/}
                        </div>
                        {/*end::Label*/}
                        {/*begin::Label*/}
                        <div className='d-flex fw-semibold align-items-center'>
                          {/*begin::Label*/}
                          <div className='text-gray-900 flex-grow-1 me-4'>Review</div>
                          {/*end::Label*/}
                        </div>
                        <div
                          style={{
                            borderBottom: '1px solid #D3D3D3',
                            width: '100%',
                            marginTop: '10px',
                          }}
                        ></div>
                        <span className='text-muted mt-3 px-5 text-center'>All changes saved</span>
                        {/*end::Label*/}
                      </div>
                      {/*end::Labels*/}
                    </div>
                    {/*end::Card body*/}
                  </div>
                  {/*end::Card widget 17*/}
                  {/*begin::List widget 26*/}
                  <div className='card card-flush h-lg-50' style={{maxHeight: '30%'}}>
                    {/*begin::Header*/}
                    <div className='card-header pt-5'>
                      {/*begin::Title*/}
                      <h3 className='card-title text-gray-800 fw-bold'>External Links</h3>
                      {/*end::Title*/}
                      {/*begin::Toolbar*/}
                      <div className='card-toolbar'>
                        {/*begin::Menu*/}
                        <button
                          className='btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-overflow='true'
                        >
                          <i className='ki-duotone ki-dots-square fs-1 text-gray-400 me-n1'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                            <span className='path4'></span>
                          </i>
                        </button>
                        {/*begin::Menu 2*/}
                        <div
                          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px'
                          data-kt-menu='true'
                        >
                          {/*begin::Menu item*/}
                          <div className='menu-item px-3'>
                            <div className='menu-content fs-6 text-dark fw-bold px-3 py-4'>
                              Quick Actions
                            </div>
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu separator*/}
                          <div className='separator mb-3 opacity-75'></div>
                          {/*end::Menu separator*/}
                          {/*begin::Menu item*/}
                          <div className='menu-item px-3'>
                            <a href='#' className='menu-link px-3'>
                              New Ticket
                            </a>
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}
                          <div className='menu-item px-3'>
                            <a href='#' className='menu-link px-3'>
                              New Customer
                            </a>
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}
                          <div
                            className='menu-item px-3'
                            data-kt-menu-trigger='hover'
                            data-kt-menu-placement='right-start'
                          >
                            {/*begin::Menu item*/}
                            <a href='#' className='menu-link px-3'>
                              <span className='menu-title'>New Group</span>
                              <span className='menu-arrow'></span>
                            </a>
                            {/*end::Menu item*/}
                            {/*begin::Menu sub*/}
                            <div className='menu-sub menu-sub-dropdown w-175px py-4'>
                              {/*begin::Menu item*/}
                              <div className='menu-item px-3'>
                                <a href='#' className='menu-link px-3'>
                                  Admin Group
                                </a>
                              </div>
                              {/*end::Menu item*/}
                              {/*begin::Menu item*/}
                              <div className='menu-item px-3'>
                                <a href='#' className='menu-link px-3'>
                                  Staff Group
                                </a>
                              </div>
                              {/*end::Menu item*/}
                              {/*begin::Menu item*/}
                              <div className='menu-item px-3'>
                                <a href='#' className='menu-link px-3'>
                                  Member Group
                                </a>
                              </div>
                              {/*end::Menu item*/}
                            </div>
                            {/*end::Menu sub*/}
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}
                          <div className='menu-item px-3'>
                            <a href='#' className='menu-link px-3'>
                              New Contact
                            </a>
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu separator*/}
                          <div className='separator mt-3 opacity-75'></div>
                          {/*end::Menu separator*/}
                          {/*begin::Menu item*/}
                          <div className='menu-item px-3'>
                            <div className='menu-content px-3 py-3'>
                              <a className='btn btn-primary btn-sm px-4' href='#'>
                                Generate Reports
                              </a>
                            </div>
                          </div>
                          {/*end::Menu item*/}
                        </div>
                        {/*end::Menu 2*/}
                        {/*end::Menu*/}
                      </div>
                      {/*end::Toolbar*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className='card-body pt-5'>
                      {/*begin::Item*/}
                      <div className='d-flex flex-stack'>
                        {/*begin::Section*/}
                        <a href='#' className='text-primary fw-semibold fs-6 me-2'>
                          Avg. Client Rating
                        </a>
                        {/*end::Section*/}
                        {/*begin::Action*/}
                        <button
                          type='button'
                          className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                        >
                          <i className='ki-duotone ki-exit-right-corner fs-2'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>
                        </button>
                        {/*end::Action*/}
                      </div>
                      {/*end::Item*/}
                      {/*begin::Separator*/}
                      <div className='separator separator-dashed my-3'></div>
                      {/*end::Separator*/}
                      {/*begin::Item*/}
                      <div className='d-flex flex-stack'>
                        {/*begin::Section*/}
                        <a href='#' className='text-primary fw-semibold fs-6 me-2'>
                          Instagram Followers
                        </a>
                        {/*end::Section*/}
                        {/*begin::Action*/}
                        <button
                          type='button'
                          className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                        >
                          <i className='ki-duotone ki-exit-right-corner fs-2'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>
                        </button>
                        {/*end::Action*/}
                      </div>
                      {/*end::Item*/}
                      {/*begin::Separator*/}
                      <div className='separator separator-dashed my-3'></div>
                      {/*end::Separator*/}
                      {/*begin::Item*/}
                      <div className='d-flex flex-stack'>
                        {/*begin::Section*/}
                        <a href='#' className='text-primary fw-semibold fs-6 me-2'>
                          Google Ads CPC
                        </a>
                        {/*end::Section*/}
                        {/*begin::Action*/}
                        <button
                          type='button'
                          className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                        >
                          <i className='ki-duotone ki-exit-right-corner fs-2'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>
                        </button>
                        {/*end::Action*/}
                      </div>
                      {/*end::Item*/}
                    </div>
                    {/*end::Body*/}
                  </div>
                  {/*end::LIst widget 26*/}
                </div>
                {/*end::Col*/}
                {/*begin::Col*/}
                <div className='col-xxl-9'>
                  {/*begin::Engage widget 10*/}
                  <div className='card card-flush h-md-100'>
                    {/*begin::Body*/}
                    <div className='card-body d-flex flex-column justify-content-between pt-3 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0'>
                      {/*begin::Wrapper*/}
                      <div className='mb-10'>
                        {/*begin::Title*/}
                        <div className='fs-2hx fw-bold text-gray-800'>
                          <span className='fs-2 fw-bold text-dark'>Details</span>
                        </div>
                        {/*end::Title*/}
                        <span className='text-gray-400 pt-1 fw-semibold fs-6'>
                          Describe the actual and professional risk to your company's assets
                          including but not limited to people, facilities, data, technology and
                          funds
                        </span>
                        <form className='form'>
                          <div className='card-body'>
                            <div className='form-group'>
                              <label className='text-dark fw-bold text-primary my-2 fs-6'>
                                Description
                              </label>
                              <textarea
                                className='form-control form-control-solid'
                                id='description'
                                rows={3}
                              ></textarea>
                            </div>

                            <div className='form-group'>
                              <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6'>
                                ID
                              </label>
                              <div className='input-group input-group-lg'>
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  placeholder='ID'
                                />
                              </div>
                            </div>

                            <div className='form-group'>
                              <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6'>
                                Owner
                              </label>
                              <input
                                type='text'
                                className='form-control form-control-solid'
                                placeholder='Owner'
                              />
                            </div>
                            <div className='form-group'>
                              <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6'>
                                Categories
                              </label>
                              <select
                                className='form-select form-select-lg form-select-solid'
                                data-kt-select2='true'
                                data-placeholder='Select option'
                                data-allow-clear='true'
                                defaultValue={'1'}
                              >
                                <option value=''></option>
                                <option value='1'>Security</option>
                                <option value='2'>Human Capital</option>
                                <option value='3'>Disaster</option>
                              </select>
                            </div>
                            <div className='form-group'>
                              <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6'>
                                Departments
                              </label>
                              <div className='input-group input-group-lg'>
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  placeholder='Departments'
                                />
                              </div>
                            </div>
                            <div className='form-group'>
                              <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6'>
                                Confidentiality, integrity and availability
                              </label>
                              <div className='input-group input-group-lg'>
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  placeholder='Last checked in'
                                />
                              </div>
                            </div>
                            <button
                              type='submit'
                              value='Submit'
                              className='btn btn-primary mr-2 mt-10'
                              style={{backgroundColor: '#44DBAF', color: '#FFFFFF', float: 'right'}}
                            >
                              Next
                            </button>
                          </div>
                        </form>
                      </div>
                      {/*begin::Wrapper*/}
                    </div>
                    {/*end::Body*/}
                  </div>
                  {/*end::Engage widget 10*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Row*/}
            </div>
            {/*end::Card body*/}
          </div>
        </div>
      )}
      {/*end::Card body*/}
    </div>
  )
}

export {CompleteAssessment}
