import React, {FC, useState, CSSProperties, useEffect} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {KTCard} from '../../../_metronic/helpers'
import {TaskManagement} from './TaskManagement'
import {ToastContainer} from 'react-toastify'
import {collection, doc, onSnapshot} from 'firebase/firestore'
import db from '../../../services/firebase'
import {useAuth} from '../../modules/auth'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

interface SelectedNav {
  title: string
  type: string
}

const TaskManagementWrapper: FC = () => {
  const [parentDataCount, setParentDataCount] = useState<number>(0)
  const [activeNavItem, setActiveNavItem] = useState<string>('All')
  const [personnels, setPersonnels] = useState<any[]>([])
  const {currentUser} = useAuth()
  const [selectedNav, setSelectedNav] = useState<SelectedNav>({
    title: 'All',
    type: 'type',
  })
  const [tasks, setTasks] = useState<any[]>([])
  const [selectedType, setSelectedType] = useState<string>('Everything')
  const [selectedStatus, setSelectedStatus] = useState<string>('All')
  const [selectedOwner, setSelectedOwner] = useState<string>('')
  const [filteredTasks, setFilteredTasks] = useState<any[]>([])

  // const sidebarContainer: CSSProperties = {
  //   position: 'sticky',
  //   height: '90vh',
  //   left: '-20px',
  //   top: '35px',
  // }
  useEffect(() => {
    getTasks()
  }, [])

  const getTasks = async () => {
    try {
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)
      const controlsCollectionRef = collection(tenantRef, 'tasklists')

      const unsubscribe = onSnapshot(controlsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setTasks(controlsData)
        setFilteredTasks(controlsData);
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      // Logout()
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setPersonnels(risksData)
    })

    return unsubscribe
  }, [])

  const updateFilters = (
    type: string | null = null,
    status: string | null = null,
    owner: string | null = null
  ) => {
    const newType = type !== null ? type : selectedType
    const newStatus = status !== null ? status : selectedStatus
    const newOwner = owner !== null ? owner : selectedOwner

    setSelectedType(newType)
    setSelectedStatus(newStatus)
    setSelectedOwner(newOwner)

    const filteredTasks = filterTasks(newType, newStatus, newOwner)
    setFilteredTasks(filteredTasks)
  }

  const filterTasks = (type: string, status: string, owner: string) => {
    return tasks.filter((task) => {
      let matchesType = true
      let matchesStatus = true
      let matchesOwner = true

      if (type !== 'Everything') {
        if (type === 'Owned by me') {
          matchesType = task.owner === currentUser?.userName
        } else if (type === 'Requested by me') {
          matchesType = task.username === currentUser?.userName
        }
      }

      if (status !== 'All') {
        matchesStatus = task.status === (status === 'Done' ? 'Completed' : 'Incomplete')
      }

      if (owner) {
        matchesOwner = task.owner === owner
      }

      return matchesType && matchesStatus && matchesOwner
    })
  }

  const sidebarContainer: CSSProperties = {
    position: 'fixed',
    height: '90vh',
    width: '14%',
    zIndex: 2,
    padding: '0',
  }

  // const sidebarStyle: CSSProperties = {
  //   height: 'calc(100% + 39px)',
  //   paddingLeft: "0px",
  //   position: 'relative',
  //   left: '-20px',
  //   top: '-39px',
  // }

  const mainContentStyle: CSSProperties = {
    marginLeft: '17.66%', // Equivalent to col-md-2
  }

  const sidebarStyle: CSSProperties = {
    height: '80%',
    overflowY: 'auto',
    paddingLeft: '0px',
  }

  const headingStyle: CSSProperties = {
    fontSize: '0.75rem',
    textTransform: 'uppercase',
  }

  const navLinkStyle: CSSProperties = {
    color: '#5A5A5A',
    fontWeight: '400',
    padding: '0.5rem 1rem',
    cursor: 'pointer',
  }

  const navLinkActiveStyle: CSSProperties = {
    fontWeight: 'bold',
    backgroundColor: '#ECECEC',
    borderRadius: '6px',
  }

  interface NavLinkProps {
    children: React.ReactNode
    isActive?: boolean
    onClick: () => void
  }

  const handleClick = (title: string, type: string) => {
    setActiveNavItem(title)
    setSelectedNav({
      title: title,
      type: type,
    })
  }

  const NavLink: FC<NavLinkProps> = ({children, isActive = false, onClick}) => {
    return (
      <a
        className={`nav-link ${isActive ? 'active' : ''} fs-6`}
        href='#'
        style={{
          ...navLinkStyle,
          ...(isActive ? navLinkActiveStyle : {}),
        }}
        onClick={onClick}
      >
        {children}
      </a>
    )
  }

  const resetFilters = () => {
    updateFilters('Everything', 'All', '')
  }

  return (
    <>
      <div className='row'>
        <div className='col-md-2' style={sidebarContainer}>
          <div style={sidebarStyle}>
            <div className='card h-100' style={{background: '#fff'}}>
              <div className='card-body p-0 px-6 pt-6'>
                <div className='d-flex justify-content-between align-items-center mb-4'>
                  <label className='fs-6 form-label fw-bold text-gray-900 mb-0'>Filters</label>
                  <button
                    className='btn btn-sm fs-6 p-0 text-bold btn-active-light-primary'
                    style={{color: '#1CBB8D'}}
                    onClick={resetFilters}
                  >
                    Reset
                  </button>
                </div>
                {/* Type filter */}
                <div className='mb-4'>
                  <label className='fs-6 form-label fw-bold text-gray-900 mb-5'>Type</label>
                  {['Everything', 'Owned by me', 'Requested by me'].map((category, index) => (
                    <div key={index} className='form-check form-check-custom mb-5 form-check-sm'>
                      <input
                        className='form-check-input'
                        type='radio'
                        id={`kt_search_category_${index + 1}`}
                        checked={selectedType === category}
                        onChange={() => updateFilters(category, null, null)}
                      />
                      <label
                        className='form-check-label flex-grow-1 fw-semibold text-gray-700 fs-6'
                        htmlFor={`kt_search_category_${index + 1}`}
                      >
                        {category}
                      </label>
                    </div>
                  ))}
                </div>

                {/* Status filter */}
                <div className='mb-4'>
                  <label className='fs-6 form-label fw-bold text-gray-900'>Status</label>
                  <div className='nav-group nav-group-fluid'>
                    {['All', 'Done', 'Todo'].map((status, index) => (
                      <label key={index}>
                        <input
                          type='radio'
                          className='btn-check'
                          name='status'
                          value={status}
                          checked={selectedStatus === status}
                          onChange={() => updateFilters(null, status, null)}
                        />
                        <span className='btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4'>
                          {status}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>

                {/* Owner filter */}
                <div className='mb-6'>
                  <label className='fs-6 form-label fw-bold text-gray-900'>Owner</label>
                  <select
                    className='form-select'
                    value={selectedOwner}
                    onChange={(e) => updateFilters(null, null, e.target.value)}
                  >
                    <option value=''>Select Owner</option>
                    {personnels.map((person) => (
                      <option key={person.id} value={person.full_name}>
                        {person.full_name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Action Buttons */}
                {/* <div className='d-flex align-items-center justify-content-end'>
             
                    <button
                      type='button'
                      // disabled={isLoading}
                      // onClick={filterData}
                      className='btn btn-sm fs-6 btn-light btn-active-light-primary fw-bold me-4 px-6'
                      data-kt-menu-dismiss='true'
                      data-kt-user-table-filter='reset'
                      style={{height: '40px'}}
                    >
                      Reset
                    </button>
                    <button
                      // disabled={isLoading}
                      type='button'
                      // onClick={resetData}
                      className='btn btn-sm fs-6 fw-bold px-6'
                      data-kt-menu-dismiss='true'
                      data-kt-user-table-filter='filter'
                      style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                      onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#148363')
                      }
                      onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#1CBB8D')
                      }
                    >
                      Apply
                    </button>
                
                    </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-10' style={mainContentStyle}>
          <KTCard>
            <TaskManagement
              className='mb-5 mb-xl-8'
              onDataCountChange={setParentDataCount}
              filter={selectedNav}
              tasks={filteredTasks}
            />
            <ToastContainer />
          </KTCard>
        </div>
      </div>
    </>
  )
}

export default TaskManagementWrapper
