import React, {FC, useEffect, useState} from 'react'
import {PageLink} from '../../../_metronic/layout/core'
import {KTCard} from '../../../_metronic/helpers'
import {Link, useLocation, useParams} from 'react-router-dom'
import VendorRisk from './VendorRisk'
import {VendorPageTitle} from '../../../_metronic/layout/core/VendorPageData'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const VendorRiskWrapper: FC = () => {
  const [activeTab, setActiveTab] = useState('vendor-risk')
  const location = useLocation()
  const {id} = useParams()

  useEffect(() => {
    // Fetch control data when the component mounts
    if (location.pathname.includes('archive')) {
      setActiveTab('archive')
    } else if (location.pathname.includes('available')) {
      setActiveTab('available')
    } else {
      setActiveTab('vendor-risk')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      <VendorPageTitle
        icon='VendorIcon.svg'
        title='Vendor Risk Management'
        desc={
          'Monitor risks associated with third party services to enhance your security posture. '
        }
        breadcrumbs={usersBreadcrumbs}
      />
      <KTCard>
        <VendorRisk className='card-xl-stretch mb-xl-8' />
      </KTCard>
    </>
  )
}

export default VendorRiskWrapper
