import {useListView} from '../../../vendor/core/ListViewProvider'
import {PersonnelListGrouping} from './PersonnelListGrouping'
import {PersonnelListSearchComponent} from './PersonnelListSearchComponent'
import {PersonnelListToolbar} from './PersonnelListToolbar'

const PersonnelListHeader = () => {
  const {selected} = useListView()
  return (
    // <div className='card-header border-0 pt-6'>
    //   {/* <PersonnelListSearchComponent /> */}
    //   {/* begin::Card toolbar */}
    //   <div className='card-toolbar'>
    //     {/* begin::Group actions */}
    //     {/* <PersonnelListToolbar /> */}
    //     {/* end::Group actions */}
    //   </div>
    //   {/* end::Card toolbar */}
    // </div>
    <></>
  )
}

export {PersonnelListHeader}

//not used