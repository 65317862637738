import db from '../../../../services/firebase'
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from 'firebase/firestore'

const policyCollectionRef = collection(db, 'PolicyManagement')

class PolicyDataService {
  addPolicy = (newPolicy) => {
    // Add 'created_at' and 'updated_at' timestamps
    const policyWithTimestamps = {
      ...newPolicy,
      created_at: serverTimestamp(),
      updated_at: serverTimestamp(),
    }

    return addDoc(policyCollectionRef, policyWithTimestamps)
  }

  updatePolicy = (id, updatePolicy) => {
    const policyDoc = doc(db, 'PolicyManagement', id)

    // Update 'updated_at' timestamp
    const policyWithTimestamp = {
      ...updatePolicy,
      updated_at: serverTimestamp(),
    }

    return updateDoc(policyDoc, policyWithTimestamp)
  }

  deletePolicy = (id) => {
    const policyDoc = doc(db, 'PolicyManagement', id)
    return deleteDoc(policyDoc)
  }

  getAllPolicies = () => {
    return getDocs(policyCollectionRef)
  }

  getPolicy = (id) => {
    const policyDoc = doc(db, 'PolicyManagement', id)
    return getDoc(policyDoc)
  }
}

export default new PolicyDataService()
