/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import db from '../../../services/firebase'
import controlServices from '../controls/services/control.services'
import {format} from 'date-fns'
import {Link, useParams, useLocation} from 'react-router-dom'

type Props = {}

const TestDetailsManagement: React.FC<Props> = () => {
  const [test, setRisk] = useState<any | null>(null)
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('overview')
  const {id} = useParams()
  console.log('Tests', test)
  const [dataCount, setDataCount] = useState(0)
  useEffect(() => {
    // Fetch test data when the component mounts
    if (id) {
      getRisk(id)
    }
    if (location.pathname.includes('overview')) {
      setActiveTab('overview')
    } else if (location.pathname.includes('evidence')) {
      setActiveTab('evidence')
    } else if (location.pathname.includes('task')) {
      setActiveTab('task')
    } else if (location.pathname.includes('controls')) {
      setActiveTab('controls')
    } else {
      setActiveTab('comments')
    }
  }, [id])

  const getRisk = async (controlId: string) => {
    try {
      const testRef = db.collection('default_tests').doc(controlId) // Replace 'your-collection-name' with your actual Firestore collection name
      const doc = await testRef.get()

      if (doc.exists) {
        setRisk(doc.data())
      } else {
        // Handle the case where the document doesn't exist
        console.log(`Risk with ID ${controlId} not found.`)
      }
    } catch (error) {
      console.error('Error fetching test data:', error)
    }
  }

  return (
    <div
      className='card card-flush pb-0 bgi-position-y-center bgi-no-repeat mb-10'
      style={{
        backgroundSize: 'auto calc(100% + 10rem)',
        backgroundPositionX: '100%',
        backgroundImage: "url('assets/media/illustrations/sketchy-1/4.png')",
      }}
    >
      {/*begin::Card header*/}

      {test && (
        <div>
          <div className='card-header pt-5'>
            <div className=''>
              <div className='d-flex justify-content-start flex-column'>
                <span className='card-label fw-bold fs-3'>{test.name}</span>
                <span className='text-muted fw-semibold fs-5 mb-3'>
                  {test.name + ' ' + 'is' + ' ' + test.status}
                </span>
              </div>
            </div>

            <div
              className='d-flex justify-content-end mb-5'
              data-kt-filemanager-table-toolbar='base'
            >
              {/*begin::Export*/}
              <button
                type='button'
                className='btn btn-flex btn-light-primary me-3'
                id='kt_file_manager_new_folder'
              >
                <i className='ki-duotone ki-add-folder fs-2'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                Archive
              </button>
              {/*end::Export*/}

              {/*begin::Add customer*/}
              <button
                type='button'
                className='btn btn-flex btn-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_upload'
              >
                Complete Assessment
              </button>
              {/*end::Add customer*/}
            </div>
          </div>
          {/*end::Card header*/}
          <div id='kt_app_content' className='app-content flex-column-fluid mt-10'>
            {/*begin::Content container*/}
            <div id='kt_app_content_container' className='app-container container-l'>
              {/*begin::Layout*/}
              <div className='d-flex flex-column flex-lg-row'>
                {/*begin::Content*/}
                <div className='flex-lg-row-fluid '>
                  {/*begin:::Tabs*/}
                  <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8'>
                    {/*begin:::Tab item*/}
                    <li className='nav-item'>
                      <a
                        className='nav-link text-active-primary pb-4 active'
                        data-bs-toggle='tab'
                        href='#kt_user_view_overview_tab'
                      >
                        Overview
                      </a>
                    </li>
                    {/*end:::Tab item*/}
                    {/*begin:::Tab item*/}
                    <li className='nav-item'>
                      <a
                        className='nav-link text-active-primary pb-4'
                        data-kt-countup-tabs='true'
                        data-bs-toggle='tab'
                        href='#kt_user_view_overview_evidence'
                      >
                        Evidence
                      </a>
                    </li>
                    {/*end:::Tab item*/}

                    {/*begin:::Tab item*/}
                    <li className='nav-item'>
                      <a
                        className='nav-link text-active-primary pb-4'
                        data-bs-toggle='tab'
                        href='#kt_user_view_overview_tasks'
                      >
                        Tasks
                      </a>
                    </li>
                    {/*end:::Tab item*/}
                    {/*begin:::Tab item*/}
                    <li className='nav-item'>
                      <a
                        className='nav-link text-active-primary pb-4'
                        data-bs-toggle='tab'
                        href='#kt_user_view_controls'
                      >
                        Controls
                      </a>
                    </li>
                    {/*end:::Tab item*/}
                    {/*begin:::Tab item*/}
                    <li className='nav-item ms-auto'>
                      {/*begin::Action menu*/}
                      <a
                        href='#'
                        className='btn btn-primary ps-7'
                        data-kt-menu-trigger='click'
                        data-kt-menu-attach='parent'
                        data-kt-menu-placement='bottom-end'
                      >
                        Actions
                        <i className='ki-duotone ki-down fs-2 me-0'></i>
                      </a>
                      {/*begin::Menu*/}
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-4 w-250px fs-6'
                        data-kt-menu='true'
                      >
                        {/*begin::Menu item*/}
                        <div className='menu-item px-5'>
                          <div className='menu-content text-muted pb-2 px-5 fs-7 text-uppercase'>
                            Payments
                          </div>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className='menu-item px-5'>
                          <a href='#' className='menu-link px-5'>
                            Create invoice
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className='menu-item px-5'>
                          <a href='#' className='menu-link flex-stack px-5'>
                            Create payments
                            <span
                              className='ms-2'
                              data-bs-toggle='tooltip'
                              title='Specify a target name for future usage and reference'
                            >
                              <i className='ki-duotone ki-information fs-7'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                              </i>
                            </span>
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div
                          className='menu-item px-5'
                          data-kt-menu-trigger='hover'
                          data-kt-menu-placement='left-start'
                        >
                          <a href='#' className='menu-link px-5'>
                            <span className='menu-title'>Subscription</span>
                            <span className='menu-arrow'></span>
                          </a>
                          {/*begin::Menu sub*/}
                          <div className='menu-sub menu-sub-dropdown w-175px py-4'>
                            {/*begin::Menu item*/}
                            <div className='menu-item px-3'>
                              <a href='#' className='menu-link px-5'>
                                Apps
                              </a>
                            </div>
                            {/*end::Menu item*/}
                            {/*begin::Menu item*/}
                            <div className='menu-item px-3'>
                              <a href='#' className='menu-link px-5'>
                                Billing
                              </a>
                            </div>
                            {/*end::Menu item*/}
                            {/*begin::Menu item*/}
                            <div className='menu-item px-3'>
                              <a href='#' className='menu-link px-5'>
                                Statements
                              </a>
                            </div>
                            {/*end::Menu item*/}
                            {/*begin::Menu separator*/}
                            <div className='separator my-2'></div>
                            {/*end::Menu separator*/}
                            {/*begin::Menu item*/}
                            <div className='menu-item px-3'>
                              <div className='menu-content px-3'>
                                <label className='form-check form-switch form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input w-30px h-20px'
                                    type='checkbox'
                                    value=''
                                    name='notifications'
                                    id='kt_user_menu_notifications'
                                  />
                                  <span className='form-check-label text-muted fs-6'>
                                    Notifications
                                  </span>
                                </label>
                              </div>
                            </div>
                            {/*end::Menu item*/}
                          </div>
                          {/*end::Menu sub*/}
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu separator*/}
                        <div className='separator my-3'></div>
                        {/*end::Menu separator*/}
                        {/*begin::Menu item*/}
                        <div className='menu-item px-5'>
                          <div className='menu-content text-muted pb-2 px-5 fs-7 text-uppercase'>
                            Account
                          </div>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className='menu-item px-5'>
                          <a href='#' className='menu-link px-5'>
                            Frameworks
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className='menu-item px-5 my-1'>
                          <a href='#' className='menu-link px-5'>
                            Account Settings
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className='menu-item px-5'>
                          <a href='#' className='menu-link text-danger px-5'>
                            Delete customer
                          </a>
                        </div>
                        {/*end::Menu item*/}
                      </div>
                      {/*end::Menu*/}
                      {/*end::Menu*/}
                    </li>
                    {/*end:::Tab item*/}
                  </ul>
                  {/*end:::Tabs*/}
                  {/*begin:::Tab content*/}
                  <div className='tab-content' id='myTabContent'>
                    {/*begin:::Tab pane*/}
                    <div
                      className='tab-pane fade show active'
                      id='kt_user_view_overview_tab'
                      role='tabpanel'
                    >
                      {/*begin::Card*/}
                      <div className='card card-flush mb-6 mb-xl-9'>
                        {/*begin::Card header*/}
                        <div className='card-header mt-6'>
                          {/*begin::Card title*/}
                          <div className='card-title flex-column'>
                            <h2 className='mb-1'>Test Overview</h2>
                            <div className='fs-6 fw-semibold text-muted'>Is this helpful?</div>
                          </div>
                          {/*end::Card title*/}
                          {/*begin::Card toolbar*/}
                          <div className='card-toolbar'>
                            <button
                              type='button'
                              className='btn btn-light-primary btn-sm'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_add_schedule'
                            >
                              <i className='ki-duotone ki-brush fs-3'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                              Add Schedule
                            </button>
                          </div>
                          {/*end::Card toolbar*/}
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className='card-body p-9 pt-4'>
                          <span className='fs-4 fw-bold text-dark text-hover-primary mb-2'>
                            {' '}
                            How to pass this test?{' '}
                          </span>
                          <br></br>
                          <span className='fs-5 mt-3'>
                            {' '}
                            1. Navigate to polciy tab. Locate the Data Classification Policy in
                            Draft or Archived.
                          </span>
                          <br></br>
                          <span className='fs-5 mt-3'>
                            {' '}
                            2. Review the Data Classification Policy and make any necessary changes
                            by clicking Edit in top right.
                          </span>
                          <br></br>
                          <span className='fs-5 mt-3'>
                            {' '}
                            3. Publish the Data Classification Policy by clicking Save and publish
                            in the bottom right.
                          </span>
                        </div>
                        {/*end::Card body*/}
                      </div>
                      {/*end::Card*/}
                    </div>
                    {/*end:::Tab pane*/}
                    {/*begin:::Tab pane*/}
                    <div
                      className='tab-pane fade'
                      id='kt_user_view_overview_evidence'
                      role='tabpanel'
                    >
                      {/*begin::Card*/}
                      <div className='card pt-4 mb-6 mb-xl-9'>
                        {/*begin::Card header*/}
                        <div className='card-header border-0'>
                          {/*begin::Card title*/}
                          <div className='card-title'>
                            <h2>Evidence</h2>
                          </div>
                          {/*end::Card title*/}
                        </div>

                        {/*begin::Card body*/}
                        <div className='card-body pt-0 pb-5'>
                          {/*begin::Table wrapper*/}
                          {/*end::Card header*/}
                          <div className='form-group'>
                            <label className='text-muted mt-2 mb-3'>
                              Select Data Classification Policy
                            </label>
                            <select
                              className='form-control form-control-solid '
                              id='exampleSelectl'
                            >
                              <option>Select</option>
                              <option>Jackson Hartman</option>
                              <option>Jack Hartman</option>
                              <option>Sam Smith</option>
                            </select>
                          </div>

                          {/*end::Table wrapper*/}
                        </div>
                        <div className='card-header border-0'>
                          {/*begin::Card title*/}
                          <div className='card-title'>
                            <h2>Additional Evidence</h2>
                          </div>
                          {/*end::Card title*/}
                        </div>
                        {/*end::Card body*/}
                        <div className='card-body pt-0 pb-2'>
                          {/*begin::Table wrapper*/}
                          {/*end::Card header*/}
                          <div className='form-group'>
                            <label className='text-muted mt-2 mb-3'>
                              If you have supplementary documentation to satisfy this control or
                              communication to your auditor, you can add it here.
                            </label>
                            <select
                              className='form-control form-control-solid mb-3'
                              id='exampleSelectl'
                            >
                              <option>Select</option>
                              <option>Jackson Hartman</option>
                              <option>Jack Hartman</option>
                              <option>Sam Smith</option>
                            </select>
                          </div>

                          {/*end::Table wrapper*/}
                        </div>
                      </div>
                      {/*end::Card*/}
                    </div>
                    {/*end:::Tab pane*/}
                    {/*begin:::Tab pane*/}
                    <div className='tab-pane fade' id='kt_user_view_overview_tasks' role='tabpanel'>
                      {/*begin::Card*/}
                      <div className='card pt-4 mb-6 mb-xl-9'>
                        {/*begin::Card header*/}
                        <div className='card-header border-0'>
                          {/*begin::Card title*/}
                          <div className='card-title'>
                            <h2>Task</h2>
                          </div>
                          {/*end::Card title*/}
                          {/*begin::Card toolbar*/}
                          <div className='card-toolbar'>
                            <button
                              type='button'
                              className='btn btn-sm btn-flex btn-light-primary mx-4'
                              data-bs-toggle='modal'
                            >
                              <i className='ki-duotone ki-entrance-right fs-3'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                              Go to all task
                            </button>
                            {/*begin::Filter*/}
                            <button
                              type='button'
                              className='btn btn-sm btn-primary'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_add_task'
                              style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                            >
                              <KTIcon iconName='plus' className='fs-2' />
                              Add Task
                            </button>
                            {/*end::Filter*/}
                          </div>
                          <div className='modal fade' tabIndex={-1} id='kt_modal_add_'>
                            <div className='modal-dialog'>
                              <div className='modal-content'>
                                <div className='modal-header'>
                                  <h3 className='modal-title'>Add new Task</h3>

                                  <div
                                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                    data-bs-dismiss='modal'
                                    aria-label='Close'
                                  >
                                    <i className='ki-duotone ki-cross fs-1'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  </div>
                                </div>

                                <div className='modal-body'>
                                  <div style={{marginTop: 20, marginLeft: 20, marginRight: 20}}>
                                    {/* {message?.msg && (
                    <div
                      className={
                        message?.error
                          ? 'alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row'
                          : 'alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row'
                      }
                    >
                      {message?.error ? (
                        <i className='ki-duotone ki-notification-bing fs-2hx text-danger me-4 mb-5 mb-sm-0'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                        </i>
                      ) : (
                        <i className='ki-duotone ki-notification-bing fs-2hx text-success me-4 mb-5 mb-sm-0'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                        </i>
                      )}

                      <div
                        className={
                          message?.error
                            ? 'd-flex flex-column text-danger text-primary text-bold pe-0 pe-sm-10'
                            : 'd-flex flex-column text-success text-primary text-bold pe-0 pe-sm-10'
                        }
                      >
                        {message?.error ? (
                          <h5 className='mb-1'>Error!!</h5>
                        ) : (
                          <h5 className='mb-1'>Sucess!!</h5>
                        )}
                        <span>{message?.msg}</span>
                      </div>
                      <button
                        type='button'
                        className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
                        onClick={() => setShowAlert(false)}
                      >
                        <span className='svg-icon svg-icon-1 svg-icon-success'>
                          <span className='svg-icon svg-icon-2x svg-icon-primary'>
                            <svg
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                              className='mh-50px'
                            >
                              <rect
                                opacity='0.5'
                                x='6'
                                y='17.3137'
                                width='16'
                                height='2'
                                rx='1'
                                transform='rotate(-45 6 17.3137)'
                                fill='black'
                              ></rect>
                              <rect
                                x='7.41422'
                                y='6'
                                width='16'
                                height='2'
                                rx='1'
                                transform='rotate(45 7.41422 6)'
                                fill='black'
                              ></rect>
                            </svg>
                          </span>
                        </span>
                      </button>
                    </div>
                  )} */}
                                  </div>
                                  <div className='card card-custom bg-light'>
                                    <div className='card-header'>
                                      <h2 className='card-title mt-5'>Custom Policy Creation</h2>
                                    </div>
                                  </div>

                                  <form className='form'>
                                    <div className='card-body'>
                                      <div className='form-group'>
                                        <label className='text-dark fw-bold text-primary my-2 fs-6'>
                                          Policy Name
                                        </label>
                                        <input
                                          type='text'
                                          className='form-control form-control-solid'
                                          value=''
                                          placeholder='Enter policy name'
                                        />
                                      </div>
                                      <div className='form-group'>
                                        <label className='text-dark fw-bold text-primary my-2  fs-6'>
                                          Owner Name
                                        </label>
                                        <input
                                          type='text'
                                          className='form-control form-control-solid'
                                          value=''
                                          placeholder='Enter owner name'
                                        />
                                      </div>
                                      <div className='form-group'>
                                        <label className='text-dark fw-bold text-primary mt-6 my-2  fs-6'>
                                          Description
                                        </label>
                                        <textarea
                                          className='form-control form-control-solid'
                                          value=''
                                          id='description'
                                          rows={3}
                                        ></textarea>
                                      </div>
                                      <div className='form-group'>
                                        <label className='text-dark fw-bold text-primary mt-6 my-2  fs-6'>
                                          Status
                                        </label>
                                        <select
                                          className='form-control form-control-solid '
                                          id='status'
                                          value=''
                                        >
                                          <option value=''>Select the option below..</option>
                                          <option value='Draft'>Draft</option>
                                          <option value='Published'>Published</option>
                                        </select>
                                      </div>
                                      <div className='form-group'>
                                        <input
                                          type='file'
                                          id='hiddenFileInput'
                                          accept='*'
                                          style={{display: 'none'}}
                                        />
                                        <input
                                          type='text'
                                          className='form-control form-control-solid me-3 mt-6 my-2'
                                          placeholder='Select File to upload'
                                          readOnly
                                        />
                                        <button
                                          type='button'
                                          className='btn btn-light-primary me-3 mt-1 my-2'
                                        >
                                          <KTIcon iconName='exit-up' className='fs-2' />
                                          Upload
                                        </button>
                                      </div>
                                    </div>
                                    <div className='modal-footer'>
                                      <button
                                        type='button'
                                        className='btn btn-light'
                                        data-bs-dismiss='modal'
                                      >
                                        Close
                                      </button>
                                      {isLoading ? (
                                        <button
                                          type='submit'
                                          value='Submit'
                                          className='btn btn-primary mr-2'
                                          style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                                        >
                                          <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                                          Add Policy
                                        </button>
                                      ) : (
                                        <button
                                          type='submit'
                                          value='Submit'
                                          className='btn btn-primary mr-2'
                                          style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                                        >
                                          Add Policy
                                        </button>
                                      )}
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*end::Card toolbar*/}
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className='card-body pt-0 pb-2'>
                          {/*begin::Table wrapper*/}
                          <div className='table-responsive'>
                            {/*begin::Table*/}
                            <table
                              className='table align-middle table-row-dashed gy-5'
                              id='kt_table_users_login_session'
                            >
                              <thead className='border-bottom border-gray-200 fs-7 fw-bold'>
                                <tr className='text-start text-muted text-uppercase gs-0'>
                                  <th className='min-w-100px'>Location</th>
                                  <th>Device</th>
                                  <th>IP Address</th>
                                  <th className='min-w-125px'>Time</th>
                                  <th className='min-w-70px'>Actions</th>
                                </tr>
                              </thead>
                              <tbody className='fs-6 fw-semibold text-gray-600'>
                                <tr>
                                  <td>Australia</td>
                                  <td>Chome - Windows</td>
                                  <td>207.10.42.265</td>
                                  <td>23 seconds ago</td>
                                  <td>Current session</td>
                                </tr>
                                <tr>
                                  <td>Australia</td>
                                  <td>Safari - iOS</td>
                                  <td>207.27.36.377</td>
                                  <td>3 days ago</td>
                                  <td>
                                    <a href='#' data-kt-users-sign-out='single_user'>
                                      Sign out
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Australia</td>
                                  <td>Chrome - Windows</td>
                                  <td>207.23.23.166</td>
                                  <td>last week</td>
                                  <td>Expired</td>
                                </tr>
                              </tbody>
                            </table>
                            {/*end::Table*/}
                          </div>
                          {/*end::Table wrapper*/}
                        </div>
                        {/*end::Card body*/}
                      </div>
                      {/*end::Card*/}
                    </div>
                    {/*end:::Tab pane*/}
                    {/*begin:::Tab pane*/}
                    <div className='tab-pane fade' id='kt_user_view_controls' role='tabpanel'>
                      {/*begin::Card*/}
                      <div className='card pt-4 mb-6 mb-xl-9'>
                        {/*begin::Card header*/}
                        <div className='card-header border-0'>
                          {/*begin::Card title*/}
                          <div className='card-title'>
                            <h2>Control</h2>
                          </div>
                          {/*end::Card title*/}
                          {/*begin::Card toolbar*/}
                          <div className='card-toolbar'>
                            {/*begin::Filter*/}
                            <button
                              type='button'
                              className='btn btn-sm btn-flex btn-light-primary'
                              id='kt_modal_sign_out_sesions'
                            >
                              <i className='ki-duotone ki-entrance-right fs-3'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                              Sign out all sessions
                            </button>
                            {/*end::Filter*/}
                          </div>
                          {/*end::Card toolbar*/}
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className='card-body py-0'>
                          {/*begin::Table wrapper*/}
                          <div className='table-responsive'>
                            {/*begin::Table*/}
                            <table
                              className='table align-middle table-row-dashed fw-semibold text-gray-600 fs-6 gy-5'
                              id='kt_table_users_logs'
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex justify-content-start flex-column'>
                                        <a
                                          href='#'
                                          className='text-dark fw-bold text-hover-primary fs-6'
                                        >
                                          AC-01
                                        </a>
                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                          ISO 27001
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td className='pe-0 text-end min-w-200px'></td>
                                  <td className='min-w-70px'>
                                    <div className='badge badge-light-warning'>Unhealthy</div>
                                  </td>
                                </tr>
                              </tbody>
                              <div className='separator'></div>
                            </table>
                            {/*end::Table*/}
                          </div>
                          {/*end::Table wrapper*/}
                        </div>
                        {/*end::Card body*/}
                      </div>
                      {/*end::Card*/}
                    </div>
                    {/*end:::Tab pane*/}
                  </div>
                  {/*end:::Tab content*/}
                </div>
                {/*end::Content*/}
                {/*begin::Sidebar*/}
                <div className='flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10 ms-lg-15'>
                  {/*begin::Card*/}
                  <div className='card mb-5 mb-xl-8'>
                    {/*begin::Card body*/}
                    <div className='card-body'>
                      {/*begin::Summary*/}
                      {/*begin::User Info*/}
                      <div className='d-flex flex-column py-5'>
                        {/*begin::Name*/}
                        <a href='#' className='fs-3 text-gray-800 text-hover-primary fw-bold mb-3'>
                          Control
                        </a>
                        {/*end::Name*/}
                        {/*begin::Position*/}
                        <div className='mb-5'>
                          {/*begin::Badge*/}
                          <div className='badge badge-lg badge-light-primary d-inline'>C-04</div>
                          {/*begin::Badge*/}
                        </div>
                        <div className='separator'></div>
                        {/*end::Position*/}
                        {/*begin::Info*/}
                      </div>
                      {/*end::User Info*/}
                      {/*end::Summary*/}
                      {/*begin::Details toggle*/}
                      <div className='d-flex flex-stack fs-4 py-3'>
                        <div
                          className='fw-bold rotate collapsible'
                          data-bs-toggle='collapse'
                          role='button'
                          aria-expanded='false'
                          aria-controls='kt_user_view_details'
                        >
                          Author
                          {/* <span className='ms-2 rotate-180'>
                            <i className='ki-duotone ki-down fs-3'></i>
                          </span> */}
                        </div>
                        {/* <span
                          data-bs-toggle='tooltip'
                          data-bs-trigger='hover'
                          title='Edit customer details'
                        >
                          <a
                            href='#'
                            className='btn btn-sm btn-light-primary'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_update_details'
                          >
                            Edit
                          </a>
                        </span> */}
                      </div>

                      <div id='kt_user_view_details' className='collapse show'>
                        <div className='pb-2 fs-6'>
                          {/*begin::Details item*/}
                          <div className='fw-bold'>Owner</div>
                          <div className='text-gray-600'>None</div>
                          {/*begin::Details item*/}
                          {/*begin::Details item*/}
                          <div className='fw-bold mt-5'>Vendor</div>
                          <div className='text-gray-600'>
                            <a href='#' className='text-gray-600 text-hover-primary'>
                              None
                            </a>
                          </div>
                          {/*begin::Details item*/}
                          {/*begin::Details item*/}
                          <div className='fw-bold mt-5'>Tolerance window</div>
                          <div className='text-gray-600 mb-5'>None</div>
                          {/*begin::Details item*/}
                          <div className='separator'></div>
                        </div>
                        {/*begin::Details item*/}
                        <div className='fw-bold mt-5'>Last refresh:</div>
                        <div className='text-gray-600 mb-5'>Oct 25, 2023 4:18pm</div>
                        {/*begin::Details item*/}
                        {/*begin::Details item*/}
                        <div className='fw-bold mt-5'>Last updated:</div>
                        <div className='text-gray-600 mb-5'>Oct 25, 2023 4:18pm</div>
                        {/*begin::Details item*/}
                      </div>

                      {/*end::Details content*/}
                    </div>
                    {/*end::Card body*/}
                  </div>
                  {/*end::Card*/}
                </div>
                {/*end::Sidebar*/}
              </div>
              {/*end::Layout*/}
              {/*begin::Modals*/}
              {/*begin::Modal - Update user details*/}
              <div
                className='modal fade'
                id='kt_modal_update_details'
                tabIndex={-1}
                aria-hidden='true'
              >
                {/*begin::Modal dialog*/}
                <div className='modal-dialog modal-dialog-centered mw-650px'>
                  {/*begin::Modal content*/}
                  <div className='modal-content'>
                    {/*begin::Form*/}
                    <form className='form' action='#' id='kt_modal_update_user_form'>
                      {/*begin::Modal header*/}
                      <div className='modal-header' id='kt_modal_update_user_header'>
                        {/*begin::Modal title*/}
                        <h2 className='fw-bold'>Update User Details</h2>
                        {/*end::Modal title*/}
                        {/*begin::Close*/}
                        <div
                          className='btn btn-icon btn-sm btn-active-icon-primary'
                          data-kt-users-modal-action='close'
                        >
                          <i className='ki-duotone ki-cross fs-1'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>
                        </div>
                        {/*end::Close*/}
                      </div>
                      {/*end::Modal header*/}
                      {/*begin::Modal body*/}
                      <div className='modal-body py-10 px-lg-17'>
                        {/*begin::Scroll*/}
                        <div
                          className='d-flex flex-column scroll-y me-n7 pe-7'
                          id='kt_modal_update_user_scroll'
                          data-kt-scroll='true'
                          data-kt-scroll-activate='{default: false, lg: true}'
                          data-kt-scroll-max-height='auto'
                          data-kt-scroll-dependencies='#kt_modal_update_user_header'
                          data-kt-scroll-wrappers='#kt_modal_update_user_scroll'
                          data-kt-scroll-offset='300px'
                        >
                          {/*begin::User toggle*/}
                          <div
                            className='fw-bolder fs-3 rotate collapsible mb-7'
                            data-bs-toggle='collapse'
                            role='button'
                            aria-expanded='false'
                            aria-controls='kt_modal_update_user_user_info'
                          >
                            User Information
                            <span className='ms-2 rotate-180'>
                              <i className='ki-duotone ki-down fs-3'></i>
                            </span>
                          </div>
                          {/*end::User toggle*/}
                          {/*begin::User form*/}
                          <div id='kt_modal_update_user_user_info' className='collapse show'>
                            {/*begin::Input group*/}
                            <div className='mb-7'>
                              {/*begin::Label*/}
                              <label className='fs-6 fw-semibold mb-2'>
                                <span>Update Avatar</span>
                                <span
                                  className='ms-1'
                                  data-bs-toggle='tooltip'
                                  title='Allowed file types: png, jpg, jpeg.'
                                >
                                  <i className='ki-duotone ki-information fs-7'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                  </i>
                                </span>
                              </label>
                              {/*end::Label*/}
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className='fv-row mb-7'>
                              {/*begin::Label*/}
                              <label className='fs-6 fw-semibold mb-2'>Name</label>
                              {/*end::Label*/}
                              {/*begin::Input*/}
                              <input
                                type='text'
                                className='form-control form-control-solid'
                                placeholder=''
                                name='name'
                                value='Emma Smith'
                              />
                              {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className='fv-row mb-7'>
                              {/*begin::Label*/}
                              <label className='fs-6 fw-semibold mb-2'>
                                <span>Email</span>
                                <span
                                  className='ms-1'
                                  data-bs-toggle='tooltip'
                                  title='Email address must be active'
                                >
                                  <i className='ki-duotone ki-information fs-7'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                  </i>
                                </span>
                              </label>
                              {/*end::Label*/}
                              {/*begin::Input*/}
                              <input
                                type='email'
                                className='form-control form-control-solid'
                                placeholder=''
                                name='email'
                                value='smith@kpmg.com'
                              />
                              {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className='fv-row mb-7'>
                              {/*begin::Label*/}
                              <label className='fs-6 fw-semibold mb-2'>Description</label>
                              {/*end::Label*/}
                              {/*begin::Input*/}
                              <input
                                type='text'
                                className='form-control form-control-solid'
                                placeholder=''
                                name='description'
                              />
                              {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className='fv-row mb-15'>
                              {/*begin::Label*/}
                              <label className='fs-6 fw-semibold mb-2'>Language</label>
                              {/*end::Label*/}
                              {/*begin::Input*/}
                              <select
                                name='language'
                                aria-label='Select a Language'
                                data-control='select2'
                                data-placeholder='Select a Language...'
                                className='form-select form-select-solid'
                                data-dropdown-parent='#kt_modal_update_details'
                              >
                                <option></option>
                                <option value='id'>Bahasa Indonesia - Indonesian</option>
                                <option value='msa'>Bahasa Melayu - Malay</option>
                                <option value='ca'>Català - Catalan</option>
                                <option value='cs'>Čeština - Czech</option>
                                <option value='da'>Dansk - Danish</option>
                                <option value='de'>Deutsch - German</option>
                                <option value='en'>English</option>
                                <option value='en-gb'>English UK - British English</option>
                                <option value='es'>Español - Spanish</option>
                                <option value='fil'>Filipino</option>
                                <option value='fr'>Français - French</option>
                                <option value='ga'>Gaeilge - Irish (beta)</option>
                                <option value='gl'>Galego - Galician (beta)</option>
                                <option value='hr'>Hrvatski - Croatian</option>
                                <option value='it'>Italiano - Italian</option>
                                <option value='hu'>Magyar - Hungarian</option>
                                <option value='nl'>Nederlands - Dutch</option>
                                <option value='no'>Norsk - Norwegian</option>
                                <option value='pl'>Polski - Polish</option>
                                <option value='pt'>Português - Portuguese</option>
                                <option value='ro'>Română - Romanian</option>
                                <option value='sk'>Slovenčina - Slovak</option>
                                <option value='fi'>Suomi - Finnish</option>
                                <option value='sv'>Svenska - Swedish</option>
                                <option value='vi'>Tiếng Việt - Vietnamese</option>
                                <option value='tr'>Türkçe - Turkish</option>
                                <option value='el'>Ελληνικά - Greek</option>
                                <option value='bg'>Български език - Bulgarian</option>
                                <option value='ru'>Русский - Russian</option>
                                <option value='sr'>Српски - Serbian</option>
                                <option value='uk'>Українська мова - Ukrainian</option>
                                <option value='he'>עִבְרִית - Hebrew</option>
                                <option value='ur'>اردو - Urdu (beta)</option>
                                <option value='ar'>العربية - Arabic</option>
                                <option value='fa'>فارسی - Persian</option>
                                <option value='mr'>मराठी - Marathi</option>
                                <option value='hi'>हिन्दी - Hindi</option>
                                <option value='bn'>বাংলা - Bangla</option>
                                <option value='gu'>ગુજરાતી - Gujarati</option>
                                <option value='ta'>தமிழ் - Tamil</option>
                                <option value='kn'>ಕನ್ನಡ - Kannada</option>
                                <option value='th'>ภาษาไทย - Thai</option>
                                <option value='ko'>한국어 - Korean</option>
                                <option value='ja'>日本語 - Japanese</option>
                                <option value='zh-cn'>简体中文 - Simplified Chinese</option>
                                <option value='zh-tw'>繁體中文 - Traditional Chinese</option>
                              </select>
                              {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                          </div>
                          {/*end::User form*/}
                          {/*begin::Address toggle*/}
                          <div
                            className='fw-bolder fs-3 rotate collapsible mb-7'
                            data-bs-toggle='collapse'
                            role='button'
                            aria-expanded='false'
                            aria-controls='kt_modal_update_user_address'
                          >
                            Address Details
                            <span className='ms-2 rotate-180'>
                              <i className='ki-duotone ki-down fs-3'></i>
                            </span>
                          </div>
                          {/*end::Address toggle*/}
                          {/*begin::Address form*/}
                          <div id='kt_modal_update_user_address' className='collapse show'>
                            {/*begin::Input group*/}
                            <div className='d-flex flex-column mb-7 fv-row'>
                              {/*begin::Label*/}
                              <label className='fs-6 fw-semibold mb-2'>Address Line 1</label>
                              {/*end::Label*/}
                              {/*begin::Input*/}
                              <input
                                className='form-control form-control-solid'
                                placeholder=''
                                name='address1'
                                value='101, Collins Street'
                              />
                              {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className='d-flex flex-column mb-7 fv-row'>
                              {/*begin::Label*/}
                              <label className='fs-6 fw-semibold mb-2'>Address Line 2</label>
                              {/*end::Label*/}
                              {/*begin::Input*/}
                              <input
                                className='form-control form-control-solid'
                                placeholder=''
                                name='address2'
                              />
                              {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className='d-flex flex-column mb-7 fv-row'>
                              {/*begin::Label*/}
                              <label className='fs-6 fw-semibold mb-2'>Town</label>
                              {/*end::Label*/}
                              {/*begin::Input*/}
                              <input
                                className='form-control form-control-solid'
                                placeholder=''
                                name='city'
                                value='Melbourne'
                              />
                              {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className='row g-9 mb-7'>
                              {/*begin::Col*/}
                              <div className='col-md-6 fv-row'>
                                {/*begin::Label*/}
                                <label className='fs-6 fw-semibold mb-2'>State / Province</label>
                                {/*end::Label*/}
                                {/*begin::Input*/}
                                <input
                                  className='form-control form-control-solid'
                                  placeholder=''
                                  name='state'
                                  value='Victoria'
                                />
                                {/*end::Input*/}
                              </div>
                              {/*end::Col*/}
                              {/*begin::Col*/}
                              <div className='col-md-6 fv-row'>
                                {/*begin::Label*/}
                                <label className='fs-6 fw-semibold mb-2'>Post Code</label>
                                {/*end::Label*/}
                                {/*begin::Input*/}
                                <input
                                  className='form-control form-control-solid'
                                  placeholder=''
                                  name='postcode'
                                  value='3000'
                                />
                                {/*end::Input*/}
                              </div>
                              {/*end::Col*/}
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className='d-flex flex-column mb-7 fv-row'>
                              {/*begin::Label*/}
                              <label className='fs-6 fw-semibold mb-2'>
                                <span>Country</span>
                                <span
                                  className='ms-1'
                                  data-bs-toggle='tooltip'
                                  title='Country of origination'
                                >
                                  <i className='ki-duotone ki-information fs-7'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                  </i>
                                </span>
                              </label>
                              {/*end::Label*/}
                              {/*begin::Input*/}
                              <select
                                name='country'
                                aria-label='Select a Country'
                                data-control='select2'
                                data-placeholder='Select a Country...'
                                className='form-select form-select-solid'
                                data-dropdown-parent='#kt_modal_update_details'
                              >
                                <option value=''>Select a Country...</option>
                                <option value='AF'>Afghanistan</option>
                                <option value='AX'>Aland Islands</option>
                                <option value='AL'>Albania</option>
                                <option value='DZ'>Algeria</option>
                                <option value='AS'>American Samoa</option>
                                <option value='AD'>Andorra</option>
                                <option value='AO'>Angola</option>
                                <option value='AI'>Anguilla</option>
                                <option value='AG'>Antigua and Barbuda</option>
                                <option value='AR'>Argentina</option>
                                <option value='AM'>Armenia</option>
                                <option value='AW'>Aruba</option>
                                <option value='AU'>Australia</option>
                                <option value='AT'>Austria</option>
                                <option value='AZ'>Azerbaijan</option>
                                <option value='BS'>Bahamas</option>
                                <option value='BH'>Bahrain</option>
                                <option value='BD'>Bangladesh</option>
                                <option value='BB'>Barbados</option>
                                <option value='BY'>Belarus</option>
                                <option value='BE'>Belgium</option>
                                <option value='BZ'>Belize</option>
                                <option value='BJ'>Benin</option>
                                <option value='BM'>Bermuda</option>
                                <option value='BT'>Bhutan</option>
                                <option value='BO'>Bolivia, Plurinational State of</option>
                                <option value='BQ'>Bonaire, Sint Eustatius and Saba</option>
                                <option value='BA'>Bosnia and Herzegovina</option>
                                <option value='BW'>Botswana</option>
                                <option value='BR'>Brazil</option>
                                <option value='IO'>British Indian Ocean Territory</option>
                                <option value='BN'>Brunei Darussalam</option>
                                <option value='BG'>Bulgaria</option>
                                <option value='BF'>Burkina Faso</option>
                                <option value='BI'>Burundi</option>
                                <option value='KH'>Cambodia</option>
                                <option value='CM'>Cameroon</option>
                                <option value='CA'>Canada</option>
                                <option value='CV'>Cape Verde</option>
                                <option value='KY'>Cayman Islands</option>
                                <option value='CF'>Central African Republic</option>
                                <option value='TD'>Chad</option>
                                <option value='CL'>Chile</option>
                                <option value='CN'>China</option>
                                <option value='CX'>Christmas Island</option>
                                <option value='CC'>Cocos (Keeling) Islands</option>
                                <option value='CO'>Colombia</option>
                                <option value='KM'>Comoros</option>
                                <option value='CK'>Cook Islands</option>
                                <option value='CR'>Costa Rica</option>
                                <option value='CI'>Côte d'Ivoire</option>
                                <option value='HR'>Croatia</option>
                                <option value='CU'>Cuba</option>
                                <option value='CW'>Curaçao</option>
                                <option value='CZ'>Czech Republic</option>
                                <option value='DK'>Denmark</option>
                                <option value='DJ'>Djibouti</option>
                                <option value='DM'>Dominica</option>
                                <option value='DO'>Dominican Republic</option>
                                <option value='EC'>Ecuador</option>
                                <option value='EG'>Egypt</option>
                                <option value='SV'>El Salvador</option>
                                <option value='GQ'>Equatorial Guinea</option>
                                <option value='ER'>Eritrea</option>
                                <option value='EE'>Estonia</option>
                                <option value='ET'>Ethiopia</option>
                                <option value='FK'>Falkland Islands (Malvinas)</option>
                                <option value='FJ'>Fiji</option>
                                <option value='FI'>Finland</option>
                                <option value='FR'>France</option>
                                <option value='PF'>French Polynesia</option>
                                <option value='GA'>Gabon</option>
                                <option value='GM'>Gambia</option>
                                <option value='GE'>Georgia</option>
                                <option value='DE'>Germany</option>
                                <option value='GH'>Ghana</option>
                                <option value='GI'>Gibraltar</option>
                                <option value='GR'>Greece</option>
                                <option value='GL'>Greenland</option>
                                <option value='GD'>Grenada</option>
                                <option value='GU'>Guam</option>
                                <option value='GT'>Guatemala</option>
                                <option value='GG'>Guernsey</option>
                                <option value='GN'>Guinea</option>
                                <option value='GW'>Guinea-Bissau</option>
                                <option value='HT'>Haiti</option>
                                <option value='VA'>Holy See (Vatican City State)</option>
                                <option value='HN'>Honduras</option>
                                <option value='HK'>Hong Kong</option>
                                <option value='HU'>Hungary</option>
                                <option value='IS'>Iceland</option>
                                <option value='IN'>India</option>
                                <option value='ID'>Indonesia</option>
                                <option value='IR'>Iran, Islamic Republic of</option>
                                <option value='IQ'>Iraq</option>
                                <option value='IE'>Ireland</option>
                                <option value='IM'>Isle of Man</option>
                                <option value='IL'>Israel</option>
                                <option value='IT'>Italy</option>
                                <option value='JM'>Jamaica</option>
                                <option value='JP'>Japan</option>
                                <option value='JE'>Jersey</option>
                                <option value='JO'>Jordan</option>
                                <option value='KZ'>Kazakhstan</option>
                                <option value='KE'>Kenya</option>
                                <option value='KI'>Kiribati</option>
                                <option value='KP'>Korea, Democratic People's Republic of</option>
                                <option value='KW'>Kuwait</option>
                                <option value='KG'>Kyrgyzstan</option>
                                <option value='LA'>Lao People's Democratic Republic</option>
                                <option value='LV'>Latvia</option>
                                <option value='LB'>Lebanon</option>
                                <option value='LS'>Lesotho</option>
                                <option value='LR'>Liberia</option>
                                <option value='LY'>Libya</option>
                                <option value='LI'>Liechtenstein</option>
                                <option value='LT'>Lithuania</option>
                                <option value='LU'>Luxembourg</option>
                                <option value='MO'>Macao</option>
                                <option value='MG'>Madagascar</option>
                                <option value='MW'>Malawi</option>
                                <option value='MY'>Malaysia</option>
                                <option value='MV'>Maldives</option>
                                <option value='ML'>Mali</option>
                                <option value='MT'>Malta</option>
                                <option value='MH'>Marshall Islands</option>
                                <option value='MQ'>Martinique</option>
                                <option value='MR'>Mauritania</option>
                                <option value='MU'>Mauritius</option>
                                <option value='MX'>Mexico</option>
                                <option value='FM'>Micronesia, Federated States of</option>
                                <option value='MD'>Moldova, Republic of</option>
                                <option value='MC'>Monaco</option>
                                <option value='MN'>Mongolia</option>
                                <option value='ME'>Montenegro</option>
                                <option value='MS'>Montserrat</option>
                                <option value='MA'>Morocco</option>
                                <option value='MZ'>Mozambique</option>
                                <option value='MM'>Myanmar</option>
                                <option value='NA'>Namibia</option>
                                <option value='NR'>Nauru</option>
                                <option value='NP'>Nepal</option>
                                <option value='NL'>Netherlands</option>
                                <option value='NZ'>New Zealand</option>
                                <option value='NI'>Nicaragua</option>
                                <option value='NE'>Niger</option>
                                <option value='NG'>Nigeria</option>
                                <option value='NU'>Niue</option>
                                <option value='NF'>Norfolk Island</option>
                                <option value='MP'>Northern Mariana Islands</option>
                                <option value='NO'>Norway</option>
                                <option value='OM'>Oman</option>
                                <option value='PK'>Pakistan</option>
                                <option value='PW'>Palau</option>
                                <option value='PS'>Palestinian Territory, Occupied</option>
                                <option value='PA'>Panama</option>
                                <option value='PG'>Papua New Guinea</option>
                                <option value='PY'>Paraguay</option>
                                <option value='PE'>Peru</option>
                                <option value='PH'>Philippines</option>
                                <option value='PL'>Poland</option>
                                <option value='PT'>Portugal</option>
                                <option value='PR'>Puerto Rico</option>
                                <option value='QA'>Qatar</option>
                                <option value='RO'>Romania</option>
                                <option value='RU'>Russian Federation</option>
                                <option value='RW'>Rwanda</option>
                                <option value='BL'>Saint Barthélemy</option>
                                <option value='KN'>Saint Kitts and Nevis</option>
                                <option value='LC'>Saint Lucia</option>
                                <option value='MF'>Saint Martin (French part)</option>
                                <option value='VC'>Saint Vincent and the Grenadines</option>
                                <option value='WS'>Samoa</option>
                                <option value='SM'>San Marino</option>
                                <option value='ST'>Sao Tome and Principe</option>
                                <option value='SA'>Saudi Arabia</option>
                                <option value='SN'>Senegal</option>
                                <option value='RS'>Serbia</option>
                                <option value='SC'>Seychelles</option>
                                <option value='SL'>Sierra Leone</option>
                                <option value='SG'>Singapore</option>
                                <option value='SX'>Sint Maarten (Dutch part)</option>
                                <option value='SK'>Slovakia</option>
                                <option value='SI'>Slovenia</option>
                                <option value='SB'>Solomon Islands</option>
                                <option value='SO'>Somalia</option>
                                <option value='ZA'>South Africa</option>
                                <option value='KR'>South Korea</option>
                                <option value='SS'>South Sudan</option>
                                <option value='ES'>Spain</option>
                                <option value='LK'>Sri Lanka</option>
                                <option value='SD'>Sudan</option>
                                <option value='SR'>Suriname</option>
                                <option value='SZ'>Swaziland</option>
                                <option value='SE'>Sweden</option>
                                <option value='CH'>Switzerland</option>
                                <option value='SY'>Syrian Arab Republic</option>
                                <option value='TW'>Taiwan, Province of China</option>
                                <option value='TJ'>Tajikistan</option>
                                <option value='TZ'>Tanzania, United Republic of</option>
                                <option value='TH'>Thailand</option>
                                <option value='TG'>Togo</option>
                                <option value='TK'>Tokelau</option>
                                <option value='TO'>Tonga</option>
                                <option value='TT'>Trinidad and Tobago</option>
                                <option value='TN'>Tunisia</option>
                                <option value='TR'>Turkey</option>
                                <option value='TM'>Turkmenistan</option>
                                <option value='TC'>Turks and Caicos Islands</option>
                                <option value='TV'>Tuvalu</option>
                                <option value='UG'>Uganda</option>
                                <option value='UA'>Ukraine</option>
                                <option value='AE'>United Arab Emirates</option>
                                <option value='GB'>United Kingdom</option>
                                <option value='US'>United States</option>
                                <option value='UY'>Uruguay</option>
                                <option value='UZ'>Uzbekistan</option>
                                <option value='VU'>Vanuatu</option>
                                <option value='VE'>Venezuela, Bolivarian Republic of</option>
                                <option value='VN'>Vietnam</option>
                                <option value='VI'>Virgin Islands</option>
                                <option value='YE'>Yemen</option>
                                <option value='ZM'>Zambia</option>
                                <option value='ZW'>Zimbabwe</option>
                              </select>
                              {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                          </div>
                          {/*end::Address form*/}
                        </div>
                        {/*end::Scroll*/}
                      </div>
                      {/*end::Modal body*/}
                      {/*begin::Modal footer*/}
                      <div className='modal-footer flex-center'>
                        {/*begin::Button*/}
                        <button
                          type='reset'
                          className='btn btn-light me-3'
                          data-kt-users-modal-action='cancel'
                        >
                          Discard
                        </button>
                        {/*end::Button*/}
                        {/*begin::Button*/}
                        <button
                          type='submit'
                          className='btn btn-primary'
                          data-kt-users-modal-action='submit'
                        >
                          <span className='indicator-label'>Submit</span>
                          <span className='indicator-progress'>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        </button>
                        {/*end::Button*/}
                      </div>
                      {/*end::Modal footer*/}
                    </form>
                    {/*end::Form*/}
                  </div>
                </div>
              </div>
              {/*end::Modal - Update user details*/}
              {/*begin::Modal - Add schedule*/}
              <div
                className='modal fade'
                id='kt_modal_add_schedule'
                tabIndex={-1}
                aria-hidden='true'
              >
                {/*begin::Modal dialog*/}
                <div className='modal-dialog modal-dialog-centered mw-650px'>
                  {/*begin::Modal content*/}
                  <div className='modal-content'>
                    {/*begin::Modal header*/}
                    <div className='modal-header'>
                      {/*begin::Modal title*/}
                      <h2 className='fw-bold'>Add an Event</h2>
                      {/*end::Modal title*/}
                      {/*begin::Close*/}
                      <div
                        className='btn btn-icon btn-sm btn-active-icon-primary'
                        data-kt-users-modal-action='close'
                      >
                        <i className='ki-duotone ki-cross fs-1'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </div>
                      {/*end::Close*/}
                    </div>
                    {/*end::Modal header*/}
                    {/*begin::Modal body*/}
                    <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                      {/*begin::Form*/}
                      <form id='kt_modal_add_schedule_form' className='form' action='#'>
                        {/*begin::Input group*/}
                        <div className='fv-row mb-7'>
                          {/*begin::Label*/}
                          <label className='required fs-6 fw-semibold form-label mb-2'>
                            Event Name
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            name='event_name'
                            value=''
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className='fv-row mb-7'>
                          {/*begin::Label*/}
                          <label className='fs-6 fw-semibold form-label mb-2'>
                            <span className='required'>Date & Time</span>
                            <span
                              className='ms-2'
                              data-bs-toggle='popover'
                              data-bs-trigger='hover'
                              data-bs-html='true'
                              data-bs-content='Select a date & time.'
                            >
                              <i className='ki-duotone ki-information fs-7'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                              </i>
                            </span>
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            className='form-control form-control-solid'
                            placeholder='Pick date & time'
                            name='event_datetime'
                            id='kt_modal_add_schedule_datepicker'
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className='fv-row mb-7'>
                          {/*begin::Label*/}
                          <label className='required fs-6 fw-semibold form-label mb-2'>
                            Event Organiser
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            name='event_org'
                            value=''
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className='fv-row mb-7'>
                          {/*begin::Label*/}
                          <label className='required fs-6 fw-semibold form-label mb-2'>
                            Send Event Details To
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            id='kt_modal_add_schedule_tagify'
                            type='text'
                            className='form-control form-control-solid'
                            name='event_invitees'
                            value='smith@kpmg.com, melody@altbox.com'
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Actions*/}
                        <div className='text-center pt-15'>
                          <button
                            type='reset'
                            className='btn btn-light me-3'
                            data-kt-users-modal-action='cancel'
                          >
                            Discard
                          </button>
                          <button
                            type='submit'
                            className='btn btn-primary'
                            data-kt-users-modal-action='submit'
                          >
                            <span className='indicator-label'>Submit</span>
                            <span className='indicator-progress'>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          </button>
                        </div>
                        {/*end::Actions*/}
                      </form>
                      {/*end::Form*/}
                    </div>
                    {/*end::Modal body*/}
                  </div>
                  {/*end::Modal content*/}
                </div>
                {/*end::Modal dialog*/}
              </div>
              {/*end::Modal - Add schedule*/}
              {/*begin::Modal - Add task*/}
              <div className='modal fade' id='kt_modal_add_task' tabIndex={-1} aria-hidden='true'>
                {/*begin::Modal dialog*/}
                <div className='modal-dialog modal-dialog-centered mw-650px'>
                  {/*begin::Modal content*/}
                  <div className='modal-content'>
                    {/*begin::Modal header*/}
                    <div className=' modal-header'>
                      {/*begin::Modal title*/}
                      <h2 className='fw-bold '>Add a Task</h2>

                      {/*end::Modal title*/}

                      {/*begin::Close*/}
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <i className='ki-duotone ki-cross fs-1'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </div>

                      {/*end::Close*/}
                    </div>
                    <div className='d-flex justify-content-start px-15 pt-5 flex-column'>
                      <span className='text-muted fw-semibold fs-5'>Tests</span>
                      <span className='card-label fw-bold fs-3'>{test.name}</span>
                    </div>
                    {/*end::Modal header*/}
                    {/*begin::Modal body*/}
                    <div className='modal-body scroll-y mx-xl-10 '>
                      {/*begin::Form*/}
                      <form id='kt_modal_add_task_form' className='form' action='#'>
                        {/*begin::Input group*/}
                        <div className='fv-row mb-7'>
                          {/*begin::Label*/}
                          <label className='required fs-6 fw-semibold form-label mb-2'>Name</label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            name='task_name'
                            value=''
                            placeholder='{{test_name}}'
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className='fv-row mb-7'>
                          {/*begin::Label*/}
                          <label className='fs-6 fw-semibold form-label mb-2'>Description</label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <textarea className='form-control form-control-solid rounded-3'></textarea>
                          {/*end::Input*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className='fv-row mb-7'>
                          {/*begin::Label*/}
                          <label className='required fs-6 fw-semibold form-label mb-2'>
                            Assignee
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            name='task_name'
                            value=''
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className='fv-row mb-7'>
                          {/*begin::Label*/}
                          <label className='fs-6 fw-semibold form-label mb-2'>
                            <span className='required'>Due Date</span>
                            <span
                              className='ms-2'
                              data-bs-toggle='popover'
                              data-bs-trigger='hover'
                              data-bs-html='true'
                              data-bs-content='Select a due date.'
                            >
                              <i className='ki-duotone ki-information fs-7'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                              </i>
                            </span>
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            className='form-control form-control-solid'
                            type='date'
                            placeholder='Pick date'
                            name='task_duedate'
                            id='kt_modal_add_task_datepicker'
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Input group*/}
                        <div className='separator'></div>
                        {/*begin::Actions*/}
                        <div className='d-flex  flex-row pt-10'>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input className='form-check-input' type='checkbox' value='1' />
                            <span className='px-5 text-dark'>
                              Auto-complete when test status changes to Pass
                            </span>
                          </div>
                          <button
                            type='reset'
                            className='btn btn-light me-3'
                            data-bs-dismiss='modal'
                        aria-label='Close'
                          >
                            Cancel
                          </button>
                          <button
                            type='submit'
                            className='btn btn-primary'
                            data-kt-users-modal-action='submit'
                          >
                            <span className='indicator-label'>Add</span>
                            <span className='indicator-progress'>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          </button>
                        </div>
                        {/*end::Actions*/}
                      </form>
                      {/*end::Form*/}
                    </div>
                    {/*end::Modal body*/}
                  </div>
                  {/*end::Modal content*/}
                </div>
                {/*end::Modal dialog*/}
              </div>
              {/*end::Modal - Add task*/}
              {/*begin::Modal - Update email*/}
              <div
                className='modal fade'
                id='kt_modal_update_email'
                tabIndex={-1}
                aria-hidden='true'
              >
                {/*begin::Modal dialog*/}
                <div className='modal-dialog modal-dialog-centered mw-650px'>
                  {/*begin::Modal content*/}
                  <div className='modal-content'>
                    {/*begin::Modal header*/}
                    <div className='modal-header'>
                      {/*begin::Modal title*/}
                      <h2 className='fw-bold'>Update Email Address</h2>
                      {/*end::Modal title*/}
                      {/*begin::Close*/}
                      <div
                        className='btn btn-icon btn-sm btn-active-icon-primary'
                        data-kt-users-modal-action='close'
                      >
                        <i className='ki-duotone ki-cross fs-1'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </div>
                      {/*end::Close*/}
                    </div>
                    {/*end::Modal header*/}
                    {/*begin::Modal body*/}
                    <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                      {/*begin::Form*/}
                      <form id='kt_modal_update_email_form' className='form' action='#'>
                        {/*begin::Notice*/}
                        {/*begin::Notice*/}
                        <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6'>
                          {/*begin::Icon*/}
                          <i className='ki-duotone ki-information fs-2tx text-primary me-4'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>
                          {/*end::Icon*/}
                          {/*begin::Wrapper*/}
                          <div className='d-flex flex-stack flex-grow-1'>
                            {/*begin::Content*/}
                            <div className='fw-semibold'>
                              <div className='fs-6 text-gray-700'>
                                Please note that a valid email address is required to complete the
                                email verification.
                              </div>
                            </div>
                            {/*end::Content*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Notice*/}
                        {/*end::Notice*/}
                        {/*begin::Input group*/}
                        <div className='fv-row mb-7'>
                          {/*begin::Label*/}
                          <label className='fs-6 fw-semibold form-label mb-2'>
                            <span className='required'>Email Address</span>
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            className='form-control form-control-solid'
                            placeholder=''
                            name='profile_email'
                            value='smith@kpmg.com'
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Actions*/}
                        <div className='text-center pt-15'>
                          <button
                            type='reset'
                            className='btn btn-light me-3'
                            data-kt-users-modal-action='cancel'
                          >
                            Discard
                          </button>
                          <button
                            type='submit'
                            className='btn btn-primary'
                            data-kt-users-modal-action='submit'
                          >
                            <span className='indicator-label'>Submit</span>
                            <span className='indicator-progress'>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          </button>
                        </div>
                        {/*end::Actions*/}
                      </form>
                      {/*end::Form*/}
                    </div>
                    {/*end::Modal body*/}
                  </div>
                  {/*end::Modal content*/}
                </div>
                {/*end::Modal dialog*/}
              </div>
              {/*end::Modal - Update email*/}
              {/*begin::Modal - Update password*/}
              <div
                className='modal fade'
                id='kt_modal_update_password'
                tabIndex={-1}
                aria-hidden='true'
              >
                {/*begin::Modal dialog*/}
                <div className='modal-dialog modal-dialog-centered mw-650px'>
                  {/*begin::Modal content*/}
                  <div className='modal-content'>
                    {/*begin::Modal header*/}
                    <div className='modal-header'>
                      {/*begin::Modal title*/}
                      <h2 className='fw-bold'>Update Password</h2>
                      {/*end::Modal title*/}
                      {/*begin::Close*/}
                      <div
                        className='btn btn-icon btn-sm btn-active-icon-primary'
                        data-kt-users-modal-action='close'
                      >
                        <i className='ki-duotone ki-cross fs-1'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </div>
                      {/*end::Close*/}
                    </div>
                    {/*end::Modal header*/}
                  </div>
                  {/*end::Modal content*/}
                </div>
                {/*end::Modal dialog*/}
              </div>
              {/*end::Modal - Update password*/}

              {/*begin::Modal - Add task*/}
              <div
                className='modal fade'
                id='kt_modal_add_one_time_password'
                tabIndex={-1}
                aria-hidden='true'
              >
                {/*begin::Modal dialog*/}
                <div className='modal-dialog modal-dialog-centered mw-650px'>
                  {/*begin::Modal content*/}
                  <div className='modal-content'>
                    {/*begin::Modal header*/}
                    <div className='modal-header'>
                      {/*begin::Modal title*/}
                      <h2 className='fw-bold'>Enable One Time Password</h2>
                      {/*end::Modal title*/}
                      {/*begin::Close*/}
                      <div
                        className='btn btn-icon btn-sm btn-active-icon-primary'
                        data-kt-users-modal-action='close'
                      >
                        <i className='ki-duotone ki-cross fs-1'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </div>
                      {/*end::Close*/}
                    </div>
                    {/*end::Modal header*/}
                    {/*begin::Modal body*/}
                    <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                      {/*begin::Form*/}
                      <form className='form' id='kt_modal_add_one_time_password_form'>
                        {/*begin::Label*/}
                        <div className='fw-bold mb-9'>
                          Enter the new phone number to receive an SMS to when you log in.
                        </div>
                        {/*end::Label*/}
                        {/*begin::Input group*/}
                        <div className='fv-row mb-7'>
                          {/*begin::Label*/}
                          <label className='fs-6 fw-semibold form-label mb-2'>
                            <span className='required'>Mobile number</span>
                            <span
                              className='ms-2'
                              data-bs-toggle='tooltip'
                              title='A valid mobile number is required to receive the one-time password to validate your account login.'
                            >
                              <i className='ki-duotone ki-information fs-7'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                              </i>
                            </span>
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            name='otp_mobile_number'
                            placeholder='+6123 456 789'
                            value=''
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Separator*/}
                        <div className='separator saperator-dashed my-5'></div>
                        {/*end::Separator*/}
                        {/*begin::Input group*/}
                        <div className='fv-row mb-7'>
                          {/*begin::Label*/}
                          <label className='fs-6 fw-semibold form-label mb-2'>
                            <span className='required'>Email</span>
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type='email'
                            className='form-control form-control-solid'
                            name='otp_email'
                            value='smith@kpmg.com'
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className='fv-row mb-7'>
                          {/*begin::Label*/}
                          <label className='fs-6 fw-semibold form-label mb-2'>
                            <span className='required'>Confirm password</span>
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type='password'
                            className='form-control form-control-solid'
                            name='otp_confirm_password'
                            value=''
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Actions*/}
                        <div className='text-center pt-15'>
                          <button
                            type='reset'
                            className='btn btn-light me-3'
                            data-kt-users-modal-action='cancel'
                          >
                            Cancel
                          </button>
                          <button
                            type='submit'
                            className='btn btn-primary'
                            data-kt-users-modal-action='submit'
                          >
                            <span className='indicator-label'>Submit</span>
                            <span className='indicator-progress'>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          </button>
                        </div>
                        {/*end::Actions*/}
                      </form>
                      {/*end::Form*/}
                    </div>
                    {/*end::Modal body*/}
                  </div>
                  {/*end::Modal content*/}
                </div>
                {/*end::Modal dialog*/}
              </div>
              {/*end::Modal - Add task*/}
              {/*end::Modals*/}
            </div>
            {/*end::Content container*/}
          </div>
        </div>
      )}
      {/*end::Card body*/}
    </div>
  )
}

export {TestDetailsManagement}
