import db from '../../../../services/firebase'

import {collection, getDocs, getDoc, addDoc, updateDoc, deleteDoc, doc} from 'firebase/firestore'

const controlCollectionRef = collection(db, 'control_templates')

class ControlDataService {
  addControl = (newPolicy) => {
    return addDoc(controlCollectionRef, newPolicy)
  }

  updateControl = (id, updateControl) => {
    const policyDoc = doc(db, 'control_templates', id)
    return updateDoc(policyDoc, updateControl)
  }

  deleteControl = (id) => {
    const policyDoc = doc(db, 'control_templates', id)
    return deleteDoc(policyDoc)
  }

  getAllControls = () => {
    return getDocs(controlCollectionRef)
  }

  getControl = (id) => {
    const policyDoc = doc(db, 'control_templates', id)
    return getDoc(policyDoc)
  }
}

export default new ControlDataService()
