import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {SettingPageTitle} from '../components/header/SettingPageTitle'

const MSP = () => {
  const [activeTab, setActiveTab] = useState('roles')
  const location = useLocation()

  useEffect(() => {
    // Fetch control data when the component mounts
    if (location.pathname.includes('auditors')) {
      console.log('Setting active tab to settingadvanced')
      setActiveTab('auditors')
    } else if (location.pathname.includes('msp')) {
      console.log('Setting active tab to settingframework')
      setActiveTab('msp')
    } else {
      console.log('Setting active tab to information')
      setActiveTab('users')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <SettingPageTitle
        icon='Settings.svg'
        title='Company Settings'
        desc=' Manage your company settings'
        // breadcrumbs={usersBreadcrumbs}
      >
        Personnel Management
      </SettingPageTitle>
      <div className={`card mb-5 mb-xl-8  `}>
        <div className='px-10 '>
          <div className='d-flex  h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap '>
              <li className='nav-item'>
                <Link
                  to={`/company-settings/users`}
                  className={`nav-link me-6 ${
                    activeTab === 'users' ? 'text-active-primary active' : ''
                  }`}
                >
                  Your organization
                </Link>
              </li>

              <li className='nav-item'>
                <Link
                  to={'/company-settings/users/auditors'}
                  className={`nav-link me-6 ${
                    activeTab === 'auditors' ? 'text-active-primary active' : ''
                  }`}
                >
                  Auditors
                </Link>
              </li>

              <li className='nav-item'>
                <Link
                  to={'/company-settings/users/msp'}
                  className={`nav-link me-6 ${
                    activeTab === 'msp' ? 'text-active-primary active' : ''
                  }`}
                >
                  Managed Service Providers
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className='p-3'>
          <div className='card mb-1 mb-xl-8 mt-4' style={{background: '#edece8'}}>
            <div className='card-header border-0 pt-5 mb-1'>
              <div className='row align-items-center'>
                <div className='col'>
                  <span className='card-label fw-bold fs-2'>Accelerate your business growth</span>
                  <p className='text-muted fs-7'>
                    The SecureSlate Managed Service Provider (MSP) program enables you to leverage a
                    security and compliance service for implementing robust security practices.
                    Transition your account to an MSP to initiate the process.
                  </p>
                </div>
              </div>
            </div>

            <div className='row mx-5'>
              <div className='form-group mb-5'>
                <button className='btn btn-primary btn-sm'>Transfer account</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MSP
