import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import vendorServices from '../services/vendor.services'

const DetailVendor = () => {
  const [activeTab, setActiveTab] = useState('details')
  const [vendorData, setVendorData] = useState<any>(null)
  const {id} = useParams()

  useEffect(() => {
    const fetchVendorData = async () => {
      try {
        const vendorId = id
        const vendor = await vendorServices.getVendor(vendorId)
        setVendorData(vendor.data())
        console.log(vendorData)
      } catch (error) {
        console.log(error)
      }
    }

    fetchVendorData()
  }, [id])

  return (
    <>
      {vendorData && (
        <>
          <div className='card mb-5 mb-xl-8'>
            <div className='card-header border-0 pt-5 mb-5'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-65px me-5'>
                  <img src={vendorData.imgUrl} alt='' />
                </div>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='card-label fw-bold fs-6'>Vendor</span>
                  <h1 className='text-bold fw-bold'>{vendorData.companyName}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className='card card-custom'>
            <div className='pt-4 pb-0 px-10'>
              <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-3x border-transparent fs-5 fw-semibold flex-nowrap'>
                  <li className='nav-item'>
                    <Link
                      to={`/vendor-management/detail-vendor/${id}`}
                      className={`nav-link ${
                        activeTab === 'details' ? 'text-active-primary active' : ''
                      }`}
                    >
                      Details
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      to={'/vendor-risk/asana/documents'}
                      className={`nav-link me-6 ${
                        activeTab === 'documents' ? 'text-active-primary active' : ''
                      }`}
                    >
                      Documents
                    </Link>
                  </li>
                  {/*end::Nav item*/}
                </ul>
              </div>
              {/*begin::Navs*/}
            </div>
            <div className='row g-5 g-xxl-8 p-10'>
              <div className='col-xl-6' style={{width: '40%'}}>
                <h2 className='mb-5'>Main Info</h2>
                <form className='form'>
                  <div className='form-group'>
                    <label className='text-muted mb-2'>Company name</label>
                    <input
                      type='text'
                      className='form-control form-control-solid form-control-sm'
                      placeholder='Company name'
                      value={vendorData.companyName}
                    />
                    {/* <span className='form-text text-muted'>Please enter Risk Source</span> */}
                  </div>
                  <div className='form-group'>
                    <label className='text-muted mt-5 mb-3'>Website</label>
                    <div className='input-group input-group-sm '>
                      <div className='input-group-prepend'>
                        <span className='input-group-text '>https://</span>
                      </div>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Website'
                        aria-describedby='basic-addon2'
                        value={vendorData.website}
                      />
                    </div>
                  </div>
                  <div className='form-group'>
                    <label className='text-muted mt-5 mb-3'>Security</label>
                    <div className='input-group input-group-sm '>
                      <div className='input-group-prepend'>
                        <span className='input-group-text '>https://</span>
                      </div>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Website'
                        aria-describedby='basic-addon2'
                        value={vendorData.security}
                      />
                    </div>
                  </div>
                  <div className='form-group'>
                    <label className='text-muted mt-5 mb-3'>Vendor Owner</label>
                    <select className='form-control form-control-solid ' id='exampleSelectl'>
                      <option> {vendorData.website}</option>
                      <option>Jackson Hartman</option>
                      <option>Jack Hartman</option>
                      <option>Sam Smith</option>
                    </select>
                  </div>
                  <div className='form-group'>
                    <label className='text-muted mt-5 mb-3'>Services Provided</label>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='Services Provided'
                      value={vendorData.serviceProvided}
                    />
                    {/* <span className='form-text text-muted'>
                        We'll never share your email with anyone else
                      </span> */}
                  </div>

                  <div className='form-group'>
                    <label className='text-muted mt-5 mb-3'>Date of engagement</label>
                    <input
                      type='date'
                      className='form-control form-control-solid'
                      placeholder=''
                      value={vendorData.dateOfEngagement}
                    />
                    {/* <span className='form-text text-muted'>
                        We'll never share your email with anyone else
                      </span> */}
                  </div>
                  <div className='form-group'>
                    <label className='text-muted mt-5 mb-3'>Date of last review</label>
                    <input
                      type='date'
                      className='form-control form-control-solid'
                      placeholder=''
                      value={vendorData.dateOfLastReview}
                    />
                  </div>
                  <div className='form-group'>
                    <label className='text-muted mt-5 mb-3'>Authentication type </label>
                    <label className='form-check form-check-custom form-check-solid p-5'>
                      <input
                        className='form-check-input'
                        type='radio'
                        // checked={true}
                        // value='Password'
                        // name='authenticationType'
                        // value={vendorData.authenticationType}
                        checked={vendorData.authenticationType === 'Password'}
                        name='authenticationType'
                        value='Password'
                      />
                      <span style={{marginLeft: 10, marginRight: 20}}> Password</span>

                      <input
                        className='form-check-input'
                        type='radio'
                        // checked={false}
                        // value='Single sign-in'
                        // value={vendorData.authenticationType}
                        // name='authenticationType'
                        checked={vendorData.authenticationType === 'Single sign-in'}
                        name='authenticationType'
                        value='Single sign-in'
                      />
                      <span style={{marginLeft: 10, marginRight: 20}}>Single sign-in</span>
                    </label>
                  </div>
                  <div className='form-group'>
                    <label className='text-muted mt-5 mb-3'>Two factor enabled</label>

                    <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value={vendorData.twoFactorEnable}
                        name='notifications'
                        defaultChecked={true}
                      />
                    </div>
                  </div>
                  <div className='form-group'>
                    <label className='text-muted mt-5 mb-3'>
                      Do the vendors third party audit reports(SOC 2, ISO 27001) evident any area of
                      concerns?{' '}
                    </label>
                    <textarea
                      className='form-control form-control-solid'
                      id='description'
                      rows={3}
                      value={vendorData.vendorThirdParty}
                    ></textarea>
                  </div>
                  <div className='form-group'>
                    <label className='text-muted mt-5 mb-3'>Other information</label>
                    <textarea
                      className='form-control form-control-solid'
                      id='description'
                      rows={3}
                      value={vendorData.otherInfo}
                    ></textarea>
                  </div>
                </form>
              </div>
              <div className='col-xl-6' style={{width: '40%', marginLeft: 50}}>
                <h2 className='mb-7'>Audit Information</h2>
                <form className='form'>
                  <div className='form-group'>
                    <h4 className='mb-5'>Environment Type</h4>
                    <label className='text-muted fw-semibold fs-6'>
                      What type of vendor is this?
                    </label>
                    <div className='checkbox-list'>
                      <label className='form-check form-check-custom form-check-solid m-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          //   checked={true}
                          // value={hdEncryption}
                          value='Production'
                          checked={vendorData.environmentType['Production']}
                          name='Production'
                          style={{width: 15, height: 15, marginRight: 10}}
                        />
                        <span></span>
                        Production
                      </label>

                      <label className='form-check form-check-custom form-check-solid m-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          //   checked={true}
                          // value={hdEncryption}

                          value='Development'
                          checked={vendorData.environmentType['Development']}
                          name='Development'
                          style={{width: 15, height: 15, marginRight: 10}}
                        />
                        <span></span>
                        Development
                      </label>
                    </div>
                  </div>
                  <div className='form-group'>
                    <h4 className='mt-10 mb-5'>Data Management</h4>
                    <label className='fw-semibold '>
                      What type of data does system store, process or interface with?
                    </label>
                    <div>
                      <label className='text-muted fw-semibold fs-6 mt-3'>
                        Publicly available data
                      </label>
                    </div>

                    <div className='checkbox-list'>
                      <label className='form-check form-check-custom form-check-solid m-3'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          //   checked={true}
                          // value={hdEncryption}
                          //   name='Checkboxes4'
                          style={{width: 15, height: 15, marginRight: 10}}
                          value='Generic_informational_or_Marketing_material'
                          checked={
                            vendorData.publiclyAvailableData[
                              'Generic_informational_or_Marketing_material'
                            ]
                          }
                          name='Generic_informational_or_Marketing_material'
                        />
                        <span></span>
                        Generic, informational, or Marketing material
                      </label>
                    </div>
                    <label className='text-muted fw-semibold fs-6'>
                      Protected Corporate Operation
                    </label>

                    <div className='checkbox-list'>
                      <label className='form-check form-check-custom form-check-solid m-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          //   checked={true}
                          // value={hdEncryption}
                          //   name='Checkboxes4'
                          style={{width: 15, height: 15, marginRight: 10}}
                          value='employmentInformation'
                          checked={vendorData.publiclyAvailableData['employmentInformation']}
                          name='employmentInformation'
                        />
                        <span></span>
                        Employment Informtion
                      </label>
                      <label className='form-check form-check-custom form-check-solid m-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          //   checked={true}
                          // value={hdEncryption}
                          //   name='Checkboxes4'
                          style={{width: 15, height: 15, marginRight: 10}}
                          value='corporativeProprietaryInformation'
                          checked={
                            vendorData.protectedCorporateOperationData[
                              'corporativeProprietaryInformation'
                            ]
                          }
                          name='corporativeProprietaryInformation'
                        />
                        <span></span>
                        Corporative Proprietary Information
                      </label>
                    </div>

                    <label className='text-muted fw-semibold fs-6'>Customer Information</label>

                    <div className='checkbox-list'>
                      <label className='form-check form-check-custom form-check-solid m-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          //   checked={true}
                          // value={hdEncryption}
                          //   name='Checkboxes4'
                          style={{width: 15, height: 15, marginRight: 10}}
                          value='PublicCustomerInformation'
                          checked={vendorData.customerInformation['PublicCustomerInformation']}
                          name='PublicCustomerInformation'
                        />
                        <span></span>
                        Public Customer Information
                      </label>
                      <label className='form-check form-check-custom form-check-solid m-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          //   checked={true}
                          // value={hdEncryption}
                          //   name='Checkboxes4'
                          style={{width: 15, height: 15, marginRight: 10}}
                          value='NonPublicCustomerInformation'
                          checked={vendorData.customerInformation['NonPublicCustomerInformation']}
                          name='PublicCustomerInformation'
                        />
                        <span></span>
                        Non-Public Customer Information
                      </label>
                    </div>

                    <label className='text-muted fw-semibold fs-6'>
                      Regulation-protected customer, consumer, and employee information
                    </label>

                    <div className='checkbox-list'>
                      <label className='form-check form-check-custom form-check-solid m-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          //   checked={true}
                          // value={hdEncryption}
                          //   name='Checkboxes4'
                          style={{width: 15, height: 15, marginRight: 10}}
                          value='GDPR_regulated_EU_resident_data'
                          checked={
                            vendorData.regulationProtectedInformation[
                              'GDPR_regulated_EU_resident_data'
                            ]
                          }
                          name='GDPR_regulated_EU_resident_data'
                        />
                        <span></span>
                        GDPR regulated EU resident data
                      </label>
                      <label className='form-check form-check-custom form-check-solid m-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          //   checked={true}
                          // value={hdEncryption}
                          //   name='Checkboxes4'
                          style={{width: 15, height: 15, marginRight: 10}}
                          value='HIPAA_or_HITRUST_protected_data_PHI'
                          checked={
                            vendorData.regulationProtectedInformation[
                              'HIPAA_or_HITRUST_protected_data_PHI'
                            ]
                          }
                          name='HIPAA_or_HITRUST_protected_data_PHI'
                        />
                        <span></span>
                        HIPAA or HITRUST protected data(PHI)
                      </label>

                      <div className='checkbox-list'>
                        <label className='form-check form-check-custom form-check-solid m-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            //   checked={true}
                            // value={hdEncryption}
                            // name='Checkboxes4'
                            style={{width: 15, height: 15, marginRight: 10}}
                            value='PCI_DSS_Protected_Data'
                            checked={
                              vendorData.regulationProtectedInformation['PCI_DSS_Protected_Data']
                            }
                            name='PCI_DSS_Protected_Data'
                          />
                          <span></span>
                          PCI-DSS Protected Data
                        </label>
                        <label className='form-check form-check-custom form-check-solid m-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            //   checked={true}
                            // value={hdEncryption}
                            // name='Checkboxes4'
                            style={{width: 15, height: 15, marginRight: 10}}
                            value='CCPA_Protected_Data'
                            checked={
                              vendorData.regulationProtectedInformation['CCPA_Protected_Data']
                            }
                            name='CCPA_Protected_Data'
                          />
                          <span></span>
                          CCPA Protected Data
                        </label>
                        {/* <label className='text-muted fw-semibold fs-6'>Customer Information</label> */}
                      </div>
                      <h4 className='mt-10 mb-5'>Operational Reliance</h4>
                      <div className='col-9 col-form-label'>
                        <div className='checkbox-list'>
                          {/* <label>Operational Reliance</label> */}
                          <label className='form-check form-check-custom form-check-solid p-0'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='reliance'
                              checked={vendorData.reliance === 'Low'}
                              value='Low'
                            />
                            <span style={{marginLeft: 10, marginRight: 20}}> Low</span>

                            <input
                              className='form-check-input'
                              type='radio'
                              name='reliance'
                              checked={vendorData.reliance === 'Medium'}
                              value='Medium'
                            />
                            <span style={{marginLeft: 10, marginRight: 20}}>Medium</span>

                            <input
                              className='form-check-input'
                              type='radio'
                              name='reliance'
                              checked={vendorData.reliance === 'Hign'}
                              value='High'
                            />
                            <span style={{marginLeft: 10, marginRight: 20}}>High</span>
                          </label>
                        </div>
                      </div>
                      <h4 className='mt-10 mb-5'>Risk Level</h4>
                      <div className='col-9 col-form-label'>
                        <div className='checkbox-list'>
                          {/* <label>Risk Level</label> */}
                          <label className='form-check form-check-custom form-check-solid p-0'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='riskLevel'
                              checked={vendorData.riskLevel === 'Low Risk Level'}
                              value='Low Risk Level'
                            />
                            <span style={{marginLeft: 10, marginRight: 20}}> Low</span>

                            <input
                              className='form-check-input'
                              type='radio'
                              name='riskLevel'
                              checked={vendorData.riskLevel === 'Medium Risk Level'}
                              value='Medium Risk Level'
                            />
                            <span style={{marginLeft: 10, marginRight: 20}}>Medium</span>

                            <input
                              className='form-check-input'
                              type='radio'
                              name='riskLevel'
                              checked={vendorData.riskLevel === 'High Risk Level'}
                              value='High Risk Level'
                            />
                            <span style={{marginLeft: 10, marginRight: 20}}>High</span>
                          </label>
                        </div>
                      </div>

                      {/*  
                      //archive checkbox if here if needed            
                      <h4 className='mt-10 mb-5'>Archive</h4>
                      <div className='col-9 col-form-label'>
                        <div className='checkbox-list'>
                          <label>Archive</label>
                          <label className='form-check form-check-custom form-check-solid p-5'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='archive'
                              checked={vendorData.archive === 'true'}
                              value='true'
                            />
                            <span style={{marginLeft: 10, marginRight: 20}}> True</span>

                            <input
                              className='form-check-input'
                              type='radio'
                              name='archive'
                              checked={vendorData.archive === 'false'}
                              value='false'
                            />
                            <span style={{marginLeft: 10, marginRight: 20}}>False</span>

                           
                          </label>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className='modal-footer m-5'>
              <Link to='/vendor-risk' className='btn btn-secondary' style={{marginRight: 20}}>
                Back
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default DetailVendor
