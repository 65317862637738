import React, {useEffect, useRef, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage'
import db, {storage} from '../../../../services/firebase'
import * as Yup from 'yup'
import {
  getAuth,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from 'firebase/auth'
import {
  DocumentData,
  DocumentReference,
  QueryDocumentSnapshot,
  WriteBatch,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import {KTCard, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {toast, ToastContainer} from 'react-toastify'

interface FormData {
  id?: string
  displayName: string
  legalName: string
  url: string
  mailingAddress: string
  telephone: string
  companyInfoUrl: string
  companylogo: string
}
const initialState: FormData = {
  displayName: '',
  legalName: '',
  url: '',
  mailingAddress: '',
  telephone: '',
  companyInfoUrl: '',
  companylogo: '',
}
const validationSchema = Yup.object().shape({
  fullName: Yup.string().notRequired(),
  legalName: Yup.string().notRequired(),
  url: Yup.string().url('Invalid URL').notRequired(),
  mailingAddress: Yup.string().required('Mailing address is required'),
  telephone: Yup.string().required('phonenumber is required').notRequired(),
  companyInfoUrl: Yup.string().url('Invalid URL').notRequired(),
  companylogo: Yup.string().url('Invalid URL').notRequired(),
})
const CompanySettings = () => {
  const {currentUser} = useAuth()

  const [progress, setProgress] = useState<number | null>(null)

  const [message, setMessage] = useState({error: false, msg: ''})
  const [showAlert, setShowAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingpw, setIsLoadingpw] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [currentPasswordError, setCurrentPasswordError] = useState('')
  const [newPasswordError, setNewPasswordError] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [matchPasswordError, setMatchPasswordError] = useState('')
  const fileInputRef = useRef(null)
  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const [previewUrl, setPreviewUrl] = useState(blankImg)
  const [image, setImage] = useState(null)
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState(currentUser?.email)
  const [users, setUsers] = useState<any[]>([])
  const currentDate = new Date() // No need to import the Firestore module
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1 // Note: The month is zero-based
  const day = currentDate.getDate()

  const todaysDate = month + '/' + day + '/' + year

  useEffect(() => {
    const fetchUserData = () => {
      if (currentUser?.tenantId) {
        const tenantRef = doc(db, 'tenants', currentUser.tenantId)
        const userDetailRef = doc(tenantRef, 'settings', 'user_detail')
  
        const unsubscribe = onSnapshot(userDetailRef, (docSnap) => {
          if (docSnap.exists()) {
            const userData = docSnap.data()
            setFirstname(userData.firstname || '')
            setLastname(userData.lastname || '')
            setEmail(userData.email || currentUser?.email)
            if (userData.image_url) {
              setPreviewUrl(userData.image_url)
            }
          }
        })
  
        return () => unsubscribe()
      }
    }
  
    fetchUserData()
  }, [currentUser])
  

  useEffect(() => {
    getPersonnel()
  }, [])

  const getPersonnel = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const email = currentUser?.email || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const employeesRef = collection(tenantRef, 'employees')

      const unsubscribe = onSnapshot(
        query(employeesRef, where('email', '==', email)),
        (snapshot) => {
          const employeeData = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
          setUsers(employeeData)
        }
      )

      return unsubscribe
    } catch (error) {
      console.error('Error getting personnel:', error)
    }
  }

  const updateUsername = async (employeeId: string, newUsername: string) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const employeeRef = doc(tenantRef, 'employees', employeeId)

      await updateDoc(employeeRef, {
        fullName: newUsername,
        updated_at: new Date(),
      })

      console.log('Username updated successfully')
    } catch (error) {
      console.error('Error updating username:', error)
    }
  }

  async function updateNameAcrossCollections(
    oldName: string,
    newName: string,
    tenantId: string,
    collectionName: string
  ) {
    if (!oldName || oldName === newName) {
      console.log('No name change detected or old name is empty. Skipping update.')
      return
    }

    const collectionRef = collection(db, 'tenants', tenantId, collectionName)
    const q = query(collectionRef, where('owner', '==', oldName))
    const querySnapshot = await getDocs(q)

    const batch = db.batch() as unknown as WriteBatch

    const updateDocument = (docRef: DocumentReference, newName: string) => {
      batch.update(docRef, {owner: newName})
    }

    try {
      if (querySnapshot.empty) {
        console.log(
          `No documents found with owner name "${oldName}" in collection: ${collectionName}`
        )
        return
      }

      querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
        updateDocument(doc.ref, newName)
      })

      await batch.commit()
      console.log(`Successfully updated documents in collection: ${collectionName}`)
    } catch (error) {
      console.error(`Failed to update documents in collection: ${collectionName}. Error: ${error}`)
    }
  }

  async function updateNameAcrossCollections2(
    oldName: string,
    newName: string,
    tenantId: string,
    collectionName: string
  ) {
    if (!oldName || oldName === newName) {
      console.log('No name change detected or old name is empty. Skipping update.')
      return
    }

    const collectionRef = collection(db, 'tenants', tenantId, collectionName)
    const q = query(collectionRef, where('full_name', '==', oldName))
    const querySnapshot = await getDocs(q)

    const batch = db.batch() as unknown as WriteBatch

    const updateDocument = (docRef: DocumentReference, newName: string) => {
      batch.update(docRef, {full_name: newName})
    }

    try {
      if (querySnapshot.empty) {
        console.log(
          `No documents found with owner name "${oldName}" in collection: ${collectionName}`
        )
        return
      }

      querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
        updateDocument(doc.ref, newName)
      })

      await batch.commit()
      console.log(`Successfully updated documents in collection: ${collectionName}`)
    } catch (error) {
      console.error(`Failed to update documents in collection: ${collectionName}. Error: ${error}`)
    }
  }

  async function updateNameAcrossCollections3(
    oldName: string,
    newName: string,
    tenantId: string,
    collectionName: string
  ) {
    if (!oldName || oldName === newName) {
      console.log('No name change detected or old name is empty. Skipping update.')
      return
    }

    const collectionRef = collection(db, 'tenants', tenantId, collectionName)
    const q = query(collectionRef, where('control_owner', '==', oldName))
    const querySnapshot = await getDocs(q)

    const batch = db.batch() as unknown as WriteBatch

    const updateDocument = (docRef: DocumentReference, newName: string) => {
      batch.update(docRef, {control_owner: newName})
    }

    try {
      if (querySnapshot.empty) {
        console.log(
          `No documents found with owner name "${oldName}" in collection: ${collectionName}`
        )
        return
      }

      querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
        updateDocument(doc.ref, newName)
      })

      await batch.commit()
      console.log(`Successfully updated documents in collection: ${collectionName}`)
    } catch (error) {
      console.error(`Failed to update documents in collection: ${collectionName}. Error: ${error}`)
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)
    if (firstname.trim() === '' && lastname.trim() === '') {
      setMessage({error: true, msg: 'First name or last name is required.'})
      setIsLoading(false)
      setShowAlert(true)
      setTimeout(() => setShowAlert(false), 2000)
      return
    }

    try {
      let imageUrl = previewUrl

      if (image) {
        const storageRef = ref(
          storage,
          `tenants/${currentUser?.tenantId}/settings/user_image/${(image as File).name}`
        )
        await uploadBytes(storageRef, image)
        imageUrl = await getDownloadURL(storageRef)
      }

      const newAsset = {
        firstname,
        lastname,
        email,
        image_url: imageUrl,
        updated_at: new Date().toISOString(),
      }

      if (!currentUser) {
        throw new Error('No tenant ID found')
      }
      const tenantId = currentUser.tenantId || ''
      const uid = currentUser?.uid

      if (currentUser) {
        const tenantRef = doc(db, 'tenants', tenantId)
        const companyRef: DocumentReference = doc(tenantRef, 'settings', 'user_detail')

        const userDetailSnap = await getDoc(companyRef)
        const currentUserDetail = userDetailSnap.data()
        const oldName = `${currentUserDetail?.firstname || ''} ${
          currentUserDetail?.lastname || ''
        }`.trim()
        const newName = `${firstname} ${lastname}`.trim()

        await setDoc(companyRef, newAsset, {merge: true})

        const userRef = doc(db, 'users', uid!)
        await updateDoc(userRef, {
          first_name: firstname,
          last_name: lastname,
          userName: `${firstname} ${lastname}`,
        })

        const storedUser = JSON.parse(localStorage.getItem('currentUser') || '{}')
        storedUser.userName = newName
        localStorage.setItem('currentUser', JSON.stringify(storedUser))

        const employeeData = users[0]
        if (employeeData) {
          await updateUsername(employeeData.id, `${firstname} ${lastname}`)
        }
        await updateNameAcrossCollections(oldName, newName, tenantId, 'assets')
        await updateNameAcrossCollections(oldName, newName, tenantId, 'tasklists')
        await updateNameAcrossCollections(oldName, newName, tenantId, 'risks')
        await updateNameAcrossCollections(oldName, newName, tenantId, 'vendors')
        await updateNameAcrossCollections(oldName, newName, tenantId, 'tests')
        await updateNameAcrossCollections(oldName, newName, tenantId, 'policies')
        await updateNameAcrossCollections2(oldName, newName, tenantId, 'employees')
        await updateNameAcrossCollections3(oldName, newName, tenantId, 'controls')

        const activitiesRef = collection(tenantRef, 'activities')
        await addDoc(activitiesRef, {
          message: `User details has been updated"`,
          timestamp: serverTimestamp(),
          tenantId: currentUser?.tenantId,
          uid: currentUser?.uid,
          action: 'User details updated',
          target: 'Settings',
          email: currentUser?.email,
          username: currentUser?.userName,
          date: todaysDate,
        })

        toast.success('Profile details updated successfully!', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })
        setTimeout(() => setShowAlert(false), 2000)
      } else {
        throw new Error('No current user or tenant ID found')
      }
    } catch (error) {
      console.error('Error updating company details:', error)
      setMessage({
        error: true,
        msg: 'An error occurred while updating profile details. Please try again.',
      })
      setShowAlert(true)
      setTimeout(() => setShowAlert(false), 2000)
    } finally {
      setIsLoading(false)
    }
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0]
    if (
      file &&
      (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg')
    ) {
      setImage(file)
      setPreviewUrl(URL.createObjectURL(file))
    } else {
      // alert('Please select an image file (png, jpg, or jpeg)')
    }
  }

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      ;(fileInputRef.current as HTMLInputElement).click()
    }
  }

  const handleDelete = () => {
    setImage(null)
    setPreviewUrl(blankImg)
    if (fileInputRef.current) {
      ;(fileInputRef.current as HTMLInputElement).value = ''
    }
  }

  const handleChangepassword = (e) => {
    const {name, value} = e.target
    if (name === 'currentPassword') setCurrentPassword(value)
    if (name === 'newPassword') setNewPassword(value)
    if (name === 'confirmPassword') setConfirmPassword(value)
  }

  const handleChangePassword = async () => {
    let valid = true
    setCurrentPasswordError('')
    setNewPasswordError('')
    setConfirmPasswordError('')
    setMatchPasswordError('')

    if (!currentPassword) {
      setCurrentPasswordError('Enter current password')
      valid = false
    }

    if (!newPassword) {
      setNewPasswordError('Enter new password')
      valid = false
    } else if (newPassword.length < 8) {
      setNewPasswordError('New password must be at least 8 characters long')
      valid = false
    }

    if (!confirmPassword) {
      setConfirmPasswordError('Enter confirm new password')
      valid = false
    } else if (newPassword !== confirmPassword) {
      setMatchPasswordError("New passwords don't match")
      valid = false
    }

    if (!valid) return

    const auth = getAuth()
    const user = auth.currentUser

    try {
      // Re-authenticate the user
      if (user && currentPassword) {
        const credential = EmailAuthProvider.credential(user.email as string, currentPassword)
        await reauthenticateWithCredential(user, credential)
        // Change the password
      }
      // Change the password
      setIsLoadingpw(true)
      if (user) {
        await updatePassword(user, newPassword)
      }
      toast.success('Password changed successfully!', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })
      // Clear the form
      setCurrentPassword('')
      setNewPassword('')
      setConfirmPassword('')
      setIsLoadingpw(false)
    } catch (error) {
      setCurrentPasswordError('Error changing password')
      setIsLoadingpw(false)
    }
  }

  return (
    <>
      <KTCard className='mb-8'>
        <div className=' mw-1111px rounded bg-white '>
          <div className='modal-content '>
            <div className='modal-header border-bottom p-6 fs-6 '>
              <h3 className='modal-title mx-2'>Profile Detail</h3>
              <br />
            </div>

            {/* <hr className='my-2 ' style={{borderTop: '1px solid #F1F1F2'}} /> */}

            {/* {showAlert && (
              <div style={{marginTop: 20, marginLeft: 20, marginRight: 20}}>
                {message?.msg && (
                  <div
                    className={
                      message?.error
                        ? 'alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row'
                        : 'alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row'
                    }
                  >
                    {message?.error ? (
                      <i className='ki-duotone ki-notification-bing fs-2hx text-danger me-4 mb-5 mb-sm-0'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    ) : (
                      <i className='ki-duotone ki-notification-bing fs-2hx text-success me-4 mb-5 mb-sm-0'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    )}

                    <div
                      className={
                        message?.error
                          ? 'd-flex flex-column text-danger text-primary text-bold pe-0 pe-sm-10'
                          : 'd-flex flex-column text-success text-primary text-bold pe-0 pe-sm-10'
                      }
                    >
                      {message?.error ? (
                        <h5 className='mb-1'>Error!!</h5>
                      ) : (
                        <h5 className='mb-1'>Sucess!!</h5>
                      )}
                      <span>{message?.msg}</span>
                    </div>
                    <button
                      type='button'
                      className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
                      onClick={() => setShowAlert(false)}
                    >
                      <span className='svg-icon svg-icon-1 svg-icon-success'>
                        <span className='svg-icon svg-icon-2x svg-icon-primary'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className='mh-50px'
                          >
                            <rect
                              opacity='0.5'
                              x='6'
                              y='17.3137'
                              width='16'
                              height='2'
                              rx='1'
                              transform='rotate(-45 6 17.3137)'
                              fill='black'
                            ></rect>
                            <rect
                              x='7.41422'
                              y='6'
                              width='16'
                              height='2'
                              rx='1'
                              transform='rotate(45 7.41422 6)'
                              fill='black'
                            ></rect>
                          </svg>
                        </span>
                      </span>
                    </button>
                  </div>
                )}
              </div>
            )} */}

            <form
              id='kt_modal_add_user_form'
              className='form px-4'
              // onSubmit={formik.handleSubmit}
              noValidate
              onSubmit={handleSubmit}
            >
              <div
                className='d-flex flex-column  me-n7 pe-7'
                id='kt_modal_add_user_scroll'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_modal_add_user_header'
                data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                data-kt-scroll-offset='300px'
              >
                <div className='row align-items-center mt-8 mx-2 mb-4'>
                  <div className='col-md-2'>
                    <div
                      className='image-input image-input-empty '
                      data-kt-image-input='true'
                      style={{backgroundImage: `url('${previewUrl}')`}}
                    >
                      <div className='image-input-wrapper w-125px h-125px'></div>
                      {/* <label
                        className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='change'
                        data-bs-toggle='tooltip'
                        title='Change avatar'
                      > */}
                      {/* <i className='ki-duotone ki-pencil fs-6'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i> */}
                      <input
                        type='file'
                        name='avatar'
                        ref={fileInputRef}
                        hidden
                        accept='.png, .jpg, .jpeg'
                        onChange={handleImageChange}
                      />
                      <input type='hidden' name='avatar_remove' />
                      {/* </label> */}
                      <span
                        className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='cancel'
                        data-bs-toggle='tooltip'
                        title='Cancel avatar'
                      >
                        <i className='ki-outline ki-cross fs-3'></i>
                      </span>
                      <span
                        className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='remove'
                        data-bs-toggle='tooltip'
                        title='Remove avatar'
                      >
                        <i className='ki-outline ki-cross fs-3'></i>
                      </span>
                    </div>
                  </div>
                  <div className='col-md-9'>
                    <div className='mb-2 '>
                      <div className='fw-bold fs-6 fw-bliock'>Profile picture</div>
                      <div className='fs-6'>Allowed file types: png, jpg and jpeg.</div>
                    </div>
                    <div className='mt-3'>
                      {/* <button className='btn btn-sm btn-primary me-2'>Upload New Picture</button> */}
                      <button
                        type='button'
                        className='fs-6 fw-semibold rounded border-0 mt-2'
                        onClick={handleUploadClick}
                        disabled={progress !== null && progress < 100}
                        style={{
                          background: '#1CBB8D',
                          color: '#fff',
                          height: '40px',
                          width: '150px',
                          top: '20px',
                          left: '223px',
                        }}
                      >
                        Upload New Picture
                      </button>
                      {previewUrl !== blankImg && (
                        <button
                          type='button'
                          className='fs-6 fw-semibold rounded mt-2 mx-4'
                          onClick={handleDelete}
                          style={{
                            background: '#fff',
                            color: '#000',
                            height: '40px',
                            width: '140px',
                            top: '20px',
                            left: '223px',
                            border: '2px solid #C5C5C5',
                          }}
                        >
                          Delete Picture
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <div className='row align-items-center mx-2 mb-4'>
                  <div className='col-md-6'>
                    <div className='fv-row mb-3'>
                      <label className='fw-bold fs-6 mb-1'>First Name</label>
                      <input
                        placeholder='Enter first name'
                        type='text'
                        className='form-control '
                        name='firstname'
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                        autoComplete='off'
                      />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='fv-row mb-3'>
                      <label className='fw-bold fs-6 mb-1'>Last Name</label>
                      <input
                        placeholder='Enter last name'
                        type='text'
                        className='form-control '
                        name='lastName'
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                        autoComplete='off'
                      />
                    </div>
                  </div>
                </div>

                {/* <div className='mx-4 notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6'>
                  <i className='ki-duotone ki-design-1 fs-2tx text-primary me-4'></i>
                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-semibold'>
                      <div className='fs-6 text-gray-700'>
                        Changes will be visible after you log in again. Any modifications made can
                        be seen after you log in again, but previous versions of the file will not
                        be updated.
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className='card mb-8 p-4 mx-4' style={{background: '#F6F6F6'}}>
                  <div className='mt-1'>
                    <span className='fw-bold fs-6'>Email</span>
                  </div>

                  <div className='fs-6 mt-2 mb-2' style={{color: '#5A5A5A'}}>
                    We recommend keeping your account email unchanged. If incase you need to change
                    it, please <a href='#'>contact</a> our support team for assistance.
                  </div>
                  <span className='fw-semibold fs-6 mb-2'>Your Email : {email}</span>
                </div>
              </div>
              <div className=' modal-footer border-top d-flex justify-content-end pt-4 mb-4'>
                <button
                  type='submit'
                  className='fs-6 fw-semibold rounded border-0 my-6 mx-8'
                  disabled={isLoading}
                  style={{
                    background: '#1CBB8D',
                    color: '#fff',
                    height: '40px',
                    width: '90px',
                    top: '20px',
                    left: '223px',
                  }}
                >
                  {isLoading ? 'Saving...' : 'Save'}  {isLoading && <span className='spinner-border spinner-border-sm align-middle'></span>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </KTCard>

      <div className='card'>
        <div className='card-header px-5 pt-4'>
          <h4 className='fw-bold m-4'>Change Password</h4>
        </div>
        <div className='card-body p-0 px-6'>
          <div className='fs-6 mb-4 fw-semibold mt-4' style={{color: '#5A5A5A'}}>
            Please make sure that your password is at least 15 letters long, or at least 8
            characters long with both letters and numbers.
          </div>

          <div className='row align-items-center mb-4 mt-4'>
            <div className='col-md-12'>
              <div className='fv-row mb-3'>
                <label className='fw-bold fs-6 mb-1'>Current Password</label>
                <input
                  placeholder='Enter current password'
                  type='password'
                  className={`form-control ${currentPasswordError ? 'is-invalid' : ''}`}
                  name='currentPassword'
                  value={currentPassword}
                  onChange={handleChangepassword}
                  autoComplete='off'
                />
                {currentPasswordError && <div style={{color: 'red'}}>{currentPasswordError}</div>}
              </div>
            </div>
          </div>

          <div className='row align-items-center mb-4 mt-4'>
            <div className='col-md-12'>
              <div className='fv-row mb-3'>
                <label className='fw-bold fs-6 mb-1'>New Password</label>
                <input
                  placeholder='Enter new password'
                  type='password'
                  className={`form-control ${newPasswordError ? 'is-invalid' : ''}`}
                  name='newPassword'
                  value={newPassword}
                  onChange={handleChangepassword}
                  autoComplete='off'
                />
                {newPasswordError && <div style={{color: 'red'}}>{newPasswordError}</div>}
              </div>
            </div>
          </div>

          <div className='row align-items-center mt-4 mb-4'>
            <div className='col-md-12'>
              <div className='fv-row mb-3'>
                <label className='fw-bold fs-6 mb-1'>Confirm New Password</label>
                <input
                  placeholder='Enter confirm new password'
                  type='password'
                  className={`form-control ${confirmPasswordError || matchPasswordError ? 'is-invalid' : ''}`}
                  name='confirmPassword'
                  value={confirmPassword}
                  onChange={handleChangepassword}
                  autoComplete='off'
                />
                {confirmPasswordError && <div style={{color: 'red'}}>{confirmPasswordError}</div>}
                {matchPasswordError && <div style={{color: 'red'}}>{matchPasswordError}</div>}
              </div>
            </div>
          </div>
        </div>
        <div className='card-footer py-0'>
          <div className='d-flex justify-content-end'>
            {isLoadingpw ? (
              <button
                className='fs-6 fw-semibold rounded border-0  my-6 '
                onClick={handleChangePassword}
                style={{
                  background: '#1CBB8D',
                  color: '#fff',
                  height: '40px',
                  width: '180px',
                  top: '20px',
                  left: '223px',
                }}
              >
                <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                Change Password
              </button>
            ) : (
              <button
                className='fs-6 fw-semibold rounded border-0  my-6 '
                onClick={handleChangePassword}
                style={{
                  background: '#1CBB8D',
                  color: '#fff',
                  height: '40px',
                  width: '140px',
                  top: '20px',
                  left: '223px',
                }}
              >
                Change Password
              </button>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default CompanySettings
