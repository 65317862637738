/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {SettingPageTitle} from '../components/header/SettingPageTitle'
import {useAuth} from '../../../modules/auth'
import {UserListToolbar} from '../components/header/UserListToolbar'

const SettingsUserpermissions = () => {
  const [activeTab, setActiveTab] = useState('roles')
  const location = useLocation()
  const {currentUser} = useAuth()

  useEffect(() => {
    // Fetch control data when the component mounts
    if (location.pathname.includes('auditors')) {
      console.log('Setting active tab to settingadvanced')
      setActiveTab('auditors')
    } else if (location.pathname.includes('msp')) {
      console.log('Setting active tab to settingframework')
      setActiveTab('msp')
    } else {
      console.log('Setting active tab to information')
      setActiveTab('users')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <SettingPageTitle
          icon='Settings.svg'
          title='Settings'
          desc=' Manage your settings'
      >
        Personnel Management
      </SettingPageTitle>
      <div className={`card mb-5 mb-xl-8`}>
        <div className='px-10 '>
          <div className='d-flex  h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap '>
              <li className='nav-item'>
                <Link
                  to={`/company-settings/users`}
                  className={`nav-link me-6 ${
                    activeTab === 'users' ? 'text-active-primary active' : ''
                  }`}
                >
                  Your organization
                </Link>
              </li>

              <li className='nav-item'>
                <Link
                  to={'/company-settings/users/auditors'}
                  className={`nav-link me-6 ${
                    activeTab === 'auditors' ? 'text-active-primary active' : ''
                  }`}
                >
                  Auditors
                </Link>
              </li>

              <li className='nav-item'>
                <Link
                  to={'/company-settings/users/msp'}
                  className={`nav-link me-6 ${
                    activeTab === 'msp' ? 'text-active-primary active' : ''
                  }`}
                >
                  Managed Service Providers
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className='card-body'>
          <UserListToolbar />
          {/* begin::Table container */}
          <div className='table-responsive' style={{height: '500px', overflowY: 'auto'}}>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-500px '>NAME</th>
                  <th className='min-w-250px'>EMAIL</th>
                  <th className='min-w-300px text-center'>ROLE</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}

              {/* {filteredItems && filteredItems.length > 0 && usersWithSameTenantId.length > 0 ? (
              filteredItems
                .filter((user) => user.tenantId === currentUser?.tenantId)
                .map((item) => ( */}
              <tbody>
                <tr>
                  <td>
                    <div className='d-flex align-items-center  fs-6 text-muted fw-bold'>
                     <a href='#'> {currentUser?.userName}</a>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center text-muted fw-bold'>
                      {currentUser?.email}
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center text-muted fw-bold'>
                      <select
                        name='group'
                        className='form-select w-10'
                        data-control='select2'
                        data-placeholder='Select role'
                      >
                        <option>Admin</option>
                        <option>Member</option>
                      </select>
                    </div>
                  </td>
                </tr>
              </tbody>

              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      </div>
    </>
  )
}

export default SettingsUserpermissions
