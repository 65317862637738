/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {collection, onSnapshot} from 'firebase/firestore'
import {useAuth} from '../../../modules/auth'
import {Dropdown} from 'react-bootstrap'
import { CloudPageTitle} from '../../../../_metronic/layout/core/CloudPageData'
import Swal from 'sweetalert2'
import {Helmet} from 'react-helmet'
import db from '../../../../services/firebase'
import vendorServices from '../services/vendor.services'
import {VendorListToolbar} from '../components/header/VendorListToolbar'
import {CloudListToolbar} from '../components/header/CloudListToolbar'

// type Props = {
//   className: string
// }
interface UserData {
  id: string
  companyName: string
  riskLevel: string
  imgUrl: string
  archive: boolean
  uid: string
  tenantId: string
}
const Checks: React.FC = () => {
  const [users, setUsers] = useState<UserData[]>([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const {currentUser} = useAuth()

  const [activeTab, setActiveTab] = useState('checks')
  const location = useLocation()
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredItems, setFilteredItems] = useState<UserData[]>([])
  const [searchLength, setSearchLength] = useState(false)
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    getVendor()
  }, [])

  useEffect(() => {
    // Fetch control data when the component mounts
    if (location.pathname.includes('checks')) {
      setActiveTab('checks')
    
    }else if(location.pathname.includes('assets')) {
      setActiveTab('assets')
    
    }else if (location.pathname.includes('containers')) {
      setActiveTab('containers')
    
    } else {
      setActiveTab('overview')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setFilteredItems(users)
  }, [users])

  const getVendor = () => {
    try {
      const unsub = onSnapshot(
        collection(db, 'vendorManagement'),
        (snapshot) => {
          let list: UserData[] = []
          snapshot.docs.forEach((doc) => {
            list.push({id: doc.id, ...doc.data()} as UserData)
          })

          setUsers(list)
          setLoading(false)
        },
        (error) => {
          console.log(error)
        }
      )

      return unsub // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting vendors:', error)
    }
  }

  const handleDelete = async (item) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${item.companyName} Vendor`,
        text: `Are you sure you want to remove ${item.companyName} Vendor ? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        // User confirmed, proceed with the deletion
        await vendorServices.deleteVendor(item.id)
        // getVen()
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: ` "${item.companyName}" has been deleted.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      console.error('Error deleting policy:', error)
      // Handle the error or show an error message to the user
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the policy.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      // setDropdownVisible(false)
    }
  }
  const usersWithSameTenantId = users.filter((user) => user.tenantId === currentUser?.tenantId)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    // Assuming policy is your original data array
    if (searchTerm === '') {
      // If the search term is empty, reset to the original policies
      setFilteredItems(users)
      setSearchLength(false)
    } else {
      const filteredItems = users.filter((user) =>
        user.companyName.toLowerCase().includes(searchTerm)
      )
      setFilteredItems(filteredItems)
      setSearchLength(filteredItems.length === 0)
    }
  }
  // console.log(users)
  return (
    <>
      {/* begin::Header */}
      <Helmet>
        <title>Secureslate | Clouds</title>
      </Helmet>
      <CloudPageTitle
         icon='Cloud.svg'
        title='Clouds'
        desc={'0 connected clouds'}
        // breadcrumbs={usersBreadcrumbs}
      >
        Vendor Risk Management
      </CloudPageTitle>

      <div className='card card-custom '>
        <div className='pt-3 pb-0 px-10'>
          {/*begin::Navs*/}
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
              {/*begin::Nav item*/}
              <li className='nav-item'>
                <Link
                  to={`/clouds`}
                  className={`nav-link me-6 ${
                    activeTab === 'overview' ? 'text-active-primary active' : ''
                  }`}
                >
                  Overview
                </Link>
              </li>
              {/*end::Nav item*/}
              {/*begin::Nav item*/}
              <li className='nav-item'>
                <Link
                  to={'/clouds/checks'}
                  className={`nav-link me-6 ${
                    activeTab === 'checks' ? 'text-active-primary active' : ''
                  }`}
                >
                  Checks
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to={'/clouds/containers'}
                  className={`nav-link me-6 ${
                    activeTab === 'containers' ? 'text-active-primary active' : ''
                  }`}
                >
                  Containers
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to={'/clouds/assets'}
                  className={`nav-link me-6 ${
                    activeTab === 'assets' ? 'text-active-primary active' : ''
                  }`}
                >
                  Assets
                </Link>
              </li>
              {/*end::Nav item*/}
            </ul>
          </div>
          {/*begin::Navs*/}
        </div>
        <div className='card-header border-0 pt-6 h-100'>
          <div className='card-title'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search..'
                value={searchTerm}
                onChange={handleInputChange}
              />
            </div>
            {/* end::Search */}
          </div>
          <div className='card-toolbar'>
            {/* begin::Group actions */}
            {/* <CloudListToolbar /> */}
            {/* end::Group actions */}
          </div>
        </div>

        <div className='card-body py-3 '>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted border-bottom border-dotted'>
                  <th className='ps-4 min-w-150px rounded-start'>RULE CODE</th>
                  <th className='min-w-400px'>TITLE</th>
                  <th className='min-w-500px'>DESCRIPTION</th>
                  <th className='min-w-150px'>COMPLIANCE</th>
                </tr>
              </thead>

              {/* {filteredPolicies &&
              filteredPolicies.length > 0 &&
              usersWithSameTenantId.length > 0 ? (
                filteredPolicies
                  .slice() // Create a copy of the array to avoid modifying the original array
                  .sort((a, b) => {
                    const policyA = a.policy || ''; // Default to empty string if undefined
                    const policyB = b.policy || ''; // Default to empty string if undefined
                    return policyA.localeCompare(policyB);
                  })
                  .filter(
                    (user) => user.tenantId === currentUser?.tenantId 
            
                  )

                  .map((policyData, index) => {
                    return ( */}
              <tbody>
                <tr className=' border-bottom border-dotted'>
                  <td>
                    <div className='d-flex align-items-center mx-5'>aws_001</div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      Root account should have MFA enabled
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      The root account is the most privileged user in an AWS account. MFA adds an
                      extra layer of protection on top of a user name and password.
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center text-danger'>
                      {' '}
                      <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Non-compliant
                    </div>
                  </td>
                </tr>
                <tr className=' border-bottom border-dotted'>
                  <td>
                    <div className='d-flex align-items-center mx-5'>aws_002</div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      Security group has too permissive ingress rules
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      We detected too permissive ingress rules allowing access from 0.0.0.0/0 or
                      ::/0 to port 22. Having security groups open allows any user or malware with
                      vpc access to scan for well known and sensitive ports and gain access to
                      instance.
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center text-danger '>
                      {' '}
                      <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Non-compliant
                    </div>
                  </td>
                </tr>
                <tr className=' border-bottom border-dotted'>
                  <td>
                    <div className='d-flex align-items-center mx-5'>aws_003</div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      Users with administrator access should have MFA enabled
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      Any user with AdministratorAccess is allowed to perform any action on an AWS
                      account, so it needs to have a multi factor authentication enabled to avoid
                      impersonation through a potential credentials leak.
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center text-primary mx-5'>Compliant</div>
                  </td>
                </tr>
                <tr className=' border-bottom border-dotted'>
                  <td>
                    <div className='d-flex align-items-center mx-5'>aws_004</div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      EC2 IAM roles vulnerable to SSRF attacks
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      IMDS is an internal AWS service used by EC2 instances to fetch their IAM
                      roles. EC2 Instances should have Metadata Service version 2 (IMDSv2) enabled
                      and required. Using IMDSv2 will protect from misconfiguration and SSRF
                      vulnerabilities. IMDSv1 will not.
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center text-danger'>
                      {' '}
                      <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Non-compliant
                    </div>
                  </td>
                </tr>
                <tr className=' border-bottom border-dotted'>
                  <td>
                    <div className='d-flex align-items-center mx-5'>aws_005</div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      S3 Buckets are publicly accessible
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      We detected that one or more of your S3 buckets have too open access. Even if
                      you enable all possible bucket ACL options available in the Amazon S3 console
                      the ACL alone does not allow everyone to download objects from your bucket.
                      Depending on which option you select any user could perform some actions.
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center text-danger'>
                      {' '}
                      <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Non-compliant
                    </div>
                  </td>
                </tr>
                <tr className=' border-bottom border-dotted'>
                  <td>
                    <div className='d-flex align-items-center mx-5'>aws_006</div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      S3 Buckets have too permissive write access
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      We detected that one or more of your S3 buckets do not have strict write
                      access policies. This can cause non intended users to put objects in a given
                      bucket.
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center text-danger'>
                      {' '}
                      <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Non-compliant
                    </div>
                  </td>
                </tr>
                <tr className=' border-bottom border-dotted'>
                  <td>
                    <div className='d-flex align-items-center mx-5'>aws_007</div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>ECR repositories are too public</div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      We detected that one or more of your ECR repositories do not have proper
                      policies configured. Too permissive policies may allow anonymous users to
                      perform actions.
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center text-danger'>
                      {' '}
                      <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Non-compliant
                    </div>
                  </td>
                </tr>
                <tr className=' border-bottom border-dotted'>
                  <td>
                    <div className='d-flex align-items-center mx-5'>aws_008</div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>RDS instances are public</div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      We detected that one or more of your RDS database instances are publicly
                      accessible. Your database has a public IP and also allows traffic from the
                      entire internet over typical database ports. Publicly accessible databases
                      could expose sensitive data to bad actors.
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center text-primary mx-5'>Compliant</div>
                  </td>
                </tr>
              </tbody>
              {/* )
                  })
              ) : ( */}
              {/* <tbody>
                  {searchLength === true ? (
                    <tr>
                      <td colSpan={6} className='text-center'>
                        <div className='m-20'>
                          <img src='/media/buttons/emptystate.svg' alt='' />
                          <div className='m-5 fs-5'>
                            <p style={{fontWeight: 'bold'}}>No polices Found</p>
                          </div>
                          <p className=''>
                            We couldn't find any policies that match your search. Maybe try a
                            different search!
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan={6} className='text-center'>
                        {!showEmptyState  ? (
                        // <PolicyListLoading/>
                        <></>
                      ) : (
                          <div className='m-20'>
                            <img src='/media/buttons/emptystate.svg' alt='' />
                            <div className='m-5 fs-5'>
                              <p style={{fontWeight: 'bold'}}>No polices Added</p>
                            </div>
                            <p>
                              Add the polices that all employees within your organization are
                              required to adhere to.
                            </p>
                          </div>
                        )}
                      </td>
                    </tr>
                  )}
                </tbody> */}
              {/* )} */}

              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      </div>
    </>
  )
}

export {Checks}
