/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {initialQueryState, KTIcon, useDebounce} from '../../../../../_metronic/helpers'
// import {useQueryRequest} from '../../core/QueryRequestProvider'

const IntegrationListSearchComponent = () => {
  // const {updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  // useEffect(
  //   () => {
  //     if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
  //       updateState({search: debouncedSearchTerm, ...initialQueryState})
  //     }
  //   },
  //   [debouncedSearchTerm] // Only call effect if debounced search term changes
  //   // More details about useDebounce: https://usehooks.com/useDebounce/
  // )

  return (
    <div className='card-title mb-4'>
      {/* begin::Search */}
      <div
        className='d-flex justify-content-between align-items-center'
        data-kt-user-table-toolbar='base'
      >
        <div className='d-flex align-items-center position-relative my-1'>
          <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control  w-250px ps-14'
            placeholder='Search..'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <button
          type='button'
          className='btn me-3 fs-5 border'
          style={{backgroundColor: '#fff',}}
          // onClick={handleClick}
        >
          {/* <KTIcon iconName='add-folder' className='fs-2 text-primary' /> */}
          {/* <img src='/media/icon/filter.svg' alt='' className='mx-2'/> */}
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 24 24'
            fill='none'
            stroke='#5A5A5A'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            className='icon icon-tabler icons-tabler-outline icon-tabler-filter mb-1 mx-1'
          >
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <path d='M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z' />
          </svg>
          Filter
        </button>
      </div>
      {/* end::Search */}
    </div>
  )
}

export {IntegrationListSearchComponent}
