/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren, toAbsoluteUrl} from '../../../_metronic/helpers'
import {KTIcon} from '../../../_metronic/helpers'
import {TrainingPage} from '../onDashBoard/Training'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
})

const PageDataProvider: FC<WithChildren> = ({children}) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: string
  breadcrumbs?: Array<PageLink>
  title?: string
  icon?: string
  desc?: string
}

const OnDashPageTitle: FC<Props & WithChildren> = ({
  children,
  description,
  breadcrumbs,
  title,
  icon,
  desc,
}) => {
  const {setPageTitle, setPageDescription, setPageBreadcrumbs} = usePageData()
  useEffect(() => {
    if (children) {
      setPageTitle(children.toString())
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  return (
    <>
      <div className={`card mb-5 mb-xl-8`}>
        <div className='card-header border-0 pt-7 pb-5 mb-5'>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-45px me-5 '>
              <img src={toAbsoluteUrl(`/media/logos/${icon}`)} alt='' />
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <span className='card-label fw-bold fs-1'>{title}</span>
              <span className='text-muted fw-semibold fs-7 d-none d-md-block'>{desc}</span>
            </div>
          </div>
        </div>
        <img
          src={toAbsoluteUrl(`/media/logos/dashIMg.svg`)}
          className='img-fluid position-absolute end-0'
          style={{width: '315px'}}
          alt=''
        />
      </div>
    </>
  )
}

const PageDescription: FC<WithChildren> = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export {PageDescription, OnDashPageTitle, PageDataProvider, usePageData}
