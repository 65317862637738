/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import AWSDrawer from './components/drawers/AWSDrawer'

type Props = {
  className: string
}

const IntegrationManagement: React.FC<Props> = ({className}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }
  return (
    <>
      <AWSDrawer isOpen={isModalOpen} onClose={handleCloseModal} />

      <div className='container-fluid min-vh-50 d-flex align-items-center justify-content-center'>
        <div className='row' style={{height: '50vh', width: '100%'}}>
          <div className='col d-flex align-items-center justify-content-center'>
            <div className='text-center'>
              <img
                src='/media/integrations/Integrations Icon.svg'
                alt='Integrations Icon'
                className='mb-4'
                style={{width: '60px'}}
              />
              <h2 className='fs-6 mb-2'>No integrations connected</h2>
              <p className='fs-6' style={{color: '#5A5A5A'}}>
                You'll see all your connected services here
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <h4 className='mb-4 mt-8'>Cloud Providers</h4>

<div className={`card mb-5 mb-xl-8`}>
  <div className='card-header border-0 pt-5 mb-5'>
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-45px me-5 '>
        <img src={toAbsoluteUrl('/media/integrations/AWS.svg')} alt='' />
      </div>
      <div className='d-flex justify-content-start flex-column'>
        <span className='card-label fw-bold fs-5'>AWS</span>
        <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
          Cloud Provider
        </span>
      </div>
    </div>
  
      <div className='mt-2'>
      <span className='badge border p-2 rounded-pill'>
        <span
          className={`badge border rounded-circle mx-2 bg-primary`}
          style={{
            width: '12px',
            height: '12px',
            padding: '3px',
          }}
        >
          {' '}
        </span>
        <span className='text-center'>Connected</span>
      </span>
      <button className='btn fw-bold fs-6 mb-1' style={{color: '#5A5A5A'}}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-settings mx-1'
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z' />
          <path d='M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0' />
        </svg>{' '}
        Manage
      </button>
    </div>
  </div>
</div>

<div className={`card mb-8 mb-xl-8`}>
  <div className='card-header border-0 pt-5 mb-5'>
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-45px me-5 '>
        <img src={toAbsoluteUrl('/media/integrations/Microsoft Azure.svg')} alt='' />
      </div>
      <div className='d-flex justify-content-start flex-column'>
        <span className='card-label fw-bold fs-5'>Microsoft Azure</span>
        <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
          Cloud Provider
        </span>
      </div>
    </div>
 
    <div className='mt-2'>
    <span className='badge border p-2 rounded-pill'>
      <span
        className={`badge border rounded-circle mx-2 bg-primary`}
        style={{
          width: '12px',
          height: '12px',
          padding: '3px',
        }}
      >
        {' '}
      </span>
      <span className='text-center'>Connected</span>
    </span>
    <button className='btn fw-bold fs-6 mb-1' style={{color: '#5A5A5A'}}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='18'
        height='18'
        viewBox='0 0 24 24'
        fill='none'
        stroke='currentColor'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
        className='icon icon-tabler icons-tabler-outline icon-tabler-settings mx-1'
      >
        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
        <path d='M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z' />
        <path d='M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0' />
      </svg>{' '}
      Manage
    </button>
    </div>
  </div>
</div>

<h4 className='mb-4'>Project Manager</h4>

<div className={`card mb-5 mb-xl-8`}>
  <div className='card-header border-0 pt-5 mb-5'>
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-45px me-5 '>
        <img src={toAbsoluteUrl('/media/integrations/Asana.svg')} alt='' />
      </div>
      <div className='d-flex justify-content-start flex-column'>
        <span className='card-label fw-bold fs-5'>Asana</span>
        <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
          Issue Tracking & Project Management
        </span>
      </div>
    </div>

    <div className='mt-2'>
      <span className='badge border p-2 rounded-pill'>
        <span
          className={`badge border rounded-circle mx-2 bg-primary`}
          style={{
            width: '12px',
            height: '12px',
            padding: '3px',
          }}
        >
          {' '}
        </span>
        <span className='text-center'>Connected</span>
      </span>
      <button className='btn fw-bold fs-6 mb-1' style={{color: '#5A5A5A'}}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-settings mx-1'
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z' />
          <path d='M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0' />
        </svg>{' '}
        Manage
      </button>
    </div>
  </div>
</div>

<div className={`card mb-5 mb-xl-8`}>
  <div className='card-header border-0 pt-5 mb-5'>
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-45px me-5 '>
        <img src={toAbsoluteUrl('/media/integrations/Notion.svg')} alt='' />
      </div>
      <div className='d-flex justify-content-start flex-column'>
        <span className='card-label fw-bold fs-5'>Notion</span>
        <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
          Issue Tracking & Project Management
        </span>
      </div>
    </div>
    <div className='mt-2'>
      <span className='badge border p-2 rounded-pill'>
        <span
          className={`badge border rounded-circle mx-2 bg-primary`}
          style={{
            width: '12px',
            height: '12px',
            padding: '3px',
          }}
        >
          {' '}
        </span>
        <span className='text-center'>Connected</span>
      </span>
      <button className='btn fw-bold fs-6 mb-1' style={{color: '#5A5A5A'}}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-settings mx-1'
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z' />
          <path d='M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0' />
        </svg>{' '}
        Manage
      </button>
    </div>
  </div>
</div> */}
    </>
  )
}

export {IntegrationManagement}
