/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {OnDashPageTitle, PageLink} from '../cores/OnDashPageData'
import {PageTitle} from '../cores/card1'
import trainingServices from '../services/training.services'
import {useAuth} from '../../modules/auth'
import { Helmet } from 'react-helmet'; 
import { collection, doc, getDoc } from 'firebase/firestore'
import db from '../../../services/firebase'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'training',
    path: '/training',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
type Props = {
  completed: boolean
}

const TrainingPage: FC<Props> = ({completed}) => {
  // const [CCPACompleted, setCCPACompleted] = useState(false)
  // const [HIPPACompleted, setHIPPACompleted] = useState(false)
  // const [PCICompleted, setPCICompleted] = useState(false)
  const [PCISecureCompleted, setPCISecureCompleted] = useState(false)
  const [SATCompleted, setSATCompleted] = useState(false)
  // const [GDPRCompleted, setGDPRCompleted] = useState(false)

  const {currentUser} = useAuth()

  useEffect(() => {
    async function fetchData() {
      const existingCollection = await trainingServices.getAllOnboarding()
      if (existingCollection) {

        for (const doc of existingCollection.docs) {
          const data = doc.data()

          if (data.tenantId === currentUser?.tenantId && data.uid === currentUser?.uid) {
            const docId = doc.id
            const trainingDetail = await trainingServices.getTraining(docId)
            const SATTraining = (trainingDetail.data() as any)?.SAT || [];
            const PCISecureTraining = (trainingDetail.data() as any)?.PCISecure || [];
            // const GDPRTraining = (trainingDetail.data() as any)?.GDPR || [];
            // const ccpaTraining = (trainingDetail.data() as any)?.CCPA || [];
            // const HIPPATraining = (trainingDetail.data() as any)?.HIPPA || [];
            // const PCITraining = (trainingDetail.data() as any)?.PCI || [];
  
            SATTraining.length && SATTraining.forEach((file) => setSATCompleted(file.isChecked));
            PCISecureTraining.length && PCISecureTraining.forEach((file) => setPCISecureCompleted(file.isChecked));
            // GDPRTraining.length && GDPRTraining.forEach((file) => setGDPRCompleted(file.isChecked));
            // ccpaTraining.length && ccpaTraining.forEach((file) => setCCPACompleted(file.isChecked));
            // HIPPATraining.length && HIPPATraining.forEach((file) => setHIPPACompleted(file.isChecked));
            // PCITraining.length && PCITraining.forEach((file) => setPCICompleted(file.isChecked));
          }
        }
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchTrainingData = async () => {
      try {
        const tenantId = currentUser?.tenantId || ''
        if (!tenantId) {
          return
        }

        const tenantRef = doc(db, 'tenants', tenantId)
        const onboardingCollectionRef = collection(tenantRef, 'onboarding')
        const policyDocRef = doc(onboardingCollectionRef, 'training')

        // Retrieve the existing training document
        const policyDoc = await getDoc(policyDocRef)
        const policyData = policyDoc.data() || {}

        setPCISecureCompleted(policyData.PCISecure[0].isChecked)
        setSATCompleted(policyData.SecurityTraining[0].isChecked)
      } catch (error) {
        console.error('Error fetching training data:', error)
      }
    }

    fetchTrainingData()
  }, [currentUser])



  return (
    <>
     <Helmet>
        <title>Secureslate | Training</title>
      </Helmet>
      <OnDashPageTitle
        icon='training.svg'
        title='Training'
        desc="Boost your awareness in safeguarding your company's digital and physical Security"
      />
      <div className='d-flex flex-column'>
        <PageTitle
          icon='security.svg'
          title='Security Awareness Training'
          desc='Safeguard your digital workspace.'
          breadcrumbs={usersBreadcrumbs}
          task={SATCompleted ? 'Completed' : ''}
          buttonUrl='/training/SAT'
        />
        {/* <PageTitle
          icon='hippa.svg'
          title='HIPAA Training'
          desc='Protect data and privacy with HIPAA.'
          breadcrumbs={usersBreadcrumbs}
          task={HIPPACompleted ? 'Completed' : ''}
          buttonUrl='/training/HIPAA'
        />
        <PageTitle
          icon='PCIT.svg'
          title='PCI Training'
          desc='Safeguard sensitive payment data.'
          breadcrumbs={usersBreadcrumbs}
          task={PCICompleted ? 'Completed' : ''}
          buttonUrl='/training/PCI'
        /> */}
        <PageTitle
          icon='PCI.svg'
          title='PCI Secure Code Training'
          desc='Secure coding practices.'
          breadcrumbs={usersBreadcrumbs}
          task={PCISecureCompleted ? 'Completed' : ''}
          buttonUrl='/training/PCISecure'
        />
        {/* <PageTitle
          icon='CCPA.svg'
          title='CCPA Training'
          desc='Safeguard customer data and ensure compliance.'
          breadcrumbs={usersBreadcrumbs}
          task={CCPACompleted ? 'Completed' : ''}
          buttonUrl='/training/CCPA'
        />
        <PageTitle
          icon='GDPRT.svg'
          title='GDPR Training'
          desc='Protect data and privacy with HIPAA.'
          breadcrumbs={usersBreadcrumbs}
          task={GDPRCompleted ? 'Completed' : ''}
          buttonUrl='/training/GDPR'
        /> */}
      </div>
    </>
  )
}

// const OnDashboardWrapper: FC = () => {
//   //   console.log('Reference')
//   return (
//     <>
//       <TrainingPage />
//     </>
//   )
// }

export {TrainingPage}
