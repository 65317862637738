import {useListView} from '../../core/ListViewProvider'
import {DataRoomGrouping} from './DataRoomGrouping'
import {DataRoomSearchComponent} from './DataRoomSearchComponent'
import {DataRoomToolbar} from './DataRoomToolbar'

const DataRoomHeader = ({ onHeaderAction , subFoldderClicked , folderPath, onFlieFolderCreated,onSearchTermChange}) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
       <DataRoomSearchComponent onSearchTermChange={onSearchTermChange}/>
      {/* begin::Card toolbar */}
      <div className='card-toolbar d-flex justify-content-end'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <DataRoomGrouping /> : <DataRoomToolbar onHeaderAction={onHeaderAction} subFolderClicked={subFoldderClicked} folderPath={folderPath} fileFolderCreated={onFlieFolderCreated}/>}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {DataRoomHeader}
