/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
// import {KTSVG} from '../../../../../_metronic/helpers'
// import assetServices from '../services/asset.services'

import React from 'react'
// import riskServices from '../services/risk.services'
import personnelServices from '../../personnel-management/services/personnel.services'
import {KTIcon} from '../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import personnelGroupServices from '../../personnel-management/services/personnelGroup.services'

export interface addPersonnel {
  fullName: string
  email: string
  role: string
  startDate: string
  group: string
}
const initialState = {
  fullName: '',
  email: '',
  role: '',
  startDate: '',
  group: '',
}

const AddUser: React.FC = () => {
  // const [fullName, setFullName] = useState('')
  // const [email, setEmail] = useState('')
  // const [role, setRole] = useState('')
  const [form, setForm] = useState(initialState)
  const {fullName, email, role, startDate, group} = form
  const [file, setFile] = useState<File | null>(null)
  const [progress, setProgress] = useState<number | null>(null)
  const [groups, setGroups] = useState<any[]>([])

  const [message, setMessage] = useState({error: false, msg: ''})
  const [showAlert, setShowAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  // console.log('Form data:', form)
  // useEffect(() => {
  //   const uploadFile = () => {
  //     if (file) {
  //       const fileName = new Date().getTime() + file.name
  //       const storageRef = ref(storage, `personnelImages/${fileName}`)
  //       const uploadTask = uploadBytesResumable(storageRef, file)
  //       uploadTask.on(
  //         'state_changed',
  //         (snapshot) => {
  //           const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //           console.log('Upload is ' + progress + '% done')
  //           setProgress(progress)
  //           // setIsLoading(true)
  //           switch (snapshot.state) {
  //             case 'paused':
  //               console.log('Upload is paused')
  //               break
  //             case 'running':
  //               console.log('Upload is running')

  //               break
  //             default:
  //               break
  //           }
  //         },
  //         (error) => {
  //           console.log(error)
  //         },
  //         () => {
  //           getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
  //             setForm((prev) => ({...prev, avatarUrl: downloadUrl}))
  //           })
  //         }
  //       )
  //     }
  //   }
  //   uploadFile()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  useEffect(() => {
    const fetchGroups = async () => {
      const querySnapshot = await personnelGroupServices.getAllPersonnels()
      const groups = querySnapshot.docs
        .map((doc) => doc.data())
        .filter((group) => group.tenantId && group.uid)
      setGroups(groups)
    }

    fetchGroups()
  }, [])
  // console.log("s",groups)
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    console.log('Form data:', form)

    if (!fullName || !email || !role || !startDate || !group) {
      setMessage({error: true, msg: 'Please fill all the fields'})
      setIsLoading(false)
      setShowAlert(true)
      window.scrollTo(0, 0)
      return
    }

    try {
      setIsLoading(true)
      // Upload file to Firebase Storage

      // const fileName = new Date().getTime() + file.name
      // const storageRef = ref(storage, `personnelImages/${fileName}`)
      // const uploadTask = uploadBytesResumable(storageRef, file)
      // await uploadTask

      // // Get download URL
      // const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)

      const newPersonnel = {
        fullName,
        email,
        role,
        startDate,
        group,
        uid: currentUser?.uid,
        tenantId: currentUser?.tenantId,
      }

      await personnelServices.addPersonnel(newPersonnel)
      setMessage({error: false, msg: 'Added Successfully'})
      setShowAlert(true)
      window.scrollTo(0, 0)
      setTimeout(() => {
        navigate('/personnel-management')
        setForm(initialState)
        setShowAlert(false)
      }, 3000)
    } catch (err: any) {
      setMessage({error: true, msg: err.message})
      setShowAlert(true)
      window.scrollTo(0, 0)
    } finally {
      setIsLoading(false)
    }
    setFile(null)
    // setName('')
    // setRiskLevel('')
    // setForm(initialState)
    setTimeout(() => {
      navigate('/personnel-management')
      setForm(initialState)
      setShowAlert(false)
    }, 3000)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({...form, [e.target.name]: e.target.value})
  }
  const handleRole = (e: {target: {name: any; value: any}}) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <>
      <button
        type='button'
        className='btn btn-primary'
        data-bs-toggle='modal'
        data-bs-target='#kt_add_personnel'
      >
        <KTIcon iconName='plus' className='fs-2' />
        Add Users
      </button>

      <div className='modal fade' tabIndex={-1} id='kt_add_personnel'>
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='modal-title'>Add a User to SecureSlate</h3>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                  <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='#5A5A5A'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  className='icon icon-tabler icons-tabler-outline icon-tabler-x'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M18 6l-12 12' />
                  <path d='M6 6l12 12' />
                </svg>
              </div>
            </div>
            {showAlert && (
              <div style={{marginTop: 20, marginLeft: 20, marginRight: 20}}>
                {message?.msg && (
                  <div
                    className={
                      message?.error
                        ? 'alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row'
                        : 'alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row'
                    }
                  >
                    {message?.error ? (
                      <i className='ki-duotone ki-notification-bing fs-2hx text-danger me-4 mb-5 mb-sm-0'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    ) : (
                      <i className='ki-duotone ki-notification-bing fs-2hx text-success me-4 mb-5 mb-sm-0'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    )}

                    <div
                      className={
                        message?.error
                          ? 'd-flex flex-column text-danger text-primary text-bold pe-0 pe-sm-10'
                          : 'd-flex flex-column text-success text-primary text-bold pe-0 pe-sm-10'
                      }
                    >
                      {message?.error ? (
                        <h5 className='mb-1'>Error!!</h5>
                      ) : (
                        <h5 className='mb-1'>Sucess!!</h5>
                      )}
                      <span>{message?.msg}</span>
                    </div>
                    <button
                      type='button'
                      className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
                      onClick={() => setShowAlert(false)}
                    >
                      <span className='svg-icon svg-icon-1 svg-icon-success'>
                        <span className='svg-icon svg-icon-2x svg-icon-primary'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className='mh-50px'
                          >
                            <rect
                              opacity='0.5'
                              x='6'
                              y='17.3137'
                              width='16'
                              height='2'
                              rx='1'
                              transform='rotate(-45 6 17.3137)'
                              fill='black'
                            ></rect>
                            <rect
                              x='7.41422'
                              y='6'
                              width='16'
                              height='2'
                              rx='1'
                              transform='rotate(45 7.41422 6)'
                              fill='black'
                            ></rect>
                          </svg>
                        </span>
                      </span>
                    </button>
                  </div>
                )}
              </div>
            )}

            <div className='modal-body scroll-y '>
              <div className='fv-row mb-7 text-muted fs-6'>
                Add a team member using the imported contacts from your identity provider, or
                manually invite them using a company email address. For more information about the
                roles and their permission levels, review the
                <a href='#'> permission details table.</a>
              </div>
              <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
                <li className='nav-item'>
                  <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
                    Already in SecureSlate
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
                    Invite to SecureSlate
                  </a>
                </li>
              </ul>
              <div className='tab-content' id='myTabContent'>
                <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
                  <form
                    id='kt_modal_add_user_form'
                    className='form'
                    // onSubmit={formik.handleSubmit}
                    noValidate
                    onSubmit={handleSubmit}
                    style={{width: '100%'}}
                  >
                    {/* begin::Scroll */}
                    <div
                      className='d-flex flex-column scroll-y me-n7 pe-7'
                      id='kt_modal_add_user_scroll'
                      data-kt-scroll='true'
                      data-kt-scroll-activate='{default: false, lg: true}'
                      data-kt-scroll-max-height='auto'
                      data-kt-scroll-dependencies='#kt_modal_add_user_header'
                      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                      data-kt-scroll-offset='300px'
                    >
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        {/* <label className='fw-bold fs-6 mb-2'>Group</label> */}
                        {/* end::Label */}

                        {/* begin::Input */}
                        <select
                          name='group'
                          className='form-select w-100'
                          data-control='select2'
                          data-placeholder='Select employee'
                          onChange={handleRole}
                          value={group}
                        >
                          <option>Select an employee</option>
                          <option>Patty O'Furniture</option>
                          <option>Ann Chovey</option>
                          <option>Olive Yew</option>
                        </select>
                        {/* end::Input */}
                        {/* {formik.touched.email && formik.errors.email && ( */}
                        <div className='fv-plugins-message-container'>
                          <span role='alert'></span>
                        </div>
                        {/* )} */}
                      </div>
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='fw-bold fs-6 mb-2'>Add as</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <select
                          name='role'
                          value={role}
                          className='form-select'
                          data-control='select2'
                          data-placeholder='Select Role'
                          onChange={handleRole}
                        >
                          <option>Select Role</option>
                          <option>Admin</option>
                          <option>Member</option>
                        
                        </select>
                        {/* end::Input */}
                        {/* {formik.touched.email && formik.errors.email && ( */}
                        <div className='fv-plugins-message-container'>
                          <span role='alert'></span>
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                    {/* end::Scroll */}

                    {/* begin::Actions */}
                    {/* <div className='text-center pt-15'> */}

                    {/* end::Actions */}
                  </form>
                </div>
                <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
                  {/* <div className='modal-body scroll-y '> */}
                  <form
                    id='kt_modal_add_user_form'
                    className='form'
                    // onSubmit={formik.handleSubmit}
                    noValidate
                    onSubmit={handleSubmit}
                    style={{width: '100%'}}
                  >
                    {/* begin::Scroll */}
                    <div
                      className='d-flex flex-column scroll-y me-n7 pe-7'
                      id='kt_modal_add_user_scroll'
                      data-kt-scroll='true'
                      data-kt-scroll-activate='{default: false, lg: true}'
                      data-kt-scroll-max-height='auto'
                      data-kt-scroll-dependencies='#kt_modal_add_user_header'
                      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                      data-kt-scroll-offset='300px'
                    >
                      <div className='fv-row mb-7 text-muted fs-6'>
                        Enter the name and email address of the employee you'd like to add to your
                        SecureSlate account.
                      </div>

                      {/* begin::Input group */}
                      <div className='fv-row mb-7'>
                        <label className=' fw-bold fs-6 mb-2'>Full Name</label>
                        <input
                          placeholder='Full name'
                          type='text'
                          className='form-control  mb-3 mb-lg-0 w-100'
                          name='fullName'
                          value={fullName}
                          onChange={handleChange}
                          autoComplete='off'
                        />

                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'></span>
                          </div>
                        </div>
                      </div>

                      <div className='fv-row mb-7'>
                        <label className=' fw-bold fs-6 mb-2'>Last Name</label>
                        <input
                          placeholder='last name'
                          type='text'
                          className='form-control  mb-3 mb-lg-0 w-100'
                          name='lastName'
                          value={fullName}
                          onChange={handleChange}
                          autoComplete='off'
                        />

                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'></span>
                          </div>
                        </div>
                      </div>

                      {/* begin::Input group */}
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className=' fw-bold fs-6 mb-2'>Email</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                          placeholder='Email'
                          //   {...formik.getFieldProps('email')}
                          className='form-control  mb-3 mb-lg-0 w-100'
                          type='email'
                          name='email'
                          autoComplete='off'
                          value={email}
                          onChange={handleChange}
                          //   disabled={formik.isSubmitting || isUserLoading}
                        />
                        {/* end::Input */}
                        {/* {formik.touched.email && formik.errors.email && ( */}
                        <div className='fv-plugins-message-container'>
                          <span role='alert'></span>
                        </div>
                        {/* )} */}
                      </div>
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='fw-bold fs-6 mb-2'>Invite as</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <select
                          name='role'
                          value={role}
                          className='form-select'
                          data-control='select2'
                          data-placeholder='Select Role'
                          onChange={handleRole}
                        >
                          <option>Select Role</option>
                          <option>Admin</option>
                          <option>Member</option>
                          {/* <option>Editor</option>
                          <option>Auditor</option>
                          <option>View-only Admin</option>
                          <option>Sales Admin</option> */}
                        </select>
                        {/* end::Input */}
                        {/* {formik.touched.email && formik.errors.email && ( */}
                        <div className='fv-plugins-message-container'>
                          <span role='alert'></span>
                        </div>
                        {/* )} */}
                      </div>

                      {/* end::Input group */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* </div> */}

            <div className='modal-footer'>
              {isLoading ? (
                <button
                  type='submit'
                  className='btn'
                  style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                  disabled={progress !== null && progress < 100}
                >
                  <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                  Add
                </button>
              ) : (
                <div className='d-flex justify-content-end align-items-center'>
                  <div
                    className='m-1'
                    style={{cursor: 'pointer'}}
                    data-bs-dismiss='modal'
                    aria-label='Cancel'
                    // onClick={() => navigate('/personnel-management')}
                  >
                    <img src='/media/buttons/Cancel.svg' alt='Cancel' />
                  </div>
                  <div
                    className='m-1'
                    style={{cursor: 'pointer'}}
                    // onClick={handleCheckedRemoveClick}
                    onClick={handleSubmit}
                  >
                    <img src='/media/buttons/Add.svg' alt='Add' />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {AddUser}
