import React, {useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../../_metronic/helpers'
import {collection, doc, getDoc, onSnapshot, updateDoc} from 'firebase/firestore'
import db from '../../../../../services/firebase'
import {useAuth} from '../../../../modules/auth'

type Props = {
  id: string
  isOpen: boolean
  onClose: () => void
}

const AddEvidence: React.FC<Props> = ({id, isOpen, onClose}) => {
  const [controls, setControls] = useState<any>([])
  const [tests, setTests] = useState<any>([])
  const {currentUser} = useAuth()
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedTestIds, setSelectedTestIds] = useState<string[]>([])
  const [existingTestIds, setExistingTestIds] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)

  console.log(id)

  useEffect(() => {
    getActiveFramworks()
  }, [])

  useEffect(() => {
    getTest()
    getControls()
  }, [activeFrameworks,isOpen])

  useEffect(() => {
    getExistingTestIds()
  }, [id])

  const getExistingTestIds = () => {
    const tenantId = currentUser?.tenantId
    if (!tenantId) {
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const controlsRef = collection(tenantRef, 'controls')
    if (!id) {
        return
      }
    const controlDoc = doc(controlsRef, id)

    const unsubscribe = onSnapshot(
      controlDoc,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const controlData = docSnapshot.data()
          setExistingTestIds(controlData.document_id || [])
        } else {
          setExistingTestIds([])
        }
      },
      (error) => {
        console.error('Error fetching existing test IDs:', error)
      }
    )

    // Return the unsubscribe function
    return unsubscribe
  }

  const getActiveFramworks = () => {
    try {
        const tenantId = currentUser?.tenantId
        if (!tenantId) {
          console.error('No tenant ID available')
          return
        }
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
        console.log(frameworks)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  const getTest = () => {
    try {
        const tenantId = currentUser?.tenantId
        if (!tenantId) {
          console.error('No tenant ID available')
          return
        }
      const tenantRef = doc(db, 'tenants', tenantId)
      const testsCollectionRef = collection(tenantRef, 'tests')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const testsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          framework: doc.data().framework,
          status: doc.data().status,
          type: doc.data().type,
        }))

        // const filteredControls = testsData.filter((control) => {
        //   if (!control.framework) return false

        //   const frameworkMatch = activeFrameworks.some(
        //     (framework) =>
        //       control.framework.startsWith(framework) &&
        //       !control.framework.startsWith('ISO 27001:2013')
        //   )

        //   const typeMatch = control.type === 'Document'

        //   return frameworkMatch && typeMatch
        // })
        const filteredTests = testsData.filter((test) => {
          if (!test.framework || !controls[0]?.control_framework_mappings) return false

          const testFrameworks = test.framework.split(',').map((f) => f.trim())
          const frameworkMatch = testFrameworks.some((framework) =>
            controls[0].control_framework_mappings.includes(framework)
          )
          const typeMatch = test.type === 'Document'

          return frameworkMatch && typeMatch
        })

        setTests(filteredTests)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const getControls = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }
      const tenantRef = doc(db, 'tenants', tenantId)
      const testsCollectionRef = collection(tenantRef, 'controls')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          framework: doc.data().control_framework_mappings,
          status: doc.data().control_evidence_status,
          author: doc.data().author,
        }))

        const filteredControls = controlsData.filter((control) => {
          if (control.author === 'Custom Control') return true

          if (!control.framework) return false

          return activeFrameworks.some(
            (framework) =>
              control.framework.startsWith(framework) &&
              !control.framework.startsWith('ISO 27001:2013')
          )
        })
        console.log(filteredControls)
        setControls(filteredControls.filter((data) => data && (data.id === id || '')))
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const filteredControls = useMemo(() => {
    return tests
      .filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [tests, searchTerm])

  const resetSelectedFrameworks = () => {
    setSearchTerm('')
  }

  const handleCheckboxChange = (testId: string, isChecked: boolean) => {
    if (!existingTestIds.includes(testId)) {
      setSelectedTestIds((prevIds) => {
        if (isChecked) {
          return [...prevIds, testId]
        } else {
          return prevIds.filter((id) => id !== testId)
        }
      })
    }
  }

  const handleAddSelectedTests = async () => {
    try {
        const tenantId = currentUser?.tenantId
  if (!tenantId) {
    console.error('No tenant ID available')
    return
  }
      const tenantRef = doc(db, 'tenants', tenantId)
      const controlsRef = collection(tenantRef, 'controls')
      const controlDoc = doc(controlsRef, id)

      const updatedTestIds = existingTestIds.slice()
      selectedTestIds.forEach((testId) => {
        if (!updatedTestIds.includes(testId)) {
          updatedTestIds.push(testId)
        }
      })
      setIsLoading(true)
      await updateDoc(controlDoc, {
        document_id: updatedTestIds,
      })
      setIsLoading(false)
      console.log('Updated document IDs for control:', updatedTestIds)

      onClose()
      setSelectedTestIds([])
    } catch (error) {
      console.error('Error adding selected controls:', error)
    }
  }
  return (
    <Modal
      show={isOpen}
      onHide={() => {
        resetSelectedFrameworks()
        onClose()
      }}
      backdropClassName='bg-opacity-1'
      centered
      size='lg'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
      dialogClassName='modal-90w'
    >
      <Modal.Header
        closeButton
        style={{borderTopLeftRadius: '0.475rem', borderTopRightRadius: '0.475rem'}}
      >
        <Modal.Title>Add Test</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className='p-0 '
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(80vh - 60px)',
          borderBottomLeftRadius: '0.475rem',
          borderBottomRightRadius: '0.475rem',
          overflow: 'hidden',
        }}
      >
        <div className='static-content'>
          <div className='d-flex align-items-center position-relative my-8 px-6 '>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control ps-14'
              placeholder='Search..'
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>

          {/* <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid my-4 px-6 mb-8'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={showRecommendedOnly}
              onChange={handleToggleRecommended}
            />
            <span className='fs-7 mx-2 fw-block fw-semibold'>Show recommended only</span>
          </div> */}
        </div>

        <div className='table-container' style={{overflowY: 'auto', flexGrow: 1}}>
          <table className='table table-hover table-rounded gy-7 gs-7 m-0'>
            <thead>
              <tr
                className='fw-bold fs-6  border-bottom-2 border-top-2 border-gray-200'
                style={{color: '#5A5A5A'}}
              >
                <th className='min-w-50px'></th>
                <th className='min-w-300px'>TEST</th>
                <th className='min-w-250px'>MAPPED FRAMEWORKS</th>
              </tr>
            </thead>
            <tbody>
              {filteredControls.map((item) => (
                <tr key={item.id} className='px-6'>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        checked={
                          existingTestIds.includes(item.id) || selectedTestIds.includes(item.id)
                        }
                        onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
                        disabled={existingTestIds.includes(item.id)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='fs-6 fw-bold'>{item.name}</div>
                  </td>
                  <td>
                    <span className='fw-normal fw-bold fs-6'>
                    {item.framework
                        .split(',') 
                        .map((framework) => framework.trim())
                        .filter((framework) => {
                          const baseMapping = controls[0]?.control_framework_mappings
                            .split('‧')[0]
                            .trim()

                          return framework.startsWith(baseMapping)
                        })
                        .map((framework, index) => (
                          <span
                            key={index}
                            className='badge px-3 py-2 rounded-pill mb-1 fs-8 me-2'
                            style={{border: '2px solid #E1E1E1'}}
                          >
                            {framework}
                          </span>
                        ))}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className='modal-footer p-0 my-4 pt-4 px-6'>
          <button
            className='btn btn-sm btn-light fs-6'
            onClick={() => {
              resetSelectedFrameworks()
              onClose()
            }}
            style={{
              width: '80px',
              height: '40px',
              borderRadius: '4px',
              transition: 'background-color 0.3s',
            }}
          >
            Cancel
          </button>
          {isLoading ? (
            <button
              className='btn btn-sm fs-6'
              type='submit'
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#148363')
              }
              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#1CBB8D')
              }
              disabled
              style={{
                backgroundColor: '#1CBB8D',
                color: '#fff',
                width: '190px',
                height: '40px',
                borderRadius: '4px',
                transition: 'background-color 0.3s',
              }}
            >
              Adding tests...{' '}
              <span className='spinner-border spinner-border-sm align-middle'></span>
            </button>
          ) : (
            <button
              className='btn btn-sm fs-6'
              type='submit'
              onClick={handleAddSelectedTests}
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#148363')
              }
              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#1CBB8D')
              }
              style={{
                backgroundColor: '#1CBB8D',
                color: '#fff',
                width: '190px',
                height: '40px',
                borderRadius: '4px',
                transition: 'background-color 0.3s',
              }}
            >
              Add Selected Tests
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddEvidence
