/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useRef} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import db from '../../../services/firebase'
import {collection, doc, onSnapshot, updateDoc, deleteDoc, writeBatch} from 'firebase/firestore'
import ReactPaginate from 'react-paginate'
import './pagination.css'
import {Helmet} from 'react-helmet'
import {useAuth} from '../../modules/auth'
import {useLocation} from 'react-router-dom'
import Swal from 'sweetalert2'
import 'react-loading-skeleton/dist/skeleton.css'
import {TestListLoading} from '../tests/components/loading/TestListLoading'
import AddTask from './components/AddTask'
import EditTask from './components/EditTask'

type Props = {
  className: string
  blogs?: any
  onDataCountChange: any
  filter: any
  tasks: any[]
}

const TaskManagement: React.FC<Props> = ({onDataCountChange, filter, tasks}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredItems, setFilteredItems] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [selectedPriority, setselectedPriority] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')
  const [selectedAssignee, setselectedAssignee] = useState('')
  const [selectedNumber, setSelectedNumber] = useState('50')
  let ITEMS_PER_PAGE = parseInt(selectedNumber)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const [show1, setShow1] = useState(false)
  const target = useRef(null)
  const overlayRef = useRef<HTMLDivElement>(null)
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [checkedItems, setCheckedItems] = useState<any[]>([])
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const {currentUser} = useAuth()
  // const [tasks, setTasks] = useState<any[]>([])
  const [sortBy, setSortBy] = useState('') // Track currently sorted column
  const [sortDirection, setSortDirection] = useState('asc') // asc or desc

  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [searchLength, setSearchLength] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpen2, setIsModalOpen2] = useState(false)
  const [taskID, setTaskID] = useState('')

  console.log('t', tasks)

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [])

  const priorityOrder = {
    Low: 1,
    Medium: 2,
    High: 3,
  }
  // Function to handle sorting based on column header click
  const handleSort = (column) => {
    const newSortDirection = column === sortBy && sortDirection === 'asc' ? 'desc' : 'asc'
    setSortBy(column)
    setSortDirection(newSortDirection)
  }

  const renderSortIcon = (column) => {
    if (column === sortBy) {
      return (
        <span
          className={`ms-1 icon-xxs text-muted ${
            sortDirection === 'asc' ? 'bi bi-arrow-up' : 'bi bi-arrow-down'
          }`}
        />
      )
    }
    return null
  }

  useEffect(() => {
    setFilteredItems(tasks)
  }, [tasks])

  const getActivities = async () => {
    try {
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)
      const controlsCollectionRef = collection(tenantRef, 'tasklists')

      const unsubscribe = onSnapshot(controlsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        // setTasks(controlsData)
        setFilteredItems(controlsData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const handleMarkAsComplete = async (taskId: string) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const taskRef = doc(db, 'tenants', tenantId, 'tasklists', taskId)
      await updateDoc(taskRef, {status: 'Completed'})
      getActivities()

    } catch (error) {
      console.error('Error updating task status:', error)
    }
  }

  const handleMarkAsIncomplete = async (taskId) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const taskRef = doc(db, 'tenants', tenantId, 'tasklists', taskId)
      await updateDoc(taskRef, {status: 'Incomplete'})
      // getActivities()

    } catch (error) {
      console.error('Error updating task status:', error)
    }
  }

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked
    setSelectAll(isChecked)
    setCheckedItems(isChecked ? filteredItems.map((task) => task.id) : [])
  }

  const handleRemoveTask = async (taskId: string) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: 'Remove Selected Items',
        text: 'Are you sure you want to remove the selected items? This action cannot be undone.',
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })
      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const taskRef = doc(db, 'tenants', tenantId, 'tasklists', taskId)
        await deleteDoc(taskRef)
        // getActivities()
      }
      Swal.fire({
        icon: 'success',
        title: 'Deleted!',
        text: 'Selected items have been removed.',
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      })
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while removing the selected items.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  const handleCheckboxChange = () => {
    const newCheckedStatus = !isChecked
    setIsChecked(newCheckedStatus)
    setFilteredItems(tasks.map((task) => ({...task, checked: newCheckedStatus})))
  }

  const handleTaskCheckboxChange = (taskId: string) => {
    setCheckedItems((prevCheckedItems) => {
      if (prevCheckedItems.includes(taskId)) {
        return prevCheckedItems.filter((id) => id !== taskId)
      } else {
        return [...prevCheckedItems, taskId]
      }
    })
  }

  const formatDate = (dateString: string): string => {
    try {
      const [year, month, day] = dateString.split('-')
      const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))

      const formattedDay = parseInt(day)
      const formattedMonth = date.toLocaleString('default', {month: 'long'})
      const formattedYear = year

      return `${formattedDay} ${formattedMonth}, ${formattedYear}`
    } catch (error) {
      return ''
    }
  }

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      // setShowEmptyState(true)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [])

  const toggleDropdown = () => {
    // console.log('clicked')
    setDropdownVisible(!isDropdownVisible)
  }

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target as Node) && show1) {
        setShow1(false)
      }
    }

    if (show1) {
      document.addEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [show1])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    // Assuming policy is your original data array
    if (searchTerm === '') {
      // If the search term is empty, reset to the original policies
      setFilteredItems(tasks)
      setSearchLength(false)
    } else {
      const filteredItems = tasks.filter((t) => {
        const taskName = typeof t.task_name === 'string' ? t.task_name.toLowerCase() : ''
        return taskName.includes(searchTerm)
      })
      setFilteredItems(filteredItems)
      setSearchLength(filteredItems.length === 0)
    }
  }

  const pageCount = Math.ceil(filteredItems.length / ITEMS_PER_PAGE)

  const handlePageClick = ({selected}: {selected: number}) => {
    setCurrentPage(selected)
  }

  const offset = currentPage * ITEMS_PER_PAGE

  const paginatedData = filteredItems
    .slice()
    .sort((a, b) => {
      if (!a || !b || !a.name || !b.name) return 0 // Check if a or b are undefined or if a.name or b.name are undefined
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    })
    .slice(offset, offset + ITEMS_PER_PAGE)

  const handleResetClick = () => {
    setselectedPriority('')
    setSelectedStatus('')
    setselectedAssignee('')
  }

  const handleDeselectClick = () => {
    setCheckedItems([])
    setSelectAll(false)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleOpenModal2 = (id) => {
    setIsModalOpen2(true)
    setTaskID(id)
  }
  const handleCloseModal2 = () => {
    setIsModalOpen2(false)
  }

  const handleMarkAsComplete2 = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) return

      const tenantRef = doc(db, 'tenants', tenantId)
      const batch = writeBatch(db)

      checkedItems.forEach((taskId) => {
        const taskRef = doc(tenantRef, 'tasklists', taskId)
        batch.update(taskRef, {status: 'Completed'})
      })

      await batch.commit()

      Swal.fire({
        icon: 'success',
        title: 'Tasks Updated',
        text: `${checkedItems.length} task(s) have been marked as complete.`,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      })

      // Reset checked items and refresh the task list
      setCheckedItems([])
      setSelectAll(false)
      // getActivities()
    } catch (error) {
      console.error('Error updating task status:', error)
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to mark tasks as complete. Please try again.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  return (
    <>
      <Helmet>
        <title>Secureslate | Tasks</title>
      </Helmet>

      <div className='card-header border-0 pt-6 h-100 mb-1'>
        <div className='card-title'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control  w-250px ps-14'
              placeholder='Search..'
              value={searchTerm}
              onChange={handleInputChange}
            />
          </div>

          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          {/* {checkedItems.length > 0 ? (
            <>
              {checkedItems.length > 0 ? (
                <div className='  border-end pe-4 text-center' style={{ color: '#A1A5B7' }}>
                  <span>
                    <p className='pt-4'>{checkedItems.length} Selected</p>
                  </span>
                </div>
              ) : null}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className='mx-5' style={{ cursor: 'pointer' }} onClick={handleDeselectClick}>
                  <img src='/media/buttons/Deselect.svg' alt='Deselect' />
                </div>
                <div className='mb-1' style={{ cursor: 'pointer' }}>
                  
                  <button
                    className='btn btn-sm fs-6'
                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#148363')
                    }
                    onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#1CBB8D')
                    }
                    ref={target}
                    onClick={() => setShow1(!show1)}
                    style={{
                      backgroundColor: '#1CBB8D',
                      color: '#fff',
                      width: '130px',
                      height: '40px',
                      borderRadius: '4px',
                      left: '615px',
                      top: '20px',
                      transition: 'background-color 0.3s',
                    }}
                  >
                    {' '}
                    Assign Owner
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <button
                // disabled={isLoading}
                type='button'
                className='btn btn-light-primary me-3'
                data-kt-menu-trigger='click'
                style={{ backgroundColor: '#f1fdf9' }}
                data-kt-menu-placement='bottom-end'
              >
                <KTIcon iconName='filter' className='fs-2' />
                Filter
              </button>
         
              <div
                className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
                data-kt-menu='true'
              >
              
                <div className='px-7 py-5'>
                  <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                </div>
             
                <div className='separator border-gray-200'></div>
            
                <div className='px-7 py-5' data-kt-user-table-filter='form'>
              
                  <div className='mb-3'>
                    <label className='form-label fs-6'>Priority:</label>
                    <div className='mb-3 d-flex align-items-center'>
                      <select
                        id='framework'
                        className='form-select form-control'
                        aria-label='Select Framework'
                        value={selectedPriority}
                        onChange={(e) => setselectedPriority(e.target.value)}
                      >
                        <option value='' disabled selected>
                          Select Priority
                        </option>
                        <option value='low'>Low</option>
                        <option value='medium'>Medium</option>
                        <option value='medium'>High</option>
                      </select>
                      {selectedPriority && (
                        <button
                          className='btn btn-link p-0'
                          onClick={(e) => {
                            setselectedPriority('')
                            e.stopPropagation()
                          }}
                        >
                          <span aria-hidden='true' className='mx-2 fw-bold fs-2'>
                            &times;
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                 
                  <div className='mb-3'>
                    <label className='form-label  fs-6'>Status</label>
                    <div className='mb-3 d-flex align-items-center'>
                      <select
                        id='status'
                        className='form-select form-control'
                        aria-label='Select Status'
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                      >
                        <option value='' disabled selected>
                          Select Status
                        </option>
                        <option value='Pass'>Incomplete</option>
                        <option value='Fail'>Failing</option>
                        <option value='Fail'>Completed</option>
                      </select>
                      {selectedStatus && (
                        <button
                          className='btn btn-link p-0'
                          onClick={(e) => {
                            setSelectedStatus('')
                            e.stopPropagation()
                          }}
                        >
                          <span aria-hidden='true' className='mx-2 fw-bold fs-2'>
                            &times;
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='owner' className='form-label  fs-6'>
                      Assign To
                    </label>
                    <div className='mb-3 d-flex align-items-center'>
                      <select
                        id='owner'
                        className='form-select form-control'
                        aria-label='Select Owner'
                        value={selectedAssignee}
                        onChange={(e) => setselectedAssignee(e.target.value)}
                      >
                        <option value='' disabled selected>
                          Selecet Assignee
                        </option>
                        {personnels.map((personnel) => (
                          <option key={personnel.id} value={personnel.fullName}>
                            {personnel.fullName}
                          </option>
                        ))}
                      </select>
                      {selectedAssignee && (
                        <button
                          className='btn btn-link p-0'
                          onClick={(e) => {
                            setselectedAssignee('')
                            e.stopPropagation()
                          }}
                        >
                          <span aria-hidden='true' className='mx-2 fw-bold fs-2'>
                            &times;
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
              

                  <div className='form-group'>
                    <label className='fw-semibold mt-5 fs-6 mb-1'>Due date</label>
                    <input
                      type='date'
                      className='form-control '
                      placeholder='Selcted due date'
                      name='dateOfLastReview'
                      value={dueDate}
                      onChange={(e) => setDueDate(e.target.value)}
                    />
                  </div>

               
                  <div className='d-flex justify-content-end mt-6'>
                    <button
                      type='button'
                      className='btn btn-sm fs-6 btn-light btn-active-light-primary fw-bold me-4 px-6'
                      data-kt-menu-dismiss='true'
                      data-kt-user-table-filter='reset'
                      onClick={(e) => {
                        handleResetClick()
                        e.stopPropagation()
                      }}
                    >
                      Reset
                    </button>
                    <button
                      disabled={isLoading}
                      type='button'
                      onClick={handleSubmit}
                      className='btn btn-sm fs-6 fw-bold px-6'
                      data-kt-menu-dismiss='true'
                      data-kt-user-table-filter='filter'
                      style={{ backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px' }}
                      onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#148363')
                      }
                      onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#1CBB8D')
                      }
                    >
                      Apply
                    </button>
                  </div>
        
                </div>
         
              </div>
          
            </>
          )} */}

          {isChecked ? (
            <>
              <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Click to mark as complete'
              >
                <button
                  className='btn btn-sm fs-6'
                  onClick={handleMarkAsComplete2}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                  // disabled={activities.length === 0}
                  style={{
                    backgroundColor: '#1CBB8D',
                    color: '#fff',
                    height: '40px',
                    borderRadius: '4px',
                    transition: 'background-color 0.3s',
                  }}
                >
                  Mark as complete
                </button>
              </div>
            </>
          ) : (
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='Click to add a task'
            >
              {/* <a
              href='#'
              className='btn btn-sm btn-primary'
              style={{backgroundColor: '#1CBB8D'}}
              onClick={handleOffboard}
            >
              <KTIcon iconName='plus' className='fs-3' />
              Add Task
            </a> */}
              <button
                className='btn btn-sm fs-6'
                onClick={handleOpenModal}
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#148363')
                }
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                }
                // disabled={activities.length === 0}
                style={{
                  backgroundColor: '#1CBB8D',
                  color: '#fff',
                  width: '130px',
                  height: '40px',
                  borderRadius: '4px',

                  transition: 'background-color 0.3s',
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='18'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  className='icon icon-tabler icons-tabler-outline icon-tabler-plus mx-1'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M12 5l0 14' />
                  <path d='M5 12l14 0' />
                </svg>
                Add Task
              </button>
            </div>
          )}
        </div>
      </div>

      <AddTask isOpen={isModalOpen} onClose={handleCloseModal} />
      <EditTask id={taskID} isOpen={isModalOpen2} onClose={handleCloseModal2} />
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive' style={{minHeight: '60vh'}}>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                  </div>
                </th>
                <th className='min-w-250px' onClick={() => handleSort('taskName')}>
                  Title {renderSortIcon('taskName')}
                </th>
                <th className='min-w-100px' onClick={() => handleSort('priority')}>
                  Priority {renderSortIcon('priority')}
                </th>
                <th className='min-w-150px' onClick={() => handleSort('owner')}>
                  Assigned To {renderSortIcon('owner')}
                </th>
                <th className='min-w-150px' onClick={() => handleSort('duedate')}>
                  Due Date {renderSortIcon('duedate')}
                </th>
                <th className='min-w-100px' onClick={() => handleSort('status')}>
                  Status {renderSortIcon('status')}
                </th>
                <th className='min-w-50px text-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {filteredItems.length > 0 ? (
                filteredItems
                  .sort((a, b) => {
                    const key = sortBy
                    const direction = sortDirection === 'asc' ? 1 : -1

                    // Special sorting for priority
                    if (key === 'priority') {
                      return direction * (priorityOrder[a[key]] - priorityOrder[b[key]])
                    }

                    if (key === 'duedate') {
                      // Special case for date sorting
                      const dateA = new Date(a[key]).getTime()
                      const dateB = new Date(b[key]).getTime()
                      return (dateA - dateB) * direction
                    } else if (a[key] < b[key]) {
                      return -1 * direction
                    } else if (a[key] > b[key]) {
                      return 1 * direction
                    } else {
                      return 0
                    }
                  })
                  .map((task) => (
                    <tr key={task.id}>
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input widget-9-check'
                            type='checkbox'
                            checked={checkedItems.includes(task.id)}
                            onChange={() => handleTaskCheckboxChange(task.id)}
                          />
                        </div>
                      </td>

                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold text-hover-primary d-block fs-6'
                          onClick={() => handleOpenModal2(task.id)}
                        >
                          {task.task_name}
                        </a>
                      </td>

                      <td>
                        <a
                          href='#'
                          className={`badge fs-7 ${
                            task.priority === 'High'
                              ? 'badge-danger'
                              : task.priority === 'Medium'
                              ? 'badge-warning'
                              : 'badge-primary'
                          }`}
                          style={{
                            color:
                              task.priority === 'High'
                                ? '#EE3043'
                                : task.priority === 'Medium'
                                ? '#FFA800'
                                : '#1CBB8D',
                            background:
                              task.priority === 'High'
                                ? '#FFF5F8'
                                : task.priority === 'Medium'
                                ? '#FFF8DD'
                                : '#E8F8F4',
                          }}
                        >
                          {task.priority}
                        </a>
                      </td>

                      {/* <td>
                        <span
                          className='badge p-2 rounded-pill fs-6 fw-semibold'
                          style={{border: '1px solid #E1E1E1'}}
                        >
                          <span
                            className={`badge border rounded-circle mx-2  ${
                              task.priority === 'High'
                                ? 'bg-danger'
                                : task.priority === 'Medium'
                                ? 'bg-warning'
                                : 'bg-primary'
                            }
                           `}
                            style={{
                              width: '12px',
                              height: '12px',
                              padding: '3px',
                            }}
                          >
                            {' '}
                          </span>

                          {task.priority}
                        </span>
                      </td> */}
                      <td>
                        <div className='d-flex align-items-center'>
                          {task.owner ? (
                            <>
                              <div className='symbol symbol-35px me-5'>
                                <span
                                  className='symbol-label fs-7 fw-bold'
                                  style={{backgroundColor: '#DAF9EF'}}
                                >
                                  {task.owner
                                    ? (() => {
                                        const nameParts = task.owner.split(' ')
                                        if (nameParts.length > 1) {
                                          return `${nameParts[0]
                                            .charAt(0)
                                            .toUpperCase()}${nameParts[1].charAt(0).toUpperCase()}`
                                        } else {
                                          return `${nameParts[0]
                                            .charAt(0)
                                            .toUpperCase()}${nameParts[0].charAt(1).toUpperCase()}`
                                        }
                                      })()
                                    : ''}
                                </span>
                              </div>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark fw-semibold text-hover-primary fs-6'>
                                  {task.owner}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <span>-</span>
                            </>
                          )}
                        </div>
                      </td>

                      <td className='fs-6 fw-semibold'> {formatDate(task.duedate)}</td>
                      <td>
                        {/* <span
                          className='badge p-2 px-2 rounded-pill fs-6 fw-semibold'
                          style={{border: '1px solid #E1E1E1'}}
                        >
                          <span
                            className={`mx-2 ${task.status === 'completed' ? '' : 'text-danger'}`}
                            style={{color: task.status === 'completed' ? '#1CBB8D' : ''}}
                          >
                            {task.status === 'completed' ? 'Complete' : 'Incomplete'}
                          </span>
                        </span> */}
                        <span
                          className='badge fs-7'
                          style={{
                            color: task.status === 'Completed' ? '#1CBB8D' : '#EE3043',
                            background: task.status === 'Completed' ? '#E8F8F4' : '#FFF5F8',
                          }}
                        >
                          {' '}
                          {task.status }
                        </span>
                      </td>
                      <td className='text-end'>
                        <div className='dropdown '>
                          <button
                            className='btn btn-secondary border-0 p-0 '
                            type='button'
                            id='dropdownMenu2'
                            data-bs-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                          >
                            <img
                              src='/media/buttons/Options.svg'
                              alt='Options'
                              onClick={toggleDropdown}
                            />
                          </button>
                          <ul className='dropdown-menu' aria-labelledby='dropdownMenu2'>
                            <li>
                              <button
                                className='dropdown-item text-hover-primary'
                                style={{color: '#5A5A5A'}}
                                type='button'
                                onClick={() => handleOpenModal2(task.id)}
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='18'
                                  height='18'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='currentColor'
                                  stroke-width='2'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-edit mx-1 mb-1'
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1' />
                                  <path d='M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z' />
                                  <path d='M16 5l3 3' />
                                </svg>{' '}
                                Edit task
                              </button>
                            </li>
                            <li>
                              {task.status === 'Complete' ? (
                                <button
                                  className='dropdown-item text-hover-primary'
                                  style={{color: '#5A5A5A'}}
                                  type='button'
                                  onClick={() => handleMarkAsIncomplete(task.id)}
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='18'
                                    height='18'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    stroke-width='2'
                                    stroke-linecap='round'
                                    stroke-linejoin='round'
                                    className='icon icon-tabler icons-tabler-outline icon-tabler-x mx-1 mb-1'
                                  >
                                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                    <path d='M18 6l-12 12' />
                                    <path d='M6 6l12 12' />
                                  </svg>{' '}
                                  Mark as Incomplete
                                </button>
                              ) : (
                                <button
                                  className='dropdown-item text-hover-primary'
                                  style={{color: '#5A5A5A'}}
                                  type='button'
                                  onClick={() => handleMarkAsComplete(task.id)}
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='18'
                                    height='18'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='icon icon-tabler icons-tabler-outline icon-tabler-check mx-1 mb-1'
                                  >
                                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                    <path d='M5 12l5 5l10 -10' />
                                  </svg>{' '}
                                  Mark as complete
                                </button>
                              )}
                            </li>
                            <li>
                              <button
                                className='dropdown-item'
                                style={{color: '#F15363'}}
                                type='button'
                                onClick={() => handleRemoveTask(task.id)}
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='18'
                                  height='18'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='#F15363'
                                  strokeWidth='2'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-1 mb-1'
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M4 7l16 0' />
                                  <path d='M10 11l0 6' />
                                  <path d='M14 11l0 6' />
                                  <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                  <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                </svg>{' '}
                                Remove task
                              </button>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))
              ) : (
                <>
                  {searchLength === true ? (
                    <tr>
                      <td colSpan={10} className='text-center'>
                        <div className='m-20'>
                          <img src='/media/buttons/emptystate.svg' alt='' />
                          <div className='m-5 fs-5'>
                            <p style={{fontWeight: 'bold'}}>No Tasks Found</p>
                          </div>
                          <p className=''>
                            We couldn't find any policies that match your search. Maybe try a
                            different search!
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      <tr>
                        {!showEmptyState ? (
                          <td colSpan={10}>
                            <div
                              className='d-flex justify-content-center align-items-center text-center'
                              style={{height: '400px'}}
                            >
                              <TestListLoading />
                            </div>
                          </td>
                        ) : (
                          <td colSpan={10}>
                            <div
                              className='d-flex justify-content-center align-items-center text-center'
                              style={{height: '100%'}}
                            >
                              <div className='m-20'>
                                <img src='/media/buttons/emptystate.svg' alt='' />
                                <div className=' fs-5'>
                                  <p style={{fontWeight: 'bold'}}>No task added</p>
                                </div>
                                <p>Maybe try adding new task </p>
                              </div>
                            </div>
                          </td>
                        )}
                      </tr>
                    </>
                  )}
                </>
              )}
            </tbody>
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {filteredItems.length >= 10 && (
        <div className='d-flex justify-content-between'>
          <div
            id=''
            className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar'
          >
            <div>
              <select
                name='kt_permissions_table_length'
                aria-controls='kt_permissions_table'
                className='form-select  mx-8'
                id='dt-length-1'
                value={selectedNumber}
                onChange={(e) => setSelectedNumber(e.target.value)}
              >
                <option value='20'>20</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
              </select>
              {/* <label for='dt-length-1'></label> */}
            </div>
          </div>

          <div className='d-flex justify-content-end my-10'>
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </div>
        </div>
      )}
    </>
  )
}

export {TaskManagement}
