// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import {getStorage} from 'firebase/storage'
import {getFirestore} from 'firebase/firestore'
import {
  browserSessionPersistence,
  getAuth,
  GoogleAuthProvider,
  setPersistence,
  signInWithPopup,
} from 'firebase/auth'
import {initializeApp} from 'firebase/app'
import 'firebase/storage'

// import {getAnalytics} from 'firebase/analytics'

// const firebaseConfig = {
//   apiKey: 'AIzaSyAl_Yy3TWdK2bAkpzm84iPkHeSD_YITzA8',
//   authDomain: 'slate-dashboard-c68d0.firebaseapp.com',
//   projectId: 'slate-dashboard-c68d0',
//   storageBucket: 'slate-dashboard-c68d0.appspot.com',
//   messagingSenderId: '466399875785',
//   appId: '1:466399875785:web:7b94c37d373ecff88c3318',
//   measurementId: 'G-E2BPH36B1P',
// }

//production version>>>>

const firebaseConfig = {
  apiKey: 'AIzaSyBzxltHdSHVe7sUKD4lxobJKTtOetJeq6k',
  authDomain: 'slate-134a0.firebaseapp.com',
  projectId: 'slate-134a0',
  storageBucket: 'slate-134a0.appspot.com',
  messagingSenderId: '759171650906',
  appId: '1:759171650906:web:fac324dba2c62dc2234f63',
  measurementId: 'G-7VGVJBNS4T',
}

//development version>>>>

// const firebaseConfig = {
//   apiKey: "AIzaSyBrmeK1nBdFsDURvJcoJMGWz4jiw4WypVs",
//   authDomain: "slate-dev-b7cd9.firebaseapp.com",
//   projectId: "slate-dev-b7cd9",
//   storageBucket: "slate-dev-b7cd9.appspot.com",
//   messagingSenderId: "1050137580521",
//   appId: "1:1050137580521:web:ecfb54e7dc06bf93b3b178",
//   measurementId: "G-1S8E3QEEBL"
// };

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
// const analytics = getAnalytics(app)

const firebaseApp = firebase.initializeApp(firebaseConfig)
// const firebaseApp = initializeApp(firebaseConfig);
const db = firebase.firestore()
// const db = getFirestore(firebaseApp)
// export const storage =firebase.storage()
export const storage = getStorage(firebaseApp)
export const auth1 = getAuth(firebaseApp)
export const googleProvider = new GoogleAuthProvider()
// Set session persistence
setPersistence(auth1, browserSessionPersistence)
export default db
