import db from '../../../services/firebase'

import {collection, getDocs, getDoc, addDoc, updateDoc, deleteDoc, doc} from 'firebase/firestore'

const onboardingCollectionRef = collection(db, 'Onboarding_Trainings')

class TrainingDataService {
  addPCITraining = async (newOnboarding, tenantId, uid) => {
    try {
      const dataToAdd = {
        tenantId: tenantId,
        uid: uid,
        PCI: newOnboarding,
      }

      const docRef = await addDoc(onboardingCollectionRef, dataToAdd)
      console.log('Document written with ID: ', docRef.id)
      return docRef
    } catch (error) {
      console.error('Error adding document: ', error)
      throw error
    }
  }

  addHIPPATraining = async (newOnboarding, tenantId, uid) => {
    try {
      const dataToAdd = {
        tenantId: tenantId,
        uid: uid,
        HIPPA: newOnboarding,
      }

      const docRef = await addDoc(onboardingCollectionRef, dataToAdd)
      console.log('Document written with ID: ', docRef.id)
      return docRef
    } catch (error) {
      console.error('Error adding document: ', error)
      throw error
    }
  }
  addCCPATraining = async (newOnboarding, tenantId, uid) => {
    try {
      const dataToAdd = {
        tenantId: tenantId,
        uid: uid,
        CCPA: newOnboarding,
      }

      const docRef = await addDoc(onboardingCollectionRef, dataToAdd)
      console.log('Document written with ID: ', docRef.id)
      return docRef
    } catch (error) {
      console.error('Error adding document: ', error)
      throw error
    }
  }
  addGDPRTraining = async (newOnboarding, tenantId, uid) => {
    try {
      const dataToAdd = {
        tenantId: tenantId,
        uid: uid,
        GDPR: newOnboarding,
      }

      const docRef = await addDoc(onboardingCollectionRef, dataToAdd)
      console.log('Document written with ID: ', docRef.id)
      return docRef
    } catch (error) {
      console.error('Error adding document: ', error)
      throw error
    }
  }
  addPCISecureTraining = async (newOnboarding, tenantId, uid) => {
    try {
      const dataToAdd = {
        tenantId: tenantId,
        uid: uid,
        PCISecure: newOnboarding,
      }

      const docRef = await addDoc(onboardingCollectionRef, dataToAdd)
      console.log('Document written with ID: ', docRef.id)
      return docRef
    } catch (error) {
      console.error('Error adding document: ', error)
      throw error
    }
  }
  addSATTraining = async (newOnboarding, tenantId, uid) => {
    try {
      const dataToAdd = {
        tenantId: tenantId,
        uid: uid,
        SAT: newOnboarding,
      }

      const docRef = await addDoc(onboardingCollectionRef, dataToAdd)
      console.log('Document written with ID: ', docRef.id)
      return docRef
    } catch (error) {
      console.error('Error adding document: ', error)
      throw error
    }
  }

  updatePCITraining = (id, updateVendor) => {
    const dataToAdd = {PCI: updateVendor}
    const policyDoc = doc(db, 'Onboarding_Trainings', id)
    return updateDoc(policyDoc, dataToAdd)
  }
  updateHIPPATraining = (id, updateVendor) => {
    const dataToAdd = {HIPPA: updateVendor}
    const policyDoc = doc(db, 'Onboarding_Trainings', id)
    return updateDoc(policyDoc, dataToAdd)
  }
  updateCCPATraining = (id, updateVendor) => {
    const dataToAdd = {CCPA: updateVendor}
    const policyDoc = doc(db, 'Onboarding_Trainings', id)
    return updateDoc(policyDoc, dataToAdd)
  }
  updateGDPRTraining = (id, updateVendor) => {
    const dataToAdd = {GDPR: updateVendor}
    const policyDoc = doc(db, 'Onboarding_Trainings', id)
    return updateDoc(policyDoc, dataToAdd)
  }
  updatePCISecureTraining = (id, updateVendor) => {
    const dataToAdd = {PCISecure: updateVendor}
    const policyDoc = doc(db, 'Onboarding_Trainings', id)
    return updateDoc(policyDoc, dataToAdd)
  }
  updateSATTraining = (id, updateVendor) => {
    const dataToAdd = {SAT: updateVendor}
    const policyDoc = doc(db, 'Onboarding_Trainings', id)
    return updateDoc(policyDoc, dataToAdd)
  }

  deleteTraining = (id) => {
    const policyDoc = doc(db, 'Onboarding_Trainings', id)
    return deleteDoc(policyDoc)
  }

  getAllOnboarding = () => {
    return getDocs(onboardingCollectionRef)
  }

  getTraining = (id) => {
    const onboardData = doc(db, 'Onboarding_Trainings', id)
    return getDoc(onboardData)
  }
}

export default new TrainingDataService()
