import React from 'react'
import {RiskPageTitle} from '../../../../../_metronic/layout/core/RiskPageData'

const RiskOverview = () => {
  return (
    <>
      <RiskPageTitle
        icon='personnelIcon.svg'
        title='Risk Management'
        desc={'Identify, assess, and mitigate risks to ensure compliance'}
        // breadcrumbs={usersBreadcrumbs}
      >
        Risk Management
      </RiskPageTitle>
    </>
  )
}

export default RiskOverview
