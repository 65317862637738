import React, {FC, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
// import {VendorRisk} from './VendorRisk'
import {RiskListHeader} from './components/header/RiskListHeader'
import {KTCard} from '../../../_metronic/helpers'
import {ToastContainer, toast} from 'react-toastify'
import {RiskDetailsManagement} from './RiskDetailsMangement'
import {CompleteAssessment} from './CompleteAssesment'

export const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CompleteAssessmentWrapper: FC = () => {
  const [parentDataCount, setParentDataCount] = useState(0)
  return (
    <>
      <PageTitle
        icon='personnelIcon.svg'
        title=' Risk Management'
        desc={`${parentDataCount + ' ' + 'risk'}`}
        breadcrumbs={usersBreadcrumbs}
      >
        Risk Management
      </PageTitle>
      {/* <ControlInformationListHeader /> */}
      <CompleteAssessment />
      {/* {assetID !== undefined && (
          <EditAssetModel
            id={assetID}
            setAssetID={setAssetID}
            sendDataToParent={receiveDataFromChild}
          />
        )} */}

      {/* {assetID !== undefined && <AddAsset alertMessage={receiveAlertMessage} />} */}
      {/* <VendorRisk className='card-xl-stretch mb-xl-8' /> */}
      <ToastContainer />
    </>
  )
}

export default CompleteAssessmentWrapper
