import db from '../../../../services/firebase'
import {collection, getDocs, getDoc, addDoc, updateDoc, deleteDoc, doc} from 'firebase/firestore'

const personnelCollectionRef = collection(db, 'PersonnelGroupManagement')

class PersonnelGroupService {
  addGroup = (newPersonnel) => {
    return addDoc(personnelCollectionRef, newPersonnel)
  }

  updatePersonnel = (id, updatePersonnel) => {
    const PersonnelDoc = doc(db, 'PersonnelGroupManagement', id)
    return updateDoc(PersonnelDoc, updatePersonnel)
  }

  deletePersonnel = (id) => {
    const PersonnelDoc = doc(db, 'PersonnelGroupManagement', id)
    return deleteDoc(PersonnelDoc)
  }

  getAllPersonnels = () => {
    return getDocs(personnelCollectionRef)
  }

  getPersonnel = (id) => {
    const PersonnelDoc = doc(db, 'PersonnelGroupManagement', id)
    return getDoc(PersonnelDoc)
  }
}

export default new PersonnelGroupService()
