import React, {FC} from 'react'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'
// import {VendorReports} from './VendorReports'
import {ReportsListHeader} from './components/header/ReportsListHeader'
import {KTCard} from '../../../_metronic/helpers'
import {Reports} from './table/Reports'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ReportsWrapper: FC = () => {
  return (
    <>
      <PageTitle
        icon='Framework.svg'
        title='Frameworks'
        desc='View all your compliance and security frameworks'
        breadcrumbs={usersBreadcrumbs}
      >
        Frameworks
      </PageTitle>
      {/* <KTCard> */}
        {/* <ReportsListHeader /> */}
        <Reports className='card-l-stretch mb-xl-8 ' />
        {/* <VendorReports className='card-xl-stretch mb-xl-8' /> */}
      {/* </KTCard> */}
    </>
  )
}

export default ReportsWrapper
