import {useListView} from '../../../vendor/core/ListViewProvider'
import {AssetListGrouping} from './AssetListGrouping'
import {AssetListSearchComponent} from './AssetListSearchComponent'
import {AssetListToolbar} from './AssetListToolbar'

const AssetListHeader = () => {
  const {selected} = useListView()
  return (
    <></>
    // <div className='card-header border-0 pt-6'>
    //   {/* <AssetListSearchComponent /> */}
    //   {/* begin::Card toolbar */}
    //   <div className='card-toolbar'>
    //     {/* begin::Group actions */}
    //     {selected.length > 0 ? <AssetListGrouping /> : <AssetListToolbar />}
    //     {/* end::Group actions */}
    //   </div>
    //   {/* end::Card toolbar */}
    // </div>
  )
}

export {AssetListHeader}
