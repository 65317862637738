import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore'
import db, {storage} from '../../../../services/firebase'
import {useAuth} from '../../../modules/auth'
import UploadFile from './UploadFile'
import {deleteObject, ref} from 'firebase/storage'
import Swal from 'sweetalert2'
import AddTask from '../../controls/components/submodal/AddTask'
import {ToastContainer} from 'react-toastify'
import {format, parseISO} from 'date-fns'

const DetailsTest = () => {
  const {id} = useParams()
  const [controlDatas, setControlDatas] = useState<any[]>([])
  const [personnels, setPersonnels] = useState<any[]>([])
  const {currentUser} = useAuth()
  const [specificTests, setSpecificTests] = useState<any[]>([])
  const [tests, setTests] = useState<any[]>([])
  const [selectedTest, setSelectedTest] = useState([] as any[])
  const [evidences, setEvidences] = useState([] as any[])
  const [policies, setPolicies] = useState([] as any[])
  const [specificdocuments, setSpecificdocuments] = useState<any[]>([])
  const [documentsCount, setDocumentsCount] = useState('')
  const [testsCount, setTestsCount] = useState('')
  const [showUploadFile, setShowUploadFile] = useState(false)
  const [fileData, setFileData] = useState<File[]>([])
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [newOwner, setNewOwner] = useState('')
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined)
  const [activeTab, setActiveTab] = useState('overview')
  const [noteInput, setNoteInput] = useState('')
  const [tasks, setTasks] = useState<any[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [allTaskMarkAsCompleted, setAllMarkAsCompleted] = useState(false)
  const [editingNoteIndex, setEditingNoteIndex] = useState(null)
  const [editedNoteContent, setEditedNoteContent] = useState('')
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      // Logout()
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setPersonnels(risksData)
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    getTests()
  }, [])

  useEffect(() => {
    getActiveFramworks()
  }, [])

  const getTests = async () => {
    try {
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'tests')
      const testDocRef = doc(testsCollectionRef, id)
      console.log(testDocRef)

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const testsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setTests(testsData)

        const filteredEvidences = testsData.filter((evidence) => evidence.id === id)

        setSelectedTest(filteredEvidences)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  useEffect(() => {
    getActivities()
  }, [])

  const getActivities = async () => {
    try {
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)
      const controlsCollectionRef = collection(tenantRef, 'tasklists')
      var activeTaskListsQuery = query(controlsCollectionRef, where('test_id', '==', id))
      const unsubscribe = onSnapshot(activeTaskListsQuery, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setTasks(controlsData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  useEffect(() => {
    const docIdss = controlDatas.filter((doc) => doc.id === id).flatMap((item) => item.document_id)

    const docControl = tests.filter((item) => docIdss.includes(item.id))

    setSpecificdocuments(docControl)
  }, [controlDatas, id, tests])
  specificdocuments.forEach((test) => {
    console.log(test.id)
  })

  useEffect(() => {
    const countOkDocuments = () => {
      const okDocuments = specificdocuments.filter((document) => {
        const relevantEvidences = evidences.filter((evi) => evi.testId === document.id)
        const firstEvidence = relevantEvidences.length > 0 ? relevantEvidences[0] : null
        return firstEvidence && firstEvidence.files && firstEvidence.files.length > 0
      }).length

      return `${okDocuments}/${specificdocuments.length}`
    }

    setDocumentsCount(countOkDocuments())
  }, [specificdocuments, evidences])

  useEffect(() => {
    const countOkTests = () => {
      const okTests = specificTests.filter((test) => {
        const relevantEvidences = evidences.filter((evi) => evi.testId === test.id)
        const hasFiles = relevantEvidences.some((evi) => evi.policy && evi.policy.length > 0)
        return hasFiles
      })
      return `${okTests.length}/${specificTests.length}`
    }

    setTestsCount(countOkTests())
  }, [specificTests, evidences])

  useEffect(() => {
    const testIdss = controlDatas.filter((doc) => doc.id === id).flatMap((item) => item.test_id)

    const testControl = tests.filter((item) => testIdss.includes(item.id))

    setSpecificTests(testControl)
  }, [controlDatas, id, tests])
  specificTests.forEach((test) => {
    console.log(test.name)
  })

  useEffect(() => {
    try {
      const unsubscribe = onSnapshot(collection(db, 'Test_Evidences'), (snapshot) => {
        const testsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          testId: doc.data().testId,
        }))
        const filteredEvidences = testsData.filter((evidence) => evidence.testId === id)
        setEvidences(filteredEvidences)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }, []) // Add TestId to the dependency array to trigger the effect when it changes
  console.log(evidences)

  useEffect(() => {
    try {
      const unsubscribe = onSnapshot(collection(db, 'PolicyManagement'), (snapshot) => {
        const testsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          policy: doc.data().policy,
        }))
        console.log(testsData)
        const filteredEvidences = testsData.filter((policyData) =>
          specificTests.filter((test) => test.policies === policyData.policy)
        )
        setPolicies(filteredEvidences)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(
          collection(db, 'BackgroundCheck_onboarding'),
          where('tenantId', '==', currentUser?.tenantId),
          where('uid', '==', currentUser?.uid)
        )
        // Create a query to filter documents based on tenantId and uid

        const querySnapshot = await getDocs(q)
        // Fetch documents based on the created query
        querySnapshot.forEach((doc) => {
          const avatarUrl = doc.data()?.avatarUrl
          setAvatarUrl(avatarUrl)
        })
      } catch (error) {
        console.error('Error fetching data:', error)
        // Handle errors if any
      }
    }

    fetchData()
  }, [currentUser?.tenantId, currentUser?.uid])

  const handleOwner = async (event) => {
    const selectedOwner = event.target.value
    console.log(selectedOwner)
    setNewOwner(selectedOwner)

    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/tests/${id}`)
      await updateDoc(testDocRef, {
        owner: selectedOwner,
      })
      console.log('Owner assigned successfully')
    } catch (error) {
      console.error('Error assigning owner: ', error)
    }
  }

  const handleFileUpload = (files: File[] | null) => {
    if (files && files.length > 0) {
      setFileData((prevFiles) => [...prevFiles, ...files])
    }
  }
  const handleAddFileClick = () => {
    setShowUploadFile(true)
  }

  const handleViewClick = (file) => {
    // Handle the update action
    const fileUrl = file.url

    // Create a hidden link
    const link = document.createElement('a')
    link.href = fileUrl
    link.target = '_blank'
    // link.download = `${file.policy}`

    // Append the link to the document
    document.body.appendChild(link)

    // Programmatically trigger a click on the link
    link.click()

    // Remove the link from the document
    document.body.removeChild(link)

    setDropdownVisible(false)
  }
  const toggleDropdown = () => {
    // console.log('clicked')
    setDropdownVisible(!isDropdownVisible)
  }
  const handleDownloadClick = async (file) => {
    try {
      // Assuming policyData.fileUrl contains the URL to the policy file
      const fileUrl = file.url

      // Fetch the file content
      const response = await fetch(fileUrl)
      const fileContent = await response.blob()

      // Create a Blob with the file content
      const blob = new Blob([fileContent], {type: 'application/octet-stream'})

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob)

      // Create a hidden link
      const link = document.createElement('a')
      link.href = blobUrl
      link.download = `${file.name}` // Set the desired file name

      // Append the link to the document
      document.body.appendChild(link)

      // Programmatically trigger a click on the link
      link.click()

      // Remove the link and revoke the Blob URL from the document
      document.body.removeChild(link)
      URL.revokeObjectURL(blobUrl)

      setDropdownVisible(false)
    } catch (error) {
      console.error('Error downloading policy file:', error)
    }
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab)
    // Additional logic if needed when a tab is clicked
  }

  const handleDeleteClick = async (file) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${file?.name}`,
        text: 'Are you sure you want to remove this file? This action cannot be undone.',
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const testDocRef = doc(db, `tenants/${tenantId}/tests/${id}`)

        const docSnapshot = await getDoc(testDocRef)

        if (docSnapshot.exists()) {
          const data = docSnapshot.data()

          // Filter out the file to be deleted from the files array
          const updatedFiles = data.files.filter((f) => f.name !== file.name)

          // Reference to the file in Firebase Storage
          const fileRef = ref(storage, file.url)

          // Delete the file from Firebase Storage
          await deleteObject(fileRef)

          // Update the document with the new files array
          await updateDoc(testDocRef, {files: updatedFiles})

          console.log(`File ${file.name} deleted successfully.`)

          Swal.fire({
            icon: 'success',
            title: 'Deleted!',
            text: `"${file?.name}" has been deleted.`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
            buttonsStyling: false,
          })
        } else {
          console.error('Document does not exist.')
        }
      }
    } catch (error) {
      console.error('Error deleting file:', error)
      // Handle the error or show an error message to the user
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the file.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      setDropdownVisible(false)
    }
  }

  const handleMarkAsCompleteClicked = async (taskId: string, status: boolean) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      console.log('Reached here')
      console.log(tenantId)
      console.log(taskId, status)

      const testDocRef = doc(db, `tenants/${tenantId}/tasklists/${taskId}`)
      await updateDoc(testDocRef, {
        checked: status,
      })
      getActivities()
    } catch (error) {
      console.log(error)
    }
  }

  const getBadgeClass = (testPassing) => {
    const [numerator, denominator] = testPassing.split('/').map(Number)
    console.log(testPassing)
    if (numerator === denominator && numerator !== 0) {
      return 'bg-primary' // All tests passed
    } else if (testPassing === '0/0') {
      return 'bg-warning' // Special case for 0/0
    } else {
      return 'bg-danger' // Some tests failed
    }
  }

  const getStatusMessage = (testPassing) => {
    const [numerator, denominator] = testPassing.split('/').map(Number)
    if (testPassing === '0/0') {
      return 'Evidence Missing'
    } else if (numerator === denominator) {
      return 'OK'
    } else {
      return 'Unhealthy'
    }
  }

  function processControlValues(inputString1, inputString2) {
    // Check if either input is "N/A"
    if (inputString1 === 'N/A') {
      return inputString2 === 'N/A' ? '0/0' : inputString2
    }
    if (inputString2 === 'N/A') {
      return inputString1
    }
    console.log(inputString1, inputString2)

    // Split the input strings into numerator and denominator
    const [num1, den1] = inputString1.split('/')
    const [num2, den2] = inputString2.split('/')

    // Convert numerators and denominators to numbers
    const numerator1 = parseFloat(num1)
    const denominator1 = parseFloat(den1)
    const numerator2 = parseFloat(num2)
    const denominator2 = parseFloat(den2)

    const commonDenominator = denominator1 + denominator2

    const adjustedNumerator1 = numerator1
    const adjustedNumerator2 = numerator2

    const resultNumerator = adjustedNumerator1 + adjustedNumerator2
    const resultDenominator = commonDenominator
    return `${resultNumerator}/${resultDenominator}`
  }

  const handleNotes = async (event: React.FormEvent) => {
    event.preventDefault() // Prevent default form submission behavior

    if (!noteInput.trim()) {
      console.log('Note is empty')
      return
    }

    const currentDate = new Date()

    const day = currentDate.getDate()
    const month = currentDate.toLocaleString('default', {month: 'long'})
    const year = currentDate.getFullYear()
    const formattedDate = `${day} ${month}, ${year}`

    let hours = currentDate.getHours()
    let minutes = currentDate.getMinutes()
    let ampm = hours >= 12 ? 'PM' : 'AM'

    // Round minutes to nearest 12
    minutes = Math.round(minutes / 12) * 12
    if (minutes === 60) {
      minutes = 0
      hours += 1
    }

    hours = hours % 12
    hours = hours ? hours : 12

    const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`
    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/tests/${id}`)

      // Get the current notes array
      const docSnap = await getDoc(testDocRef)
      const currentNotes = docSnap.data()?.notes || []

      // Create a new note object
      const newNoteObject = {
        addedUser: currentUser?.userName || 'Unknown User',
        addedDate: formattedDate,
        addedTime: formattedTime,
        note: noteInput.trim(),
      }

      // Add the new note to the array
      const updatedNotes = [newNoteObject, ...currentNotes]

      // Update the document
      await updateDoc(testDocRef, {
        notes: updatedNotes,
      })

      setNoteInput('')

      console.log('Note added successfully')
    } catch (error) {
      console.error('Error adding note: ', error)
    }
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleRemoveTask = async (taskId) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: 'Remove Selected Items',
        text: 'Are you sure you want to remove the selected items? This action cannot be undone.',
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })
      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const taskRef = doc(db, 'tenants', tenantId, 'tasklists', taskId)
        await deleteDoc(taskRef)
        getActivities() // Refresh the tasks list
      }
      Swal.fire({
        icon: 'success',
        title: 'Deleted!',
        text: 'Selected items have been removed.',
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      })
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while removing the selected items.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  const handleAllMarkAsCompleteClicked = async () => {
    var status = !allTaskMarkAsCompleted
    setAllMarkAsCompleted(status)
    try {
      const tenantId = currentUser?.tenantId || ''
      const taskListsRef = collection(db, `tenants/${tenantId}/tasklists`)
      const q = query(taskListsRef, where('test_id', '==', id))
      const querySnapshot = await getDocs(q)
      const batch = writeBatch(db)
      querySnapshot.forEach((doc) => {
        batch.update(doc.ref, {checked: status})
      })
      await batch.commit()
      getActivities()
    } catch (error) {
      console.log(error)
    }
  }

  const handleEditNote = (index, currentContent) => {
    setEditingNoteIndex(index)
    setEditedNoteContent(currentContent)
  }

  const handleDeleteNote = async (index) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/tests/${id}`)

      // Get the current notes array
      const docSnap = await getDoc(testDocRef)
      const currentNotes = docSnap.data()?.notes || []

      // Remove the note at the specified index
      const updatedNotes = currentNotes.filter((_, i) => i !== index)

      // Update the document
      await updateDoc(testDocRef, {
        notes: updatedNotes,
      })

      // You might want to update your local state here if you're using it
      // setNotes(updatedNotes)
    } catch (error) {
      console.error('Error deleting note: ', error)
    }
  }

  const handleSaveEditedNote = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/tests/${id}`)

      // Get the current notes array
      const docSnap = await getDoc(testDocRef)
      const currentNotes = docSnap.data()?.notes || []

      const updatedNotes = currentNotes.map((note, index) =>
        index === editingNoteIndex ? {...note, note: editedNoteContent} : note
      )

      // Update the document
      await updateDoc(testDocRef, {
        notes: updatedNotes,
      })

      setEditingNoteIndex(null)
      setEditedNoteContent('')
    } catch (error) {
      console.error('Error editing note: ', error)
    }
  }

  const formatFileDate = (dateString: string): string => {
    if (dateString) {
      try {
        // Parse the ISO date string
        const date = parseISO(dateString)

        // Format the date
        return format(date, 'd MMMM, yyyy')
      } catch (error) {
        console.error('Error parsing date:', error)
        return 'Invalid Date'
      }
    } else {
      return 'Invalid Date'
    }
  }

  return (
    <>
      {selectedTest.length > 0 ? (
        selectedTest.map((test) => (
          <>
            <div className='card px-6 pt-6 mb-4'>
              <div className='d-flex justify-content-between align-items-center'>
                <h3 className='mb-0'>{test.name}</h3>

                <div className='d-flex justify-content-end'>
                  {/* <button
                    className='btn btn-sm btn-success fs-7 mx-2'
                    style={{backgroundColor: '#1CBB8D'}}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-download mx-1 mb-1'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
                      <path d='M7 11l5 5l5 -5' />
                      <path d='M12 4l0 12' />
                    </svg>
                    Export
                  </button> */}
                  {/* <div className='d-flex justify-content-end mb-2'> */}

                  <button
                    className='btn btn-sm  fs-6 py-3'
                    style={{backgroundColor: '#1CBB8D', color: '#fff'}}
                    onClick={handleAddFileClick}
                  >
                    Add File
                  </button>
                </div>
              </div>

              <div>
                {/* <span className='fw-normal fw-bold fs-6'>
                  <span>
                    {test.framework.split(',')[0].trim() && (
                      <span
                        className='badge px-2 py-1 rounded-pill mb-1 fs-7 me-1'
                        style={{border: '2px solid #E1E1E1'}}
                      >
                        {test.framework.split(',')[0].trim()}
                      </span>
                    )}
                    {test.framework
                      .split(',')
                      .slice(1)
                      .filter((framework) =>
                        activeFrameworks.some((item) => framework.trim().startsWith(item))
                      ).length > 0 && (
                      <span
                        className='badge px-2 py-1 rounded-pill mb-1 fs-7 '
                        style={{border: '2px solid #E1E1E1'}}
                      >
                        {test.framework
                          .split(',')
                          .slice(1)
                          .filter((framework) =>
                            activeFrameworks.some((item) => framework.trim().startsWith(item))
                          )
                          .map((framework, index, array) => (
                            <React.Fragment key={index}>
                              {framework.trim()}
                              {index < array.length - 1 && ', '}
                            </React.Fragment>
                          ))}
                      </span>
                    )}
                  </span>
                </span> */}
                {test.framework
                  .split(', ')
                  .filter((framework) =>
                    activeFrameworks.some((item) => framework.startsWith(item))
                  )
                  .map((framework, index) => (
                    <span
                      key={index}
                      className='badge px-2 py-1 rounded-pill mb-1 fs-7 me-1'
                      style={{border: '2px solid #E1E1E1'}}
                    >
                      {framework}
                    </span>
                  ))}

                <span className='fw-normal fs-6 mx-2'>
                  <span
                    className='badge fs-7'
                    style={{
                      color: test.status === 'Pass' ? '#1CBB8D' : '#EE3043',
                      background: test.status === 'Pass' ? '#E8F8F4' : '#FFF5F8',
                    }}
                  >
                    {' '}
                    {test.status === 'Pass' ? 'Passing' : 'Failing'}
                  </span>
                  <span className='mx-1'> </span>
                </span>
              </div>

              {/* <div className='separator my-4'></div> */}

              <div className='  mt-4'>
                <div className='d-flex  h-55px'>
                  <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap '>
                    <li className='nav-item'>
                      <Link
                        to={`/tests/details/${id}`}
                        className={`nav-link me-6 ${
                          activeTab === 'overview' ? 'text-active-primary active' : ''
                        }`}
                        onClick={() => handleTabClick('overview')}
                      >
                        Overview
                      </Link>
                    </li>

                    <li className='nav-item'>
                      <Link
                        to={`/tests/details/${id}`}
                        className={`nav-link me-6 ${
                          activeTab === 'notes' ? 'text-active-primary active' : ''
                        }`}
                        onClick={() => handleTabClick('notes')}
                      >
                        Notes
                      </Link>
                    </li>

                    <li className='nav-item'>
                      <Link
                        to={`/tests/details/${id}`}
                        className={`nav-link me-6 ${
                          activeTab === 'tasks' ? 'text-active-primary active' : ''
                        }`}
                        onClick={() => handleTabClick('tasks')}
                      >
                        Tasks
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {activeTab === 'overview' && (
              <>
                <div className='card mb-5 mb-xl-8 p-6 mt-8'>
                  <div className='text-grey-800 fw-semibold fs-6 mb-6 '>
                    {/* {test.description && test.description.includes('Guidance') ? (
                      <>
                        {test.description.split('Guidance')[0]} <br />
                        <br /> Guidance {test.description.split('Guidance')[1]}
                      </>
                    ) : (
                      <>
                        {test.description.split('\n').map((line, index) => (
                          <>
                            {line}
                            <br />
                          </>
                        ))}
                      </>
                    )} */}
                    <div
                      className='fv-row mb-4 text-gray-800 fs-6'
                      dangerouslySetInnerHTML={{__html: test.description}}
                    />
                  </div>

                  <div className='row g-5 g-xxl-6'>
                    <div className='col-md-6'>
                      <h4 className=' fw-bold fs-6' style={{color: '#5E6274'}}>
                        CATEGORY
                      </h4>
                      <p className='text-grey-800 fw-semibold'>{test.category}</p>

                      <h4 className=' fw-bold fs-6' style={{color: '#5E6274'}}>
                        STATUS
                      </h4>
                      <p className='text-grey-800 fw-semibold'>
                        {' '}
                        <span className='fw-normal fs-6 '>
                          <span className='fw-semibold'>
                            <span className='fw-semibold'>
                              {test.type === 'Document'
                                ? // If the test type is 'Document', check if evidences array has at least one element and if files array within the first element contains data
                                  test.files && test.files.length > 0
                                  ? 'OK'
                                  : 'Failing'
                                : policies.length > 0
                                ? 'OK'
                                : 'Failing'}
                            </span>
                          </span>{' '}
                          {/* <span className='mx-1'> . </span>
                      <span className='fs-6 text-grey-800 fw-semibold'>
                        {' '}
                        {/* {processControlValues(test.control_document, test.control_test)} Test */}
                          {/* Passing
                      </span> */}
                        </span>
                      </p>
                    </div>
                    <div className='col-md-6'>
                      <h4 className=' fw-bold fs-6' style={{color: '#5E6274'}}>
                        FRAMEWORKS MAPPED
                      </h4>
                      <p className='text-grey-800 fw-semibold'>
                        {test.framework.split(',').map((framework, index) => (
                          <span key={index}>
                            {framework.trim()}
                            {index !== test.framework.split(',').length - 1 && ', '}
                          </span>
                        ))}
                      </p>

                      <h4 className=' fw-bold fs-6' style={{color: '#5E6274'}}>
                        OWNER
                      </h4>
                      <p className='text-grey-800 fw-semibold'>
                        <span className='fw-normal'>
                          {' '}
                          <select
                            name='group'
                            className='form-select w-40 py-2 px-4'
                            data-control='select2'
                            style={{width: '200px'}}
                            data-placeholder='Select Group'
                            value={newOwner || test.owner}
                            onChange={handleOwner}
                          >
                            <option value='' defaultChecked>
                              Assign Owner
                            </option>
                            {personnels
                              .filter((user) => user.role === 'Admin')
                              .map((group) => (
                                <option key={group.id} value={group.full_name}>
                                  {group.full_name}
                                </option>
                              ))}
                          </select>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className='modal-footer'>
                  <div className='mt-2 w-100'>
                    {test.type === 'Document' ? (
                      // If it's not an image, render a link to download the file
                      <>
                        {test.files === '' || !test.files ? (
                          <>
                            <span className='text-gray-800 fw-semibold me-2 fs-6 mb-4'>
                              Evidences
                            </span>
                            <UploadFile onFileUpload={handleFileUpload} fid={test.id} />
                          </>
                        ) : (
                          <>
                            {showUploadFile && (
                              <UploadFile onFileUpload={handleFileUpload} fid={test.id} />
                            )}

                            <div>
                              {test.files &&
                                test.files.map((file, fileIndex) => (
                                  <>
                                    <div className='d-flex align-items-center mb-3 mt-4 mx-1'>
                                      <i className='ki-duotone ki-file fs-1'>
                                        <span className='path1'></span>
                                        <span className='path2'></span>
                                      </i>
                                      <div className='d-flex justify-content-between align-items-center flex-grow-1 '>
                                        <div className='mx-2 fw-bold fs-5 '>
                                          Document #{fileIndex + 1}
                                        </div>
                                      </div>
                                    </div>

                                    <div className='card m-1 mb-3' key={fileIndex}>
                                      <div className='mx-4 my-4 d-flex align-items-center'>
                                        <p className='fw-bold fs-5 mb-0'>{file.name}</p>
                                        <span className='badge border p-2 rounded-pill ms-auto'>
                                          <span
                                            className={`badge border rounded-circle  bg-primary`}
                                            style={{
                                              width: '12px',
                                              height: '12px',
                                              padding: '3px',
                                            }}
                                          >
                                            {' '}
                                          </span>{' '}
                                          <span className='text-center fw-bold text-muted mx-2'>
                                            Uploaded
                                          </span>
                                        </span>
                                      </div>
                                      <p className='mx-4'>
                                        Recurrence start: {file.date} |{' '}
                                        <div className='symbol symbol-20px me-1 rounded-circle'>
                                          {test.owner ? (
                                            avatarUrl ? (
                                              <img alt='logo' loading='lazy' src={avatarUrl} />
                                            ) : (
                                              <div className='symbol symbol-25px'>
                                                <span
                                                  className='symbol-label fs-7 fw-bold'
                                                  style={{backgroundColor: '#DAF9EF'}}
                                                >
                                                  {test.owner
                                                    ? (() => {
                                                        const nameParts = test.owner.split(' ')
                                                        if (nameParts.length > 1) {
                                                          return `${nameParts[0]
                                                            .charAt(0)
                                                            .toUpperCase()}${nameParts[1]
                                                            .charAt(0)
                                                            .toUpperCase()}`
                                                        } else {
                                                          return `${nameParts[0]
                                                            .charAt(0)
                                                            .toUpperCase()}${nameParts[0]
                                                            .charAt(1)
                                                            .toUpperCase()}`
                                                        }
                                                      })()
                                                    : ''}
                                                </span>
                                              </div>
                                            )
                                          ) : null}
                                        </div>{' '}
                                        {test.owner || 'Not assigned'}{' '}
                                      </p>

                                      <div className='mx-4 my-4 d-flex justify-content-between align-items-center'>
                                        <button
                                          className='btn btn-primary w-50 mr-2'
                                          onClick={() => handleViewClick(file)}
                                        >
                                          Review
                                        </button>
                                        <div className='dropdown'>
                                          <button
                                            className='btn btn-secondary border-0 p-0 m-2'
                                            type='button'
                                            id='dropdownMenu2'
                                            data-bs-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                          >
                                            <img
                                              src='/media/buttons/Options.svg'
                                              alt='Options'
                                              onClick={toggleDropdown}
                                            />
                                          </button>
                                          <ul
                                            className='dropdown-menu dropdown-menu-end'
                                            aria-labelledby='dropdownMenu2'
                                          >
                                            {/* <li>
                                                <button
                                                  className='dropdown-item text-muted text-hover-primary'
                                                  type='button'
                                                  onClick={() => handleViewClick(file)}
                                                >
                                                  <img
                                                    src='/media/buttons/offboardp.svg'
                                                    className='mx-2'
                                                    alt=''
                                                  />{' '}
                                                  View
                                                </button>
                                              </li> */}
                                            <li>
                                              <button
                                                className='dropdown-item text-muted text-hover-primary '
                                                type='button'
                                                onClick={(e) => handleDownloadClick(file)}
                                              >
                                                <img
                                                  src='/media/buttons/download.svg'
                                                  alt=''
                                                  className='m-1 mx-1'
                                                  style={{width: '20px', height: '20px'}}
                                                />
                                                Download
                                              </button>
                                            </li>

                                            <li>
                                              <button
                                                className='dropdown-item text-muted text-hover-primary'
                                                type='button'
                                                onClick={(e) => handleDeleteClick(file)}
                                              >
                                                <i className='fas fa-trash mx-2'></i> Remove
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {/* <a
                      href={`https://app.gtsecureslate.com/policy-library/PolicyTemplates?policies=${test.policies}`}
                      target='_blank'
                      rel='noreferrer'
                    > */}
                        <a
                          href={`https://app.getsecureslate.com/policy-library/lists?policies=${test.policies}`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <div className={`card mb-5 mb-xl-8 `}>
                            <div
                              className='card-header border-0 pt-6 mb-5'
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <span className='card-label fw-bold fs-6'>How to fix</span>
                                    <span className='text-muted fw-semibold fs-7 d-none d-md-block my-2'>
                                      Create and approve a {test.name}for your business using the
                                      templates included in our{' '}
                                      {/* <a href='/policy-library/PolicyTemplates'>policy library</a>. */}
                                      <a
                                        href={`https://app.getsecureslate.com/policy-library/lists?policies=${test.policies}`}
                                        target='_blank'
                                        rel='noreferrer'
                                      >
                                        {/* <a
                                    href={`https://app.gtsecureslate.com/policy-library/PolicyTemplates?policies=${test.policies}`}
                                    target='_blank'
                                    rel='noreferrer'
                                  > */}
                                        policy library
                                      </a>
                                      .
                                    </span>
                                  </div>
                                </div>
                              </>
                            </div>
                          </div>
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}

            {activeTab === 'notes' && (
              <>
                <div className='mb-8 mt-8'>
                  <div className='d-flex align-items-center mb-8'>
                    <textarea
                      // type='text'
                      className='form-control me-2'
                      placeholder='Leave a note'
                      name='notes'
                      value={noteInput}
                      onChange={(e) => setNoteInput(e.target.value)}
                    />
                    <button
                      className='btn btn-sm'
                      onClick={handleNotes}
                      onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#148363')
                      }
                      onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#1CBB8D')
                      }
                      // disabled={activities.length === 0}
                      style={{
                        backgroundColor: '#1CBB8D',
                        color: '#fff',
                        width: '120px',
                        height: '40px',
                        borderRadius: '4px',
                        marginRight: '15px',
                        transition: 'background-color 0.3s',
                      }}
                    >
                      Add Note
                    </button>
                  </div>
                  {tests && tests.length > 0 ? (
                    tests
                      .filter((control) => control.id === id)
                      .map((control) =>
                        control.notes && control.notes.length > 0 ? (
                          control.notes.map((note, index) => (
                            <div className='mt-4 mb-8' key={index}>
                              <div className='d-flex align-items-center justify-content-between mb-2'>
                                <div className='d-flex align-items-center'>
                                  <div className='symbol symbol-40px me-3'>
                                    {note.addedUser ? (
                                      <span
                                        className='symbol-label fs-7 fw-bold'
                                        style={{
                                          backgroundColor: '#DAF9EF',
                                          borderRadius: '50%',
                                        }}
                                      >
                                        {note.addedUser
                                          ? (() => {
                                              const nameParts = note.addedUser.split(' ')
                                              if (nameParts.length > 1) {
                                                return `${nameParts[0]
                                                  .charAt(0)
                                                  .toUpperCase()}${nameParts[1]
                                                  .charAt(0)
                                                  .toUpperCase()}`
                                              } else {
                                                return `${nameParts[0]
                                                  .charAt(0)
                                                  .toUpperCase()}${nameParts[0]
                                                  .charAt(1)
                                                  .toUpperCase()}`
                                              }
                                            })()
                                          : ''}
                                      </span>
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </div>

                                  <div className='d-flex flex-column'>
                                    <span className='text-dark fw-bold fs-6'>{note.addedUser}</span>
                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                      {note.addedDate} {note.addedTime}
                                    </span>
                                  </div>
                                </div>

                                <div className='dropdown'>
                                  <button
                                    className='btn btn-secondary border-0 p-0 m-2'
                                    type='button'
                                    id='dropdownMenu2'
                                    data-bs-toggle='dropdown'
                                    aria-haspopup='true'
                                    aria-expanded='false'
                                  >
                                    <img
                                      src='/media/buttons/Options.svg'
                                      alt='Options'
                                      onClick={toggleDropdown}
                                    />
                                  </button>
                                  <ul
                                    className='dropdown-menu dropdown-menu-end'
                                    aria-labelledby='dropdownMenu2'
                                  >
                                    <li>
                                      <button
                                        className='dropdown-item text-muted text-hover-primary '
                                        type='button'
                                        onClick={() => handleEditNote(index, note.note)}
                                      >
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='18'
                                          height='18'
                                          viewBox='0 0 24 24'
                                          fill='none'
                                          stroke='currentColor'
                                          stroke-width='2'
                                          stroke-linecap='round'
                                          stroke-linejoin='round'
                                          className='icon icon-tabler icons-tabler-outline icon-tabler-edit mx-2 mb-1'
                                        >
                                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                          <path d='M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1' />
                                          <path d='M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z' />
                                          <path d='M16 5l3 3' />
                                        </svg>
                                        Edit
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className='dropdown-item text-danger'
                                        type='button'
                                        onClick={() => handleDeleteNote(index)}
                                      >
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='18'
                                          height='18'
                                          viewBox='0 0 24 24'
                                          fill='none'
                                          stroke='currentColor'
                                          stroke-width='2'
                                          stroke-linecap='round'
                                          stroke-linejoin='round'
                                          className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-2 mb-1'
                                        >
                                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                          <path d='M4 7l16 0' />
                                          <path d='M10 11l0 6' />
                                          <path d='M14 11l0 6' />
                                          <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                          <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                        </svg>
                                        Delete
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              {editingNoteIndex === index ? (
                                <div>
                                  <textarea
                                    value={editedNoteContent}
                                    onChange={(e) => setEditedNoteContent(e.target.value)}
                                    className='form-control'
                                  />
                                  <button
                                    onClick={handleSaveEditedNote}
                                    className='btn btn-sm fs-6 mt-2'
                                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                      (e.currentTarget.style.backgroundColor = '#148363')
                                    }
                                    onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                      (e.currentTarget.style.backgroundColor = '#1CBB8D')
                                    }
                                    // disabled={activities.length === 0}
                                    style={{
                                      backgroundColor: '#1CBB8D',
                                      color: '#fff',
                                      width: '80px',
                                      height: '40px',
                                      borderRadius: '4px',
                                      marginLeft: '4px',
                                      transition: 'background-color 0.3s',
                                    }}
                                  >
                                    Save
                                  </button>
                                </div>
                              ) : (
                                <div className='card p-4 mt-2' style={{background: '#F6F6F6'}}>
                                  <span className='d-block fs-6' style={{color: '#5A5A5A'}}>
                                    <div>{note.note}</div>
                                  </span>
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <div key='no-notes'></div>
                        )
                      )
                  ) : (
                    <div></div>
                  )}
                </div>
              </>
            )}

            <AddTask isOpen={isModalOpen} onClose={handleCloseModal} test_id={id ?? ''} />

            {activeTab === 'tasks' && (
              <>
                <div className='my-4 d-flex justify-content-between'>
                  <div className='my-4 '>
                    <div className='fs-6 fw-bold mb-2'>Task List</div>
                    <span>Assign tasks that might fix this control to team members.</span>
                  </div>
                  <button
                    onClick={handleOpenModal}
                    className='btn btn-sm '
                    // onClick={handleSubmit}
                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#148363')
                    }
                    onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#1CBB8D')
                    }
                    // disabled={activities.length === 0}
                    style={{
                      backgroundColor: '#1CBB8D',
                      color: '#fff',
                      width: '110px',
                      height: '40px',
                      borderRadius: '4px',
                      marginRight: '15px',
                      transition: 'background-color 0.3s',
                    }}
                  >
                    Add Task
                  </button>
                </div>
                <div className='card p-3 mt-4'>
                  {/* begin::Table container */}
                  <div
                    className='table-responsive'
                    style={{minHeight: '300px', maxHeight: '500px', overflowY: 'auto'}}
                  >
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className='w-25px'>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                value='1'
                                data-kt-check='true'
                                data-kt-check-target='.widget-9-check'
                                checked={allTaskMarkAsCompleted}
                                onChange={handleAllMarkAsCompleteClicked}
                              />
                            </div>
                          </th>
                          <th className='min-w-200px'>Task</th>
                          <th className='min-w-50px'>Priority</th>
                          <th className='min-w-100px'>Assigned To</th>
                          {/* <th className='min-w-100px'>Due date</th> */}
                          <th className='min-w-50px text-end'></th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {tasks.map((task) => (
                          <tr key={task.id}>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input widget-9-check'
                                  type='checkbox'
                                  checked={task.checked}
                                  onChange={() =>
                                    handleMarkAsCompleteClicked(task.id, !task.checked ?? false)
                                  }
                                />
                              </div>
                            </td>

                            <td>
                              <div className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {task.taskName}
                              </div>
                            </td>
                            {/* <td className="text-end">
      <div className="d-flex flex-column w-100 me-2">
        <div className="d-flex flex-stack mb-2">
          
          <span className={`badge badge-light-success`}>{task.priority}</span>
        </div>
      </div>
    </td> */}
                            <td>
                              <span
                                className={`badge fs-7 ${
                                  task.priority === 'High'
                                    ? 'badge-danger'
                                    : task.priority === 'Medium'
                                    ? 'badge-warning'
                                    : 'badge-primary'
                                }`}
                                style={{
                                  color:
                                    task.priority === 'High'
                                      ? '#EE3043'
                                      : task.priority === 'Medium'
                                      ? '#FFA800'
                                      : '#1CBB8D',
                                  background:
                                    task.priority === 'High'
                                      ? '#FFF5F8'
                                      : task.priority === 'Medium'
                                      ? '#FFF8DD'
                                      : '#E8F8F4',
                                }}
                              >
                                {task.priority}
                              </span>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                {task.owner ? (
                                  <>
                                    <div className='symbol symbol-35px me-3'>
                                      <span
                                        className='symbol-label fs-7 fw-bold'
                                        style={{backgroundColor: '#DAF9EF'}}
                                      >
                                        {task.owner
                                          ? (() => {
                                              const nameParts = task.owner.split(' ')
                                              if (nameParts.length > 1) {
                                                return `${nameParts[0]
                                                  .charAt(0)
                                                  .toUpperCase()}${nameParts[1]
                                                  .charAt(0)
                                                  .toUpperCase()}`
                                              } else {
                                                return `${nameParts[0]
                                                  .charAt(0)
                                                  .toUpperCase()}${nameParts[0]
                                                  .charAt(1)
                                                  .toUpperCase()}`
                                              }
                                            })()
                                          : ''}
                                      </span>
                                    </div>
                                    <div className='d-flex justify-content-start flex-column'>
                                      <div className='text-dark fw-semibold text-hover-primary fs-6'>
                                        {task.owner}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <span>-</span>
                                  </>
                                )}
                              </div>
                            </td>

                            {/* <td className='fs-6 fw-semibold'>{task.duedate}</td> */}
                            <td className='text-end'>
                              <div className='dropdown '>
                                <button
                                  className='btn btn-secondary border-0 p-0 '
                                  type='button'
                                  id='dropdownMenu2'
                                  data-bs-toggle='dropdown'
                                  aria-haspopup='true'
                                  aria-expanded='false'
                                >
                                  <img
                                    src='/media/buttons/Options.svg'
                                    alt='Options'
                                    // onClick={toggleDropdown}
                                  />
                                </button>
                                <ul className='dropdown-menu' aria-labelledby='dropdownMenu2'>
                                  <li>
                                    <button
                                      className='dropdown-item'
                                      type='button'
                                      onClick={() => handleMarkAsCompleteClicked(task.id, true)}
                                    >
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='18'
                                        height='18'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='#5E6274'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        className='icon icon-tabler icons-tabler-outline icon-tabler-check mx-1 mb-1'
                                      >
                                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                        <path d='M5 12l5 5l10 -10' />
                                      </svg>{' '}
                                      Mark as complete
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className='dropdown-item'
                                      style={{color: '#F15363'}}
                                      type='button'
                                      onClick={() => handleRemoveTask(task.id)}
                                    >
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='18'
                                        height='18'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='#F15363'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-1 mb-1'
                                      >
                                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                        <path d='M4 7l16 0' />
                                        <path d='M10 11l0 6' />
                                        <path d='M14 11l0 6' />
                                        <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                        <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                      </svg>{' '}
                                      Remove task
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </>
        ))
      ) : (
        <>
          {' '}
          <div style={{height: '80vh', display: 'flex', flexDirection: 'column'}}>
            {' '}
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className='spinner-border text-primary' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          </div>
        </>
      )}
      <ToastContainer />
    </>
  )
}

export default DetailsTest
