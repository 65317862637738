import React, {FC} from 'react'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'
import {KTCard} from '../../../_metronic/helpers'
import {Reports} from './table/Reports'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ReportPageWrapper: FC = () => {
  return (
    <>
      <PageTitle
        icon='Icon-4.svg'
        title='Reports'
        desc='Get an overview of your security posture'
        breadcrumbs={usersBreadcrumbs}
      >
        Frameworks
      </PageTitle>
      {/* <KTCard> */}
        {/* <ReportsListHeader /> */}
        <Reports className='card-l-stretch mb-xl-8 ' />
        {/* <VendorReports className='card-xl-stretch mb-xl-8' /> */}
      {/* </KTCard> */}
    </>
  )
}

export default ReportPageWrapper
