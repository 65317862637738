/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useRef, useEffect} from 'react'
import clsx from 'clsx'
import {
  toAbsoluteUrl,
  defaultMessages,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
  messageFromClient,
} from '../../helpers'
import OpenAI from 'openai' // Import the necessary types
import {collection, getDocs, query, where} from 'firebase/firestore'
import db from '../../../services/firebase'
import {useAuth} from '../../../app/modules/auth'

// const openai = new OpenAI({apiKey: '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'})

type Props = {
  isDrawer?: boolean
}

const bufferMessages = defaultMessages

const ChatInner: FC<Props> = ({isDrawer = false}) => {
  const initialMessages: MessageModel[] = [
    {
      user: 1,
      type: 'in',
      text: 'Hello, I am your assistant. How can I help you today?',
      time: new Date().toLocaleTimeString(),
    },
    ...bufferMessages,
  ]
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState<MessageModel[]>(initialMessages)
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)
  const lastMessageRef = useRef<HTMLDivElement | null>(null)
  const {currentUser} = useAuth()
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined)
  const [fullName, setFullName] = useState<string | undefined>(undefined)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(
          collection(db, 'BackgroundCheck_onboarding'),
          where('tenantId', '==', currentUser?.tenantId),
          where('uid', '==', currentUser?.uid)
        )
        // Create a query to filter documents based on tenantId and uid

        const querySnapshot = await getDocs(q)
        // Fetch documents based on the created query
        querySnapshot.forEach((doc) => {
          const fullName = doc.data()?.fullName
          const avatarUrl = doc.data()?.avatarUrl
          setAvatarUrl(avatarUrl)
          setFullName(fullName)
        })
      } catch (error) {
        console.error('Error fetching data:', error)
        // Handle errors if any
      }
    }

    fetchData()
  }, [currentUser?.tenantId, currentUser?.uid])

  // console.log('msg', message)
  const sendMessage = async () => {
    if (message.trim() === '') {
      return
    }
    const newMessage: MessageModel = {
      user: 2,
      type: 'out',
      text: message,
      time: 'Just now',
    }
    bufferMessages.push(newMessage)
    // console.log(newMessage)
    bufferMessages.push({
      user: 1,
      type: 'in',
      text: 'Generating response...',
      time: new Date().toLocaleTimeString(),
      loading: true, // Set loading to true
    })

    setMessages(bufferMessages)

    toggleChatUpdateFlat(!chatUpdateFlag)
    setMessage('')
    try {
      // Make a request to OpenAI GPT-3 API
      // const response = await fetch(
      //   'https://api.openai.com/v1/engines/gpt-3.5-turbo-0613/completions',
      //   {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       Authorization: `Bearer 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`,
      //     },
      //     body: JSON.stringify({
      //       prompt: `You are a helpful assistant that have full knowledge about system secuirty.`,
      //       max_tokens: 1000,
      //       temperature: 0.7,
      //       stop: null,
      //     }),
      //   }
      // )
      // console.log('Key', process.env.REACT_APP_OPENAI_API_KEY)
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer 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`,
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo-0613',
          // gpt-4-1106-preview
          messages: [
            {
              role: 'system',
              content:
                'You are a knowledgeable AI assistant with expertise in compliance standards and best practices. Your responses are professional, thorough, and tailored to the user’s inquiries.',
            },
            {
              role: 'user',
              content: `I have a question about ${message}. Can you provide detailed information and guidance on this topic?`,
            },
          ],
          max_tokens: 200,
          temperature: 0.7,
          stop: null,
        }),
      })

      const data = await response.json()
      const messageContent = data.choices[0].message.content
      console.log(data)

      const loadingMessageIndex = bufferMessages.findIndex((message) => message.loading)

      // Replace the loading message with the AI's response
      if (loadingMessageIndex !== -1) {
        bufferMessages[loadingMessageIndex] = {
          user: 1,
          type: 'in',
          text: messageContent,
          time: new Date().toLocaleTimeString(),
          loading: false, // Set loading to false
        }
      }

      setMessages(bufferMessages)
      toggleChatUpdateFlat((flag) => !flag)
    } catch (error) {
      console.error('Error from OpenAI API:', error)
    } finally {
    }
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }
  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({behavior: 'smooth'})
    }
  }, [messages])

  function formatText(text: string): string {
    // Split the text into paragraphs
    const paragraphs: string[] = text.split(/\n\n/)

    // Initialize variables to store paragraphs and points
    const formattedParagraphs: string[] = []
    const points: string[] = []

    // Iterate through each paragraph
    paragraphs.forEach((paragraph) => {
      // Check if the paragraph starts with a number
      if (/^\d+\./.test(paragraph.trim())) {
        // If yes, add it to the points array
        points.push(paragraph.trim())
      } else {
        // If no, add it to the formatted paragraphs array
        formattedParagraphs.push(`<p>${paragraph.trim()}</p>`)
      }
    })

    // Format points into a list
    const formattedPoints: string =
      points.length > 0 ? `<ul>${points.map((point) => `<li>${point}</li>`).join('')}</ul>` : ''

    // Combine formatted paragraphs and points
    return formattedParagraphs.join('') + formattedPoints
  }

  return (
    <>
      <div
        className='card-body mb-30'
        id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_drawer_chat_messenger_body'}
        style={{height: 'calc(60vh - 100px)', overflow: 'auto'}}
      >
        <div
          className={clsx('scroll-y me-n5 pe-5', {'h-300px h-lg-auto': !isDrawer})}
          data-kt-element='messages'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies={
            isDrawer
              ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
              : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
          }
          data-kt-scroll-wrappers={
            isDrawer
              ? '#kt_drawer_chat_messenger_body'
              : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
          }
          data-kt-scroll-offset={isDrawer ? '0px' : '5px'}
        >
          {messages.map((message, index) => {
            // console.log('message', messages.length)
            const userInfo = userInfos[message.user]
            const state = message.type === 'in' ? 'info' : 'primary'
            const templateAttr = {}
            if (message.template) {
              Object.defineProperty(templateAttr, 'data-kt-element', {
                value: `template-${message.type}`,
              })
            }
            const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
              message.type === 'in' ? 'start' : 'end'
            } mb-10`
            return (
              <div
                key={`message${index}`}
                ref={index === messages.length - 1 ? lastMessageRef : null}
                className={clsx('d-flex', contentClass, 'mb-10', {'d-none': message.template})}
                {...templateAttr}
              >
                <div
                  className={clsx(
                    'd-flex flex-column align-items',
                    `align-items-${message.type === 'in' ? 'start' : 'end'}`
                  )}
                >
                  <div className='d-flex align-items-center mb-2'>
                    {message.type === 'in' ? (
                      <>
                        <div className='symbol  symbol-25px symbol-circle '>
                          <img alt='Pic' src={toAbsoluteUrl(`/media/logos/logo-mini.png`)} />
                        </div>
                        <div className='ms-3'>
                          <a
                            href='#'
                            className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                          >
                            {/* {userInfo.name} */}
                            SecureslateAI{' '}
                          </a>
                          <span className='text-muted fs-7 mb-1'>{message.time}</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='me-3'>
                          {/* <span className='text-muted fs-7 mb-1'>{message.time}</span> */}
                          <span className='text-muted fs-7 mb-1'>{message.time}</span>
                          <a
                            href='#'
                            className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                          >
                            You
                          </a>
                        </div>
                        <div className='symbol  symbol-35px symbol-circle '>
                          {/* <img alt='Pic' src={toAbsoluteUrl(`/media/${userInfo.avatar}`)} /> */}
                          {/* <img alt='Pic' src={toAbsoluteUrl(`/media/avatars/blank.png`)} /> */}
                          {avatarUrl ? (
                            <img alt='logo' loading='lazy' src={avatarUrl} />
                          ) : (
                            <div className='symbol symbol-35px'>
                              <span
                                className='symbol-label fs-6 fw-bold '
                                style={{backgroundColor: '#DAF9EF'}}
                              >
                                {currentUser
                                  ? `${currentUser?.userName
                                      ?.split(' ')[0]
                                      ?.charAt(0)
                                      .toUpperCase()}${currentUser?.userName
                                      ?.split(' ')[1]
                                      ?.charAt(0)
                                      .toUpperCase()}`
                                  : ''}
                              </span>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  <div
                    className={clsx(
                      'p-5 rounded',
                      `bg-light-${state}`,
                      'text-dark fw-bold mw-lg-400px',
                      `text-${message.type === 'in' ? 'start' : 'end'}`
                    )}
                    data-kt-element='message-text'
                    dangerouslySetInnerHTML={{
                      __html: message.loading ? 'AI is typing...' : formatText(message.text),
                    }}
                  ></div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div
        className='card-footer pt-4 position-sticky bottom-0 mt-auto w-100'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
        //
        style={{width: '96%', backgroundColor: 'white'}}
      >
        <textarea
          className='form-control form-control-flush mb-3'
          rows={1}
          data-kt-element='input'
          placeholder='Type a message'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
        ></textarea>

        <div className='d-flex flex-stack'>
          <div className='d-flex align-items-center me-2'>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              <i className='bi bi-paperclip fs-3'></i>
            </button>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              <i className='bi bi-upload fs-3'></i>
            </button>
          </div>
          <button
            className='btn btn-primary'
            type='button'
            data-kt-element='send'
            onClick={sendMessage}
          >
            Send
          </button>
        </div>
      </div>
    </>
  )
}

export {ChatInner}
