import {collection, getDocs, getFirestore} from 'firebase/firestore'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {saveAs} from 'file-saver'

import {useState} from 'react'
import Importcsv from '../Importcsv'
import {Addvendors} from '../Addvendors'

const VendorListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [isCSV, setIsCSV] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  const toggleDropdown = () => {
    console.log('clicked')
    setDropdownVisible(!isDropdownVisible)
  }
  const handleMannually = () => {
    setIsCSV(!isCSV)
  }

  const handleOpenModal2 = () => {
    setIsCSV(true)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const exportData = async () => {
    const db = getFirestore()
    const collectionRef = collection(db, 'vendorManagement')
    const querySnapshot = await getDocs(collectionRef)

    const data = querySnapshot.docs.map((doc) => doc.data())
    // console.log(data);

    if (data.length > 0) {
      const fields = Object.keys(data[0]).filter((field) => field !== 'tenantId' && field !== 'uid') // Get the field names from the first data item
      const csvContent = [fields.join(',')] // Add the field names as the first row
      data.forEach((row) => {
        const rowData = fields
          .map((field) => {
            if (field === 'dateOfLastReview' || field === 'dateOfEngagement') {
              const date = new Date(row[field]) // Convert the string to a date object
              return `"${date.toLocaleDateString() || ''}"` // Convert date to date string
            } else if (typeof row[field] === 'object' && !Array.isArray(row[field])) {
              // Handle map and boolean fields
              return JSON.stringify(row[field])
            } else {
              return `"${row[field] || ''}"` // Use the field value directly if it exists, otherwise use an empty string
            }
          })
          .join(',')
        csvContent.push(rowData)
      })
      // Add the data rows
      const csvBlob = new Blob([csvContent.join('\n')], {type: 'text/csv;charset=utf-8'})
      saveAs(csvBlob, 'data.csv')
    }
  }
  return (
    // <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>

    <>
      <Addvendors isOpen={isModalOpen} onClose={handleCloseModal} />

      <Importcsv isOpen={isCSV} onClose={handleMannually} />

      <div className='dropdown'>
        <button
          className='btn btn-sm fs-6 rotate'
          style={{background: '#1CBB8D', height: '40px', color: '#fff', width: '150px'}}
          onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
            (e.currentTarget.style.backgroundColor = '#148363')
          }
          onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
            (e.currentTarget.style.backgroundColor = '#1CBB8D')
          }
          onClick={toggleDropdown}
          id='dropdownMenu2'
          data-bs-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'
        >
          {/* <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            className='icon icon-tabler icons-tabler-outline icon-tabler-plus'
          >
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <path d='M12 5l0 14' />
            <path d='M5 12l14 0' />
          </svg> */}
          <span className='mx-2'>Add Vendor</span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-down rotate-180'
          >
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <path d='M6 9l6 6l6 -6' />
          </svg>
        </button>
        <ul className='dropdown-menu mt-2' aria-labelledby='dropdownMenu2' style={{ width: '150px'}}>
          <li>
            <button
              className='dropdown-item text-hover-primary button-with-icon'
              type='button'
              onClick={handleOpenModal}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-pencil mb-1 mx-1 '
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4' />
                <path d='M13.5 6.5l4 4' />
              </svg>{' '}
              Add manually
            </button>
          </li>

          <li>
            <button
              className='dropdown-item text-hover-primary button-with-icon'
              type='button'
              onClick={handleOpenModal2}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-file-import mb-1 mx-1'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M14 3v4a1 1 0 0 0 1 1h4' />
                <path d='M5 13v-8a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5.5m-9.5 -2h7m-3 -3l3 3l-3 3' />
              </svg>{' '}
              Import csv
            </button>
          </li>
        </ul>
      </div>
    </>
  )
}

export {VendorListToolbar}
