import {ChangeEvent, useCallback, useEffect, useState} from 'react'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {collection, doc, onSnapshot, updateDoc} from 'firebase/firestore'
import db from '../../../../services/firebase'
import Drawer from 'react-modern-drawer'
import {getStorage, ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage'

interface EditPersonnelChecklistProps {
  isOpen: boolean
  onClose: () => void
  id: string
}

const EditPersonnelChecklistModal: React.FC<EditPersonnelChecklistProps> = ({
  isOpen,
  onClose,
  id,
}) => {
  const [form, setForm] = useState<any>({
    policies: [],
    CustomTasks: [],
  })
  const [progress, setProgress] = useState<number | null>(null)
  const [message, setMessage] = useState({error: false, msg: ''})
  const [showAlert, setShowAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [selectAll, setSelectAll] = useState(false)
  const [policy, setPolicy] = useState<any[]>([])
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null)

  const getPersonnelChecklist = useCallback(() => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'personnel_checklist')

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const risksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          policies: doc.data().policies || [],
        }))
        console.log(risksData)
        if (id) {
          const filteredData = risksData.find((data) => data.id === id)
          if (filteredData) {
            console.log('Fetched data:', JSON.stringify(filteredData, null, 2))
            setForm(filteredData)
            console.log('Fetched policies:', filteredData.policies)
            // setSelectAll(filteredData.policies.length === policy.length)
          }
        }
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting personnels:', error)
    }
  }, [currentUser, id, policy.length])

  useEffect(() => {
    const unsubscribe = getPersonnelChecklist()
    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [getPersonnelChecklist])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      console.error('tenantId is missing or invalid')
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'policies')

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const policyData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setPolicy(policyData)
    })

    return unsubscribe
  }, [currentUser])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!form.checklist_name) {
      setMessage({error: true, msg: 'Please fill all the fields'})
      setShowAlert(true)
      window.scrollTo(0, 0)
      return
    }

    try {
      setIsLoading(true)

      const storage = getStorage()

      // Upload file to Firebase Storage (if selectedFile is present)
      if (selectedFile) {
        const fileName = new Date().getTime() + selectedFile.name
        const storageRef = ref(storage, `ChecklistFile/${fileName}`)
        const uploadTask = uploadBytesResumable(storageRef, selectedFile)

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log('Upload is ' + progress + '% done')
            setProgress(progress)
          },
          (error) => {
            console.error('Error uploading file:', error.message)
            setIsLoading(false)
          },
          async () => {
            // Get download URL and set it in the form state
            const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref)
            form.fileUrl = downloadUrl

            await updatePersonnelChecklist()
          }
        )
      } else {
        await updatePersonnelChecklist()
      }
    } catch (err: any) {
      setMessage({error: true, msg: err.message})
      setShowAlert(true)
      window.scrollTo(0, 0)
    } finally {
      setIsLoading(false)
    }
  }

  const updatePersonnelChecklist = async () => {
    const updatedForm = Object.entries(form).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null) {
        acc[key] = value
      }
      return acc
    }, {})
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)
    const checklistRef = doc(tenantRef, 'personnel_checklist', id)

    await updateDoc(checklistRef, {
      ...updatedForm,
      updated_at: new Date(),
    })

    setMessage({error: false, msg: 'Updated Successfully'})
    setShowAlert(true)
    window.scrollTo(0, 0)
    setIsLoading(false)
    setTimeout(() => {
      setShowAlert(false)
      onClose()
    }, 3000)
  }

  const getFileName = (fileUrl: string) => {
    if (fileUrl) {
      const decodedUrl = decodeURIComponent(fileUrl)
      const fileNameWithParams = decodedUrl.split('?')[0]
      const fileNameArray = fileNameWithParams.split('/')
      let fileName = fileNameArray[fileNameArray.length - 1]
      fileName = fileName.replace(/\d{13}/, '')
      return fileName
    }
    return ''
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setSelectedFile(file)
      setSelectedFileName(file.name)
    }
  }

  const handleRemoveFile = (e: React.MouseEvent) => {
    e.preventDefault()
    setSelectedFile(null)
    setSelectedFileName(null)
    setForm({...form, fileUrl: '', NDA: false})
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({...form, [e.target.name]: e.target.value})
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({...form, [e.target.name]: e.target.checked})
  }

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    setSelectAll(checked)
    setForm({
      ...form,
      policies: checked ? policy.map((p) => p.policy) : [],
    })
  }

  const handlePolicyChange = (e: React.ChangeEvent<HTMLInputElement>, policyName: string) => {
    const checked = e.target.checked
    setForm((prevForm) => {
      const updatedPolicies = checked
        ? [...(prevForm.policies || []), policyName]
        : (prevForm.policies || []).filter((p: string) => p !== policyName)
      console.log('Updated policies:', updatedPolicies) // Add this log
      return {
        ...prevForm,
        policies: updatedPolicies,
      }
    })
  }
  return (
    <Drawer open={isOpen} onClose={onClose} direction='right' className='' size='45%'>
      {/* Drawer header */}
      <div
        className='modal-dialog modal-dialog-centered pt-3 px-6 border-bottom border-gray-300'
        style={{position: 'sticky', top: 0, backgroundColor: 'white', paddingLeft: '12px'}}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h4 className='modal-title mt-2 mb-4'>Edit Checklist</h4>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary mx-4'
              onClick={onClose}
              aria-label='Close'
            >
              <i className='ki-duotone ki-cross fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            </div>
          </div>
        </div>
      </div>

      {/* Drawer body */}
      <div
        className='scroll-y overflow-auto p-6 mb-4'
        tabIndex={-1}
        style={{height: 'calc(100vh - 10px)'}}
      >
        <form onSubmit={handleSubmit}>
          {/* Alert message */}
          {showAlert && (
            <div style={{marginTop: 0, marginLeft: 0, marginRight: 0}}>
              {message?.msg && (
                <div
                  className={
                    message?.error
                      ? 'alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row'
                      : 'alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row'
                  }
                >
                  {message?.error ? (
                    <i className='ki-duotone ki-notification-bing fs-2hx text-danger me-4 mb-5 mb-sm-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                  ) : (
                    <i className='ki-duotone ki-notification-bing fs-2hx text-success me-4 mb-5 mb-sm-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                  )}

                  <div
                    className={
                      message?.error
                        ? 'd-flex flex-column text-danger text-primary text-bold pe-0 pe-sm-10'
                        : 'd-flex flex-column text-success text-primary text-bold pe-0 pe-sm-10'
                    }
                  >
                    {message?.error ? (
                      <h5 className='mb-1'>Error!!</h5>
                    ) : (
                      <h5 className='mb-1'>Sucess!!</h5>
                    )}
                    <span>{message?.msg}</span>
                  </div>
                  <button
                    type='button'
                    className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
                    onClick={() => setShowAlert(false)}
                  >
                    <span className='svg-icon svg-icon-1 svg-icon-success'>
                      <span className='svg-icon svg-icon-2x svg-icon-primary'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                          className='mh-50px'
                        >
                          <rect
                            opacity='0.5'
                            x='6'
                            y='17.3137'
                            width='16'
                            height='2'
                            rx='1'
                            transform='rotate(-45 6 17.3137)'
                            fill='black'
                          ></rect>
                          <rect
                            x='7.41422'
                            y='6'
                            width='16'
                            height='2'
                            rx='1'
                            transform='rotate(45 7.41422 6)'
                            fill='black'
                          ></rect>
                        </svg>
                      </span>
                    </span>
                  </button>
                </div>
              )}
            </div>
          )}

          {/* Checklist Name */}
          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>Checklist Name</label>
            <input
              type='text'
              className='form-control  mb-3 mb-lg-0'
              name='checklist_name'
              value={form.checklist_name || ''}
              onChange={handleChange}
            />
          </div>

          {/* Onboarding Section */}
          <div className='fv-row mb-3'>
            <label className='fw-bold fs-6 mb-2'>Onboarding</label>

            {/* Policies */}
            <div className='border rounded'>
              <div className='p-4 fw-bold' style={{borderBottom: '1px solid #ddd'}}>
                <img src='/media/buttons/badge-1.svg' alt='' className='m-1' /> Policies
              </div>
              <div
                className='form-check form-check-custom form-check-solid p-4'
                style={{borderBottom: '1px dotted #ddd'}}
              >
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='selectAll'
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
                <label className='form-check-label fw-bold' htmlFor='selectAll'>
                  Select All
                </label>
              </div>
              <div style={{height: '50vh', overflowY: 'auto'}}>
                {policy.map((p) => (
                  <div
                    className='form-check form-check-custom form-check-solid p-4 fw-bold'
                    style={{borderBottom: '1px dotted #ddd'}}
                    key={p.id}
                  >
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id={`policy-${p.id}`}
                      checked={form.policies?.includes(p.policy)}
                      onChange={(e) => handlePolicyChange(e, p.policy)}
                    />
                    <label className='form-check-label' htmlFor={`policy-${p.id}`}>
                      <img src='/media/buttons/text_snippet.png' alt='' className='m-1' />{' '}
                      {p.policy}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            {/* Security Training */}
            <div className='border rounded mt-5'>
              <div className='p-4 fw-bold rounded d-flex align-items-center justify-content-between'>
                <div>
                  <img src='/media/buttons/badge-2.svg' alt='' className='m-1' /> Security Training
                </div>
                <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='security_training'
                    checked={form.security_training || false}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>
            </div>

            {/* OWASP Training */}
            <div className='border rounded mt-5'>
              <div className='p-4 fw-bold rounded d-flex align-items-center justify-content-between'>
                <div>
                  <img src='/media/buttons/badge-3.svg' alt='' className='m-1' /> OWASP Training
                </div>
                <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='owasp_training'
                    checked={form.owasp_training || false}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Offboarding Section */}
          <div className='fv-row mb-5 mt-10'>
            <label className='fw-bold fs-6 mb-2'>Offboarding</label>
            <div className='border rounded'>
              <div
                className='p-4 fw-bold d-flex align-items-center justify-content-between'
                style={{borderBottom: '1px solid #ddd'}}
              >
                <div>
                  <img src='/media/buttons/badge-1.svg' alt='' className='m-1' /> NDA
                </div>
                <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='NDA'
                    checked={form.NDA || false}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>
              {form.NDA && (
                <div className='p-4 fw-bold d-flex align-items-center justify-content-between'>
                  <div>
                    {!selectedFile && !form.fileUrl && (
                      <>
                        <label htmlFor='fileInput'>
                          <img
                            src='/media/buttons/upload.svg'
                            alt=''
                            className='mb-5 mt-2'
                            style={{cursor: 'pointer'}}
                          />
                        </label>
                        <input
                          type='file'
                          id='fileInput'
                          style={{display: 'none'}}
                          onChange={handleFileChange}
                          accept='application/pdf'
                        />
                        <br />
                        <span className='form-text text-muted'>
                          Max number of files is 1. Supported file type: pdf
                        </span>
                      </>
                    )}
                    {selectedFile && (
                      <p className='bg-gray-100 p-2 w-100'>
                        <span className='mx-5'>Selected File: {selectedFile.name}</span>
                        <button onClick={handleRemoveFile} className='btn p-0'>
                          <i className='bi bi-x fs-1'></i>
                        </button>
                      </p>
                    )}
                    {form.fileUrl && !selectedFile && (
                      <p className='bg-gray-100 p-2 w-100'>
                        <span className='mx-5'>Selected File: {getFileName(form.fileUrl)}</span>
                        <button onClick={handleRemoveFile} className='btn p-0'>
                          <i className='bi bi-x fs-1'></i>
                        </button>
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Custom Tasks Section */}
          <div className='fv-row mb-20'>
            <div className='border rounded'>
              <div className='p-4 fw-bold ' style={{borderBottom: '1px solid #ddd'}}>
                <img src='/media/buttons/badge-2.svg' alt='' className='m-1' /> Custom Tasks
              </div>
              {(form.CustomTasks || []).map((task: string, index: number) => (
                <div
                  key={index}
                  className='form-check form-check-custom form-check-solid p-4'
                  style={{borderBottom: '1px dotted #ddd'}}
                >
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id={`task-${index}`}
                    checked={true}
                    onChange={() => {
                      setForm((prevForm) => ({
                        ...prevForm,
                        CustomTasks: (prevForm.CustomTasks || []).filter(
                          (_: any, i: number) => i !== index
                        ),
                      }))
                    }}
                  />
                  <label className='form-check-label fw-bold' htmlFor={`task-${index}`}>
                    {task}
                  </label>
                </div>
              ))}
              <div className='form-check form-check-custom form-check-solid p-4 fw-bold mb-10'>
                <img
                  src='/media/buttons/AddTask.svg'
                  alt=''
                  className='m-1'
                  style={{cursor: 'pointer'}}
                  // onClick={() => {
                  //   const newTask = prompt('Enter new task:')
                  //   if (newTask) {
                  //     setForm({
                  //       ...form,
                  //       CustomTasks: [...(form.CustomTasks || []), newTask],
                  //     })
                  //   }
                  // }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* Drawer footer */}
      <div
        className='offcanvas-footer d-flex justify-content-end border-top py-4 mx-6 '
        style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
      >
        <div className='d-flex justify-content-end align-items-center'>
          {/* <div className='m-1' style={{cursor: 'pointer'}} aria-label='Cancel' onClick={onClose}>
            <img src='/media/buttons/Cancel.svg' alt='Cancel' />
          </div> */}
          <button
            className='btn btn-sm btn-light fw-semibold me-4 fs-6'
            onClick={onClose}
            aria-label='Close'
            style={{height: '40px'}}
          >
            Cancel
          </button>
          <div style={{cursor: 'pointer'}} onClick={handleSubmit}>
            {isLoading ? (
              <button
                className='btn btn-sm fw-semibold px-6 fs-6'
                style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#148363')
                }
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                }
                disabled
              >
                Updating...<span className='spinner-border spinner-border-sm align-middle'></span>
              </button>
            ) : (
              <button
                className='btn btn-sm fw-semibold px-6 fs-6'
                onClick={handleSubmit}
                style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#148363')
                }
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                }
              >
                Update
              </button>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export {EditPersonnelChecklistModal}
