import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../onboarding/cores/card1'
import {ReportPageTitle} from './PageTitle/ReportPageTitle'
import {useAuth} from '../../../modules/auth'
import {collection, doc, onSnapshot} from 'firebase/firestore'
import db from '../../../../services/firebase'
import {useParams} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'

interface Control {
  id: string
  control_category: string
  control_description: string
  control_document: string
  control_evidence_status: string
  control_framework_mappings: string
  control_id: string
  control_owner: string
  control_test: string
  control_title: string
  control_uid: string
  document_id: string[]
  test_id: string[]
}

const getCodeFromFrameworkMapping = (mapping: string) => {
  // Extract the code portion, e.g., "C.4.1" from "ISO 27001:2022 ‧ C.4.1"
  const parts = mapping.split('‧')
  return parts.length > 1 ? parts[1].trim() : ''
}

const getMainCode = (mapping: string) => {
  const parts = mapping.split('‧')
  if (parts.length > 1) {
    return parts[1].trim().split('.').slice(0, 2).join('.') // Return full code, e.g., "A.4"
  }
  return ''
}

// Function to get the letter prefix
const getLetterPrefix = (code: string) => {
  return code.match(/[A-Z]/)?.[0] || ''
}

// Function to get the numeric part
const getNumericPart = (code: string) => {
  return parseInt(code.replace(/[A-Z]\./i, '')) || 0
}

const ViewReports = () => {
  const [openAccordion, setOpenAccordion] = useState<string | null>(null)
  const {currentUser} = useAuth()
  const [controls, setControls] = useState<Record<string, Control[]>>({})
  const {name} = useParams()
  const [open, setOpen] = useState(false)

  console.log(name)

  useEffect(() => {
    getControl()
  }, [])

  const toggleAccordion = (id: string) => {
    setOpenAccordion(openAccordion === id ? null : id)
    setOpen(!open)
  }

  const getControl = async () => {
    try {
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)
      const controlsCollectionRef = collection(tenantRef, 'controls')

      const unsubscribe = onSnapshot(controlsCollectionRef, (snapshot) => {
        const controlsData: Control[] = snapshot.docs.map((doc) => ({
          id: doc.id,
          control_category: doc.data().control_category || '',
          control_description: doc.data().control_description || '',
          control_document: doc.data().control_document || '',
          control_evidence_status: doc.data().control_evidence_status || '',
          control_framework_mappings: doc.data().control_framework_mappings || '',
          control_id: doc.data().control_id || '',
          control_owner: doc.data().control_owner || '',
          control_test: doc.data().control_test || '',
          control_title: doc.data().control_title || '',
          control_uid: doc.data().control_uid || '',
          document_id: doc.data().document_id || [],
          test_id: doc.data().test_id || [],
        }))
        // setControls(controlsData)

        // Filter to get only ISO framework controls
        const filteredControls = controlsData.filter(
          (control) =>
            control.control_framework_mappings.startsWith(`${name}`) &&
            !control.control_framework_mappings.startsWith('ISO 27001:2013')
        )

        // Group controls by main code
        const groupedControls = filteredControls.reduce((acc, control) => {
          const mainCode = getMainCode(control.control_framework_mappings)
          if (!acc[mainCode]) {
            acc[mainCode] = []
          }
          acc[mainCode].push(control)
          return acc
        }, {} as Record<string, Control[]>)

        // Sort each group by the full code portion
        Object.keys(groupedControls).forEach((mainCode) => {
          groupedControls[mainCode].sort((a, b) => {
            const codeA = getCodeFromFrameworkMapping(a.control_framework_mappings)
            const codeB = getCodeFromFrameworkMapping(b.control_framework_mappings)
            return codeA.localeCompare(codeB)
          })
        })

        const sortedGroupedControls = Object.entries(groupedControls)
          .sort(([keyA], [keyB]) => {
            const prefixA = getLetterPrefix(keyA)
            const prefixB = getLetterPrefix(keyB)
            if (prefixA !== prefixB) {
              return prefixA.localeCompare(prefixB)
            }
            return getNumericPart(keyA) - getNumericPart(keyB)
          })
          .reduce((acc, [key, value]) => {
            acc[key] = value.sort((a, b) => {
              const codeA = getCodeFromFrameworkMapping(a.control_framework_mappings)
              const codeB = getCodeFromFrameworkMapping(b.control_framework_mappings)
              return getNumericPart(codeA) - getNumericPart(codeB)
            })
            return acc
          }, {} as Record<string, Control[]>)

        setControls(sortedGroupedControls)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  function processControlValues(inputString: string, inputString2: string): string {
    // Concatenate the input strings with a '+' separator
    const concatenatedString = inputString + '+' + inputString2
    console.log(concatenatedString)

    // Split the concatenated string by '+' to separate the two parts
    const parts = concatenatedString.split('+')

    let numerator = 0
    let denominator = 0

    // Loop through each part
    parts.forEach((part) => {
      // Split the part by '/' to separate the numerator and the denominator
      const splitPart = part.split('/')
      // Convert the numerator and the denominator to integers
      const num = parseInt(splitPart[0], 10)
      const den = parseInt(splitPart[1], 10)

      // Check if the conversion was successful
      if (isNaN(num) || isNaN(den)) {
        console.error('Invalid input format')
        return
      }

      // Add the numerator and denominator to the total
      numerator += num
      denominator += den
    })

    if (denominator === 0) {
      return '0/0'
    }

    // Return the result as a string in the format "numerator/denominator"
    const processedValue = `${numerator}/${denominator}`
    console.log(processedValue)
    return processedValue
  }

  const getBadgeClass = (testPassing) => {
    const [numerator, denominator] = testPassing.split('/').map(Number)

    if (numerator === 0 && denominator === 0) {
      // Special case for 0/0
      return 'bg-primary'
    } else if (numerator === 0) {
      // If the numerator is 0 but denominator is not
      return 'bg-danger'
    } else if (numerator === denominator) {
      // Numerator equals denominator and not 0/0
      return 'bg-primary'
    } else {
      // Some tests failed
      return 'bg-danger'
    }
  }

  return (
    <>
      {name === 'SOC 2' ? (
        <ReportPageTitle
          icon='SOC.svg'
          title='SOC 2'
          desc='Your progress towards SOC 2 compliance based on your controls and tests.'
        ></ReportPageTitle>
      ) : (
        <ReportPageTitle
          icon='ISO.svg'
          title='ISO 27001: 2022'
          desc='Your progress towards ISO 27001:2022 compliance based on your controls and tests.'
        ></ReportPageTitle>
      )}

      <div className=''>
        <div className='card-header border-0 pt-6 h-100 mb-8'>
          <div className='d-flex justify-content-between align-items-center w-100'>
            {/* Search on the left side */}
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-2 position-absolute ms-3' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control ps-12'
                placeholder='Search..'
                // value={searchTerm}
                // onChange={handleInputChange}
              />
            </div>

            {/* Export button on the right side */}
            <div>
              <button
                type='button'
                // onClick={exportData}
                className='btn btn-sm fs-6'
                style={{height: '40px', background: '#1CBB8D', color: '#fff'}}
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#148363')
                }
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                }
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='18'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='icon icon-tabler icons-tabler-outline icon-tabler-download mb-1'
                  style={{marginRight: '4px'}}
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
                  <path d='M7 11l5 5l5 -5' />
                  <path d='M12 4l0 12' />
                </svg>
                Export
              </button>
            </div>
          </div>
        </div>
        {Object.keys(controls).map((mainCode) => (
          <div key={mainCode} className='mt-4'>
            <span className='fs-5 fw-bold '>
              {mainCode.split(',')[0].trim()} {controls[mainCode][0].control_category}
            </span>
            {controls[mainCode].map((control) => (
              <>
                <div key={control.id} className={`card p-6 mt-4 mb-4`}>
                  <div
                    className='d-flex'
                    onClick={() => toggleAccordion(control.id)}
                    style={{cursor: 'pointer'}}
                  >
                    <div className='row w-100'>
                      <div className='col'>
                        <div className='d-flex align-items-center'>
                          <span>
                            {openAccordion === control.id ? (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#5A5A5A'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-down'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M6 9l6 6l6 -6' />
                              </svg>
                            ) : (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#5A5A5A'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-right'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M9 6l6 6l-6 6' />
                              </svg>
                            )}
                          </span>
                          <span className='fs-6 fw-bold mx-4'>
                            {getCodeFromFrameworkMapping(
                              control.control_framework_mappings.split(',')[0].trim()
                            )}{' '}
                            {control.control_title}
                          </span>
                        </div>
                      </div>
                      <div className='col-auto d-flex align-items-center ml-auto'>
                        <div className='d-flex justify-content-end'>
                          {control.control_evidence_status !== 'OK' ? (
                            <div className='fw-semibold'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#EE3043'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle mb-1 mx-1'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M12 9v4' />
                                <path d='M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z' />
                                <path d='M12 16h.01' />
                              </svg>{' '}
                              {/* {processControlValues(control.control_document, control.control_test)}{' '} */}
                              Controls Unhealthy
                            </div>
                          ) : (
                            <>
                              <div className='fw-bold'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='16'
                                  height='16'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='#1CBB8D'
                                  strokeWidth='2'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-circle-check mb-1 mx-1'
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
                                  <path d='M9 12l2 2l4 -4' />
                                </svg>{' '}
                                OK
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {openAccordion === control.id && (
                    <div className='table-responsive mt-3'>
                      <table className='table table-hover'>
                        <thead>
                          <tr className='py-4 border-bottom fw-bold' style={{color: '#5A5A5A'}}>
                            <th>CONTROL</th>
                            <th>STATUS</th>
                            <th className='d-flex justify-content-end mx-20'>ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className='border-bottom my-2 fw-bold' style={{color: '#171717'}}>
                            <td className='pt-6 pb-6'>{control.control_title}</td>
                            <td className='pt-6 pb-6'>
                              <div className='d-flex align-items-center'>
                                <span className='badge border p-2 rounded-pill d-flex align-items-center'>
                                  <span
                                    className={`badge border rounded-circle mx-2  ${getBadgeClass(
                                      processControlValues(
                                        control.control_document,
                                        control.control_test
                                      )
                                    )}`}
                                    style={{
                                      width: '12px',
                                      height: '12px',
                                      padding: '3px',
                                    }}
                                  >
                                    {' '}
                                  </span>
                                  <span>{control.control_evidence_status}</span>
                                </span>
                                <span className='mx-2'>
                                  {processControlValues(
                                    control.control_document,
                                    control.control_test
                                  )}{' '}
                                  Test passing
                                </span>
                              </div>
                            </td>
                            <td className='pt-6 pb-6'>
                              <div className='d-flex justify-content-end'>
                                <a
                                  href={`/controls/details/${control.id}`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  className='d-flex align-items-center'
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='16'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='#5A5A5A'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='icon icon-tabler icons-tabler-outline icon-tabler-external-link mb-1 mx-1'
                                  >
                                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                    <path d='M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6' />
                                    <path d='M11 13l9 -9' />
                                    <path d='M15 4h5v5' />
                                  </svg>
                                  <span style={{color: '#5A5A5A'}}>View in full Screen</span>
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </>
            ))}
          </div>
        ))}
      </div>
    </>
  )
}

export default ViewReports
