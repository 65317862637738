import db from '../../../../services/firebase'
import {collection, getDocs, getDoc, addDoc, updateDoc, deleteDoc, doc} from 'firebase/firestore'

const personnelCollectionRef = collection(db, 'PersonnelManagement')

class PersonnelDataService {
  addPersonnel = (newPersonnel) => {
    return addDoc(personnelCollectionRef, newPersonnel)
  }

  updatePersonnel = (id, updatePersonnel) => {
    const PersonnelDoc = doc(db, 'PersonnelManagement', id)
    return updateDoc(PersonnelDoc, updatePersonnel)
  }

  deletePersonnel = (id) => {
    const PersonnelDoc = doc(db, 'PersonnelManagement', id)
    return deleteDoc(PersonnelDoc)
  }

  getAllPersonnels = () => {
    return getDocs(personnelCollectionRef)
  }

  getPersonnel = (id) => {
    const PersonnelDoc = doc(db, 'PersonnelManagement', id)
    return getDoc(PersonnelDoc)
  }
}

export default new PersonnelDataService()
