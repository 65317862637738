import {collection, doc, getDocs, getFirestore, onSnapshot} from 'firebase/firestore'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useListView} from '../../../vendor/core/ListViewProvider'
import {saveAs} from 'file-saver'
import {useEffect, useRef, useState} from 'react'
import {Overlay, Popover} from 'react-bootstrap'
import AddManual from '../AddManual'
import AddCSV from '../AddCSV'
import { useAuth } from '../../../../modules/auth'
import db from '../../../../../services/firebase'

// import ControlFilter from '../dropdown/ControlFilter'

const ControlListToolbar = () => {
  // const {setItemIdForUpdate} = useListView()
  const [controls, setControls] = useState([] as any[])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const {currentUser} = useAuth()
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [isCSV, setIsCSV] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])

  useEffect(() => {
    getActiveFramworks()
  }, [])

  useEffect(() => {
    getControls()
  }, [activeFrameworks])

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
        console.log(frameworks)
      })

      return unsubscribe 
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  const getControls = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }
      const tenantRef = doc(db, 'tenants', tenantId)
      const testsCollectionRef = collection(tenantRef, 'controls')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          framework: doc.data().control_framework_mappings,
          status: doc.data().control_evidence_status,
          author: doc.data().author,
        }))

        const filteredControls = controlsData.filter((control) => {
          if (control.author === 'Custom Control') return true

          if (!control.framework) return false

          return activeFrameworks.some(
            (framework) =>
              control.framework.startsWith(framework) &&
              !control.framework.startsWith('ISO 27001:2013')
          )
        })
        setControls(filteredControls)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const handleMannually = () => {
    setIsCSV(!isCSV)
  }

  const handleOpenModal2 = () => {
    setIsCSV(true)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const stripHtmlTags = (html) => {
    const tmp = document.createElement('DIV')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
  }

  const exportData = async () => {
    // const db = getFirestore()
    // const tenantId = currentUser?.tenantId
    // const tenantRef = doc(db, 'tenants', tenantId!)
    // const PolicyRef = collection(tenantRef, 'controls')
    // const querySnapshot = await getDocs(PolicyRef)
    
    // const data = querySnapshot.docs.map((doc) => doc.data())

    const filteredControls = controls.filter((control) => {
      if (control.author === 'Custom Control') return true

      if (!control.control_framework_mappings) return false

      return activeFrameworks.some(
        (framework) =>
          control.control_framework_mappings.startsWith(framework) &&
          !control.control_framework_mappings.startsWith('ISO 27001:2013')
      )
    })
    
    if (filteredControls.length > 0) {
      const fields = Object.keys(filteredControls[0])
      const excludedFields = ['tenantId', 'uid', 'control_id', 'test_id', 'control_uid', 'document_id','riskScenario','createdAt','tenant_id']
      const csvFields = fields.filter((field) => !excludedFields.includes(field))
      
      csvFields.push('riskSource')
      
      const csvContent = [csvFields.join(',')]
      
      filteredControls.forEach((row) => {
        const rowData = csvFields
          .map((field) => {
            if (field === 'control_description') {
              return stripHtmlTags(row[field])
            }
            if (field === 'createdAt') {
              const date = new Date(row[field])
              return `"${date.toLocaleDateString() || ''}"`
            } else if (field === 'riskSource') {
              // Handle the riskSource field
              const riskSources = row.risk_scenario
                ? row.risk_scenario.map(scenario => scenario.riskSource).join(', ')
                : ''
              return `"${riskSources}"`
            } else if (field === 'risk_scenario') {
              return '""'
            } else {
              return `"${row[field] || ''}"`
            }
          })
          .join(',')
        csvContent.push(rowData)
      })
      
      const csvBlob = new Blob([csvContent.join('\n')], {type: 'text/csv;charset=utf-8'})
      saveAs(csvBlob, 'controls.csv')
    }
  }
  const toggleDropdown = () => {
    console.log('clicked')
    setDropdownVisible(!isDropdownVisible)
  }

  console.log(isDropdownOpen)
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <VendorListFilter /> */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button> */}
      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3' onClick={exportData} >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-download mb-1'
          style={{marginRight: '4px'}}
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
          <path d='M7 11l5 5l5 -5' />
          <path d='M12 4l0 12' />
        </svg>
        Export
      </button> */}
      <button
        type='button'
        className=' btn btn-sm btn-light me-4 fs-6'
        onClick={exportData}
        style={{height: '40px', background: '#fff', color: '#000', border: '2px solid #C5C5C5'}}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='#5A5A5A'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-download mb-1'
          style={{marginRight: '4px'}}
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
          <path d='M7 11l5 5l5 -5' />
          <path d='M12 4l0 12' />
        </svg>
        Export
      </button>
      {/* end::Export */}

      {/* begin::Add user */}

      {/* <a href='/controls/addControls'>
        {' '}
        <button
          type='button'
          className='btn btn-primary'
          style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
        >
          <KTIcon iconName='plus' className='fs-2' />
          Add Control


        </button>
      </a> */}

      {/* <div>
        <div ref={ref}>
          <button
            type='button'
            className='btn me-3 fs-5'
            style={{backgroundColor: '#f1fdf9', color: '#45dbaf'}}
            onClick={handleClick}
          >
  
        
            <i className='ki-solid ki-filter text-primary fs-4 p-0 '>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            Filter <span className="badge border border-primary rounded-circle bg-primary text-white mx-1">3 </span>
          </button>
         
          
          
          <Overlay
            show={show}
            target={target}
            placement='bottom'
            container={ref}
            containerPadding={10}
          >
            <Popover id='popover-contained'>
              <Popover.Header as='h3' className='fw-bold fs-5'>
                Filter
              </Popover.Header>
              <Popover.Body className=''>
                <form onSubmit={handleSubmit}>
                  <div className='mb-3'>
                    <label htmlFor='framework'>Framework</label>
                    <select id='framework' className='form-select form-control' aria-label='Select Framework'>
                      <option value='' disabled selected>
                        Select Framework
                      </option>
                      <option value='ISO 27001'>ISO 27001</option>
                      <option value='SOC 2'>SOC 2</option>
                    </select>
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='status'>Status</label>
                    <select id='status' className='form-select' aria-label='Select Status'>
                      <option value='' disabled selected>
                        Select Status
                      </option>
                      <option value='Unhealthy'>Unhealthy</option>
                      <option value='OK'>OK</option>
                    </select>
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='owner'>Owner</label>
                    <select id='owner' className='form-select' aria-label='Select Owner'>
                      <option value='' disabled selected>
                        Select Owner
                      </option>
                      <option value='react'>React</option>
                      <option value='angular'>Angular</option>
                      <option value='vue'>Vue.js</option>
                    </select>
                  </div>
                  <button
                    type='button'
                    className='btn mx-5 btn-sm '
                    style={{backgroundColor: '#f1fdf9', color: '#45dbaf'}}
                  >
                    Reset
                  </button>
                  <button type='submit' className='btn btn-primary btn-sm ml-5'>
                    Apply
                  </button>
                </form>
              </Popover.Body>
            </Popover>
          </Overlay>
        </div>
      </div> */}

      <AddManual isOpen={isModalOpen} onClose={handleCloseModal} />
      <AddCSV isOpen={isCSV} onClose={handleMannually} />

      <div className='dropdown'>
        <button
          className='btn btn-sm fs-6 rotate'
          style={{background: '#1CBB8D', height: '40px', color: '#fff', width: '160px'}}
          onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
            (e.currentTarget.style.backgroundColor = '#148363')
          }
          onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
            (e.currentTarget.style.backgroundColor = '#1CBB8D')
          }
          onClick={toggleDropdown}
          id='dropdownMenu2'
          data-bs-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'
        >
          {/* <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            className='icon icon-tabler icons-tabler-outline icon-tabler-plus'
          >
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <path d='M12 5l0 14' />
            <path d='M5 12l14 0' />
          </svg> */}
          <span className='mx-2'>Add Controls</span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-down rotate-180'
          >
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <path d='M6 9l6 6l6 -6' />
          </svg>
        </button>
        <ul className='dropdown-menu mt-2' aria-labelledby='dropdownMenu2' style={{width: '160px'}}>
          <li>
            <button
              className='dropdown-item text-hover-primary button-with-icon'
              type='button'
              onClick={handleOpenModal}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-pencil mb-1 mx-1 '
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4' />
                <path d='M13.5 6.5l4 4' />
              </svg>{' '}
              Add manually
            </button>
          </li>

          <li>
            <button
              className='dropdown-item text-hover-primary button-with-icon'
              type='button'
              onClick={handleOpenModal2}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-file-import mb-1 mx-1'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M14 3v4a1 1 0 0 0 1 1h4' />
                <path d='M5 13v-8a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5.5m-9.5 -2h7m-3 -3l3 3l-3 3' />
              </svg>{' '}
              Import csv
            </button>
          </li>
        </ul>
      </div>

      {/* end::Add user */}
    </div>
  )
}

export {ControlListToolbar}
