import {collection, doc, getDocs, getFirestore} from 'firebase/firestore'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useListView} from '../../../vendor/core/ListViewProvider'
import {AddPersonnelModal} from '../AddPersonnelModal'
import {saveAs} from 'file-saver'
import {useAuth} from '../../../../modules/auth'
const PersonnelListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const {currentUser} = useAuth()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }
  const exportData = async () => {
    const db = getFirestore()
    const tenantId = currentUser?.tenantId
    const tenantRef = doc(db, 'tenants', tenantId!)
    const PolicyRef = collection(tenantRef, 'employees')
    const querySnapshot = await getDocs(PolicyRef)

    const data = querySnapshot.docs.map((doc) => doc.data())

    // .filter((item) => item.tenantId === currentUser?.tenantId);

    if (data.length > 0) {
      const fields = Object.keys(data[0]).filter(
        (field) => field !== 'tenantId' && field !== 'uid' && field !== 'id'
      )
      const csvContent = [fields.join(',')]

      data.forEach((row) => {
        const rowData = fields
          .map((field) => {
            return `"${row[field] || ''}"`
          })
          .join(',')
        csvContent.push(rowData)
      })

      const csvBlob = new Blob([csvContent.join('\n')], {type: 'text/csv;charset=utf-8'})
      saveAs(csvBlob, 'personnel.csv')
    }
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <VendorListFilter /> */}

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-sm me-3 btn-light-primary' onClick={exportData} style={{backgroundColor: '#f1fdf9', color: '#45dbaf' ,height:'40px'}}>
        <KTIcon iconName='exit-up' className='fs-2 text-primary' />
        Export CSV
      </button> */}
   
      <button
        type='button'
        className=' btn btn-sm btn-light me-4 fs-6'
        onClick={exportData}
        style={{height: '40px', background: '#fff', color: '#000', border: '2px solid #C5C5C5'}}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='#5A5A5A'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-download mb-1'
          style={{marginRight: '4px'}}
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
          <path d='M7 11l5 5l5 -5' />
          <path d='M12 4l0 12' />
        </svg>
        Export
      </button>
      {/* end::Export */}

      {/* begin::Add user */}
      <AddPersonnelModal />
      {/* end::Add user */}
    </div>
  )
}

export {PersonnelListToolbar}
