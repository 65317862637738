/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import db from '../../../services/firebase'
import controlServices from '../controls/services/control.services'
import {format} from 'date-fns'
import {Link, useParams, useLocation} from 'react-router-dom'

type Props = {}

const ControlInformationManagement: React.FC<Props> = () => {
  const [control, setControl] = useState<any | null>(null)
  const location = useLocation()
  const [activeTab, setActiveTab] = useState('details')
  const {id} = useParams()
  console.log('IDDDDD', id)
  const [dataCount, setDataCount] = useState(0)
  useEffect(() => {
    // Fetch control data when the component mounts
    if (id) {
      getControl(id)
    }
    if (location.pathname.includes('requirements')) {
      setActiveTab('requirements')
    } else if (location.pathname.includes('testing')) {
      setActiveTab('testing')
    }
  }, [id])

  const getControl = async (controlId: string) => {
    try {
      const controlRef = db.collection('ControlManagement').doc(controlId) // Replace 'your-collection-name' with your actual Firestore collection name
      const doc = await controlRef.get()

      if (doc.exists) {
        setControl(doc.data())
      } else {
        // Handle the case where the document doesn't exist
        console.log(`Control with ID ${controlId} not found.`)
      }
    } catch (error) {
      console.error('Error fetching control data:', error)
    }
  }

  return (
    <div
      className='card card-flush pb-0 bgi-position-y-center bgi-no-repeat mb-10'
      style={{
        backgroundSize: 'auto calc(100% + 10rem)',
        backgroundPositionX: '100%',
        backgroundImage: "url('assets/media/illustrations/sketchy-1/4.png')",
      }}
    >
      {/*begin::Card header*/}

      {control && (
        <div>
          <div className='card-header pt-10'>
            <div className=''>
              <div className='d-flex justify-content-start flex-column'>
                <span className='card-label fw-bold fs-3'>{control.name}</span>
                <span className='text-muted fw-semibold fs-5 mb-3'>{control.description}</span>
              </div>
            </div>
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className=' pt-2 pb-0' style={{paddingLeft: '2%'}}>
            {/*begin::Navs*/}
            <div className='d-flex overflow-auto h-55px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
                {/*begin::Nav item*/}
                <li className='nav-item'>
                  <Link
                    to={`/controls/${id}/details`}
                    className={`nav-link me-6 ${
                      activeTab === 'details' ? 'text-active-primary active' : ''
                    }`}
                  >
                    Details
                  </Link>
                </li>
                {/*end::Nav item*/}
                {/*begin::Nav item*/}
                <li className='nav-item'>
                  <Link
                    to={`/controls/${id}/requirements`}
                    className={`nav-link me-6 ${
                      activeTab === 'requirements' ? 'text-active-primary active' : ''
                    }`}
                  >
                    Requirements
                  </Link>
                </li>
                {/*end::Nav item*/}
                {/*begin::Nav item*/}
                <li className='nav-item'>
                  <Link
                    to={`/controls/${id}/testing`}
                    className={`nav-link me-6 ${
                      activeTab === 'testing' ? 'text-active-primary active' : ''
                    }`}
                  >
                    Testing
                  </Link>
                </li>
                {/*end::Nav item*/}
              </ul>
            </div>
            {/*begin::Navs*/}
          </div>
          <div className='card card-flush'>
            {/*begin::Card header*/}
            <div className='card-header pt-8'>
              <div className='d-flex flex-stack mb-5'>
                <h3> Control Details</h3>
              </div>
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className='card-body pt-0'>
              {/*begin::Table header*/}

              <div className='row g-5 mb-9'>
                {/*end::Col*/}
                <div className='col-sm-6'>
                  {/*end::Label*/}
                  <div className='fw-semibold fs-4 text-gray-600 mb-1'>Control ID</div>
                  {/*end::Label*/}
                  {/*end::Col*/}
                  <div className='fw-bold fs-4 text-gray-800'>{control?.name}</div>
                  {/*end::Col*/}
                </div>
                {/*end::Col*/}
                {/*end::Col*/}
                <div className='col-sm-6'>
                  {/*end::Label*/}
                  <div className='fw-semibold fs-4 text-gray-600 mb-1'>Frameworks</div>
                  {/*end::Label*/}
                  {/*end::Info*/}
                  <div className='fw-bold fs-4 text-gray-800'>{control?.frameworks}</div>
                  {/*end::Info*/}
                </div>
                {/*end::Col*/}
              </div>

              <div className='row g-5 mb-9'>
                {/*end::Col*/}
                <div className='col-sm-6'>
                  {/*end::Label*/}
                  <div className='fw-semibold fs-4 text-gray-600 mb-1'>Name</div>
                  {/*end::Label*/}
                  {/*end::Col*/}
                  <div className='fw-bold fs-4 text-gray-800'>{control?.name}</div>
                  {/*end::Col*/}
                </div>
                {/*end::Col*/}
                {/*end::Col*/}
                <div className='col-sm-6'>
                  {/*end::Label*/}
                  <div className='fw-semibold fs-4 text-gray-600 mb-1'>Author</div>
                  {/*end::Label*/}
                  {/*end::Info*/}
                  <div className='fw-bold fs-4 text-gray-800'>SecureSlate</div>
                  {/*end::Info*/}
                </div>
                {/*end::Col*/}
              </div>
              <div className='row g-5 mb-9'>
                {/*end::Col*/}
                <div className='col-sm-6'>
                  {/*end::Label*/}
                  <div className='fw-semibold fs-4 text-gray-600 mb-1'>Status</div>
                  {/*end::Label*/}
                  {/*end::Col*/}
                  <div className='fw-bold fs-4 text-gray-800'>
                    {control.health === 'healthy'
                      ? 'Healthy'
                      : control.health === 'unhealthy'
                      ? 'Unhealthy'
                      : 'Not Tested'}
                  </div>
                  {/*end::Col*/}
                </div>
                {/*end::Col*/}
                {/*end::Col*/}
                <div className='col-sm-6'>
                  {/*end::Label*/}
                  <div className='fw-semibold fs-4 text-gray-600 mb-1'>Owner</div>
                  {/*end::Label*/}
                  {/*end::Info*/}
                  <div className='fw-bold fs-4 text-gray-800'>
                    <i className='ki-duotone ki-magnifier fs-1 position-absolute ms-6 mt-3'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>

                    <input
                      type='text'
                      data-kt-filemanager-table-filter='search'
                      className='form-control form-control-solid w-50 ps-15'
                      placeholder='Search Owners'
                    />
                  </div>
                  {/*end::Info*/}
                </div>
                {/*end::Col*/}
              </div>
              <div className='row g-5 mb-9'>
                {/*end::Col*/}
                <div className='col-sm-6'>
                  {/*end::Label*/}
                  <div className='fw-semibold fs-4 text-gray-600 mb-1'>Implementation Date</div>
                  {/*end::Label*/}
                  {/*end::Col*/}
                  <div className='fw-bold fs-4 text-gray-800'>
                    <input
                      type='date'
                      className='form-control form-control-solid w-50'
                      placeholder='name@example.com'
                    />
                  </div>
                  {/*end::Col*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Table header*/}
            </div>
            {/*end::Card body*/}
          </div>
        </div>
      )}
      {/*end::Card body*/}
    </div>
  )
}

export {ControlInformationManagement}
