/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren, toAbsoluteUrl} from '../../../_metronic/helpers'
import {KTIcon} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
})

const PageDataProvider: FC<WithChildren> = ({children}) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: string
  breadcrumbs?: Array<PageLink>
  title?: string
  icon?: string
  desc?: string
  task?: string
  buttonUrl?: string
  completed?: boolean
}

const PageTitle: FC<Props & WithChildren> = ({
  children,
  description,
  breadcrumbs,
  title,
  icon,
  desc,
  task,
  completed,
  buttonUrl = '',
}) => {
  const {setPageTitle, setPageDescription, setPageBreadcrumbs} = usePageData()
  useEffect(() => {
    if (children) {
      setPageTitle(children.toString())
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])
  const handleButtonClick = () => {
    window.location.href = buttonUrl // Navigate to the specified URL when the button is clicked
  }

  return (
    <>
      {icon ? (
        <Link to={buttonUrl} onClick={handleButtonClick}>
          <div className={`card mb-5 mb-xl-8`}>
            <div className='card-header border-0 pt-5 mb-5'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-45px me-5 '>
                  <img src={toAbsoluteUrl(`/media/logos/${icon}`)} alt='' />
                </div>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='card-label fw-bold fs-3'>{title}</span>
                  <span className='text-muted fw-semibold fs-7 d-none d-md-block'>{desc}</span>
                </div>
              </div>

              {task ? (
                <div className='d-flex align-items-center justify-content-end'>
                  <div className='pt-1 mx-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#1CBB8D'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-circle-check'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
                      <path d='M9 12l2 2l4 -4' />
                    </svg>
                  </div>
                  <div className='pt-2'>
                    <a href={buttonUrl} onClick={handleButtonClick}>
                      <span className='card-label fw-bold fs-5 pt-4' style={{color: '#1CBB8D'}}>
                        {task}
                      </span>
                    </a>
                  </div>
                </div>
              ) : (
                <div className='pt-5'>
                  <a href={buttonUrl} onClick={handleButtonClick}>
                    <img src={toAbsoluteUrl(`/media/buttons/Chevron Button.svg`)} alt='' />
                  </a>
                </div>
              )}
            </div>
          </div>
        </Link>
      ) : null}
    </>
  )
}

const PageDescription: FC<WithChildren> = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export {PageDescription, PageTitle, PageDataProvider, usePageData}
