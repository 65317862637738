/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link, useLocation, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {VendorListSearchComponent} from '../components/header/VendorListSearchComponent'
import db from '../../../../services/firebase'
// import firebase from '../../../../services/firebase'

type Props = {}

const Available: React.FC<Props> = () => {
  const [activeTab, setActiveTab] = useState('vendor-risk')
  const[totalVendor,setTotalVendor]= useState(0);
  const location = useLocation()
  const {id} = useParams()
  useEffect(() => {
    // Fetch control data when the component mounts
    if (location.pathname.includes('archive')) {
      setActiveTab('archive')
    } else if (location.pathname.includes('available')) {
      setActiveTab('available')
    } else {
      setActiveTab('vendor-risk')
    }
  }, [id])

  //   const [task, setTask] = useState([])
  //   const [loading, setLoading] = useState(false)

  //   const ref = firebase.firestore().collection('task-list')
  console.log('Reference')

  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: 'Dashboard',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  useEffect(() => {
    const collectionRef = db.collection('vendorManagement'); 
  
    const unsubscribe = collectionRef.onSnapshot((snapshot) => {
      const count = snapshot.size; // Get the number of documents in the collection
      setTotalVendor(count);
    });
  
    return () => unsubscribe(); // Clean up the subscription when the component unmounts
  }, []);

  return (
    <div>
      <PageTitle
        icon='VendorIcon.svg'
        title='Vendor Risk Management'
        desc={totalVendor.toString() + ' Vendors'}
        breadcrumbs={usersBreadcrumbs}
      >
        Vendor Risk Management
      </PageTitle>

      <div className='card card-custom '>
        <div className='pt-3 pb-0 px-10'>
          {/*begin::Navs*/}
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
              {/*begin::Nav item*/}
              <li className='nav-item'>
                <Link
                  to={`/vendor-risk`}
                  className={`nav-link me-6 ${
                    activeTab === 'all-vendors' ? 'text-active-primary active' : ''
                  }`}
                >
                  All Vendor
                </Link>
              </li>
              {/*end::Nav item*/}
              {/*begin::Nav item*/}
              <li className='nav-item'>
                <Link
                  to={'/vendor-risk/available'}
                  className={`nav-link me-6 ${
                    activeTab === 'available' ? 'text-active-primary active' : ''
                  }`}
                >
                  Available
                </Link>
              </li>
              {/* <li className='nav-item'>
                <Link
                  to={'/vendor-risk/archive'}
                  className={`nav-link me-6 ${
                    activeTab === 'archive' ? 'text-active-primary active' : ''
                  }`}
                >
                  Archive
                </Link>
              </li> */}
              {/*end::Nav item*/}
            </ul>
          </div>
          {/*begin::Navs*/}
        </div>
        <div className='px-10 border-0 pt-6'>
          <VendorListSearchComponent />
        </div>
        {/* begin::Table container */}
        <div className='table-responsive py-3 px-10'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-120px'>Vendor</th>
                <th className='min-w-140px'>Integration</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/icons/duotune/social/Discord.svg')} alt='' />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Discord
                      </a>
                    </div>
                  </div>
                </td>

                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Integration available
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img
                        src={toAbsoluteUrl('/media/icons/duotune/social/1Password.svg')}
                        alt=''
                      />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        1Password
                      </a>
                    </div>
                  </div>
                </td>

                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'></a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>

                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/icons/duotune/social/Asana.svg')} alt='' />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Asana
                      </a>
                    </div>
                  </div>
                </td>

                <td>
                  <div className='symbol symbol-20px me-10' style={{paddingLeft: '30%'}}>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'></a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/icons/duotune/social/Notion.svg')} alt='' />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Notion
                      </a>
                    </div>
                  </div>
                </td>

                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Integration available
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/icons/duotune/social/Google.svg')} alt='' />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Google Suite
                      </a>
                    </div>
                  </div>
                </td>

                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Integration available
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {Available}
