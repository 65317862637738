import {collection, doc, getDocs, getFirestore} from 'firebase/firestore'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useListView} from '../../../vendor/core/ListViewProvider'
import {AddRiskModal} from '../AddRiskModal'
import {saveAs} from 'file-saver'
import {useAuth} from '../../../../modules/auth'
// import {RiskListFilter} from './RiskListFilter'

const RiskListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const {currentUser} = useAuth()

  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }
  const exportData = async () => {
    const db = getFirestore()
    const tenantId = currentUser?.tenantId
    const tenantRef = doc(db, 'tenants', tenantId!)
    const PolicyRef = collection(tenantRef, 'risks')
    const querySnapshot = await getDocs(PolicyRef)

    const data = querySnapshot.docs.map((doc) => doc.data())
    // console.log(data);

    if (data.length > 0) {
      const fields = Object.keys(data[0])
      const excludedFields = ['tenantId', 'uid', 'added_controls', 'id','recommendedControlsId']
      const csvFields = fields.filter((field) => !excludedFields.includes(field))

      const csvContent = [csvFields.join(',')] 
      data.forEach((row) => {
        const rowData = fields
          .map((field) => {
            if (field === 'treatmentDue') {
              const date = new Date(row[field])
              return `"${date.toLocaleDateString() || ''}"` 
            } else {
              return `"${row[field] || ''}"` 
            }
          })
          .join(',')
        csvContent.push(rowData)
      })
      // Add the data rows
      const csvBlob = new Blob([csvContent.join('\n')], {type: 'text/csv;charset=utf-8'})
      saveAs(csvBlob, 'risks.csv')
    }
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <VendorListFilter /> */}

      {/* begin::Export */}
      {/* <button type='button' className='btn me-3' onClick={exportData} style={{backgroundColor: '#f1fdf9', color: '#45dbaf'}}>
        <KTIcon iconName='exit-up' className='fs-2 text-success' />
        Export CSV
      </button> */}
      <button
        type='button'
        className=' btn btn-sm btn-light me-4 fs-6'
        onClick={exportData}
        style={{height: '40px', background: '#fff', color: '#000', border: '2px solid #C5C5C5'}}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='#5A5A5A'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-download mb-1'
          style={{marginRight: '4px'}}
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
          <path d='M7 11l5 5l5 -5' />
          <path d='M12 4l0 12' />
        </svg>
        Export
      </button>
      {/* end::Export */}

      {/* begin::Add user */}
      <AddRiskModal />
      {/* end::Add user */}
    </div>
  )
}

export {RiskListToolbar}
