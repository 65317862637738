/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {collection, onSnapshot} from 'firebase/firestore'
import {useAuth} from '../../../modules/auth'
import {Dropdown} from 'react-bootstrap'
import {CloudPageTitle} from '../../../../_metronic/layout/core/CloudPageData'
import Swal from 'sweetalert2'
import {Helmet} from 'react-helmet'
import db from '../../../../services/firebase'
import vendorServices from '../services/vendor.services'
import {PageTitle, PageLink} from '../../../../_metronic/layout/core'
import {Button, ButtonGroup, Popover, OverlayTrigger} from 'react-bootstrap'

// type Props = {
//   className: string
// }
interface UserData {
  id: string
  companyName: string
  riskLevel: string
  imgUrl: string
  archive: boolean
  uid: string
  tenantId: string
}
const DomainChecks: React.FC = () => {
  const [users, setUsers] = useState<UserData[]>([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [activeButton, setActiveButton] = useState('ZAP')
  const [activeTab, setActiveTab] = useState('checks')
  const location = useLocation()
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredItems, setFilteredItems] = useState<UserData[]>([])
  const [searchLength, setSearchLength] = useState(false)
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    getVendor()
  }, [])

  useEffect(() => {
    // Fetch control data when the component mounts
    if (location.pathname.includes('checks')) {
      setActiveTab('checks')
    } else {
      setActiveTab('domains')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setFilteredItems(users)
  }, [users])

  const getVendor = () => {
    try {
      const unsub = onSnapshot(
        collection(db, 'vendorManagement'),
        (snapshot) => {
          let list: UserData[] = []
          snapshot.docs.forEach((doc) => {
            list.push({id: doc.id, ...doc.data()} as UserData)
          })

          setUsers(list)
          setLoading(false)
        },
        (error) => {
          console.log(error)
        }
      )

      return unsub // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting vendors:', error)
    }
  }

  const handleDelete = async (item) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${item.companyName} Vendor`,
        text: `Are you sure you want to remove ${item.companyName} Vendor ? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        // User confirmed, proceed with the deletion
        await vendorServices.deleteVendor(item.id)
        // getVen()
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: ` "${item.companyName}" has been deleted.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      console.error('Error deleting policy:', error)
      // Handle the error or show an error message to the user
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the policy.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      // setDropdownVisible(false)
    }
  }
  const usersWithSameTenantId = users.filter((user) => user.tenantId === currentUser?.tenantId)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    // Assuming policy is your original data array
    if (searchTerm === '') {
      // If the search term is empty, reset to the original policies
      setFilteredItems(users)
      setSearchLength(false)
    } else {
      const filteredItems = users.filter((user) =>
        user.companyName.toLowerCase().includes(searchTerm)
      )
      setFilteredItems(filteredItems)
      setSearchLength(filteredItems.length === 0)
    }
  }

  const popover = (
    <Popover id='popover-basic'>
      <Popover.Body>
        Zap will probe the software you have built for typical defects such as missing headers,
        cookie configuration,accidentally deployed files,etc.
      </Popover.Body>
    </Popover>
  )

  const popover2 = (
    <Popover id='popover-basic'>
      <Popover.Body>
        Nuclei will probe your web-availabe services for known defects. It will give you an
        attacker's point of view of your hosted infrastructure, such as your Gitlab server, your
        Wordpress website, your hosted Confluence server, etc.
      </Popover.Body>
    </Popover>
  )
  // console.log(users)
  return (
    <>
      {/* begin::Header */}
      <Helmet>
        <title>Secureslate | Domains</title>
      </Helmet>
      <PageTitle
        icon='Icon-3.svg'
        title='Domains'
        desc={'0 connected domain'}
        // breadcrumbs={usersBreadcrumbs}
      >
        Domains
      </PageTitle>

      <div className='card card-custom '>
        <div className='pt-3 pb-0 px-10'>
          {/*begin::Navs*/}
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
              {/*begin::Nav item*/}
              <li className='nav-item'>
                <Link
                  to={`/domains`}
                  className={`nav-link me-6 ${
                    activeTab === 'domains' ? 'text-active-primary active' : ''
                  }`}
                >
                  Domains
                </Link>
              </li>
              {/*end::Nav item*/}
              {/*begin::Nav item*/}
              <li className='nav-item'>
                <Link
                  to={'/domains/checks'}
                  className={`nav-link me-6 ${
                    activeTab === 'checks' ? 'text-active-primary active' : ''
                  }`}
                >
                  Checks
                </Link>
              </li>
              {/*end::Nav item*/}
            </ul>
          </div>
          {/*begin::Navs*/}
        </div>
        <div className='card-header border-0 pt-6 h-100'>
          <div className='card-title'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search..'
                value={searchTerm}
                onChange={handleInputChange}
              />
            </div>
            {/* end::Search */}
          </div>
          <div className='card-toolbar'>
            {/* begin::Group actions */}
            {/* <CloudListToolbar /> */}
            {/* end::Group actions */}
            <ButtonGroup aria-label='Basic example' className='border'>
              <Button
                variant={activeButton === 'ZAP' ? 'primary' : 'outline-secondary'}
                onClick={() => setActiveButton('ZAP')}
              >
                <OverlayTrigger trigger={['hover', 'focus']} placement='bottom' overlay={popover}>
                  <i className='bi bi-exclamation-circle '></i>
                </OverlayTrigger>{' '}
                ZAP
              </Button>
              <Button
                variant={activeButton === 'Nuclei' ? 'primary' : 'outline-secondary'}
                onClick={() => setActiveButton('Nuclei')}
              >
                <OverlayTrigger trigger={['hover', 'focus']} placement='bottom' overlay={popover2}>
                  <i className='bi bi-exclamation-circle '></i>
                </OverlayTrigger>{' '}
                Nuclei
              </Button>
            </ButtonGroup>
          </div>
        </div>

        <div className='card-body py-3 '>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {activeButton === 'ZAP' ? (
              <>
                {/* begin::Table */}
                <table className='table align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold text-muted border-bottom border-dotted '>
                      <th className='ps-4 min-w-500px rounded-start'>TITLE</th>
                      <th className='min-w-500px'>DESCRIPTION</th>
                      <th className='min-w-100px'>SCORE</th>
                    </tr>
                  </thead>

                  {/* {filteredPolicies &&
              filteredPolicies.length > 0 &&
              usersWithSameTenantId.length > 0 ? (
                filteredPolicies
                  .slice() // Create a copy of the array to avoid modifying the original array
                  .sort((a, b) => {
                    const policyA = a.policy || ''; // Default to empty string if undefined
                    const policyB = b.policy || ''; // Default to empty string if undefined
                    return policyA.localeCompare(policyB);
                  })
                  .filter(
                    (user) => user.tenantId === currentUser?.tenantId 
            
                  )

                  .map((policyData, index) => {
                    return ( */}
                  <tbody>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          Server accepts invalid JWT tokens{' '}
                          <span className='badge badge-light m-1'>Authenticated Only</span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          The server verifying the JWT, accepts accepts tokens with an invalid
                          signature. The signature of a JWT is crucial for ensuring its integrity
                          and authenticity; it confirms that the token was issued by a trusted
                          source and has not been tampered with. A failure in this verification
                          process means a bad actor could alter the contents of a JWT (such as the
                          payload data indicating the user's identity or permissions) without the
                          application detecting the modification.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-danger'>
                          {' '}
                          <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Critical
                        </div>
                      </td>
                    </tr>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          Heartbleed OpenSSL Vulnerability
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          The TLS and DTLS implementations in OpenSSL 1.0.1 before 1.0.1g do not
                          properly handle Heartbeat Extension packets, which allows remote attackers
                          to obtain sensitive information from process memory via crafted packets
                          that trigger a buffer over-read, potentially disclosing sensitive
                          information.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-danger '>
                          {' '}
                          <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Crtical
                        </div>
                      </td>
                    </tr>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          Content Security Policy (CSP) header not set
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          Content Security Policy (CSP) is a first line of defense agains common
                          attacks including Cross Site Scripting (XSS) and data injection attacks.
                          These attacks are used for everything from data theft via account
                          takeovers to site defacement or distribution of malware. CSP config allows
                          you to declare what content can be loaded and executed via a standard HTTP
                          header. You can whitelist JavaScript, CSS, HTML frames, fonts, images and
                          embeddable objects such as Java applets, ActiveX, audio and video files.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-danger'>
                          <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Crtical
                        </div>
                      </td>
                    </tr>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          Content Security Policy (CSP) Report-Only header found
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          The response contained a Content-Security-Policy-Report-Only header, this
                          may indicate a work-in-progress implementation, or an oversight in
                          promoting pre-Prod to Prod, etc.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-danger'>
                          {' '}
                          <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Crtical
                        </div>
                      </td>
                    </tr>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          TLS not enforced with valid HSTS header
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          HTTP Strict Transport Security (HSTS) is a mechanism whereby a web server
                          forces web browsers to interact with it using only secure HTTPS
                          connections (i.e. HTTP layered over TLS/SSL). By enforcing this, you are
                          protecting your users from various man-in-the-middle attacks because it's
                          harder for attackers to downgrade connections to HTTP instead of HTTPS.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-danger'>
                          {' '}
                          <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Crtical
                        </div>
                      </td>
                    </tr>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>HSTS header is missing</div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          HTTP Strict Transport Security (HSTS) is a mechanism whereby a web server
                          forces web browsers to interact with it using only secure HTTPS
                          connections (i.e. HTTP layered over TLS/SSL). By enforcing this, you are
                          protecting your users from various man-in-the-middle attacks because it's
                          harder for attackers to downgrade connections to HTTP instead of HTTPS.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-warning'>
                          {' '}
                          <i className='bi bi-exclamation-circle mx-1 text-warning'></i>High
                        </div>
                      </td>
                    </tr>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          Session cookie is not secured{' '}
                          <span className='badge badge-light m-1'>Authenticated Only</span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          The cookie holding the session token should have the 'httpOnly' and
                          'secure' attribute enabled. These attributes are crucial to ensure that
                          this token is secured from 3rd party scripts obtaining them.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-warning'>
                          {' '}
                          <i className='bi bi-exclamation-circle mx-1 text-warning'></i>High
                        </div>
                      </td>
                    </tr>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          CSP configuration is not restrictive enough
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          Content Security Policy (CSP) is a first line of defense agains common
                          attacks including Cross Site Scripting (XSS) and data injection attacks.
                          In this case your CSP header is set, but it's not restrictive enough to
                          provide the best defense.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-info mx-5'>Medium</div>
                      </td>
                    </tr>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          Authentication cookie can be leaked to any subdomain{' '}
                          <span className='badge badge-light m-1'>Authenticated Only</span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          Cookies can be scoped by domain or path. The domain scope applied to a
                          cookie determines which domains can access it. For example, a cookie can
                          be scoped strictly to a subdomain e.g. www.nottrusted.com, or loosely
                          scoped to a parent domain e.g. nottrusted.com. In the latter case, any
                          subdomain of nottrusted.com can access the cookie.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-info mx-5'>Medium</div>
                      </td>
                    </tr>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          Server leaks version info via 'Server' HTTP header
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          The server is leaking version information via the “Server” HTTP response
                          header. Access to such information may facilitate attackers identifying
                          other vulnerabilities your web/application server is subject to.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-info mx-5'>Medium</div>
                      </td>
                    </tr>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          App crash may disclose error info
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          This page contains an error message that may disclose sensitive
                          information like the location of the file that produced the unhandled
                          exception. This information can be used to launch further attacks against
                          the web application.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-primary mx-5'>Low</div>
                      </td>
                    </tr>
                  </tbody>
                  {/* )
                  })
              ) : ( */}
                  {/* <tbody>
                  {searchLength === true ? (
                    <tr>
                      <td colSpan={6} className='text-center'>
                        <div className='m-20'>
                          <img src='/media/buttons/emptystate.svg' alt='' />
                          <div className='m-5 fs-5'>
                            <p style={{fontWeight: 'bold'}}>No polices Found</p>
                          </div>
                          <p className=''>
                            We couldn't find any policies that match your search. Maybe try a
                            different search!
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan={6} className='text-center'>
                        {!showEmptyState  ? (
                        // <PolicyListLoading/>
                        <></>
                      ) : (
                          <div className='m-20'>
                            <img src='/media/buttons/emptystate.svg' alt='' />
                            <div className='m-5 fs-5'>
                              <p style={{fontWeight: 'bold'}}>No polices Added</p>
                            </div>
                            <p>
                              Add the polices that all employees within your organization are
                              required to adhere to.
                            </p>
                          </div>
                        )}
                      </td>
                    </tr>
                  )}
                </tbody> */}
                  {/* )} */}

                  {/* end::Table body */}
                </table>
              </>
            ) : (
              <>
                <table className='table align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold text-muted border-bottom border-dotted '>
                      <th className='ps-4 min-w-500px rounded-start'>NAME</th>
                      <th className='min-w-500px'>DESCRIPTION</th>
                      <th className='min-w-150px'>TAGS</th>
                      <th className='min-w-100px'>SEVERITY</th>
                    </tr>
                  </thead>

                  {/* {filteredPolicies &&
              filteredPolicies.length > 0 &&
              usersWithSameTenantId.length > 0 ? (
                filteredPolicies
                  .slice() // Create a copy of the array to avoid modifying the original array
                  .sort((a, b) => {
                    const policyA = a.policy || ''; // Default to empty string if undefined
                    const policyB = b.policy || ''; // Default to empty string if undefined
                    return policyA.localeCompare(policyB);
                  })
                  .filter(
                    (user) => user.tenantId === currentUser?.tenantId 
            
                  )

                  .map((policyData, index) => {
                    return ( */}
                  <tbody>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          ClockWatch Enterprise - Remote Code Execution
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          ClockWatch Enterprise is susceptible to remote code execution.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>clockwatch, rce, network</div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-danger'>
                          {' '}
                          <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Critical
                        </div>
                      </td>
                    </tr>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          ZTE Router Panel - Detect
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          Multiple ZTE router panels were detected. These routers have a
                          telnet-hardcoded backdoor account that spawns root shell.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          edb, network, zte, telnet, backdoor, router
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-danger '>
                          {' '}
                          <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Crtical
                        </div>
                      </td>
                    </tr>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          Ruijie EG Easy Gateway - Remote Command Execution
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          Ruijie EG Easy Gateway login.php has remote commmand execution
                          vulnerability, which can lead to the disclosure of administrator account
                          and password.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>ruijie, rce</div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-danger'>
                          <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Crtical
                        </div>
                      </td>
                    </tr>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          QVISDVR JSF Deserialization - Remote Code Execution
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          QVISDVR Java-Deserialization was discovered, which could allow remote code
                          execution.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          qvisdvr, rce, deserialization, jsf, iot
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-danger'>
                          {' '}
                          <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Crtical
                        </div>
                      </td>
                    </tr>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          elFinder 2.1.58 - Remote Code Execution
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          elFinder 2.1.58 is vulnerable to remote code execution. This can allow an
                          attacker to execute arbitrary code and commands on the server hosting the
                          elFinder PHP connector, even with minimal configuration.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>tech, elfinder, oss</div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-danger'>
                          {' '}
                          <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Crtical
                        </div>
                      </td>
                    </tr>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          Header - Remote Command Injection
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          Headers were tested for remote command injection vulnerabilities.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>fuzz, rce</div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-danger'>
                          {' '}
                          <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Crtical
                        </div>
                      </td>
                    </tr>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          Plastic Admin Console - Authentication Bypass
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          A Plastic Admin console was discovered.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          plastic, misconfig, intrusive
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-danger'>
                          {' '}
                          <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Crtical
                        </div>
                      </td>
                    </tr>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          Jupyter Lab - Unauthenticated Access
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          JupyterLab was able to be accessed without authentication.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          unauth, jupyter, jupyterlab, misconfig
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-danger'>
                          {' '}
                          <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Crtical
                        </div>
                      </td>
                    </tr>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          Jupyter ipython - Authorization Bypass
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          Jupyter was able to be accessed without authentication.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>unauth, jupyter, misconfig</div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-danger'>
                          {' '}
                          <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Crtical
                        </div>
                      </td>
                    </tr>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          Redis Sandbox Escape - Remote Code Execution
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          This template exploits CVE-2022-0543, a Lua-based Redis sandbox escape.
                          The vulnerability was introduced by Debian and Ubuntu Redis packages that
                          insufficiently sanitized the Lua environment. The maintainers failed to
                          disable the package interface, allowing attackers to load arbitrary
                          libraries.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          cve, cve2022, network, redis, unauth, rce, kev
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-danger'>
                          {' '}
                          <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Crtical
                        </div>
                      </td>
                    </tr>
                    <tr className=' border-bottom border-dotted'>
                      <td>
                        <div className='d-flex align-items-center mx-5'>
                          ProFTPd - Remote Code Execution
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          ProFTPD 1.3.5 contains a remote code execution vulnerability via the
                          mod_copy module which allows remote attackers to read and write to
                          arbitrary files via the site cpfr and site cpto commands.
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          cve, cve2015, ftp, rce, network, proftpd, edb
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center text-danger'>
                          {' '}
                          <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>Crtical
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  {/* )
                  })
              ) : ( */}
                  {/* <tbody>
                  {searchLength === true ? (
                    <tr>
                      <td colSpan={6} className='text-center'>
                        <div className='m-20'>
                          <img src='/media/buttons/emptystate.svg' alt='' />
                          <div className='m-5 fs-5'>
                            <p style={{fontWeight: 'bold'}}>No polices Found</p>
                          </div>
                          <p className=''>
                            We couldn't find any policies that match your search. Maybe try a
                            different search!
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan={6} className='text-center'>
                        {!showEmptyState  ? (
                        // <PolicyListLoading/>
                        <></>
                      ) : (
                          <div className='m-20'>
                            <img src='/media/buttons/emptystate.svg' alt='' />
                            <div className='m-5 fs-5'>
                              <p style={{fontWeight: 'bold'}}>No polices Added</p>
                            </div>
                            <p>
                              Add the polices that all employees within your organization are
                              required to adhere to.
                            </p>
                          </div>
                        )}
                      </td>
                    </tr>
                  )}
                </tbody> */}
                  {/* )} */}

                  {/* end::Table body */}
                </table>
              </>
            )}
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      </div>
    </>
  )
}

export {DomainChecks}
