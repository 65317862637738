/* eslint-disable no-loop-func */
/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../helpers'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'
import {collection, doc, onSnapshot, updateDoc} from 'firebase/firestore'
import db from '../../../../../services/firebase'

const OnSidebarMenuMain = () => {
  const [grandTotal, setGrandTotal] = useState(0)
  const [policyTotal, setPolicyTotal] = useState(0)
  const [completed, setCompleted] = useState(0)
  const {currentUser} = useAuth()
  const [personnelPoliciesLength, setPersonnelPoliciesLength] = useState(0)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    const fetchTrainingData = async () => {
      try {
        const tenantId = currentUser?.tenantId || ''
        if (!tenantId) {
          return
        }
        let totalCompleted = 0
        const tenantRef = doc(db, 'tenants', tenantId)
        const onboardingCollectionRef = collection(tenantRef, 'onboarding')
        const policyDocRef = doc(onboardingCollectionRef, 'training')

        const unsubscribe = onSnapshot(policyDocRef, (doc) => {
          const policyData = doc.data() || {}

          if (
            policyData &&
            policyData.SecurityTraining?.[0]?.isChecked &&
            policyData.PCISecure?.[0]?.isChecked
          ) {
            totalCompleted += 50
          } else {
            totalCompleted += 0
          }

          setGrandTotal(totalCompleted)
        })

        return () => unsubscribe()
      } catch (error) {
        console.error('Error fetching training data:', error)
      }
    }

    fetchTrainingData()
  }, [grandTotal])

  const fetchData = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }

      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'employees')
      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const employeesData = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
            fullName: doc.data().full_name,
            group: doc.data().group,
          }))
          .filter((data) => data.fullName === currentUser?.userName)

        if (employeesData.length > 0) {
          const group = employeesData[0].group
          const groupRef = collection(tenantRef, 'personnel_group')
          const unsubscribe = onSnapshot(groupRef, (snapshot) => {
            const groupData = snapshot.docs
              .map((doc) => ({
                ...doc.data(),
                id: doc.id,
                checklist: doc.data().checklist,
                groupName: doc.data().groupName,
              }))
              .filter((data) => data.groupName === group)

            const checklist = groupData[0].checklist
            const checklistRef = collection(tenantRef, 'personnel_checklist')
            const unsubscribe2 = onSnapshot(checklistRef, (snapshot) => {
              const checklistData = snapshot.docs
                .map((doc) => ({
                  ...doc.data(),
                  id: doc.id,
                  checklist: doc.data().checklist_name,
                  policies: doc.data().policies,
                }))
                .filter((data) => data.checklist === checklist)
                if (checklistData.length > 0) {  
                  setPersonnelPoliciesLength(checklistData[0].policies.length);  
                } else {  
                  console.warn("No checklist data found for:", checklist);  
                  setPersonnelPoliciesLength(0); 
                }  
            })
          })
        }
      })
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    const fetchTrainingData = async () => {
      try {
        const tenantId = currentUser?.tenantId || ''
        if (!tenantId) {
          return
        }
        let totalCompleted = 0
        const tenantRef = doc(db, 'tenants', tenantId)
        const onboardingCollectionRef = collection(tenantRef, 'onboarding')
        const policyDocRef = doc(onboardingCollectionRef, 'policy')

        const unsubscribe = onSnapshot(policyDocRef, (doc) => {
          const policyData = doc.data() || {}

          const checkedEntries = Object.values(policyData).filter((entry) => entry.isChecked)
          console.log(checkedEntries)
          const allEntriesChecked = checkedEntries.length === personnelPoliciesLength

          if (allEntriesChecked) {
            totalCompleted += 50
          } else {
            totalCompleted += 0
          }
          setPolicyTotal(totalCompleted)
        })

        return () => unsubscribe()
      } catch (error) {
        console.error('Error fetching training data:', error)
      }
    }

    fetchTrainingData()
  }, [policyTotal, personnelPoliciesLength])
  console.log(policyTotal)

  // useEffect(() => {
  //   const fetchIdentityData = async () => {
  //     try {
  //       const tenantId = currentUser?.tenantId || '';
  //       const userId = currentUser?.uid || '';
  //       let totalCompleted = 0
  //       if (!tenantId || !userId) {
  //         // If either tenantId or userId is not available, exit early
  //         return;
  //       }

  //       const tenantRef = doc(db, 'tenants', tenantId);
  //       const identityDocRef = doc(tenantRef, 'onboarding', 'identity');

  //       // Fetch the identity document
  //       const identityDoc = await getDoc(identityDocRef);
  //       const identityData = identityDoc.exists() ? identityDoc.data() : null;

  //       if (identityData?.identity) {
  //         console.log(identityData.identity)
  //         totalCompleted += 20
  //       } else{
  //         totalCompleted += 0
  //       }
  //       setOnboardingTotal(totalCompleted)
  //     } catch (error) {
  //       console.error('Error fetching identity data:', error);

  //     }
  //   };

  //   fetchIdentityData();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentUser?.tenantId, currentUser?.uid]);

  useEffect(() => {
    setCompleted(grandTotal + policyTotal)
    // try {

    //   const tenantId = currentUser?.tenantId || '' 
    //   const tenantRef = doc(db, 'tenants', tenantId)
 
    //   const assetRef = doc(tenantRef, 'employees', id)
    // updateDoc(assetRef, {onboarding: "Complete"})
    
    // } catch (error) {
    //   console.error('Error updating personnel:')
 
    // }
  }, [grandTotal, policyTotal])

  return (
    <>
      <div
        className='card-body d-flex align-items-center pt-2 justify-content-center'
        style={{marginTop: '-20px'}}
      >
        <div
          className='d-flex align-items-center flex-column mt-5 w-100 justify-content-start mb-5 p-5 pt-3 '
          style={{backgroundColor: '#19191D', borderRadius: '10px'}}
        >
          <div className='d-flex justify-content-between fw-bold fs-7 text-black opacity-75 w-100 mt-auto mb-2 pt-2'>
            <span className='text-gray-100'>Onboarding</span>

            <span className='text-muted fs-7'>
              {/* {grandTotal === 40 && policyTotal === 40 && onboardingTotal === 20
                ? '3 of 3 complete'
                : (grandTotal === 40 ? 1 : 0) +
                    (policyTotal === 40 ? 1 : 0) +
                    (onboardingTotal === 20 ? 1 : 0) ===
                  2
                ? '2 of 3 complete'
                : grandTotal === 40 || policyTotal === 40 || onboardingTotal === 20
                ? '1 of 3 complete'
                : '0 of 3 complete'} */}
              {grandTotal === 50 && policyTotal === 50
                ? '2 of 2 complete'
                : (grandTotal === 50 ? 1 : 0) + (policyTotal === 50 ? 1 : 0) === 2
                ? '2 of 2 complete'
                : grandTotal === 50 || policyTotal === 50
                ? '1 of 2 complete'
                : '0 of 2 complete'}
            </span>
          </div>
          <div className='h-8px mx-0 w-100 rounded' style={{backgroundColor: '#DAF9EF'}}>
            <div
              className='rounded h-8px'
              role='progressbar'
              style={{
                width: `${Math.min(grandTotal + policyTotal, 100)}%`,
                backgroundColor: '#44DBAF',
              }}
              aria-valuenow={Math.min(grandTotal + policyTotal, 100)}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
          <div className='text-muted mt-2 fs-8'>
            {completed === 100 ? 'Onboarding has been completed.You can exit Onboarding.' : ''}
          </div>
        </div>
      </div>

      <img src={toAbsoluteUrl(`/media/logos/line 4.svg`)} className='mb-6' alt='line' />
      {/* <SidebarMenuItem
        to='/dashboard'
        icon='home'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      /> */}

      <SidebarMenuItem to='/training' icon='copy-success' title='Training' fontIcon='bi-layers' />
      <SidebarMenuItem to='/policies' icon='document' title='Policies' fontIcon='bi-layers' />
      {/* <SidebarMenuItem
        to='/backgroundCheck'
        icon='profile-circle'
        title='Identity Verification'
        fontIcon='bi-layers'
      /> */}
    </>
  )
}

export {OnSidebarMenuMain}
