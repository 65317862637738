import React, {useEffect, useRef, useState} from 'react'
import {useAuth} from '../../../../../app/modules/auth'

interface ReferralProps {
  onClose: () => void
}

const Referal: React.FC<ReferralProps> = ({onClose}) => {
  const modalRef = useRef<HTMLDivElement | null>(null)
  const {currentUser} = useAuth()
  const [copySuccess, setCopySuccess] = useState<string>('')

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const copyToClipboard = () => {
    const referralId = currentUser?.uid || ''
    navigator.clipboard.writeText(referralId).then(
      () => {
        setCopySuccess('Copied!')
        setTimeout(() => setCopySuccess(''), 2000)
      },
      (err) => {
        console.error('Failed to copy: ', err)
        setCopySuccess('Failed to copy')
      }
    )
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        tabIndex={-1}
        id='kt_add_personnel'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
      >
        <div className='modal-dialog modal-dialog-centered mw-500px'>
          <div className='modal-content ' ref={modalRef}>
            <div className='modal-header d-flex justify-content-between align-items-center '>
              <h2 className='fw-bold m-0'>Earn $150 when you refer a friend</h2>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={onClose}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  className='icon icon-tabler icons-tabler-outline icon-tabler-x'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M18 6l-12 12' />
                  <path d='M6 6l12 12' />
                </svg>
              </div>
            </div>

            <div className='modal-body scroll-y '>
              <p className='fs-6 fw-semibold mb-4' style={{color: '#5A5A5A'}}>
                Refer a friend to SecureSlate and earn $150 credit when they sign up. Your friend
                will also get a discount!
              </p>
              <label className='fs-6 fw-semibold mb-1'>Referral Code </label>
              <div style={{display: 'flex', alignItems: 'center'}} className='mb-4 fw-block'>
                <input
                  className='form-control '
                  type='url'
                  placeholder='Referral Id'
                  style={{flexGrow: 1}}
                  value={currentUser?.uid || ''}
                  readOnly
                />
                <button
                  className='btn btn-sm '
                  style={{height: '42px', background: '#1CBB8D', color: '#fff',marginLeft:'12px'}}
                  onClick={copyToClipboard}
                >
                  {copySuccess || 'Copy'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Referal
