import {collection, getDocs, getFirestore} from 'firebase/firestore'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {VendorListFilter} from './VendorListFilter'
import {saveAs} from 'file-saver'

const VendorListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  const exportData = async () => {
    const db = getFirestore()
    const collectionRef = collection(db, 'vendorManagement')
    const querySnapshot = await getDocs(collectionRef)

    const data = querySnapshot.docs.map((doc) => doc.data())
    // console.log(data);

    if (data.length > 0) {
      const fields = Object.keys(data[0]).filter(field => field !== 'tenantId' && field !== 'uid') // Get the field names from the first data item
      const csvContent = [fields.join(',')] // Add the field names as the first row
      data.forEach((row) => {
        const rowData = fields
          .map((field) => {
            if (field === 'dateOfLastReview' || field === 'dateOfEngagement') {
              const date = new Date(row[field]) // Convert the string to a date object
              return `"${date.toLocaleDateString() || ''}"` // Convert date to date string
            } else if (typeof row[field] === 'object' && !Array.isArray(row[field])) {
              // Handle map and boolean fields
              return JSON.stringify(row[field])
            } else {
              return `"${row[field] || ''}"` // Use the field value directly if it exists, otherwise use an empty string
            }
          })
          .join(',')
        csvContent.push(rowData)
      })
      // Add the data rows
      const csvBlob = new Blob([csvContent.join('\n')], {type: 'text/csv;charset=utf-8'})
      saveAs(csvBlob, 'data.csv')
    }
  }
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <VendorListFilter /> */}

      {/* begin::Export btn-light-primary */}
      {/* <button type='button' className='btn me-3' onClick={exportData} style={{backgroundColor: '#f1fdf9', color: '#45dbaf'}}>
        <KTIcon iconName='exit-up' className='fs-2 text-primary' />
        Export CSV
      </button> */}
      {/* end::Export */}

      {/* begin::Add user */}
      <a href='#'>
        <button
          type='button'
    
          onClick={openAddUserModal}
          className='btn btn-sm fs-6'
          style={{height:'40px',background:'#1CBB8D',color:'#fff'}}
          onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
            (e.currentTarget.style.backgroundColor = '#148363')
          }
          onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
            (e.currentTarget.style.backgroundColor = '#1CBB8D')
          }
        >
          <KTIcon iconName='plus' className='fs-2 text-white' />
          Connect cloud
        </button>
      </a>
      {/* end::Add user */}
    </div>
  )
}

export {VendorListToolbar}
