/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import db from '../../../services/firebase'
import controlServices from '../controls/services/control.services'
import {format} from 'date-fns'
import {Link, useParams, useLocation} from 'react-router-dom'

type Props = {}

const RiskDetailsManagement: React.FC<Props> = () => {
  const [risk, setRisk] = useState<any | null>(null)
  const location = useLocation()
  const [activeTab, setActiveTab] = useState('details')
  const {id} = useParams()
  // console.log('Risk', risk)
  const [dataCount, setDataCount] = useState(0)
  useEffect(() => {
    // Fetch risk data when the component mounts
    if (id) {
      getRisk(id)
    }
    if (location.pathname.includes('details')) {
      setActiveTab('details')
    } else if (location.pathname.includes('history')) {
      setActiveTab('history')
    }
  }, [id])

  const getRisk = async (controlId: string) => {
    try {
      const riskRef = db.collection('RiskManagement').doc(controlId) // Replace 'your-collection-name' with your actual Firestore collection name
      const doc = await riskRef.get()

      if (doc.exists) {
        setRisk(doc.data())
      } else {
        // Handle the case where the document doesn't exist
        console.log(`Risk with ID ${controlId} not found.`)
      }
    } catch (error) {
      console.error('Error fetching risk data:', error)
    }
  }

  return (
    <div
      className='card card-flush pb-0 bgi-position-y-center bgi-no-repeat mb-10'
      style={{
        backgroundSize: 'auto calc(100% + 10rem)',
        backgroundPositionX: '100%',
        backgroundImage: "url('assets/media/illustrations/sketchy-1/4.png')",
      }}
    >
      {/*begin::Card header*/}

      {risk && (
        <div>
          <div className='card-header pt-5'>
            <div className=''>
              <div className='d-flex justify-content-start flex-column'>
                <span className='card-label fw-bold fs-3'>{risk.riskCode}</span>
                <span className='text-muted fw-semibold fs-5 mb-3'>{risk.riskSource}</span>
              </div>
            </div>

            <div
              className='d-flex justify-content-end mb-5'
              data-kt-filemanager-table-toolbar='base'
            >
              {/*begin::Export*/}
              <button
                type='button'
                className='btn btn-flex btn-light-primary me-3'
                id='kt_file_manager_new_folder'
              >
                <i className='ki-duotone ki-add-folder fs-2'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                Archive
              </button>
              {/*end::Export*/}

              {/*begin::Add customer*/}
              <button
                type='button'
                className='btn btn-flex btn-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_upload'
              >
                Complete Assessment
              </button>
              {/*end::Add customer*/}
            </div>
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className=' pt-2 pb-0' style={{paddingLeft: '2%'}}>
            {/*begin::Navs*/}
            <div className='d-flex overflow-auto h-55px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
                {/*begin::Nav item*/}
                <li className='nav-item'>
                  <Link
                    to={`/risk-management/${id}/details`}
                    className={`nav-link me-6 ${
                      activeTab === 'details' ? 'text-active-primary active' : ''
                    }`}
                  >
                    Details
                  </Link>
                </li>
                {/*end::Nav item*/}
                {/*begin::Nav item*/}
                <li className='nav-item'>
                  <Link
                    to={`/risk-management/${id}/history`}
                    className={`nav-link me-6 ${
                      activeTab === 'history' ? 'text-active-primary active' : ''
                    }`}
                  >
                    History
                  </Link>
                </li>
                {/*end::Nav item*/}
              </ul>
            </div>
            {/*begin::Navs*/}
          </div>
          <div className='card card-flush ' style={{height: '600px'}}>
            {/*begin::Card header*/}

            {/*end::Card header*/}
            <div
              className='card-body d-flex flex-column align-items-center justify-content-center'
              style={{height: '100%'}}
            >
              {/* Icon */}
              <i className='ki-duotone ki-note-2 fs-5x mb-3' style={{color: '#44dbae'}}>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
                <span className='path4'></span>
              </i>

              <span className='text-semibold mb-3 fs-4'>
                This risk assessment has not been started
              </span>

              {/* Button */}
              <a
                className='btn btn-flex btn-primary'
                href={`/risk-management/${id}/details/complete-assessment`}
                role='button'
              >
                Complete Assessment
              </a>
            </div>
          </div>
        </div>
      )}
      {/*end::Card body*/}
    </div>
  )
}

export {RiskDetailsManagement}
