import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {KTCard} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth'
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  Timestamp,
  where,
} from 'firebase/firestore'
import db from '../../../../services/firebase'
import {TestListLoading} from '../../tests/components/loading/TestListLoading'
import {saveAs} from 'file-saver'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ReactPaginate from 'react-paginate'
type Location = string | null

type DateState = {
  startDate: Date | null
  endDate: Date | null
}
const ActivityLog = () => {
  const [activities, setActivities] = useState<any[]>([])
  const {currentUser} = useAuth()
  const [dateState, setDateState] = useState<DateState>({
    startDate: null,
    endDate: null,
  })
  const [selectedLocation, setSelectedLocation] = useState<Location>(null)
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [selectedAction, setSelectedAction] = useState<string | null>(null)
  const [filteredActivities, setFilteredActivities] = useState<any[]>([])
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null])
  const [startDate, endDate] = dateRange
  const [currentPage, setCurrentPage] = useState(0)
  const [selectedNumber, setSelectedNumber] = useState('100')
  let ITEMS_PER_PAGE = parseInt(selectedNumber)
  const pageCount = Math.ceil(filteredActivities.length / ITEMS_PER_PAGE)

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 3000)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    getActivities()
  }, [])

  useEffect(() => {
    const filtered = activities.filter((item: any) => {
      const matchesLocation = selectedLocation === null || item.target === selectedLocation
      const matchesAction = selectedAction === null || item.action === selectedAction
      const isWithinDateRange =
        !startDate ||
        !endDate ||
        (item.timestamp.toDate() >= startDate && item.timestamp.toDate() <= endDate)
      return matchesLocation && matchesAction && isWithinDateRange
    })
    setFilteredActivities(filtered)
  }, [activities, selectedLocation, selectedAction, startDate, endDate])

  const getActivities = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const activitiesCollectionRef = collection(tenantRef, 'activities')

      let activitiesQuery = query(activitiesCollectionRef)

      if (dateState.startDate && dateState.endDate) {
        const startTimestamp = Timestamp.fromDate(dateState.startDate)
        const endTimestamp = Timestamp.fromDate(dateState.endDate)
        activitiesQuery = query(
          activitiesCollectionRef,
          where('timestamp', '>=', startTimestamp),
          where('timestamp', '<=', endTimestamp)
        )
      }

      const unsubscribe = onSnapshot(activitiesQuery, (snapshot) => {
        const activitiesData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setActivities(activitiesData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting activities:', error)
    }
  }

  const handlePageClick = ({selected}: {selected: number}) => {
    setCurrentPage(selected)
  }

  const offset = currentPage * ITEMS_PER_PAGE

  const paginatedData = filteredActivities.slice().slice(offset, offset + ITEMS_PER_PAGE)

  const formatDate = (dateString: string): string => {
    try {
      let [month, day, year] = dateString.split('/')

      // Ensure year is 4 digits
      if (year.length === 2) {
        year = `20${year}`
      }

      const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))

      const formattedDay = parseInt(day)
      const formattedMonth = date.toLocaleString('default', {month: 'long'})
      const formattedYear = year

      return `${formattedDay} ${formattedMonth}, ${formattedYear}`
    } catch (error) {
      return ''
    }
  }

  function formatTimestamp(timestamp: Timestamp): string {
    const date = timestamp.toDate()

    let hours = date.getHours()
    let minutes = date.getMinutes()

    // Round minutes to nearest 12
    minutes = Math.round(minutes / 12) * 12

    // Adjust hours if minutes round up to 60
    if (minutes === 60) {
      minutes = 0
      hours += 1
    }

    // Convert to 12-hour format
    const ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'

    // Format the result
    const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`

    return formattedTime
  }

  const exportData = async () => {
    const db = getFirestore()
    const tenantId = currentUser?.tenantId
    const tenantRef = doc(db, 'tenants', tenantId!)
    const PolicyRef = collection(tenantRef, 'activities')
    const querySnapshot = await getDocs(PolicyRef)

    const data = querySnapshot.docs.map((doc) => doc.data())

    // .filter((item) => item.tenantId === currentUser?.tenantId);

    if (data.length > 0) {
      const fields = Object.keys(data[0]).filter(
        (field) => field !== 'tenantId' && field !== 'uid' && field !== 'id'
      )
      const csvContent = [fields.join(',')]

      data.forEach((row) => {
        const rowData = fields
          .map((field) => {
            return `"${row[field] || ''}"`
          })
          .join(',')
        csvContent.push(rowData)
      })

      const csvBlob = new Blob([csvContent.join('\n')], {type: 'text/csv;charset=utf-8'})
      saveAs(csvBlob, 'Activity_logs.csv')
    }
  }

  return (
    <>
      <PageTitle
        icon='Activity.svg'
        title='Activity Logs'
        desc={'Monitor all user activities within SecureSlate.'}
        // breadcrumbs={usersBreadcrumbs}
      >
        Data Room
      </PageTitle>
      <KTCard>
        <div className='card-header border-0 pt-6 h-100'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1 me-4'>
              <div className='me-4'>
                <DatePicker
                  selectsRange={true}
                  startDate={startDate || undefined}
                  endDate={endDate || undefined}
                  onChange={(update: [Date | null, Date | null]) => {
                    setDateRange(update) // Update dateRange state
                    if (update[0] && update[1]) {
                      setDateState({
                        startDate: update[0],
                        endDate: update[1],
                      }) // Update dateState for filtering
                    } else {
                      setDateState({
                        startDate: null,
                        endDate: null,
                      })
                    }
                  }}
                  customInput={
                    <input
                      style={{borderColor: '#C5C5C5', height: '40px'}}
                      className='form-control'
                    />
                  }
                  isClearable={true}
                  placeholderText='Pick date range'
                  className='form-control'
                  wrapperClassName='custom-datepicker'
                  clearButtonStyle={{
                    backgroundColor: '#1CBB8D',
                    color: '#fff',
                  }}
                />
                <style>
                  {`

    .react-datepicker__close-icon::before {
      color: #1CBB8D;
      font-size: 1.5rem; 
    }
    .react-datepicker__close-icon:hover::before {
      color: #218838; 
    }
    /* Change the selected day and hover colors */
    .react-datepicker__day--selected {
      background-color: #1CBB8D; 
      color: #fff; 
    }
    .react-datepicker__day:hover {
      background-color: #148363;
      color: #fff;
    }
  
   
  `}
                </style>
              </div>
              {/* <Flatpickr
                value={
                  dateState.startDate && dateState.endDate
                    ? [dateState.startDate, dateState.endDate]
                    : null
                }
                onChange={(dates) => {
                  if (dates.length === 2) {
                    setDateState({
                      startDate: dates[0],
                      endDate: dates[1],
                    })
                  } else if (dates.length === 0) {
                    setDateState({
                      startDate: null,
                      endDate: null,
                    })
                  }
                }}
                options={{
                  mode: 'range',
                  dateFormat: 'Y-m-d',
                  allowInput: true,
                  clear: true,
                }}
                className='form-control fs-6'
                placeholder='Select date range'
              /> */}
            </div>
          </div>
          <div className='card-toolbar'>
            <div className='d-flex align-items-center justify-content-end '>
              <span className='fs-6 fw-semibold mx-4'>Action:</span>
              <select
                id='status'
                className='form-select me-4'
                aria-label='Select Status'
                style={{width: '70%', height: '40px'}}
                value={selectedAction || ''}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  setSelectedAction(e.target.value || null)
                }
              >
                <option value='' selected>
                  {' '}
                  Show All
                </option>
                <option value='Asset Updated'>Asset Updated</option>
                <option value='Employee offboarded'>Employee offboarded</option>
                <option value='Policy updated'>Policy updated</option>
              </select>
              <span className='fs-6 fw-semibold mx-4'>Location:</span>
              <select
                id='status'
                className='form-select me-4'
                aria-label='Select Status'
                style={{width: '70%', height: '40px'}}
                value={selectedLocation || ''}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  setSelectedLocation(e.target.value || null)
                }
              >
                <option value='' selected>
                  Show All
                </option>

                <option value='Controls'>Controls</option>
                <option value='Tests'>Tests</option>
                <option value='Policies'>Policies</option>
                <option value='Employee'>Employee</option>
                <option value='Asset Management'>Asset Management</option>
                <option value='Data Room'>Data Room</option>
                <option value='Risk Management'>Risk Management</option>
                <option value='Vendor Risk'>Vendor Risk</option>
                <option value='Integrations'>Integrations</option>
              </select>
            </div>
            <button
              type='button'
              onClick={exportData}
              className='btn btn-sm fs-6'
              style={{height: '40px', background: '#1CBB8D', color: '#fff'}}
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#148363')
              }
              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#1CBB8D')
              }
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-download me-2 mb-1'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
                <path d='M7 11l5 5l5 -5' />
                <path d='M12 4l0 12' />
              </svg>
              Export
            </button>
          </div>
        </div>
        <div className='card-body py-6'>
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-hover'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold fs-7' style={{color: '#5A5A5A'}}>
                  <th className='min-w-150px'>DATE</th>

                  <th className='min-w-150px'>ACTION</th>
                  <th className='min-w-150px'>LOCATION</th>
                  <th className='min-w-400px'>DETAILS</th>
                  <th className='min-w-200px'>USER</th>
                </tr>
              </thead>

              {paginatedData && filteredActivities.length > 0 ? (
                <tbody>
                  {paginatedData
                    .sort((a, b) => b.timestamp - a.timestamp)
                    .map((item) => (
                      <tr key={item.id}>
                        <td>
                          {' '}
                          <div className='d-flex flex-column'>
                            <span className='fw-semibold text-dark fs-6'>
                              {formatDate(item.date)}
                            </span>
                            <span className='fs-7' style={{color: '#5A5A5A'}}>
                              {formatTimestamp(item.timestamp)}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center fw-semibold'>{item.action}</div>
                        </td>

                        <td>
                          <div
                            className='badge fs-7 text-dark text-bold m-1'
                            style={{background: '#F6F6F6'}}
                          >
                            {item.target}
                          </div>
                        </td>

                        <td>
                          <div className='fs-6 fw-semibold'>{item.message}</div>
                        </td>

                        <td>
                          <div className='d-flex align-items-center py-0'>
                            {item.username ? (
                              <>
                                <div className='symbol symbol-circle symbol-35px me-3'>
                                  <span
                                    className='symbol-label fs-7 fw-bold'
                                    style={{backgroundColor: '#DAF9EF'}}
                                  >
                                    {item.username
                                      ? (() => {
                                          const nameParts = item.username.split(' ')
                                          if (nameParts.length > 1) {
                                            return `${nameParts[0]
                                              .charAt(0)
                                              .toUpperCase()}${nameParts[1]
                                              .charAt(0)
                                              .toUpperCase()}`
                                          } else {
                                            return `${nameParts[0]
                                              .charAt(0)
                                              .toUpperCase()}${nameParts[0]
                                              .charAt(1)
                                              .toUpperCase()}`
                                          }
                                        })()
                                      : ''}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>-</>
                            )}

                            <div className='d-flex flex-column'>
                              <a href='#' className='fw-semibold text-dark text-hover-primary fs-6'>
                                {item.username}
                              </a>
                              <span className='fs-7' style={{color: '#5A5A5A'}}>
                                {item.email}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              ) : (
                <>
                  <tbody>
                    <tr>
                      <td colSpan={10} className='text-center'>
                        {!showEmptyState ? (
                          // <td colSpan={10}>
                          <div
                            className='d-flex justify-content-center align-items-center text-center'
                            style={{height: '500px'}}
                          >
                            <TestListLoading />
                          </div>
                        ) : (
                          <div className='m-20'>
                            <img src='/media/buttons/emptystate.svg' alt='' />
                            <div className=' fs-5'>
                              <p style={{fontWeight: 'bold'}}>No filter results</p>
                            </div>
                            <p>Maybe try a different filter or reset filters</p>
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </>
              )}

              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        </div>
      </KTCard>
      {filteredActivities.length >= 50 && (
        <div className='d-flex justify-content-between'>
          <div
            id=''
            className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar'
          >
            <div>
              <select
                name='kt_permissions_table_length'
                aria-controls='kt_permissions_table'
                className='form-select'
                id='dt-length-1'
                value={selectedNumber}
                onChange={(e) => setSelectedNumber(e.target.value)}
              >
                {/* <option value='20'>20</option> */}
                <option value='50'>50</option>
                <option value='100'>100</option>
                <option value='150'>150</option>
              </select>
              {/* <label for='dt-length-1'></label> */}
            </div>
          </div>

          <div className='d-flex justify-content-end my-10'>
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ActivityLog
