/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import { useLocation, useNavigate} from 'react-router-dom'
import {collection, onSnapshot} from 'firebase/firestore'
import db from '../../../services/firebase'
import vendorServices from './services/vendor.services'
import {useAuth} from '../../modules/auth'
import Swal from 'sweetalert2'
import {Helmet} from 'react-helmet'
import {DomainListToolbar} from './components/header/DomainListToolbar'

type Props = {
  className: string
}
interface UserData {
  id: string
  companyName: string
  riskLevel: string
  imgUrl: string
  archive: boolean
  uid: string
  tenantId: string
}
const Domain: React.FC<Props> = ({className}) => {
  const [users, setUsers] = useState<UserData[]>([])
  const [loading, setLoading] = useState(false)
  const {currentUser} = useAuth()
  const [activeTab, setActiveTab] = useState('details')
  const location = useLocation()
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredItems, setFilteredItems] = useState<UserData[]>([])
  const [searchLength, setSearchLength] = useState(false)
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [isDropdownVisible, setDropdownVisible] = useState(false)

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    getVendor()
  }, [])

  useEffect(() => {
    // Fetch control data when the component mounts
    if (location.pathname.includes('details')) {
      setActiveTab('details')
    } else if (location.pathname.includes('documents')) {
      setActiveTab('documents')
    }
  })
  useEffect(() => {
    setFilteredItems(users)
  }, [users])

  const toggleDropdown = () => {
    // console.log('clicked')
    setDropdownVisible(!isDropdownVisible)
  }

  const getVendor = () => {
    try {
      const unsub = onSnapshot(
        collection(db, 'vendorManagement'),
        (snapshot) => {
          let list: UserData[] = []
          snapshot.docs.forEach((doc) => {
            list.push({id: doc.id, ...doc.data()} as UserData)
          })

          setUsers(list)
          setLoading(false)
        },
        (error) => {
          console.log(error)
        }
      )

      return unsub // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting vendors:', error)
    }
  }

  const handleDelete = async (item) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${item.companyName} Vendor`,
        text: `Are you sure you want to remove ${item.companyName} Vendor ? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        // User confirmed, proceed with the deletion
        await vendorServices.deleteVendor(item.id)
        // getVen()
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: ` "${item.companyName}" has been deleted.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      console.error('Error deleting policy:', error)
      // Handle the error or show an error message to the user
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the policy.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      // setDropdownVisible(false)
    }
  }
  const usersWithSameTenantId = users.filter((user) => user.tenantId === currentUser?.tenantId)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    // Assuming policy is your original data array
    if (searchTerm === '') {
      // If the search term is empty, reset to the original policies
      setFilteredItems(users)
      setSearchLength(false)
    } else {
      const filteredItems = users.filter((user) =>
        user.companyName.toLowerCase().includes(searchTerm)
      )
      setFilteredItems(filteredItems)
      setSearchLength(filteredItems.length === 0)
    }
  }
  // console.log(users)
  return (
    <>
      {/* begin::Header */}
      <Helmet>
        <title>Secureslate | Domains</title>
      </Helmet>

      {/* end::Header */}
      <div className='card-header border-0 pt-6 h-100'>
        <div className='card-title'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='Search..'
              value={searchTerm}
              onChange={handleInputChange}
            />
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          {/* begin::Group actions */}
          <DomainListToolbar />
          {/* end::Group actions */}
        </div>
      </div>
      {/* begin::Body */}
      <div className='card-body py-3 '>
        {/* begin::Table container */}
        <div className='table-responsive' style={{height: '55vh', overflowY: 'auto'}}>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted border-bottom border-dotted'>
                <th className='ps-4 min-w-300px rounded-start'>DOMAIN NAME</th>
                <th className='min-w-300px'>LOCATION</th>
                <th className='min-w-100px'>SEVERITY</th>
                <th className='min-w-100px'>OPEN</th>
                <th className='min-w-100px'>IGNORED</th>
                <th className='min-w-100px'>LAST SCAN</th>
                <th className='min-w-50px text-end rounded-end'></th>
              </tr>
            </thead>

            {/* {filteredPolicies &&
              filteredPolicies.length > 0 &&
              usersWithSameTenantId.length > 0 ? (
                filteredPolicies
                  .slice() // Create a copy of the array to avoid modifying the original array
                  .sort((a, b) => {
                    const policyA = a.policy || ''; // Default to empty string if undefined
                    const policyB = b.policy || ''; // Default to empty string if undefined
                    return policyA.localeCompare(policyB);
                  })
                  .filter(
                    (user) => user.tenantId === currentUser?.tenantId 
            
                  )

                  .map((policyData, index) => {
                    return ( */}
            <tbody>
              <tr className=' border-bottom border-dotted'>
                <td>
                  <div className='d-flex align-items-center mx-5'>https://www.test-app.com</div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>demo-app-1</div>
                </td>
                <td>
                  <div className='d-flex align-items-center text-danger'>
                    {/* <div className='d-inline-block align-middle me-1 mb-2'>
                      <img src='/media/logos/Warning.svg' alt='' style={{width: '20px'}} />
                    </div> */}
                    <i className='bi bi-exclamation-triangle mx-1 text-danger'></i>
                    Critical
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>3</div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>0</div>
                </td>

                <td>
                  <div className='d-flex align-items-center'>Not scan yet</div>
                </td>
                <td>
                  <div className='dropdown mx-2'>
                    <button
                      className='btn btn-secondary border-0 p-0 m-2'
                      type='button'
                      id='dropdownMenu2'
                      data-bs-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      <img
                        src='/media/buttons/Options.svg'
                        alt='Options'
                        onClick={toggleDropdown}
                      />
                    </button>
                    <ul className='dropdown-menu' aria-labelledby='dropdownMenu2'>
                      <li>
                        <button
                          className='dropdown-item'
                          type='button'
                          // onClick={() => handleViewClick(policyData)}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-arrow-right text-muted mx-1'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M5 12l14 0' />
                            <path d='M13 18l6 -6' />
                            <path d='M13 6l6 6' />
                          </svg>
                          Start scan
                        </button>
                      </li>
                      <li>
                        <button
                          className='dropdown-item'
                          type='button'
                          // onClick={(e) => handleDownloadClick(policyData)}
                        >
                     
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='20'
                            height='20'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-edit text-muted mx-1'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1' />
                            <path d='M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z' />
                            <path d='M16 5l3 3' />
                          </svg>
                          Edit configuration
                        </button>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
            {/* )
                  })
              ) : ( */}
            {/* <tbody>
                  {searchLength === true ? (
                    <tr>
                      <td colSpan={6} className='text-center'>
                        <div className='m-20'>
                          <img src='/media/buttons/emptystate.svg' alt='' />
                          <div className='m-5 fs-5'>
                            <p style={{fontWeight: 'bold'}}>No polices Found</p>
                          </div>
                          <p className=''>
                            We couldn't find any policies that match your search. Maybe try a
                            different search!
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan={6} className='text-center'>
                        {!showEmptyState  ? (
                        // <PolicyListLoading/>
                        <></>
                      ) : (
                          <div className='m-20'>
                            <img src='/media/buttons/emptystate.svg' alt='' />
                            <div className='m-5 fs-5'>
                              <p style={{fontWeight: 'bold'}}>No polices Added</p>
                            </div>
                            <p>
                              Add the polices that all employees within your organization are
                              required to adhere to.
                            </p>
                          </div>
                        )}
                      </td>
                    </tr>
                  )}
                </tbody> */}
            {/* )} */}

            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </>
  )
}

export {Domain}
