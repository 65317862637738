/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import {useAuth} from '../../../../modules/auth'
import {addDoc, collection, doc, onSnapshot, updateDoc, deleteDoc} from 'firebase/firestore'
import db from '../../../../../services/firebase'
import 'flatpickr/dist/themes/material_green.css'
import {TestListLoading} from '../../../tests/components/loading/TestListLoading'
import EditTask from '../../../tasks/components/EditTask'
import AddTask from '../../../tasks/components/AddTask'

type Props = {
  className: string
}

const TaskList: React.FC<Props> = ({className}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [owners, setOwners] = useState<any[]>([])
  const {currentUser} = useAuth()
  const [owner, setOwner] = useState('')
  const [taskName, setTaskName] = useState('')
  const [priority, setPriority] = useState('')
  const [dueDate, setDueDate] = useState('')
  const [tasks, setTasks] = useState<any[]>([])
  const [controlTask, setControlTask] = useState('')
  const [testTask, setTestTask] = useState('')
  const [sortBy, setSortBy] = useState('') // Track currently sorted column
  const [sortDirection, setSortDirection] = useState('asc')
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [isModalOpen2, setIsModalOpen2] = useState(false)
  const [taskID, setTaskID] = useState('')
  const [checkedTasks, setCheckedTasks] = useState<string[]>([])
  const [activeTab, setActiveTab] = useState('all')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [filteredTasks, setFilteredTasks] = useState<any[]>([])
  const [subTab, setSubTab] = useState('open')

  // useEffect(() => {
  //   const filterTasks = () => {
  //     const filtered = tasks.filter((task) => {
  //       switch (activeTab) {
  //         case 'all':
  //           return true
  //         case 'myTasks':
  //           return task.owner === currentUser?.userName
  //         case 'unassigned':
  //           return task.owner === null || task.owner === ''
  //         case 'completed':
  //           return task.status === 'Completed' ? 'Completed' : ''
  //         default:
  //           return true
  //       }
  //     })
  //     setShowEmptyState(filtered.length === 0)
  //     setFilteredTasks(filtered)
  //   }

  //   filterTasks()
  // }, [tasks, activeTab])

  useEffect(() => {
    const filterTasks = () => {
      const filtered = tasks.filter((task) => {
        const matchesMainTab =
          activeTab === 'all' ||
          (activeTab === 'myTasks' && task.owner === currentUser?.userName) ||
          (activeTab === 'unassigned' && (!task.owner || task.owner === ''))

        const matchesSubTab =
          (subTab === 'open' && task.status !== 'Completed') ||
          (subTab === 'completed' && task.status === 'Completed')

        return matchesMainTab && matchesSubTab
      })

      setShowEmptyState(filtered.length === 0)
      setFilteredTasks(filtered)
    }

    filterTasks()
  }, [tasks, activeTab, subTab, currentUser])

  useEffect(() => {
    getActivities()
  }, [])

  const getActivities = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const controlsCollectionRef = collection(tenantRef, 'tasklists')
      const unsubscribe = onSnapshot(controlsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setTasks(controlsData)
        // setShowEmptyState(false)
        //  setFilteredTasks(controlsData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setOwners(risksData)
    })

    return unsubscribe
  }, [])

  const handleClose = () => {
    setShowModal(false)
    setLoading(false)
  }

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible)
  }

  const priorityOrder = {
    Low: 1,
    Medium: 2,
    High: 3,
  }

  const handleSort = (column) => {
    const newSortDirection = column === sortBy && sortDirection === 'asc' ? 'desc' : 'asc'
    setSortBy(column)
    setSortDirection(newSortDirection)
  }

  const renderSortIcon = (column) => {
    if (column === sortBy) {
      return (
        <span
          className={`ms-1 icon-xxs text-muted ${
            sortDirection === 'asc' ? 'bi bi-arrow-up' : 'bi bi-arrow-down'
          }`}
        />
      )
    }
    return null
  }

  const handleMarkAsComplete = async (taskId) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const taskRef = doc(db, 'tenants', tenantId, 'tasklists', taskId)
      await updateDoc(taskRef, {status: 'Completed'})
      getActivities()

      const updatedTasks = tasks.filter((task) => task.id !== taskId)
      setTasks(updatedTasks)
    } catch (error) {
      console.error('Error updating task status:', error)
    }
  }
  const handleMarkAsIncomplete = async (taskId) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const taskRef = doc(db, 'tenants', tenantId, 'tasklists', taskId)
      await updateDoc(taskRef, {status: 'Incomplete'})
      getActivities()
    } catch (error) {
      console.error('Error updating task status:', error)
    }
  }

  const handleRemoveTask = async (taskId, task) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${task.task_name}`,
        text: `Are you sure you want to remove '${task.task_name}'? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })
      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const taskRef = doc(db, 'tenants', tenantId, 'tasklists', taskId)
        await deleteDoc(taskRef)
        getActivities()
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: `'${task.task_name}' have been removed.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while removing the selected items.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  const handleTaskCheckboxChange = (taskId: string) => {
    setCheckedTasks((prev) => {
      if (prev.includes(taskId)) {
        return prev.filter((id) => id !== taskId)
      } else {
        return [...prev, taskId]
      }
    })
  }

  const formatDate = (dateString: string): string => {
    try {
      const [year, month, day] = dateString.split('-')
      const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))

      const formattedDay = parseInt(day)
      const formattedMonth = date.toLocaleString('default', {month: 'long'})
      const formattedYear = year

      return `${formattedDay} ${formattedMonth}, ${formattedYear}`
    } catch (error) {
      return ''
    }
  }

  const handleSelectAllChange = () => {
    if (checkedTasks.length === tasks.length) {
      setCheckedTasks([])
    } else {
      setCheckedTasks(tasks.map((task) => task.id))
    }
  }

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    const taskData = {
      taskName,
      priority,
      owner,
    }
    if (taskName === '' || priority === '' || owner === '') {
      return
    }
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1
    const day = currentDate.getDate()

    const todaysDate = `${month}/${day}/${year}`

    if (currentUser) {
      const tenantRef = doc(db, 'tenants', currentUser.tenantId!)
      const activitiesRef = collection(tenantRef, 'tasklists')
      await addDoc(activitiesRef, {
        taskName: taskName,
        priority: priority,
        owner: owner,
        duedate: dueDate,
        control: controlTask,
        test: testTask,
        tenantId: currentUser?.tenantId,
        username: currentUser?.userName,
        date: todaysDate,
        checked: false,
      })

      setTaskName('')
      setPriority('')
      setOwner('')
      setControlTask('')
      setTestTask('')
      handleClose()
      getActivities()
    }
  }

  const handleOpenModal2 = (id) => {
    setIsModalOpen2(true)
    setTaskID(id)
  }
  const handleCloseModal2 = () => {
    setIsModalOpen2(false)
  }

  const handleMarkSelectedAsComplete = async () => {
    const tenantId = currentUser?.tenantId || ''

    for (const taskId of checkedTasks) {
      try {
        const taskRef = doc(db, 'tenants', tenantId, 'tasklists', taskId)
        await updateDoc(taskRef, {status: 'Completed'})
      } catch (error) {
        console.error(`Error updating task ${taskId} status:`, error)
      }
    }

    getActivities()

    setCheckedTasks([])
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const getEmptyStateMessage = () => {
    switch (activeTab) {
      case 'all':
        return subTab === 'open'
          ? 'No open tasks found. All tasks are completed or try adding a new task.'
          : 'No completed tasks found. Keep working on your open tasks!'
      case 'myTasks':
        return subTab === 'open'
          ? "You don't have any open tasks assigned to you. Check back later or ask for new assignments."
          : "You haven't completed any tasks yet. Keep working on your open tasks!"
      case 'unassigned':
        return subTab === 'open'
          ? 'There are no open unassigned tasks. All tasks are currently assigned or completed.'
          : 'There are no completed unassigned tasks. This is expected as completed tasks are typically assigned.'
      default:
        return subTab === 'open'
          ? 'No open tasks found. Try adding a new task or check back later.'
          : 'No completed tasks found. Keep working on open tasks!'
    }
  }

  const getTaskCountMessage = () => {
    const openTasks = filteredTasks.filter((data) => data.status !== 'Completed')
    const completedTasks = filteredTasks.filter((data) => data.status === 'Completed')

    const openCount = openTasks.length
    const completedCount = completedTasks.length

    let message = ''

    switch (activeTab) {
      case 'all':
        message =
          subTab === 'open' ? `${openCount} tasks pending` : `${completedCount} tasks completed`
        break
      case 'myTasks':
        message =
          subTab === 'open'
            ? `${openCount} of your tasks pending`
            : `${completedCount} of your tasks completed`
        break
      case 'unassigned':
        message =
          subTab === 'open'
            ? `${openCount} unassigned tasks pending`
            : `${completedCount} unassigned tasks completed`
        break
      default:
        message =
          subTab === 'open' ? `${openCount} tasks pending` : `${completedCount} tasks completed`
    }

    return message
  }

  const handleSubTabClick = (tab) => {
    setSubTab(tab)
  }

  // Update handleTabClick to reset subTab
  const handleTabClick = (tab) => {
    setActiveTab(tab)
    setSubTab('open')
  }

  return (
    <>
      <div className='card' id='kt_timeline_widget_2_card'>
        {/* // h-xl-100 */}
        <div
          className='card-header border-0 pt-5'
          style={{position: 'sticky', top: 0, zIndex: 1, background: 'white'}}
        >
          <div className='d-flex justify-content-between align-items-center'>
            <h3 className='card-title align-items-start flex-column me-10'>
              <span className='card-label fw-bold fs-4 mb-1'>Task Lists</span>
              <span className='text-muted mt-1 fw-semibold fs-7'>{getTaskCountMessage()}</span>
            </h3>
            <div
              className='btn-group w-30 fs-6 '
              role='group'
              aria-label='Task navigation'
              style={{border: '1px solid #C5C5C5', borderRadius: '8px'}}
            >
              <button
                type='button'
                //  className='btn btn-outline-primary text-white active fw-bold'
                className={`btn btn-outline-primary   ${
                  activeTab === 'all' ? 'active text-white' : ''
                }`}
                onClick={() => handleTabClick('all')}
                style={{
                  backgroundColor: activeTab === 'all' ? '#1CBB8D' : 'transparent',
                  borderColor: activeTab === 'all' ? '#1CBB8D' : '#007bff',
                  color: activeTab === 'all' ? 'white' : '#000',
                }}
                onMouseEnter={(e) => {
                  if (activeTab !== 'all') {
                    e.currentTarget.style.backgroundColor = '#f6f6f6'
                    e.currentTarget.style.color = '#000'
                  }
                }}
                onMouseLeave={(e) => {
                  if (activeTab !== 'all') {
                    e.currentTarget.style.backgroundColor = 'transparent'
                    e.currentTarget.style.color = '#000'
                  }
                }}
              >
                All
              </button>
              <button
                type='button'
                // className='btn btn-outline-secondary fw-bold'
                className={`btn btn-outline-primary   ${
                  activeTab === 'myTasks' ? 'active text-white' : ''
                }`}
                onClick={() => handleTabClick('myTasks')}
                style={{
                  backgroundColor: activeTab === 'myTasks' ? '#1CBB8D' : 'transparent',
                  borderColor: activeTab === 'myTasks' ? '#1CBB8D' : '#007bff',
                  color: activeTab === 'myTasks' ? 'white' : '#000',
                }}
                onMouseEnter={(e) => {
                  if (activeTab !== 'myTasks') {
                    e.currentTarget.style.backgroundColor = '#f6f6f6'
                    e.currentTarget.style.color = '#000'
                  }
                }}
                onMouseLeave={(e) => {
                  if (activeTab !== 'myTasks') {
                    e.currentTarget.style.backgroundColor = 'transparent'
                    e.currentTarget.style.color = '#000'
                  }
                }}
              >
                My Tasks
              </button>
              <button
                type='button'
                // className='btn btn-outline-secondary fw-bold'
                className={`btn btn-outline-primary   ${
                  activeTab === 'unassigned' ? 'active text-white' : ''
                }`}
                onClick={() => handleTabClick('unassigned')}
                style={{
                  backgroundColor: activeTab === 'unassigned' ? '#1CBB8D' : 'transparent',
                  borderColor: activeTab === 'unassigned' ? '#1CBB8D' : '#007bff',
                  color: activeTab === 'unassigned' ? 'white' : '#000',
                }}
                onMouseEnter={(e) => {
                  if (activeTab !== 'unassigned') {
                    e.currentTarget.style.backgroundColor = '#f6f6f6'
                    e.currentTarget.style.color = '#000'
                  }
                }}
                onMouseLeave={(e) => {
                  if (activeTab !== 'unassigned') {
                    e.currentTarget.style.backgroundColor = 'transparent'
                    e.currentTarget.style.color = '#000'
                  }
                }}
              >
                Unassigned
              </button>
            </div>
          </div>
          {checkedTasks.length > 0 ? (
            <>
              <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Click to mark as complete'
              >
                <button
                  className='btn btn-sm'
                  onClick={handleMarkSelectedAsComplete}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                  style={{
                    backgroundColor: '#1CBB8D',
                    color: '#fff',
                    width: '140px',
                    height: '40px',
                    borderRadius: '4px',

                    transition: 'background-color 0.3s',
                  }}
                >
                  Mark as complete
                </button>
              </div>
            </>
          ) : (
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='Click to add a task'
            >
              <button
                className='btn btn-sm fs-6'
                onClick={handleOpenModal}
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#148363')
                }
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                }
                // disabled={activities.length === 0}
                style={{
                  backgroundColor: '#1CBB8D',
                  color: '#fff',
                  height: '40px',
                  borderRadius: '4px',

                  transition: 'background-color 0.3s',
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='18'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  className='icon icon-tabler icons-tabler-outline icon-tabler-plus mx-1'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M12 5l0 14' />
                  <path d='M5 12l14 0' />
                </svg>
                Add Task
              </button>
            </div>
          )}
        </div>
        {/* begin::Header */}

        <div className='card-header position-relative py-0 border-bottom-2'>
          {/* begin::Nav */}

          <ul className='nav nav-stretch nav-pills nav-pills-custom d-flex mt-3' role='tablist'>
            {/* begin::Item */}
            {/* <li className='nav-item p-0 ms-0 me-8' role='presentation'>
             <a
                data-bs-toggle='pill'
                href='#kt_timeline_widget_2_tab_1'
                aria-selected='true'
                role='tab'
                className={`nav-link btn btn-color-muted ${
                  activeTab === 'all' ? 'active' : ''
                } px-0`}
                onClick={() => handleTabClick('all')}
              >
             
                <span className='nav-text fw-semibold fs-4 mb-3'>All</span>
         
                <span className='bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded'></span>
         
              </a>
       
            </li> */}
            {/* end::Item */}

            {/* begin::Item */}
            {/* <li className='nav-item p-0 ms-0 me-8' role='presentation'>
           
              <a
                data-bs-toggle='pill'
                href='#kt_timeline_widget_2_tab_2'
                aria-selected='false'
                tabIndex={-1}
                role='tab'
                className={`nav-link btn btn-color-muted ${
                  activeTab === 'myTasks' ? 'active' : ''
                } px-0`}
                onClick={() => handleTabClick('myTasks')}
              >
           
                <span className='nav-text fw-semibold fs-4 mb-3'>My Tasks</span>
       
                <span className='bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded'></span>
             
              </a>
           
            </li> */}
            {/* end::Item */}

            {/* begin::Item */}
            {/* <li className='nav-item p-0 ms-0 me-8' role='presentation'>
      
              <a
                data-bs-toggle='pill'
                href='#kt_timeline_widget_2_tab_2'
                aria-selected='false'
                tabIndex={-1}
                role='tab'
                className={`nav-link btn btn-color-muted ${
                  activeTab === 'unassigned' ? 'active' : ''
                } px-0`}
                onClick={() => handleTabClick('unassigned')}
              >
           
                <span className='nav-text fw-semibold fs-4 mb-3'>Unassigned</span>
         
                <span className='bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded'></span>
             
              </a>
         
            </li> */}
            {/* end::Item */}

            {/* begin::Item */}
            <li className='nav-item p-0 ms-0 me-8' role='presentation'>
              <a
                data-bs-toggle='pill'
                href='#kt_timeline_widget_2_tab_2'
                aria-selected='false'
                tabIndex={-1}
                role='tab'
                className={`nav-link btn btn-color-muted ${subTab === 'open' ? 'active' : ''} px-0`}
                onClick={() => handleSubTabClick('open')}
              >
                <span className='nav-text fw-semibold fs-6 mb-2'>Open</span>

                <span className='bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded'></span>
              </a>
            </li>
            {/* end::Item */}

            {/* begin::Item */}
            <li className='nav-item p-0 ms-0' role='presentation'>
              {/* begin::Link */}
              <a
                data-bs-toggle='pill'
                href='#kt_timeline_widget_2_tab_3'
                aria-selected='false'
                tabIndex={-1}
                role='tab'
                className={`nav-link btn btn-color-muted ${
                  subTab === 'completed' ? 'active' : ''
                } px-0`}
                onClick={() => handleSubTabClick('completed')}
              >
                {/* begin::Subtitle */}
                <span className='nav-text fw-semibold fs-6 mb-2'>Completed</span>
                {/* end::Subtitle */}

                {/* begin::Bullet */}
                <span className='bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded'></span>
                {/* end::Bullet */}
              </a>
              {/* end::Link */}
            </li>
            {/* end::Item */}
          </ul>
          {/* end::Nav */}
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body'>
          {/* begin::Tab Content */}
          <div className='tab-content'>
            {/* begin::Tap pane */}
            {/* {filteredTasks.length > 0 ? ( */}
            <div className='tab-pane fade show active' role='tabpanel'>
              {/* begin::Table container */}
              <div
                className='table-responsive'
                style={{minHeight: '400px', maxHeight: '600px', overflowY: 'auto'}}
              >
                {/* begin::Table */}
                <table className='table align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold' style={{color: '#5A5A5A'}}>
                      {/* <th className='w-25px'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value='1'
                            data-kt-check='true'
                            data-kt-check-target='.widget-9-check'
                            checked={checkedTasks.length === tasks.length}
                            onChange={handleSelectAllChange}
                          />
                        </div>
                      </th> */}
                      <th className='min-w-350px' onClick={() => handleSort('task_mame')}>
                        Task name {renderSortIcon('task_name')}
                      </th>
                      <th className='min-w-100px' onClick={() => handleSort('priority')}>
                        Priority {renderSortIcon('priority')}
                      </th>
                      <th className='min-w-200px' onClick={() => handleSort('owner')}>
                        Assigned To {renderSortIcon('owner')}
                      </th>
                      <th className='min-w-150px' onClick={() => handleSort('duedate')}>
                        Due Date {renderSortIcon('duedate')}
                      </th>
                      <th className='min-w-150px' onClick={() => handleSort('status')}>
                        Status {renderSortIcon('status')}
                      </th>
                      <th className='min-w-50px text-end'></th>
                    </tr>
                  </thead>
                  {/* end::Table head */}

                  {/* begin::Table body */}

                  <tbody>
                    {filteredTasks.length > 0 ? (
                      filteredTasks
                        .sort((a, b) => {
                          const key = sortBy
                          const direction = sortDirection === 'asc' ? 1 : -1

                          // Special sorting for priority
                          if (key === 'priority') {
                            return direction * (priorityOrder[a[key]] - priorityOrder[b[key]])
                          }

                          if (key === 'duedate') {
                            // Special case for date sorting
                            const dateA = new Date(a[key]).getTime()
                            const dateB = new Date(b[key]).getTime()
                            return (dateA - dateB) * direction
                          } else if (a[key] < b[key]) {
                            return -1 * direction
                          } else if (a[key] > b[key]) {
                            return 1 * direction
                          } else {
                            return 0
                          }
                        })
                        .map((task) => (
                          <tr key={task.id}>
                            {/* <td>
                          
                            </td> */}

                            <td>
                              <div className='d-flex '>
                                <div className='form-check form-check-sm form-check-custom form-check-solid me-4'>
                                  <input
                                    className='form-check-input widget-9-check rounded-circle'
                                    type='checkbox'
                                    // checked={checkedTasks.includes(task.id)}
                                    // onChange={() => handleTaskCheckboxChange(task.id)}
                                    checked={task.status === 'Completed'}
                                    onChange={() => {
                                      if (task.status === 'Completed') {
                                        handleMarkAsIncomplete(task.id)
                                      } else {
                                        handleMarkAsComplete(task.id)
                                      }
                                    }}
                                  />
                                </div>
                                <div
                                  className='text-dark fw-bold text-hover-primary d-block fs-6'
                                  style={{cursor: 'pointer'}}
                                  onClick={() => handleOpenModal2(task.id)}
                                >
                                  {task.task_name}
                                </div>
                              </div>
                            </td>
                            <td>
                              <a
                                href='#'
                                className={`badge fs-7 ${
                                  task.priority === 'High'
                                    ? 'badge-danger'
                                    : task.priority === 'Medium'
                                    ? 'badge-warning'
                                    : 'badge-primary'
                                }`}
                                style={{
                                  color:
                                    task.priority === 'High'
                                      ? '#EE3043'
                                      : task.priority === 'Medium'
                                      ? '#FFA800'
                                      : '#1CBB8D',
                                  background:
                                    task.priority === 'High'
                                      ? '#FFF5F8'
                                      : task.priority === 'Medium'
                                      ? '#FFF8DD'
                                      : '#E8F8F4',
                                }}
                              >
                                {task.priority}
                              </a>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                {task.owner ? (
                                  <>
                                    <div className='symbol symbol-35px me-5'>
                                      <span
                                        className='symbol-label fs-7 fw-bold'
                                        style={{backgroundColor: '#DAF9EF'}}
                                      >
                                        {task.owner
                                          ? (() => {
                                              const nameParts = task.owner.split(' ')
                                              if (nameParts.length > 1) {
                                                return `${nameParts[0]
                                                  .charAt(0)
                                                  .toUpperCase()}${nameParts[1]
                                                  .charAt(0)
                                                  .toUpperCase()}`
                                              } else {
                                                return `${nameParts[0]
                                                  .charAt(0)
                                                  .toUpperCase()}${nameParts[0]
                                                  .charAt(1)
                                                  .toUpperCase()}`
                                              }
                                            })()
                                          : ''}
                                      </span>
                                    </div>
                                    <div className='d-flex justify-content-start flex-column'>
                                      <div className='text-dark fw-semibold text-hover-primary fs-6'>
                                        {task.owner}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <span>-</span>
                                  </>
                                )}
                              </div>
                            </td>

                            <td className='fs-6 fw-semibold'> {formatDate(task.duedate)}</td>
                            <td>
                              <span
                                className='badge fs-7'
                                style={{
                                  color: task.status === 'Completed' ? '#1CBB8D' : '#EE3043',
                                  background: task.status === 'Completed' ? '#E8F8F4' : '#FFF5F8',
                                }}
                              >
                                {' '}
                                {task.status}
                              </span>
                            </td>
                            <td className=''>
                              <div className='dropdown '>
                                <button
                                  className='btn btn-secondary border-0 p-0 '
                                  type='button'
                                  id='dropdownMenu2'
                                  data-bs-toggle='dropdown'
                                  aria-haspopup='true'
                                  aria-expanded='false'
                                >
                                  <img
                                    src='/media/buttons/Options.svg'
                                    alt='Options'
                                    onClick={toggleDropdown}
                                  />
                                </button>
                                <ul className='dropdown-menu' aria-labelledby='dropdownMenu2'>
                                  <li>
                                    <button
                                      type='button'
                                      onClick={() => handleOpenModal2(task.id)}
                                      className='dropdown-item text-hover-primary'
                                      style={{color: '#5A5A5A'}}
                                    >
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='18'
                                        height='18'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        stroke-width='2'
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                        className='icon icon-tabler icons-tabler-outline icon-tabler-edit mx-1 mb-1'
                                      >
                                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                        <path d='M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1' />
                                        <path d='M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z' />
                                        <path d='M16 5l3 3' />
                                      </svg>{' '}
                                      Edit task
                                    </button>
                                  </li>
                                  <li>
                                    {task.status === 'Completed' ? (
                                      <button
                                        className='dropdown-item text-hover-primary'
                                        style={{color: '#5A5A5A'}}
                                        type='button'
                                        onClick={() => handleMarkAsIncomplete(task.id)}
                                      >
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='18'
                                          height='18'
                                          viewBox='0 0 24 24'
                                          fill='none'
                                          stroke='currentColor'
                                          stroke-width='2'
                                          stroke-linecap='round'
                                          stroke-linejoin='round'
                                          className='icon icon-tabler icons-tabler-outline icon-tabler-x mx-1 mb-1'
                                        >
                                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                          <path d='M18 6l-12 12' />
                                          <path d='M6 6l12 12' />
                                        </svg>{' '}
                                        Mark as Incomplete
                                      </button>
                                    ) : (
                                      <button
                                        className='dropdown-item text-hover-primary'
                                        style={{color: '#5A5A5A'}}
                                        type='button'
                                        onClick={() => handleMarkAsComplete(task.id)}
                                      >
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='18'
                                          height='18'
                                          viewBox='0 0 24 24'
                                          fill='none'
                                          stroke='#5E6274'
                                          strokeWidth='2'
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          className='icon icon-tabler icons-tabler-outline icon-tabler-check mx-1 mb-1'
                                        >
                                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                          <path d='M5 12l5 5l10 -10' />
                                        </svg>{' '}
                                        Mark as complete
                                      </button>
                                    )}
                                  </li>
                                  <li>
                                    <button
                                      className='dropdown-item'
                                      style={{color: '#F15363'}}
                                      type='button'
                                      onClick={() => handleRemoveTask(task.id, task)}
                                    >
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='18'
                                        height='18'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='#F15363'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-1 mb-1'
                                      >
                                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                        <path d='M4 7l16 0' />
                                        <path d='M10 11l0 6' />
                                        <path d='M14 11l0 6' />
                                        <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                        <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                      </svg>{' '}
                                      Remove task
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        ))
                    ) : (
                      <>
                        <tr>
                          {!showEmptyState ? (
                            <td colSpan={10}>
                              <div
                                className='d-flex justify-content-center align-items-center text-center'
                                style={{height: '400px'}}
                              >
                                <TestListLoading />
                              </div>
                            </td>
                          ) : (
                            <td colSpan={10}>
                              <div
                                className='d-flex justify-content-center align-items-center text-center'
                                style={{height: '100%'}}
                              >
                                <div className='m-20'>
                                  <img src='/media/buttons/emptystate.svg' alt='' />

                                  <div className='mt-4 fs-6'>
                                    <p className='semibold'>{getEmptyStateMessage()}</p>
                                  </div>
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>
                      </>
                    )}
                  </tbody>

                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </div>
            {/* ) : (
              <>
                {!showEmptyState ? (
                  <div
                    className='d-flex justify-content-center align-items-center text-center'
                    style={{height: '400px'}}
                  >
                    <TestListLoading />
                  </div>
                ) : (
                  <div
                    className='d-flex justify-content-center align-items-center text-center'
                    style={{height: '100%'}}
                  >
                    <div className='m-20'>
                      <img src='/media/buttons/emptystate.svg' alt='' />
                      <div className=' fs-5'>
                        <p style={{fontWeight: 'bold'}}>No task added</p>
                      </div>
                      <p>
                        Maybe try adding{' '}
                        <a href='https://app.getsecureslate.com/tasks' style={{color: '#1CBB8D'}}>
                          {' '}
                          new task{' '}
                        </a>{' '}
                      </p>
                    </div>
                  </div>
                )}
              </>
            )} */}
            {/* end::Tap pane */}

            {/* Additional tabs would go here */}
          </div>
          {/* end::Tab Content */}
        </div>
        {/* end::Body */}
      </div>
      <AddTask isOpen={isModalOpen} onClose={handleCloseModal} />
      <EditTask id={taskID} isOpen={isModalOpen2} onClose={handleCloseModal2} />
    </>
  )
}
export {TaskList}
