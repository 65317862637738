import React, {FC, useEffect, useState} from 'react'
import { PageLink} from '../../../_metronic/layout/core'
import {RiskListHeader} from './components/header/RiskListHeader'
import {KTCard} from '../../../_metronic/helpers'
import {RiskManagement} from './table/RiskManagement'
import db from '../../../services/firebase'
import { useAuth } from '../../modules/auth'
import { collection, doc, onSnapshot } from 'firebase/firestore'
import { RiskPageTitle } from '../../../_metronic/layout/core/RiskPageData'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const RiskManagementWrapper: FC = () => {
  const [riskID, setRiskID] = useState('')
  const{currentUser} = useAuth() 
  const [totalRisk, setTotalRisk] = useState(0)
  const [activeTab, setActiveTab] = useState('overview')
  const getRiskIdHandler = (id) => {
    setRiskID(id)
  }

  
  
  
  useEffect(() => {
   
    try {
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'risks')
    
      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const risksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setTotalRisk(risksData.length)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }

  }, []);

  return (
    <>
      <RiskPageTitle
        icon='personnelIcon.svg'
        title='Risk Management'
        desc= {'Identify, assess, and mitigate risks to ensure compliance'}
        breadcrumbs={usersBreadcrumbs}
      >
        Risk Management
      </RiskPageTitle>

      <KTCard>
    
        <RiskListHeader />
        <RiskManagement className='mb-5 mb-xl-8' getRiskID={getRiskIdHandler} />

        {/* {riskID !== undefined && <EditRiskModal id={riskID} setRiskID={setRiskID} />} */}
        {/* <VendorRisk className='card-xl-stretch mb-xl-8' /> */}
      </KTCard>
    </>
  )
}

export default RiskManagementWrapper
