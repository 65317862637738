/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {addDoc, collection, doc, getDocs, onSnapshot, serverTimestamp} from 'firebase/firestore'
import db from '../../../../../services/firebase'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {RiskPageTitle} from '../../../../../_metronic/layout/core/RiskPageData'
import {useAuth} from '../../../../modules/auth'
import {AssetPageTitle} from '../../../../../_metronic/layout/core/AssetPageData'


type Props = {}

interface AssetTemplate {
  id: string
  assetSource: string
  category: string[]
  CIA: string[]
  recommendedControls: string[]
  is_repo: boolean
  // Add other properties as needed
}

const AssetRepository: React.FC<Props> = () => {
  const {currentUser} = useAuth()
  const [repoTemplates, setrepoTemplates] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('repositories')
  const location = useLocation()
  const [filteredItems, setFilteredItems] = useState<any[]>([])
  const [searchLength, setSearchLength] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const {id} = useParams()
  const [checkedItems, setCheckedItems] = useState<any[]>([])
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [totalFilterSelected, setTotalFilterSelected] = useState(0)
  const overlayRef = useRef<HTMLElement>(null)
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const navigate = useNavigate()
  const [selectedCategory, setSelectedCategory] = useState('')
  const [isFiltered, setIsFiltered] = useState(false)
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [tenantRisks, setTenantRisks] = useState<any[]>([])
  const [loadingStates, setLoadingStates] = useState<{[key: string]: boolean}>({})

  console.log(tenantRisks)

  useEffect(() => {
    // Fetch control data when the component mounts
    if (location.pathname.includes('repositories')) {
      setActiveTab('repositories')
    } else {
      setActiveTab('`asset-management')
    }
  }, [id])

  useEffect(() => {
    getRepositories()
    fetchTenantRisks()
  }, [])

  useEffect(() => {
    setFilteredItems(repoTemplates)
  }, [repoTemplates])

  useEffect(() => {
    if (!isFiltered) {
      setFilteredItems(repoTemplates)
    }
  }, [repoTemplates, isFiltered])

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [])

  const getRepositories = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'assets')

      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const assetData = snapshot.docs
          .map((item) => {
            const data = item.data() as AssetTemplate
            return {
              ...data,
              id: item.id,
              is_repo: data.is_repo,
            }
          })
          .filter((data) => data.is_repo === true)
        setrepoTemplates(assetData)
        const newDataCount = assetData.length

        setShowEmptyState(false)
      })
      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
      setShowEmptyState(false)
    }
  }

  console.log('repo', repoTemplates)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    let itemsToFilter = repoTemplates

    if (searchTerm === '') {
      setFilteredItems(itemsToFilter)
      setSearchLength(false)
    } else {
    }
  }
  const handleDeselectClick = () => {
    setCheckedItems([])
    setSelectAll(false)
  }

  const handleClick = (event) => {
    setShow(!show)
    setTarget(event.target)
  }
  const fetchTenantRisks = async () => {
    try {
      const tenantRef = doc(db, 'tenants', currentUser?.tenantId!)
      const risksSnapshot = await getDocs(collection(tenantRef, 'repositores'))
      const risks = risksSnapshot.docs.map((doc) => doc.data())
      setTenantRisks(risks)
    } catch (error) {
      console.error('Error fetching tenant risks:', error)
    }
  }

  // Add this new function
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target as Node) && show) {
        setShow(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [show])

  const handleSelectAllChange = (e) => {
    setSelectAll(e.target.checked)
    if (e.target.checked) {
      setCheckedItems(
        filteredItems
          // .filter((user) => user.tenantId === currentUser?.tenantId)
          .map((item) => item.id)
      )
    } else {
      setCheckedItems([])
    }
  }

  const handleCheckChange = (e) => {
    const {checked, value} = e.target
    setCheckedItems((prev) => (checked ? [...prev, value] : prev.filter((item) => item !== value)))
  }

  // const handleApplyFilter = (e: React.FormEvent) => {
  //   e.preventDefault()
  //   if (selectedCategory) {
  //     const filtered = repoTemplates.filter((risk: RiskTemplate) =>
  //       risk.category.includes(selectedCategory)
  //     )
  //     setFilteredItems(filtered)
  //     setIsFiltered(true)
  //     setShow(false)
  //     setTotalFilterSelected(1)
  //   }
  // }

  const handleResetFilter = () => {
    setSelectedCategory('All')
    setFilteredItems(repoTemplates)
    setIsFiltered(false)
    setShow(false)
    setTotalFilterSelected(0)
  }

  const handleApplyFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const category = e.target.value
    if (category === 'All') {
      handleResetFilter()
    } else {
      const filtered = repoTemplates.filter((risk: AssetTemplate) =>
        risk.category.includes(category)
      )
      setFilteredItems(filtered)
      setIsFiltered(true)
      setShow(false)
      setTotalFilterSelected(1)
    }
  }

  return (
    <>
      <AssetPageTitle
        icon='Icon-1.svg'
        title='Asset Management'
        desc={repoTemplates.length + ' repository'}
        // breadcrumbs={usersBreadcrumbs}
      >
        Asset Management
      </AssetPageTitle>

      <div className='card card-custom '>
        <div className='card-header border-0 pt-6 h-100'>
          <div className='card-title'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control  w-250px ps-14'
                placeholder='Search..'
                value={searchTerm}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className='card-toolbar'>
            {/* begin::Group actions */}
         
            <button
              type='button'
              // onClick={handleOpenModal}
              className='btn btn-sm fs-6'
              style={{height: '40px', background: '#1CBB8D', color: '#fff'}}
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#148363')
              }
              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#1CBB8D')
              }
            >
              <KTIcon iconName='plus' className='fs-2 text-white' />
              Add Repository
            </button>
            {/* end::Group actions */}
          </div>
        </div>
        <div className='card-body py-1'>{/* <VendorListSearchComponent /> */}</div>
        <div className='table-responsive ps-9'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold fs-7' style={{color: '#5A5A5A'}}>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={selectAll}
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                      onChange={handleSelectAllChange}
                    />
                  </div>
                </th>

                <th className='min-w-300px'>REPOSITORY NAME</th>
                <th className='min-w-200px'>PLATFORM</th>
                <th className='min-w-200px'>VISIBILITY</th>
                <th className='min-w-200px'>OWNER</th>
                <th className='min-w-150px'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {filteredItems && filteredItems.length > 0 ? (
                filteredItems.map((risk) => (
                  <tr key={risk.id}>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value={risk.id}
                          checked={selectAll || checkedItems.includes(risk.id)}
                          onChange={handleCheckChange}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center fw-bold fs-6 py-2'>
                        {risk.repo_name}
                      </div>
                    </td>

                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-semibold text-hover-primary fs-6'>
                            {risk.platform}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <div className='fw-semibold fs-6'>{risk.visibility}</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <div className='fw-semibold fs-6'>--</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        {tenantRisks.some((tenantRisk) => tenantRisk.id === risk.id) ? (
                          <button
                            className='btn btn-sm '
                            // onClick={() => handleAddRisk(risk)}
                            onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                              (e.currentTarget.style.backgroundColor = '#148363')
                            }
                            onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                              (e.currentTarget.style.backgroundColor = '#1CBB8D')
                            }
                            disabled
                            style={{
                              backgroundColor: '#E1E1E1',
                              color: '#909090',
                              width: '90px',
                              height: '40px',
                              borderRadius: '4px',
                              left: '615px',
                              top: '20px',
                              transition: 'background-color 0.3s',
                            }}
                          >
                            Added
                          </button>
                        ) : (
                          <>
                            {loadingStates[risk.id] ? (
                              <button
                                className='btn btn-sm'
                                // onClick={() => handleAddRisk(risk)}
                                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                  (e.currentTarget.style.backgroundColor = '#148363')
                                }
                                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                                }
                                // disabled={activities.length === 0}
                                style={{
                                  backgroundColor: '#1CBB8D',
                                  color: '#fff',
                                  width: '120px',
                                  height: '40px',
                                  borderRadius: '4px',
                                  left: '615px',
                                  top: '20px',
                                  transition: 'background-color 0.3s',
                                }}
                              >
                                <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                                Add Risk
                              </button>
                            ) : (
                              <button
                                className='btn btn-sm'
                                // onClick={() => handleAddRisk(risk)}
                                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                  (e.currentTarget.style.backgroundColor = '#148363')
                                }
                                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                                }
                                // disabled={activities.length === 0}
                                style={{
                                  backgroundColor: '#1CBB8D',
                                  color: '#fff',
                                  width: '90px',
                                  height: '40px',
                                  borderRadius: '4px',
                                  left: '615px',
                                  top: '20px',
                                  transition: 'background-color 0.3s',
                                }}
                              >
                                Add Risk
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <>
                  <tr style={{height: '100%'}}>
                    {searchLength ? (
                      <td colSpan={10}>
                        <div
                          className='d-flex justify-content-center align-items-center text-center'
                          style={{height: '100%'}}
                        >
                          <div className='m-20'>
                            <img src={toAbsoluteUrl('/media/emptystate/riskstate.svg')} alt='' />
                            <div className='m-5 fs-5'>
                              <p style={{fontWeight: 'bold'}}>No risk scenario found!</p>
                            </div>
                            <p>
                              We couldn't find any risk scenario that match your search. Maybe try a
                              different search!
                            </p>
                          </div>
                        </div>
                      </td>
                    ) : (
                      <td colSpan={10}>
                        <div
                          className='d-flex justify-content-center align-items-center text-center'
                          style={{height: '100%'}}
                        >
                          <div className='m-20'>
                            <img src={toAbsoluteUrl('/media/buttons/emptystate.svg')} alt='' />
                            <div className='m-5 fs-5'>
                              <p style={{fontWeight: 'bold'}}>No Repositories Found</p>
                            </div>
                            <p>
                              Add repositories that all employees within your organization are
                              required to adhere to.
                            </p>
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                </>
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
      </div>
      {/* begin::Table container */}

      {/* begin::Body */}
    </>
  )
}

export {AssetRepository}
