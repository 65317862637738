import {ChangeEvent, useEffect, useRef, useState} from 'react'
import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Logout, useAuth} from '../../../modules/auth'
import {getDownloadURL, ref, uploadBytesResumable} from 'firebase/storage'
import db, {storage} from '../../../../services/firebase'
import {addDoc, collection, doc, onSnapshot, serverTimestamp} from 'firebase/firestore'
import Drawer from 'react-modern-drawer'
import {toast, ToastContainer} from 'react-toastify'

export interface addChecklist {
  Checklist_name: string
  Policies: boolean
  Security_training: boolean
  OWASP_training: boolean
  uid: string
  tenantId: string
  NDA: boolean
  Custom_tasks: string
  file_url: string
}
const initialState = {
  checklist_name: '',
  policies: [] as string[],
  security_training: false,
  owasp_training: false,
  NDA: false,
  Custom_tasks: [] as string[],
  file_url: '',
}

const AddPersonnelChecklistModal: React.FC = () => {
  const [form, setForm] = useState(initialState)
  const {checklist_name, security_training, owasp_training, NDA, file_url, Custom_tasks} = form
  const [file, setFile] = useState<File | null>(null)
  const [progress, setProgress] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser} = useAuth()
  const [selectAll, setSelectAll] = useState(false)
  const [policies, setPolicies] = useState<any[]>([])
  const [selectedPolicies, setSelectedPolicies] = useState<any[]>([])
  const [ndaChecked, setNdaChecked] = useState(false)
  const [selectedTasks, setSelectedTasks] = useState<any[]>([])
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null)
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [tasks, setTasks] = useState<any[]>([])
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})
  const focusRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (focusRef.current) {
          focusRef.current.focus()
        }
      }, 300)
    }
  }, [isOpen])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      console.error('tenantId is missing or invalid')
      Logout()
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'policies')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const policyData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setPolicies(policyData)
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      console.error('tenantId is missing or invalid')
      Logout()
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'tasklists')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const taskData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setTasks(taskData)
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    setForm(initialState)
  }, [])

  useEffect(() => {
    setForm((prev) => ({...prev, CustomTasks: [...selectedTasks]}))
  }, [selectedTasks])

  useEffect(() => {
    setForm((prev) => ({...prev, fileUrl: downloadUrl as string}))
  }, [downloadUrl])

  const validateForm = () => {
    const errors: Record<string, string> = {}

    if (!checklist_name.trim()) {
      errors.checklistName = 'Checklist name is required'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1
  const day = currentDate.getDate()

  const todaysDate = month + '/' + day + '/' + year

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    console.log('Form data:', form)

    if (!validateForm()) {
      setIsLoading(false)
      return
    }

    try {
      setIsLoading(true)

      let fileDownloadUrl = ''
      if (
        selectedFile ||
        checklist_name ||
        selectedPolicies ||
        security_training ||
        owasp_training ||
        NDA ||
        selectedTasks
      ) {
        // Perform the file upload logic (similar to what you have in the handleUpload function)
        if (selectedFile) {
          const fileName = new Date().getTime() + selectedFile.name
          const storageRef = ref(
            storage,
            `tenants/${currentUser?.tenantId}/ChecklistFile/${fileName}`
          )
          const uploadTask = uploadBytesResumable(storageRef, selectedFile)

          await new Promise((resolve, reject) => {
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                console.log('Upload is ' + progress + '% done')
                setProgress(progress)
              },
              (error) => {
                console.error('Error uploading file:', error.message)
                reject(error)
              },
              async () => {
                fileDownloadUrl = await getDownloadURL(uploadTask.snapshot.ref)
                setDownloadUrl(fileDownloadUrl)
                setForm((prev) => ({...prev, fileUrl: fileDownloadUrl}))
                resolve(fileDownloadUrl)
              }
            )
          })
        }

        const newPersonnel = {
          checklist_name,
          policies: selectedPolicies,
          security_training,
          owasp_training,
          NDA,
          Custom_tasks: selectedTasks,
          createdon: new Date().toLocaleDateString(),
          file_url: fileDownloadUrl,
          uid: currentUser?.uid,
          tenantId: currentUser?.tenantId,
        }
        console.log('newPersonnel', newPersonnel)

        // personnelChecklistServices.addChecklist(newPersonnel)
        if (currentUser) {
          if (!currentUser.tenantId) {
            // tenantId is falsy (empty string or undefined)
            console.error('tenantId is missing or invalid')
            Logout()
            return
          }
          const tenantRef = doc(db, 'tenants', currentUser.tenantId!)
          const policyRef = collection(tenantRef, 'personnel_checklist')

          await addDoc(policyRef, {
            ...newPersonnel,
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
          })

          const activitiesRef = collection(tenantRef, 'activities')
          await addDoc(activitiesRef, {
            message: `${currentUser?.userName} added a new checklist '${checklist_name}'`,
            timestamp: serverTimestamp(),
            tenantId: currentUser?.tenantId,
            uid: currentUser?.uid,
            username: currentUser?.userName,
            action: 'Checklist added',
            target: 'Employees',
            email: currentUser?.email,
            date: todaysDate,
          })
        }
        toast.success('New checklist added', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })

        window.scrollTo(0, 0)
      }
    } catch (err: any) {
      window.scrollTo(0, 0)
    } finally {
      setIsLoading(false)

      // Reset file-related and form states
      setFile(null)
      setSelectedFile(null)
      setForm(initialState)

      // Navigate or perform any other actions after submission
      setTimeout(() => {
        // navigate('/personnel-management/checklist')
        closeDrawer()
        setSelectAll(false)
        setSelectedPolicies([])
        setNdaChecked(false)
        setSelectedTasks([])
      }, 3000)
    }
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    // console.log(file)
    if (file) {
      setSelectedFile(file)
      setSelectedFileName(file.name)
    }
  }

  const handleRemoveFile = () => {
    // Reset the selected file and file-related states
    setSelectedFile(null)
    setSelectedFileName(null)
    setForm((prev) => ({...prev, fileUrl: ''}))
  }

  const handleTaskChange = (event) => {
    if (event.target.checked) {
      setSelectedTasks([...selectedTasks, event.target.value])
    } else {
      setSelectedTasks(selectedTasks.filter((task) => task !== event.target.value))
    }
  }
  // console.log(selectedTasks)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({...form, [e.target.name]: e.target.value})
    setFormErrors((prev) => ({...prev, [e.target.name]: ''}))

    if (e.target.name === 'checklistName' && !e.target.value.trim()) {
      setFormErrors((prev) => ({...prev, checklistName: 'Checklist name is required'}))
    }
  }

  const handleSecurityTrainingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({...prev, security_training: e.target.checked}))
  }

  const handleOwaspTrainingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({...prev, owasp_training: e.target.checked}))
  }
  const handleNDAChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNdaChecked(e.target.checked)
    setForm((prev) => ({...prev, NDA: e.target.checked}))
  }
  const handleSelectAllChange = (e) => {
    setSelectAll(e.target.checked)
    if (e.target.checked) {
      setSelectedPolicies(policies.map((policy) => policy.policy))
    } else {
      setSelectedPolicies([])
    }
  }
  // console.log(selectedPolicies)

  const handlePolicyChange = (e, policyName) => {
    if (e.target.checked) {
      setSelectedPolicies((prev) => [...prev, policyName])
    } else {
      setSelectedPolicies((prev) => prev.filter((name) => name !== policyName))
    }
  }

  const toggleDrawer = () => {
    setIsOpen(!isOpen)
  }

  const closeDrawer = () => {
    setIsOpen(false)
    setFormErrors({})
  }

  return (
    <>
      <button
        type='button'
        className='btn btn-sm fs-6'
        onClick={toggleDrawer}
        data-bs-target='#kt_add_personnel'
        style={{height: '40px', background: '#1CBB8D', color: '#fff'}}
      >
        <KTIcon iconName='plus' className='fs-2 text-white' />
        Add Checklist
      </button>

      <Drawer open={isOpen} onClose={closeDrawer} direction='right' className='' size='45%'>
        <div
          className='modal-dialog modal-dialog-centered border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 className='modal-title '>Add Checklist</h4>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary'
                onClick={closeDrawer}
                aria-label='Close'
                style={{
                  transition: 'all 0.2s ease',
                }}
                onMouseEnter={(e) => {
                  if (e.currentTarget) {
                    e.currentTarget.style.backgroundColor = '#fff'
                    const svgElement = e.currentTarget.querySelector('svg')
                    if (svgElement) {
                      svgElement.style.stroke = '#1CBB8D'
                    }
                  }
                }}
                onMouseLeave={(e) => {
                  if (e.currentTarget) {
                    e.currentTarget.style.backgroundColor = '#fff'
                    const svgElement = e.currentTarget.querySelector('svg')
                    if (svgElement) {
                      svgElement.style.stroke = '#5A5A5A'
                    }
                  }
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='#5A5A5A'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  className='icon icon-tabler icons-tabler-outline icon-tabler-x'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M18 6l-12 12' />
                  <path d='M6 6l12 12' />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div
          className=' scroll-y overflow-auto p-5 mb-4'
          tabIndex={-1}
          style={{height: 'calc(100vh - 10px)'}}
        >
          {/* <form id='kt_risk_form' className='form' onSubmit={handleSubmit}> */}
          {/* <div className='modal-content'> */}
          <div className='modal-body mb-8'>
            <div className=''>
              {/* <div className='col-xl-4'> */}
              <form
                id='kt_modal_add_user_form'
                className='form'
                // onSubmit={formik.handleSubmit}
                noValidate
                onSubmit={handleSubmit}
                // style={{width: '100%'}}
              >
                <div
                  className='d-flex flex-column scroll-y me-n6'
                  id='kt_modal_add_user_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  // data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_user_header'
                  data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                  data-kt-scroll-offset='300px'
                >
                  <div className='fv-row mb-7 fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                    Create a checklist with action items for employee onboarding/offboarding.
                    Employee groups assigned to this checklist will be required to go through the
                    action items during their onboarding/offboarding.
                  </div>
                  {/* begin::Input group */}
                  <div className='fv-row mb-7'>
                    <label className='fw-bold fs-6 mb-2'>Checklist Name</label>
                    <input
                      placeholder='Enter Checklist Name'
                      type='text'
                      value={checklist_name}
                      className={`form-control mb-3 mb-lg-0 w-100 ${
                        formErrors.checklistName ? 'is-invalid' : ''
                      }`}
                      name='checklist_name'
                      onChange={handleChange}
                      autoComplete='off'
                      ref={focusRef}
                    />
                    {formErrors.checklistName && (
                      <div className='invalid-feedback d-block'>{formErrors.checklistName}</div>
                    )}
                  </div>
                  {/* end::Input group */}

                  <div className='fv-row mb-3'>
                    {/* begin::Label */}
                    <label className=' fw-bold fs-6 mb-2'>Onboarding</label>
                    {/* end::Label */}
                    <div className='border rounded'>
                      <div className='p-4 fw-bold ' style={{borderBottom: '1px solid #ddd'}}>
                        <img src='/media/buttons/badge-1.svg' alt='' className='m-1' /> Policies
                      </div>
                      <div
                        className='form-check form-check-custom form-check-solid p-4'
                        style={{borderBottom: '1px dotted #ddd'}}
                      >
                        <input
                          className='form-check-input '
                          type='checkbox'
                          value='Select All'
                          id='flexCheckChecked'
                          checked={selectAll}
                          onChange={handleSelectAllChange}
                        />
                        <label className='form-check-label fw-bold' htmlFor='flexCheckChecked'>
                          Select All
                        </label>
                      </div>
                      <div style={{height: '50vh', overflowY: 'auto'}}>
                        {policies.map((policy) => (
                          <div
                            className='form-check form-check-custom form-check-solid p-4 fw-bold'
                            style={{borderBottom: '1px dotted #ddd'}}
                            key={policy.id}
                          >
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value={policy.policy} // Assuming the policy name is stored in the 'name' field
                              id={`policy-${policy.id}`}
                              checked={selectAll || selectedPolicies.includes(policy.policy)}
                              onChange={(e) => handlePolicyChange(e, policy.policy)}
                            />
                            <label className='form-check-label' htmlFor={`policy-${policy.id}`}>
                              <img src='/media/buttons/text_snippet.png' alt='' className='m-1' />{' '}
                              {policy.policy}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* begin::Label */}

                    {/* end::Label */}
                    <div className='border rounded mt-5'>
                      <div
                        style={{border: '1px solid #ddd'}}
                        className='p-4 fw-bold rounded d-flex align-items-center justify-content-between'
                      >
                        <div>
                          <img src='/media/buttons/badge-2.svg' alt='' className='m-1' /> Security
                          Training
                        </div>
                        <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='security_training'
                            checked={security_training}
                            onChange={handleSecurityTrainingChange}
                          />
                        </div>
                      </div>
                    </div>

                    {/* begin::Label */}

                    {/* end::Label */}
                    <div className='border rounded mt-5'>
                      <div
                        style={{border: '1px solid #ddd'}}
                        className='p-4 fw-bold rounded d-flex align-items-center justify-content-between'
                      >
                        {' '}
                        {/* Add justify-content-between here */}
                        <div>
                          {' '}
                          {/* Wrap the text and image in a div */}
                          <img src='/media/buttons/badge-3.svg' alt='' className='m-1' /> OWASP
                          Training
                        </div>
                        <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                          {' '}
                          {/* Remove ml-auto */}
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='owasp_training'
                            checked={owasp_training}
                            onChange={handleOwaspTrainingChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='fv-row mb-5 mt-10'>
                      {/* begin::Label */}
                      <label className='fw-bold fs-6 mb-2'>Offboarding</label>
                      <div className='border rounded'>
                        <div
                          className='p-4 fw-bold d-flex align-items-center justify-content-between'
                          style={{borderBottom: '1px solid #ddd'}}
                        >
                          <div>
                            <img src='/media/buttons/badge-1.svg' alt='' className='m-1' /> NDA
                          </div>
                          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                            {' '}
                            {/* Remove ml-auto */}
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              name='twoFactorEnable'
                              checked={NDA}
                              onChange={handleNDAChange}
                            />
                          </div>
                        </div>
                        {ndaChecked && (
                          <div
                            // style={{border: '1px solid #ddd'}}
                            className='p-4 fw-bold  d-flex align-items-center justify-content-between'
                          >
                            <div>
                              <>
                                {!selectedFile && (
                                  <>
                                    <label htmlFor='fileInput'>
                                      <img
                                        src='/media/buttons/upload.svg'
                                        alt=''
                                        className='mb-5 mt-2'
                                        style={{cursor: 'pointer'}}
                                      />
                                    </label>
                                    <input
                                      type='file'
                                      id='fileInput'
                                      style={{display: 'none'}}
                                      onChange={handleFileChange}
                                      accept='application/pdf'
                                    />
                                    <br />
                                    <span className='form-text text-muted'>
                                      Max number of files is 1. Supported file type: pdf
                                    </span>
                                  </>
                                )}
                                <div className='m-1'>
                                  {selectedFile && (
                                    <>
                                      <p className='bg-gray-100 p-2 w-100'>
                                        <span className='mx-5'>
                                          Selected File: {selectedFile.name}
                                        </span>
                                        <button onClick={handleRemoveFile} className='btn p-0'>
                                          <i className='bi bi-x fs-1'></i>
                                        </button>
                                      </p>
                                      <span className='form-text text-muted mx-5'>
                                        Max number of files is 1. Supported file type: pdf
                                      </span>
                                    </>
                                  )}
                                </div>
                              </>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='fv-row mb-3'>
                      {/* begin::Label */}

                      {/* end::Label */}
                      <div className='border rounded'>
                        <div className='p-4 fw-bold ' style={{borderBottom: '1px solid #ddd'}}>
                          <img src='/media/buttons/badge-2.svg' alt='' className='m-1' /> Custom
                          Tasks
                        </div>
                        {tasks.map((data) => (
                          <>
                            <div
                              className='form-check form-check-custom form-check-solid p-4'
                              style={{borderBottom: '1px dotted #ddd'}}
                            >
                              <input
                                className='form-check-input '
                                type='checkbox'
                                value={data.taskName}
                                id='flexCheckChecked'
                                checked={selectedTasks.includes('task 1')}
                                onChange={handleTaskChange}
                              />
                              <label
                                className='form-check-label fw-bold'
                                htmlFor='flexCheckChecked'
                              >
                                {data.taskName}
                              </label>
                            </div>
                          </>
                        ))}
                        <div className='form-check form-check-custom form-check-solid p-4 fw-bold'>
                          <img src='/media/buttons/AddTask.svg' alt='' className='m-1' />
                        </div>
                      </div>
                    </div>

                    {/* begin::Input */}
                    {/* <select
                      className='form-select '
                      data-control='select2'
                      data-placeholder='Select Checklist'
                    >
                      <option>Select Checklist</option>
                      <option value='1'>Admin</option>
                      <option value='2'>Employee</option>
                    </select> */}
                    {/* end::Input */}
                    {/* {formik.touched.email && formik.errors.email && ( */}
                    <div className='fv-plugins-message-container'>
                      <span role='alert'></span>
                    </div>
                    {/* )} */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* </div> */}
        <div
          className='offcanvas-footer d-flex justify-content-end  border-top py-4 px-6'
          style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
        >
          {isLoading ? (
            <button
              className='btn btn-sm fw-semibold px-6 fs-6'
              type='submit'
              style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#148363')
              }
              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#1CBB8D')
              }
              disabled={progress !== null && progress < 100}
            >
              Adding... <span className='spinner-border spinner-border-sm align-middle m-2'></span>
            </button>
          ) : (
            <>
              <button
                // className='m-1 btn btn-sm btn-light '
                className='btn btn-sm btn-light fw-semibold me-4 px-6 fs-6'
                // style={{cursor: 'pointer'}}
                onClick={closeDrawer}
                aria-label='Close'
                style={{height: '40px'}}
              >
                Cancel
              </button>
              <button
                className='btn btn-sm fw-semibold px-6 fs-6'
                onClick={handleSubmit}
                style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#148363')
                }
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                }
              >
                <KTIcon iconName='plus' className='fs-2 text-white' />Add
              </button>
            </>
          )}
        </div>

        {/* </form> */}
      </Drawer>
      <ToastContainer />
    </>
  )
}

export {AddPersonnelChecklistModal}
