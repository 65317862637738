import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Link, useLocation, useParams} from 'react-router-dom'

const MicrosoftTeam = () => {
  const [activeTab, setActiveTab] = useState('details')
  const location = useLocation()
  const {id} = useParams()
  useEffect(() => {
    // Fetch control data when the component mounts
    if (location.pathname.includes('details')) {
      setActiveTab('details')
    } else if (location.pathname.includes('documents')) {
      setActiveTab('documents')
    }
  }, [id])
  return (
    <>
      <div className={`card mb-5 mb-xl-8`}>
        <div className='card-header border-0 pt-5 mb-5'>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-65px me-5 '>
              <img src={toAbsoluteUrl(`/media/icons/duotune/social/Team.svg`)} alt='' />
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <span className='card-label fw-bold fs-6'>Vendor</span>
              <h1 className='text-bold fw-bold'>Microsoft Team</h1>
            </div>
          </div>
        </div>
      </div>
      <div className='card card-custom'>
        {/* <div className='card-header'>
          <h3 className='card-title'>Add Asset</h3>
        </div> */}
        <div className='pt-4 pb-0 px-10'>
          {/*begin::Navs*/}
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-3x border-transparent fs-5 fw-semibold flex-nowrap'>
              {/*begin::Nav item*/}
              <li className='nav-item'>
                <Link
                  to={`/vendor-risk/notion/details`}
                  className={`nav-link me-6 ${
                    activeTab === 'details' ? 'text-active-primary active' : ''
                  }`}
                >
                  Details
                </Link>
              </li>
              {/*end::Nav item*/}
              {/*begin::Nav item*/}
              <li className='nav-item'>
                <Link
                  to={'/vendor-risk/notion/documents'}
                  className={`nav-link me-6 ${
                    activeTab === 'documents' ? 'text-active-primary active' : ''
                  }`}
                >
                  Documents
                </Link>
              </li>
              {/*end::Nav item*/}
            </ul>
          </div>
          {/*begin::Navs*/}
        </div>
        <div className='row g-5 g-xxl-8 p-10'>
          <div className='col-xl-6' style={{width: '40%'}}>
            <h2 className='mb-5'>Main Info</h2>
            <form className='form'>
              <div className='form-group'>
                <label className='text-muted mb-3'>Company name</label>
                <input
                  type='text'
                  className='form-control form-control-solid form-control-sm'
                  placeholder='Company name'
                />
                {/* <span className='form-text text-muted'>Please enter Risk Source</span> */}
              </div>
              <div className='form-group'>
                <label className='text-muted mt-5 mb-3'>Website</label>
                <div className='input-group input-group-sm '>
                  <div className='input-group-prepend'>
                    <span className='input-group-text '>https://</span>
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Website'
                    aria-describedby='basic-addon2'
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className='text-muted mt-5 mb-3'>Security</label>
                <div className='input-group input-group-sm '>
                  <div className='input-group-prepend'>
                    <span className='input-group-text '>https://</span>
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Website'
                    aria-describedby='basic-addon2'
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className='text-muted mt-5 mb-3'>Vendor Owner</label>
                <select className='form-control form-control-solid ' id='exampleSelectl'>
                  <option>Jackson Hartman</option>
                  <option>Jack Hartman</option>
                  <option>Sam Smith</option>
                </select>
              </div>
              <div className='form-group'>
                <label className='text-muted mt-5 mb-3'>Services Provided</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='Services Provided'
                />
                {/* <span className='form-text text-muted'>
                        We'll never share your email with anyone else
                      </span> */}
              </div>

              <div className='form-group'>
                <label className='text-muted mt-5 mb-3'>Date of engagement</label>
                <input type='date' className='form-control form-control-solid' placeholder='' />
                {/* <span className='form-text text-muted'>
                        We'll never share your email with anyone else
                      </span> */}
              </div>
              <div className='form-group'>
                <label className='text-muted mt-5 mb-3'>Date of last review</label>
                <input type='date' className='form-control form-control-solid' placeholder='' />
              </div>
              <div className='form-group'>
                <label className='text-muted mt-5 mb-3'>Authentication type </label>
                <label className='form-check form-check-custom form-check-solid p-5'>
                  <input
                    className='form-check-input'
                    type='radio'
                    checked={true}
                    value='Password'
                    name='authenticationType'
                  />
                  <span style={{marginLeft: 10, marginRight: 20}}> Password</span>

                  <input
                    className='form-check-input'
                    type='radio'
                    checked={false}
                    value='Single sign-in'
                    name='authenticationType'
                  />
                  <span style={{marginLeft: 10, marginRight: 20}}>Single sign-in</span>
                </label>
              </div>
              <div className='form-group'>
                <label className='text-muted mt-5 mb-3'>Two factor enabled</label>

                <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    name='notifications'
                    defaultChecked={true}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className='text-muted mt-5 mb-3'>
                  Do the vendors third party audit reports(SOC 2, ISO 27001) evident any area of
                  concerns?{' '}
                </label>
                <textarea
                  className='form-control form-control-solid'
                  id='description'
                  rows={3}
                ></textarea>
              </div>
              <div className='form-group'>
                <label className='text-muted mt-5 mb-3'>Other information</label>
                <textarea
                  className='form-control form-control-solid'
                  id='description'
                  rows={3}
                ></textarea>
              </div>
            </form>
          </div>
          <div className='col-xl-6' style={{width: '40%', marginLeft: 50}}>
            <h2 className='mb-7'>Audit Information</h2>
            <form className='form'>
              <div className='form-group'>
                <h4 className='mb-5'>Environment Type</h4>
                <label className='text-muted fw-semibold fs-6'>What type of vendor is this?</label>
                <div className='checkbox-list'>
                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='Checkboxes4'
                      style={{width: 15, height: 15, marginRight: 10}}
                    />
                    <span></span>
                    Production
                  </label>

                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='Checkboxes4'
                      style={{width: 15, height: 15, marginRight: 10}}
                    />
                    <span></span>
                    Development
                  </label>
                </div>
              </div>
              <div className='form-group'>
                <h4 className='mt-10 mb-5'>Data Management</h4>
                <label className='fw-semibold '>
                  What type of data does system store, process or interface with?
                </label>
                <div>
                  <label className='text-muted fw-semibold fs-6 mt-3'>
                    Publicly available data
                  </label>
                </div>

                <div className='checkbox-list'>
                  <label className='form-check form-check-custom form-check-solid m-3'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='Checkboxes4'
                      style={{width: 15, height: 15, marginRight: 10}}
                    />
                    <span></span>
                    Generic, informational, or Marketing material
                  </label>
                </div>
                <label className='text-muted fw-semibold fs-6'>Protected Corporate Operation</label>

                <div className='checkbox-list'>
                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='Checkboxes4'
                      style={{width: 15, height: 15, marginRight: 10}}
                    />
                    <span></span>
                    Employment Informtion
                  </label>
                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='Checkboxes4'
                      style={{width: 15, height: 15, marginRight: 10}}
                    />
                    <span></span>
                    Corporative Proprietary Information
                  </label>
                </div>

                <label className='text-muted fw-semibold fs-6'>Customer Information</label>

                <div className='checkbox-list'>
                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='Checkboxes4'
                      style={{width: 15, height: 15, marginRight: 10}}
                    />
                    <span></span>
                    Public Customer Information
                  </label>
                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='Checkboxes4'
                      style={{width: 15, height: 15, marginRight: 10}}
                    />
                    <span></span>
                    Non-Public Customer Information
                  </label>
                </div>
                <label className='text-muted fw-semibold fs-6'>
                  Regulation-protected customer, consumer, and employee information
                </label>

                <div className='checkbox-list'>
                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='Checkboxes4'
                      style={{width: 15, height: 15, marginRight: 10}}
                    />
                    <span></span>
                    GDPR regulated EU resident data
                  </label>
                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='Checkboxes4'
                      style={{width: 15, height: 15, marginRight: 10}}
                    />
                    <span></span>
                    HIPAA or HITRUST protected data(PHI)
                  </label>

                  <div className='checkbox-list'>
                    <label className='form-check form-check-custom form-check-solid m-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        //   checked={true}
                        // value={hdEncryption}
                        name='Checkboxes4'
                        style={{width: 15, height: 15, marginRight: 10}}
                      />
                      <span></span>
                      PCI-DSS Protected Data
                    </label>
                    <label className='form-check form-check-custom form-check-solid m-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        //   checked={true}
                        // value={hdEncryption}
                        name='Checkboxes4'
                        style={{width: 15, height: 15, marginRight: 10}}
                      />
                      <span></span>
                      CCPA Protected Data
                    </label>
                  </div>
                  <h4 className='mt-10 mb-5'>Operational Reliance</h4>
                  <div className='col-9 col-form-label'>
                    <div className='checkbox-list'>
                      <label>Operational Reliance</label>
                      <label className='form-check form-check-custom form-check-solid p-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={true}
                          value='Low'
                          name='reliance'
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}> Low</span>

                        <input
                          className='form-check-input'
                          type='radio'
                          checked={false}
                          value='Medium'
                          name='reliance'
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}>Medium</span>

                        <input
                          className='form-check-input'
                          type='radio'
                          checked={false}
                          value='No'
                          name='reliance'
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}>High</span>
                      </label>
                    </div>
                  </div>

                  <h4 className='mt-10 mb-5'>Risk Level</h4>
                  <div className='col-9 col-form-label'>
                    <div className='checkbox-list'>
                      <label>Risk Level</label>
                      <label className='form-check form-check-custom form-check-solid p-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={true}
                          value='Low'
                          name='risk'
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}> Low</span>

                        <input
                          className='form-check-input'
                          type='radio'
                          checked={false}
                          value='Low'
                          name='risk'
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}>Low</span>

                        <input
                          className='form-check-input'
                          type='radio'
                          checked={false}
                          value='High'
                          name='risk'
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}>High</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default MicrosoftTeam
