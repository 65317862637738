import db from '../../../../services/firebase'
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from 'firebase/firestore'

const testsCollectionRef = collection(db, 'default_tests')

class TestsDataService {
  addTest = (newTests) => {
    // Add 'created_at' and 'updated_at' timestamps
    const testsWithTimestamps = {
      ...newTests,
      created_at: serverTimestamp(),
      updated_at: serverTimestamp(),
    }

    return addDoc(testsCollectionRef, testsWithTimestamps)
  }

  updateTest = (id, updateTests) => {
    const testsDoc = doc(db, 'default_tests', id)

    // Update 'updated_at' timestamp
    const testsWithTimestamp = {
      ...updateTests,
      updated_at: serverTimestamp(),
    }

    return updateDoc(testsDoc, testsWithTimestamp)
  }

  deleteTest = (id) => {
    const testsDoc = doc(db, 'Tests', id)
    return deleteDoc(testsDoc)
  }

  getAllTests = () => {
    return getDocs(testsCollectionRef)
  }

  getTest = (id) => {
    const testsDoc = doc(db, 'Tests', id)
    return getDoc(testsDoc)
  }
}

export default new TestsDataService()
