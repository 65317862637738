import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {BuilderPage} from './BuilderPage'

const BuilderPageWrapper: FC = () => {
  return (
    <>
      <PageTitle
        icon='personnelIcon.svg'
        title='Layout Builder'
        desc='Layouts'
        breadcrumbs={[]}
      ></PageTitle>
      <BuilderPage />
    </>
  )
}

export default BuilderPageWrapper
