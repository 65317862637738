import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Link, useLocation, useParams} from 'react-router-dom'

const FigmaDocuments = () => {
  const [activeTab, setActiveTab] = useState('details')
  const location = useLocation()
  const {id} = useParams()
  useEffect(() => {
    // Fetch control data when the component mounts
    if (location.pathname.includes('details')) {
      setActiveTab('details')
    } else if (location.pathname.includes('documents')) {
      setActiveTab('documents')
    }
  }, [id])
  return (
    <>
      <div className={`card mb-5 mb-xl-8`}>
        <div className='card-header border-0 pt-5 mb-5'>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-65px me-5 '>
              <img src={toAbsoluteUrl(`/media/icons/duotune/social/Figma.svg`)} alt='' />
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <span className='card-label fw-bold fs-6'>Vendor</span>
              <h1 className='text-bold fw-bold'>Figma</h1>
            </div>
          </div>
        </div>
      </div>
      <div className='card card-custom'>
        {/* <div className='card-header'>
          <h3 className='card-title'>Add Asset</h3>
        </div> */}
        <div className='pt-4 pb-0 px-10'>
          {/*begin::Navs*/}
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-3x border-transparent fs-5 fw-semibold flex-nowrap'>
              {/*begin::Nav item*/}
              <li className='nav-item'>
                <Link
                  to={`/vendor-risk/figma/details`}
                  className={`nav-link me-6 ${
                    activeTab === 'details' ? 'text-active-primary active' : ''
                  }`}
                >
                  Details
                </Link>
              </li>
              {/*end::Nav item*/}
              {/*begin::Nav item*/}
              <li className='nav-item'>
                <Link
                  to={'/vendor-risk/figma/documents'}
                  className={`nav-link me-6 ${
                    activeTab === 'documents' ? 'text-active-primary active' : ''
                  }`}
                >
                  Documents
                </Link>
              </li>
              {/*end::Nav item*/}
            </ul>
          </div>
          {/*begin::Navs*/}
        </div>
        {/*begin::Card toolbar*/}
        <div className='card-header pt-4'>
          <div className='card-title'>
            {/*begin::Search*/}
            <div className='d-flex align-items-center position-relative my-1'>
              <i className='ki-duotone ki-magnifier fs-1 position-absolute ms-6'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <input
                type='text'
                data-kt-filemanager-table-filter='search'
                className='form-control form-control-solid w-250px ps-15'
                placeholder='Search Files & Folders'
              />
            </div>
            {/*end::Search*/}
          </div>
          {/*begin::Card toolbar*/}
          <div className='card-toolbar'>
            {/*begin::Toolbar*/}
            <div className='d-flex justify-content-end' data-kt-filemanager-table-toolbar='base'>
              {/*begin::Export*/}
              <button
                type='button'
                className='btn btn-flex btn-light-primary me-3'
                id='kt_file_manager_new_folder'
              >
                <i className='ki-duotone ki-add-folder fs-2'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                Add Documents
              </button>
              {/*end::Export*/}
            </div>
            {/*end::Toolbar*/}
            {/*begin::Group actions*/}
            <div
              className='d-flex justify-content-end align-items-center d-none'
              data-kt-filemanager-table-toolbar='selected'
            >
              <div className='fw-bold me-5'>
                <span className='me-2' data-kt-filemanager-table-select='selected_count'></span>
                Selected
              </div>
              <button
                type='button'
                className='btn btn-danger'
                data-kt-filemanager-table-select='delete_selected'
              >
                Delete Selected
              </button>
            </div>
            {/*end::Group actions*/}
          </div>
          {/*end::Card toolbar*/}
        </div>
        {/*end::Card toolbar*/}
        <div className='table-responsive py-3 px-10'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-120px'>Document name</th>
                <th className='min-w-140px'>Size</th>
                <th className='min-w-140px'>Last Modified</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input' type='checkbox' value='1' />
                  </div>
                </td>
                <td data-order='apps'>
                  <div className='d-flex align-items-center'>
                    <span className='icon-wrapper'>
                      <i className='ki-duotone ki-folder fs-2x text-primary me-4'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>
                    <a
                      href='../../demo1/dist/apps/file-manager/files/.html'
                      className='text-gray-800 text-hover-primary'
                    >
                      apps
                    </a>
                  </div>
                </td>
                <td>-</td>
                <td>-</td>
                <td className='text-end' data-kt-filemanager-table='action_dropdown'>
                  <div className='d-flex justify-content-end'>
                    {/* begin::Share link*/}
                    <div className='ms-2' data-kt-filemanger-table='copy_link'>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-light btn-active-light-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                      >
                        <i className='ki-duotone ki-fasten fs-5 m-0'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </button>
                      {/* begin::Menu*/}
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px'
                        data-kt-menu='true'
                      >
                        {/* begin::Card*/}
                        <div className='card card-flush'>
                          <div className='card-body p-5'>
                            {/* begin::Loader*/}
                            <div className='d-flex' data-kt-filemanger-table='copy_link_generator'>
                              {/* begin::Spinner*/}
                              <div className='me-5' data-kt-indicator='on'>
                                <span className='indicator-progress'>
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              </div>
                              {/* end::Spinner*/}
                              {/* begin::Label*/}
                              <div className='fs-6 text-dark'>Generating Share Link...</div>
                              {/* end::Label*/}
                            </div>
                            {/* end::Loader*/}
                            {/* begin::Link*/}
                            <div
                              className='d-flex flex-column text-start d-none'
                              data-kt-filemanger-table='copy_link_result'
                            >
                              <div className='d-flex mb-3'>
                                <i className='ki-duotone ki-check fs-2 text-success me-3'></i>
                                <div className='fs-6 text-dark'>Share Link Generated</div>
                              </div>
                              <input
                                type='text'
                                className='form-control form-control-sm'
                                value='https://path/to/file/or/folder/'
                              />
                              <div className='text-muted fw-normal mt-2 fs-8 px-3'>
                                Read only.
                                <a
                                  href='../../demo1/dist/apps/file-manager/settings/.html'
                                  className='ms-2'
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                            {/* end::Link*/}
                          </div>
                        </div>
                        {/* end::Card*/}
                      </div>
                      {/* end::Menu*/}
                      {/* end::Share link*/}
                    </div>
                    {/* begin::More*/}
                    <div className='ms-2'>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-light btn-active-light-primary me-2'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                      >
                        <i className='ki-duotone ki-dots-square fs-5 m-0'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                          <span className='path4'></span>
                        </i>
                      </button>
                      {/* begin::Menu*/}
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4'
                        data-kt-menu='true'
                      >
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a
                            href='../../demo1/dist/apps/file-manager/files.html'
                            className='menu-link px-3'
                          >
                            View
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a href='#' className='menu-link px-3' data-kt-filemanager-table='rename'>
                            Rename
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a href='#' className='menu-link px-3'>
                            Download Folder
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a
                            href='#'
                            className='menu-link px-3'
                            data-kt-filemanager-table-filter='move_row'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_move_to_folder'
                          >
                            Move to folder
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a
                            href='#'
                            className='menu-link text-danger px-3'
                            data-kt-filemanager-table-filter='delete_row'
                          >
                            Delete
                          </a>
                        </div>
                        {/* end::Menu item*/}
                      </div>
                      {/* end::Menu*/}
                      {/* end::More*/}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input' type='checkbox' value='1' />
                  </div>
                </td>
                <td data-order='widgets'>
                  <div className='d-flex align-items-center'>
                    <span className='icon-wrapper'>
                      <i className='ki-duotone ki-folder fs-2x text-primary me-4'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>
                    <a
                      href='../../demo1/dist/apps/file-manager/files/.html'
                      className='text-gray-800 text-hover-primary'
                    >
                      widgets
                    </a>
                  </div>
                </td>
                <td>-</td>
                <td>-</td>
                <td className='text-end' data-kt-filemanager-table='action_dropdown'>
                  <div className='d-flex justify-content-end'>
                    {/* begin::Share link*/}
                    <div className='ms-2' data-kt-filemanger-table='copy_link'>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-light btn-active-light-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                      >
                        <i className='ki-duotone ki-fasten fs-5 m-0'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </button>
                      {/* begin::Menu*/}
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px'
                        data-kt-menu='true'
                      >
                        {/* begin::Card*/}
                        <div className='card card-flush'>
                          <div className='card-body p-5'>
                            {/* begin::Loader*/}
                            <div className='d-flex' data-kt-filemanger-table='copy_link_generator'>
                              {/* begin::Spinner*/}
                              <div className='me-5' data-kt-indicator='on'>
                                <span className='indicator-progress'>
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              </div>
                              {/* end::Spinner*/}
                              {/* begin::Label*/}
                              <div className='fs-6 text-dark'>Generating Share Link...</div>
                              {/* end::Label*/}
                            </div>
                            {/* end::Loader*/}
                            {/* begin::Link*/}
                            <div
                              className='d-flex flex-column text-start d-none'
                              data-kt-filemanger-table='copy_link_result'
                            >
                              <div className='d-flex mb-3'>
                                <i className='ki-duotone ki-check fs-2 text-success me-3'></i>
                                <div className='fs-6 text-dark'>Share Link Generated</div>
                              </div>
                              <input
                                type='text'
                                className='form-control form-control-sm'
                                value='https://path/to/file/or/folder/'
                              />
                              <div className='text-muted fw-normal mt-2 fs-8 px-3'>
                                Read only.
                                <a
                                  href='../../demo1/dist/apps/file-manager/settings/.html'
                                  className='ms-2'
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                            {/* end::Link*/}
                          </div>
                        </div>
                        {/* end::Card*/}
                      </div>
                      {/* end::Menu*/}
                      {/* end::Share link*/}
                    </div>
                    {/* begin::More*/}
                    <div className='ms-2'>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-light btn-active-light-primary me-2'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                      >
                        <i className='ki-duotone ki-dots-square fs-5 m-0'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                          <span className='path4'></span>
                        </i>
                      </button>
                      {/* begin::Menu*/}
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4'
                        data-kt-menu='true'
                      >
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a
                            href='../../demo1/dist/apps/file-manager/files.html'
                            className='menu-link px-3'
                          >
                            View
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a href='#' className='menu-link px-3' data-kt-filemanager-table='rename'>
                            Rename
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a href='#' className='menu-link px-3'>
                            Download Folder
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a
                            href='#'
                            className='menu-link px-3'
                            data-kt-filemanager-table-filter='move_row'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_move_to_folder'
                          >
                            Move to folder
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a
                            href='#'
                            className='menu-link text-danger px-3'
                            data-kt-filemanager-table-filter='delete_row'
                          >
                            Delete
                          </a>
                        </div>
                        {/* end::Menu item*/}
                      </div>
                      {/* end::Menu*/}
                      {/* end::More*/}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input' type='checkbox' value='1' />
                  </div>
                </td>
                <td data-order='assets'>
                  <div className='d-flex align-items-center'>
                    <span className='icon-wrapper'>
                      <i className='ki-duotone ki-folder fs-2x text-primary me-4'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>
                    <a
                      href='../../demo1/dist/apps/file-manager/files/.html'
                      className='text-gray-800 text-hover-primary'
                    >
                      assets
                    </a>
                  </div>
                </td>
                <td>-</td>
                <td>-</td>
                <td className='text-end' data-kt-filemanager-table='action_dropdown'>
                  <div className='d-flex justify-content-end'>
                    {/* begin::Share link*/}
                    <div className='ms-2' data-kt-filemanger-table='copy_link'>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-light btn-active-light-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                      >
                        <i className='ki-duotone ki-fasten fs-5 m-0'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </button>
                      {/* begin::Menu*/}
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px'
                        data-kt-menu='true'
                      >
                        {/* begin::Card*/}
                        <div className='card card-flush'>
                          <div className='card-body p-5'>
                            {/* begin::Loader*/}
                            <div className='d-flex' data-kt-filemanger-table='copy_link_generator'>
                              {/* begin::Spinner*/}
                              <div className='me-5' data-kt-indicator='on'>
                                <span className='indicator-progress'>
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              </div>
                              {/* end::Spinner*/}
                              {/* begin::Label*/}
                              <div className='fs-6 text-dark'>Generating Share Link...</div>
                              {/* end::Label*/}
                            </div>
                            {/* end::Loader*/}
                            {/* begin::Link*/}
                            <div
                              className='d-flex flex-column text-start d-none'
                              data-kt-filemanger-table='copy_link_result'
                            >
                              <div className='d-flex mb-3'>
                                <i className='ki-duotone ki-check fs-2 text-success me-3'></i>
                                <div className='fs-6 text-dark'>Share Link Generated</div>
                              </div>
                              <input
                                type='text'
                                className='form-control form-control-sm'
                                value='https://path/to/file/or/folder/'
                              />
                              <div className='text-muted fw-normal mt-2 fs-8 px-3'>
                                Read only.
                                <a
                                  href='../../demo1/dist/apps/file-manager/settings/.html'
                                  className='ms-2'
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                            {/* end::Link*/}
                          </div>
                        </div>
                        {/* end::Card*/}
                      </div>
                      {/* end::Menu*/}
                      {/* end::Share link*/}
                    </div>
                    {/* begin::More*/}
                    <div className='ms-2'>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-light btn-active-light-primary me-2'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                      >
                        <i className='ki-duotone ki-dots-square fs-5 m-0'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                          <span className='path4'></span>
                        </i>
                      </button>
                      {/* begin::Menu*/}
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4'
                        data-kt-menu='true'
                      >
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a
                            href='../../demo1/dist/apps/file-manager/files.html'
                            className='menu-link px-3'
                          >
                            View
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a href='#' className='menu-link px-3' data-kt-filemanager-table='rename'>
                            Rename
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a href='#' className='menu-link px-3'>
                            Download Folder
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a
                            href='#'
                            className='menu-link px-3'
                            data-kt-filemanager-table-filter='move_row'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_move_to_folder'
                          >
                            Move to folder
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a
                            href='#'
                            className='menu-link text-danger px-3'
                            data-kt-filemanager-table-filter='delete_row'
                          >
                            Delete
                          </a>
                        </div>
                        {/* end::Menu item*/}
                      </div>
                      {/* end::Menu*/}
                      {/* end::More*/}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input' type='checkbox' value='1' />
                  </div>
                </td>
                <td data-order='documentation'>
                  <div className='d-flex align-items-center'>
                    <span className='icon-wrapper'>
                      <i className='ki-duotone ki-folder fs-2x text-primary me-4'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>
                    <a
                      href='../../demo1/dist/apps/file-manager/files/.html'
                      className='text-gray-800 text-hover-primary'
                    >
                      documentation
                    </a>
                  </div>
                </td>
                <td>-</td>
                <td>-</td>
                <td className='text-end' data-kt-filemanager-table='action_dropdown'>
                  <div className='d-flex justify-content-end'>
                    {/* begin::Share link*/}
                    <div className='ms-2' data-kt-filemanger-table='copy_link'>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-light btn-active-light-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                      >
                        <i className='ki-duotone ki-fasten fs-5 m-0'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </button>
                      {/* begin::Menu*/}
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px'
                        data-kt-menu='true'
                      >
                        {/* begin::Card*/}
                        <div className='card card-flush'>
                          <div className='card-body p-5'>
                            {/* begin::Loader*/}
                            <div className='d-flex' data-kt-filemanger-table='copy_link_generator'>
                              {/* begin::Spinner*/}
                              <div className='me-5' data-kt-indicator='on'>
                                <span className='indicator-progress'>
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              </div>
                              {/* end::Spinner*/}
                              {/* begin::Label*/}
                              <div className='fs-6 text-dark'>Generating Share Link...</div>
                              {/* end::Label*/}
                            </div>
                            {/* end::Loader*/}
                            {/* begin::Link*/}
                            <div
                              className='d-flex flex-column text-start d-none'
                              data-kt-filemanger-table='copy_link_result'
                            >
                              <div className='d-flex mb-3'>
                                <i className='ki-duotone ki-check fs-2 text-success me-3'></i>
                                <div className='fs-6 text-dark'>Share Link Generated</div>
                              </div>
                              <input
                                type='text'
                                className='form-control form-control-sm'
                                value='https://path/to/file/or/folder/'
                              />
                              <div className='text-muted fw-normal mt-2 fs-8 px-3'>
                                Read only.
                                <a
                                  href='../../demo1/dist/apps/file-manager/settings/.html'
                                  className='ms-2'
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                            {/* end::Link*/}
                          </div>
                        </div>
                        {/* end::Card*/}
                      </div>
                      {/* end::Menu*/}
                      {/* end::Share link*/}
                    </div>
                    {/* begin::More*/}
                    <div className='ms-2'>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-light btn-active-light-primary me-2'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                      >
                        <i className='ki-duotone ki-dots-square fs-5 m-0'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                          <span className='path4'></span>
                        </i>
                      </button>
                      {/* begin::Menu*/}
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4'
                        data-kt-menu='true'
                      >
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a
                            href='../../demo1/dist/apps/file-manager/files.html'
                            className='menu-link px-3'
                          >
                            View
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a href='#' className='menu-link px-3' data-kt-filemanager-table='rename'>
                            Rename
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a href='#' className='menu-link px-3'>
                            Download Folder
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a
                            href='#'
                            className='menu-link px-3'
                            data-kt-filemanager-table-filter='move_row'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_move_to_folder'
                          >
                            Move to folder
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a
                            href='#'
                            className='menu-link text-danger px-3'
                            data-kt-filemanager-table-filter='delete_row'
                          >
                            Delete
                          </a>
                        </div>
                        {/* end::Menu item*/}
                      </div>
                      {/* end::Menu*/}
                      {/* end::More*/}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input' type='checkbox' value='1' />
                  </div>
                </td>
                <td data-order='layouts'>
                  <div className='d-flex align-items-center'>
                    <span className='icon-wrapper'>
                      <i className='ki-duotone ki-folder fs-2x text-primary me-4'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>
                    <a
                      href='../../demo1/dist/apps/file-manager/files/.html'
                      className='text-gray-800 text-hover-primary'
                    >
                      layouts
                    </a>
                  </div>
                </td>
                <td>-</td>
                <td>-</td>
                <td className='text-end' data-kt-filemanager-table='action_dropdown'>
                  <div className='d-flex justify-content-end'>
                    {/* begin::Share link*/}
                    <div className='ms-2' data-kt-filemanger-table='copy_link'>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-light btn-active-light-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                      >
                        <i className='ki-duotone ki-fasten fs-5 m-0'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </button>
                      {/* begin::Menu*/}
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px'
                        data-kt-menu='true'
                      >
                        {/* begin::Card*/}
                        <div className='card card-flush'>
                          <div className='card-body p-5'>
                            {/* begin::Loader*/}
                            <div className='d-flex' data-kt-filemanger-table='copy_link_generator'>
                              {/* begin::Spinner*/}
                              <div className='me-5' data-kt-indicator='on'>
                                <span className='indicator-progress'>
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              </div>
                              {/* end::Spinner*/}
                              {/* begin::Label*/}
                              <div className='fs-6 text-dark'>Generating Share Link...</div>
                              {/* end::Label*/}
                            </div>
                            {/* end::Loader*/}
                            {/* begin::Link*/}
                            <div
                              className='d-flex flex-column text-start d-none'
                              data-kt-filemanger-table='copy_link_result'
                            >
                              <div className='d-flex mb-3'>
                                <i className='ki-duotone ki-check fs-2 text-success me-3'></i>
                                <div className='fs-6 text-dark'>Share Link Generated</div>
                              </div>
                              <input
                                type='text'
                                className='form-control form-control-sm'
                                value='https://path/to/file/or/folder/'
                              />
                              <div className='text-muted fw-normal mt-2 fs-8 px-3'>
                                Read only.
                                <a
                                  href='../../demo1/dist/apps/file-manager/settings/.html'
                                  className='ms-2'
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                            {/* end::Link*/}
                          </div>
                        </div>
                        {/* end::Card*/}
                      </div>
                      {/* end::Menu*/}
                      {/* end::Share link*/}
                    </div>
                    {/* begin::More*/}
                    <div className='ms-2'>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-light btn-active-light-primary me-2'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                      >
                        <i className='ki-duotone ki-dots-square fs-5 m-0'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                          <span className='path4'></span>
                        </i>
                      </button>
                      {/* begin::Menu*/}
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4'
                        data-kt-menu='true'
                      >
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a
                            href='../../demo1/dist/apps/file-manager/files.html'
                            className='menu-link px-3'
                          >
                            View
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a href='#' className='menu-link px-3' data-kt-filemanager-table='rename'>
                            Rename
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a href='#' className='menu-link px-3'>
                            Download Folder
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a
                            href='#'
                            className='menu-link px-3'
                            data-kt-filemanager-table-filter='move_row'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_move_to_folder'
                          >
                            Move to folder
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a
                            href='#'
                            className='menu-link text-danger px-3'
                            data-kt-filemanager-table-filter='delete_row'
                          >
                            Delete
                          </a>
                        </div>
                        {/* end::Menu item*/}
                      </div>
                      {/* end::Menu*/}
                      {/* end::More*/}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input' type='checkbox' value='1' />
                  </div>
                </td>
                <td data-order='modals'>
                  <div className='d-flex align-items-center'>
                    <span className='icon-wrapper'>
                      <i className='ki-duotone ki-folder fs-2x text-primary me-4'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>
                    <a
                      href='../../demo1/dist/apps/file-manager/files/.html'
                      className='text-gray-800 text-hover-primary'
                    >
                      modals
                    </a>
                  </div>
                </td>
                <td>-</td>
                <td>-</td>
                <td className='text-end' data-kt-filemanager-table='action_dropdown'>
                  <div className='d-flex justify-content-end'>
                    {/* begin::Share link*/}
                    <div className='ms-2' data-kt-filemanger-table='copy_link'>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-light btn-active-light-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                      >
                        <i className='ki-duotone ki-fasten fs-5 m-0'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </button>
                      {/* begin::Menu*/}
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px'
                        data-kt-menu='true'
                      >
                        {/* begin::Card*/}
                        <div className='card card-flush'>
                          <div className='card-body p-5'>
                            {/* begin::Loader*/}
                            <div className='d-flex' data-kt-filemanger-table='copy_link_generator'>
                              {/* begin::Spinner*/}
                              <div className='me-5' data-kt-indicator='on'>
                                <span className='indicator-progress'>
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              </div>
                              {/* end::Spinner*/}
                              {/* begin::Label*/}
                              <div className='fs-6 text-dark'>Generating Share Link...</div>
                              {/* end::Label*/}
                            </div>
                            {/* end::Loader*/}
                            {/* begin::Link*/}
                            <div
                              className='d-flex flex-column text-start d-none'
                              data-kt-filemanger-table='copy_link_result'
                            >
                              <div className='d-flex mb-3'>
                                <i className='ki-duotone ki-check fs-2 text-success me-3'></i>
                                <div className='fs-6 text-dark'>Share Link Generated</div>
                              </div>
                              <input
                                type='text'
                                className='form-control form-control-sm'
                                value='https://path/to/file/or/folder/'
                              />
                              <div className='text-muted fw-normal mt-2 fs-8 px-3'>
                                Read only.
                                <a
                                  href='../../demo1/dist/apps/file-manager/settings/.html'
                                  className='ms-2'
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                            {/* end::Link*/}
                          </div>
                        </div>
                        {/* end::Card*/}
                      </div>
                      {/* end::Menu*/}
                      {/* end::Share link*/}
                    </div>
                    {/* begin::More*/}
                    <div className='ms-2'>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-light btn-active-light-primary me-2'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                      >
                        <i className='ki-duotone ki-dots-square fs-5 m-0'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                          <span className='path4'></span>
                        </i>
                      </button>
                      {/* begin::Menu*/}
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4'
                        data-kt-menu='true'
                      >
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a
                            href='../../demo1/dist/apps/file-manager/files.html'
                            className='menu-link px-3'
                          >
                            View
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a href='#' className='menu-link px-3' data-kt-filemanager-table='rename'>
                            Rename
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a href='#' className='menu-link px-3'>
                            Download Folder
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a
                            href='#'
                            className='menu-link px-3'
                            data-kt-filemanager-table-filter='move_row'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_move_to_folder'
                          >
                            Move to folder
                          </a>
                        </div>
                        {/* end::Menu item*/}
                        {/* begin::Menu item*/}
                        <div className='menu-item px-3'>
                          <a
                            href='#'
                            className='menu-link text-danger px-3'
                            data-kt-filemanager-table-filter='delete_row'
                          >
                            Delete
                          </a>
                        </div>
                        {/* end::Menu item*/}
                      </div>
                      {/* end::Menu*/}
                      {/* end::More*/}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
      </div>
    </>
  )
}

export default FigmaDocuments
