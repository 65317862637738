import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../../modules/auth'
import db from '../../../../../services/firebase'
import {collection, doc, onSnapshot} from 'firebase/firestore'

const ControlCard = () => {
  const {currentUser} = useAuth()
  const [totalControl, setTotalcontrols] = useState(0)
  const [totalISO, setTotalISO] = useState(0)
  const [totalSOC, setTotalSOC] = useState(0)
  const [unhealthyControlsCount, setUnhealthyControlsCount] = useState(0)
  const [unhealthyISOControlsCount, setUnhealthyISOControlsCount] = useState(0)
  const [unhealthySOCControlsCount, setUnhealthySOCControlsCount] = useState(0)
  const [healthyControlsCount, setHealthyControlsCount] = useState(0)
  const [healthyISOControlsCount, setHealthyISOControlsCount] = useState(0)
  const [healthySOCControlsCount, setHealthySOCControlsCount] = useState(0)
  const [controls, setControls] = useState<any[]>([])
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const [isCrispLoaded, setIsCrispLoaded] = useState(false)
  const [isChatOpen, setIsChatOpen] = useState(false)

  useEffect(() => {
    getControls()
  }, [activeFrameworks])

  useEffect(() => {
    getActiveFramworks()
  }, [])

  useEffect(() => {
    if (!window.$crisp) {
      window.$crisp = []
      window.CRISP_WEBSITE_ID = '61030408-87ac-47eb-979f-e95d108e2d77'

      const script = document.createElement('script')
      script.src = 'https://client.crisp.chat/l.js'
      script.async = true

      script.onload = () => {
        setIsCrispLoaded(true)
        const wasChatOpen = localStorage.getItem('isChatOpen') === 'true'
        if (wasChatOpen) {
          window.$crisp.push(['do', 'chat:show'])
          window.$crisp.push(['do', 'chat:open'])
          setIsChatOpen(true)
        } else {
          window.$crisp.push(['do', 'chat:hide'])
          setIsChatOpen(false)
        }
      }

      document.head.appendChild(script)
    } else {
      setIsCrispLoaded(true)
      const wasChatOpen = localStorage.getItem('isChatOpen') === 'true'
      if (wasChatOpen) {
        window.$crisp.push(['do', 'chat:show'])
        window.$crisp.push(['do', 'chat:open'])
        setIsChatOpen(true)
      } else {
        window.$crisp.push(['do', 'chat:hide'])
        setIsChatOpen(false)
      }
    }
  }, [])

  useEffect(() => {
    if (isCrispLoaded) {
      if (isChatOpen) {
        window.$crisp.push(['do', 'chat:show'])
      } else {
        window.$crisp.push(['do', 'chat:hide'])
      }
    }
  }, [isChatOpen, isCrispLoaded])

  useEffect(() => {
    if (isCrispLoaded) {
      window.$crisp.push([
        'on',
        'chat:closed',
        () => {
          window.$crisp.push(['do', 'chat:hide'])
          setIsChatOpen(false)
          localStorage.setItem('isChatOpen', 'false')
        },
      ])
    }
  }, [isCrispLoaded])

  const handleCrispChat = () => {
    // setIsDropdownVisible(false);
    console.log('clicked')
    if (isCrispLoaded) {
      if (!isChatOpen) {
        window.$crisp.push(['do', 'chat:show'])
        window.$crisp.push(['do', 'chat:open'])
        setIsChatOpen(true)
        localStorage.setItem('isChatOpen', 'true')
      } else {
        window.$crisp.push(['do', 'chat:hide'])
        window.$crisp.push(['do', 'session:reset'])
        setIsChatOpen(false)
        localStorage.setItem('isChatOpen', 'false')
      }
    } else {
      console.log('Crisp is not loaded yet. Please wait a moment and try again.')
    }
  }

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
        console.log(frameworks)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  const getControls = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const testsCollectionRef = collection(tenantRef, 'controls')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          framework: doc.data().control_framework_mappings,
          status: doc.data().control_evidence_status,
        }))

        const filteredControls = controlsData.filter((control) => {
          if (!control.framework) return false

          return activeFrameworks.some(
            (framework) =>
              control.framework.startsWith(framework) &&
              !control.framework.startsWith('ISO 27001:2013')
          )
        })

        setControls(filteredControls)

        setTotalcontrols(filteredControls.length)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  useEffect(() => {
    const unhealthyControls = controls.filter((doc) => {
      const status = doc.control_evidence_status

      return status && status === 'Unhealthy'
    })

    // Count the number of documents with status "Unhealthy"
    const unhealthyControlsCount = unhealthyControls.length

    // Update state with the count of "Unhealthy" controls
    setUnhealthyControlsCount(unhealthyControlsCount)

    const iso27001Controls = controls.filter((doc) => {
      const framework = doc.control_framework_mappings
      return framework && framework.includes('ISO') && !framework.startsWith('ISO 27001:2013')
    })

    // Count the number of documents that start with "ISO 27001"
    const iso27001ControlsCount = iso27001Controls.length
    setTotalISO(iso27001ControlsCount)

    const SOC2Controls = controls.filter((doc) => {
      const framework = doc.control_framework_mappings
      return framework && framework.startsWith('SOC')
    })

    // Count the number of documents that start with "ISO 27001"
    const SOC2ControlsCount = SOC2Controls.length
    setTotalSOC(SOC2ControlsCount)
  }, [controls])

  useEffect(() => {
    if (!controls || controls.length === 0) {
      return
    }

    const HealthyControls = controls.filter((doc) => {
      const status = doc.control_evidence_status || ''
      return status.toLowerCase() === 'ok'
    })
    const HealthyControlsCount = HealthyControls.length
    setHealthyControlsCount(HealthyControlsCount)

    // Filter documents with framework containing "ISO" and status "Ok"
    const isoHealthyControls = controls.filter((doc) => {
      const framework = doc.control_framework_mappings || ''
      const status = doc.control_evidence_status || ''
      return (
        framework.includes('ISO') &&
        status.toLowerCase() === 'ok' &&
        !framework.startsWith('ISO 27001:2013')
      )
    })

    // Count the number of documents with framework containing "ISO" and status "Ok"
    const isoHealthyControlsCount = isoHealthyControls.length

    // Update state with the count of "ISO" controls with status "Ok"
    setHealthyISOControlsCount(isoHealthyControlsCount)

    // Filter documents with framework containing "SOC" and status "OK"
    const socHealthyControls = controls.filter((doc) => {
      const framework = doc.control_framework_mappings || ''
      const status = doc.control_evidence_status || ''
      return framework.includes('SOC') && status.toLowerCase() === 'ok'
    })

    // Count the number of documents with framework containing "SOC" and status "OK"
    const socHealthyControlsCount = socHealthyControls.length

    // Update state with the count of "SOC" controls with status "OK"
    setHealthySOCControlsCount(socHealthyControlsCount)
  }, [controls])

  useEffect(() => {
    // Filter documents with framework starting with "ISO" and status "Unhealthy"
    const isoUnhealthyControls = controls.filter((doc) => {
      const framework = doc.control_framework_mappings
      const status = doc.control_evidence_status
      return (
        framework &&
        framework.startsWith('ISO') &&
        status === 'Unhealthy' &&
        !framework.startsWith('ISO 27001:2013')
      )
    })

    // Count the number of documents with framework starting with "ISO" and status "Unhealthy"
    const isoUnhealthyControlsCount = isoUnhealthyControls.length
    // Update state with the count of "ISO" controls with status "Unhealthy"
    setUnhealthyISOControlsCount(isoUnhealthyControlsCount)

    const socUnhealthyControls = controls.filter((doc) => {
      const framework = doc.control_framework_mappings
      const status = doc.control_evidence_status
      return framework && framework.startsWith('SOC') && status === 'Unhealthy'
    })
    // console.log(socUnhealthyControls.map(doc => doc.data()));
    // Count the number of documents with framework starting with "ISO" and status "Unhealthy"
    const socUnhealthyControlsCount = socUnhealthyControls.length

    // Update state with the count of "ISO" controls with status "Unhealthy"
    setUnhealthySOCControlsCount(socUnhealthyControlsCount)
  }, [controls])

  const renderFrameworkCard = (framework: string | never) => {
    let totalControls, healthyControlsCount, unhealthyControlsCount

    switch (framework) {
      case 'ISO 27001:2022':
        totalControls = totalISO
        healthyControlsCount = healthyISOControlsCount
        unhealthyControlsCount = unhealthyISOControlsCount
        break
      case 'SOC 2':
        totalControls = totalSOC
        healthyControlsCount = healthySOCControlsCount
        unhealthyControlsCount = unhealthySOCControlsCount
        break
      default:
        console.error(`Unknown framework: ${framework}`)
        return null // or some default/error card
    }

    return (
      <div className='col-sm-4 mb-5 mb-xl-10'>
        <div className='card card-flush h-lg-80'>
          <div className='card-header pt-0'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-dark'>{framework}</span>
            </h3>
          </div>

          <div className='card-body pt-0'>
            <div className='card-body d-flex align-items-end pt-0 justify-content-end px-0'>
              <div className='d-flex align-items-center flex-column  w-100 justify-content-end'>
                <div className='d-flex flex-column flex-sm-row justify-content-between fw-semibold fs-6 text-black opacity-75 w-100 mt-auto mb-2 pt-2'>
                  <span className='text-gray-800'>Controls OK</span>

                  <span className='fw-bold fs-6'>
                    {' '}
                    {/* {((healthyControlsCount / totalControls) * 100).toFixed(0)}% */}
                    {totalControls !== 0
                      ? `${((healthyControlsCount / totalControls) * 100).toFixed(0)}%`
                      : '0%'}
                  </span>
                </div>

                <div className='h-12px mx-0 w-100 rounded' style={{backgroundColor: '#DAF9EF'}}>
                  {totalControls !== 0 ? (
                    <div
                      className='rounded h-8px'
                      role='progressbar'
                      style={{
                        width: `${(healthyControlsCount / totalControls) * 100}%`,
                        backgroundColor: '#1CBB8D',
                      }}
                      aria-valuenow={(healthyControlsCount / totalControls) * 100}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  ) : (
                    <div
                      className='rounded h-8px'
                      role='progressbar'
                      style={{
                        width: `${0}%`,
                        backgroundColor: '#1CBB8D',
                      }}
                      aria-valuenow={0}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex flex-stack'>
              <div className='text-gray-700 fw-semibold fs-6 me-2'>
                <div className='d-inline-block align-middle me-2 mb-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#5E6274'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-list-check'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M3.5 5.5l1.5 1.5l2.5 -2.5' />
                    <path d='M3.5 11.5l1.5 1.5l2.5 -2.5' />
                    <path d='M3.5 17.5l1.5 1.5l2.5 -2.5' />
                    <path d='M11 6l9 0' />
                    <path d='M11 12l9 0' />
                    <path d='M11 18l9 0' />
                  </svg>
                </div>
                Total Controls
              </div>

              <div className='d-flex align-items-senter'>
                <span className='text-gray-900 fw-bolder fs-6'>{totalControls}</span>
              </div>
            </div>

            <div className='my-3'></div>

            <div className='d-flex flex-stack'>
              <div className='text-gray-700 fw-semibold fs-6 me-2'>
                <div className='d-inline-block align-middle me-2 mb-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#EE3043'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M12 9v4' />
                    <path d='M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z' />
                    <path d='M12 16h.01' />
                  </svg>
                </div>
                Unhealthy
              </div>

              <div className='d-flex align-items-senter'>
                <span className='text-gray-900 fw-bolder fs-6'>{unhealthyControlsCount}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <div className='row gx-5 gx-xl-10'>
        {/*begin::Col*/}
        <div className='col-sm-4 mb-5 mb-xl-10'>
          {/*begin::List widget 1*/}
          <div className='card card-flush h-lg-80'>
            {/*begin::Header*/}
            <div className='card-header pt-0'>
              {/*begin::Title*/}
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-dark'>All Controls</span>
              </h3>
              {/*end::Title*/}
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className='card-body pt-0'>
              <div className='card-body d-flex align-items-end pt-0 justify-content-end px-0'>
                <div className='d-flex align-items-center flex-column  w-100 justify-content-end'>
                  <div className='d-flex flex-column flex-sm-row justify-content-between fw-semibold fs-6 text-black opacity-75 w-100 mt-auto mb-2 pt-2'>
                    <span className='text-gray-800'>Controls OK</span>

                    <span className='fw-bold fs-6'>
                      {' '}
                      {/* {((healthyControlsCount / totalControls) * 100).toFixed(0)}% */}
                      {totalControl !== 0
                        ? `${((healthyControlsCount / totalControl) * 100).toFixed(0)}%`
                        : '0%'}
                    </span>
                  </div>

                  <div className='h-12px mx-0 w-100 rounded' style={{backgroundColor: '#DAF9EF'}}>
                    {/* <div
                      className='rounded h-8px'
                      role='progressbar'
                      style={{
                        width: `${Math.min((healthyControlsCount / totalControl) * 100, 100)}%`,
                        backgroundColor: '#1CBB8D',
                      }}
                      aria-valuenow={Math.min((healthyControlsCount / totalControl) * 100, 100)}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div> */}
                    {totalControl !== 0 ? (
                      <div
                        className='rounded h-8px'
                        role='progressbar'
                        style={{
                          width: `${(healthyControlsCount / totalControl) * 100}%`,
                          backgroundColor: '#1CBB8D',
                        }}
                        aria-valuenow={(healthyControlsCount / totalControl) * 100}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    ) : (
                      <div
                        className='rounded h-8px'
                        role='progressbar'
                        style={{
                          width: `${0}%`,
                          backgroundColor: '#1CBB8D',
                        }}
                        aria-valuenow={0}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
              {/*begin::Item*/}
              <div className='d-flex flex-stack'>
                {/*begin::Section*/}
                <div className='text-gray-700 fw-semibold fs-6 me-2'>
                  <div className='d-inline-block align-middle me-2 mb-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#5E6274'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-list-check'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M3.5 5.5l1.5 1.5l2.5 -2.5' />
                      <path d='M3.5 11.5l1.5 1.5l2.5 -2.5' />
                      <path d='M3.5 17.5l1.5 1.5l2.5 -2.5' />
                      <path d='M11 6l9 0' />
                      <path d='M11 12l9 0' />
                      <path d='M11 18l9 0' />
                    </svg>
                  </div>
                  Total Controls
                </div>

                {/*end::Section*/}
                {/*begin::Statistics*/}
                <div className='d-flex align-items-senter'>
                  {/*begin::Number*/}
                  <span className='text-gray-900 fw-bolder fs-6'>{totalControl}</span>
                  {/*end::Number*/}
                </div>
                {/*end::Statistics*/}
              </div>
              {/*end::Item*/}
              {/*begin::Separator*/}
              <div className='my-3'></div>
              {/*end::Separator*/}
              {/*begin::Item*/}
              <div className='d-flex flex-stack'>
                {/*begin::Section*/}
                <div className='text-gray-700 fw-semibold fs-6 me-2'>
                  <div className='d-inline-block align-middle me-2 mb-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#EE3043'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M12 9v4' />
                      <path d='M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z' />
                      <path d='M12 16h.01' />
                    </svg>
                  </div>
                  Unhealthy
                </div>
                {/*end::Section*/}
                {/*begin::Statistics*/}
                <div className='d-flex align-items-senter'>
                  {/*begin::Number*/}
                  <span className='text-gray-900 fw-bolder fs-6'>{unhealthyControlsCount}</span>
                  {/*end::Number*/}
                </div>
                {/*end::Statistics*/}
              </div>
            </div>
            {/*end::Body*/}
          </div>
          {/*end::LIst widget 1*/}
        </div>
        {/*end::Col*/}

        {/* <div className='col-sm-4 mb-5 mb-xl-10'>
          <div className='card card-flush h-lg-80'>
            <div className='card-header pt-0'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-dark'>ISO 27001</span>
              </h3>
            </div>

            <div className='card-body pt-0'>
              <div className='card-body d-flex align-items-end pt-0 justify-content-end px-2'>
                <div className='d-flex align-items-center flex-column  w-100 justify-content-end'>
                  <div className='d-flex flex-column flex-sm-row justify-content-between fw-bold fs-6 text-black opacity-75 w-100 mt-auto mb-2 pt-2'>
                    <span className='text-gray-400'>Controls OK</span>

                    <span className='text-muted fs-7'>
                      {' '}
                      {((healthyISOControlsCount / totalISO) * 100).toFixed(0)}%
                    </span>
                  </div>

                  <div className='h-12px mx-0 w-100 rounded' style={{backgroundColor: '#DAF9EF'}}>
                    <div
                      className='rounded h-8px'
                      role='progressbar'
                      style={{
                        width: `${Math.min((healthyISOControlsCount / totalISO) * 100, 100)}%`,
                        backgroundColor: '#44DBAF',
                      }}
                      aria-valuenow={Math.min((healthyISOControlsCount / totalISO) * 100, 100)}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                </div>
              </div>

              <div className='d-flex flex-stack'>
                <div className='text-gray-700 fw-semibold fs-6 me-2'>
                  <div className='d-inline-block align-middle me-2 mb-2'>
                    <img src='/media/logos/ControlsTotal.svg' alt='' style={{width: '20px'}} />
                  </div>
                  Total Controls
                </div>

                <div className='d-flex align-items-senter'>
                  <span className='text-gray-900 fw-bolder fs-6'>{totalISO}</span>
                </div>
              </div>

              <div className='my-3'></div>

              <div className='d-flex flex-stack'>
                <div className='text-gray-700 fw-semibold fs-6 me-2'>
                  <div className='d-inline-block align-middle me-2 mb-1'>
                    <img src='/media/logos/Warning.svg' alt='' style={{width: '20px'}} />
                  </div>
                  Unhealthy
                </div>

                <div className='d-flex align-items-senter'>
                  <span className='text-gray-900 fw-bolder fs-6'>{unhealthyISOControlsCount}</span>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {activeFrameworks.includes('ISO 27001:2022') && renderFrameworkCard('ISO 27001:2022')}
        {activeFrameworks.includes('SOC 2') && renderFrameworkCard('SOC 2')}

        {/* <div className='col-sm-4 mb-5 mb-xl-10'>
          <div className='card card-flush h-lg-80'>
            <div className='card-header pt-0'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-dark'>SOC 2</span>
              </h3>
            </div>

            <div className='card-body pt-0'>
              <div className='card-body d-flex align-items-end pt-0 justify-content-end px-2'>
                <div className='d-flex align-items-center flex-column  w-100 justify-content-end'>
                  <div className='d-flex flex-column flex-sm-row justify-content-between fw-bold fs-6 text-black opacity-75 w-100 mt-auto mb-2 pt-2'>
                    <span className='text-gray-400'>Controls OK</span>

                    <span className='text-muted fs-7'>
                      {' '}
                      {((healthySOCControlsCount / totalSOC) * 100).toFixed(0)}%
                    </span>
                  </div>

                  <div className='h-12px mx-0 w-100 rounded' style={{backgroundColor: '#DAF9EF'}}>
                    <div
                      className='rounded h-8px'
                      role='progressbar'
                      style={{
                        width: `${Math.min((healthySOCControlsCount / totalSOC) * 100, 100)}%`,
                        backgroundColor: '#44DBAF',
                      }}
                      aria-valuenow={Math.min((healthySOCControlsCount / totalSOC) * 100, 100)}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                </div>
              </div>

              <div className='d-flex flex-stack'>
                <div className='text-gray-700 fw-semibold fs-6 me-2'>
                  <div className='d-inline-block align-middle me-2 mb-2'>
                    <img src='/media/logos/ControlsTotal.svg' alt='' style={{width: '20px'}} />
                  </div>
                  Total Controls
                </div>

                <div className='d-flex align-items-senter'>
                  <span className='text-gray-900 fw-bolder fs-6'>{totalSOC}</span>
                </div>
              </div>

              <div className='my-3'></div>

              <div className='d-flex flex-stack'>
                <div className='text-gray-700 fw-semibold fs-6 me-2'>
                  <div className='d-inline-block align-middle me-2 mb-1'>
                    <img src='/media/logos/Warning.svg' alt='' style={{width: '20px'}} />
                  </div>
                  Unhealthy
                </div>

                <div className='d-flex align-items-senter'>
                  <span className='text-gray-900 fw-bolder fs-6'>{unhealthySOCControlsCount}</span>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {activeFrameworks.length < 2 && (
          <div className='col d-flex align-items-stretch mb-5 mb-xl-10'>
            <div className='card flex-fill'>
              <div className='card-body d-flex flex-column justify-content-center align-items-center'>
                <img src='/media/icon/framework.svg' alt='Framework icon' className='mb-4' />
                <h5 className='card-title fw-bold text-center mb-4'>Enable other frameworks</h5>
                <p className='card-text fw-semibold text-center'>
                  <a href='#' onClick={() => handleCrispChat()} className='text-primary'>
                    Contact sales
                  </a>{' '}
                  to enable other frameworks
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ControlCard
