import React, {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
// import {VendorRisk} from './VendorRisk'
import {IntegrationListHeader} from './components/header/IntegrationListHeader'
import {KTCard} from '../../../_metronic/helpers'
import {IntegrationManagement} from './Integration'
import {Helmet} from 'react-helmet'
import { IntegrationPageTitle } from '../../../_metronic/layout/core/IntegrationPageData'
import { IntegrationListSearchComponent } from './components/header/IntegrationListSearchComponent'

const IntegrationWrapper: FC = () => {

  //uncomment when you need total intergation from db
  // const [totalIntergrations, setTotalIntegrations] = useState(0)

  // useEffect(() => {
  //   const collectionRef = db.collection('Integration')

  //   const unsubscribe = collectionRef.onSnapshot((snapshot) => {
  //     const count = snapshot.size // Get the number of documents in the collection
  //     setTotalIntegrations(count)
  //   })

  //   return () => unsubscribe() // Clean up the subscription when the component unmounts
  // }, [])
  return (
    <>
       <Helmet>
        <title>Secureslate | Integrations</title>
      </Helmet>
      <IntegrationPageTitle
        icon='integrations.svg'
        title='Integrations'
        desc='Connect services and activate automated evidence collection & monitoring.'
        // breadcrumbs={usersBreadcrumbs}
      >
        Integrations
      </IntegrationPageTitle>
      <IntegrationListSearchComponent />
      {/* <KTCard> */}
        {/* <IntegrationListHeader /> */}
        <IntegrationManagement className='mb-5 mb-xl-8' />
        {/* <VendorRisk className='card-xl-stretch mb-xl-8' /> */}
      {/* </KTCard> */}
    </>
  )
}

export default IntegrationWrapper
