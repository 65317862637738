import clsx from 'clsx'
import {KTIcon} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import {useAuth} from '../../../../app/modules/auth'
import {useEffect, useRef, useState} from 'react'
import {collection, doc, DocumentReference, getDoc, onSnapshot} from 'firebase/firestore'
import db from '../../../../services/firebase'
import {useNavigate} from 'react-router-dom'

declare global {
  interface Window {
    $crisp: any[]
    CRISP_WEBSITE_ID: string
  }
}

const itemClass = 'ms-1 ms-md-1'
const itemClass2 = 'ms-1 ms-md-1 mx-5'
const btnClass = 'btn btn-icon btn-custom btn-icon-muted  w-35px h-35px'
const btnClass2 = 'btn p-0 btn-active-light btn-active-color-primary fs-6  w-120px h-40px mx-0 mt-1'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const iconStyles = {
  transition: 'stroke 0.3s ease',
}

const Navbar = () => {
  const {config} = useLayout()
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined)
  const [isChecked, setIsChecked] = useState(false)
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [isCrispLoaded, setIsCrispLoaded] = useState(false)
  const [isChatOpen, setIsChatOpen] = useState(localStorage.getItem('isChatOpen') === 'true')
  const [userName, setUsername] = useState('')

  useEffect(() => {
    const fetchIdentityData = async () => {
      try {
        const tenantId = currentUser?.tenantId || ''

        if (!tenantId) {
          // If either tenantId or userId is not available, exit early
          return
        }

        const tenantRef = doc(db, 'tenants', tenantId)
        const identityDocRef = doc(tenantRef, 'settings', 'user_detail')

        // Fetch the identity document
        const identityDoc = await getDoc(identityDocRef)
        setUsername(
          identityDoc.data()
            ? (identityDoc.data()?.firstname ?? '') + ' ' + (identityDoc.data()?.lastname ?? '')
            : currentUser?.userName ?? ''
        )
      } catch (error) {
        console.error('Error fetching identity data:', error)
      }
    }

    fetchIdentityData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.tenantId, currentUser?.uid])

  const handleHelpClick = () => {
    setDropdownVisible(!dropdownVisible)
  }

  const toggleDropdown = () => {
    // console.log('clicked')
    setIsDropdownVisible(!isDropdownVisible)
  }

  const loadCrisp = () => {
    if (!window.$crisp) {
      window.$crisp = []
      window.CRISP_WEBSITE_ID = '61030408-87ac-47eb-979f-e95d108e2d77'

      const script = document.createElement('script')
      script.src = 'https://client.crisp.chat/l.js'
      script.async = true

      script.onload = () => {
        setIsCrispLoaded(true)
        window.$crisp.push(['do', 'chat:open'])
        setIsChatOpen(true)
        localStorage.setItem('isChatOpen', 'true')
      }

      document.head.appendChild(script)
    } else {
      setIsCrispLoaded(true)
      window.$crisp.push(['do', 'chat:open'])
      setIsChatOpen(true)
      localStorage.setItem('isChatOpen', 'true')
    }
  }
  useEffect(() => {
    if (isCrispLoaded && isChatOpen) {
      window.$crisp.push(['do', 'chat:open'])
    }
  }, [isCrispLoaded, isChatOpen])

  useEffect(() => {
    if (isCrispLoaded) {
      window.$crisp.push([
        'on',
        'chat:closed',
        () => {
          setIsChatOpen(false)
          localStorage.setItem('isChatOpen', 'false')
          window.$crisp.push(['do', 'chat:hide'])
        },
      ])
    }
  }, [isCrispLoaded])

  const handleOptionClick = (option: string) => {
    setIsDropdownVisible(false)
    if (option === 'email') {
      window.location.href = 'mailto:support@getsecureslate.com'
    } else if (option === 'chat') {
      if (!isCrispLoaded) {
        loadCrisp()
      } else {
        window.$crisp.push(['do', 'chat:open'])
        setIsChatOpen(true)
        localStorage.setItem('isChatOpen', 'true')
      }
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)
    const settingRef: DocumentReference = doc(tenantRef, 'settings', 'secureslate_ai')

    const unsubscribe = onSnapshot(settingRef, (snapshot) => {
      const data = snapshot.data()
      if (data) {
        setIsChecked(data.two_factor_enable)
      }
    })
    return () => {
      // Unsubscribe from the listener when component unmounts
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    const fetchIdentityData = () => {
      const tenantId = currentUser?.tenantId || ''

      if (!tenantId) {
        return
      }

      const tenantRef = doc(db, 'tenants', tenantId)
      const identityDocRef = doc(tenantRef, 'settings', 'user_detail')

      const unsubscribe = onSnapshot(
        identityDocRef,
        (doc) => {
          if (doc.exists()) {
            const data = doc.data()
            setUsername(
              (data?.firstname ?? '') + ' ' + (data?.lastname ?? '') || currentUser?.userName || ''
            )
            setAvatarUrl(data?.image_url)
          }
        },
        (error) => {
          console.error('Error fetching identity data:', error)
        }
      )

      return unsubscribe
    }

    const unsubscribe = fetchIdentityData()

    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.tenantId, currentUser?.uid])

  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div> */}

      {isChecked && (
        <div className={clsx('app-navbar-item')}>
          <div className={clsx('position-relative', btnClass2)} id='kt_drawer_chat_toggle'>
            <a href='/chatgpt'>
              {' '}
              <button className='btn btn-sm fw-bold mx-0 px-3 pb-4'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='#5A5A5A'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  className='icon icon-tabler icons-tabler-outline icon-tabler-message mb-1'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M8 9h8' />
                  <path d='M8 13h6' />
                  <path d='M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z' />
                </svg>
                <span className='fs-6' style={{color: '#5A5A5A'}}>
                  {' '}
                  AI Chat
                </span>
              </button>
            </a>
          </div>
        </div>
      )}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div className='dropdown mx-2' ref={dropdownRef}>
          <div id='' className={btnClass} onClick={toggleDropdown}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='#5E6274'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='icon icon-tabler icons-tabler-outline icon-tabler-help help-icon'
              style={iconStyles}
            >
              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
              <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
              <path d='M12 17l0 .01' />
              <path d='M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4' />
            </svg>
          </div>
          {isDropdownVisible && (
            <ul
              className='dropdown-menu show '
              style={{
                position: 'absolute',
                inset: '0px auto auto 0px',
                margin: '0px',
                transform: 'translate3d(0px, 40px, 0px)',
              }}
            >
              <li>
                <button
                  className='dropdown-item fw-semibold fs-6 text-hover-primary'
                  onClick={() => handleOptionClick('email')}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-mail me-1'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z' />
                    <path d='M3 7l9 6l9 -6' />
                  </svg>{' '}
                  Email
                </button>
              </li>
              <li>
                <button
                  className='dropdown-item fw-semibold fs-6 text-hover-primary'
                  onClick={() => handleOptionClick('chat')}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-message me-1'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M8 9h8' />
                    <path d='M8 13h6' />
                    <path d='M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z' />
                  </svg>{' '}
                  Chat
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass2)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          {/* <KTIcon iconName='element-plus' className={btnIconClass} /> */}
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            stroke='#5E6274'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            className='icon icon-tabler icons-tabler-outline icon-tabler-bell btnIconClass help-icon'
          >
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <path d='M10 5a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6' />
            <path d='M9 17v1a3 3 0 0 0 6 0v-1' />
          </svg>
        </div>
        <HeaderNotificationsMenu />
      </div>

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {avatarUrl && avatarUrl !== '/media/svg/avatars/blank.svg' ? (
            <img alt='logo' loading='lazy' src={avatarUrl} />
          ) : (
            <div className='symbol symbol-35px'>
              <span className='symbol-label fs-6 fw-bold ' style={{backgroundColor: '#DAF9EF'}}>
                {currentUser?.userName
                  ? `${currentUser?.userName
                      ?.split(' ')[0]
                      ?.charAt(0)
                      .toUpperCase()}${currentUser?.userName
                      ?.split(' ')[1]
                      ?.charAt(0)
                      .toUpperCase()}`
                  : ''}
              </span>
            </div>
          )}
        </div>
        {/* <div className='mx-2'>
          <span style={{display: 'block', fontSize: '12px'}} className='fw-bold '>
            {currentUser?.userName}
          </span>
          <span
            style={{color: '#5A5A5A', display: 'block', fontSize: '12px'}}
            className='fw-semibold'
          >
            Admin
          </span>
        </div> */}
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}
const style = document.createElement('style')
style.textContent = `
  .help-icon:hover {
    stroke: #1CBB8D; 
  }
`
document.head.appendChild(style)
export {Navbar}
