/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {collection, doc, onSnapshot, query, where} from 'firebase/firestore'
import db from '../../../../services/firebase'
import {useAuth} from '../../../../app/modules/auth'

const HeaderNotificationsMenu: FC = () => {
  const [activities, setActivities] = useState<any[]>([])
  const [alerts, setAlerts] = useState<any[]>([])

  const {currentUser} = useAuth()

  useEffect(() => {
    getAlerts()
  }, [])

  useEffect(() => {
    getActivities()
  }, [])

  const getActivities = async () => {
    try {
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)
      const controlsCollectionRef = collection(tenantRef, 'activities')

      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(controlsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setActivities(controlsData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const getAlerts = async () => {
    try {
      const userId = currentUser?.uid || '' // Replace with the desired tenantId
      const notificationsCollectionRef = collection(db, 'notification')
      // Query documents where 'uid' field matches the current user's UID
      const q = query(notificationsCollectionRef, where('uid', '==', userId))
      // Set up a listener for real-time updates based on the query
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const alerts = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setAlerts(alerts) // Assuming setAlerts is a state updater function
      })
      return unsubscribe
    } catch (error) {
      console.error('Error getting notifications:', error)
    }
  }

  const formatDate = (dateString: string): string => {
    try {
      let [month, day, year] = dateString.split('/')

      // Ensure year is 4 digits
      if (year.length === 2) {
        year = `20${year}`
      }

      const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))

      const formattedDay = parseInt(day)
      const formattedMonth = date.toLocaleString('default', {month: 'long'})
      const formattedYear = year

      return `${formattedDay} ${formattedMonth}, ${formattedYear}`
    } catch (error) {
      return ''
    }
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`}}
      >
        <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
          Notifications <span className='fs-8 opacity-75 ps-3'></span>
        </h3>

        <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
          <li className='nav-item' key='0'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_1'
            >
              Alerts
            </a>
          </li>

          {/* <li className='nav-item'>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
            data-bs-toggle='tab'
            href='#kt_topbar_notifications_2'
          >
            Updates
          </a>
        </li> */}

          <li className='nav-item' key='1'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_2'
            >
              Logs
            </a>
          </li>
        </ul>
      </div>

      <div className='tab-content'>
        <div className='tab-pane fade' id='kt_topbar_notifications_1' role='tabpanel'>
          <div className='scroll-y mh-325px my-5 px-8'>
            {/* {defaultAlerts.map((alert, index) => (
            <div key={`alert${index}`} className='d-flex flex-stack py-4'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-4'>
                  <span className={clsx('symbol-label', `bg-light-${alert.state}`)}>
                    {' '}
                    <KTIcon iconName={alert.icon} className={`fs-2 text-${alert.state}`} />
                  </span>
                </div>

                <div className='mb-0 me-2'>
                  <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                    {alert.title}
                  </a>
                  <div className='text-gray-400 fs-7'>{alert.description}</div>
                </div>
              </div>

              <span className='badge badge-light fs-8'>{alert.time}</span>
            </div>
          ))} */}

            <div className='d-flex flex-stack py-2'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-40px me-4'>
                  <span className={clsx('symbol-label', `bg-light-danger text-danger`)}>
                    {' '}
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M12 9v4' />
                      <path d='M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z' />
                      <path d='M12 16h.01' />
                    </svg>
                  </span>
                </div>

                <div className='mb-0 me-2'>
                  <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-semibold'>
                    <strong> 23 evidences</strong> missing in document tests
                  </a>
                  <div className='text-gray-700 fs-7'>29 July, 2024</div>
                </div>
              </div>
            </div>

            <div className='d-flex flex-stack py-2'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-40px me-4'>
                  <span className={clsx('symbol-label', `bg-light-danger text-danger`)}>
                    {' '}
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M12 9v4' />
                      <path d='M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z' />
                      <path d='M12 16h.01' />
                    </svg>
                  </span>
                </div>

                <div className='mb-0 me-2'>
                  <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-semibold'>
                    <strong> Due diligence</strong> pending for 4 vendors
                  </a>
                  <div className='text-gray-700 fs-7'>28 July, 2024</div>
                </div>
              </div>
            </div>

            <div className='d-flex flex-stack py-2'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-40px me-4'>
                  <span className={clsx('symbol-label', `bg-light-danger text-danger`)}>
                    {' '}
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M12 9v4' />
                      <path d='M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z' />
                      <path d='M12 16h.01' />
                    </svg>
                  </span>
                </div>

                <div className='mb-0 me-2'>
                  <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-semibold'>
                    <strong> Access request ticket and history</strong> is due
                  </a>
                  <div className='text-gray-700 fs-7'>28 July, 2024</div>
                </div>
              </div>
            </div>

            <div className='d-flex flex-stack py-2'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-40px me-4'>
                  <span className={clsx('symbol-label', `bg-light-danger text-danger`)}>
                    {' '}
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M12 9v4' />
                      <path d='M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z' />
                      <path d='M12 16h.01' />
                    </svg>
                  </span>
                </div>

                <div className='mb-0 me-2'>
                  <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-semibold'>
                    <strong> 12 policy</strong> missing in policy tests
                  </a>
                  <div className='text-gray-700 fs-7'>25 July, 2024</div>
                </div>
              </div>
            </div>

            <div className='d-flex flex-stack py-2'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-40px me-4'>
                  <span className={clsx('symbol-label', `bg-light-danger text-danger`)}>
                    {' '}
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M12 9v4' />
                      <path d='M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z' />
                      <path d='M12 16h.01' />
                    </svg>
                  </span>
                </div>

                <div className='mb-0 me-2'>
                  <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-semibold'>
                    <strong>Onboarding</strong> for all employees is incomplete
                  </a>
                  <div className='text-gray-700 fs-7'>20 July, 2024</div>
                </div>
              </div>
            </div>

            {alerts.length > 0 ? (
              alerts
                // .filter((data) => data.tenantId === currentUser?.tenantId)
                .map((alert) => (
                  <div className='d-flex flex-stack py-2'>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-40px me-4'>
                        <span className={clsx('symbol-label', `bg-light-danger text-danger`)}>
                          {' '}
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='18'
                            height='18'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-circle-check'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
                            <path d='M9 12l2 2l4 -4' />
                          </svg>
                        </span>
                      </div>

                      <div className='mb-0 me-2'>
                        <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-semibold'>
                          {alert.title}
                        </a>
                        <div className='text-gray-400 fs-7'>{alert.task.date}</div>
                      </div>
                    </div>
                    {/* <span className='badge badge-light fs-8'>{alert.time}</span> */}
                  </div>
                ))
            ) : (
              <>
                <tr>
                  <td colSpan={6} className='text-center '>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='30'
                      height='30'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#5A5A5A'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-clock mt-20 mb-4'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                      <path d='M12 7v5l3 3' />
                    </svg>
                    <h3 className='mb-4'>No recent alerts</h3>
                    <p className='fw-semibold mb-20'>You'll see all your teams alerts here</p>
                  </td>
                </tr>
              </>
            )}
          </div>
          {/* <div className='py-3 text-center border-top'>
          <Link
            to='/crafted/pages/profile'
            className='btn btn-color-gray-600 btn-active-color-primary'
          >
            View All <KTIcon iconName='arrow-right' className='fs-5' />
          </Link>
        </div> */}
        </div>

        {/* <div className='tab-pane fade show active' id='kt_topbar_notifications_2' role='tabpanel'>
        <div className='d-flex flex-column px-9'>
          <div className='pt-10 pb-0'>
            <h3 className='text-dark text-center fw-bolder'>Get Pro Access</h3>

            <div className='text-center text-gray-600 fw-bold pt-1'>
              Outlines keep you honest. They stoping you from amazing poorly about drive
            </div>

            <div className='text-center mt-5 mb-9'>
              <a
                href='#'
                className='btn btn-sm btn-primary px-6'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_upgrade_plan'
              >
                Upgrade
              </a>
            </div>
          </div>

          <div className='text-center px-4'>
            <img className='mw-100 mh-200px' alt='metronic' src={useIllustrationsPath('1.png')} />
          </div>
        </div>
      </div> */}

        <div className='tab-pane fade show active' id='kt_topbar_notifications_2' role='tabpanel'>
          <div className='scroll-y mh-325px my-5 px-8'>
            {/* {defaultLogs.map((log, index) => (
            <div key={`log${index}`} className='d-flex flex-stack py-4'>
              <div className='d-flex align-items-center me-2'>
                <span className={clsx('w-70px badge', `badge-light-${log.state}`, 'me-4')}>
                  {log.code}
                </span>

                <a href='#' className='text-gray-800 text-hover-primary fw-bold'>
                  {log.message}
                </a>

                <span className='badge badge-light fs-8'>{log.time}</span>
              </div>
            </div>
          ))} */}
            {activities.length > 0 ? (
              activities
                // .filter((data) => data.tenantId === currentUser?.tenantId)
                .map((activity) => (
                  <tr>
                    <td>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='#1CBB8D'
                        className='icon icon-tabler icons-tabler-filled icon-tabler-point me-2'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M12 7a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z' />
                      </svg>
                    </td>

                    <td>
                      <div className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {/* <strong>
                          {activity.username === currentUser?.userName ? 'You' : activity.username}
                        </strong>{' '} */}
                        {activity.message}
                        <strong> {activity.personnelName || ''}</strong>
                      </div>
                      <span className='text-muted fw-semibold text-muted d-block fs-7 mb-2'>
                        {formatDate(activity.date)}
                      </span>
                    </td>
                  </tr>
                ))
            ) : (
              <>
                <tr>
                  <td colSpan={6} className='text-center '>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='30'
                      height='30'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#5A5A5A'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-clock mt-20 mb-4'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                      <path d='M12 7v5l3 3' />
                    </svg>
                    <h3 className='mb-4'>No recent activities</h3>
                    <p className='fw-semibold mb-20'>You'll see all your teams activity here</p>
                  </td>
                </tr>
              </>
            )}
          </div>
          {/* <div className='py-3 text-center border-top'>
          <Link
            to='/crafted/pages/profile'
            className='btn btn-color-gray-600 btn-active-color-primary'
          >
            View All <KTIcon iconName='arrow-right' className='fs-5' />
          </Link>
        </div> */}
        </div>
      </div>
    </div>
  )
}

export {HeaderNotificationsMenu}
