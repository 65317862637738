
import { KTIcon } from "../../../../../_metronic/helpers"
import { AddPersonnelModal } from "../../../personnel-management/components/AddPersonnelModal"
import {AddUser }from "../AddUser"

const UserListToolbar = () => {
//   const {setItemIdForUpdate} = useListView()
//   const openAddUserModal = () => {
//     setItemIdForUpdate(null)
//   }
//   const exportData = async () => {
//     const db = getFirestore()
//     const collectionRef = collection(db, 'PersonnelManagement')
//     const querySnapshot = await getDocs(collectionRef)
   
//     // const data = querySnapshot.docs.map((doc) => doc.data())
//     const data = querySnapshot.docs.map((doc) => {
//       const {tenantId, uid, ...rest} = doc.data()
//       return rest
//     })
//     console.log("Export data",data);

    
//     if (data.length > 0) {
//       const fields = Object.keys(data[0]); // Get the field names from the first data item
//       const csvContent = [fields.join(',')]; // Add the field names as the first row
//       data.forEach((row) => {
//         const rowData = fields.map((field) => {
//           return `"${row[field] || ''}"`;// Enclose the field value in double quotes to handle special characters
//         }).join(',');
//         csvContent.push(rowData);
//       });
//       // Add the data rows
//       const csvBlob = new Blob([csvContent.join('\n')], { type: 'text/csv;charset=utf-8' });
//       saveAs(csvBlob, 'data.csv');
//     }
//   };

  return (
    <div className='d-flex align-items-center justify-content-between position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='Search Users'
            //   value={searchTerm}
            //   onChange={handleInputChange}
            />
            <AddUser />
          </div>
  )
}

export {UserListToolbar}
