import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useAuth} from '../../../modules/auth'
import {addDoc, collection, doc, onSnapshot, serverTimestamp, updateDoc} from 'firebase/firestore'
import db from '../../../../services/firebase'
import Swal from 'sweetalert2'

interface UserData {
  uid: string
  id: string
  full_name: string
  role: string
  avatarUrl: string
  tenantId: string
  policiesAccepted?: boolean
  start_date: string
  group: string
  end_date: string
  email: string
}

interface OffboardingProps {
  isOpen: boolean
  onClose: () => void
  id: string
}

const Offboarding: React.FC<OffboardingProps> = ({isOpen, onClose, id}) => {
  const [users, setUsers] = useState<UserData[]>([])
  const [showModal, setShowModal] = useState(false)
  const {currentUser} = useAuth()

  const [endDate, setEndDate] = useState('')

  useEffect(() => {
    setShowModal(isOpen)
  }, [isOpen])

  const handleClose = () => {
    setShowModal(false)
    onClose()
  }

  useEffect(() => {
    getPersonnel()
  }, [id])

  const getPersonnel = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'employees')

      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const risksData = snapshot.docs
          .map((doc) => ({
            ...(doc.data() as UserData),
            id: doc.id,
            full_name: doc.data().full_name,
            end_date: doc.data().end_date,
          }))
          .filter((data) => data.id === id)
        setUsers(risksData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting personnels:', error)
    }
  }

  console.log(users)

  const handleChangeEndDate = (e) => {
    setEndDate(e.target.value)
  }

  const handleConfirmOffboard = async () => {
    // if (selectedItem) {
    //   const updatedItem = {...selectedItem, endDate}

    const currentDate = new Date() // No need to import the Firestore module
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1 // Note: The month is zero-based
    const day = currentDate.getDate()

    const todaysDate = month + '/' + day + '/' + year

    try {
      // await personnelServices.updatePersonnel(selectedItem.id, updatedItem)
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)

      const assetRef = doc(tenantRef, 'employees', id)
      await updateDoc(assetRef, {end_date: endDate})

      const activitiesRef = collection(tenantRef, 'activities')
      await addDoc(activitiesRef, {
        message: ` ${users[0].full_name} has been Offboarded by ${currentUser?.userName} `,
        timestamp: serverTimestamp(),
        tenantId: currentUser?.tenantId,
        // email: email,
        uid: currentUser?.uid,
        username: currentUser?.userName,
        action: 'Employee offboarded',
        target: 'Employees',
        email: currentUser?.email,
        date: todaysDate,
      })

      setShowModal(false)
      setEndDate('')
      Swal.fire({
        icon: 'success',
        title: 'Offboarded',
        text: ` ${users[0].full_name} has been offboarded successfully.`,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      })
    } catch (error) {
      console.error('Error updating personnel:')
    }
    // }
  }

  return (
    <Modal show={showModal} onHide={handleClose} backdropClassName='bg-opacity-1' centered>
      <Modal.Header closeButton>
        <Modal.Title>Offboard {users.length > 0 ? users[0].full_name : ''}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='fs-6'>
          Set the employee's last working day below to initiate the offboarding. Please note that
          your employee will be noticed to complete the assignment checklist.
        </div>
        <div className='fv-row mb-2 mt-7'>
          <label className=' fw-bold fs-6 mb-2'>End Date</label>
          <input
            placeholder='Enter Checklist Date'
            type='date'
            className='form-control  mb-3 mb-lg-0 w-100'
            name='endDate'
            value={endDate || users[0]?.end_date}
            onChange={handleChangeEndDate}
            autoComplete='off'
          />
        </div>
      </Modal.Body>
      <Modal.Footer className='p-0 px-6 py-4'>
        <button type='button' className='btn btn-light fs-6 mx-4' onClick={handleClose}>
          Close
        </button>
        {/* <div  style={{cursor: 'pointer'}}> */}
        <button
          className='btn btn-sm fs-6'
          onClick={() => (endDate ? handleConfirmOffboard() : null)}
          onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
            (e.currentTarget.style.backgroundColor = '#148363')
          }
          onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
            (e.currentTarget.style.backgroundColor = '#1CBB8D')
          }
          // disabled={activities.length === 0}
          style={{
            backgroundColor: '#1CBB8D',
            color: '#fff',
            height: '40px',
            borderRadius: '4px',

            transition: 'background-color 0.3s',
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            className='icon icon-tabler icons-tabler-outline icon-tabler-plus mx-1'
          >
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <path d='M12 5l0 14' />
            <path d='M5 12l14 0' />
          </svg>
          Offboard Employee
        </button>
        {/* </div> */}
      </Modal.Footer>
    </Modal>
  )
}

export default Offboarding
