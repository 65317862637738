import {useListView} from '../../../vendor/core/ListViewProvider'
import {RiskListGrouping} from './RiskListGrouping'
import {RiskListSearchComponent} from './RiskListSearchComponent'
import {RiskListToolbar} from './RiskListToolbar'

const RiskListHeader = () => {
  const {selected} = useListView()
  return (
    <></>
    // <div className='card-header border-0 pt-6'>
    //   <RiskListSearchComponent />
    //   {/* begin::Card toolbar */}
    //   <div className='card-toolbar'>
    //     {/* begin::Group actions */}
    //     {selected.length > 0 ? <RiskListGrouping /> : <RiskListToolbar />}
    //     {/* end::Group actions */}
    //   </div>
    //   {/* end::Card toolbar */}
    // </div>
  )
}

export {RiskListHeader}
