import React, {FC, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
// import {VendorRisk} from './VendorRisk'
import {ControlInformationListHeader} from './components/header/ControlInformationListHeader'
import {KTCard} from '../../../_metronic/helpers'
import {ToastContainer, toast} from 'react-toastify'
import {ControlInformationManagement} from './ControlInformationManagement'

export const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ControlInformationWrapper: FC = () => {
  const [assetID, setAssetID] = useState('')
  const [parentDataCount, setParentDataCount] = useState(0)
  const [childData, setChildData] = useState(null)
  const [alertMessage, setAlertMessage] = useState(null)

  const getAssetIdHandler = (id) => {
    setAssetID(id)
  }

  const handleDataCountChange = (dataCount) => {
    // Do something with the received dataCount, e.g., set it in the parent component's state
    setParentDataCount(dataCount)
  }

  const receiveDataFromChild = (dataFromChild) => {
    console.log('Data from child', dataFromChild)
    setChildData(dataFromChild)
  }

  return (
    <>
      <PageTitle
        icon='personnelIcon.svg'
        title='Control Management'
        desc={`${parentDataCount + ' ' + 'assets'}`}
        breadcrumbs={usersBreadcrumbs}
      >
        Control Management
      </PageTitle>
      {/* <ControlInformationListHeader /> */}
      <ControlInformationManagement />
      {/* {assetID !== undefined && (
          <EditAssetModel
            id={assetID}
            setAssetID={setAssetID}
            sendDataToParent={receiveDataFromChild}
          />
        )} */}

      {/* {assetID !== undefined && <AddAsset alertMessage={receiveAlertMessage} />} */}
      {/* <VendorRisk className='card-xl-stretch mb-xl-8' /> */}
      <ToastContainer />
    </>
  )
}

export default ControlInformationWrapper
