/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {RiskListToolbar} from '../components/header/RiskListToolbar'
import Swal from 'sweetalert2'
import db from '../../../../services/firebase'
// import firebase from '../../../../services/firebase'
import {RiskListLoading} from '../components/loading/RiskListLoading'
import {Helmet} from 'react-helmet'
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
  writeBatch,
} from 'firebase/firestore'
import {EditRiskModal} from '../components/EditRiskModal'
import {useParams} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'
import {Overlay, OverlayTrigger, Popover, Tooltip} from 'react-bootstrap'

type LocationState = {
  showToast?: boolean
  toastMessage?: string
}

type Props = {
  className: string
  getRiskID: any
}

const RiskManagement: React.FC<Props> = ({getRiskID}) => {
  const [risk, setRisk] = useState([] as any[])
  // console.log('Reference')
  const {currentUser} = useAuth()
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredItems, setFilteredItems] = useState<any[]>([])
  const [searchLength, setSearchLength] = useState(false)
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [riskID, setRiskID] = useState('')
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const navigate = useNavigate()
  const {id: riskId} = useParams<{id: string}>()
  const location = useLocation()
  const [highlightedRiskId, setHighlightedRiskId] = useState<string | null>(null)
  const [checkedItems, setCheckedItems] = useState<any[]>([])
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [show1, setShow1] = useState(false)
  const target = useRef(null)
  const overlayRef = useRef<HTMLDivElement>(null)
  const [personnels, setPersonnels] = useState<any[]>([])
  const [selectedOwner, setSelectedOwner] = useState('')
  const params = new URLSearchParams(location.search)
  const id = params.get('id')

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 3000)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    getRisk()
  }, [])

  useEffect(() => {
    setFilteredItems(risk)
  }, [risk])

  useEffect(() => {
    if (id) {
      setRiskID(id)
      setIsModalOpen(true)
    }
  }, [id])

  useEffect(() => {
    const state = location.state as LocationState | null
    if (state?.showToast) {
      toast.success(state.toastMessage, {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })
    }
  }, [location])

  useEffect(() => {
    getRisk()
    if (riskId) {
      setHighlightedRiskId(riskId)
      setTimeout(() => {
        setHighlightedRiskId(null)
      }, 5000)
    }
  }, [riskId])

  const getRisk = async () => {
    try {
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'risks')

      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const risksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setRisk(risksData)
        setShowEmptyState(false)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      // Logout()
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setPersonnels(risksData)
    })

    return unsubscribe
  }, [])

  const toggleDropdown = () => {
    // console.log('clicked')
    setDropdownVisible(!isDropdownVisible)
  }

  const deleteHandler = async (riskData) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${riskData.riskSource}`,
        text: `Are you sure you want to remove this risk? This will also remove its association from all linked controls. This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        // await riskServices.deleteRisk(riskData.id)
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const policyRef = collection(tenantRef, 'risks')
        const docRef = doc(policyRef, riskData.id)
        await deleteDoc(docRef)

        const controlUpdates = riskData.added_controls.map(async (control) => {
          const controlRef = doc(collection(tenantRef, 'controls'), control.id)

          const controlDoc = await getDoc(controlRef)
          if (controlDoc.exists()) {
            const controlData = controlDoc.data()

            const updatedRiskScenario = controlData.risk_scenario.filter(
              (risk) => risk.riskId !== riskData.id
            )

            await updateDoc(controlRef, {risk_scenario: updatedRiskScenario})
          }
        })

        await Promise.all(controlUpdates)

        getRisk()

        toast.success('Risk scenario has been deleted successfully!', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: `"${riskData.riskSource}" has been deleted and removed from all associated controls.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the risks and updating associated controls.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      // setDropdownVisible(false)
    }
  }

  // console.log('Risk', risk.length)
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    if (searchTerm === '') {
      setFilteredItems(risk)
      setSearchLength(false)
    } else {
      const filteredItems = risk.filter((user) =>
        user.riskSource.toLowerCase().includes(searchTerm)
      )
      setFilteredItems(filteredItems)
      setSearchLength(filteredItems.length === 0)
    }
  }

  const handleOpenModal = (id) => {
    setIsModalOpen(true)
    setRiskID(id)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleDeselectClick = () => {
    setCheckedItems([])
    setSelectAll(false)
  }

  const handleSelectAllChange = (e) => {
    setSelectAll(e.target.checked)
    if (e.target.checked) {
      setCheckedItems(
        filteredItems
          // .filter((user) => user.tenantId === currentUser?.tenantId)
          .map((item) => item.id)
      )
    } else {
      setCheckedItems([])
    }
  }

  const getBadgeClass = (risk) => {
    if (risk < 5) {
      return {backgroundColor: '#1CBB8D'} // Low risk (primary blue)
    } else if (risk >= 5 && risk <= 14) {
      return {backgroundColor: '#ffc107'} // Medium risk (warning yellow)
    } else if (risk >= 15 && risk < 25) {
      return {backgroundColor: '#dc3545'} // High risk (danger red)
    } else if (risk >= 25) {
      return {backgroundColor: '#A7222F'} // Critical risk (custom dark red)
    } else {
      return {backgroundColor: ''} // Default or undefined risk (secondary gray)
    }
  }
  const getRiskLevelText = (risk) => {
    if (risk < 5) {
      return 'Low'
    } else if (risk >= 5 && risk <= 14) {
      return 'Medium'
    } else if (risk >= 15 && risk < 25) {
      return 'High'
    } else if (risk >= 25) {
      return 'Critical'
    } else {
      return ''
    }
  }

  const handleCheckChange = (e) => {
    const {checked, value} = e.target
    setCheckedItems((prev) => (checked ? [...prev, value] : prev.filter((item) => item !== value)))
  }

  const handleAssignOwner = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!selectedOwner) {
      alert('Please select an owner')
      return
    }

    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) return

    const tenantRef = doc(db, 'tenants', tenantId)
    const batch = writeBatch(db)

    for (const riskId of checkedItems) {
      const riskRef = doc(tenantRef, 'risks', riskId)
      batch.update(riskRef, {owner: selectedOwner})
    }

    try {
      await batch.commit()
      // alert(`Successfully assigned ${selectedOwner} to ${checkedItems.length} test(s)`)
      Swal.fire({
        icon: 'success',
        title: 'Updated owner',
        text: ` "Successfully assigned ${selectedOwner} to ${checkedItems.length} test(s)`,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      })
      setShow1(false)
      setCheckedItems([])
      setSelectAll(false)
      setSelectedOwner('')
    } catch (error) {
      console.error('Error assigning owner:', error)
      alert('Failed to assign owner. Please try again.')
    }
  }

  const handleResetClick = () => {
    setSelectedOwner('')
  }

  return (
    <>
      <Helmet>
        <title>Secureslate | Risk Management</title>
      </Helmet>
      {/* begin::Header */}
      {/* <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Task Lists</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Avd 57 orders per day</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm btn-success'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
            style={{backgroundColor: '#44DBAF'}}
          >
            <KTIcon iconName='plus' className='fs-3' />
            Add Task
          </a>
        </div>
      </div> */}

      {/* end::Header */}
      <div className='card-header border-0 pt-6 h-100'>
        <div className='card-title'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control  w-250px ps-14'
              placeholder='Search..'
              value={searchTerm}
              onChange={handleInputChange}
            />
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          {/* begin::Group actions */}
          {checkedItems.length > 0 ? (
            <>
              {checkedItems.length > 0 ? (
                <div className='  border-end pe-4 text-center' style={{color: '#A1A5B7'}}>
                  <span>
                    <p className='pt-4'>{checkedItems.length} Selected</p>
                  </span>
                </div>
              ) : null}
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div className='mx-5' style={{cursor: 'pointer'}} onClick={handleDeselectClick}>
                  <img src='/media/buttons/Deselect.svg' alt='Deselect' />
                </div>

                <button
                  className='btn btn-sm fs-6'
                  ref={target}
                  onClick={() => setShow1(!show1)}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                  // disabled={activities.length === 0}
                  style={{
                    backgroundColor: '#1CBB8D',
                    color: '#fff',
                    width: '130px',
                    height: '40px',
                    borderRadius: '4px',
                    left: '615px',
                    top: '20px',
                    transition: 'background-color 0.3s',
                  }}
                >
                  Assign Owner
                </button>
              </div>
              <Overlay
                show={show1}
                target={target.current}
                placement='bottom'
                container={document.body}
                containerPadding={10}
              >
                {(props) => (
                  <Popover id='popover-contained' {...props}>
                    <div ref={overlayRef}>
                      {/* <Popover id='popover-contained'> */}
                      {/* <Popover.Header as='h3' className='fw-bold fs-5'>
                      Assign Owner
                    </Popover.Header> */}
                      <Popover.Body onClick={(e) => e.stopPropagation()}>
                        <form onSubmit={handleAssignOwner}>
                          <div className='mb-3'>
                            {/* <label htmlFor='owner'>Owner</label> */}
                            <div className='mb-3 d-flex align-items-center'>
                              <select
                                id='owner'
                                className='form-select form-control'
                                aria-label='Select Owner'
                                value={selectedOwner}
                                onChange={(e) => setSelectedOwner(e.target.value)}
                              >
                                <option value='' disabled selected>
                                  Select Owner
                                </option>
                                {personnels
                                  .filter((user) => user.role === 'Admin')
                                  .map((personnel) => (
                                    <option key={personnel.id} value={personnel.full_name}>
                                      {personnel.full_name}
                                    </option>
                                  ))}
                              </select>
                              {selectedOwner && (
                                <button
                                  className='btn btn-link p-0'
                                  onClick={() => setSelectedOwner('')}
                                >
                                  <span aria-hidden='true' className='mx-2 fw-bold fs-2'>
                                    &times;
                                  </span>
                                </button>
                              )}
                            </div>
                          </div>
                          <button
                            type='button'
                            onClick={handleResetClick}
                            className='btn btn-sm fs-6'
                            onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                              (e.currentTarget.style.backgroundColor = '#148363')
                            }
                            onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                              (e.currentTarget.style.backgroundColor = '#f1fdf9')
                            }
                            style={{
                              backgroundColor: '#f1fdf9',
                              color: '#45dbaf',
                              width: '80px',
                              // height: '40px',
                              borderRadius: '4px',
                              left: '615px',
                              top: '20px',
                              transition: 'background-color 0.3s',
                              marginRight: '12px',
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type='submit'
                            className='btn btn-sm fs-6'
                            onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                              (e.currentTarget.style.backgroundColor = '#148363')
                            }
                            onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                              (e.currentTarget.style.backgroundColor = '#1CBB8D')
                            }
                            style={{
                              backgroundColor: '#1CBB8D',
                              color: '#fff',
                              width: '80px',
                              // height: '40px',
                              borderRadius: '4px',
                              left: '615px',
                              top: '20px',
                              transition: 'background-color 0.3s',
                            }}
                          >
                            Apply
                          </button>
                        </form>
                      </Popover.Body>
                    </div>
                  </Popover>
                )}
              </Overlay>
            </>
          ) : (
            <RiskListToolbar />
          )}
          {/* end::Group actions */}
        </div>
      </div>
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive' style={{minHeight: '100vh'}}>
          {/* begin::Table */}
          {/* table-row-dashed */}
          <table className='table  table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold fs-7' style={{color: '#5A5A5A'}}>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={selectAll}
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                      onChange={handleSelectAllChange}
                    />
                  </div>
                </th>
                <th className='min-w-200px'>SCENARIO</th>
                <th className='min-w-100px'>INHERENT RISK</th>
                <th className='min-w-100px'>RESIDUAL RISK</th>
                <th className='min-w-100px'>MAPPED CONTROLS</th>
                <th className='min-w-150px'>OWNER</th>
                <th className='min-w-100px'>STATUS</th>
                <th className='min-w-10px'></th>
              </tr>
            </thead>
            <ToastContainer />
            <EditRiskModal id={riskID} isOpen={isModalOpen} onClose={handleCloseModal} />

            {filteredItems && filteredItems.length > 0 ? (
              filteredItems.map((riskData) => {
                return (
                  <tbody>
                    <tr
                      style={{
                        backgroundColor: riskData.id === highlightedRiskId ? '#f6f6f6' : '',
                        transition: 'background-color 0.3s ease-in-out',
                      }}
                    >
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input widget-9-check'
                            type='checkbox'
                            value={riskData.id}
                            checked={selectAll || checkedItems.includes(riskData.id)}
                            onChange={handleCheckChange}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            {/* <Link
                              to={`/risk-management/${riskData.id}/details`}
                              className='text-dark fw-bold text-hover-primary d-block fs-6'
                            >
                              {riskData?.riskSource}
                            </Link> */}
                            <a
                              href='#'
                              className='text-dark fw-bold text-hover-primary d-block fs-6'
                              onClick={() => handleOpenModal(riskData.id)}
                            >
                              {riskData?.riskSource}
                            </a>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <span className='badge border p-2 rounded-pill'>
                            <span
                              className={`badge border rounded-circle mx-2 `}
                              style={{
                                width: '12px',
                                height: '12px',
                                padding: '3px',
                                ...getBadgeClass(
                                  riskData.inherent_impact * riskData.inherent_likelihood
                                ),
                              }}
                            >
                              {' '}
                            </span>
                            {getRiskLevelText(
                              riskData.inherent_impact * riskData.inherent_likelihood
                            )}
                            : {riskData.inherent_impact * riskData.inherent_likelihood}
                          </span>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <span className='badge border p-2 rounded-pill'>
                            <span
                              className={`badge border rounded-circle mx-2`}
                              style={{
                                width: '12px',
                                height: '12px',
                                padding: '3px',
                                ...getBadgeClass(
                                  riskData.residual_impact * riskData.residual_likelihood
                                ),
                              }}
                            >
                              {' '}
                            </span>
                            {getRiskLevelText(
                              riskData.residual_impact * riskData.residual_likelihood
                            )}
                            : {riskData.residual_impact * riskData.residual_likelihood}
                          </span>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <span className='fw-semibold  d-block fs-7'>
                            {riskData?.author === 'Custom risks'
                              ? riskData?.added_controls
                                ? riskData?.added_controls.length + ' ' + 'controls'
                                : '--'
                              : riskData?.recommendedControls
                              ? riskData?.recommendedControls.length + ' ' + 'controls'
                              : '--'}
                            <OverlayTrigger
                              placement='right'
                              overlay={
                                <Tooltip id='tooltip-top'>
                                  <ol>
                                    {riskData?.author === 'Custom risks'
                                      ? riskData.added_controls.map((control, index) => (
                                          <li key={index}>{control.control_title}</li>
                                        ))
                                      : riskData.recommendedControls.map((control, index) => (
                                          <li key={index}>{control}</li>
                                        ))}
                                  </ol>
                                </Tooltip>
                              }
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#5A5A5A'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                                <path d='M12 9h.01' />
                                <path d='M11 12h1v4h1' />
                              </svg>
                            </OverlayTrigger>
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          {riskData?.owner ? (
                            <>
                              <div className='symbol symbol-35px me-2'>
                                <span
                                  className='symbol-label fs-7 fw-bold'
                                  style={{backgroundColor: '#DAF9EF'}}
                                >
                                  {(() => {
                                    const nameParts = riskData?.owner.split(' ')
                                    if (nameParts.length > 1) {
                                      return `${nameParts[0].charAt(0).toUpperCase()}${nameParts[1]
                                        .charAt(0)
                                        .toUpperCase()}`
                                    } else {
                                      return `${nameParts[0].charAt(0).toUpperCase()}${nameParts[0]
                                        .charAt(1)
                                        .toUpperCase()}`
                                    }
                                  })()}
                                </span>
                              </div>
                              <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                {riskData?.owner}
                              </a>
                            </>
                          ) : (
                            <span>--</span>
                          )}
                        </div>
                      </td>

                      <td>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2'>
                            <div className='clearfix bshadow0 pbs fw-semibold'>
                              {/* {riskData?.riskStatus === 'Incomplete' ? (
                                <>
                                  <span
                                    className='badge rounded-pill p-1 mt-2'
                                    style={{border: '1px solid #C5C5C5', width: '110px'}}
                                  >
                                    <span className='mx-2'>
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='18'
                                        height='18'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='#EE3043'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        className='icon icon-tabler icons-tabler-outline icon-tabler-xbox-x '
                                      >
                                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                        <path d='M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z' />
                                        <path d='M9 8l6 8' />
                                        <path d='M15 8l-6 8' />
                                      </svg>
                                    </span>
                                    <span className='fs-7'>Incomplete</span>
                                  </span>
                                </>
                              ) : riskData?.riskStatus === 'Needs approval' ? (
                                <>
                                  <span
                                    className='badge rounded-pill p-1 mt-2'
                                    style={{border: '1px solid #C5C5C5', width: '140px'}}
                                  >
                                    <span className='mx-2'>
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='18'
                                        height='18'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='#FFA800'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        className='icon icon-tabler icons-tabler-outline icon-tabler-file-check'
                                      >
                                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                        <path d='M14 3v4a1 1 0 0 0 1 1h4' />
                                        <path d='M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z' />
                                        <path d='M9 15l2 2l4 -4' />
                                      </svg>
                                    </span>
                                    <span className='fs-7'>Needs Approval</span>
                                  </span>
                                </>
                              ) : (
                                riskData?.riskStatus === 'Approved' && (
                                  <>
                                    <span
                                      className='badge rounded-pill p-1 mt-2'
                                      style={{border: '1px solid #C5C5C5', width: '110px'}}
                                    >
                                      <span className='mx-2'>
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='20'
                                          height='20'
                                          viewBox='0 0 24 24'
                                          fill='none'
                                          stroke='#1CBB8D'
                                          stroke-width='2'
                                          stroke-linecap='round'
                                          stroke-linejoin='round'
                                          className='icon icon-tabler icons-tabler-outline icon-tabler-circle-check'
                                        >
                                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                          <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
                                          <path d='M9 12l2 2l4 -4' />
                                        </svg>
                                      </span>
                                      <span className='fs-7'>Approved</span>
                                    </span>
                                  </>
                                )
                              )} */}
                              <span
                                className={`badge fs-7 ${
                                  riskData?.risk_status === 'Incomplete'
                                    ? 'badge-danger'
                                    : riskData?.risk_status === 'Needs approval'
                                    ? 'badge-warning'
                                    : 'badge-primary'
                                }`}
                                style={{
                                  color:
                                    riskData?.risk_status === 'Incomplete'
                                      ? '#EE3043'
                                      : riskData?.risk_status === 'Needs approval'
                                      ? '#FFA800'
                                      : '#1CBB8D',
                                  background:
                                    riskData?.risk_status === 'Incomplete'
                                      ? '#FFF5F8'
                                      : riskData?.risk_status === 'Needs approval'
                                      ? '#FFF8DD'
                                      : '#E8F8F4',
                                }}
                              >
                                {riskData?.risk_status}
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>

                      <tr>
                        {/* <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                // onClick={(e) => getRiskID(riskData.id)}
                                onClick={() => handleOpenModal(riskData.id)}
                                // data-bs-toggle='modal'
                                data-bs-target='#kt_modal_9'
                              >
                                <KTIcon iconName='pencil' className='fs-3' />
                              </button>
                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                onClick={(e) => deleteHandler(riskData)}
                              >
                                <KTIcon iconName='trash' className='fs-3' />
                              </button>
                            </div>
                          </td> */}
                        <td className=''>
                          <div className='dropdown mx-2'>
                            <button
                              className='btn btn-secondary border-0 p-0 m-2'
                              type='button'
                              id='dropdownMenu2'
                              data-bs-toggle='dropdown'
                              aria-haspopup='true'
                              aria-expanded='false'
                            >
                              <img
                                src='/media/buttons/Options.svg'
                                alt='Options'
                                onClick={toggleDropdown}
                              />
                            </button>
                            <ul className='dropdown-menu' aria-labelledby='dropdownMenu2'>
                              <li>
                                <button
                                  className='dropdown-item text-muted text-hover-primary'
                                  type='button'
                                  onClick={() => handleOpenModal(riskData.id)}
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='18'
                                    height='18'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    stroke-width='2'
                                    stroke-linecap='round'
                                    stroke-linejoin='round'
                                    className='icon icon-tabler icons-tabler-outline icon-tabler-edit mx-1 mb-1'
                                  >
                                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                    <path d='M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1' />
                                    <path d='M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z' />
                                    <path d='M16 5l3 3' />
                                  </svg>{' '}
                                  Edit Risk
                                </button>
                              </li>

                              <li>
                                <button
                                  className='dropdown-item'
                                  type='button'
                                  style={{color: '#F15363'}}
                                  onClick={(e) => deleteHandler(riskData)}
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='18'
                                    height='18'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='#F15363'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-1 mb-1'
                                  >
                                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                    <path d='M4 7l16 0' />
                                    <path d='M10 11l0 6' />
                                    <path d='M14 11l0 6' />
                                    <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                    <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                  </svg>{' '}
                                  Delete Risk
                                </button>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </tr>
                  </tbody>
                )
              })
            ) : (
              <tr style={{height: '100%'}}>
                {searchLength ? (
                  <td colSpan={8}>
                    <div
                      className='d-flex justify-content-center align-items-center text-center'
                      style={{height: '100%'}}
                    >
                      <div className='m-20'>
                        <img src={toAbsoluteUrl('/media/emptystate/riskstate.svg')} alt='' />
                        <div className='m-5 fs-5'>
                          <p style={{fontWeight: 'bold'}}>No risk scenario found!</p>
                        </div>
                        <p>
                          We couldn't find any risk scenario that match your search. Maybe try a
                          different search!
                        </p>
                      </div>
                    </div>
                  </td>
                ) : (
                  <td colSpan={8}>
                    <div
                      className='d-flex justify-content-center align-items-center text-center'
                      style={{height: '100%'}}
                    >
                      {!showEmptyState ? (
                        <div className='m-20'>
                          <RiskListLoading />
                        </div>
                      ) : (
                        <div className='m-20'>
                          <img src={toAbsoluteUrl('/media/emptystate/riskstate.svg')} alt='' />
                          <div className='m-4 fs-5'>
                            <p style={{fontWeight: 'bold'}}>No Risks Scenario added</p>
                          </div>
                          <p>Click on 'Add Scenario' to assess risk</p>
                        </div>
                      )}
                    </div>
                  </td>
                )}
              </tr>
            )}

            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </>
  )
}

export {RiskManagement}
