import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Link, useLocation, useParams} from 'react-router-dom'

const NotionDocuments = () => {
  const [activeTab, setActiveTab] = useState('details')
  const location = useLocation()
  const {id} = useParams()
  useEffect(() => {
    // Fetch control data when the component mounts
    if (location.pathname.includes('details')) {
      setActiveTab('details')
    } else if (location.pathname.includes('documents')) {
      setActiveTab('documents')
    }
  }, [id])
  console.log('Active Tab', activeTab)
  return (
    <>
      <div className={`card mb-5 mb-xl-8`}>
        <div className='card-header border-0 pt-5 mb-5'>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-65px me-5 '>
              <img src={toAbsoluteUrl(`/media/icons/duotune/social/Notion.svg`)} alt='' />
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <span className='card-label fw-bold fs-6'>Vendor</span>
              <h1 className='text-bold fw-bold'>Notion</h1>
            </div>
          </div>
        </div>
      </div>
      <div className='card card-custom'>
        {/* <div className='card-header'>
          <h3 className='card-title'>Add Asset</h3>
        </div> */}
        <div className='pt-4 pb-0 px-10'>
          {/*begin::Navs*/}
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-3x border-transparent fs-5 fw-semibold flex-nowrap'>
              {/*begin::Nav item*/}
              <li className='nav-item'>
                <Link
                  to={`/vendor-risk/notion/details`}
                  className={`nav-link me-6 ${
                    activeTab === 'details' ? 'text-active-primary active' : ''
                  }`}
                >
                  Details
                </Link>
              </li>
              {/*end::Nav item*/}
              {/*begin::Nav item*/}
              <li className='nav-item'>
                <Link
                  to={'/vendor-risk/notion/documents'}
                  className={`nav-link me-6 ${
                    activeTab === 'documents' ? 'text-active-primary active' : ''
                  }`}
                >
                  Documents
                </Link>
              </li>
              {/*end::Nav item*/}
            </ul>
          </div>
          {/*begin::Navs*/}
        </div>
        <div className='card-header pt-4'>
          <div className='card-title'>
            {/*begin::Search*/}
            <div className='d-flex align-items-center position-relative my-1'>
              <i className='ki-duotone ki-magnifier fs-1 position-absolute ms-6'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <input
                type='text'
                data-kt-filemanager-table-filter='search'
                className='form-control form-control-solid w-250px ps-15'
                placeholder='Search Files & Folders'
              />
            </div>
            {/*end::Search*/}
          </div>
          {/*begin::Card toolbar*/}
          <div className='card-toolbar'>
            {/*begin::Toolbar*/}
            <div className='d-flex justify-content-end' data-kt-filemanager-table-toolbar='base'>
              {/*begin::Export*/}
              <button
                type='button'
                className='btn btn-flex btn-light-primary me-3'
                id='kt_file_manager_new_folder'
              >
                <i className='ki-duotone ki-add-folder fs-2'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                Add Documents
              </button>
              {/*end::Export*/}
            </div>
            {/*end::Toolbar*/}
            {/*begin::Group actions*/}
            <div
              className='d-flex justify-content-end align-items-center d-none'
              data-kt-filemanager-table-toolbar='selected'
            >
              <div className='fw-bold me-5'>
                <span className='me-2' data-kt-filemanager-table-select='selected_count'></span>
                Selected
              </div>
              <button
                type='button'
                className='btn btn-danger'
                data-kt-filemanager-table-select='delete_selected'
              >
                Delete Selected
              </button>
            </div>
            {/*end::Group actions*/}
          </div>
          {/*end::Card toolbar*/}
        </div>
      </div>
    </>
  )
}

export default NotionDocuments
