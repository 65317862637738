/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren, toAbsoluteUrl} from '../../helpers'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../app/modules/auth'
import {collection, doc, onSnapshot} from 'firebase/firestore'
import db from '../../../services/firebase'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
})

const PageDataProvider: FC<WithChildren> = ({children}) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: string
  breadcrumbs?: Array<PageLink>
  title?: string
  icon?: string
  desc?: string
}

const DashPageTitle: FC<Props & WithChildren> = ({
  children,
  description,
  breadcrumbs,
  title,
  icon,
  desc,
}) => {
  const {setPageTitle, setPageDescription, setPageBreadcrumbs} = usePageData()
  const [grandTotal, setGrandTotal] = useState(0)
  const [policyTotal, setPolicyTotal] = useState(0)
  const [total, setTotal] = useState(0)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [personnelPoliciesLength, setPersonnelPoliciesLength] = useState(0)
  const {currentUser} = useAuth()
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 1000)

    // Clear the timeout to prevent memory leaks
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    setTotal(grandTotal + policyTotal)
    const derivedTotal = grandTotal + policyTotal

    if (derivedTotal === 100) {
      setLoading(true)
      return
    }

    setTotal(derivedTotal)
  }, [grandTotal, policyTotal, total, navigate])
  // const derivedTotal = grandTotal + policyTotal + onboardingTotal;

  // useEffect(() => {
  //   const fetchTrainingData = async () => {
  //     try {
  //       const tenantId = currentUser?.tenantId || ''
  //       if (!tenantId) {
  //         return
  //       }
  //       let totalCompleted = 0
  //       const tenantRef = doc(db, 'tenants', tenantId)
  //       const onboardingCollectionRef = collection(tenantRef, 'onboarding')
  //       const policyDocRef = doc(onboardingCollectionRef, 'training')

  //       // Retrieve the existing training document
  //       const policyDoc = await getDoc(policyDocRef)
  //       const policyData = policyDoc.data() || {}

  //       // Update the trainingsData state with the retrieved data
  //       // setTrainingsData(policyData || [])
  //       if(policyData.SecurityTraining[0].isChecked && policyData.PCISecure[0].isChecked ){
  //         totalCompleted += 40
  //       }else{
  //         totalCompleted += 0
  //       }
  //       setGrandTotal(totalCompleted)
  //     } catch (error) {
  //       console.error('Error fetching training data:', error)
  //     }
  //   }

  //   fetchTrainingData()
  // }, [currentUser])

  // useEffect(() => {
  //   const fetchTrainingData = async () => {
  //     try {
  //       const tenantId = currentUser?.tenantId || '';
  //       if (!tenantId) {
  //         return;
  //       }
  //       let totalCompleted = 0;
  //       const tenantRef = doc(db, 'tenants', tenantId);
  //       const onboardingCollectionRef = collection(tenantRef, 'onboarding');
  //       const policyDocRef = doc(onboardingCollectionRef, 'policy');

  //       // Retrieve the existing training document
  //       const policyDoc = await getDoc(policyDocRef);
  //       const policyData = policyDoc.data() || {};

  //       // Update the trainingsData state with the retrieved data
  //       // setPolicyData(policyData || []);

  //       // Check if all entries in policyData have isChecked set to true
  //       const allEntriesChecked = Object.values(policyData).every(entry => entry.isChecked);

  //       if (allEntriesChecked) {
  //         totalCompleted += 40;
  //       } else {
  //         totalCompleted += 0;
  //       }
  //       setPolicyTotal(totalCompleted)
  //     } catch (error) {
  //       console.error('Error fetching training data:', error);
  //     }
  //   };

  //   fetchTrainingData();
  // }, [currentUser]);

  // useEffect(() => {
  //   const fetchIdentityData = async () => {
  //     try {
  //       const tenantId = currentUser?.tenantId || '';
  //       const userId = currentUser?.uid || '';
  //       let totalCompleted = 0
  //       if (!tenantId || !userId) {
  //         // If either tenantId or userId is not available, exit early
  //         return;
  //       }

  //       const tenantRef = doc(db, 'tenants', tenantId);
  //       const identityDocRef = doc(tenantRef, 'onboarding', 'identity');

  //       // Fetch the identity document
  //       const identityDoc = await getDoc(identityDocRef);
  //       const identityData = identityDoc.exists() ? identityDoc.data() : null;

  //       if (identityData?.identity) {
  //         console.log(identityData.identity)
  //         totalCompleted += 20
  //       } else{
  //         totalCompleted += 0
  //       }
  //       setOnboardingTotal(totalCompleted)
  //     } catch (error) {
  //       console.error('Error fetching identity data:', error);

  //     }
  //   };

  //   fetchIdentityData();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentUser?.tenantId, currentUser?.uid]);

  useEffect(() => {
    const fetchTrainingData = async () => {
      try {
        const tenantId = currentUser?.tenantId || ''
        if (!tenantId) {
          return
        }
        let totalCompleted = 0
        const tenantRef = doc(db, 'tenants', tenantId)
        const onboardingCollectionRef = collection(tenantRef, 'onboarding')
        const policyDocRef = doc(onboardingCollectionRef, 'training')

        // Use onSnapshot to listen for realtime updates to the training document
        const unsubscribe = onSnapshot(policyDocRef, (doc) => {
          const policyData = doc.data() || {}

          // Check if SecurityTraining and PCISecure are checked
          if (
            policyData &&
            policyData.SecurityTraining?.[0]?.isChecked &&
            policyData.PCISecure?.[0]?.isChecked
          ) {
            totalCompleted += 50
          } else {
            totalCompleted += 0
          }

          setGrandTotal(totalCompleted)
        })

        return () => unsubscribe()
      } catch (error) {
        console.error('Error fetching training data:', error)
      }
    }

    fetchTrainingData()
  }, [grandTotal])
  const fetchData = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }
  
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'employees')
      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const employeesData = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
            fullName: doc.data().full_name,
            group: doc.data().group,
          }))
          .filter((data) => data.fullName === currentUser?.userName)
  
        console.log('employeesData', employeesData)
  
        if (employeesData.length > 0) {
          const group = employeesData[0].group
          const groupRef = collection(tenantRef, 'personnel_group')
          const unsubscribe = onSnapshot(groupRef, (snapshot) => {
            const groupData = snapshot.docs
              .map((doc) => ({
                ...doc.data(),
                id: doc.id,
                checklist: doc.data().checklist,
                groupName: doc.data().groupName,
              }))
              .filter((data) => data.groupName === group)
  
            if (groupData.length > 0 && groupData[0].checklist) {
              const checklist = groupData[0].checklist
              const checklistRef = collection(tenantRef, 'personnel_checklist')
              const unsubscribe2 = onSnapshot(checklistRef, (snapshot) => {
                const checklistData = snapshot.docs
                  .map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                    checklist: doc.data().checklist_name,
                    policies: doc.data().policies,
                  }))
                  .filter((data) => data.checklist === checklist)
  
                if (checklistData.length > 0 && checklistData[0].policies) {
                  setPersonnelPoliciesLength(checklistData[0].policies.length)
                } else {
                  setPersonnelPoliciesLength(0)
                }
              })
            } else {
              console.error('No group data found or checklist is missing')
              setPersonnelPoliciesLength(0)
            }
          })
        } else {
          console.error('No employees data found matching the current user')
        }
      })
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  
  useEffect(() => {
    const fetchTrainingData = async () => {
      try {
        const tenantId = currentUser?.tenantId || ''
        if (!tenantId) {
          return
        }
        let totalCompleted = 0
        const tenantRef = doc(db, 'tenants', tenantId)
        const onboardingCollectionRef = collection(tenantRef, 'onboarding')
        const policyDocRef = doc(onboardingCollectionRef, 'policy')

        // Use onSnapshot to listen for realtime updates to the policy document
        const unsubscribe = onSnapshot(policyDocRef, (doc) => {
          const policyData = doc.data() || {}

          const checkedEntries = Object.values(policyData).filter((entry) => entry.isChecked)
          const allEntriesChecked = checkedEntries.length === personnelPoliciesLength

          if (allEntriesChecked) {
            totalCompleted += 50
          } else {
            totalCompleted += 0
          }
          setPolicyTotal(totalCompleted)
        })

        return () => unsubscribe()
      } catch (error) {
        console.error('Error fetching training data:', error)
      }
    }

    fetchTrainingData()
  }, [policyTotal, personnelPoliciesLength])

  // useEffect(() => {
  //   const fetchIdentityData = async () => {
  //     try {
  //       const tenantId = currentUser?.tenantId || '';
  //       const userId = currentUser?.uid || '';
  //       let totalCompleted = 0
  //       if (!tenantId || !userId) {
  //         // If either tenantId or userId is not available, exit early
  //         return;
  //       }

  //       const tenantRef = doc(db, 'tenants', tenantId);
  //       const identityDocRef = doc(tenantRef, 'onboarding', 'identity');

  //       // Fetch the identity document
  //       const identityDoc = await getDoc(identityDocRef);
  //       const identityData = identityDoc.exists() ? identityDoc.data() : null;

  //       if (identityData?.identity) {
  //         console.log(identityData.identity)
  //         totalCompleted += 20
  //       } else{
  //         totalCompleted += 0
  //       }
  //       setOnboardingTotal(totalCompleted)
  //     } catch (error) {
  //       console.error('Error fetching identity data:', error);

  //     }
  //   };

  //   fetchIdentityData();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentUser?.tenantId, currentUser?.uid]);

  useEffect(() => {
    if (children) {
      setPageTitle(children.toString())
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  return (
    <>
      {showEmptyState && total !== 100 && (
        <div className={`card mb-5 mb-xl-8`}>
          <div className='card-header border-0 pt-2 pb-1 mb-2'>
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-40px me-5 '>
                <img src={toAbsoluteUrl(`/media/logos/${icon}`)} alt='' />
              </div>
              <div className='d-flex justify-content-start flex-column'>
                <span className='card-label fw-bold fs-4'>{title}</span>
                <span className='text-gray-800 fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                  {desc}
                </span>
              </div>
            </div>

            <div
              className='card-body d-flex align-items-end pt-2 justify-content-end'
              style={{paddingLeft: 5}}
            >
              <div className='d-flex align-items-center flex-column mt-5 w-50 justify-content-end'>
                <div className='d-flex flex-column flex-sm-row justify-content-between fw-bold fs-6 text-black opacity-75 w-100 mt-auto mb-2 pt-2'>
                  <span className='text-gray-800'>Status</span>

                  <span className='text-gray-800 fw-bold fs-7'>
                    {grandTotal === 50 && policyTotal === 50
                      ? '2 of 2 complete'
                      : (grandTotal === 50 ? 1 : 0) + (policyTotal === 50 ? 1 : 0) === 2
                      ? '2 of 2 complete'
                      : grandTotal === 50 || policyTotal === 50
                      ? '1 of 2 complete'
                      : '0 of 2 complete'}
                  </span>
                </div>

                <div className='h-8px mx-3 w-100 rounded' style={{backgroundColor: '#DAF9EF'}}>
                  <div
                    className='rounded h-8px'
                    role='progressbar'
                    style={{
                      width: `${Math.min(total, 100)}%`,
                      backgroundColor: '#44DBAF',
                    }}
                    aria-valuenow={Math.min(total, 100)}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
            </div>

            <a href='/training'>
              <button
                className='btn btn-sm'
                // onClick={openAddUserModal}
                // data-bs-toggle='modal'
                // data-bs-target='#kt_modal_4'
                style={{
                  backgroundColor: '#1CBB8D',
                  color: '#FFFFFF',
                  height: '50px',
                  width: '110px',
                  marginTop: '22px',
                }}
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#148363')
                }
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                }
              >
                {/* <KTIcon iconName='' className='fs-2 text-white' /> */}
                Get Started
              </button>
            </a>
          </div>
        </div>
      )}
    </>
  )
}

const PageDescription: FC<WithChildren> = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export {PageDescription, DashPageTitle, PageDataProvider, usePageData}
