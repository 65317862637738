import {KTIcon} from '../../../../../_metronic/helpers'
import {saveAs} from 'file-saver'
import {collection, doc, getDocs, getFirestore} from 'firebase/firestore'
import {useAuth} from '../../../../modules/auth'
import {toast} from 'react-toastify'

const AssetListToolbar = () => {
  const {currentUser} = useAuth()

  const exportData = async () => {
    const db = getFirestore()
    const tenantId = currentUser?.tenantId
    const tenantRef = doc(db, 'tenants', tenantId!)
    const PolicyRef = collection(tenantRef, 'assets')
    const querySnapshot = await getDocs(PolicyRef)

    const data = querySnapshot.docs.map((doc) => doc.data()).filter((doc) => !doc.is_repo)

    if (data.length > 0) {
      const fields = Object.keys(data[0]).filter((field) => field !== 'tenantId' && field !== 'uid') // Get the field names from the first data item
      const csvContent = [fields.join(',')] // Add the field names as the first row
      data.forEach((row) => {
        const rowData = fields
          .map((field) => {
            if (field === 'last_checkedin') {
              const date = new Date(row[field]) // Convert the string to a date object
              return `"${date.toLocaleDateString() || ''}"` // Convert date to date string
            } else {
              return `"${row[field] || ''}"` // Use the field value directly
            }
          })
          .join(',')
        csvContent.push(rowData)
      })
      // Add the data rows
      const csvBlob = new Blob([csvContent.join('\n')], {type: 'text/csv;charset=utf-8'})
      saveAs(csvBlob, 'Assets.csv')
    }
    toast.success('Assets data exported successfully', {
      position: toast.POSITION.TOP_CENTER,
      className: 'bg-white',
      style: {
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        border: '1px solid #e0e0e0',
        color: 'black',
      },
      progressStyle: {
        background: '#1CBB8D',
      },
      icon: ({theme, type}) => (
        <svg
          width='26'
          height='26'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          style={{color: '#1CBB8D'}}
        >
          <path
            d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
            fill='currentColor'
          />
        </svg>
      ),
      autoClose: 3000,
    })
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <VendorListFilter /> */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button> */}
      {/* begin::Export */}
      {/* <button
        type='button'
        className=' btn btn-sm btn-flex btn-secondary fw-bold me-4'
        onClick={exportData}
        style={{height: '40px'}}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-download me-1 mb-1'
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
          <path d='M7 11l5 5l5 -5' />
          <path d='M12 4l0 12' />
        </svg>
        Export CSV
      </button> */}
      <button
        type='button'
        className=' btn btn-sm btn-light me-4 fs-6'
        onClick={exportData}
        style={{height: '40px', background: '#fff', color: '#000', border: '2px solid #C5C5C5'}}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='#5A5A5A'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-download mb-1'
          style={{marginRight: '4px'}}
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
          <path d='M7 11l5 5l5 -5' />
          <path d='M12 4l0 12' />
        </svg>
        Export
      </button>

      {/* end::Export */}

      {/* begin::Add user */}
      {/* <button
        type='button'
        className='btn btn-primary'
        onClick={openAddUserModal}
        style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
      >
        <KTIcon iconName='file' className='fs-2' />
        Export Data
      </button> */}

      {/* <Link to='/asset-management/add-asset'>
        <button
          type='button'
          className='btn btn-primary'
          style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
        >
          <KTIcon iconName='plus' className='fs-2' />
          Add Asset
        </button>
      </Link> */}

      {/* end::Add user */}
    </div>
  )
}

export {AssetListToolbar}
