import React, {FC, useState, CSSProperties, useEffect, useRef} from 'react'
import {KTCard} from '../../../_metronic/helpers'
import {TestManagement} from './TestManagement'
import {EditTestsModal} from './components/EditTestsModal'
import {ToastContainer} from 'react-toastify'
import TestHeader from './components/header/TestHeader'
import {
  collection,
  doc,
  onSnapshot,
  query,
  where,
  CollectionReference,
  Query,
  DocumentData,
  getDocs,
} from 'firebase/firestore'
import db from '../../../services/firebase'
import {useAuth} from '../../modules/auth'

type Framework = {
  name: string
  checked: boolean
}

const TestManagementWrapper: FC = () => {
  const categories = ['Policy', 'Human resources', 'Engineering', 'Risks', 'Information technology']
  const types = ['Document', 'Platform']
  const statusList = ['All', 'Passing', 'Failing']

  const initialCheckedState = categories.map((category) => ({name: category, checked: false}))

  const initalCheckedTypes = types.map((type) => ({name: type, checked: false}))

  const [tests, setTests] = useState([] as any[])
  const [isLoading, setIsLoading] = useState(true)
  const [assetID, setAssetID] = useState<string>('')
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const [checkedCategories, setCheckedCategories] = useState(initialCheckedState)
  const [checkedTypes, setCheckedTypes] = useState(initalCheckedTypes)
  const [checkedFrameworks, setCheckedFrameworks] = useState<Framework[]>([])
  const [status, setStatus] = useState('All')
  const [owner, setOwner] = useState('')
  const [documentCount, setDocumentCount] = useState(40)
  const [platformCount, setPlatformCount] = useState(33)
  const [automatedCount, setAutomatedTestCount] = useState(0)
  const [policyCount, setPolicyCount] = useState(0)
  const [hrCount, setHRCount] = useState(0)
  const [engineeringCount, setEngineeringCount] = useState(0)
  const [riskCounts, setRisksCount] = useState(0)
  const [frameWorkCounts, setFrameWorkCounts] = useState(0)
  const [isoFrameWorkCounts, setIsoFrameWorkCounts] = useState(0)
  const {currentUser} = useAuth()
  const [personnels, setPersonnels] = useState<any[]>([])
  const [sidebarTop, setSidebarTop] = useState(104)

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight
      const sidebarElement = document.getElementById('filterSidebar')
      const sidebarHeight = sidebarElement ? sidebarElement.offsetHeight : 0
      const newTop = windowHeight < sidebarHeight + 104 ? windowHeight - sidebarHeight - 20 : 104
      setSidebarTop(newTop)
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const sidebarStyle: CSSProperties = {
    paddingLeft: '0px',
    // overflowY: 'auto',
    // minHeight:'85vh',
    // maxHeight: '85vh',
    width: '105%',
  }

  const mainContentStyle: CSSProperties = {
    marginLeft: '0%',
  }
  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      // Logout()
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setPersonnels(risksData)
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    getTests()
  }, [])

  useEffect(() => {
    getActiveFramworks()
  }, [])

  // const getTests = () => {
  //   try {
  //     const tenantId = currentUser?.tenantId || ''
  //     const tenantRef = doc(db, 'tenants', tenantId)
  //     const testsCollectionRef = collection(tenantRef, 'tests')

  //     const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
  //       const testsData = snapshot.docs.map((doc) => ({
  //         ...doc.data(),
  //         id: doc.id,
  //         framework: doc.data().framework,
  //         status: doc.data().status,
  //       }))

  //       const filteredControls = testsData.filter((test) => {
  //         if (!test.framework) return false

  //         // Split the test's framework string into an array
  //         const testFrameworks = test.framework.split(', ')

  //         // Check if any of the active frameworks exactly match any of the test's frameworks
  //         return activeFrameworks.some(activeFramework =>
  //           testFrameworks.some(testFramework => {
  //             // Exact match, excluding 'ISO 27001:2013'
  //             return testFramework === activeFramework && testFramework !== 'ISO 27001:2013'
  //           })
  //         )
  //       })

  //       setIsLoading(false)
  //       setTests(filteredControls)
  //       updateCounts(testsData)
  //     })

  //     return unsubscribe
  //   } catch (error) {
  //     console.log('Error getting tests:', error)
  //     setIsLoading(false)
  //   }
  // }

  const getTests = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const testsCollectionRef = collection(tenantRef, 'tests')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const testsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          framework: doc.data().framework,
          status: doc.data().status,
        }))

        setIsLoading(false)
        setTests(testsData)
        updateCounts(testsData)
      })

      return unsubscribe
    } catch (error) {
      console.log('Error getting tests:', error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    filterTests()
  }, [status, owner, checkedCategories, checkedTypes, checkedFrameworks])

  // const filterTests =async () => {
  //   const tenantId = currentUser?.tenantId || ''
  //   const tenantRef = doc(db, 'tenants', tenantId)
  //   const testsCollectionRef = collection(tenantRef, 'tests')

  //   let q: CollectionReference<DocumentData> | Query<DocumentData> = testsCollectionRef

  //   const snapshot = await getDocs(testsCollectionRef);
  //   const allDocs = snapshot.docs.map(doc => doc.data());
  //   // Conditionally add 'status' filter
  //   if (status && status !== 'All') {
  //     q = query(q, where('status', '==', status === 'Failing' ? 'Fail' : 'Pass'))
  //   }

  //   // Conditionally add 'owner' filter
  //   if (owner) {
  //     q = query(q, where('owner', '==', owner))
  //   }

  //   // const frameworkNames = checkedFrameworks
  //   //   .filter((value) => value.checked)
  //   //   .map((value) => value.name)

  //   // // Conditionally add 'framework' filter
  //   // if (frameworkNames && frameworkNames.length > 0) {
  //   //   allDocs.filter(doc => {
  //   //     const frameworks = doc.framework.split(','); // Assuming frameworks are stored as comma-separated strings
  //   //     return frameworkNames.some(name => frameworks.includes(name));
  //   //   });
  //   // }

  //   // Conditionally add 'category' filter
  //   const categoryNames = checkedCategories
  //     .filter((category) => category.checked)
  //     .map((category) => category.name)
  //   if (categoryNames && categoryNames.length > 0) {
  //     q = query(q, where('category', 'in', categoryNames))
  //   }

  //   // Conditionally add 'type' filter
  //   const typeNames = checkedTypes.filter((type) => type.checked).map((type) => type.name)
  //   if (typeNames && typeNames.length > 0) {
  //     q = query(q, where('type', 'in', typeNames))
  //   }

  //   try {

  //     const snapshot = await getDocs(q);
  //     const allDocs = snapshot.docs.map(doc => doc.data());

  //     // Apply 'framework' filter on the client side
  //     const frameworkNames = checkedFrameworks
  //       .filter((value) => value.checked)
  //       .map((value) => value.name);
  //     const filteredDocs = frameworkNames.length > 0
  //       ? allDocs.filter(doc => {
  //           const frameworks = doc.framework.split(','); // Assuming frameworks are stored as comma-separated strings
  //           return frameworkNames.some(name => frameworks.includes(name));
  //         })
  //       : allDocs;

  //     const unsubscribe = onSnapshot(q, (snapshot) => {
  //       const testsData = snapshot.docs.map((doc) => ({
  //         ...doc.data(),
  //         id: doc.id,
  //         framework: doc.data().framework,
  //         status: doc.data().status,
  //       }))
  //       setIsLoading(false)
  //       updateCounts(testsData)
  //       setTests(testsData)
  //     })

  //     return unsubscribe
  //   } catch (error) {
  //     console.log('Error getting tests:', error)
  //     setIsLoading(false)
  //   }
  // }

  const filterTests = async () => {
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)
    const testsCollectionRef = collection(tenantRef, 'tests')

    let q: CollectionReference<DocumentData> | Query<DocumentData> = testsCollectionRef

    // Conditionally add 'status' filter
    if (status && status !== 'All') {
      q = query(q, where('status', '==', status === 'Failing' ? 'Fail' : 'Pass'))
    }

    // Conditionally add 'owner' filter
    if (owner) {
      q = query(q, where('owner', '==', owner))
    }

    // Conditionally add 'category' filter
    const categoryNames = checkedCategories
      .filter((category) => category.checked)
      .map((category) => category.name)
    if (categoryNames.length > 0) {
      q = query(q, where('category', 'in', categoryNames))
    }

    // Conditionally add 'type' filter
    const typeNames = checkedTypes.filter((type) => type.checked).map((type) => type.name)
    if (typeNames.length > 0) {
      q = query(q, where('type', 'in', typeNames))
    }

    try {
      const snapshot = await getDocs(q)
      const allDocs = snapshot.docs.map((doc) => doc.data())

      const frameworkNames = checkedFrameworks
        .filter((value) => value.checked)
        .map((value) => value.name)

      const filteredDocs =
        frameworkNames.length > 0
          ? allDocs.filter((doc) => {
              const frameworks = doc.framework.split(',').map((fw) => fw.trim())
              return frameworkNames.some((name) => frameworks.includes(name))
            })
          : allDocs

      setIsLoading(false)
      updateCounts(filteredDocs)
      setTests(filteredDocs)

      // Listen to changes in the original query (before client-side filtering)
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const testsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          framework: doc.data().framework,
          status: doc.data().status,
        }))

        // Apply the framework filter again on live updates
        const liveFilteredDocs =
          frameworkNames.length > 0
            ? testsData.filter((doc) => {
                const frameworks = doc.framework.split(',').map((fw) => fw.trim())
                return frameworkNames.some((name) => frameworks.includes(name))
              })
            : testsData

        setIsLoading(false)
        updateCounts(liveFilteredDocs)
        setTests(liveFilteredDocs)
      })

      return unsubscribe
    } catch (error) {
      console.log('Error getting tests:', error)
      setIsLoading(false)
    }
  }

  const updateCounts = (testsData: any) => {
    const documentCount = testsData.filter((test) => test.type.trim() === 'Document').length
    const platformCount = testsData.filter((test) => test.type.trim() === 'Platform').length
    const automatedCount = testsData.filter((test) => test.type.trim() === 'Platform').length
    const policyCount = testsData.filter((test) => test.category.trim() === 'Policy').length
    const hrCount = testsData.filter((test) => test.category.trim() === 'Human resources').length
    const engineeringCount = testsData.filter(
      (test) => test.category.trim() === 'Engineering'
    ).length
    const riskCounts = testsData.filter((test) => test.category.trim() === 'Risks').length
    const frameWorkCounts = testsData.filter((test) => test.framework.trim() === 'SOC 2').length
    const isoFrameWorkCounts = testsData.filter(
      (test) => test.framework.trim() === 'ISO 27001:2022'
    ).length

    setDocumentCount(documentCount)
    setPlatformCount(platformCount)
    setPlatformCount(automatedCount)
    setPolicyCount(policyCount)
    setHRCount(hrCount)
    setEngineeringCount(engineeringCount)
    setRisksCount(riskCounts)
    setFrameWorkCounts(frameWorkCounts)
    setIsoFrameWorkCounts(isoFrameWorkCounts)
  }

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
        setCheckedFrameworks(frameworks.map((framework) => ({name: framework, checked: false})))
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
      setIsLoading(false)
    }
  }

  const onResetClicked = () => {
    setCheckedCategories(initialCheckedState)
    setCheckedTypes(initalCheckedTypes)
    setStatus('All')
    setOwner('')
    setCheckedFrameworks(activeFrameworks.map((framework) => ({name: framework, checked: false})))
    getTests()
  }

  const handleCategoryChange = (index) => {
    setCheckedCategories((prevState) => {
      const newState = [...prevState]
      newState[index].checked = !newState[index].checked
      return newState
    })
  }

  const handleTypechange = (index) => {
    setCheckedTypes((prevState) => {
      const newState = [...prevState]
      newState[index].checked = !newState[index].checked
      return newState
    })
  }

  const handleFrameworkChange = (index) => {
    setCheckedFrameworks((prevState) => {
      const newState = [...prevState]
      newState[index].checked = !newState[index].checked
      return newState
    })
  }

  return (
    <>
      <div style={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
        {isLoading ? (
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <div className='row '>
            <div className='col-md-2 d-none d-sm-block'>
              <div
                id='filterSidebar'
                style={{
                  ...sidebarStyle,
                  position: 'sticky',
                  top: `${sidebarTop}px`,
                  maxWidth: '105%',
                  overflowX: 'hidden',
                  // overflowY: 'hidden',
                  boxSizing: 'border-box',
                  minHeight:'85vh',
                  maxHeight:'100vh',
                  // height:'85vh'
                }}
              >
                <div className='card h-100' style={{background: '#fff'}}>
                  <div className='card-body p-0 px-6 pt-4'>
                    {/* Filters Header */}
                    <div className='d-flex justify-content-between align-items-center mb-2'>
                      <label className='fs-6 form-label fw-bold text-gray-900 mb-0'>Filters</label>
                      <button
                        className='btn btn-sm fs-6 text-bold btn-active-light-primary'
                        style={{color: '#1CBB8D'}}
                        onClick={() => onResetClicked()}
                      >
                        Reset
                      </button>
                    </div>

                    {/* Framework Filter */}
                    <div className='mb-4'>
                      <label className='fs-6 form-label fw-bold text-gray-900 mb-5'>
                        Framework
                      </label>
                      {checkedFrameworks.map((framework, index) => (
                        <div
                          key={index}
                          className='form-check form-check-custom mb-5 form-check-sm'
                        >
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id={`kt_search_category_${index + 1}`}
                            onChange={() => handleFrameworkChange(index)}
                            checked={framework.checked}
                          />
                          <label
                            className='form-check-label flex-grow-1 fw-semibold text-gray-700 fs-7'
                            onClick={() => handleFrameworkChange(index)}
                          >
                            {framework.name}
                          </label>
                        </div>
                      ))}
                    </div>

                    {/* Type Filter */}
                    <div className='mb-4'>
                      <label className='fs-6 form-label fw-bold text-gray-900 mb-5'>Type</label>
                      {checkedTypes.map((type, index) => (
                        <div
                          key={index}
                          className='form-check form-check-custom mb-5 form-check-sm'
                        >
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id={`kt_search_category_${index + 1}`}
                            onChange={() => handleTypechange(index)}
                            checked={type.checked}
                          />
                          <label
                            className='form-check-label flex-grow-1 fw-semibold text-gray-700 fs-7'
                            onClick={() => handleTypechange(index)}
                          >
                            {type.name}
                          </label>
                        </div>
                      ))}
                    </div>

                    {/* Categories Filter */}
                    <div className='mb-4'>
                      <label className='fs-6 form-label fw-bold text-gray-900 mb-5'>
                        Categories
                      </label>
                      {checkedCategories.map((category, index) => (
                        <div
                          key={index}
                          className='form-check form-check-custom mb-5 form-check-sm'
                        >
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id={`kt_search_category_${index + 1}`}
                            onChange={() => handleCategoryChange(index)}
                            checked={category.checked}
                          />
                          <label
                            className='form-check-label flex-grow-1 fw-semibold text-gray-700 fs-7'
                            onClick={() => handleCategoryChange(index)}
                          >
                            {category.name}
                          </label>
                        </div>
                      ))}
                    </div>

                    {/* Status Filter */}
                    <div className='mb-4'>
                      <label className='fs-6 form-label fw-bold text-gray-900'>Status</label>
                      <div className='nav-group nav-group-fluid'>
                        {statusList.map((size, index) => (
                          <label key={index}>
                            <input
                              type='radio'
                              className='btn-check'
                              name='teamSize'
                              value={size}
                              onChange={(e) => setStatus(e.target.value)}
                              defaultChecked={statusList[index] === status}
                            />
                            <span className='btn btn-sm btn-color-muted btn-active btn-active-primary fs-7 px-2'>
                              {size}
                            </span>
                          </label>
                        ))}
                      </div>
                    </div>

                    {/* Owner Filter */}
                    <div className='mb-6'>
                      <label className='fs-6 form-label fw-bold text-gray-900'>Owner</label>
                      <select
                        className='form-select form-select-solid'
                        onChange={(e) => setOwner(e.target.value)}
                      >
                        <option value='' selected>
                          Select Owner
                        </option>
                        {personnels
                          .filter((user) => user.role === 'Admin')
                          .map((group) => (
                            <option key={group.id} value={group.full_name}>
                              {group.full_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-10' style={mainContentStyle}>
              <TestHeader />
              <KTCard>
                <TestManagement className='mb-5 mb-xl-8' tests={tests} />
                {assetID && <EditTestsModal id={assetID} setTestsID={setAssetID} />}
                <ToastContainer />
              </KTCard>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default TestManagementWrapper
