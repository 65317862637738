/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import db from '../../../services/firebase'
import controlServices from '../controls/services/control.services'
import {format} from 'date-fns'
import {Link, useParams, useLocation} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import {usersBreadcrumbs} from './ControlInformationWrapper'

type Props = {}

const Requirements: React.FC<Props> = () => {
  const [control, setControl] = useState<any | null>(null)
  const location = useLocation()
  const [activeTab, setActiveTab] = useState('details')
  const {id} = useParams()
  console.log('IDDDDD Requirement', id)
  const [dataCount, setDataCount] = useState(0)
  useEffect(() => {
    // Fetch control data when the component mounts
    if (id) {
      getControl(id)
    }

    if (location.pathname.includes('requirements')) {
      setActiveTab('requirements')
    } else if (location.pathname.includes('testing')) {
      setActiveTab('testing')
    }
  }, [id])

  const getControl = async (controlId: string) => {
    try {
      const controlRef = db.collection('ControlManagement').doc(controlId) // Replace 'your-collection-name' with your actual Firestore collection name
      const doc = await controlRef.get()

      if (doc.exists) {
        setControl(doc.data())
      } else {
        // Handle the case where the document doesn't exist
        console.log(`Control with ID ${controlId} not found.`)
      }
    } catch (error) {
      console.error('Error fetching control data:', error)
    }
  }

  return (
    <>
      <PageTitle icon='personnelIcon.svg' title='Control Management' breadcrumbs={usersBreadcrumbs}>
        Control Management
      </PageTitle>
      <div
        className='card card-flush pb-0 bgi-position-y-center bgi-no-repeat mb-10'
        style={{
          backgroundSize: 'auto calc(100% + 10rem)',
          backgroundPositionX: '100%',
          backgroundImage: "url('assets/media/illustrations/sketchy-1/4.png')",
        }}
      >
        {/*begin::Card header*/}

        {control && (
          <div>
            <div className='card-header pt-10'>
              <div className=''>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='card-label fw-bold fs-3'>{control.name}</span>
                  <span className='text-muted fw-semibold fs-5 mb-3'>{control.description}</span>
                </div>
              </div>
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className='card-body pt-2 pb-0'>
              {/*begin::Navs*/}
              <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
                  {/*begin::Nav item*/}
                  <li className='nav-item'>
                    <Link
                      to={`/controls/${id}/details`}
                      className={`nav-link me-6 ${
                        activeTab === 'details' ? 'text-active-primary active' : ''
                      }`}
                    >
                      Details
                    </Link>
                  </li>
                  {/*end::Nav item*/}
                  {/*begin::Nav item*/}
                  <li className='nav-item'>
                    <Link
                      to={`/controls/${id}/requirements`}
                      className={`nav-link me-6 ${
                        activeTab === 'requirements' ? 'text-active-primary active' : ''
                      }`}
                    >
                      Requirements
                    </Link>
                  </li>
                  {/*end::Nav item*/}
                  {/*begin::Nav item*/}
                  <li className='nav-item'>
                    <Link
                      to={`/controls/${id}/testing`}
                      className={`nav-link me-6 ${
                        activeTab === 'testing' ? 'text-active-primary active' : ''
                      }`}
                    >
                      Testing
                    </Link>
                  </li>
                  {/*end::Nav item*/}
                </ul>
              </div>
              {/*begin::Navs*/}
            </div>
            <div className='card card-flush'>
              {/*begin::Card header*/}
              <div className='card-header pt-8'>
                <div className='card-title'>
                  {/*begin::Search*/}
                  <div className='d-flex align-items-center position-relative my-1'>
                    <i className='ki-duotone ki-magnifier fs-1 position-absolute ms-6'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                    <input
                      type='text'
                      data-kt-filemanager-table-filter='search'
                      className='form-control form-control-solid w-250px ps-15'
                      placeholder='Search'
                    />
                  </div>
                  {/*end::Search*/}
                </div>
                {/*begin::Card toolbar*/}
                <div className='card-toolbar'>
                  {/*begin::Toolbar*/}
                  <div
                    className='d-flex justify-content-end'
                    data-kt-filemanager-table-toolbar='base'
                  >
                    {/*begin::Back to folders*/}
                    {/* <a
                      href='../../demo1/dist/apps/file-manager/folders.html'
                      className='btn btn-icon btn-light-primary me-3'
                    >
                      <i className='ki-duotone ki-exit-up fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </a> */}
                    {/*end::Back to folders*/}
                    {/*begin::Export*/}
                    <button
                      type='button'
                      className='btn btn-flex btn-light-primary me-3'
                      id='kt_file_manager_new_folder'
                    >
                      <i className='ki-duotone ki-add-folder fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                      New Folder
                    </button>
                    {/*end::Export*/}
                    {/*begin::Add customer*/}
                    <button
                      type='button'
                      className='btn btn-flex btn-primary'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_upload'
                    >
                      <i className='ki-duotone ki-folder-up fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                      Upload Files
                    </button>
                    {/*end::Add customer*/}
                  </div>
                  {/*end::Toolbar*/}
                  {/*begin::Group actions*/}
                  <div
                    className='d-flex justify-content-end align-items-center d-none'
                    data-kt-filemanager-table-toolbar='selected'
                  >
                    <div className='fw-bold me-5'>
                      <span
                        className='me-2'
                        data-kt-filemanager-table-select='selected_count'
                      ></span>
                      Selected
                    </div>
                    <button
                      type='button'
                      className='btn btn-danger'
                      data-kt-filemanager-table-select='delete_selected'
                    >
                      Delete Selected
                    </button>
                  </div>
                  {/*end::Group actions*/}
                </div>
                {/*end::Card toolbar*/}
              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className='card-body'>
                {/*begin::Table header*/}
                <div className='d-flex flex-stack mb-5'>
                  <h3> Mapped Framework Requirements</h3>
                </div>
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='w-25px'>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value='1'
                              data-kt-check='true'
                              data-kt-check-target='.widget-9-check'
                            />
                          </div>
                        </th>
                        <th className='min-w-150px'>Name</th>
                        <th className='min-w-100px'>Health</th>
                        <th className='min-w-150px'>Description</th>
                        {/* <th className='min-w-100px'>Owner</th>
                <th className='min-w-100px'>Created</th> */}
                        {/* <th className='min-w-90px'>Anti-virus</th>
                <th className='min-w-100px'>Password Policy</th>
                <th className='min-w-100px'>Screen Policy</th>
                <th className='min-w-50px'>Firewall</th>
                <th className='min-w-100px'>In Audit Scope</th> */}
                        <th className='min-w-100px'></th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      <tr>
                        <td>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input widget-9-check'
                              type='checkbox'
                              value='1'
                            />
                          </div>
                        </td>

                        <td>
                          <a
                            href='#'
                            className='fs-4 text-dark fw-bold text-hover-primary d-block fs-6'
                          >
                            5.9 Inventory of information and other associated assets
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='fs-4 text-dark fw-bold text-hover-primary d-block fs-6'
                          >
                            0 of 1 controls healthy
                          </a>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            4F34DG1
                          </span>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='fs-4 text-dark fw-bold text-hover-primary d-block fs-6'
                          >
                            An Inventory of information and other associated assets, including
                            owners shall be developed
                          </a>
                        </td>
                        <td>
                          <div className='d-flex justify-content-center flex-shrink-0'>
                            <a
                              href='#'
                              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            >
                              <i className='bi bi-three-dots fs-5'></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>

                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
                {/*end::Table header*/}
              </div>
              {/*end::Card body*/}
            </div>
          </div>
        )}
        {/*end::Card body*/}
      </div>
    </>
  )
}

export {Requirements}
