import React, {useEffect, useRef, useState} from 'react'
import db from '../../../../services/firebase'
import {addDoc, collection, doc, serverTimestamp} from 'firebase/firestore'
import {useAuth} from '../../../modules/auth'
import Drawer from 'react-modern-drawer'
import {toast} from 'react-toastify'

export interface policy {
  msg: string
  message: string
  fileUrl?: any
}

type Props = {
  isOpen: boolean
  onClose: () => void
}

const AddManual: React.FC<Props> = ({isOpen, onClose}) => {
  const [control, setControl] = useState('')
  const [description, setDescription] = useState('')
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})
  const focusRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (focusRef.current) {
          focusRef.current.focus()
        }
      }, 300)
    }
  }, [isOpen])

  const validateForm = () => {
    const errors: Record<string, string> = {}

    if (!control.trim()) {
      errors.control = 'Control name is required'
    }

    if (!description.trim()) {
      errors.description = 'Control description is required'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1
  const day = currentDate.getDate()

  const todaysDate = month + '/' + day + '/' + year

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    console.log('clicked')

    const modalContentElement = document.getElementById('kt_modal_2')
    if (!validateForm()) {
      setIsLoading(false)
      return
    }

    try {
      const newPolicy = {
        control_title: control,
        control_description: description,
        author: 'Custom Control',
        tenantId: currentUser?.tenantId,
        uid: currentUser?.uid,
      }

      if (currentUser) {
        const tenantRef = doc(db, 'tenants', currentUser.tenantId!)
        const controlRef = collection(tenantRef, 'controls')

        await addDoc(controlRef, {
          ...newPolicy,
          control_evidence_status: 'Unhealthy',
          author: 'Custom Control',
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
        })

        const activitiesRef = collection(tenantRef, 'activities')
        await addDoc(activitiesRef, {
          message: `${currentUser?.userName} added a new manual control '${control}'`,
          timestamp: serverTimestamp(),
          tenantId: currentUser?.tenantId,
          uid: currentUser?.uid,
          action: 'Control added',
          target: 'Controls',
          email: currentUser?.email,
          username: currentUser?.userName,
          date: todaysDate,
        })
        onClose()
      }
      toast.success('Custom control added successfully!', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })

      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
    } catch (err) {
      if (modalContentElement) {
        modalContentElement.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
    } finally {
      setIsLoading(false)
      setControl('')
      setDescription('')
    }
  }

  const handleControlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setControl(e.target.value)
    setFormErrors((prev) => ({...prev, control: ''}))
  }

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value)
    setFormErrors((prev) => ({...prev, description: ''}))
  }

  const onCancel = () => {
    setFormErrors({})
    onClose()
  }

  return (
    <>
      <Drawer open={isOpen} onClose={onCancel} direction='right' className='' size='45%'>
        <div
          className='modal-dialog-centered border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
            Add Control
          </div>

          <div style={{display: 'flex', gap: '16px'}}>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary '
              onClick={onCancel}
              aria-label='Close'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-x'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M18 6l-12 12' />
                <path d='M6 6l12 12' />
              </svg>
            </div>
          </div>
        </div>

        <div
          className=' scroll-y overflow-auto p-7 mb-4'
          tabIndex={-1}
          style={{height: 'calc(100vh - 20px)'}}
        >
          <div className='form' onSubmit={handleSubmit}>
            <div className=''>
              <div className='form-group mb-4'>
                <label className='fw-semibold mb-2 fs-6'>Control Name</label>
                <input
                  type='text'
                  className={`form-control ${formErrors.control ? 'is-invalid' : ''}`}
                  value={control}
                  placeholder='Enter control name'
                  onChange={handleControlChange}
                  ref={focusRef}
                />
                {formErrors.control && <div className='invalid-feedback'>{formErrors.control}</div>}
              </div>

              <div className='form-group mb-4'>
                <label className='text-dark fw-bold text-primary  my-2  fs-6'>
                  {' '}
                  Control Description
                </label>
                <textarea
                  className={`form-control ${formErrors.description ? 'is-invalid' : ''}`}
                  value={description}
                  id='description'
                  placeholder='Enter control description'
                  rows={3}
                  onChange={handleDescriptionChange}
                ></textarea>
                {formErrors.description && (
                  <div className='invalid-feedback'>{formErrors.description}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className='offcanvas-footer d-flex justify-content-end mb-4 border-top pt-4 pb-3 mx-7'
          style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
        >
          <button type='button' className='btn btn-light me-4' onClick={onCancel}>
            Cancel
          </button>
          {isLoading ? (
            <button
              type='submit'
              value='Submit'
              className='btn btn-sm'
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#148363')
              }
              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#1CBB8D')
              }
              disabled
              style={{
                backgroundColor: '#1CBB8D',
                color: '#fff',
                // width: '110px',
                height: '40px',
                borderRadius: '4px',

                transition: 'background-color 0.3s',
              }}
            >
              <span className='spinner-border spinner-border-sm align-middle m-2'></span>
              Add Control
            </button>
          ) : (
            <button
              className='btn btn-sm'
              onClick={handleSubmit}
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#148363')
              }
              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#1CBB8D')
              }
              // disabled={activities.length === 0}
              style={{
                backgroundColor: '#1CBB8D',
                color: '#fff',
                width: '110px',
                height: '40px',
                borderRadius: '4px',

                transition: 'background-color 0.3s',
              }}
            >
              Add Control
            </button>
          )}
        </div>
      </Drawer>
    </>
  )
}

export default AddManual
