import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../../modules/auth'
import db from '../../../../../services/firebase'
import {collection, doc, onSnapshot, query, where} from 'firebase/firestore'

const DashboardPageHeader = () => {
  const [totalPersonnel, setTotalPersonnel] = useState(0)
  const [totalAsset, setTotalAsset] = useState(0)
  const [totalRepo, setTotalRepo] = useState(0)
  const [totalRisk, setTotalRisk] = useState(0)
  const {currentUser} = useAuth()
  const [totalOffboarded, setTotalOffboarded] = useState(0)
  const [criticalRisks, setCriticalRisks] = useState(0)
  const [highRisks, setHighRisks] = useState(0)
  const [mediumRisks, setMediumRisks] = useState(0)
  const [lowRisks, setLowRisks] = useState(0)

  console.log(criticalRisks,highRisks,mediumRisks,lowRisks)

  // useEffect(() => {
  //   const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
  //   const tenantRef = doc(db, 'tenants', tenantId)
  //   const PolicyRef = collection(tenantRef, 'employees')

  //   // const limitedQuery = query(controlsCollectionRef, limit(10))

  //   const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
  //     const risksData = snapshot.size
  //     setTotalPersonnel(risksData)
  //   })

  //   return unsubscribe// Clean up the subscription when the component unmounts
  // }, [])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
    const tenantRef = doc(db, 'tenants', tenantId)
    const policyRef = collection(tenantRef, 'employees')

    const unsubscribeTotal = onSnapshot(policyRef, (snapshot) => {
      const personnelCount = snapshot.size
      setTotalPersonnel(personnelCount)
    })

    const offboardedQuery = query(policyRef, where('end_date', '!=', null))
    const unsubscribeOffboarded = onSnapshot(offboardedQuery, (snapshot) => {
      const offboardedCount = snapshot.size
      setTotalOffboarded(offboardedCount)
    })

    return () => {
      unsubscribeTotal()
      unsubscribeOffboarded()
    }
  }, [])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || '' 
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'assets')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const assetData = snapshot.docs.map((item) => ({
        ...item.data(),
        id: item.id,
        is_repo:item.data().is_repo
      }))
      const newDataCount = assetData.filter((data)=>data.is_repo !== true)
      setTotalAsset(newDataCount.length)

      const repoCount = assetData.filter((data)=>data.is_repo === true)
      setTotalRepo(repoCount.length)
    })

    return unsubscribe 
  }, [])



  // useEffect(() => {
  //   const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
  //   const tenantRef = doc(db, 'tenants', tenantId)
  //   const PolicyRef = collection(tenantRef, 'risks')

  //   // const limitedQuery = query(controlsCollectionRef, limit(10))

  //   const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
  //     const assetData = snapshot.size
  //     setTotalRisk(assetData)

  //   })

  //   return unsubscribe// Clean up the subscription when the component unmounts
  // }, [])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || '' 
    const tenantRef = doc(db, 'tenants', tenantId)
    const risksRef = collection(tenantRef, 'risks')
  
    const unsubscribe = onSnapshot(risksRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        id: doc.id,
        residualImpact: doc.data().residual_impact,
        residualLikelihood: doc.data().residual_likelihood,
        ...doc.data(),
      }))
  
      setTotalRisk(risksData.length)
  
      // Initialize counters for each risk level
      let criticalCount = 0
      let highCount = 0
      let mediumCount = 0
      let lowCount = 0
  
      risksData.forEach(risk => {
        // Calculate the risk level
        const riskLevel = risk.residualImpact * risk.residualLikelihood
  
        // Increment the appropriate counter based on the risk level
        if (riskLevel >= 25) {
          criticalCount++
        } else if (riskLevel >= 15) {
          highCount++
        } else if (riskLevel >= 5) {
          mediumCount++
        } else {
          lowCount++
        }
      })
  
      // Set the state with the counted values
      setCriticalRisks(criticalCount)
      setHighRisks(highCount)
      setMediumRisks(mediumCount)
      setLowRisks(lowCount)
    })
  
    return unsubscribe // Clean up the subscription when the component unmounts
  }, [])

  return (
    <>
      <div className='row gx-5 gx-xl-10'>
        {/*begin::Col*/}
        <div className='col-sm-4 mb-5 mb-xl-10'>
          {/*begin::List widget 1*/}
          <div className='card card-flush h-lg-80'>
            {/*begin::Header*/}
            <div className='card-header pt-0'>
              {/*begin::Title*/}
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-4 text-dark'>Employees</span>
              </h3>
              {/*end::Title*/}
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className='card-body pt-0'>
              {/*begin::Item*/}
              <div className='d-flex flex-stack mt-5'>
                {/*begin::Section*/}
                <div className='text-gray-800 fw-semibold fs-6 me-2'>
                  <div className='d-inline-block align-middle me-2 mb-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#5E6274'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-users'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0' />
                      <path d='M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2' />
                      <path d='M16 3.13a4 4 0 0 1 0 7.75' />
                      <path d='M21 21v-2a4 4 0 0 0 -3 -3.85' />
                    </svg>
                  </div>
                  Total Employees
                </div>

                {/*end::Section*/}
                {/*begin::Statistics*/}
                <div className='d-flex align-items-senter'>
                  {/*begin::Number*/}
                  <span className='text-gray-900 fw-bolder fs-6'>{totalPersonnel}</span>
                  {/*end::Number*/}
                </div>
                {/*end::Statistics*/}
              </div>
              <div className='my-3'></div>

              <div className='d-flex flex-stack'>
                {/*begin::Section*/}
                <div className='text-gray-800 fw-semibold fs-6 me-2'>
                  <div className='d-inline-block align-middle me-2 mb-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#5E6274'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-user-check'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0' />
                      <path d='M6 21v-2a4 4 0 0 1 4 -4h4' />
                      <path d='M15 19l2 2l4 -4' />
                    </svg>
                  </div>
                  Onboarding
                </div>

                {/*end::Section*/}
                {/*begin::Statistics*/}
                <div className='d-flex align-items-senter'>
                  {/*begin::Number*/}
                  <span className='text-gray-900 fw-bolder fs-6'>0</span>
                  {/*end::Number*/}
                </div>
                {/*end::Statistics*/}
              </div>
              {/*end::Item*/}
              {/*begin::Separator*/}
              <div className='my-3'></div>
              {/*end::Separator*/}
              {/*begin::Item*/}
              <div className='d-flex flex-stack mb-2'>
                {/*begin::Section*/}
                <div className='text-gray-800 fw-semibold fs-6 me-2'>
                  <div className='d-inline-block align-middle me-2 mb-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#EE3043'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M12 9v4' />
                      <path d='M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z' />
                      <path d='M12 16h.01' />
                    </svg>
                  </div>
                  Offboarded
                </div>
                {/*end::Section*/}
                {/*begin::Statistics*/}
                <div className='d-flex align-items-senter'>
                  {/*begin::Number*/}
                  <span className='text-gray-900 fw-bolder fs-6'>{totalOffboarded}</span>
                  {/*end::Number*/}
                </div>
                {/*end::Statistics*/}
              </div>
            </div>
            {/*end::Body*/}
          </div>
          {/*end::LIst widget 1*/}
        </div>
        {/*end::Col*/}

        <div className='col-sm-4 mb-5 mb-xl-10'>
          {/*begin::List widget 1*/}
          <div className='card card-flush h-lg-80'>
            {/*begin::Header*/}
            <div className='card-header pt-0'>
              {/*begin::Title*/}
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-4 text-dark'>Assets</span>
              </h3>
              {/*end::Title*/}
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className='card-body pt-0'>
              {/*begin::Item*/}
              <div className='d-flex flex-stack mt-5'>
                {/*begin::Section*/}
                <div className='text-gray-800 fw-semibold fs-6 me-2'>
                  <div className='d-inline-block align-middle me-2 mb-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#5E6274'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-devices'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M13 9a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1v-10z' />
                      <path d='M18 8v-3a1 1 0 0 0 -1 -1h-13a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h9' />
                      <path d='M16 9h2' />
                    </svg>
                  </div>
                  Devices
                </div>

                {/*end::Section*/}
                {/*begin::Statistics*/}
                <div className='d-flex align-items-senter'>
                  {/*begin::Number*/}
                  <span className='text-gray-900 fw-bolder fs-6'>{totalAsset}</span>
                  {/*end::Number*/}
                </div>
                {/*end::Statistics*/}
              </div>
              <div className='my-3'></div>

              <div className='d-flex flex-stack'>
                {/*begin::Section*/}
                <div className='text-gray-800 fw-semibold fs-6 me-2'>
                  <div className='d-inline-block align-middle me-2 mb-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#5E6274'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-cloud'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M6.657 18c-2.572 0 -4.657 -2.007 -4.657 -4.483c0 -2.475 2.085 -4.482 4.657 -4.482c.393 -1.762 1.794 -3.2 3.675 -3.773c1.88 -.572 3.956 -.193 5.444 1c1.488 1.19 2.162 3.007 1.77 4.769h.99c1.913 0 3.464 1.56 3.464 3.486c0 1.927 -1.551 3.487 -3.465 3.487h-11.878' />
                    </svg>
                  </div>
                  Cloud Resources
                </div>

                {/*end::Section*/}
                {/*begin::Statistics*/}
                <div className='d-flex align-items-senter'>
                  {/*begin::Number*/}
                  <span className='text-gray-900 fw-bolder fs-6'>0</span>
                  {/*end::Number*/}
                </div>
                {/*end::Statistics*/}
              </div>
              {/*end::Item*/}
              {/*begin::Separator*/}
              <div className='my-3'></div>
              {/*end::Separator*/}
              {/*begin::Item*/}
              <div className='d-flex flex-stack mb-2'>
                {/*begin::Section*/}
                <div className='text-gray-800 fw-semibold fs-6 me-2'>
                  <div className='d-inline-block align-middle me-2 mb-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#5E6274'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-git-merge'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M7 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
                      <path d='M7 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
                      <path d='M17 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
                      <path d='M7 8l0 8' />
                      <path d='M7 8a4 4 0 0 0 4 4h4' />
                    </svg>
                  </div>
                  Repositories
                </div>
                {/*end::Section*/}
                {/*begin::Statistics*/}
                <div className='d-flex align-items-senter'>
                  {/*begin::Number*/}
                  <span className='text-gray-900 fw-bolder fs-6'>{totalRepo}</span>
                  {/*end::Number*/}
                </div>
                {/*end::Statistics*/}
              </div>
            </div>
            {/*end::Body*/}
          </div>
          {/*end::LIst widget 1*/}
        </div>

        <div className='col-sm-4 mb-5 mb-xl-10'>
          {/*begin::List widget 1*/}
          <div className='card card-flush h-lg-80'>
            {/*begin::Header*/}
            <div className='card-header pt-0'>
              {/*begin::Title*/}
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-4 text-dark'>Residual Risk</span>
              </h3>
              {/*end::Title*/}
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className='card-body pt-0'>
              {/*begin::Item*/}
              <div className='d-flex flex-stack mt-5'>
                {/*begin::Section*/}
                <div className='text-gray-800 fw-semibold fs-6 me-2'>
                  <div className='d-inline-block align-middle me-2 mb-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#EE3043'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-bolt'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M13 3l0 7l6 0l-8 11l0 -7l-6 0l8 -11' />
                    </svg>
                  </div>
                  Critical
                </div>

                {/*end::Section*/}
                {/*begin::Statistics*/}
                <div className='d-flex align-items-senter'>
                  {/*begin::Number*/}
                  <span className='text-gray-900 fw-bolder fs-6'>{criticalRisks}</span>
                  {/*end::Number*/}
                </div>
                {/*end::Statistics*/}
              </div>
              <div className='my-3'></div>

              <div className='d-flex flex-stack'>
                {/*begin::Section*/}
                <div className='text-gray-800 fw-semibold fs-6 me-2'>
                  <div className='d-inline-block align-middle me-2 mb-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#FFA800'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-recharging'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M7.038 4.5a9 9 0 0 0 -2.495 2.47' />
                      <path d='M3.186 10.209a9 9 0 0 0 0 3.508' />
                      <path d='M4.5 16.962a9 9 0 0 0 2.47 2.495' />
                      <path d='M10.209 20.814a9 9 0 0 0 3.5 0' />
                      <path d='M16.962 19.5a9 9 0 0 0 2.495 -2.47' />
                      <path d='M20.814 13.791a9 9 0 0 0 0 -3.508' />
                      <path d='M19.5 7.038a9 9 0 0 0 -2.47 -2.495' />
                      <path d='M13.791 3.186a9 9 0 0 0 -3.508 -.02' />
                      <path d='M12 8l-2 4h4l-2 4' />
                      <path d='M12 21a9 9 0 0 0 0 -18' />
                    </svg>
                  </div>
                  High
                </div>

                {/*end::Section*/}
                {/*begin::Statistics*/}
                <div className='d-flex align-items-senter'>
                  {/*begin::Number*/}
                  <span className='text-gray-900 fw-bolder fs-6'>{highRisks}</span>
                  {/*end::Number*/}
                </div>
                {/*end::Statistics*/}
              </div>
              {/*end::Item*/}
              {/*begin::Separator*/}
              <div className='my-3'></div>
              {/*end::Separator*/}
              {/*begin::Item*/}
              <div className='d-flex flex-stack mb-2'>
                {/*begin::Section*/}
                <div className='text-gray-800 fw-semibold fs-6 me-2'>
                  <div className='d-inline-block align-middle me-2 mb-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#5E6274'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M8.56 3.69a9 9 0 0 0 -2.92 1.95' />
                      <path d='M3.69 8.56a9 9 0 0 0 -.69 3.44' />
                      <path d='M3.69 15.44a9 9 0 0 0 1.95 2.92' />
                      <path d='M8.56 20.31a9 9 0 0 0 3.44 .69' />
                      <path d='M15.44 20.31a9 9 0 0 0 2.92 -1.95' />
                      <path d='M20.31 15.44a9 9 0 0 0 .69 -3.44' />
                      <path d='M20.31 8.56a9 9 0 0 0 -1.95 -2.92' />
                      <path d='M15.44 3.69a9 9 0 0 0 -3.44 -.69' />
                    </svg>
                  </div>
                  Medium
                </div>
                {/*end::Section*/}
                {/*begin::Statistics*/}
                <div className='d-flex align-items-senter'>
                  {/*begin::Number*/}
                  <span className='text-gray-900 fw-bolder fs-6'>{mediumRisks}</span>
                  {/*end::Number*/}
                </div>
                {/*end::Statistics*/}
              </div>
            </div>
            {/*end::Body*/}
          </div>
          {/*end::LIst widget 1*/}
        </div>
      </div>
    </>
  )
}

export default DashboardPageHeader
