import React, {useRef, useState} from 'react'
import controlServices from '../services/control.services'
import {useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'

export interface addControl {
  msg: string
  message: string
  checked?: boolean | undefined
}

const AddControl = () => {
  const [control_id, setControl_id] = useState('')
  const [control_description, setControl_description] = useState('')
  const [control_evidence_status, setControl_evidence_status] = useState('')
  const [control_framework_mappings, setControl_framework_mappings] = useState('')
  const [control_category, setControl_category] = useState('')
  const [control_owner, setControl_owner] = useState('')
  const [control_title, setControl_title] = useState('')
  const [control_uid, setControl_uid] = useState('')
  const [control_test, setControl_test] = useState('')
  const[control_document,setControl_document] = useState('')
  const [message, setMessage] = useState({error: false, msg: ''})
  const [showAlert, setShowAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    setIsLoading(true)

    e.preventDefault()

    const newControl = {
      control_id,
      control_uid,
      control_title,
      control_category,
      control_description,
      control_evidence_status,
      control_framework_mappings,
      createdAt: new Date(),
      control_owner,
      control_test,
      control_document
    }

    if (
      !newControl.control_id ||
      !newControl.control_category ||
      !newControl.control_description ||
      !newControl.control_evidence_status ||
      !newControl.control_framework_mappings ||
      !newControl.control_uid ||
      !newControl.control_title
      // ||
      // !newControl.control_owner
    ) {
      setMessage({error: true, msg: 'Please fill all the fields'})
      setIsLoading(false)
      setShowAlert(true)
      window.scrollTo(0, 0)
      return
    }

    try {
      await controlServices.addControl(newControl)
      setMessage({error: false, msg: 'Added Successfully'})
      setShowAlert(true)
      window.scrollTo(0, 0)
    } catch (err: any) {
      setMessage({error: true, msg: err.message})
      setShowAlert(true)
      window.scrollTo(0, 0)
    } finally {
      setIsLoading(false)
    }

    setControl_id('')
    setControl_description('')
    setControl_evidence_status('')
    setControl_framework_mappings('')
    setControl_category('')
    setControl_owner('')
    setControl_title('')
    setControl_uid('')
    setControl_test('')
    setControl_document('')

    // setTimeout(() => {
    //   navigate('/asset-management')
    // }, 3000)
  }

  // const alertMessages = () => {
  //   // Call the callback function passed from the parent component
  //   console.log('Parents data', message?.msg)
  //   alertMessage(message?.msg)
  // }

  // const checkedEncryption = (e) => {
  //   setHdEncryption(e.target.value)
  // }

  // const checkedAntivirus = (e) => {
  //   setAntiVirus(e.target.value)
  // }

  // const checkedPasswordPolicy = (e) => {
  //   setPasswordPolicy(e.target.value)
  // }

  // const checkedScreenPolicy = (e) => {
  //   setScreenPolicy(e.target.value)
  // }

  // const checkedFirewall = (e) => {
  //   setFirewall(e.target.value)
  // }

  // const checkedAuditScope = (e) => {
  //   setAuditScope(e.target.value)
  // }

  return (
    <>
      <div className='card card-custom'>
        <div className='card-header'>
          <h3 className='card-title'>Add Control</h3>
        </div>
        {showAlert && (
          <div style={{marginTop: 20, marginLeft: 20, marginRight: 20}}>
            {message?.msg && (
              <div
                className={
                  message?.error
                    ? 'alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row'
                    : 'alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row'
                }
              >
                {message?.error ? (
                  <i className='ki-duotone ki-notification-bing fs-2hx text-danger me-4 mb-5 mb-sm-0'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                ) : (
                  <i className='ki-duotone ki-notification-bing fs-2hx text-success me-4 mb-5 mb-sm-0'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                )}

                <div
                  className={
                    message?.error
                      ? 'd-flex flex-column text-danger text-primary text-bold pe-0 pe-sm-10'
                      : 'd-flex flex-column text-success text-primary text-bold pe-0 pe-sm-10'
                  }
                >
                  {message?.error ? (
                    <h5 className='mb-1'>Error!!</h5>
                  ) : (
                    <h5 className='mb-1'>Sucess!!</h5>
                  )}
                  <span>{message?.msg}</span>
                </div>
                <button
                  type='button'
                  className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
                  onClick={() => setShowAlert(false)}
                >
                  <span className='svg-icon svg-icon-1 svg-icon-success'>
                    <span className='svg-icon svg-icon-2x svg-icon-primary'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        className='mh-50px'
                      >
                        <rect
                          opacity='0.5'
                          x='6'
                          y='17.3137'
                          width='16'
                          height='2'
                          rx='1'
                          transform='rotate(-45 6 17.3137)'
                          fill='black'
                        ></rect>
                        <rect
                          x='7.41422'
                          y='6'
                          width='16'
                          height='2'
                          rx='1'
                          transform='rotate(45 7.41422 6)'
                          fill='black'
                        ></rect>
                      </svg>
                    </span>
                  </span>
                </button>
              </div>
            )}
          </div>
        )}

        <form className='form' onSubmit={handleSubmit}>
          <div className='card-body'>
            <div className='form-group'>
              <label className='text-dark fw-bold text-primary my-2 fs-6'>Control Id</label>
              <input
                type='text'
                className='form-control form-control-solid'
                placeholder='Name'
                name='control_id'
                value={control_id}
                onChange={(e) => setControl_id(e.target.value)}
              />
            </div>

            <div className='form-group'>
              <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6'>control Uid</label>
              <div className='input-group input-group-lg'>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='Model'
                  value={control_uid}
                  onChange={(e) => setControl_uid(e.target.value)}
                />
              </div>
            </div>

            <div className='form-group'>
              <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6'>control_title</label>
              <input
                type='text'
                className='form-control form-control-solid'
                placeholder='OS Version'
                value={control_title}
                onChange={(e) => setControl_title(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6'>
                Control Description
              </label>
              <textarea
                className='form-control form-control-solid'
                value={control_description}
                placeholder='Enter control description'
                onChange={(e) => setControl_description(e.target.value)}
              />
            </div>

            {/* <div className='form-group'>
              <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6'>
                Control Status
              </label>
              <input
                type='text'
                className='form-control form-control-solid'
                value={control_evidence_status}
                placeholder='Enter owner name'
                onChange={(e) => setControl_evidence_status(e.target.value)}
              />
            </div> */}
            <div className='form-group'>
              <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6'>
                Control Status
              </label>
              <select
                className='form-select form-select-solid'
                value={control_evidence_status}
                onChange={(e) => setControl_evidence_status(e.target.value)}
              >
                <option value=''>Select Status</option>
                <option value='OK'>OK</option>
                <option value='Evidence missing'>Evidence missing</option>
                <option value='Unhealthy'>Unhealthy</option>
              </select>
            </div>
            <div className='form-group'>
              <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6'>
                Control Framework
              </label>
              <input
                type='text'
                className='form-control form-control-solid'
                value={control_framework_mappings}
                placeholder='Enter owner name'
                onChange={(e) => setControl_framework_mappings(e.target.value)}
              />
            </div>

            <div className='form-group'>
              <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6'>
                Control Category
              </label>
              <input
                type='text'
                className='form-control form-control-solid'
                value={control_category}
                placeholder='Enter owner name'
                onChange={(e) => setControl_category(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6'>Control Owner</label>
              <input
                type='text'
                className='form-control form-control-solid'
                value={control_owner}
                placeholder='Enter owner name'
                onChange={(e) => setControl_owner(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6'>Tests</label>
              <input
                type='text'
                className='form-control form-control-solid'
                value={control_test}
                placeholder='Enter owner name'
                onChange={(e) => setControl_test(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6'>Documents</label>
              <input
                type='text'
                className='form-control form-control-solid'
                value={control_document}
                placeholder='Enter document name'
                onChange={(e) => setControl_document(e.target.value)}
              />
            </div>
          </div>
          <div className='card-footer'>
            {isLoading ? (
              <button
                type='submit'
                value='Submit'
                className='btn'
                // onClick={alertMessage}
                style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
              >
                <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                Add Control
              </button>
            ) : (
              <button
                type='submit'
                value='Submit'
                className='btn'
                // onClick={alertMessage}
                style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
              >
                Add Control
              </button>
            )}

            <a
              className='btn btn-secondary'
              href='/asset-management'
              role='button'
              style={{marginLeft: 20}}
            >
              Cancel
            </a>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddControl
