import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import { useAuth } from '../modules/auth';

const useTenantCheck = () => {
  const { currentUser,logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser?.tenantId) {
      toast.error('Session expired');
      navigate('/login');
      logout()
    }
  }, [currentUser, navigate,logout]);
};

export default useTenantCheck;
