/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren, toAbsoluteUrl} from '../../helpers'
import {KTIcon} from '../../helpers'
import {Link, useLocation, useParams} from 'react-router-dom'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
})

const PageDataProvider: FC<WithChildren> = ({children}) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])

  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: string
  breadcrumbs?: Array<PageLink>
  title?: string
  icon?: string
  desc?: string
}

const AssetPageTitle: FC<Props & WithChildren> = ({
  children,
  description,
  breadcrumbs,
  title,
  icon,
  desc,
}) => {
  const {setPageTitle, setPageDescription, setPageBreadcrumbs} = usePageData()
  const [activeTab, setActiveTab] = useState('asset-management')
  const location = useLocation()
  const {id} = useParams()
  useEffect(() => {
    if (children) {
      setPageTitle(children.toString())
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  useEffect(() => {
    // Fetch control data when the component mounts
    // if (location.pathname.includes('overview')) {
    //   console.log('Setting active tab to overview')
    //   setActiveTab('overview')
    // } else  
    if (location.pathname.includes('repositories')) {
        console.log('Setting active tab to repository')
        setActiveTab('repositories')
      }
    else {
      console.log('Setting active tab to risk management')
      setActiveTab('asset-management')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname,id])
  return (
    <>
      {icon ? (
        <div className={`card mb-5 mb-xl-8`}>
          <div className='card-header border-0 pt-5 mb-5'>
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-45px me-5 '>
                <img src={toAbsoluteUrl(`/media/logos/${icon}`)} alt='' /> 
              </div>
              <div className='d-flex justify-content-start flex-column'>
                <span className='card-label fw-bold fs-5'>{title}</span>
                <span className='fw-semibold fs-6' style={{color:'#5A5A5A'}}>{desc}</span>
              </div>
            </div>
          </div>
          {/*begin::Navs*/}
          <div className='px-10 '>
          
              <div className='d-flex  h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap '>
              
                  {/* <li className='nav-item'>
                <Link
                  to={`/asset-management/overview`}
                  className={`nav-link me-6 ${
                    activeTab === 'overview' ? 'text-active-primary active' : ''
                  }`}
                >
                 Overview
                </Link>
              </li> */}
              <li className='nav-item'>
                <Link
                  to={'/asset-management'}
                  className={`nav-link me-6 ${
                    activeTab === 'asset-management' ? 'text-active-primary active' : ''
                  }`}
                >
                  Assets
                </Link>
              </li>
           
             <li className='nav-item'>
                <Link
                  to={'/asset-management/repositories'}
                  className={`nav-link me-6 ${
                    activeTab === 'repositories' ? 'text-active-primary active' : ''
                  }`}
                >
                  Repositories
                </Link>
              </li>

               
                </ul>
              </div>
          
            </div>
          {/* </div> */}
          {/*begin::Navs*/}
        </div>
      ) : null}
    </>
  )
}

const PageDescription: FC<WithChildren> = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export {PageDescription, AssetPageTitle, PageDataProvider, usePageData}
