import React, {FC, useEffect, useState} from 'react'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'
import {CloudPageTitle} from '../../../_metronic/layout/core/CloudPageData'

import {VendorListHeader} from './components/header/VendorListHeader'
import {KTCard} from '../../../_metronic/helpers'
import {Link, useLocation, useParams} from 'react-router-dom'
import db from '../../../services/firebase'
import {useAuth} from '../../modules/auth'
import {Domain} from './Domain'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DomainWrapper: FC = () => {
  const [activeTab, setActiveTab] = useState('domains')
  const [totalVendor, setTotalVendor] = useState(0)
  const location = useLocation()
  const {id} = useParams()
  const {currentUser} = useAuth()
  useEffect(() => {
    // Fetch control data when the component mounts
    if (location.pathname.includes('checks')) {
      setActiveTab('checks')
    } else {
      setActiveTab('domains')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  // useEffect(() => {
  //   const collectionRef = db.collection('vendorManagement');

  //   const unsubscribe = collectionRef.onSnapshot((snapshot) => {
  //     const count = snapshot.size; // Get the number of documents in the collection
  //     setTotalVendor(count);
  //   });

  //   return () => unsubscribe(); // Clean up the subscription when the component unmounts
  // }, []);

  useEffect(() => {
    const currentUserTenantId = currentUser?.tenantId

    const collectionRef = db
      .collection('vendorManagement')
      .where('tenantId', '==', currentUserTenantId)

    const unsubscribe = collectionRef.onSnapshot((snapshot) => {
      const count = snapshot.size // Get the number of documents in the filtered collection
      setTotalVendor(count)
    })

    return () => unsubscribe() // Clean up the subscription when the component unmounts
  }, [])

  return (
    <>
      <PageTitle
        icon='Icon-3.svg'
        title='Domains'
        // desc={totalVendor.toString() + ' Vendors'}
        desc={'1 connected domain'}
        breadcrumbs={usersBreadcrumbs}
      >
        Vendor Risk Management
      </PageTitle>
      <KTCard>
        <div className='pt-3 pb-0 px-10'>
          {/*begin::Navs*/}
          <div className='d-flex  h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
              {/*begin::Nav item*/}
              <li className='nav-item'>
                <Link
                  to={`/domains`}
                  className={`nav-link me-6 ${
                    activeTab === 'domains' ? 'text-active-primary active' : ''
                  }`}
                >
                  Domains
                </Link>
              </li>
              {/*end::Nav item*/}
              {/*begin::Nav item*/}
              <li className='nav-item'>
                <Link
                  to={'/domains/checks'}
                  className={`nav-link me-6 ${
                    activeTab === 'checks' ? 'text-active-primary active' : ''
                  }`}
                >
                  Checks
                </Link>
              </li>

              {/*end::Nav item*/}
            </ul>
          </div>
          {/*begin::Navs*/}
        </div>
        {/* <VendorListHeader /> */}
        <Domain className='card-xl-stretch mb-xl-8' />
      </KTCard>
    </>
  )
}

export default DomainWrapper
