import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import VendorRiskWrapper from '../pages/vendor/VendorRiskWrapper'
import PersonnelManagementWrapper from '../pages/personnel-management/PersonnelManagementWrapper'
import DataRoomWrapper from '../pages/data-room/DataRoomWrapper'
import AssetManagementWrapper from '../pages/asset-management/AssetManagementWrapper'
import IntegrationWrapper from '../pages/integrations/IntegrationWrapper'
import RiskManagementWrapper from '../pages/risk-management/RiskManagementWrapper'
import ReportsWrapper from '../pages/reports/ReportsWrapper'
import PolicyPage from '../pages/policy-management/PolicyPage'
// import AddPolicy from '../modules/apps/policy-management/users-list/components/AddPolicy'
import Github from '../pages/vendor/applications/Github/Github'
import {ChatGPT} from '../pages/chat/ChatGPT'
import Asana from '../pages/vendor/applications/Asana/Asana'
import Password from '../pages/vendor/applications/1Password/1Password'
import Discord from '../pages/vendor/applications/Discord/Discord'
import Notion from '../pages/vendor/applications/Notion/Notion'
import GoogleSuite from '../pages/vendor/applications/GoogleSuite/GoogleSuite'
import Jira from '../pages/vendor/applications/Jira/Jira'
import Zoom from '../pages/vendor/applications/Zoom/Zoom'
import Slack from '../pages/vendor/applications/Slack/Slack'
import MicrosoftTeam from '../pages/vendor/applications/MicrosoftTeam/MicrosoftTeam'
import ControlManagementWrapper from '../pages/controls/ControlManagementWrapper'
import {Requirements} from '../pages/controlInformation/Requirements'
import {Testing} from '../pages/controlInformation/Testing'
import TestManagementWrapper from '../pages/tests/TestManagementWrapper'
import PasswordDocuments from '../pages/vendor/applications/1Password/PasswordDocuments'
import AsanaDocuments from '../pages/vendor/applications/Asana/AsanaDocuments'
import DiscordDocuments from '../pages/vendor/applications/Discord/DiscordDocuments'
import NotionDocuments from '../pages/vendor/applications/Notion/NotionDocuments'
import GoogleDocuments from '../pages/vendor/applications/GoogleSuite/GoogleDocuments'
import JiraDocuments from '../pages/vendor/applications/Jira/JiraDocuments'
import SlackDocuments from '../pages/vendor/applications/Slack/SlackDocuments'
import MicrosoftDocuments from '../pages/vendor/applications/MicrosoftTeam/MicrosoftDocuments'
import GithubDocuments from '../pages/vendor/applications/Github/GithubDocument'
import Dropbox from '../pages/vendor/applications/Dropbox/Dropbox'
import DropboxDocuments from '../pages/vendor/applications/Dropbox/DropboxDocuments'
import Figma from '../pages/vendor/applications/Figma/Figma'
import DocuSign from '../pages/vendor/applications/DocuSign/DocuSign'
import PandaDoc from '../pages/vendor/applications/PandaDoc/PandaDoc'
import PandaDocuments from '../pages/vendor/applications/PandaDoc/PandaDocuments'
import TypeForm from '../pages/vendor/applications/TypeForm/TypeForm'
import TypeFormDocuments from '../pages/vendor/applications/TypeForm/TypeFormDocuments'
import ZoomDocuments from '../pages/vendor/applications/Zoom/ZoomDocuments'
import {Available} from '../pages/vendor/tab-menu-list/Available'
import {Archive} from '../pages/vendor/tab-menu-list/Archive'
import AddVendor from '../pages/vendor/components/AddVendor'
import EditVendor from '../pages/vendor/components/EditVendor'
import DetailVendor from '../pages/vendor/components/DetailVendor'
import FigmaDocuments from '../pages/vendor/applications/Figma/FigmaDocuments'
import {ControlInformationManagement} from '../pages/controlInformation/ControlInformationManagement'
import RiskDetailsWrapper from '../pages/risk-management-details/RiskDetailsWrapper'
import CompleteAssessmentWrapper from '../pages/risk-management-details/CompleteAssesmentWrapper'
import TestDetailsWrapper from '../pages/test-details/TestDetailsWrapper'
import {TrainingPage} from '../onboarding/onDashBoard/Training'
import Policies from '../onboarding/onDashBoard/Policies'

import BackgroundCheck from '../onboarding/onDashBoard/BackgroundCheck'
import SAT from '../onboarding/components/SAT'
import HIPAA from '../onboarding/components/HIPAA'
import PCI from '../onboarding/components/PCI'
import PCISecure from '../onboarding/components/PCISecure'
import CCPA from '../onboarding/components/CCPA'
import GDPR from '../onboarding/components/GDPR'
import {PolicyTemplates} from '../pages/policy-management/tab-menu-list/PolicyTemplates'
import PersonnelGroup from '../pages/personnel-management/tab-menu-list/PersonnelGroup'
import PersonnelChecklist from '../pages/personnel-management/tab-menu-list/PersonnelChecklist'
import SettingPage from '../pages/settings/SettingPage'

import SettingAdvanced from '../pages/settings/tab-menu-list/SettingAdvanced'
import SettingFramework from '../pages/settings/tab-menu-list/SettingFramework'
import SettingSecurity from '../pages/settings/tab-menu-list/SettingSecurity'
import SettingLanguage from '../pages/settings/tab-menu-list/SettingLanguage'
import SettingRoles from '../pages/settings/tab-menu-list/SettingRoles'
import RolePermissiondetails from '../pages/settings/sub-tab-menu-list/RolePermissiondetails'
import SettingsUserpermissions from '../pages/settings/tab-menu-list/SettingsUserpermissions'
import UserAuditors from '../pages/settings/sub-tab-menu-list/UserAuditors'
import MSP from '../pages/settings/sub-tab-menu-list/MSP'
import AddControl from '../pages/controls/components/AddControl'
import CloudRiskWrapper from '../pages/Clouds/CloudRiskWrapper'
import {Checks} from '../pages/Clouds/tab-menu-list/Checks'
import {Containers} from '../pages/Clouds/tab-menu-list/Containers'
import {Assets} from '../pages/Clouds/tab-menu-list/Assets'
import DomainWrapper from '../pages/Domains/DomainWrapper'
import {DomainChecks} from '../pages/Domains/tab-menu-list/DomainChecks'
import DetailControl from '../pages/controls/components/DetailControl'
import DetailsTest from '../pages/tests/components/DetailsTest'
import ViewReports from '../pages/reports/components/ViewReports'
import {RiskLibrary} from '../pages/risk-management/components/tab-menu/RiskLibrary'
import RiskOverview from '../pages/risk-management/components/tab-menu/RiskOverview'
import Availabe from '../pages/integrations/components/tabs/Availabe'
import IntegrationForm from '../pages/integrations/components/IntegrationForm'
import SettingsCompany from '../pages/settings/tab-menu-list/SettingsCompany'
// import CloudsAWSWrapper from '../pages/Clouds-link/CloudsAWSWrapper'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import ViewVendor from '../pages/vendor/components/ViewVendor'
import Diligence from '../pages/vendor/components/subcomponents/Diligence'
import ReportPageWrapper from '../pages/report-page/ReportPageWrapper'
import TaskManagementWrapper from '../pages/tasks/TaskManagementWrapper'
import { AssetRepository } from '../pages/asset-management/components/tab-menu/AssetRepository'
import ActivityLog from '../pages/report-page/pages/ActivityLog'

const PrivateRoutes = () => {
  const {currentUser} = useAuth()

  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  // const PolicyPage = lazy(() => import('../modules/apps/policy-management/PolicyPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {currentUser?.role === 'Member' ? (
          <Route path='/*' element={<Navigate to='/training' />} />
        ) : (
          <>
            <Route path='auth/*' element={<Navigate to='/dashboard' />} />
            <Route path='dashboard' element={<DashboardWrapper />} />
            <Route path='builder' element={<BuilderPageWrapper />} />

            <Route
              path='policy-library/*'
              element={
                <SuspensedView>
                  <PolicyPage />
                </SuspensedView>
              }
            />
            {/* Settings-start */}
            <Route path='company-settings' element={<SettingPage />} />
            <Route path='company-settings/advanced' element={<SettingAdvanced />} />
            <Route path='company-settings/framework' element={<SettingFramework />} />
            <Route path='company-settings/company_info' element={<SettingsCompany />} />
            <Route path='company-settings/users' element={<SettingsUserpermissions />} />
            <Route path='company-settings/users/auditors' element={<UserAuditors />} />
            <Route path='company-settings/users/msp' element={<MSP />} />
            {/* <Route path='company-settings/security' element={<SettingSecurity/>} />
        <Route path='company-settings/language' element={<SettingLanguage/>} />
        <Route path='company-settings/roles' element={<SettingRoles/>} />
        <Route path='company-settings/roles' element={<SettingRoles/>} />
        <Route path='company-settings/roles/PermissionDetails' element={<RolePermissiondetails/>} />
        <Route path='company-settings/users' element={<SettingsUserpermissions/>} />
        <Route path='company-settings/users/auditors' element={<UserAuditors/>} />
        <Route path='company-settings/users/msp' element={<MSP/>} /> */}

            {/* Settings-end */}

            <Route path='policy-library/*' element={<PolicyPage />} />
            {/* <Route path='/policy-library/PolicyTemplates' element={<PolicyTemplates />} /> */}
            <Route path='vendor-risk' element={<VendorRiskWrapper />} />
            <Route path='vendor-risk/details/:id' element={<ViewVendor />} />
            <Route path='vendor-risk/details/diligence/:id' element={<Diligence />} />
            <Route path='employees' element={<PersonnelManagementWrapper />} />
            <Route path='employees/group' element={<PersonnelGroup />} />
            <Route path='/employees/checklist' element={<PersonnelChecklist />} />
            <Route path='data-room' element={<DataRoomWrapper />} />
            <Route path='asset-management' element={<AssetManagementWrapper />} />
            <Route path='asset-management/repositories' element={<AssetRepository />} />
            <Route path='integrations' element={<IntegrationWrapper />} />
            <Route path='integrations/available' element={<Availabe />} />
            {/* <Route path='integrations/form' element={<IntegrationForm />} /> //{for adding integration use this route} */}
            <Route path='menu-test' element={<MenuTestPage />} />
            {/* <Route path='asset-management/add-asset' element={<AddAsset />} /> */}
            <Route path='risk-management' element={<RiskManagementWrapper />} />
            <Route path='risk-management/library' element={<RiskLibrary />} />
            <Route path='risk-management/overview' element={<RiskOverview />} />
            <Route path='risk-management/:id' element={<RiskManagementWrapper />} />

            <Route path='/frameworks' element={<ReportsWrapper />} />
            <Route path='/reports' element={<ReportPageWrapper/>} />
            <Route path='/reports/activity-logs' element={<ActivityLog/>} />
            <Route path='vendor-management/add-vendor' element={<AddVendor />} />
            <Route path='vendor-management/detail-vendor/:id' element={<DetailVendor />} />
            <Route path='vendor-management/edit-vendor/:id' element={<EditVendor />} />
            <Route path='/vendor-risk/archive' element={<Archive />} />
            <Route path='/vendor-risk/archive/:id' element={<DetailVendor />} />
            <Route path='vendor-risk/github/details' element={<Github />} />
            <Route path='vendor-risk/asana/details' element={<Asana />} />
            <Route path='vendor-risk/password/details' element={<Password />} />
            <Route path='vendor-risk/discord/details' element={<Discord />} />
            <Route path='vendor-risk/notion/details' element={<Notion />} />
            <Route path='vendor-risk/google-suite/details' element={<GoogleSuite />} />
            <Route path='vendor-risk/atlassian-jira/details' element={<Jira />} />
            <Route path='vendor-risk/zoom/details' element={<Zoom />} />
            <Route path='vendor-risk/slack/details' element={<Slack />} />
            <Route path='vendor-risk/microsoft-team/details' element={<MicrosoftTeam />} />
            <Route path='vendor-risk/dropbox/details' element={<Dropbox />} />
            <Route path='vendor-risk/figma/details' element={<Figma />} />
            <Route path='vendor-risk/docusign/details' element={<DocuSign />} />
            <Route path='vendor-risk/panda-doc/details' element={<PandaDoc />} />
            <Route path='vendor-risk/type-form/details' element={<TypeForm />} />
            <Route path='/controls' element={<ControlManagementWrapper />} />
            <Route path='/tests' element={<TestManagementWrapper />} />
            <Route path='/tests/details/:id' element={<DetailsTest />} />
            <Route path='/vendor-risk/password/documents' element={<PasswordDocuments />} />
            <Route path='/vendor-risk/asana/documents' element={<AsanaDocuments />} />
            <Route path='/vendor-risk/discord/documents' element={<DiscordDocuments />} />
            <Route path='/vendor-risk/notion/documents' element={<NotionDocuments />} />
            <Route path='/vendor-risk/google-suite/documents' element={<GoogleDocuments />} />
            <Route path='/vendor-risk/atlassian-jira/documents' element={<JiraDocuments />} />
            <Route path='/vendor-risk/google-suite/documents' element={<NotionDocuments />} />
            <Route path='/vendor-risk/slack/documents' element={<SlackDocuments />} />
            <Route path='/vendor-risk/microsoft-team/documents' element={<MicrosoftDocuments />} />
            <Route path='/vendor-risk/github/documents' element={<GithubDocuments />} />
            <Route path='/vendor-risk/dropbox/documents' element={<DropboxDocuments />} />
            <Route path='/vendor-risk/panda-doc/documents' element={<PandaDocuments />} />
            <Route path='/vendor-risk/type-form/documents' element={<TypeFormDocuments />} />
            <Route path='/vendor-risk/zoom/documents' element={<ZoomDocuments />} />
            <Route path='/vendor-risk/figma/documents' element={<FigmaDocuments />} />
            <Route path='/vendor-risk/available' element={<Available />} />
            <Route path='/vendor-risk/archive' element={<Archive />} />
            <Route path='/tasks' element={<TaskManagementWrapper />} />


            <Route path='/clouds' element={<CloudRiskWrapper />} />
            <Route path='/clouds/checks' element={<Checks />} />
            <Route path='/clouds/containers' element={<Containers />} />
            <Route path='/clouds/assets' element={<Assets />} />
            {/* <Route path='/clouds/AWS' element={<CloudsAWSWrapper/>} /> */}
            <Route path='/controls/addControls' element={<AddControl />} />
            <Route path='/controls/details/:id' element={<DetailControl />} />
            <Route path='/domains' element={<DomainWrapper />} />
            <Route path='/domains/checks' element={<DomainChecks />} />

            <Route path='/frameworks/details/:name' element={<ViewReports />} />

            <Route
              path='/controls/:id/requirements'
              element={
                <SuspensedView>
                  <Requirements />
                </SuspensedView>
              }
            />
            <Route
              path='/controls/:id/details'
              element={
                <SuspensedView>
                  <ControlInformationManagement />
                </SuspensedView>
              }
            />
            <Route
              path='/controls/:id/testing'
              element={
                <SuspensedView>
                  <Testing />
                </SuspensedView>
              }
            />
            <Route
              path='/risk-management/:id/details'
              element={
                <SuspensedView>
                  <RiskDetailsWrapper />
                </SuspensedView>
              }
            />
            <Route
              path='/tests/:id/details'
              element={
                <SuspensedView>
                  <TestDetailsWrapper />
                </SuspensedView>
              }
            />
            <Route
              path='/tests/:id/details/overview'
              element={
                <SuspensedView>
                  <TestDetailsWrapper />
                </SuspensedView>
              }
            />
            <Route
              path='/tests/:id/details'
              element={
                <SuspensedView>
                  <TestDetailsWrapper />
                </SuspensedView>
              }
            />
            <Route
              path='/tests/:id/details'
              element={
                <SuspensedView>
                  <TestDetailsWrapper />
                </SuspensedView>
              }
            />
            <Route
              path='/tests/:id/details'
              element={
                <SuspensedView>
                  <TestDetailsWrapper />
                </SuspensedView>
              }
            />
            <Route
              path='/risk-management/:id/details/complete-assessment'
              element={
                <SuspensedView>
                  <CompleteAssessmentWrapper />
                </SuspensedView>
              }
            />
            <Route
              path='/risk-management/:id/history'
              element={
                <SuspensedView>
                  <Testing />
                </SuspensedView>
              }
            />
            <Route path='/chatgpt' element={<ChatGPT />} />
            {/* Lazy Modules */}
            <Route
              path='crafted/pages/profile/*'
              element={
                <SuspensedView>
                  <ProfilePage />
                </SuspensedView>
              }
            />
            <Route
              path='crafted/pages/wizards/*'
              element={
                <SuspensedView>
                  <WizardsPage />
                </SuspensedView>
              }
            />
            <Route
              path='crafted/widgets/*'
              element={
                <SuspensedView>
                  <WidgetsPage />
                </SuspensedView>
              }
            />

            <Route
              path='apps/chat/*'
              element={
                <SuspensedView>
                  <ChatPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/user-management/*'
              element={
                <SuspensedView>
                  <UsersPage />
                </SuspensedView>
              }
            />
          </>
        )}

        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route path='training' element={<TrainingPage completed={false} />} />
        <Route path='policies' element={<Policies />} />
        <Route path='backgroundCheck' element={<BackgroundCheck />} />
        {/* comp0nents in training */}
        <Route path='training/SAT' element={<SAT />} />
        <Route path='training/HIPAA' element={<HIPAA />} />
        <Route path='training/PCI' element={<PCI />} />
        <Route path='training/PCISecure' element={<PCISecure />} />
        <Route path='training/CCPA' element={<CCPA />} />
        <Route path='training/GDPR' element={<GDPR />} />
        {/* end */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
