import React, {useEffect, useState} from 'react'
import {SettingPageTitle} from '../components/header/SettingPageTitle'
import {collection, doc, onSnapshot} from 'firebase/firestore'
import db from '../../../../services/firebase'
import {useAuth} from '../../../modules/auth'

const SettingFramework = () => {
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const {currentUser} = useAuth()

  useEffect(() => {
    getActiveFrameworks()
  }, [])

  const getActiveFrameworks = () => {
    try {
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
        console.log(frameworks)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  console.log(activeFrameworks)
  return (
    <>
      <SettingPageTitle icon='Settings.svg' title='Settings' desc=' Manage your settings'>
        Personnel Management
      </SettingPageTitle>
      <div className='card py-3'>
        <div className='card-body'>
          {/* begin::Table container */}
          <div className='table-responsive' style={{height: '200px', overflowY: 'auto'}}>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-200px '>FRAMEWORK</th>
                  <th className='min-w-100px'>STATUS</th>

                  <th className='min-w-50px'></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}

              {activeFrameworks.map((data) => (
                <tbody>
                  <tr>
                    <td>
                      <div className='d-flex align-items-center mx-5 fs-6'>{data}</div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-20px me-15'>
                          {/* <span className="badge badge-light">Active</span> */}
                          <img
                            src='/media/buttons/Active.svg'
                            alt='Active'
                            className='w-100'
                            style={{width: '25px', height: '25px'}}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <button className='btn btn-primary btn-sm'>Snooze</button>
                      </div>
                    </td>
                  </tr>

                  {/* <tr>
                  <td>
                    <div className='d-flex align-items-center mx-5 fs-6'>SOC 2</div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-20px me-15'>
                    
                        <img
                          src='/media/buttons/Active.svg'
                          alt='Active'
                          className='w-100'
                          style={{width: '25px', height: '25px'}}
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <button className='btn btn-primary btn-sm'>Snooze</button>
                    </div>
                  </td>
                </tr> */}
                </tbody>
              ))}
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      </div>
      {/* begin::Body */}
    </>
  )
}

export default SettingFramework
