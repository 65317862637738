import React, {useEffect, useRef, useState} from 'react'
import {IntegrationPageTitle} from '../../../../../_metronic/layout/core/IntegrationPageData'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import AWSDrawer from '../drawers/AWSDrawer'
import db from '../../../../../services/firebase'
import {Overlay, Popover} from 'react-bootstrap'
import {collection, doc, onSnapshot} from 'firebase/firestore'
import {useAuth} from '../../../../modules/auth'
import AsanaDrawer from '../drawers/AsanaDrawer'
import GitlabDrawer from '../drawers/GitlabDrawer'
import SentryDrawer from '../drawers/SentryDrawer'
import {AssetListLoading} from '../loading/AssetListLoading'
import GithubDrawer from '../drawers/GithubDrawer'
import {ToastContainer} from 'react-toastify'

const Availabe = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpen2, setIsModalOpen2] = useState(false)
  const [isModalOpen3, setIsModalOpen3] = useState(false)
  const [isModalOpen4, setIsModalOpen4] = useState(false)
  const [isModalOpen5, setIsModalOpen5] = useState(false)
  const [integrations, setIntegrations] = useState<any[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredIntegrations, setFilteredIntegrations] = useState<any[]>([])
  const [searchLength, setSearchLength] = useState(false)
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const overlayRef = useRef<HTMLElement>(null)
  const [totalFilterSelected, setTotalFilterSelected] = useState(0)
  const [selectedIntegration, setSelectedIntegration] = useState('')
  const [isCrispLoaded, setIsCrispLoaded] = useState(false)
  const [isChatOpen, setIsChatOpen] = useState(false)
  const [activeIntegrations, setActiveIntegrations] = useState<any[]>([])
  const {currentUser} = useAuth()
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 2000)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    getIntegrations()
    getActiveIntegrations()
  }, [])

  useEffect(() => {
    setFilteredIntegrations(integrations)
  }, [integrations])

  const getActiveIntegrations = () => {
    try {
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_integrations')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          integrations: doc.data().integrations,
        }))
        const integrations = frameworksData[0]?.integrations || []
        setActiveIntegrations(integrations)
        setShowEmptyState(false)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
      setShowEmptyState(false)
    }
  }

  useEffect(() => {
    if (!window.$crisp) {
      window.$crisp = []
      window.CRISP_WEBSITE_ID = '61030408-87ac-47eb-979f-e95d108e2d77'

      const script = document.createElement('script')
      script.src = 'https://client.crisp.chat/l.js'
      script.async = true

      script.onload = () => {
        setIsCrispLoaded(true)
        const wasChatOpen = localStorage.getItem('isChatOpen') === 'true'
        if (wasChatOpen) {
          window.$crisp.push(['do', 'chat:show'])
          window.$crisp.push(['do', 'chat:open'])
          setIsChatOpen(true)
        } else {
          window.$crisp.push(['do', 'chat:hide'])
          setIsChatOpen(false)
        }
      }

      document.head.appendChild(script)
    } else {
      setIsCrispLoaded(true)
      const wasChatOpen = localStorage.getItem('isChatOpen') === 'true'
      if (wasChatOpen) {
        window.$crisp.push(['do', 'chat:show'])
        window.$crisp.push(['do', 'chat:open'])
        setIsChatOpen(true)
      } else {
        window.$crisp.push(['do', 'chat:hide'])
        setIsChatOpen(false)
      }
    }
  }, [])

  useEffect(() => {
    if (isCrispLoaded) {
      if (isChatOpen) {
        window.$crisp.push(['do', 'chat:show'])
      } else {
        window.$crisp.push(['do', 'chat:hide'])
      }
    }
  }, [isChatOpen, isCrispLoaded])

  useEffect(() => {
    if (isCrispLoaded) {
      window.$crisp.push([
        'on',
        'chat:closed',
        () => {
          window.$crisp.push(['do', 'chat:hide'])
          setIsChatOpen(false)
          localStorage.setItem('isChatOpen', 'false')
        },
      ])
    }
  }, [isCrispLoaded])

  const handleCrispChat = () => {
    // setIsDropdownVisible(false);
    if (isCrispLoaded) {
      if (!isChatOpen) {
        window.$crisp.push(['do', 'chat:show'])
        window.$crisp.push(['do', 'chat:open'])
        setIsChatOpen(true)
        localStorage.setItem('isChatOpen', 'true')
      } else {
        window.$crisp.push(['do', 'chat:hide'])
        window.$crisp.push(['do', 'session:reset'])
        setIsChatOpen(false)
        localStorage.setItem('isChatOpen', 'false')
      }
    } else {
      console.log('Crisp is not loaded yet. Please wait a moment and try again.')
    }
  }

  const getIntegrations = () => {
    try {
      const collectionRef = db.collection('integrations')

      const unsubscribe = collectionRef.onSnapshot((snapshot) => {
        const integrations: any[] = []
        snapshot.forEach((doc) => {
          const data = doc.data()
          integrations.push(data)
        })
        setShowEmptyState(false)
        setIntegrations(integrations)
        console.log(integrations.length)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting integrations:', error)
      setShowEmptyState(false)
    }
  }

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target as Node) && show) {
        setShow(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [show])

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleOpenModal2 = () => {
    setIsModalOpen2(true)
  }
  const handleCloseModal2 = () => {
    setIsModalOpen2(false)
  }

  const handleOpenModal3 = () => {
    setIsModalOpen3(true)
  }
  const handleCloseModal3 = () => {
    setIsModalOpen3(false)
  }

  const handleOpenModal4 = () => {
    setIsModalOpen4(true)
  }
  const handleCloseModal4 = () => {
    setIsModalOpen4(false)
  }

  const handleOpenModal5 = () => {
    setIsModalOpen5(true)
  }
  const handleCloseModal5 = () => {
    setIsModalOpen5(false)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    let filteredItems = integrations

    if (selectedIntegration) {
      filteredItems = filteredItems.filter((item) => item.group === selectedIntegration)
    }

    if (searchTerm) {
      filteredItems = filteredItems.filter((item) => item.name.toLowerCase().includes(searchTerm))
      setSearchLength(filteredItems.length === 0)
    } else {
      setSearchLength(false)
    }

    setFilteredIntegrations(filteredItems)
  }

  const handleClick = (event) => {
    setShow(!show)
    setTarget(event.target)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (selectedIntegration) {
      const filteredItems = integrations.filter((item) => item.group === selectedIntegration)
      setFilteredIntegrations(filteredItems)
      setTotalFilterSelected(1)
    } else {
      setFilteredIntegrations(integrations)
      setTotalFilterSelected(0)
    }

    setShow(false)
  }

  const handleResetClick = () => {
    setSelectedIntegration('')
    setFilteredIntegrations(integrations)
    setTotalFilterSelected(0)
  }

  const integrationHandlers = {
    AWS: handleOpenModal,
    Asana: handleOpenModal2,
    GitLab: handleOpenModal3,
    Sentry: handleOpenModal4,
    GitHub: handleOpenModal5,
  }

  console.log(activeIntegrations)

  return (
    <>
      <ToastContainer />
      <IntegrationPageTitle
        icon='integrations.svg'
        title='Integrations'
        desc='Connect services and activate automated evidence collection & monitoring.'
        // breadcrumbs={usersBreadcrumbs}
      >
        Integrations
      </IntegrationPageTitle>
      <div className='card-title mb-4'>
        {/* begin::Search */}
        <div
          className='d-flex justify-content-between align-items-center'
          data-kt-user-table-toolbar='base'
        >
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control  w-250px ps-14'
              placeholder='Search..'
              value={searchTerm}
              onChange={handleInputChange}
            />
          </div>

          <button
            type='button'
            className='btn me-3 fs-5 border'
            style={{backgroundColor: '#fff'}}
            onClick={handleClick}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              viewBox='0 0 24 24'
              fill='none'
              stroke='#5A5A5A'
              stroke-width='2'
              stroke-linecap='round'
              stroke-linejoin='round'
              className='icon icon-tabler icons-tabler-outline icon-tabler-filter mb-1 mx-1'
            >
              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
              <path d='M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z' />
            </svg>
            Filter{' '}
            {totalFilterSelected > 0 && (
              <span className='badge border border-primary rounded-circle bg-primary text-white mx-1'>
                {totalFilterSelected}
              </span>
            )}
          </button>
          <Overlay
            show={show}
            target={target}
            placement='bottom'
            containerPadding={10}
            rootClose={true}
            onHide={() => setShow(false)}
          >
            <Popover id='popover-contained' ref={overlayRef as React.RefObject<HTMLDivElement>}>
              <Popover.Header as='h3' className='fw-bold fs-5'>
                Filter
              </Popover.Header>
              <Popover.Body className=''>
                <form onSubmit={handleSubmit}>
                  <div className='mb-6'>
                    <label htmlFor='Integrations' className='fw-semibold fs-6 mb-1'>
                      Category
                    </label>
                    <div className='mb-3 d-flex align-items-center'>
                      <select
                        id='Integrations'
                        className='form-select form-control'
                        aria-label='Select Integrations'
                        value={selectedIntegration}
                        onChange={(e) => setSelectedIntegration(e.target.value)}
                      >
                        <option value='' selected>
                          Select Category
                        </option>
                        <option value='Cloud Providers'>Cloud Services</option>
                        <option value='Business Suite'>Business Suite</option>
                        <option value='Background Checks'>Background Checks</option>
                        <option value='Device Management'>Device Management</option>
                        <option value='Developer Tools'>Developer Tools</option>
                        <option value='Endpoint Security'>Endpoint Security</option>
                        <option value='Human Resources'>Human Resources</option>
                        <option value='Password Management'>Password Management</option>
                        <option value='Single Sign-On'>Single Sign On</option>
                        <option value='Project Manager'>Project Manager</option>
                        <option value='Vulnerability Management'>Vulnerability Management</option>
                        <option value='Other'>Other</option>
                      </select>
                    </div>
                  </div>
                  <div className='d-flex justify-content-center'>
                    <button
                      type='button'
                      // className='btn btn-sm mx-2'
                      // style={{backgroundColor: '#f1fdf9', color: '#45dbaf'}}
                      onClick={handleResetClick}
                      className='btn btn-sm fs-6 '
                      onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#148363')
                      }
                      onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#1CBB8D')
                      }
                      style={{
                        backgroundColor: '#1CBB8D',
                        color: '#fff',
                        width: '80px',
                        height: '40px',
                        borderRadius: '4px',
                        left: '615px',
                        top: '20px',
                        marginRight: '8px',
                        transition: 'background-color 0.3s',
                      }}
                    >
                      Reset
                    </button>
                    <button
                      type='submit'
                      //  className='btn btn-primary btn-sm ml-5 mx-2'
                      className='btn btn-sm fs-6'
                      onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#148363')
                      }
                      onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#1CBB8D')
                      }
                      style={{
                        backgroundColor: '#1CBB8D',
                        color: '#fff',
                        width: '80px',
                        height: '40px',
                        borderRadius: '4px',
                        left: '615px',
                        top: '20px',
                        transition: 'background-color 0.3s',
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </form>
              </Popover.Body>
            </Popover>
          </Overlay>
        </div>
        {/* end::Search */}
      </div>
      <AWSDrawer isOpen={isModalOpen} onClose={handleCloseModal} />
      <AsanaDrawer isOpen={isModalOpen2} onClose={handleCloseModal2} />
      <GitlabDrawer isOpen={isModalOpen3} onClose={handleCloseModal3} />
      <SentryDrawer isOpen={isModalOpen4} onClose={handleCloseModal4} />
      <GithubDrawer isOpen={isModalOpen5} onClose={handleCloseModal5} />
      {(() => {
        // Get unique groups
        const groups = filteredIntegrations.reduce((acc: string[], item) => {
          if (!acc.includes(item.group)) {
            acc.push(item.group)
          }
          return acc
        }, [])

        return groups.map((group: any) => (
          <div key={group}>
            <h4 className='mb-4 mt-8'>{group}</h4>
            {filteredIntegrations
              .filter((item) => item.group === group)
              .map((item, index) => (
                <div key={`${group}-${index}`} className={`card mb-5 mb-xl-8`}>
                  <div className='card-header border-0 pt-5 mb-5'>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px me-5 '>
                        {/* <img src={toAbsoluteUrl(`${item.img}`)} loading='lazy' alt='' /> */}
                        <img src={toAbsoluteUrl(`${item.img_path}`)} loading='lazy' alt='' />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='card-label fw-bold fs-5'>{item.name}</span>
                        <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                          {item.description}
                        </span>
                      </div>
                    </div>
                    {activeIntegrations.includes(item.name) ? (
                      <button
                        onClick={integrationHandlers[item.name] || handleOpenModal}
                        className='btn btn-sm fs-6 mt-2'
                        onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                          (e.currentTarget.style.backgroundColor = '#148363')
                        }
                        onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                          (e.currentTarget.style.backgroundColor = '#1CBB8D')
                        }
                        style={{
                          backgroundColor: '#1CBB8D',
                          color: '#fff',
                          width: '100px',
                          height: '40px',
                          borderRadius: '4px',
                          left: '615px',
                          top: '20px',
                          transition: 'background-color 0.3s',
                        }}
                      >
                        Connect
                      </button>
                    ) : (
                      <button
                        // className='fs-6 fw-semibold rounded border-0 mt-2 mx-1'
                        onClick={() => handleCrispChat()}
                        className='btn btn-sm fs-6 mt-2'
                        onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                          (e.currentTarget.style.backgroundColor = '#148363')
                        }
                        onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                          (e.currentTarget.style.backgroundColor = '#1CBB8D')
                        }
                        style={{
                          backgroundColor: '#1CBB8D',
                          color: '#fff',
                          width: '100px',
                          height: '40px',
                          borderRadius: '4px',
                          left: '615px',
                          top: '20px',
                          transition: 'background-color 0.3s',
                        }}
                      >
                        Request
                      </button>
                    )}
                  </div>
                </div>
              ))}
          </div>
        ))
      })()}

      {showEmptyState && activeIntegrations.length === 0 && (
        <div
          className='d-flex justify-content-center align-items-center text-center'
          style={{height: '500px'}}
        >
          <AssetListLoading />
        </div>
      )}

      {/* <h4 className='mb-4 mt-8'>Cloud Providers</h4>
 
      {filteredIntegrations
        .filter((item) => item.group === 'Cloud Providers')
        .map((item) => (
          <>
      
          <div className={`card mb-5 mb-xl-8`}>
            <div className='card-header border-0 pt-5 mb-5'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-5 '>
                  <img src={toAbsoluteUrl(`${item.img}`)} alt='' />
                </div>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='card-label fw-bold fs-5'>{item.name}</span>
                  <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                    {item.description}
                  </span>
                </div>
              </div>
              <button
                className='fs-6 fw-semibold rounded border-0 mt-2 mx-1'
                onClick={handleOpenModal}
                style={{
                  background: '#1CBB8D',
                  color: '#fff',
                  height: '40px',
                  width: '92px',
                  top: '20px',
                  left: '223px',
                }}
              >
                Request
              </button>
            </div>
          </div>
          </>
        ))}

      <h4 className='mb-4'>Project Manager</h4>
      {filteredIntegrations
        .filter((item) => item.group === 'Project Manager')
        .map((item) => (
          <div className={`card mb-5 mb-xl-8`}>
            <div className='card-header border-0 pt-5 mb-5'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-5 '>
                  <img src={toAbsoluteUrl(`${item.img}`)} alt='' />
                </div>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='card-label fw-bold fs-5'>{item.name}</span>
                  <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                    {item.description}
                  </span>
                </div>
              </div>
              <button
                className='fs-6 fw-semibold rounded border-0 mt-2 mx-1'
                onClick={handleOpenModal}
                style={{
                  background: '#1CBB8D',
                  color: '#fff',
                  height: '40px',
                  width: '92px',
                  top: '20px',
                  left: '223px',
                }}
              >
                Request
              </button>
            </div>
          </div>
        ))}

      <h4 className='mb-4'>Business Suite</h4>
      {filteredIntegrations
        .filter((item) => item.group === 'Business Suite')
        .map((item) => (
          <div className={`card mb-5 mb-xl-8`}>
            <div className='card-header border-0 pt-5 mb-5'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-5 '>
                  <img src={toAbsoluteUrl(`${item.img}`)} alt='' />
                </div>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='card-label fw-bold fs-5'>{item.name}</span>
                  <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                    {item.description}
                  </span>
                </div>
              </div>
              <button
                className='fs-6 fw-semibold rounded border-0 mt-2 mx-1'
                onClick={handleOpenModal}
                style={{
                  background: '#1CBB8D',
                  color: '#fff',
                  height: '40px',
                  width: '92px',
                  top: '20px',
                  left: '223px',
                }}
              >
                Request
              </button>
            </div>
          </div>
        ))}

      <h4 className='mb-4'>Background Checks</h4>
      {filteredIntegrations
        .filter((item) => item.group === 'Background Checks')
        .map((item) => (
          <div className={`card mb-5 mb-xl-8`}>
            <div className='card-header border-0 pt-5 mb-5'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-5 '>
                  <img src={toAbsoluteUrl(`${item.img}`)} alt='' />
                </div>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='card-label fw-bold fs-5'>{item.name}</span>
                  <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                    {item.description}
                  </span>
                </div>
              </div>
              <button
                className='fs-6 fw-semibold rounded border-0 mt-2 mx-1'
                onClick={handleOpenModal}
                style={{
                  background: '#1CBB8D',
                  color: '#fff',
                  height: '40px',
                  width: '92px',
                  top: '20px',
                  left: '223px',
                }}
              >
                Request
              </button>
            </div>
          </div>
        ))}

      <h4 className='mb-4'>Human Resources</h4>
      {filteredIntegrations
        .filter((item) => item.group === 'Human Resources')
        .map((item) => (
          <div className={`card mb-5 mb-xl-8`}>
            <div className='card-header border-0 pt-5 mb-5'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-5 '>
                  <img src={toAbsoluteUrl(`${item.img}`)} alt='' />
                </div>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='card-label fw-bold fs-5'>{item.name}</span>
                  <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                    {item.description}
                  </span>
                </div>
              </div>
              <button
                className='fs-6 fw-semibold rounded border-0 mt-2 mx-1'
                onClick={handleOpenModal}
                style={{
                  background: '#1CBB8D',
                  color: '#fff',
                  height: '40px',
                  width: '92px',
                  top: '20px',
                  left: '223px',
                }}
              >
                Request
              </button>
            </div>
          </div>
        ))}

      <h4 className='mb-4'>Device Management</h4>
      {filteredIntegrations
        .filter((item) => item.group === 'Device Management')
        .map((item) => (
          <div className={`card mb-5 mb-xl-8`}>
            <div className='card-header border-0 pt-5 mb-5'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-5 '>
                  <img src={toAbsoluteUrl(`${item.img}`)} alt='' />
                </div>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='card-label fw-bold fs-5'>{item.name}</span>
                  <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                    {item.description}
                  </span>
                </div>
              </div>
              <button
                className='fs-6 fw-semibold rounded border-0 mt-2 mx-1'
                onClick={handleOpenModal}
                style={{
                  background: '#1CBB8D',
                  color: '#fff',
                  height: '40px',
                  width: '92px',
                  top: '20px',
                  left: '223px',
                }}
              >
                Request
              </button>
            </div>
          </div>
        ))}

      <h4 className='mb-4'>Endpoint Security</h4>
      {filteredIntegrations
        .filter((item) => item.group === 'Endpoint Security')
        .map((item) => (
          <div className={`card mb-5 mb-xl-8`}>
            <div className='card-header border-0 pt-5 mb-5'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-5 '>
                  <img src={toAbsoluteUrl(`${item.img}`)} alt='' />
                </div>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='card-label fw-bold fs-5'>{item.name}</span>
                  <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                    {item.description}
                  </span>
                </div>
              </div>
              <button
                className='fs-6 fw-semibold rounded border-0 mt-2 mx-1'
                onClick={handleOpenModal}
                style={{
                  background: '#1CBB8D',
                  color: '#fff',
                  height: '40px',
                  width: '92px',
                  top: '20px',
                  left: '223px',
                }}
              >
                Request
              </button>
            </div>
          </div>
        ))}

      <h4 className='mb-4'>Developer Tools</h4>
      {filteredIntegrations
        .filter((item) => item.group === 'Developer Tools')
        .map((item) => (
          <div className={`card mb-5 mb-xl-8`}>
            <div className='card-header border-0 pt-5 mb-5'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-5 '>
                  <img src={toAbsoluteUrl(`${item.img}`)} alt='' />
                </div>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='card-label fw-bold fs-5'>{item.name}</span>
                  <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                    {item.description}
                  </span>
                </div>
              </div>
              <button
                className='fs-6 fw-semibold rounded border-0 mt-2 mx-1'
                onClick={handleOpenModal}
                style={{
                  background: '#1CBB8D',
                  color: '#fff',
                  height: '40px',
                  width: '92px',
                  top: '20px',
                  left: '223px',
                }}
              >
                Request
              </button>
            </div>
          </div>
        ))}

      <h4 className='mb-4'>Password Management</h4>
      {filteredIntegrations
        .filter((item) => item.group === 'Password Management')
        .map((item) => (
          <div className={`card mb-5 mb-xl-8`}>
            <div className='card-header border-0 pt-5 mb-5'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-5 '>
                  <img src={toAbsoluteUrl(`${item.img}`)} alt='' />
                </div>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='card-label fw-bold fs-5'>{item.name}</span>
                  <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                    {item.description}
                  </span>
                </div>
              </div>
              <button
                className='fs-6 fw-semibold rounded border-0 mt-2 mx-1'
                onClick={handleOpenModal}
                style={{
                  background: '#1CBB8D',
                  color: '#fff',
                  height: '40px',
                  width: '92px',
                  top: '20px',
                  left: '223px',
                }}
              >
                Request
              </button>
            </div>
          </div>
        ))}

      <h4 className='mb-4'>Single Sign On</h4>
      {filteredIntegrations
        .filter((item) => item.group === 'Single Sign On')
        .map((item) => (
          <div className={`card mb-5 mb-xl-8`}>
            <div className='card-header border-0 pt-5 mb-5'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-5 '>
                  <img src={toAbsoluteUrl(`${item.img}`)} alt='' />
                </div>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='card-label fw-bold fs-5'>{item.name}</span>
                  <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                    {item.description}
                  </span>
                </div>
              </div>
              <button
                className='fs-6 fw-semibold rounded border-0 mt-2 mx-1'
                onClick={handleOpenModal}
                style={{
                  background: '#1CBB8D',
                  color: '#fff',
                  height: '40px',
                  width: '92px',
                  top: '20px',
                  left: '223px',
                }}
              >
                Request
              </button>
            </div>
          </div>
        ))}

      <h4 className='mb-4'>Single Sign On</h4>
      {filteredIntegrations
        .filter((item) => item.group === 'Single Sign On')
        .map((item) => (
          <div className={`card mb-5 mb-xl-8`}>
            <div className='card-header border-0 pt-5 mb-5'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-5 '>
                  <img src={toAbsoluteUrl(`${item.img}`)} alt='' />
                </div>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='card-label fw-bold fs-5'>{item.name}</span>
                  <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                    {item.description}
                  </span>
                </div>
              </div>
              <button
                className='fs-6 fw-semibold rounded border-0 mt-2 mx-1'
                onClick={handleOpenModal}
                style={{
                  background: '#1CBB8D',
                  color: '#fff',
                  height: '40px',
                  width: '92px',
                  top: '20px',
                  left: '223px',
                }}
              >
                Request
              </button>
            </div>
          </div>
        ))}

      <h4 className='mb-4'>Other</h4>
      {filteredIntegrations
        .filter((item) => item.group === 'Other')
        .map((item) => (
          <div className={`card mb-5 mb-xl-8`}>
            <div className='card-header border-0 pt-5 mb-5'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-5 '>
                  <img src={toAbsoluteUrl(`${item.img}`)} alt='' />
                </div>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='card-label fw-bold fs-5'>{item.name}</span>
                  <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                    {item.description}
                  </span>
                </div>
              </div>
              <button
                className='fs-6 fw-semibold rounded border-0 mt-2 mx-1'
                onClick={handleOpenModal}
                style={{
                  background: '#1CBB8D',
                  color: '#fff',
                  height: '40px',
                  width: '92px',
                  top: '20px',
                  left: '223px',
                }}
              >
                Request
              </button>
            </div>
          </div>
        ))} */}
    </>
  )
}

export default Availabe
