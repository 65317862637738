import React, {FC, useEffect, useState} from 'react'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'
import { CloudPageTitle} from '../../../_metronic/layout/core/CloudPageData'

import {VendorListHeader} from './components/header/VendorListHeader'
import {KTCard} from '../../../_metronic/helpers'
import {Link, useLocation, useParams} from 'react-router-dom'
import db from '../../../services/firebase'
import { useAuth } from '../../modules/auth'
import { Cloud } from './Cloud'


const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CloudRiskWrapper: FC = () => {
  const [activeTab, setActiveTab] = useState('overview')
  const[totalVendor,setTotalVendor]= useState(0);
  const location = useLocation()
  const {id} = useParams()
  const {currentUser} = useAuth()
  useEffect(() => {
    // Fetch control data when the component mounts
    if (location.pathname.includes('checks')) {
      setActiveTab('checks')
    
    }else if(location.pathname.includes('assets')) {
      setActiveTab('assets')
    
    }else if (location.pathname.includes('containers')) {
      setActiveTab('containers')
    
    } else {
      setActiveTab('overview')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  

  // useEffect(() => {
  //   const collectionRef = db.collection('vendorManagement'); 
  
  //   const unsubscribe = collectionRef.onSnapshot((snapshot) => {
  //     const count = snapshot.size; // Get the number of documents in the collection
  //     setTotalVendor(count);
  //   });
  
  //   return () => unsubscribe(); // Clean up the subscription when the component unmounts
  // }, []);

  useEffect(() => {
   
    const currentUserTenantId = currentUser?.tenantId; 
  
    const collectionRef = db.collection('vendorManagement').where('tenantId', '==', currentUserTenantId);
  
    const unsubscribe = collectionRef.onSnapshot((snapshot) => {
      const count = snapshot.size; // Get the number of documents in the filtered collection
      setTotalVendor(count);
    });
  
    return () => unsubscribe(); // Clean up the subscription when the component unmounts
  }, []);

  return (
    <>
      <CloudPageTitle
        icon='Cloud.svg'
        title='Clouds'
        // desc={totalVendor.toString() + ' Vendors'}
        desc={'1 connected cloud'}
        breadcrumbs={usersBreadcrumbs}
      >
        Cloud
      </CloudPageTitle>
      <KTCard>
        <div className='pt-3 pb-0 px-10'>
          {/*begin::Navs*/}
          <div className='d-flex  h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
              {/*begin::Nav item*/}
              <li className='nav-item'>
                <Link
                  to={`/clouds`}
                  className={`nav-link me-6 ${
                    activeTab === 'overview' ? 'text-active-primary active' : ''
                  }`}
                >
                  Overview
                </Link>
              </li>
              {/*end::Nav item*/}
              {/*begin::Nav item*/}
              <li className='nav-item'>
                <Link
                  to={'/clouds/checks'}
                  className={`nav-link me-6 ${
                    activeTab === 'checks' ? 'text-active-primary active' : ''
                  }`}
                >
                  Checks
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to={'/clouds/containers'}
                  className={`nav-link me-6 ${
                    activeTab === 'checks' ? 'text-active-primary active' : ''
                  }`}
                >
                  Containers
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to={'/clouds/assets'}
                  className={`nav-link me-6 ${
                    activeTab === 'assets' ? 'text-active-primary active' : ''
                  }`}
                >
                  Assets
                </Link>
              </li>
             
            
              {/*end::Nav item*/}
            </ul>
          </div>
          {/*begin::Navs*/}
        </div>
        {/* <VendorListHeader /> */}
        <Cloud className='card-xl-stretch mb-xl-8' />
      </KTCard>
    </>
  )
}

export default CloudRiskWrapper
