import db from '../../../../services/firebase'

import {collection, getDocs, getDoc, addDoc, updateDoc, deleteDoc, doc} from 'firebase/firestore'

const vendorCollectionRef = collection(db, 'vendorManagement')

class VendorDataService {
    addVendor = (newVendor) => {
      return addDoc(vendorCollectionRef, newVendor)
    }
  
    updateVendor= (id, updateVendor) => {
      const policyDoc = doc(db, 'vendorManagement', id)
      return updateDoc(policyDoc, updateVendor)
    }
  
    deleteVendor = (id) => {
      const policyDoc = doc(db, 'vendorManagement', id)
      return deleteDoc(policyDoc)
    }
  
    getAllVendors = () => {
      return getDocs(vendorCollectionRef)
    }
  
    getVendor = (id) => {
      const policyDoc = doc(db, 'vendorManagement', id)
      return getDoc(policyDoc)
    }
  }
  
  export default new VendorDataService()
  