import {collection, doc, getDocs, getFirestore, onSnapshot} from 'firebase/firestore'
import {saveAs} from 'file-saver'
import {useAuth} from '../../../../modules/auth'
import db from '../../../../../services/firebase'
import {useEffect, useState} from 'react'
const TestListToolbar = () => {
  const {currentUser} = useAuth()
  const [tests, setTests] = useState([] as any[])
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const [filteredItems, setFilteredItems] = useState<any[]>([])

  useEffect(() => {
    getActiveFramworks()
  }, [])

  useEffect(() => {
    getTests()
  }, [activeFrameworks])

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  useEffect(() => {
    if (activeFrameworks.length > 0) {
      const filteredTests = tests.filter((test) => {
        const testFrameworks = test.framework.split(',').map((fw) => fw.trim())

        return activeFrameworks.some((activeFramework) =>
          testFrameworks.some((testFramework) => testFramework === activeFramework)
        )
      })

      setFilteredItems(filteredTests)
    } else {
      setFilteredItems(tests)
    }
  }, [tests, activeFrameworks])

  const getTests = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const testsCollectionRef = collection(tenantRef, 'tests')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const testsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          framework: doc.data().framework,
          status: doc.data().status,
        }))

        setTests(testsData)
      })

      return unsubscribe
    } catch (error) {
      console.log('Error getting tests:', error)
    }
  }

  // const exportData = () => {
  //   if (filteredItems.length > 0) {
  //     const excludedFields = ['id', 'updated_at', 'created_at', 'notes', 'time_interval', 'files']
  //     const fields = Object.keys(filteredItems[0]).filter(
  //       (field) => !excludedFields.includes(field)
  //     )

  //     if (!fields.includes('timeframe') && fields.includes('timeFrame')) {
  //       fields.push('timeframe')
  //     }

  //     const csvContent = [fields.join(',')]

  //     filteredItems.forEach((row) => {
  //       const rowData = fields
  //         .map((field) => {
  //           if (Array.isArray(row[field])) {
  //             return `"${row[field].join('; ')}"`
  //           } else if (typeof row[field] === 'object' && row[field] !== null) {
  //             return `"${JSON.stringify(row[field]).replace(/"/g, '""')}"`
  //           } else {
  //             return `"${row[field] || ''}"`
  //           }
  //         })
  //         .join(',')
  //       csvContent.push(rowData)
  //     })

  //     const csvBlob = new Blob([csvContent.join('\n')], {type: 'text/csv;charset=utf-8'})
  //     saveAs(csvBlob, 'tests.csv')
  //   } else {
  //     console.log('No data to export')
  //   }
  // }

  const stripHtmlTags = (html) => {
    const tmp = document.createElement('DIV')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
  }

  const exportData = () => {
    if (filteredItems.length > 0) {
      const excludedFields = ['id', 'updated_at', 'created_at', 'notes', 'time_interval', 'files']
      let fields = Object.keys(filteredItems[0]).filter((field) => !excludedFields.includes(field))

      const additionalFields = ['timeframe', 'next_review_date']
      additionalFields.forEach((field) => {
        if (!fields.includes(field)) {
          fields.push(field)
        }
      })

      const csvContent = [fields.join(',')]

      filteredItems.forEach((row) => {
        const rowData = fields
          .map((field) => {
            let value = row[field] || row[field.toLowerCase()] || ''

            if (field === 'description') {
              value = stripHtmlTags(value)
            }

            if (field === 'next_review_date' && value) {
              value = value.split('T')[0]
            }

            if (Array.isArray(value)) {
              return `"${value.join('; ')}"`
            } else if (typeof value === 'object' && value !== null) {
              return `"${JSON.stringify(value).replace(/"/g, '""')}"`
            } else {
              return `"${value.toString().replace(/"/g, '""').replace(/\n/g, ' ')}"`
            }
          })
          .join(',')
        csvContent.push(rowData)
      })

      const csvBlob = new Blob([csvContent.join('\n')], {type: 'text/csv;charset=utf-8'})
      saveAs(csvBlob, 'tests.csv')
    } else {
      console.log('No data to export')
    }
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <TestListFilter /> */}

      {/* begin::Export */}
      {/* <button type='button' className='btn me-3' onClick={exportData} style={{backgroundColor: '#f1fdf9', color: '#45dbaf'}}>
        <KTIcon iconName='exit-up' className='fs-2 text-primary' />
        Export CSV
      </button> */}

      {/* <AddTestsModal /> */}

      {/* <button
        type='button'
        className=' btn btn-sm btn-light me-4'
        //  onClick={exportData}
        style={{height: '40px', background: '#fff', color: '#000', border: '2px solid #C5C5C5'}}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-alarm mb-1'
          style={{marginRight: '4px'}}
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M12 13m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0' />
          <path d='M12 10l0 3l2 0' />
          <path d='M7 4l-2.75 2' />
          <path d='M17 4l2.75 2' />
        </svg>
        SLA Settings
      </button> */}
      {/* <button
        type='button'
        onClick={exportData}
        className='btn btn-sm fs-6'
        style={{height: '40px', background: '#1CBB8D', color: '#fff'}}
        onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
          (e.currentTarget.style.backgroundColor = '#148363')
        }
        onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
          (e.currentTarget.style.backgroundColor = '#1CBB8D')
        }
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-download mb-1'
          style={{marginRight: '4px'}}
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
          <path d='M7 11l5 5l5 -5' />
          <path d='M12 4l0 12' />
        </svg>
        Export
      </button> */}
      <button
        type='button'
        className=' btn btn-sm btn-light me-4 fs-6'
        onClick={exportData}
        style={{height: '40px', background: '#fff', color: '#000', border: '2px solid #C5C5C5'}}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='#5A5A5A'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-download mb-1'
          style={{marginRight: '4px'}}
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
          <path d='M7 11l5 5l5 -5' />
          <path d='M12 4l0 12' />
        </svg>
        Export
      </button>
    </div>
  )
}

export {TestListToolbar}
