import axios from "axios";
import { Octokit } from "@octokit/core";

const createGitHubIntegration = ({ apiKey, orgName }) => {
  const repoName = 'swan';
    const axiosInstance = axios.create({
        baseURL: 'https://api.github.com',
        headers: {
            'Authorization': `token ${apiKey}`,
            'Accept': 'application/vnd.github.v3+json'
        }
    });

    const octokit = new Octokit({ 
        auth: apiKey
    });

    const checkMFAEnforcement = async () => {
        try {
            const response = await octokit.request(`GET /orgs/${orgName}`);
            const { mfa_required } = response.data;
            console.log(`MFA enforcement status: ${mfa_required ? 'Enforced' : 'Not Enforced'}`);
        } catch (error) {
            console.error('Error checking MFA enforcement:', error.message);
        }
    };

    const checkUserMFA = async () => {
        try {
            const response = await octokit.request(`GET /orgs/${orgName}/members`);
            const users = response.data;
            for (const user of users) {
                const userResponse = await axiosInstance.get(`/users/${user.login}`);
                const { two_factor_authentication } = userResponse.data;
                if (two_factor_authentication) {
                    console.log(`User ${user.login} has MFA enabled.`);
                } else {
                    console.log(`User ${user.login} does not have MFA enabled.`);
                }
            }
        } catch (error) {
            console.error('Error checking user MFA:', error.message);
        }
    };

    const checkDependabot = async () => {
        try {
            const response = await octokit.request('GET /orgs/swan-int/dependabot/secrets');
            const { total_count } = response.data;
            console.log(`Dependabot vulnerability scan status: ${total_count > 0 ? 'Enabled' : 'Not Enabled'}`);
        } catch (error) {
            console.error('Error checking Dependabot vulnerability scan:', error.message);
        }
    };

    const checkDependabotAlerts = async () => {
        try {
            const response = await octokit.request('GET /orgs/swan-int/dependabot/alerts');
            const alerts = response.data;
            console.log('###### alerts', alerts);
            const slaDays = 7; // Replace with actual SLA
            const now = new Date();

            for (const alert of alerts) {
                const { created_at, updated_at } = alert;
                const createdDate = new Date(created_at);
                const updatedDate = new Date(updated_at);

                const diffDays = Math.ceil((now - updatedDate) / (1000 * 60 * 60 * 24));

                if (diffDays > slaDays) {
                    console.log(`Alert for ${alert.vulnerability}: Not resolved within SLA.`);
                } else {
                    console.log(`Alert for ${alert.vulnerability}: Resolved within SLA.`);
                }
            }
        } catch (error) {
            console.error('Error checking Dependabot vulnerability alerts:', error.message);
        }
    };

    const checkBranchProtection = async () => {
        try {
            const branches = await octokit.request(`GET /repos/${orgName}/${repoName}/branches`);
            for (const branch of branches.data) {
                try {
                    const protectionResponse = await axiosInstance.get(`/repos/${orgName}/${repoName}/branches/${branch.name}/protection`);
                    console.log(`Branch protection for ${branch.name}:`, protectionResponse.data);
                } catch (err) {
                    if (err.response && err.response.status === 404) {
                        console.log(`Branch protection not found for ${branch.name}`);
                    } else {
                        console.error('Error checking branch protection rules:', err.message);
                    }
                }
            }
        } catch (error) {
            console.error('Error checking branches:', error.message);
        }
    };

    const checkCodeReviewRequirements = async () => {
        try {
            const branches = await octokit.request(`GET /repos/${orgName}/${repoName}/branches`);
            for (const branch of branches.data) {
                try {
                    const protectionResponse = await axiosInstance.get(`/repos/${orgName}/${repoName}/branches/${branch.name}/protection`);
                    const { required_pull_request_reviews } = protectionResponse.data;
                    console.log(`Code review requirements for ${branch.name}:`, required_pull_request_reviews);
                } catch (err) {
                    if (err.response && err.response.status === 404) {
                        console.log(`Branch protection not found for ${branch.name}`);
                    } else {
                        console.error('Error checking code review requirements:', err.message);
                    }
                }
            }
        } catch (error) {
            console.error('Error checking branches:', error.message);
        }
    };

    const checkOffboardedUserAccess = async () => {
        try {
            const response = await octokit.request(`GET /orgs/${orgName}/members`);
            const users = response.data;
            for (const user of users) {
                if (user.offboarded) {
                    await axiosInstance.delete(`/orgs/${orgName}/members/${user.login}`);
                    console.log(`Removed GitHub access for offboarded user ${user.login}.`);
                }
            }
        } catch (error) {
            console.error('Error removing access for offboarded users:', error.message);
        }
    };

    const runChecks = async () => {
        await checkMFAEnforcement();
        await checkUserMFA();
        await checkDependabot();
        await checkDependabotAlerts();
        await checkBranchProtection();
        await checkCodeReviewRequirements();
        await checkOffboardedUserAccess();
    };

    const getUserOrganizations = async () => {
        const octokitInstance = new Octokit({ 
            auth: apiKey
        });
        console.log('###### getUserOrganizations ######', apiKey);
        try {
            const response = await octokitInstance.request(`GET /orgs/${orgName}/repos`, {
                headers: {
                    'Authorization': `token ${apiKey}`,
                }
            });
            console.log('##### response', response);
            await runChecks();
            return response;
        } catch (error) {
            console.error('Error fetching organizations:', error);
            throw error;
        }
    };

    return {
        runChecks,
        getUserOrganizations,
    };
};

export default createGitHubIntegration;
