/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {collection, deleteDoc, doc, getDoc, onSnapshot, updateDoc} from 'firebase/firestore'
import db from '../../../../services/firebase'
import {useAuth} from '../../../modules/auth'
import AddTask from './submodal/AddTask'
import Swal from 'sweetalert2'
import AddFramework from './submodal/AddFramework'
import AddAutomated from './submodal/AddAutomated'
import AddEvidence from './submodal/AddEvidence'
import AddRiskScenario from './submodal/AddRiskScenario'

const DetailControl = () => {
  var {id} = useParams()
  const [controls, setControls] = useState([] as any[])
  const [personnels, setPersonnels] = useState<any[]>([])
  const {currentUser} = useAuth()
  const [specificTests, setSpecificTests] = useState<any[]>([])
  const [tests, setTests] = useState<any[]>([])
  const [policies, setPolicies] = useState([] as any[])
  const [specificdocuments, setSpecificdocuments] = useState<any[]>([])
  const [newOwner, setNewOwner] = useState('')
  const [activeTab, setActiveTab] = useState('overview')
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [noteInput, setNoteInput] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [tasks, setTasks] = useState<any[]>([])
  const [editingNoteIndex, setEditingNoteIndex] = useState(null)
  const [editedNoteContent, setEditedNoteContent] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [showAutomated, setShowAutomated] = useState(false)
  const [showEvidence, setShowEvidence] = useState(false)
  const [isModalRisk, setIsModalRisk] = useState(false)
  const navigate = useNavigate()

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    const testIdss = controls.filter((doc) => doc.id === id).flatMap((item) => item.test_id)

    const testControl = tests.filter((item) => testIdss.includes(item.id))

    setSpecificTests(testControl)
  }, [controls, id, tests])
  specificTests.forEach((test) => {
    console.log(test.name)
  })

  useEffect(() => {
    getActivities()
  }, [])

  const getActivities = async () => {
    try {
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)
      const controlsCollectionRef = collection(tenantRef, 'tasklists')

      const unsubscribe = onSnapshot(controlsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setTasks(controlsData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  useEffect(() => {
    const updateDocument = async () => {
      if (!currentUser) return
      if (id) {
        const tenantId = currentUser.tenantId || ''
        const controlDocRef = doc(db, `tenants/${tenantId}/controls/${id}`)
        const controlDocSnapshot = await getDoc(controlDocRef)

        if (controlDocSnapshot.exists()) {
          const controlData = controlDocSnapshot.data()
          const documentIds = controlData.document_id || []
          let passCount = 0
          const totalCount = documentIds.length

          console.log(totalCount)
          for (const testId of documentIds) {
            try {
              const testDocRef = doc(db, `tenants/${tenantId}/tests/${testId}`)
              const testDocSnapshot = await getDoc(testDocRef)

              if (testDocSnapshot.exists()) {
                const testData = testDocSnapshot.data()
                if (testData.status === 'Pass') {
                  passCount++
                }
              }
            } catch (error) {
              console.error('Error fetching test document: ', error)
            }
          }

          // Update the control_test field in the control document
          const updatedControlDocument = `${passCount}/${totalCount}`
          console.log(updatedControlDocument)
          try {
            await updateDoc(controlDocRef, {
              control_document: updatedControlDocument,
            })
            console.log('Control document updated successfully')
          } catch (error) {
            console.error('Error updating control document: ', error)
          }
        } else {
          console.log('Control document does not exist')
        }
      }
    }

    updateDocument()
  }, [specificdocuments, currentUser, id])

  useEffect(() => {
    const updateTest = async () => {
      if (!currentUser) return
      if (id) {
        const tenantId = currentUser.tenantId || ''
        const controlDocRef = doc(db, `tenants/${tenantId}/controls/${id}`)
        const controlDocSnapshot = await getDoc(controlDocRef)

        if (controlDocSnapshot.exists()) {
          const controlData = controlDocSnapshot.data()
          const testIds = controlData.test_id || []
          let passCount = 0
          const totalCount = testIds.length

          for (const testId of testIds) {
            try {
              const testDocRef = doc(db, `tenants/${tenantId}/tests/${testId}`)
              const testDocSnapshot = await getDoc(testDocRef)

              if (testDocSnapshot.exists()) {
                const testData = testDocSnapshot.data()
                if (testData.status === 'Pass') {
                  passCount++
                }
              }
            } catch (error) {
              console.error('Error fetching test document: ', error)
            }
          }

          // Update the control_test field in the control document
          const updatedControlTest = `${passCount}/${totalCount}`
          try {
            await updateDoc(controlDocRef, {
              control_test: updatedControlTest,
            })
            console.log('Control test updated successfully')
          } catch (error) {
            console.error('Error updating control document: ', error)
          }
        } else {
          console.log('Control document does not exist')
        }
      }
    }

    updateTest()
  }, [specificTests, currentUser, id])

  useEffect(() => {
    const updateStatus = async () => {
      if (!currentUser) return

      const tenantId = currentUser.tenantId || ''

      try {
        // Fetch the current document data
        const testDocRef = doc(db, `tenants/${tenantId}/controls/${id}`)
        const testDocSnapshot = await getDoc(testDocRef)

        if (testDocSnapshot.exists()) {
          const data = testDocSnapshot.data()
          let controlTest = data.control_test || '0/0' // Default value if control_test is not set

          // Extract the current control_test values
          const [currentCount, totalCount] = controlTest.split('/').map(Number)

          let controlDocument = data.control_document || '0/0' // Default value if control_document is not set

          // Extract the current control_document values
          const [currentCount1, totalCount1] = controlDocument.split('/').map(Number)

          // Update control_evidence_status to 'OK' if both conditions are met
          if (currentCount === totalCount && currentCount1 === totalCount1) {
            await updateDoc(testDocRef, {
              control_evidence_status: 'Ok',
            })
            console.log('Control evidence status updated to OK')
          } else {
            console.log('Control test and/or control document are not at their maximum counts')
          }
        } else {
          console.log('Document does not exist')
        }
      } catch (error) {
        console.error('Error updating control test: ', error)
      }
    }

    updateStatus()
  }, [specificTests, specificdocuments, currentUser, id])

  useEffect(() => {
    const docIdss = controls.filter((doc) => doc.id === id).flatMap((item) => item.document_id)

    const docControl = tests.filter((item) => docIdss.includes(item.id))

    setSpecificdocuments(docControl)
  }, [controls, id, tests])
  specificdocuments.forEach((test) => {
    console.log(test.name)
  })

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      // Logout()
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setPersonnels(risksData)
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    getControl()
  }, [])

  useEffect(() => {
    try {
      const unsubscribe = onSnapshot(collection(db, 'PolicyManagement'), (snapshot) => {
        const testsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          policy: doc.data().policy,
        }))
        console.log(testsData)
        const filteredEvidences = testsData.filter((policyData) =>
          specificTests.filter(
            (test) => test.policies === policyData.policy && test.tenantId === currentUser?.tenantId
          )
        )
        setPolicies(filteredEvidences)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }, [])
  const handleTabClick = (tab) => {
    setActiveTab(tab)
    // Additional logic if needed when a tab is clicked
  }

  const getControl = async () => {
    try {
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)
      const controlsCollectionRef = collection(tenantRef, 'controls')

      const unsubscribe = onSnapshot(controlsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setControls(controlsData)
      })

      const testsCollectionRef = collection(tenantRef, 'tests')

      const unsubscribee = onSnapshot(testsCollectionRef, (snapshot) => {
        const testsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setTests(testsData)
      })

      return [unsubscribe, unsubscribee]
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  function processControlValues(inputString: string, inputString2: string): string {
    // Concatenate the input strings with a '+' separator
    const concatenatedString = inputString + '+' + inputString2
    console.log(concatenatedString)

    // Split the concatenated string by '+' to separate the two parts
    const parts = concatenatedString.split('+')

    let numerator = 0
    let denominator = 0

    // Loop through each part
    parts.forEach((part) => {
      // Split the part by '/' to separate the numerator and the denominator
      const splitPart = part.split('/')
      // Convert the numerator and the denominator to integers
      const num = parseInt(splitPart[0], 10)
      const den = parseInt(splitPart[1], 10)

      // Check if the conversion was successful
      if (isNaN(num) || isNaN(den)) {
        console.error('Invalid input format')
        return
      }

      // Add the numerator and denominator to the total
      numerator += num
      denominator += den
    })

    if (denominator === 0) {
      return '0/0'
    }

    // Return the result as a string in the format "numerator/denominator"
    const processedValue = `${numerator}/${denominator}`
    console.log(processedValue)
    return processedValue
  }

  const getBadgeClass = (testPassing) => {
    const [numerator, denominator] = testPassing.split('/').map(Number)
    console.log(testPassing)
    if (numerator === denominator && numerator !== 0) {
      return 'bg-primary' // All tests passed
    } else {
      return 'bg-danger' // Some tests failed
    }
  }

  const getStatusMessage = (testPassing) => {
    const [numerator, denominator] = testPassing.split('/').map(Number)
    if (testPassing === '0/0' && numerator === '0') {
      return 'Evidence Missing'
    } else if (numerator === denominator) {
      return 'OK'
    } else {
      return 'Unhealthy'
    }
  }

  const handleOwner = async (event) => {
    const selectedOwner = event.target.value
    console.log(selectedOwner)
    setNewOwner(selectedOwner)

    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/controls/${id}`)
      await updateDoc(testDocRef, {
        control_owner: selectedOwner,
      })
      setNewOwner('')
      console.log('Owner assigned successfully')
    } catch (error) {
      console.error('Error assigning owner: ', error)
    }
  }

  const toggleDropdown = () => {
    // console.log('clicked')
    setDropdownVisible(!isDropdownVisible)
  }

  const handleNotes = async (event: React.FormEvent) => {
    event.preventDefault() // Prevent default form submission behavior

    if (!noteInput.trim()) {
      console.log('Note is empty')
      return
    }

    const currentDate = new Date()

    const day = currentDate.getDate()
    const month = currentDate.toLocaleString('default', {month: 'long'})
    const year = currentDate.getFullYear()
    const formattedDate = `${day} ${month}, ${year}`

    let hours = currentDate.getHours()
    let minutes = currentDate.getMinutes()
    let ampm = hours >= 12 ? 'PM' : 'AM'

    // Round minutes to nearest 12
    minutes = Math.round(minutes / 12) * 12
    if (minutes === 60) {
      minutes = 0
      hours += 1
    }

    hours = hours % 12
    hours = hours ? hours : 12

    const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`
    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/controls/${id}`)

      // Get the current notes array
      const docSnap = await getDoc(testDocRef)
      const currentNotes = docSnap.data()?.notes || []

      // Create a new note object
      const newNoteObject = {
        addedUser: currentUser?.userName || 'Unknown User',
        addedDate: formattedDate,
        addedTime: formattedTime,
        note: noteInput.trim(),
      }

      // Add the new note to the array
      const updatedNotes = [...currentNotes, newNoteObject]

      // Update the document
      await updateDoc(testDocRef, {
        notes: updatedNotes,
      })

      setNoteInput('')
      // setNewnotes(updatedNotes)

      console.log('Note added successfully')
    } catch (error) {
      console.error('Error adding note: ', error)
    }
  }

  const handleRemoveTask = async (taskId) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: 'Remove Selected Items',
        text: 'Are you sure you want to remove the selected items? This action cannot be undone.',
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })
      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const taskRef = doc(db, 'tenants', tenantId, 'tasklists', taskId)
        await deleteDoc(taskRef)
        getActivities() // Refresh the tasks list
      }
      Swal.fire({
        icon: 'success',
        title: 'Deleted!',
        text: 'Selected items have been removed.',
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      })
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while removing the selected items.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  const handleEditNote = (index, currentContent) => {
    setEditingNoteIndex(index)
    setEditedNoteContent(currentContent)
  }

  const handleDeleteNote = async (index) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/controls/${id}`)

      const docSnap = await getDoc(testDocRef)
      const currentNotes = docSnap.data()?.notes || []

      const updatedNotes = currentNotes.filter((_, i) => i !== index)

      await updateDoc(testDocRef, {
        notes: updatedNotes,
      })

      // setNotes(updatedNotes)
    } catch (error) {
      console.error('Error deleting note: ', error)
    }
  }

  const handleSaveEditedNote = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/controls/${id}`)

      const docSnap = await getDoc(testDocRef)
      const currentNotes = docSnap.data()?.notes || []

      const updatedNotes = currentNotes.map((note, index) =>
        index === editingNoteIndex ? {...note, note: editedNoteContent} : note
      )

      await updateDoc(testDocRef, {
        notes: updatedNotes,
      })

      setEditingNoteIndex(null)
      setEditedNoteContent('')
    } catch (error) {
      console.error('Error editing note: ', error)
    }
  }

  const handleboard = () => {
    setShowModal(true)
  }

  const handleCloseboard = () => {
    setShowModal(false)
  }

  const handleAutomated = () => {
    setShowAutomated(true)
  }

  const handleCloseAutomated = () => {
    setShowAutomated(false)
  }

  const handleEvidence = () => {
    setShowEvidence(true)
  }

  const handleCloseEvidence = () => {
    setShowEvidence(false)
  }

  const handleDeleteTest = async (index) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/controls/${id}`)

      const docSnap = await getDoc(testDocRef)
      const currentdocument = docSnap.data()?.document_id || []

      const updatedRisks = currentdocument.filter((_, i) => i !== index)

      await updateDoc(testDocRef, {
        document_id: updatedRisks,
      })

      // setNotes(updatedNotes)
    } catch (error) {
      console.error('Error deleting note: ', error)
    }
  }

  const handleDeletePlatform = async (index) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/controls/${id}`)

      const docSnap = await getDoc(testDocRef)
      const currentdocument = docSnap.data()?.test_id || []

      const updatedRisks = currentdocument.filter((_, i) => i !== index)

      await updateDoc(testDocRef, {
        test_id: updatedRisks,
      })

      // setNotes(updatedNotes)
    } catch (error) {
      console.error('Error deleting note: ', error)
    }
  }

  const handleOpenRisk = () => {
    setIsModalRisk(true)
  }

  const handleCloseRisk = () => {
    setIsModalRisk(false)
  }

  const handleRiskClick = (riskId) => {
    navigate(`/risk-management?id=${riskId}`)
  }

  const handleDeleteControl = async (risk) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${risk.riskSource}`,
        text: `Are you sure you want to remove ${risk.riskSource} control from risk scenario? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const riskRef = doc(tenantRef, 'risks', risk.riskId)
        const controlRef = doc(tenantRef, 'controls', id || '')

        // 1. Update the risk document
        const riskDoc = await getDoc(riskRef)
        if (riskDoc.exists()) {
          const riskData = riskDoc.data()
          const updatedAddedControls = riskData.added_controls.filter(
            (control) => control.id !== id
          )
          await updateDoc(riskRef, {
            added_controls: updatedAddedControls,
          })
        }

        // 2. Update the control document
        const controlDoc = await getDoc(controlRef)
        if (controlDoc.exists()) {
          const controlData = controlDoc.data()
          const updatedRiskScenarios = controlData.risk_scenario.filter(
            (scenario) => scenario.riskId !== risk.riskId
          )
          await updateDoc(controlRef, {
            risk_scenario: updatedRiskScenarios,
          })
        }

        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: `"${risk.riskSource}" has been removed from the risk scenario.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
        getControl()
        // setControls((prevControls) => {
        //   const updatedControl = {...prevControls[0]}
        //   updatedControl.risk_scenario = updatedControl.risk_scenario.filter(
        //     (scenario) => scenario.riskId !== risk.riskId
        //   )
        //   return [updatedControl]
        // })
      }
    } catch (error) {
      console.error('Error removing risk from control:', error)
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while removing the risk from the control.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  return (
    <>
      <AddFramework id={id || ''} isOpen={showModal} onClose={handleCloseboard} />
      <AddAutomated id={id || ''} isOpen={showAutomated} onClose={handleCloseAutomated} />
      <AddEvidence id={id || ''} isOpen={showEvidence} onClose={handleCloseEvidence} />
      <AddRiskScenario isOpen={isModalRisk} onCancel={handleCloseRisk} controlId={id} />
      {controls.length > 0 ? (
        controls
          .filter((control) => control.id === id)
          .map((control) => (
            <>
              <div className='card px-6 pt-6 mb-4'>
                {/* <p className='text-grey-600 fw-bold fs-6 mb-0'>{control.control_id || ''}</p> */}
                <div className='d-flex justify-content-between align-items-center mb-2'>
                  <h3 className='mb-0'>{control.control_title}</h3>

                  {/* begin::Menu */}
                  {/* <button
                    className='btn btn-sm btn-success fs-7'
                    style={{backgroundColor: '#1CBB8D'}}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-download mx-1 mb-1'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
                      <path d='M7 11l5 5l5 -5' />
                      <path d='M12 4l0 12' />
                    </svg>
                    Export
                  </button> */}
                </div>

                <div>
                  <span className='fw-normal fw-bold fs-6'>
                    {/* <span className='badge border p-2 rounded-pill mb-2'> */}
                    {control.control_framework_mappings &&
                    typeof control.control_framework_mappings === 'string' ? (
                      <span
                        className='badge px-3 rounded-pill me-2'
                        style={{border: '2px solid #E1E1E1'}}
                      >
                        {control.control_framework_mappings.split(',')[0].trim()}{' '}
                      </span>
                    ) : (
                      ''
                    )}
                    {/* </span> */}

                    {control.control_framework_mappings &&
                      typeof control.control_framework_mappings === 'string' && (
                        <>
                          {control.control_framework_mappings
                            .split(',')
                            .slice(1)
                            .map((framework, index) => (
                              <span
                                key={index}
                                className='badge px-3  rounded-pill me-2'
                                style={{border: '2px solid #E1E1E1'}}
                              >
                                {framework.trim()}
                              </span>
                            ))}
                        </>
                      )}
                  </span>
                  <span className='fw-normal fs-6 mx-2'>
                    {control.control_evidence_status === 'Deactivate' ? (
                      <a
                        href='#'
                        className='badge fs-7 text-dark text-bold m-1'
                        style={{background: '#F6F6F6'}}
                      >
                        {control.control_evidence_status}
                      </a>
                    ) : (
                      <a
                        className={`badge fs-7 ${
                          control.control_evidence_status === 'Ok' ||
                          control.control_evidence_status === 'OK'
                            ? 'badge-primary'
                            : 'badge-danger'
                        }`}
                        style={{
                          color:
                            control.control_evidence_status === 'Ok' ||
                            control.control_evidence_status === 'OK'
                              ? '#1CBB8D'
                              : '#EE3043',
                          background:
                            control.control_evidence_status === 'Ok' ||
                            control.control_evidence_status === 'OK'
                              ? '#E8F8F4'
                              : '#FFF5F8',
                        }}
                        href='#'
                      >
                        {control.control_evidence_status === 'Ok' ||
                        control.control_evidence_status === 'OK'
                          ? 'OK'
                          : 'Unhealthy'}
                      </a>
                    )}{' '}
                    <span className='mx-1'> </span>
                    <span className='fs-6 text-grey-800 fw-semibold'>
                      {' '}
                      {processControlValues(control.control_document, control.control_test)} Test
                      Passing
                    </span>
                  </span>
                </div>

                <div className='px-2  mt-4 '>
                  <div className='d-flex  h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap '>
                      <li className='nav-item'>
                        <Link
                          to={`/controls/details/${id}`}
                          className={`nav-link me-6 ${
                            activeTab === 'overview' ? 'text-active-primary active' : ''
                          }`}
                          onClick={() => handleTabClick('overview')}
                        >
                          Tests
                        </Link>
                      </li>

                      <li className='nav-item'>
                        <Link
                          to={`/controls/details/${id}`}
                          className={`nav-link me-6 ${
                            activeTab === 'risks' ? 'text-active-primary active' : ''
                          }`}
                          onClick={() => handleTabClick('risks')}
                        >
                          Risks
                        </Link>
                      </li>

                      <li className='nav-item'>
                        <Link
                          to={`/controls/details/${id}`}
                          className={`nav-link me-6 ${
                            activeTab === 'notes' ? 'text-active-primary active' : ''
                          }`}
                          onClick={() => handleTabClick('notes')}
                        >
                          Notes
                        </Link>
                      </li>

                      {/* <li className='nav-item'>
                        <Link
                          to={`/controls/details/${id}`}
                          className={`nav-link me-6 ${
                            activeTab === 'tasks' ? 'text-active-primary active' : ''
                          }`}
                          onClick={() => handleTabClick('tasks')}
                        >
                          Tasks
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              {activeTab === 'overview' && (
                <>
                  {/* <div className='separator my-4'></div> */}
                  <div className='card mb-5 mb-xl-8 p-6 mt-8'>
                    {/* <div className='text-grey-800 fw-semibold fs-6 mb-6'>
                      {control.control_description}
                    </div> */}
                    <div
                      className='fv-row mb-6 text-gray-800 fs-6'
                      dangerouslySetInnerHTML={{__html: control.control_description}}
                    />

                    <div className='row g-5 g-xxl-6'>
                      <div className='col-md-6'>
                        <h4 className=' fw-bold fs-6' style={{color: '#5E6274'}}>
                          AUTHOR
                        </h4>
                        <p className='text-grey-800 fw-semibold'>
                          {control.author ? control.author : 'SecureSlate'}
                        </p>

                        <h4 className=' fw-bold fs-6' style={{color: '#5E6274'}}>
                          STATUS
                        </h4>
                        <p className='text-grey-800 fw-semibold'>
                          {' '}
                          <span className='fw-normal fs-6 '>
                            <span className='fw-semibold'>
                              <a
                                className={`badge fs-7 ${
                                  control.control_evidence_status === 'Ok' ||
                                  control.control_evidence_status === 'OK'
                                    ? 'badge-primary'
                                    : 'badge-danger'
                                }`}
                                style={{
                                  color:
                                    control.control_evidence_status === 'Ok' ||
                                    control.control_evidence_status === 'OK'
                                      ? '#1CBB8D'
                                      : '#EE3043',
                                  background:
                                    control.control_evidence_status === 'Ok' ||
                                    control.control_evidence_status === 'OK'
                                      ? '#E8F8F4'
                                      : '#FFF5F8',
                                }}
                                href='#'
                              >
                                {control.control_evidence_status === 'Ok' ||
                                control.control_evidence_status === 'OK'
                                  ? 'OK'
                                  : 'Unhealthy'}
                              </a>
                            </span>{' '}
                            <span className='mx-1'> . </span>
                            <span className='fs-6 text-grey-800 fw-semibold'>
                              {' '}
                              {processControlValues(
                                control.control_document,
                                control.control_test
                              )}{' '}
                              Test Passing
                            </span>
                          </span>
                        </p>
                      </div>
                      <div className='col-md-6'>
                        <h4 className=' fw-bold fs-6' style={{color: '#5E6274'}}>
                          FRAMEWORKS MAPPED
                        </h4>

                        <p className='text-grey-800 fw-semibold'>
                          {control.control_framework_mappings &&
                          typeof control.control_framework_mappings === 'string' ? (
                            <span
                              className='badge px-3 rounded-pill me-2'
                              style={{border: '2px solid #E1E1E1'}}
                            >
                              {control.control_framework_mappings.split(',')[0].trim()}{' '}
                            </span>
                          ) : (
                            <>--</>
                          )}

                          {control.control_framework_mappings &&
                            typeof control.control_framework_mappings === 'string' && (
                              <>
                                {control.control_framework_mappings
                                  .split(',')
                                  .slice(1)
                                  .map((framework, index) => (
                                    <span
                                      key={index}
                                      className='badge px-3 rounded-pill me-2'
                                      style={{border: '2px solid #E1E1E1'}}
                                    >
                                      {framework.trim()}
                                    </span>
                                  ))}
                              </>
                            )}
                          {control.author === 'Custom Control' && (
                            <button
                              className='btn btn-sm fs-6 p-0'
                              style={{color: '#1CBB8D'}}
                              onClick={handleboard}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-edit me-1 mb-1'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1' />
                                <path d='M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z' />
                                <path d='M16 5l3 3' />
                              </svg>
                              edit
                            </button>
                          )}
                        </p>

                        <h4 className=' fw-bold fs-6' style={{color: '#5E6274'}}>
                          OWNER
                        </h4>
                        <p className='text-grey-800 fw-semibold'>
                          <span className='fw-normal'>
                            {' '}
                            <select
                              name='group'
                              className='form-select w-40 py-2 px-4'
                              data-control='select2'
                              style={{width: '200px'}}
                              data-placeholder='Select Group'
                              value={newOwner || control.control_owner}
                              onChange={handleOwner}
                            >
                              <option value='' defaultChecked>
                                Assign Owner
                              </option>
                              {personnels
                                .filter((user) => user.role === 'Admin')
                                .map((group) => (
                                  <option key={group.id} value={group.full_name}>
                                    {group.full_name}
                                  </option>
                                ))}
                            </select>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  {control.control_test === '0/0' ? (
                    <>
                      <div className='card mb-5 mb-xl-8'>
                        {/* <div className='border-bottom p-6 d-flex align-items-center'>
                          <h3 className='mb-0 me-2'>Platform</h3>
                          <span>(0/0 Passing)</span>
                        </div> */}
                        <div className='border-bottom p-6 d-flex justify-content-between align-items-center fw-bold fs-6 mb-6'>
                          <div className='d-flex align-items-center'>
                            <h4 className='mb-0 me-2'>Platform</h4>
                            <span className='text-gray-800 fw-semibold fs-8'>
                              {/* (
                              {control.control_test === '0/0'
                                ? ' N/A '
                                : `${control.control_test} OK`}
                              ) */}
                              (0/0 Passing)
                            </span>
                          </div>

                          {control.author === 'Custom Control' && (
                            <button
                              className='btn btn-sm fs-6'
                              onClick={handleAutomated}
                              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#148363')
                              }
                              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#1CBB8D')
                              }
                              // disabled={activities.length === 0}
                              style={{
                                backgroundColor: '#1CBB8D',
                                color: '#fff',
                                width: '90px',
                                height: '40px',
                                borderRadius: '4px',

                                transition: 'background-color 0.3s',
                              }}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-plus mx-1'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M12 5l0 14' />
                                <path d='M5 12l14 0' />
                              </svg>
                              Add
                            </button>
                          )}
                        </div>
                        <div className='d-flex align-items-center justify-content-center w-100 py-10 fw-bold'>
                          No tests required for this control
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={`card mb-5 mb-xl-8`}>
                        <div className='border-bottom p-6 d-flex justify-content-between align-items-center fw-bold fs-6'>
                          <div className='d-flex align-items-center'>
                            <h4 className='mb-0 me-2'>Platform</h4>
                            <span className='fw-semibold fs-7'>
                              {' '}
                              (
                              {control.control_test === '0/0'
                                ? ' N/A '
                                : `${control.control_test} OK`}{' '}
                              )
                            </span>
                          </div>
                          {control.author === 'Custom Control' && (
                            <button
                              className='btn btn-sm fs-6'
                              onClick={handleAutomated}
                              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#148363')
                              }
                              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#1CBB8D')
                              }
                              // disabled={activities.length === 0}
                              style={{
                                backgroundColor: '#1CBB8D',
                                color: '#fff',
                                width: '90px',
                                height: '40px',
                                borderRadius: '4px',

                                transition: 'background-color 0.3s',
                              }}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-plus mx-1'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M12 5l0 14' />
                                <path d='M5 12l14 0' />
                              </svg>
                              Add
                            </button>
                          )}
                        </div>
                        {specificTests.map((test, index) => (
                          <div key={index}>
                            <div className='d-flex align-items-center p-6'>
                              <div className='d-flex justify-content-start flex-column'>
                                <a
                                  href={`https://app.getsecureslate.com/tests?id=${test.id}`}
                                  target='_blank'
                                  rel='noreferrer'
                                  style={{textDecoration: 'none', color: 'inherit'}}
                                >
                                  <span className='card-label fw-bold fs-6'>{test.name}</span>
                                </a>
                                <span className='text-gray-800 fw-semibold fs-8 d-none d-md-block my-2'>
                                  Owner:{' '}
                                  <div className='symbol symbol-20px me-1 rounded-circle'>
                                    {test.owner ? (
                                      <div className='symbol symbol-25px'>
                                        <span
                                          className='symbol-label fs-7 fw-bold'
                                          style={{backgroundColor: '#DAF9EF'}}
                                        >
                                          {test.owner
                                            ? (() => {
                                                const nameParts = test.owner.split(' ')
                                                if (nameParts.length > 1) {
                                                  return `${nameParts[0]
                                                    .charAt(0)
                                                    .toUpperCase()}${nameParts[1]
                                                    .charAt(0)
                                                    .toUpperCase()}`
                                                } else {
                                                  return `${nameParts[0]
                                                    .charAt(0)
                                                    .toUpperCase()}${nameParts[0]
                                                    .charAt(1)
                                                    .toUpperCase()}`
                                                }
                                              })()
                                            : ''}
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                  <span className='fw-semibold mx-1'>
                                    {test.owner ? test.owner : 'Not assigned'} |{' '}
                                  </span>
                                  {/* )} */}
                                  {/* <span className='badge border p-2 rounded-pill '>
                                    <span
                                      className={`badge border rounded-circle mx-1 ${
                                        policies.length > 0 &&
                                        policies
                                          .filter((data) => data.tenantId === currentUser?.tenantId)
                                          .some((policy) => policy.policy === test.policies)
                                          ? 'bg-primary'
                                          : 'bg-danger'
                                      }`}
                                      style={{
                                        width: '12px',
                                        height: '12px',
                                        padding: '3px',
                                      }}
                                    >
                                      {' '}
                                    </span>
                                    {policies.length > 0 &&
                                    policies
                                      .filter((data) => data.tenantId === currentUser?.tenantId)
                                      .some((policy) => policy.policy === test.policies)
                                      ? 'Ok'
                                      : 'Failing'}
                                  </span> */}
                                  <a
                                    href='#'
                                    className='badge fs-7'
                                    style={{
                                      color: test.status === 'Pass' ? '#1CBB8D' : '#EE3043',
                                      background: test.status === 'Pass' ? '#E8F8F4' : '#FFF5F8',
                                    }}
                                  >
                                    {' '}
                                    {test.status === 'Pass' ? 'Passing' : 'Failing'}
                                  </a>
                                </span>
                              </div>

                              <div className='ms-auto'>
                                {control.author !== 'Custom Control' ? (
                                  <a
                                    href={`https://app.getsecureslate.com/tests?id=${test.id}`}
                                    target='_blank'
                                    rel='noreferrer'
                                    style={{textDecoration: 'none', color: 'inherit'}}
                                  >
                                    {/* <a href={`https://app.getsecureslate.com/tests?id=${test.id}`} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'inherit' }} > */}
                                    <span className='fw-semibold mx-2'>See test details</span>
                                    <img
                                      src={toAbsoluteUrl(`/media/buttons/Chevron Button.svg`)}
                                      alt=''
                                    />
                                  </a>
                                ) : (
                                  <div className='dropdown'>
                                    <button
                                      className='btn btn-secondary border-0 p-0 m-2'
                                      type='button'
                                      id='dropdownMenu2'
                                      data-bs-toggle='dropdown'
                                      aria-haspopup='true'
                                      aria-expanded='false'
                                    >
                                      <img
                                        src='/media/buttons/Options.svg'
                                        alt='Options'
                                        onClick={toggleDropdown}
                                      />
                                    </button>
                                    <ul
                                      className='dropdown-menu dropdown-menu-end'
                                      aria-labelledby='dropdownMenu2'
                                    >
                                      <li>
                                        <a
                                          className='dropdown-item text-muted text-hover-primary '
                                          type='button'
                                          // onClick={() => handleEditNote(index, note.note)}
                                          href={`/tests?id=${test.id}`}
                                        >
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='18'
                                            height='18'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            stroke-width='2'
                                            stroke-linecap='round'
                                            stroke-linejoin='round'
                                            className='icon icon-tabler icons-tabler-outline icon-tabler-eye mx-2 mb-1'
                                          >
                                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                            <path d='M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0' />
                                            <path d='M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6' />
                                          </svg>
                                          View
                                        </a>
                                      </li>
                                      <li>
                                        <button
                                          className='dropdown-item text-danger'
                                          type='button'
                                          onClick={() => handleDeletePlatform(index)}
                                        >
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='18'
                                            height='18'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            stroke-width='2'
                                            stroke-linecap='round'
                                            stroke-linejoin='round'
                                            className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-2 mb-1'
                                          >
                                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                            <path d='M4 7l16 0' />
                                            <path d='M10 11l0 6' />
                                            <path d='M14 11l0 6' />
                                            <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                            <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                          </svg>
                                          Delete
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className='separator'></div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {control.control_document === '0/0' ? (
                    <>
                      <div className='card mb-5 mb-xl-8'>
                        <div className='border-bottom p-6 d-flex justify-content-between align-items-center fw-bold fs-6 mb-6'>
                          <div className='d-flex align-items-center'>
                            <h4 className='mb-0 me-2'>Document</h4>
                            <span className='text-gray-800 fw-semibold fs-8'>
                              {/* (
                              {control.control_test === '0/0'
                                ? ' N/A '
                                : `${control.control_test} OK`}
                              ) */}
                              (0/0 Passing)
                            </span>
                          </div>

                          {control.author === 'Custom Control' && (
                            <button
                              className='btn btn-sm fs-6'
                              onClick={handleEvidence}
                              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#148363')
                              }
                              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#1CBB8D')
                              }
                              // disabled={activities.length === 0}
                              style={{
                                backgroundColor: '#1CBB8D',
                                color: '#fff',
                                width: '90px',
                                height: '40px',
                                borderRadius: '4px',

                                transition: 'background-color 0.3s',
                              }}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-plus mx-1'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M12 5l0 14' />
                                <path d='M5 12l14 0' />
                              </svg>
                              Add
                            </button>
                          )}
                        </div>
                        <div className='d-flex align-items-center justify-content-center w-100 py-10 fw-bold'>
                          No documents for this control
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={`card mb-5 mb-xl-8`}>
                        <div className='border-bottom p-6 d-flex justify-content-between align-items-center fw-bold fs-6'>
                          <div className='d-flex align-items-center'>
                            <h3 className='mb-0 me-2'>Document</h3>
                            <span className='fw-semibold fs-7'>
                              {' '}
                              (
                              {control.control_document === '0/0'
                                ? ' N/A '
                                : `${control.control_document} OK`}{' '}
                              )
                            </span>
                          </div>
                          {control.author === 'Custom Control' && (
                            <button
                              className='btn btn-sm fs-6'
                              onClick={handleEvidence}
                              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#148363')
                              }
                              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#1CBB8D')
                              }
                              // disabled={activities.length === 0}
                              style={{
                                backgroundColor: '#1CBB8D',
                                color: '#fff',
                                width: '90px',
                                height: '40px',
                                borderRadius: '4px',

                                transition: 'background-color 0.3s',
                              }}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-plus mx-1'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M12 5l0 14' />
                                <path d='M5 12l14 0' />
                              </svg>
                              Add
                            </button>
                          )}
                        </div>

                        {specificdocuments.map((test, index) => (
                          <React.Fragment key={index}>
                            <div className='p-6 d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                {/* <a
                              href={`https://app.gtsecureslate.com/tests?id=${test.id}`}
                              target='_blank'
                              rel='noreferrer'
                              key={index}
                              style={{textDecoration: 'none', color: 'inherit'}}
                            > */}
                                <a
                                  href={`https://app.getsecureslate.com/tests?id=${test.id}`}
                                  target='_blank'
                                  rel='noreferrer'
                                  style={{textDecoration: 'none', color: 'inherit'}}
                                >
                                  <span className='card-label fw-bold fs-6'>{test.name}</span>
                                </a>
                                <span className='text-gray-800 fw-semibold fs-8 d-none d-md-block my-2'>
                                  Owner:
                                  <span>
                                    {' '}
                                    {test.owner ? (
                                      <>
                                        {test.owner ? (
                                          <div className='symbol symbol-25px'>
                                            <span
                                              className='symbol-label fs-7 fw-bold'
                                              style={{backgroundColor: '#DAF9EF'}}
                                            >
                                              {test.owner
                                                ? (() => {
                                                    const nameParts = test.owner.split(' ')
                                                    if (nameParts.length > 1) {
                                                      return `${nameParts[0]
                                                        .charAt(0)
                                                        .toUpperCase()}${nameParts[1]
                                                        .charAt(0)
                                                        .toUpperCase()}`
                                                    } else {
                                                      return `${nameParts[0]
                                                        .charAt(0)
                                                        .toUpperCase()}${nameParts[0]
                                                        .charAt(1)
                                                        .toUpperCase()}`
                                                    }
                                                  })()
                                                : ''}
                                            </span>
                                          </div>
                                        ) : null}
                                        <span> {test.owner ? test.owner : 'Not assigned'} </span>
                                      </>
                                    ) : (
                                      'Not assigned'
                                    )}{' '}
                                  </span>
                                  |{' '}
                                  {/* <span className='badge border p-2 rounded-pill '>
                                    <span
                                      className={`badge border rounded-circle mx-1 ${
                                        test.status === 'Pass' ? 'bg-primary' : 'bg-danger'
                                      }`}
                                      style={{
                                        width: '12px',
                                        height: '12px',
                                        padding: '3px',
                                      }}
                                    >
                                      {' '}
                                    </span>
                                    <span className='text-center'>
                                      {test.status === 'Pass' ? 'OK' : 'Failing'}
                                    </span>
                                  </span> */}
                                  <a
                                    href='#'
                                    className='badge fs-7'
                                    style={{
                                      color: test.status === 'Pass' ? '#1CBB8D' : '#EE3043',
                                      background: test.status === 'Pass' ? '#E8F8F4' : '#FFF5F8',
                                    }}
                                  >
                                    {' '}
                                    {test.status === 'Pass' ? 'Passing' : 'Failing'}
                                  </a>
                                </span>
                              </div>
                              <div className='ms-auto'>
                                {control.author !== 'Custom Control' ? (
                                  <a
                                    href={`https://app.getsecureslate.com/tests?id=${test.id}`}
                                    target='_blank'
                                    rel='noreferrer'
                                    style={{textDecoration: 'none', color: 'inherit'}}
                                  >
                                    {/* <a href={`https://app.getsecureslate.com/tests?id=${test.id}`} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'inherit' }} > */}
                                    <span className='fw-semibold mx-2'>See test details</span>
                                    <img
                                      src={toAbsoluteUrl(`/media/buttons/Chevron Button.svg`)}
                                      alt=''
                                    />
                                  </a>
                                ) : (
                                  <div className='dropdown'>
                                    <button
                                      className='btn btn-secondary border-0 p-0 m-2'
                                      type='button'
                                      id='dropdownMenu2'
                                      data-bs-toggle='dropdown'
                                      aria-haspopup='true'
                                      aria-expanded='false'
                                    >
                                      <img
                                        src='/media/buttons/Options.svg'
                                        alt='Options'
                                        onClick={toggleDropdown}
                                      />
                                    </button>
                                    <ul
                                      className='dropdown-menu dropdown-menu-end'
                                      aria-labelledby='dropdownMenu2'
                                    >
                                      <li>
                                        <a
                                          className='dropdown-item text-muted text-hover-primary '
                                          type='button'
                                          // onClick={() => handleEditNote(index, note.note)}
                                          href={`/tests?id=${test.id}`}
                                        >
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='18'
                                            height='18'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            stroke-width='2'
                                            stroke-linecap='round'
                                            stroke-linejoin='round'
                                            className='icon icon-tabler icons-tabler-outline icon-tabler-eye mx-2 mb-1'
                                          >
                                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                            <path d='M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0' />
                                            <path d='M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6' />
                                          </svg>
                                          View
                                        </a>
                                      </li>
                                      <li>
                                        <button
                                          className='dropdown-item text-danger'
                                          type='button'
                                          onClick={() => handleDeleteTest(index)}
                                        >
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='18'
                                            height='18'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            stroke-width='2'
                                            stroke-linecap='round'
                                            stroke-linejoin='round'
                                            className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-2 mb-1'
                                          >
                                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                            <path d='M4 7l16 0' />
                                            <path d='M10 11l0 6' />
                                            <path d='M14 11l0 6' />
                                            <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                            <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                          </svg>
                                          Delete
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className='separator'></div>
                          </React.Fragment>
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}

              {activeTab === 'risks' && (
                <>
                  {' '}
                  <div className='my-4 mt-8 d-flex justify-content-between'>
                    <div className=''>
                      <div className='fs-6 fw-bold mb-2'>Risk Scenarios</div>
                      <span>
                        {control?.risk_scenario ? control?.risk_scenario.length : 0} scenarios
                        mapped to this control
                      </span>
                    </div>
                    <button
                      className='btn btn-sm fs-6'
                      onClick={handleOpenRisk}
                      onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#148363')
                      }
                      onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#1CBB8D')
                      }
                      style={{
                        backgroundColor: '#1CBB8D',
                        color: '#fff',
                        width: '130px',
                        height: '40px',
                        borderRadius: '4px',
                        marginRight: '0px',
                        transition: 'background-color 0.3s',
                      }}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        className='icon icon-tabler icons-tabler-outline icon-tabler-plus me-1 mb-1'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M12 5l0 14' />
                        <path d='M5 12l14 0' />
                      </svg>
                      Add Risks
                    </button>
                  </div>
                  <div className='card mt-4'>
                    <div
                      className='table-responsive'
                      style={{minHeight: '100px', maxHeight: '500px', overflowY: 'auto'}}
                    >
                      {control?.risk_scenario && control?.risk_scenario.length > 0 ? (
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-6 gy-6'>
                          {/* begin::Table head */}
                          <thead>
                            <tr
                              className='fw-bold'
                              style={{background: '#F6F6F6', color: '#5A5A5A'}}
                            >
                              <th className='min-w-200px pe-6'>Scenario</th>
                              <th className='min-w-100px'>Status</th>
                              <th className='min-w-50px'>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {control?.risk_scenario.map((control) => (
                              <tr key={control.riskId} className=' px-6'>
                                <td
                                  className='fs-6 fw-semibold text-hover-primary py-8'
                                  style={{borderBottom: '1px dotted #C5C5C5', cursor: 'pointer'}}
                                  onClick={() => handleRiskClick(control.riskId)}
                                >
                                  {control.riskSource}
                                </td>
                                <td>
                                  {' '}
                                  <span
                                    className={`badge fs-7 ${
                                      control.status === 'Incomplete'
                                        ? 'badge-danger'
                                        : control.status === 'Needs approval'
                                        ? 'badge-warning'
                                        : 'badge-primary'
                                    }`}
                                    style={{
                                      color:
                                        control.status === 'Incomplete'
                                          ? '#EE3043'
                                          : control.status === 'Needs approval'
                                          ? '#FFA800'
                                          : '#1CBB8D',
                                      background:
                                        control.status === 'Incomplete'
                                          ? '#FFF5F8'
                                          : control.status === 'Needs approval'
                                          ? '#FFF8DD'
                                          : '#E8F8F4',
                                    }}
                                  >
                                    {control.status}
                                  </span>
                                </td>
                                <td>
                                  <div
                                    className='btn btn-icon btn-sm btn-active-light-primary'
                                    onClick={() => handleDeleteControl(control)}
                                    style={{
                                      transition: 'all 0.2s ease',
                                      // border:'2px solid #C5C5C5'
                                    }}
                                    onMouseEnter={(e) => {
                                      if (e.currentTarget) {
                                        e.currentTarget.style.backgroundColor = '#fff'
                                        const svgElement = e.currentTarget.querySelector('svg')
                                        if (svgElement) {
                                          svgElement.style.stroke = '#1CBB8D'
                                        }
                                      }
                                    }}
                                    onMouseLeave={(e) => {
                                      if (e.currentTarget) {
                                        e.currentTarget.style.backgroundColor = '#fff'
                                        const svgElement = e.currentTarget.querySelector('svg')
                                        if (svgElement) {
                                          svgElement.style.stroke = '#5A5A5A'
                                        }
                                      }
                                    }}
                                  >
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width='20'
                                      height='20'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      stroke='#5E6274'
                                      strokeWidth='2'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      className='icon icon-tabler icons-tabler-outline icon-tabler-x '
                                    >
                                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                      <path d='M18 6l-12 12' />
                                      <path d='M6 6l12 12' />
                                    </svg>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <>
                          <div
                            className='card-body'
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='#000'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className='icon icon-tabler icons-tabler-outline icon-tabler-adjustments-horizontal'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M14 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
                              <path d='M4 6l8 0' />
                              <path d='M16 6l4 0' />
                              <path d='M8 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
                              <path d='M4 12l2 0' />
                              <path d='M10 12l10 0' />
                              <path d='M17 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
                              <path d='M4 18l11 0' />
                              <path d='M19 18l1 0' />
                            </svg>
                            <br />
                            <span className='fs-6 fw-semibold'>No risks added</span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

              {activeTab === 'notes' && (
                <>
                  <div className='mb-8 mt-8'>
                    <div className='d-flex align-items-center mb-8'>
                      <textarea
                        // type='text'
                        className='form-control me-2'
                        placeholder='Leave a note'
                        name='notes'
                        value={noteInput}
                        onChange={(e) => setNoteInput(e.target.value)}
                      />
                      <button
                        className='btn btn-sm'
                        onClick={handleNotes}
                        onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                          (e.currentTarget.style.backgroundColor = '#148363')
                        }
                        onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                          (e.currentTarget.style.backgroundColor = '#1CBB8D')
                        }
                        // disabled={activities.length === 0}
                        style={{
                          backgroundColor: '#1CBB8D',
                          color: '#fff',
                          width: '120px',
                          height: '40px',
                          borderRadius: '4px',

                          transition: 'background-color 0.3s',
                        }}
                      >
                        Add Note
                      </button>
                    </div>
                    {controls && controls.length > 0 ? (
                      controls
                        .filter((control) => control.id === id)
                        .map((control) =>
                          control.notes && control.notes.length > 0 ? (
                            control.notes.map((note, index) => (
                              <div className='mt-4 mb-8' key={index}>
                                <div className='d-flex align-items-center justify-content-between mb-2'>
                                  <div className='d-flex align-items-center'>
                                    <div className='symbol symbol-40px me-3'>
                                      {note.addedUser ? (
                                        <span
                                          className='symbol-label fs-7 fw-bold'
                                          style={{
                                            backgroundColor: '#DAF9EF',
                                            borderRadius: '50%',
                                          }}
                                        >
                                          {note.addedUser
                                            ? (() => {
                                                const nameParts = note.addedUser.split(' ')
                                                if (nameParts.length > 1) {
                                                  return `${nameParts[0]
                                                    .charAt(0)
                                                    .toUpperCase()}${nameParts[1]
                                                    .charAt(0)
                                                    .toUpperCase()}`
                                                } else {
                                                  return `${nameParts[0]
                                                    .charAt(0)
                                                    .toUpperCase()}${nameParts[0]
                                                    .charAt(1)
                                                    .toUpperCase()}`
                                                }
                                              })()
                                            : ''}
                                        </span>
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </div>

                                    <div className='d-flex flex-column'>
                                      <span className='text-dark fw-bold fs-6'>
                                        {note.addedUser}
                                      </span>
                                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        {note.addedDate} {note.addedTime}
                                      </span>
                                    </div>
                                  </div>

                                  <div className='dropdown'>
                                    <button
                                      className='btn btn-secondary border-0 p-0 m-2'
                                      type='button'
                                      id='dropdownMenu2'
                                      data-bs-toggle='dropdown'
                                      aria-haspopup='true'
                                      aria-expanded='false'
                                    >
                                      <img
                                        src='/media/buttons/Options.svg'
                                        alt='Options'
                                        onClick={toggleDropdown}
                                      />
                                    </button>
                                    <ul
                                      className='dropdown-menu dropdown-menu-end'
                                      aria-labelledby='dropdownMenu2'
                                    >
                                      <li>
                                        <button
                                          className='dropdown-item text-muted text-hover-primary '
                                          type='button'
                                          onClick={() => handleEditNote(index, note.note)}
                                        >
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='18'
                                            height='18'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            stroke-width='2'
                                            stroke-linecap='round'
                                            stroke-linejoin='round'
                                            className='icon icon-tabler icons-tabler-outline icon-tabler-edit mx-2 mb-1'
                                          >
                                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                            <path d='M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1' />
                                            <path d='M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z' />
                                            <path d='M16 5l3 3' />
                                          </svg>
                                          Edit
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          className='dropdown-item text-danger'
                                          type='button'
                                          onClick={() => handleDeleteNote(index)}
                                        >
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='18'
                                            height='18'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            stroke-width='2'
                                            stroke-linecap='round'
                                            stroke-linejoin='round'
                                            className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-2 mb-1'
                                          >
                                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                            <path d='M4 7l16 0' />
                                            <path d='M10 11l0 6' />
                                            <path d='M14 11l0 6' />
                                            <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                            <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                          </svg>
                                          Delete
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                {editingNoteIndex === index ? (
                                  <div>
                                    <textarea
                                      value={editedNoteContent}
                                      onChange={(e) => setEditedNoteContent(e.target.value)}
                                      className='form-control'
                                    />
                                    <button
                                      onClick={handleSaveEditedNote}
                                      className='btn btn-sm mt-2 fs-6'
                                      onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                        (e.currentTarget.style.backgroundColor = '#148363')
                                      }
                                      onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                        (e.currentTarget.style.backgroundColor = '#1CBB8D')
                                      }
                                      // disabled={activities.length === 0}
                                      style={{
                                        backgroundColor: '#1CBB8D',
                                        color: '#fff',
                                        width: '80px',
                                        height: '40px',
                                        borderRadius: '4px',
                                        marginLeft: '4px',
                                        transition: 'background-color 0.3s',
                                      }}
                                    >
                                      Save
                                    </button>
                                  </div>
                                ) : (
                                  <div className='card p-4 mt-2' style={{background: '#F6F6F6'}}>
                                    <span className='d-block fs-6' style={{color: '#5A5A5A'}}>
                                      {note.note}
                                    </span>
                                  </div>
                                )}
                                {/* <div className='card p-4 mt-2' style={{background: '#F6F6F6'}}>
                                  <span className='d-block fs-6' style={{color: '#5A5A5A'}}>
                                    {note.note}
                                  </span>
                                </div> */}
                              </div>
                            ))
                          ) : (
                            <div key='no-notes'></div>
                          )
                        )
                    ) : (
                      <div>No controls available</div>
                    )}
                  </div>
                </>
              )}

              <AddTask isOpen={isModalOpen} onClose={handleCloseModal} control_id={id ?? ''} />

              {activeTab === 'tasks' && (
                <>
                  <div className='my-4 '>
                    <div className='fs-6 fw-bold mb-2'>Task List</div>
                    <span>Assign tasks that might fix this control to team members.</span>
                  </div>
                  <button
                    className='btn btn-sm '
                    onClick={handleOpenModal}
                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#ECECEC')
                    }
                    onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#fff')
                    }
                    // disabled={activities.length === 0}
                    style={{
                      backgroundColor: '#fff',
                      color: '#000',
                      width: '120px',
                      height: '40px',
                      borderRadius: '4px',
                      border: '2px solid #C5C5C5',
                      transition: 'background-color 0.3s',
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-plus mx-1'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M12 5l0 14' />
                      <path d='M5 12l14 0' />
                    </svg>
                    Add Task
                  </button>
                  <div className='card p-3 mt-4'>
                    {/* begin::Table container */}
                    <div
                      className='table-responsive'
                      style={{minHeight: '300px', maxHeight: '500px', overflowY: 'auto'}}
                    >
                      {/* begin::Table */}
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th className='w-25px'>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value='1'
                                  data-kt-check='true'
                                  data-kt-check-target='.widget-9-check'
                                  // checked={isChecked}
                                  // onChange={handleCheckboxChange}
                                />
                              </div>
                            </th>
                            <th className='min-w-200px'>Task</th>
                            <th className='min-w-50px'>Priority</th>
                            <th className='min-w-100px'>Assigned To</th>
                            {/* <th className='min-w-100px'>Due date</th> */}
                            <th className='min-w-50px text-end'></th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {tasks.map((task) => (
                            <tr key={task.id}>
                              <td>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input widget-9-check'
                                    type='checkbox'
                                    checked={task.checked}
                                    // onChange={() => handleTaskCheckboxChange(task.id)}
                                  />
                                </div>
                              </td>

                              <td>
                                <div className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                  {task.taskName}
                                </div>
                              </td>
                              {/* <td className="text-end">
                    <div className="d-flex flex-column w-100 me-2">
                      <div className="d-flex flex-stack mb-2">
                        
                        <span className={`badge badge-light-success`}>{task.priority}</span>
                      </div>
                    </div>
                  </td> */}
                              <td>
                                <span className='badge border p-2 rounded-pill fs-6 fw-semibold'>
                                  <span
                                    className={`badge border rounded-circle mx-2  ${
                                      task.priority === 'High'
                                        ? 'bg-danger'
                                        : task.priority === 'Medium'
                                        ? 'bg-warning'
                                        : 'bg-primary'
                                    }
                           `}
                                    style={{
                                      width: '12px',
                                      height: '12px',
                                      padding: '3px',
                                    }}
                                  >
                                    {' '}
                                  </span>

                                  {task.priority}
                                </span>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  {task.owner ? (
                                    <>
                                      <div className='symbol symbol-35px me-3'>
                                        <span
                                          className='symbol-label fs-7 fw-bold'
                                          style={{backgroundColor: '#DAF9EF'}}
                                        >
                                          {task.owner
                                            ? (() => {
                                                const nameParts = task.owner.split(' ')
                                                if (nameParts.length > 1) {
                                                  return `${nameParts[0]
                                                    .charAt(0)
                                                    .toUpperCase()}${nameParts[1]
                                                    .charAt(0)
                                                    .toUpperCase()}`
                                                } else {
                                                  return `${nameParts[0]
                                                    .charAt(0)
                                                    .toUpperCase()}${nameParts[0]
                                                    .charAt(1)
                                                    .toUpperCase()}`
                                                }
                                              })()
                                            : ''}
                                        </span>
                                      </div>
                                      <div className='d-flex justify-content-start flex-column'>
                                        <div className='text-dark fw-semibold text-hover-primary fs-6'>
                                          {task.owner}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <span>-</span>
                                    </>
                                  )}
                                </div>
                              </td>

                              {/* <td className='fs-6 fw-semibold'>{task.duedate}</td> */}
                              <td className='text-end'>
                                <div className='dropdown '>
                                  <button
                                    className='btn btn-secondary border-0 p-0 '
                                    type='button'
                                    id='dropdownMenu2'
                                    data-bs-toggle='dropdown'
                                    aria-haspopup='true'
                                    aria-expanded='false'
                                  >
                                    <img
                                      src='/media/buttons/Options.svg'
                                      alt='Options'
                                      // onClick={toggleDropdown}
                                    />
                                  </button>
                                  <ul className='dropdown-menu' aria-labelledby='dropdownMenu2'>
                                    <li>
                                      <button
                                        className='dropdown-item'
                                        type='button'
                                        // onClick={() => handleMarkAsComplete(task.id)}
                                      >
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='18'
                                          height='18'
                                          viewBox='0 0 24 24'
                                          fill='none'
                                          stroke='#5E6274'
                                          strokeWidth='2'
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          className='icon icon-tabler icons-tabler-outline icon-tabler-check mx-1 mb-1'
                                        >
                                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                          <path d='M5 12l5 5l10 -10' />
                                        </svg>{' '}
                                        Mark as complete
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className='dropdown-item'
                                        style={{color: '#F15363'}}
                                        type='button'
                                        onClick={() => handleRemoveTask(task.id)}
                                      >
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='18'
                                          height='18'
                                          viewBox='0 0 24 24'
                                          fill='none'
                                          stroke='#F15363'
                                          strokeWidth='2'
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-1 mb-1'
                                        >
                                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                          <path d='M4 7l16 0' />
                                          <path d='M10 11l0 6' />
                                          <path d='M14 11l0 6' />
                                          <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                          <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                        </svg>{' '}
                                        Remove task
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </>
          ))
      ) : (
        <>
          {' '}
          <div style={{height: '80vh', display: 'flex', flexDirection: 'column'}}>
            {' '}
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className='spinner-border text-primary' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default DetailControl
