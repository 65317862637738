import {doc, getDoc} from 'firebase/firestore'
import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useParams} from 'react-router-dom'
import db, {storage} from '../../../../services/firebase'
import vendorServices from '../services/vendor.services'
import {getDownloadURL, ref, uploadBytesResumable} from 'firebase/storage'

interface VendorData {
  companyName: string
  website: string
  security: string
  vendorOwner: string
  serviceProvided: string
  dateOfEngagement: string
  dateOfLastReview: string
  authenticationType: string
  twoFactorEnable: boolean
  vendorThirdParty: string
  otherInfo: string
  environmentType: string[]
  publiclyAvailableData: string[]
  protectedCorporateOperationData: string[]
  customerInformation: string[]
  regulationProtectedInformation: string[]

  reliance: string
  riskLevel: string
  imgUrl: string
}
const initialState: VendorData = {
  companyName: '',
  website: '',
  security: '',
  vendorOwner: '',
  serviceProvided: '',
  dateOfEngagement: '',
  dateOfLastReview: '',
  authenticationType: 'password',
  twoFactorEnable: true,
  vendorThirdParty: '',
  otherInfo: '',
  environmentType: [] as string[],
  publiclyAvailableData: [] as string[],
  protectedCorporateOperationData: [] as string[],
  customerInformation: [] as string[],
  regulationProtectedInformation: [] as string[],

  reliance: '',
  riskLevel: '',
  imgUrl: '',
}
const EditVendor: React.FC = () => {
  const [form, setForm] = useState(initialState)
  const [file, setFile] = useState<File | null>(null)
  const [progress, setProgress] = useState<number | null>(null)
  const {
    companyName,
    website,
    security,
    vendorOwner,
    serviceProvided,
    dateOfEngagement,
    dateOfLastReview,
    authenticationType,
    twoFactorEnable,
    vendorThirdParty,
    otherInfo,
    environmentType,
    publiclyAvailableData,
    protectedCorporateOperationData,
    customerInformation,
    regulationProtectedInformation,
    reliance,
    riskLevel,
  } = form

  const [message, setMessage] = useState({error: false, msg: ''})
  const [showAlert, setShowAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const {id} = useParams()
  const [imgUrl, setImgUrl] = useState('')
  console.log(form, imgUrl)

  useEffect(() => {
    if (id) {
      const getSingleUser = async () => {
        const docRef = doc(db, 'vendorManagement', id)
        const snapshot = await getDoc(docRef)
        if (snapshot.exists()) {
          const data = snapshot.data() as VendorData
          setForm((prevForm) => ({
            ...prevForm,
            companyName: data.companyName || '',
            website: data.website || '',
            security: data.security || '',
            vendorOwner: data.vendorOwner || '',
            serviceProvided: data.serviceProvided || '',
            dateOfEngagement: data.dateOfEngagement || '',
            dateOfLastReview: data.dateOfLastReview || '',
            authenticationType: data.authenticationType || '',
            vendorThirdParty: data.vendorThirdParty || '',
            otherInfo: data.otherInfo || '',
            environmentType: data.environmentType || '',
            publiclyAvailableData: data.publiclyAvailableData || '',
            protectedCorporateOperationData: data.protectedCorporateOperationData || '',
            customerInformation: data.customerInformation || '',
            regulationProtectedInformation: data.regulationProtectedInformation || '',
            reliance: data.reliance || '',
            riskLevel: data.riskLevel || '',
            imgUrl: data.imgUrl || '',
          }))
          // Assuming imgUrl is the file URL
          const fileUrl = data.imgUrl
          setImgUrl(fileUrl)
          if (fileUrl && file) {
            // Create a new File object with the file URL
            const newfile = new File([], '', {type: 'image/jpeg'})
            setFile(newfile)
          }
        }
      }
      getSingleUser()
    }
  }, [id])

  // const handleSubmit = async (e) => {
  //   e.preventDefault()
  //   if (id)  {
  //     try {
  //       await updateDoc(doc(db, 'vendorManagement', id), {
  //         ...form,
  //         timestamp: serverTimestamp(),
  //       })
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  //   navigate('/vendor-risk')
  // }
  // const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files && e.target.files[0]) {
  //     setFile(e.target.files[0]);
  //   }
  // };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({...form, [e.target.name]: e.target.value})
  }

  const handleAuthType = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }
  const handleTwoFactor = (e) => {
    setForm({
      ...form,
      twoFactorEnable: e.target.checked,
    })
  }
  const handleCheckbox = (event) => {
    const {name, checked} = event.target
    setForm((prevState) => {
      if (checked) {
        return {...prevState, environmentType: [...prevState.environmentType, name]}
      } else {
        return {
          ...prevState,
          environmentType: prevState.environmentType.filter((type) => type !== name),
        }
      }
    })
  }
  const handleDataManagementCheckbox = (event) => {
    const {name, checked} = event.target
    setForm((prevState) => {
      if (checked) {
        return {...prevState, publiclyAvailableData: [...prevState.publiclyAvailableData, name]}
      } else {
        return {
          ...prevState,
          publiclyAvailableData: prevState.publiclyAvailableData.filter((type) => type !== name),
        }
      }
    })
  }
  const handleProtectedCorporateOperationCheckbox = (event) => {
    const {name, checked} = event.target
    setForm((prevState) => {
      if (checked) {
        return {
          ...prevState,
          protectedCorporateOperation: [...prevState.protectedCorporateOperationData, name],
        }
      } else {
        return {
          ...prevState,
          protectedCorporateOperation: prevState.protectedCorporateOperationData.filter(
            (type) => type !== name
          ),
        }
      }
    })
  }

  const handleCustomerInformationCheckbox = (event) => {
    const {name, checked} = event.target
    setForm((prevState) => {
      if (checked) {
        return {...prevState, customerInformation: [...prevState.customerInformation, name]}
      } else {
        return {
          ...prevState,
          customerInformation: prevState.customerInformation.filter((type) => type !== name),
        }
      }
    })
  }

  const handleRegulationProtectedInformationCheckbox = (event) => {
    const {name, checked} = event.target
    setForm((prevState) => {
      if (checked) {
        return {
          ...prevState,
          regulationProtectedInformation: [...prevState.regulationProtectedInformation, name],
        }
      } else {
        return {
          ...prevState,
          regulationProtectedInformation: prevState.regulationProtectedInformation.filter(
            (type) => type !== name
          ),
        }
      }
    })
  }
  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setForm({...form, [e.target.name]: e.target.value})
  }
  const handleRelianceChange = (e) => {
    setForm({
      ...form,
      reliance: e.target.value,
    })
  }
  const handleRiskLevel = (e) => {
    setForm({
      ...form,
      riskLevel: e.target.value,
    })
  }
  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0])
    } else {
      setFile(null)
    }
  }
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (
      !companyName ||
      !website ||
      !security ||
      !vendorOwner ||
      !serviceProvided ||
      !dateOfEngagement ||
      !dateOfLastReview ||
      !authenticationType ||
      !vendorThirdParty ||
      !reliance ||
      !riskLevel
    ) {
      setMessage({error: true, msg: 'Please fill all the fields'})
      setIsLoading(false)
      setShowAlert(true)
      window.scrollTo(0, 0)
      return
    }

    try {
      setIsLoading(true)
      //Upload file to Firebase Storage
      if (file) {
        const fileName = new Date().getTime() + file.name
        const storageRef = ref(storage, `vendorImages/${fileName}`)
        const uploadTask = uploadBytesResumable(storageRef, file)
        await uploadTask

        // Get download URL
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)

        const newVendor = {
          companyName,
          website,
          security,
          vendorOwner,
          serviceProvided,
          dateOfEngagement,
          dateOfLastReview,
          authenticationType,
          twoFactorEnable,
          vendorThirdParty,
          otherInfo,
          environmentType,
          publiclyAvailableData,
          protectedCorporateOperationData,
          customerInformation,
          regulationProtectedInformation,
          reliance,
          riskLevel,
          imgUrl: downloadURL,
        }
        await vendorServices.updateVendor(id, newVendor)
        setMessage({error: false, msg: 'Updated Successfully'})

        setShowAlert(true)
        window.scrollTo(0, 0)
      } else {
        const newVendor = {
          companyName,
          website,
          security,
          vendorOwner,
          serviceProvided,
          dateOfEngagement,
          dateOfLastReview,
          authenticationType,
          twoFactorEnable,
          vendorThirdParty,
          otherInfo,
          environmentType,
          publiclyAvailableData,
          protectedCorporateOperationData,
          customerInformation,
          regulationProtectedInformation,
          reliance,
          riskLevel,
        }

        await vendorServices.updateVendor(id, newVendor)
        setMessage({error: false, msg: 'Updated Successfully'})
        setIsLoading(false)
        setShowAlert(true)
        window.scrollTo(0, 0)
      }
    } catch (err: any) {
      setMessage({error: true, msg: err.message})
      setShowAlert(true)

      window.scrollTo(0, 0)
    } finally {
      setIsLoading(false)
    }
    // setFile(null)

    setForm(initialState)
    setTimeout(() => {
      navigate('/vendor-risk')
    }, 3000)
  }
  const getFileName = (fileUrl) => {
    if (fileUrl) {
      const decodedUrl = decodeURIComponent(fileUrl)
      const fileNameWithParams = decodedUrl.split('?')[0]
      const fileNameArray = fileNameWithParams.split('/')
      let fileName = fileNameArray[fileNameArray.length - 1]
      fileName = fileName.replace(/\d{13}/, '')
      return fileName
    }
    return '' // Return an empty string if fileUrl is null or undefined
  }
  const handleRemoveFile = () => {
    // Reset the selected file and file-related states
    setImgUrl('')
    setFile(null)
    setForm((prev) => ({...prev, imgUrl: ''}))
  }

  return (
    <>
      <div className={`card mb-5 mb-xl-8`}>
        <div className='card-header border-0 pt-5 mb-5'>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-65px me-5 '>
              <img src={imgUrl} alt='' />
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <span className='card-label fw-bold fs-6'>Edit</span>
              <h1 className='text-bold fw-bold'>{companyName}</h1>
            </div>
          </div>
        </div>
      </div>
      {showAlert && (
        <div style={{marginTop: 20, marginLeft: 20, marginRight: 20}}>
          {message?.msg && (
            <div
              className={
                message?.error
                  ? 'alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row'
                  : 'alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row'
              }
            >
              {message?.error ? (
                <i className='ki-duotone ki-notification-bing fs-2hx text-danger me-4 mb-5 mb-sm-0'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
              ) : (
                <i className='ki-duotone ki-notification-bing fs-2hx text-success me-4 mb-5 mb-sm-0'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
              )}

              <div
                className={
                  message?.error
                    ? 'd-flex flex-column text-danger text-primary text-bold pe-0 pe-sm-10'
                    : 'd-flex flex-column text-success text-primary text-bold pe-0 pe-sm-10'
                }
              >
                {message?.error ? (
                  <h5 className='mb-1'>Error!!</h5>
                ) : (
                  <h5 className='mb-1'>Sucess!!</h5>
                )}
                <span>{message?.msg}</span>
              </div>
              <button
                type='button'
                className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
                onClick={() => setShowAlert(false)}
              >
                <span className='svg-icon svg-icon-1 svg-icon-success'>
                  <span className='svg-icon svg-icon-2x svg-icon-primary'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className='mh-50px'
                    >
                      <rect
                        opacity='0.5'
                        x='6'
                        y='17.3137'
                        width='16'
                        height='2'
                        rx='1'
                        transform='rotate(-45 6 17.3137)'
                        fill='black'
                      ></rect>
                      <rect
                        x='7.41422'
                        y='6'
                        width='16'
                        height='2'
                        rx='1'
                        transform='rotate(45 7.41422 6)'
                        fill='black'
                      ></rect>
                    </svg>
                  </span>
                </span>
              </button>
            </div>
          )}
        </div>
      )}
      {/* <div className='card card-custom'> */}
      {/* <div className='card-header'>
        <h3 className='card-title'>Add Asset</h3>
      </div> */}
      {/* <div className='pt-4 pb-0 px-10'> */}
      {/*begin::Navs*/}
      {/* <div className='d-flex overflow-auto h-55px'> */}
      {/* <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-3x border-transparent fs-5 fw-semibold flex-nowrap'> */}
      {/*begin::Nav item*/}
      {/* <li className='nav-item'>
              <Link
                to={`/vendor-risk/asana/details`}
                className={`nav-link ${
                  activeTab === 'details' ? 'text-active-primary active' : ''
                }`}
              >
                Details
              </Link>
            </li> */}
      {/*end::Nav item*/}
      {/*begin::Nav item*/}
      {/* <li className='nav-item'>
              <Link
                to={'/vendor-risk/asana/documents'}
                className={`nav-link me-6 ${
                  activeTab === 'documents' ? 'text-active-primary active' : ''
                }`}
              >
                Documents
              </Link>
            </li> */}
      {/*end::Nav item*/}
      {/* </ul>
        </div> */}
      {/*begin::Navs*/}
      {/* </div> } */}
      <div className='card'>
        <div className='row g-5 g-xxl-8 p-10'>
          <div className='col-xl-6' style={{width: '40%'}}>
            <h2 className='mb-5'>Main Info</h2>
            <form className='form' onSubmit={handleSubmit}>
              <div className='form-group'>
                <label className='text-muted mb-2'>Company name</label>
                <input
                  type='text'
                  className='form-control form-control-solid form-control-sm'
                  placeholder='Company name'
                  name='companyName'
                  value={companyName}
                  onChange={handleChange}
                />
                {/* {errors.companyName && <p>{errors.companyName}</p>} */}
                {/* <span className='form-text text-muted'>Please enter Risk Source</span> */}
              </div>
              <div className='form-group'>
                <label className='text-muted mt-5 mb-3'>Website</label>
                <div className='input-group input-group-sm '>
                  <div className='input-group-prepend'>
                    <span className='input-group-text '>https://</span>
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Website'
                    aria-describedby='basic-addon2'
                    name='website'
                    value={website}
                    onChange={handleChange}
                  />
                  {/* {errors.website && <p>{errors.website}</p>} */}
                </div>
              </div>
              <div className='form-group'>
                <label className='text-muted mt-5 mb-3'>Security</label>
                <div className='input-group input-group-sm '>
                  <div className='input-group-prepend'>
                    <span className='input-group-text '>https://</span>
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Website'
                    aria-describedby='basic-addon2'
                    name='security'
                    value={security}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className='text-muted mt-5 mb-3'>Vendor Owner</label>
                {/* <select className='form-control form-control-solid ' id='exampleSelectl'>
                <option>Jackson Hartman</option>
                <option>Jack Hartman</option>
                <option>Sam Smith</option>
              </select> */}
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='Vendor Owner'
                  name='vendorOwner'
                  value={vendorOwner}
                  onChange={handleChange}
                />
              </div>
              <div className='form-group'>
                <label className='text-muted mt-5 mb-3'>Services Provided</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='Services Provided'
                  name='serviceProvided'
                  value={serviceProvided}
                  onChange={handleChange}
                />
                {/* <span className='form-text text-muted'>
                      We'll never share your email with anyone else
                    </span> */}
              </div>

              <div className='form-group'>
                <label className='text-muted mt-5 mb-3'>Date of engagement</label>
                <input
                  type='date'
                  className='form-control form-control-solid'
                  placeholder=''
                  name='dateOfEngagement'
                  value={dateOfEngagement}
                  onChange={handleChange}
                />
                {/* <span className='form-text text-muted'>
                      We'll never share your email with anyone else
                    </span> */}
              </div>
              <div className='form-group'>
                <label className='text-muted mt-5 mb-3'>Date of last review</label>
                <input
                  type='date'
                  className='form-control form-control-solid'
                  placeholder=''
                  name='dateOfLastReview'
                  value={dateOfLastReview}
                  onChange={handleChange}
                />
              </div>
              <div className='form-group'>
                <label className='text-muted mt-5 mb-3'>Authentication type </label>
                <label className='form-check form-check-custom form-check-solid p-5'>
                  <input
                    className='form-check-input'
                    type='radio'
                    // checked={true}
                    value='password'
                    name='password'
                    checked={authenticationType === 'password'}
                    onChange={handleAuthType}
                  />

                  <span style={{marginLeft: 10, marginRight: 20}}> Password</span>

                  <input
                    className='form-check-input'
                    type='radio'
                    // checked={false}
                    value='Single sign-in'
                    name='authenticationType'
                    checked={authenticationType === 'Single sign-in'}
                    onChange={handleAuthType}
                  />
                  <span style={{marginLeft: 10, marginRight: 20}}>Single sign-in</span>
                </label>
              </div>
              <div className='form-group'>
                <label className='text-muted mt-5 mb-3'>Two factor enabled</label>

                <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    name='twoFactorEnable'
                    checked={twoFactorEnable}
                    onChange={handleTwoFactor}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className='text-muted mt-5 mb-3'>
                  Do the vendors third party audit reports(SOC 2, ISO 27001) evident any area of
                  concerns?{' '}
                </label>
                <textarea
                  className='form-control form-control-solid'
                  id='description'
                  rows={3}
                  value={vendorThirdParty}
                  name='vendorThirdParty'
                  onChange={handleTextAreaChange}
                ></textarea>
              </div>
              <div className='form-group'>
                <label className='text-muted mt-5 mb-3'>Other information</label>
                <textarea
                  className='form-control form-control-solid'
                  id='description'
                  rows={3}
                  value={otherInfo}
                  name='otherInfo'
                  onChange={handleTextAreaChange}
                ></textarea>
              </div>
            </form>
          </div>
          <div className='col-xl-6' style={{width: '40%', marginLeft: 50}}>
            <h2 className='mb-7'>Audit Information</h2>
            <form className='form' onSubmit={handleSubmit}>
              <div className='form-group'>
                <h4 className='mb-5'>Environment Type</h4>
                <label className='text-muted fw-semibold fs-6'>What type of vendor is this?</label>
                <div className='checkbox-list'>
                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='Production'
                      style={{width: 15, height: 15, marginRight: 10}}
                      // value={environmentType}
                      checked={environmentType['Production']}
                      onChange={handleCheckbox}
                    />
                    <span></span>
                    Production
                  </label>

                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='Development'
                      style={{width: 15, height: 15, marginRight: 10}}
                      // value={environmentType}
                      checked={environmentType['Development']}
                      onChange={handleCheckbox}
                    />
                    <span></span>
                    Development
                  </label>
                </div>
              </div>
              <div className='form-group'>
                <h4 className='mt-10 mb-5'>Data Management</h4>
                <label className='fw-semibold '>
                  What type of data does system store, process or interface with?
                </label>
                <div>
                  <label className='text-muted fw-semibold fs-6 mt-3'>
                    Publicly available data
                  </label>
                </div>

                <div className='checkbox-list'>
                  <label className='form-check form-check-custom form-check-solid m-3'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='Generic_informational_or_Marketing_material'
                      style={{width: 15, height: 15, marginRight: 10}}
                      checked={publiclyAvailableData['Generic_informational_or_Marketing_material']}
                      onChange={handleDataManagementCheckbox}
                    />
                    <span></span>
                    Generic, informational, or Marketing material
                  </label>
                </div>
                <label className='text-muted fw-semibold fs-6'>Protected Corporate Operation</label>

                <div className='checkbox-list'>
                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='employmentInformation'
                      style={{width: 15, height: 15, marginRight: 10}}
                      checked={protectedCorporateOperationData['employmentInformation']}
                      onChange={handleProtectedCorporateOperationCheckbox}
                    />
                    <span></span>
                    Employment Informtion
                  </label>
                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='corporativeProprietaryInformation'
                      style={{width: 15, height: 15, marginRight: 10}}
                      checked={protectedCorporateOperationData['corporativeProprietaryInformation']}
                      onChange={handleProtectedCorporateOperationCheckbox}
                    />
                    <span></span>
                    Corporative Proprietary Information
                  </label>
                </div>

                <label className='text-muted fw-semibold fs-6'>Customer Information</label>

                <div className='checkbox-list'>
                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='PublicCustomerInformation'
                      style={{width: 15, height: 15, marginRight: 10}}
                      checked={customerInformation['PublicCustomerInformation']}
                      onChange={handleCustomerInformationCheckbox}
                    />
                    <span></span>
                    Public Customer Information
                  </label>
                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='NonPublicCustomerInformation'
                      style={{width: 15, height: 15, marginRight: 10}}
                      checked={customerInformation['NonPublicCustomerInformation']}
                      onChange={handleCustomerInformationCheckbox}
                    />
                    <span></span>
                    Non-Public Customer Information
                  </label>
                </div>

                <label className='text-muted fw-semibold fs-6'>
                  Regulation-protected customer, consumer, and employee information
                </label>

                <div className='checkbox-list'>
                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='GDPR_regulated_EU_resident_data'
                      style={{width: 15, height: 15, marginRight: 10}}
                      checked={regulationProtectedInformation['GDPR_regulated_EU_resident_data']}
                      onChange={handleRegulationProtectedInformationCheckbox}
                    />
                    <span></span>
                    GDPR regulated EU resident data
                  </label>

                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='HIPAA_or_HITRUST_protected_data_PHI'
                      style={{width: 15, height: 15, marginRight: 10}}
                      checked={
                        regulationProtectedInformation['HIPAA_or_HITRUST_protected_data_PHI']
                      }
                      onChange={handleRegulationProtectedInformationCheckbox}
                    />
                    <span></span>
                    HIPAA or HITRUST protected data(PHI)
                  </label>

                  <div className='checkbox-list'>
                    <label className='form-check form-check-custom form-check-solid m-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        //   checked={true}
                        // value={hdEncryption}
                        name='PCI_DSS_Protected_Data'
                        style={{width: 15, height: 15, marginRight: 10}}
                        checked={regulationProtectedInformation['PCI_DSS_Protected_Data']}
                        onChange={handleRegulationProtectedInformationCheckbox}
                      />
                      <span></span>
                      PCI-DSS Protected Data
                    </label>
                    <label className='form-check form-check-custom form-check-solid m-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        //   checked={true}
                        // value={hdEncryption}
                        name='CCPA_Protected_Data'
                        style={{width: 15, height: 15, marginRight: 10}}
                        checked={regulationProtectedInformation['CCPA_Protected_Data']}
                        onChange={handleRegulationProtectedInformationCheckbox}
                      />
                      <span></span>
                      CCPA Protected Data
                    </label>
                    {/* <label className='text-muted fw-semibold fs-6'>Customer Information</label> */}
                  </div>
                  <h4 className='mt-10 mb-5'>Operational Reliance</h4>
                  <div className='col-9 col-form-label'>
                    <div className='checkbox-list'>
                      {/* <label>Operational Reliance</label> */}
                      <label className='form-check form-check-custom form-check-solid p-0'>
                        <input
                          className='form-check-input'
                          type='radio'
                          value='Low'
                          name='reliance'
                          checked={reliance === 'Low'}
                          onChange={handleRelianceChange}
                        />

                        <span style={{marginLeft: 10, marginRight: 20}}> Low</span>

                        <input
                          className='form-check-input'
                          type='radio'
                          value='Medium'
                          name='reliance'
                          checked={reliance === 'Medium'}
                          onChange={handleRelianceChange}
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}>Medium</span>

                        <input
                          className='form-check-input'
                          type='radio'
                          value='High'
                          name='reliance'
                          checked={reliance === 'High'}
                          onChange={handleRelianceChange}
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}>High</span>
                      </label>
                    </div>
                  </div>
                  <h4 className='mt-10 mb-5'>Risk Level</h4>
                  <div className='col-9 col-form-label'>
                    <div className='checkbox-list'>
                      {/* <label>Risk Level</label> */}
                      <label className='form-check form-check-custom form-check-solid p-0'>
                        <input
                          className='form-check-input'
                          type='radio'
                          value='Low Risk Level'
                          name='riskLevel'
                          checked={riskLevel === 'Low Risk Level'}
                          onChange={handleRiskLevel}
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}> Low</span>

                        <input
                          className='form-check-input'
                          type='radio'
                          value='Medium Risk Level'
                          name='riskLevel'
                          checked={riskLevel === 'Medium Risk Level'}
                          onChange={handleRiskLevel}
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}>Medium</span>

                        <input
                          className='form-check-input'
                          type='radio'
                          value='High Risk Level'
                          name='riskLevel'
                          checked={riskLevel === 'High Risk Level'}
                          onChange={handleRiskLevel}
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}>High</span>
                      </label>
                    </div>
                  </div>
                  <div className='form-group'>
                    <h4 className='mt-10 mb-5'>Logo</h4>

                    {/* <label className='text-dark fw-bold text-primary my-2 fs-6'>Logo</label> */}
                    {imgUrl ? (
                      <div className='position-relative'>
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          name='vendorlogo'
                          value={getFileName(imgUrl)}
                          onChange={handleImage}
                          autoComplete='off'
                          accept='.jpg, .jpeg, .png, .svg'
                        />
                        <button
                          onClick={handleRemoveFile}
                          className='btn p-0 position-absolute top-50 end-0 translate-middle-y'
                        >
                          <i className='bi bi-x fs-1'></i>
                        </button>
                      </div>
                    ) : (
                      <input
                        type='file'
                        className='form-control form-control-solid'
                        // placeholder='Logo'
                        // name='logo'
                        // value={logo}
                        accept='.jpg,.png,.jepg'
                        onChange={handleImage}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className='modal-footer mx-5 mt-20'>
                <Link to='/vendor-risk' className='btn btn-secondary' style={{marginRight: 20}}>
                  Cancel
                </Link>
                {isLoading ? (
                  <button
                    type='submit'
                    className='btn'
                    style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                  >
                    <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                    Update Vendor
                  </button>
                ) : (
                  <button
                    type='submit'
                    className='btn'
                    style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                    disabled={progress !== null && progress < 100}
                  >
                    Update Vendor
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditVendor
