import React, {useEffect, useState} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import {getDownloadURL, ref, uploadBytesResumable} from 'firebase/storage'
import db, {storage} from '../../../../services/firebase'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import { addDoc, collection, doc, serverTimestamp } from 'firebase/firestore'

interface VendorData {
  companyName: string
  website: string
  security: string
  vendorOwner: string
  serviceProvided: string
  dateOfEngagement: string
  dateOfLastReview: string
  authenticationType: string
  twoFactorEnable: boolean
  vendorThirdParty: string
  otherInfo: string
  // environmentType: string[]
  // publiclyAvailableData: string[]
  // protectedCorporateOperation: string[]
  // customerInformation: string[]
  // regulationProtectedInformation: string[]

  reliance: string
  riskLevel: string
  imgUrl: string
}

const initialState: VendorData = {
  companyName: '',
  website: '',
  security: '',
  vendorOwner: '',
  serviceProvided: '',
  dateOfEngagement: '',
  dateOfLastReview: '',
  authenticationType: 'password',
  twoFactorEnable: true,
  vendorThirdParty: '',
  otherInfo: '',
  // environmentType: [] as string[],
  // publiclyAvailableData: [] as string[],
  // protectedCorporateOperation: [] as string[],
  // customerInformation: [] as string[],
  // regulationProtectedInformation: [] as string[],

  reliance: '',
  riskLevel: '',
  imgUrl: '',
}

// type ErrorsState = {
//   companyName?: string;
//   website?: string;
//   // Add other form fields here...
// };

const AddVendor: React.FC = () => {
  const [form, setForm] = useState(initialState)
  const [file, setFile] = useState<File | null>(null)
  const [progress, setProgress] = useState<number | null>(null)
  // const [errors, setErrors] = useState<ErrorsState>({});
  const [message, setMessage] = useState({error: false, msg: ''})

  const [showAlert, setShowAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [protectedCorporateOperationData, setProtectedCorporateOperationData] = useState({
    employmentInformation: false,
    corporativeProprietaryInformation: false,
  })
  const [customerInformation, setCustomerInformation] = useState({
    PublicCustomerInformation: false,
    NonPublicCustomerInformation: false,
  })
  const [regulationProtectedInformation, setRegulationProtectedInformation] = useState({
    GDPR_regulated_EU_resident_data: false,
    HIPAA_or_HITRUST_protected_data_PHI: false,
    PCI_DSS_Protected_Data: false,
    CCPA_Protected_Data: false,
  })
  const [environmentType, setEnvironmentType] = useState({
    Production: false,
    Development: false,
  })
  const [publiclyAvailableData, setPubliclyAvailableData] = useState({
    Generic_informational_or_Marketing_material: false,
  })

  const {
    companyName,
    website,
    security,
    vendorOwner,
    serviceProvided,
    dateOfEngagement,
    dateOfLastReview,
    authenticationType,
    twoFactorEnable,
    vendorThirdParty,
    otherInfo,
    // environmentType,
    // publiclyAvailableData,
    // protectedCorporateOperation,
    // customerInformation,
    // regulationProtectedInformation,
    reliance,
    riskLevel,
  } = form
  const navigate = useNavigate()
  const {currentUser} = useAuth()

  const location = useLocation()
  const [activeTab, setActiveTab] = useState('details')
  const {id} = useParams()
  useEffect(() => {
    // Fetch control data when the component mounts
    if (location.pathname.includes('details')) {
      setActiveTab('details')
    } else if (location.pathname.includes('documents')) {
      setActiveTab('documents')
    }
  }, [id])

  useEffect(() => {
    const uploadFile = () => {
      if (file) {
        const fileName = new Date().getTime() + file.name
        const storageRef = ref(storage, `vendorImages/${fileName}`)
        const uploadTask = uploadBytesResumable(storageRef, file)
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log('Upload is ' + progress + '% done')
            setProgress(progress)
            // setIsLoading(true)
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused')
                break
              case 'running':
                console.log('Upload is running')

                break
              default:
                break
            }
          },
          (error) => {
            console.log(error)
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
              setForm((prev) => ({...prev, imgUrl: downloadUrl}))
            })
          }
        )
      }
    }
    file && uploadFile()
  }, [file])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({...form, [e.target.name]: e.target.value})
  }
  const handleAuthType = (e: {target: {name: any; value: any}}) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }
  const handleTwoFactor = (e: {target: {checked: any}}) => {
    setForm({
      ...form,
      twoFactorEnable: e.target.checked,
    })
  }
  const handleCheckbox = (event: {target: {name: any; checked: any}}) => {
    const {name, checked} = event.target
    setEnvironmentType({
      ...environmentType,
      [name]: checked,
    })
  }

  const handleDataManagementCheckbox = (event: {target: {name: any; checked: any}}) => {
    const {name, checked} = event.target
    setPubliclyAvailableData({
      ...publiclyAvailableData,
      [name]: checked,
    })
  }

  const handleProtectedCorporateOperationCheckbox = (event: {
    target: {name: any; checked: any}
  }) => {
    const {name, checked} = event.target
    setProtectedCorporateOperationData({
      ...protectedCorporateOperationData,
      [name]: checked,
    })
  }

  const handleCustomerInformationCheckbox = (event: {target: {name: any; checked: any}}) => {
    const {name, checked} = event.target
    setCustomerInformation({
      ...customerInformation,
      [name]: checked,
    })
  }

  const handleRegulationProtectedInformationCheckbox = (event: {
    target: {name: any; checked: any}
  }) => {
    const {name, checked} = event.target
    setRegulationProtectedInformation({
      ...regulationProtectedInformation,
      [name]: checked,
    })
  }
  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setForm({...form, [e.target.name]: e.target.value})
  }
  const handleRelianceChange = (e: {target: {value: any}}) => {
    setForm({
      ...form,
      reliance: e.target.value,
    })
  }
  const handleRiskLevel = (e: {target: {value: any}}) => {
    setForm({
      ...form,
      riskLevel: e.target.value,
    })
  }
  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0])
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    // let errors = {};
    // if (!form.companyName) errors.companyName = 'Company name is required.';
    // if (!form.website) errors.website = 'Website is required.';

    // if (Object.keys(errors).length > 0) {
    //   setErrors(errors);
    // }
    //   return;
    console.log('Form data:', form)

    if (
      // companyName &&
      // website &&
      // security &&
      // vendorOwner &&
      // serviceProvided &&
      // dateOfEngagement &&
      // dateOfLastReview &&
      // authenticationType &&
      // twoFactorEnable &&
      // vendorThirdParty &&

      // environmentType &&
      // dataManagement &&
      // protectedCorporateOperation &&
      // customerInformation &&
      // regulationProtectedInformation &&
      // reliance &&
      // riskLevel

      !companyName ||
      !website ||
      !security ||
      !vendorOwner ||
      !serviceProvided ||
      !dateOfEngagement ||
      !dateOfLastReview ||
      !authenticationType ||
      !vendorThirdParty ||
      !reliance ||
      !riskLevel
    ) {
      setMessage({error: true, msg: 'Please fill all the fields'})
      setIsLoading(false)
      setShowAlert(true)
      window.scrollTo(0, 0)
      return
    }

    try {
      setIsLoading(true)
      // Upload file to Firebase Storage

      if (file) {
        const fileName = new Date().getTime() + file.name
        const storageRef = ref(storage, `vendorImages/${fileName}`)
        const uploadTask = uploadBytesResumable(storageRef, file)
        await uploadTask

        // Get download URL
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)

        const newVendor = {
          companyName,
          website,
          security,
          vendorOwner,
          serviceProvided,
          dateOfEngagement,
          dateOfLastReview,
          authenticationType,
          twoFactorEnable,
          vendorThirdParty,
          otherInfo,
          environmentType,
          publiclyAvailableData,
          protectedCorporateOperationData,
          customerInformation,
          regulationProtectedInformation,
          reliance,
          riskLevel,
          imgUrl: downloadURL,
          tenantId: currentUser?.tenantId,
          uid: currentUser?.uid,
        }
        if (currentUser) {
          const tenantRef = doc(db, 'tenants', currentUser.tenantId!)
          const policyRef = collection(tenantRef, 'Vendors')
  
          await addDoc(policyRef, {
            ...newVendor,
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
          })
        
          const activitiesRef = collection(tenantRef, 'activities')
          await addDoc(activitiesRef, {
            message: ` added a new vendor`,
            timestamp: serverTimestamp(),
            tenantId: currentUser?.tenantId,
           
            uid: currentUser?.uid,
            username: currentUser?.userName,
    
         
          })
        }
        setMessage({error: false, msg: 'Added Successfully'})
        setShowAlert(true)
        window.scrollTo(0, 0)
      }
    } catch (err: any) {
      setMessage({error: true, msg: err.message})
      setShowAlert(true)
      window.scrollTo(0, 0)
    } finally {
      setIsLoading(false)
    }
    setFile(null)
    // setName('')
    // setRiskLevel('')
    setForm(initialState)

    setTimeout(() => {
      navigate('/vendor-risk')
    }, 3000)

    // try{
    //   await addDoc(collection(db,"vendorManagement"),{
    //     ...form,
    //     timestamp: serverTimestamp(),

    //   })
    //   console.log("successfull")
    // }catch(error){
    //   console.log(error)
    // }
  }

  return (
    <>
      <div className={`card mb-5 mb-xl-8`}>
        <div className='card-header border-0 pt-5 mb-5'>
          <div className='d-flex align-items-center'>
            <div className='d-flex justify-content-start flex-column'>
              <span className='card-label fw-bold fs-5'>Add</span>
              <h1 className='text-bold fw-bold'>Vendor</h1>
            </div>
          </div>
        </div>
      </div>
      {showAlert && (
        <div style={{marginTop: 20, marginLeft: 20, marginRight: 20}}>
          {message?.msg && (
            <div
              className={
                message?.error
                  ? 'alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row'
                  : 'alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row'
              }
            >
              {message?.error ? (
                <i className='ki-duotone ki-notification-bing fs-2hx text-danger me-4 mb-5 mb-sm-0'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
              ) : (
                <i className='ki-duotone ki-notification-bing fs-2hx text-success me-4 mb-5 mb-sm-0'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
              )}

              <div
                className={
                  message?.error
                    ? 'd-flex flex-column text-danger text-primary text-bold pe-0 pe-sm-10'
                    : 'd-flex flex-column text-success text-primary text-bold pe-0 pe-sm-10'
                }
              >
                {message?.error ? (
                  <h5 className='mb-1'>Error!!</h5>
                ) : (
                  <h5 className='mb-1'>Sucess!!</h5>
                )}
                <span>{message?.msg}</span>
              </div>
              <button
                type='button'
                className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
                onClick={() => setShowAlert(false)}
              >
                <span className='svg-icon svg-icon-1 svg-icon-success'>
                  <span className='svg-icon svg-icon-2x svg-icon-primary'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className='mh-50px'
                    >
                      <rect
                        opacity='0.5'
                        x='6'
                        y='17.3137'
                        width='16'
                        height='2'
                        rx='1'
                        transform='rotate(-45 6 17.3137)'
                        fill='black'
                      ></rect>
                      <rect
                        x='7.41422'
                        y='6'
                        width='16'
                        height='2'
                        rx='1'
                        transform='rotate(45 7.41422 6)'
                        fill='black'
                      ></rect>
                    </svg>
                  </span>
                </span>
              </button>
            </div>
          )}
        </div>
      )}
      <div className='card card-custom'>
        {/* <div className='card-header'>
          <h3 className='card-title'>Add Asset</h3>
        </div> */}
        <div className='pt-4 pb-0 px-10'>
          {/*begin::Navs*/}
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-3x border-transparent fs-5 fw-semibold flex-nowrap'>
              {/*begin::Nav item*/}
              <li className='nav-item'>
                <Link
                  to={`/vendor-risk/asana/details`}
                  className={`nav-link ${
                    activeTab === 'details' ? 'text-active-primary active' : ''
                  }`}
                >
                  Details
                </Link>
              </li>
              {/*end::Nav item*/}
              {/*begin::Nav item*/}
              {/* <li className='nav-item'>
                <Link
                  to={'/vendor-risk/asana/documents'}
                  className={`nav-link me-6 ${
                    activeTab === 'documents' ? 'text-active-primary active' : ''
                  }`}
                >
                  Documents
                </Link>
              </li> */}
              {/*end::Nav item*/}
            </ul>
          </div>
          {/*begin::Navs*/}
        </div>
        <div className='row g-5 g-xxl-8 p-10'>
          <div className='col-xl-6' style={{width: '40%'}}>
            <h2 className='mb-5'>Main Info</h2>
            <form className='form' onSubmit={handleSubmit}>
              <div className='form-group'>
                <label className='fw-semibold mb-2'>Company name</label>
                <input
                  type='text'
                  className='form-control  form-control-sm'
                  placeholder='Company name'
                  name='companyName'
                  value={companyName}
                  onChange={handleChange}
                />
                {/* {errors.companyName && <p>{errors.companyName}</p>} */}
                {/* <span className='form-text text-muted'>Please enter Risk Source</span> */}
              </div>
              <div className='form-group'>
                <label className='fw-semibold mt-5 mb-3'>Website</label>
                <div className='input-group input-group-sm '>
                  <div className='input-group-prepend'>
                    <span className='input-group-text '>https://</span>
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Website'
                    aria-describedby='basic-addon2'
                    name='website'
                    value={website}
                    onChange={handleChange}
                  />
                  {/* {errors.website && <p>{errors.website}</p>} */}
                </div>
              </div>
              <div className='form-group'>
                <label className='fw-semibold mt-5 mb-3'>Security</label>
                <div className='input-group input-group-sm '>
                  <div className='input-group-prepend'>
                    <span className='input-group-text '>https://</span>
                  </div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Website'
                    aria-describedby='basic-addon2'
                    name='security'
                    value={security}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className='fw-semibold mt-5 mb-3'>Vendor Owner</label>
                {/* <select className='form-control  ' id='exampleSelectl'>
                  <option>Jackson Hartman</option>
                  <option>Jack Hartman</option>
                  <option>Sam Smith</option>
                </select> */}
                <input
                  type='text'
                  className='form-control '
                  placeholder='Vendor Owner'
                  name='vendorOwner'
                  value={vendorOwner}
                  onChange={handleChange}
                />
              </div>
              <div className='form-group'>
                <label className='fw-semibold mt-5 mb-3'>Services Provided</label>
                <input
                  type='text'
                  className='form-control '
                  placeholder='Services Provided'
                  name='serviceProvided'
                  value={serviceProvided}
                  onChange={handleChange}
                />
                {/* <span className='form-text text-muted'>
                        We'll never share your email with anyone else
                      </span> */}
              </div>

              <div className='form-group'>
                <label className='fw-semibold mt-5 mb-3'>Date of engagement</label>
                <input
                  type='date'
                  className='form-control '
                  placeholder=''
                  name='dateOfEngagement'
                  value={dateOfEngagement}
                  onChange={handleChange}
                />
                {/* <span className='form-text text-muted'>
                        We'll never share your email with anyone else
                      </span> */}
              </div>
              <div className='form-group'>
                <label className='fw-semibold mt-5 mb-3'>Date of last review</label>
                <input
                  type='date'
                  className='form-control '
                  placeholder=''
                  name='dateOfLastReview'
                  value={dateOfLastReview}
                  onChange={handleChange}
                />
              </div>
              <div className='form-group'>
                <label className='fw-semibold mt-5 mb-3'>Authentication type </label>
                <label className='form-check form-check-custom form-check-solid p-5'>
                  <input
                    className='form-check-input'
                    type='radio'
                    // checked={true}
                    value='Password'
                    name='authenticationType'
                    checked={authenticationType === 'password'}
                    onChange={handleAuthType}
                  />

                  <span style={{marginLeft: 10, marginRight: 20}}> Password</span>

                  <input
                    className='form-check-input'
                    type='radio'
                    // checked={false}
                    value='Single sign-in'
                    name='authenticationType'
                    checked={authenticationType === 'Single sign-in'}
                    onChange={handleAuthType}
                  />
                  <span style={{marginLeft: 10, marginRight: 20}}>Single sign-in</span>
                </label>
              </div>
              <div className='form-group'>
                <label className='fw-semibold mt-5 mb-3'>Two factor enabled</label>

                <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    name='twoFactorEnable'
                    checked={twoFactorEnable}
                    onChange={handleTwoFactor}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className='fw-semibold mt-5 mb-3'>
                  Do the vendors third party audit reports(SOC 2, ISO 27001) evident any area of
                  concerns?{' '}
                </label>
                <textarea
                  className='form-control '
                  id='description'
                  rows={3}
                  value={vendorThirdParty}
                  name='vendorThirdParty'
                  onChange={handleTextAreaChange}
                ></textarea>
              </div>
              <div className='form-group'>
                <label className='fw-semibold mt-5 mb-3'>Other information</label>
                <textarea
                  className='form-control '
                  id='description'
                  rows={3}
                  value={otherInfo}
                  name='otherInfo'
                  onChange={handleTextAreaChange}
                ></textarea>
              </div>
            </form>
          </div>
          <div className='col-xl-6' style={{width: '40%', marginLeft: 50}}>
            <h2 className='mb-7'>Audit Information</h2>
            <form className='form' onSubmit={handleSubmit}>
              <div className='form-group'>
                <h4 className='mb-5'>Environment Type</h4>
                <label className='fw-semibold fs-6'>What type of vendor is this?</label>
                <div className='checkbox-list'>
                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='Production'
                      style={{width: 15, height: 15, marginRight: 10}}
                      // value={environmentType}
                      checked={environmentType.Production}
                      onChange={handleCheckbox}
                    />
                    <span></span>
                    Production
                  </label>

                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='Development'
                      style={{width: 15, height: 15, marginRight: 10}}
                      // value={environmentType}
                      checked={environmentType.Development}
                      onChange={handleCheckbox}
                    />
                    <span></span>
                    Development
                  </label>
                </div>
              </div>
              <div className='form-group'>
                <h4 className='mt-10 mb-5'>Data Management</h4>
                <label className='fw-semibold '>
                  What type of data does system store, process or interface with?
                </label>
                <div>
                  <label className='fw-semibold fs-6 mt-3'>
                    Publicly available data
                  </label>
                </div>

                <div className='checkbox-list'>
                  <label className='form-check form-check-custom form-check-solid m-3'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='Generic_informational_or_Marketing_material'
                      style={{width: 15, height: 15, marginRight: 10}}
                      checked={publiclyAvailableData.Generic_informational_or_Marketing_material}
                      onChange={handleDataManagementCheckbox}
                    />
                    <span></span>
                    Generic, informational, or Marketing material
                    <OverlayTrigger
                      placement='right'
                      overlay={
                        <Tooltip id='tooltip-top'>
                          <span>
                            Data that is openly available to the public without any restrictions,
                            such as company brochures, marketing materials, press releases, etc.
                          </span>
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='#5A5A5A'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                        <path d='M12 9h.01' />
                        <path d='M11 12h1v4h1' />
                      </svg>
                    </OverlayTrigger>
                  </label>
                </div>
                <label className='fw-semibold fs-6'>Protected Corporate Operation</label>

                <div className='checkbox-list'>
                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='employmentInformation'
                      style={{width: 15, height: 15, marginRight: 10}}
                      checked={protectedCorporateOperationData.employmentInformation}
                      onChange={handleProtectedCorporateOperationCheckbox}
                    />
                    <span></span>
                    Employment Informtion{' '}
                    <OverlayTrigger
                      placement='right'
                      overlay={
                        <Tooltip id='tooltip-top'>
                          <span>
                            Data related to employees, such as personal details, employment history,
                            payroll information, performance reviews, and other HR-related data.
                          </span>
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='#5A5A5A'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                        <path d='M12 9h.01' />
                        <path d='M11 12h1v4h1' />
                      </svg>
                    </OverlayTrigger>
                  </label>
                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='corporativeProprietaryInformation'
                      style={{width: 15, height: 15, marginRight: 10}}
                      checked={protectedCorporateOperationData.corporativeProprietaryInformation}
                      onChange={handleProtectedCorporateOperationCheckbox}
                    />
                    <span></span>
                    Corporative Proprietary Information{' '}
                    <OverlayTrigger
                      placement='right'
                      overlay={
                        <Tooltip id='tooltip-top'>
                          <span>
                            Sensitive business information that is proprietary to the company, such
                            as internal project details, financial records, strategic plans, and
                            intellectual property.
                          </span>
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='#5A5A5A'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                        <path d='M12 9h.01' />
                        <path d='M11 12h1v4h1' />
                      </svg>
                    </OverlayTrigger>
                  </label>
                </div>

                <label className=' fw-semibold fs-6'>Customer Information</label>

                <div className='checkbox-list'>
                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='PublicCustomerInformation'
                      style={{width: 15, height: 15, marginRight: 10}}
                      checked={customerInformation.PublicCustomerInformation}
                      onChange={handleCustomerInformationCheckbox}
                    />
                    <span></span>
                    Public Customer Information{' '}
                    <OverlayTrigger
                      placement='right'
                      overlay={
                        <Tooltip id='tooltip-top'>
                          <span>
                            Data about customers that is publicly available, such as customer
                            testimonials, public profiles, or information shared openly by the
                            customers themselves.
                          </span>
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='#5A5A5A'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                        <path d='M12 9h.01' />
                        <path d='M11 12h1v4h1' />
                      </svg>
                    </OverlayTrigger>
                  </label>
                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='NonPublicCustomerInformation'
                      style={{width: 15, height: 15, marginRight: 10}}
                      checked={customerInformation.NonPublicCustomerInformation}
                      onChange={handleCustomerInformationCheckbox}
                    />
                    <span></span>
                    Non-Public Customer Information{' '}
                    <OverlayTrigger
                      placement='right'
                      overlay={
                        <Tooltip id='tooltip-top'>
                          <span>
                            Data about customers that is not publicly accessible such as personal
                            contact information, purchase history, account details, and any other
                            confidential customer data.
                          </span>
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='#5A5A5A'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                        <path d='M12 9h.01' />
                        <path d='M11 12h1v4h1' />
                      </svg>
                    </OverlayTrigger>
                  </label>
                </div>

                <label className='fw-semibold fs-6'>
                  Regulation-protected customer, consumer, and employee information
                </label>

                <div className='checkbox-list'>
                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='GDPR_regulated_EU_resident_data'
                      style={{width: 15, height: 15, marginRight: 10}}
                      checked={regulationProtectedInformation.GDPR_regulated_EU_resident_data}
                      onChange={handleRegulationProtectedInformationCheckbox}
                    />
                    <span></span>
                    GDPR regulated EU resident data{' '}
                    <OverlayTrigger
                      placement='right'
                      overlay={
                        <Tooltip id='tooltip-top'>
                          <span>
                            Data of EU residents that must be protected according to the General
                            Data Protection Regulation (GDPR). It covers any information that can be
                            used to identify an individual, such as names, addresses, email
                            addresses, and IP addresses.
                          </span>
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='#5A5A5A'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                        <path d='M12 9h.01' />
                        <path d='M11 12h1v4h1' />
                      </svg>
                    </OverlayTrigger>
                  </label>
                  <label className='form-check form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      //   checked={true}
                      // value={hdEncryption}
                      name='HIPAA_or_HITRUST_protected_data_PHI'
                      style={{width: 15, height: 15, marginRight: 10}}
                      checked={regulationProtectedInformation.HIPAA_or_HITRUST_protected_data_PHI}
                      onChange={handleRegulationProtectedInformationCheckbox}
                    />
                    <span></span>
                    HIPAA or HITRUST protected data(PHI){' '}
                    <OverlayTrigger
                      placement='right'
                      overlay={
                        <Tooltip id='tooltip-top'>
                          <span>
                            Includes medical records, health insurance information, and other
                            health-related data.
                          </span>
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='#5A5A5A'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                        <path d='M12 9h.01' />
                        <path d='M11 12h1v4h1' />
                      </svg>
                    </OverlayTrigger>
                  </label>

                  <div className='checkbox-list'>
                    <label className='form-check form-check-custom form-check-solid m-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        //   checked={true}
                        // value={hdEncryption}
                        name='PCI_DSS_Protected_Data'
                        style={{width: 15, height: 15, marginRight: 10}}
                        checked={regulationProtectedInformation.PCI_DSS_Protected_Data}
                        onChange={handleRegulationProtectedInformationCheckbox}
                      />
                      <span></span>
                      PCI-DSS Protected Data{' '}
                      <OverlayTrigger
                        placement='right'
                        overlay={
                          <Tooltip id='tooltip-top'>
                            <span>
                              Data protected under the Payment Card Industry Data Security Standard
                              (PCI-DSS) such as credit card numbers, cardholder names, expiration
                              dates, and security codes.
                            </span>
                          </Tooltip>
                        }
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='#5A5A5A'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                        >
                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                          <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                          <path d='M12 9h.01' />
                          <path d='M11 12h1v4h1' />
                        </svg>
                      </OverlayTrigger>
                    </label>
                    <label className='form-check form-check-custom form-check-solid m-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        //   checked={true}
                        // value={hdEncryption}
                        name='CCPA_Protected_Data'
                        style={{width: 15, height: 15, marginRight: 10}}
                        checked={regulationProtectedInformation.CCPA_Protected_Data}
                        onChange={handleRegulationProtectedInformationCheckbox}
                      />
                      <span></span>
                      CCPA Protected Data{' '}
                      <OverlayTrigger
                        placement='right'
                        overlay={
                          <Tooltip id='tooltip-top'>
                            <span>
                              Data protected under the California Consumer Privacy Act (CCPA) such
                              as names, addresses, social security numbers, purchase histories, and
                              other personal identifiers.
                            </span>
                          </Tooltip>
                        }
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='#5A5A5A'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                        >
                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                          <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                          <path d='M12 9h.01' />
                          <path d='M11 12h1v4h1' />
                        </svg>
                      </OverlayTrigger>
                    </label>
                    {/* <label className='text-muted fw-semibold fs-6'>Customer Information</label> */}
                  </div>
                  <h4 className='mt-8 mb-3'>
                    Operational Reliance{' '}
                    <OverlayTrigger
                      placement='right'
                      overlay={
                        <Tooltip id='tooltip-top'>
                          <span>
                            <strong>Operational Reliance</strong>
                            <ul>
                              <li>
                                <strong>Low:</strong> Non-critical services. Business functions
                                without significant impact if disrupted.
                              </li>
                              <li>
                                <strong>Medium:</strong> Important services. Disruptions cause some
                                delays but manageable.
                              </li>
                              <li>
                                <strong>High:</strong> Crucial services. Disruptions significantly
                                impact business operations.
                              </li>
                            </ul>
                          </span>
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='#5A5A5A'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                        <path d='M12 9h.01' />
                        <path d='M11 12h1v4h1' />
                      </svg>
                    </OverlayTrigger>
                  </h4>
                  <div className='col-9 col-form-label'>
                    <div className='checkbox-list'>
                      {/* <label className='text-muted'>Operational Reliance</label> */}
                      <label className='form-check form-check-custom form-check-solid p-3'>
                        <input
                          className='form-check-input'
                          type='radio'
                          value='Low'
                          name='reliance'
                          checked={reliance === 'Low'}
                          onChange={handleRelianceChange}
                        />

                        <span style={{marginLeft: 10, marginRight: 20}}> Low</span>

                        <input
                          className='form-check-input'
                          type='radio'
                          value='Medium'
                          name='reliance'
                          checked={reliance === 'Medium'}
                          onChange={handleRelianceChange}
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}>Medium</span>

                        <input
                          className='form-check-input'
                          type='radio'
                          value='High'
                          name='reliance'
                          checked={reliance === 'High'}
                          onChange={handleRelianceChange}
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}>High</span>
                      </label>
                    </div>
                  </div>
                  <h4 className='mt-6 mb-3'>
                    Risk Level{' '}
                    <OverlayTrigger
                      placement='right'
                      overlay={
                        <Tooltip id='tooltip-top'>
                          <span>
                            Risk Level
                            <ul>
                              <li>
                                <strong>Low:</strong> Minor impact and low likelihood. Manageable
                                consequences.
                              </li>
                              <li>
                                <strong>Medium:</strong> Moderate impact and reasonable likelihood.
                                Some disruptions.
                              </li>
                              <li>
                                <strong>High:</strong> Significant impact and high likelihood.
                                Severe consequences.
                              </li>
                            </ul>
                          </span>
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='#5A5A5A'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                        <path d='M12 9h.01' />
                        <path d='M11 12h1v4h1' />
                      </svg>
                    </OverlayTrigger>
                  </h4>
                  <div className='col-9 col-form-label'>
                    <div className='checkbox-list'>
                      {/* <label>Risk Level</label> */}
                      <label className='form-check form-check-custom form-check-solid p-3'>
                        <input
                          className='form-check-input'
                          type='radio'
                          value='Low Risk Level'
                          name='riskLevel'
                          checked={riskLevel === 'Low Risk Level'}
                          onChange={handleRiskLevel}
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}> Low</span>

                        <input
                          className='form-check-input'
                          type='radio'
                          value='Medium Risk Level'
                          name='riskLevel'
                          checked={riskLevel === 'Medium Risk Level'}
                          onChange={handleRiskLevel}
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}>Medium</span>

                        <input
                          className='form-check-input'
                          type='radio'
                          value='High Risk Level'
                          name='riskLevel'
                          checked={riskLevel === 'High Risk Level'}
                          onChange={handleRiskLevel}
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}>High</span>
                      </label>
                    </div>
                  </div>
                  <div className='form-group'>
                    <h4 className='mt-6 mb-3'>Logo</h4>

                    {/* <label className='text-dark fw-bold text-primary my-2 fs-6'>Logo</label> */}
                    <input
                      type='file'
                      className='form-control '
                      accept='.jpg,.png,.jepg'
                      onChange={handleImage}
                    />
                  </div>
                  <div className='modal-footer m-5'>
                    <a
                      className='btn btn-secondary'
                      href='/vendor-risk'
                      role='button'
                      style={{marginRight: 20}}
                    >
                      Cancel
                    </a>
                    {isLoading ? (
                      <button
                        type='submit'
                        className='btn'
                        style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                        disabled={progress !== null && progress < 100}
                      >
                        <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                        Add Vendor
                      </button>
                    ) : (
                      <button
                        type='submit'
                        className='btn'
                        style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                        disabled={progress !== null && progress < 100}
                      >
                        Add Vendor
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddVendor
