import React, {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import {
  DocumentReference,
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore'
import {useAuth} from '../../../../modules/auth'
import db from '../../../../../services/firebase'

export interface addAsset {
  msg: string
  message: string
  checked?: boolean | undefined
}
interface AddAssetsProps {
  isOpen: boolean
  onClose: () => void
}

const ProductDrawer: React.FC<AddAssetsProps> = ({isOpen, onClose}) => {
  const [productName, setProductName] = useState('')
  const [websiteUrl, setWebsiteUrl] = useState('')
  const [productUrl, setProductUrl] = useState('')
  const [contactUrl, setContactUrl] = useState('')
  const [loginUrl, setLoginUrl] = useState('')
  const [policyUrl, setPolicyUrl] = useState('')
  const [message, setMessage] = useState({error: false, msg: ''})
  const [showAlert, setShowAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const drawerTopRef = useRef(null)
  const [isEditMode, setIsEditMode] = useState(false)

  if (showAlert && drawerTopRef.current) {
    ;(drawerTopRef.current as HTMLElement).scrollIntoView({behavior: 'smooth'})
  }

  useEffect(() => {
    const fetchCompanyData = async () => {
      if (currentUser?.tenantId) {
        const tenantRef = doc(db, 'tenants', currentUser.tenantId)
        const companyRef = doc(tenantRef, 'settings', 'product')
        const docSnap = await getDoc(companyRef)

        if (docSnap.exists()) {
          const productData = docSnap.data().newAsset
          setProductName(productData.productName || '')
          setWebsiteUrl(productData.websiteUrl || '')
          setProductUrl(productData.productUrl || '')
          setContactUrl(productData.contactUrl || '')
          setLoginUrl(productData.loginUrl || '')
          setPolicyUrl(productData.policyUrl || '')
          setIsEditMode(true)
        } else {
          setIsEditMode(false)
        }
      }
    }

    fetchCompanyData()
  }, [currentUser])
  
    const handleSubmit = async (e) => {
      e.preventDefault()
      setIsLoading(true)
  
      const newAsset = {
        productName,
        websiteUrl,
        productUrl,
        contactUrl,
        loginUrl,
        policyUrl,
        tenantId: currentUser?.tenantId,
      }
  
      if (productName === '' || websiteUrl === '' || productUrl === '' || contactUrl === '') {
        setMessage({error: true, msg: 'Please fill all the required fields'})
        setIsLoading(false)
        setShowAlert(true)
        window.scrollTo(0, 0)
        return
      }
  
      try {
        if (currentUser) {
          const tenantRef = doc(db, 'tenants', currentUser.tenantId!)
          const productRef: DocumentReference = doc(tenantRef, 'settings', 'product')
  
          await setDoc(productRef, {newAsset})
  
          const currentDate = new Date()
          const todaysDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`
  
          const activitiesRef = collection(tenantRef, 'activities')
          await addDoc(activitiesRef, {
            message: isEditMode
              ? `updated the product '${productName}'`
              : `added a new product '${productName}'`,
            timestamp: serverTimestamp(),
            tenantId: currentUser?.tenantId,
            uid: currentUser?.uid,
            username: currentUser?.userName,
            date: todaysDate,
          })
  
          setShowAlert(true)
          setMessage({error: false, msg: isEditMode ? 'Updated Successfully' : 'Added Successfully'})
          setIsEditMode(true) // Set to edit mode after successful submission
        }
      } catch (error) {
        console.error('Error saving product:', error)
        setMessage({error: true, msg: 'An error occurred. Please try again.'})
        setShowAlert(true)
      } finally {
        setIsLoading(false)
        window.scrollTo(0, 0)
      }
  
      setTimeout(() => {
        setShowAlert(false)
        onClose()
      }, 2000)
    }

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} direction='right' className='' size='45%'>
        <div
          className='modal-dialog modal-dialog-centered pt-3 px-6 border-bottom border-gray-300 '
          style={{position: 'sticky', top: 0, backgroundColor: 'white', paddingLeft: '12px'}}
        >
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 className='modal-title mt-2 mb-4'>Add Product</h4>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary mx-4'
                onClick={onClose}
                aria-label='Close'
              >
                  <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='#5A5A5A'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  className='icon icon-tabler icons-tabler-outline icon-tabler-x'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M18 6l-12 12' />
                  <path d='M6 6l12 12' />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div
          className=' scroll-y overflow-auto p-6 mb-4'
          tabIndex={-1}
          style={{height: 'calc(100vh - 20px)'}}
        >
          <form id='kt_risk_form' className='form' onSubmit={handleSubmit}>
            <div className='modal-content'>
              <div className='modal-body'>
                {showAlert && (
                  <div style={{marginTop: 0, marginLeft: 0, marginRight: 0}}>
                    {message?.msg && (
                      <div
                        className={
                          message?.error
                            ? 'alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row'
                            : 'alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row'
                        }
                      >
                        {message?.error ? (
                          <i className='ki-duotone ki-notification-bing fs-2hx text-danger me-4 mb-5 mb-sm-0'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>
                        ) : (
                          <i className='ki-duotone ki-notification-bing fs-2hx text-success me-4 mb-5 mb-sm-0'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>
                        )}

                        <div
                          className={
                            message?.error
                              ? 'd-flex flex-column text-danger text-primary text-bold pe-0 pe-sm-10'
                              : 'd-flex flex-column text-success text-primary text-bold pe-0 pe-sm-10'
                          }
                        >
                          {message?.error ? (
                            <h5 className='mb-1'>Error!!</h5>
                          ) : (
                            <h5 className='mb-1'>Sucess!!</h5>
                          )}
                          <span>{message?.msg}</span>
                        </div>
                        <button
                          type='button'
                          className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
                          onClick={() => setShowAlert(false)}
                        >
                          <span className='svg-icon svg-icon-1 svg-icon-success'>
                            <span className='svg-icon svg-icon-2x svg-icon-primary'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                                className='mh-50px'
                              >
                                <rect
                                  opacity='0.5'
                                  x='6'
                                  y='17.3137'
                                  width='16'
                                  height='2'
                                  rx='1'
                                  transform='rotate(-45 6 17.3137)'
                                  fill='black'
                                ></rect>
                                <rect
                                  x='7.41422'
                                  y='6'
                                  width='16'
                                  height='2'
                                  rx='1'
                                  transform='rotate(45 7.41422 6)'
                                  fill='black'
                                ></rect>
                              </svg>
                            </span>
                          </span>
                        </button>
                      </div>
                    )}
                  </div>
                )}

                <div className=''>
                  <div className='form-group'>
                    <label className='text-dark fw-bold text-primary mb-2 fs-6 required'>
                      Product Name
                    </label>
                    <input
                      type='text'
                      className='form-control '
                      placeholder='Enter product name'
                      name='productName'
                      value={productName}
                      onChange={(e) => setProductName(e.target.value)}
                    />
                  </div>

                  <div className='form-group'>
                    <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6 required'>
                      Website/Landing Page URL
                    </label>
                    <div className='input-group input-group-lg'>
                      <input
                        type='url'
                        className='form-control '
                        placeholder='Enter website name'
                        value={websiteUrl}
                        onChange={(e) => setWebsiteUrl(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className='form-group'>
                    <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6 required'>
                      Product/Application URL
                    </label>
                    <input
                      type='url'
                      className='form-control '
                      placeholder='Enter product/application URL'
                      value={productUrl}
                      onChange={(e) => setProductUrl(e.target.value)}
                    />
                  </div>

                  <div className='form-group'>
                    <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6 required'>
                      Contact Page URL
                    </label>
                    <div className='input-group input-group-lg'>
                      <input
                        type='url'
                        className='form-control '
                        placeholder='Enter contact page URL'
                        value={contactUrl}
                        onChange={(e) => setContactUrl(e.target.value)}
                      />
                    </div>
                    <div className='fs-7' style={{color: '#828282'}}>
                      Your product’s contact us page URL
                    </div>
                  </div>

                  <div className='form-group'>
                    <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6'>
                      Login Page URL
                    </label>
                    <div className='input-group input-group-lg'>
                      <input
                        type='url'
                        className='form-control '
                        placeholder='Enter login page URL'
                        value={loginUrl}
                        onChange={(e) => setLoginUrl(e.target.value)}
                      />
                    </div>
                    <div className='fs-7' style={{color: '#828282'}}>
                      Your product’s login page URL
                    </div>
                  </div>

                  <div className='form-group mb-8'>
                    <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6'>
                      Privacy Policy Page URL
                    </label>
                    <div className='input-group input-group-lg'>
                      <input
                        type='url'
                        className='form-control '
                        placeholder='Enter privacy policy page URL'
                        value={policyUrl}
                        onChange={(e) => setPolicyUrl(e.target.value)}
                      />
                    </div>
                    <div className='fs-7' style={{color: '#828282'}}>
                      Your product’s privacy policy page URL
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className='offcanvas-footer d-flex justify-content-end  border-top py-4'
              style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
            >
              <button type='button' className='btn btn-light mx-2 mb-4' onClick={onClose}>
                Cancel
              </button>
              {isLoading ? (
                <button
                  type='submit'
                  className='btn mb-4'
                  style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                >
                  <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                  {isEditMode ? 'Update Product' : 'Add Product'}
                </button>
              ) : (
                <button
                  type='submit'
                  className='btn mb-4'
                  style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                >
                {isEditMode ? 'Update Product' : 'Add Product'}
                </button>
              )}
            </div>
            {/* </div> */}
          </form>
        </div>
      </Drawer>
    </>
  )
}

export default ProductDrawer
